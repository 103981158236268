import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAppContext } from "./appContext";
import { createStyles, Fab, makeStyles, Theme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { IGlobalState } from "../store/types/state";
import { Actions as AppActions } from "../store/ducks/app";
import queryString from "query-string";
import { SidebarTopBar } from "../components/SidebarTopBar";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export default function AuthenticatedRoute({
  children,
  ...rest
}: {
  children: any;
  exact?: boolean;
  path: string;
}) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();
  const isMenuOpen = useSelector((state: IGlobalState) => state.app.isMenuOpen);
  const dispatch = useDispatch();
  const toggleMenu = () => dispatch(AppActions.toggleMenu());
  const classes = useStyles();

  return (
    <React.Fragment>
      {/* <MenuAppBar /> */}
      {/* <Sidebar /> */}
      <SidebarTopBar changeMenu={isMenuOpen} />
      <Fab size="small" className={classes.toggleSidebar} onClick={toggleMenu}>
        <ChevronLeftIcon fontSize="small" className={isMenuOpen ? "" : classes.expandIcon} />
      </Fab>
      <div className={classes.appBody}>
        <Route {...rest}>
          {isAuthenticated ? (
            children
          ) : (
            <Redirect to={{
              pathname: "/login",
              search: queryString.stringify({"redirect": pathname+search})
            }} />
          )}
        </Route>
      </div>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBody: {
      display: "flex",
      flexFlow: "column",
      //marginLeft: theme.spacing() * -1,
      padding: theme.spacing(2),
      background: "#F1F5F9",
      width: "100%",
      height: "100%",
      overflow: "auto",
      backgroundSize: "cover",
    },
    toggleSidebar: {
      marginTop: theme.spacing(3),
      marginLeft: "-24px", //theme.spacing(2) * -1,
      height: theme.spacing(3),
      minHeight: theme.spacing(3),
      width: theme.spacing(3),
      minWidth: theme.spacing(3),
      backgroundColor: "#fff",
      zIndex: 9999,
      right: "-12px",
    },
    expandIcon: {
      transform: "rotate(180deg)"
    }
  })
);
