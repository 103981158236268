import { useContext, createContext } from "react";

interface IContext {
  isAuthenticated: boolean;
  userHasAuthenticated: any;
}

const INITIAL_CONTEXT: IContext = {
  isAuthenticated: false,
  userHasAuthenticated: null,
};

export const AppContext = createContext(INITIAL_CONTEXT);
export function useAppContext() {
  return useContext(AppContext);
}
