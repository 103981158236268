import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { Grid, Typography, Tabs, Tab, Button,  Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, 
    TextField,
    IconButton} from "@material-ui/core";
import { Close, EditOutlined, AttachMoney, AddBox } from "@material-ui/icons";
import { CustomBreadcrumbs } from "../../../components/CustomBreadcrumbs";
import { CustomerDetailData } from "./CustomerDetailData";
import { CustomerDetailStores } from "./CustomerDetailStores";
import { CustomerUsers } from "./CustomerUsers";
import { CustomerOperations } from "./CustomerOperations";
import { CustomerIntegrations } from "./CustomerIntegrations";
import { CustomerPanel } from "./CustomerPanel";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import AddCreditsCustomer from "../../../components/AddCreditsCustomer";
import { CustomerDetailPlan } from "./CustomerDetailPlan";
import { Link } from "react-router-dom";
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../../components/ConfirmationDialog";
import CustomerService from "../../../services/customerService";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { CustomerDetails } from "../../../services/types/customer";
import ShowIf from "../../../components/ShowIf";
import AuthService from "../../../services/authService";
import { CustomerDetailBlockList } from "./CustomerDetailBlockList";
import InsertCredits from "./insertCredit";
import numbro from "numbro";
import  ContentCopyIcon  from "@material-ui/icons/FileCopy";
import { CustomerDebitNotes } from "./CustomerDebitNotes";
import { useHistory } from "react-router-dom";
import { decodeURLParams, encodeURLParams, URLParamType } from "../../../utils/utils";


interface IDetailCustomerProps {
    match: any;
}

interface IParams {
    customerId: string;
}

export const DetailCustomer: React.FC<IDetailCustomerProps> = () => {
    const tabLabels = [
        {name: "Painel", tab: "panel"}, 
        {name: "Cliente", tab: "client"}, 
        {name: "Lojas", tab: "stores"}, 
        {name: "Usuários", tab: "users"}, 
        {name: "Lançamentos", tab: "releases"}, 
        {name: "Integrações", tab: "integrations"}, 
        {name: "Notas de Débito", tab: "notes"}
    ];
    if (AuthService.hasAuthorization("view_blocklist")) {
        tabLabels.push({name: "Bloqueios", tab: "block"});
    }
    const [tab, setTab] = React.useState<string>("panel");
    const [showAddCreditDialog, setShowAddCreditDialog] = React.useState(false);
    const { customerId } = useParams<IParams>();
    const [isLoading, showLoading] = React.useState(false);
    const [customer, setCustomer] = React.useState<CustomerDetails>({} as CustomerDetails);
    const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
    const [showInsertCredits, setShowInsertCredits] = React.useState(false);
    const [pixDetails, setPixDetails] = React.useState<any>();
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [copyPix, setCopyPix] = React.useState<boolean>(false);
    const history = useHistory();

    React.useLayoutEffect(() => {
        const result: any = decodeURLParams(history.location.search, [{"menu": URLParamType.STRING}]);
        if(result && result.menu){
            setTab(result.menu);
        }      
    }, [history.location.search]);

    const refreshData = useCallback(async (id: number) => {
        showLoading(true);
        try {
            const customerDetails = await CustomerService.getCustomer(id);
            setCustomer(customerDetails);
        } catch (error) {
            NotificationManager.error(
                "Erro ao tentar carregar os dados do Cliente.",
                "Cliente"
            );
        } finally {
            showLoading(false);
        }
    }, []);

    const handleChangeTab = (
        event: React.ChangeEvent<{}>,
        newValue: string
    ) => {
        history.replace(encodeURLParams(history.location.pathname, {menu: newValue}));
        setTab(newValue);
    };

    const openAddCreditDialog = () => {
        setShowAddCreditDialog(true);
    };

    useEffect(() => {
        refreshData(Number(customerId));
    }, [customerId, refreshData]);

    const activateCustomer = (customer: CustomerDetails) => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            "Ativação de Cliente",
            <Typography>Você tem certeza que deseja <strong>Ativar</strong> este Cliente?</Typography>,
            "Ativar",
            "Cancelar"
        ).then(() => {
            CustomerService.activateCustomer(customer.id)
                .then(() => {
                    refreshData(customer.id);
                    dialog?.hide();
                })
                .catch((error: any) => {
                    let errorMsg = "Ocorreu um erro ao Ativar o Cliente";
                    const { status, data } = error.response;
                    const errorMsgs: string[] = [];
                    if (status === 400) {
                        for (var key in data) {
                            var value = data[key];
                            errorMsgs.push(value);
                        }
                    }

                    if (errorMsgs.length > 0) {
                        errorMsg = errorMsgs.join(". \n");
                    }
                    NotificationManager.error(errorMsg, "Ativar Cliente");
                });
        }).catch(() => { });
    }

    const deactivateCustomer = (customer: CustomerDetails) => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            "Desativação de Cliente",
            <Typography>Você tem certeza que deseja <strong>Desativar</strong> este Cliente?</Typography>,
            "Desativar",
            "Cancelar"
        ).then(() => {
            CustomerService.deactivateCustomer(customer.id)
                .then(() => {
                    refreshData(customer.id);
                    dialog?.hide();
                })
                .catch((error: any) => {
                    let errorMsg = "Ocorreu um erro ao Desativar o Cliente";
                    const { status, data } = error.response;
                    const errorMsgs: string[] = [];
                    if (status === 400) {
                        for (var key in data) {
                            var value = data[key];
                            errorMsgs.push(value);
                        }
                    }

                    if (errorMsgs.length > 0) {
                        errorMsg = errorMsgs.join(". \n");
                    }
                    NotificationManager.error(errorMsg, "Desativar Cliente");
                });
        }).catch(() => { });
    }

    const refresh = () => {
        refreshData(Number(customerId));
    };

    const handleShowInsertCredits = () => {
        setShowInsertCredits(true);
    };

    const copyPixKey = async (pix: string) => {
        if (pix) {     
          navigator.clipboard.writeText(pix);         
          setInterval(() => {
            setCopyPix(false);
          }, 3000);        
        }  
    };

    return (
        <React.Fragment>
            <ConfirmationDialog ref={confirmationDialog} />

            <LoadingOverlay open={isLoading} />

            <InsertCredits
                open={showInsertCredits}
                idCustomer={customerId ? Number(customerId) : 0}
                setOpen={setShowInsertCredits}
                updateFinances={refresh}
                setPixDetails={setPixDetails}
                setOpenDialog={setOpenDialog}
            />

            <Grid container justify="space-between" alignItems="center" className="page-title">
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">
                            Detalhes do Cliente
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[
                                { label: "Clientes", url: "/customer" },
                                {
                                    label: "Detalhes do Cliente",
                                    url: `/customer/detail/${customerId}`,
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                className="tabs"
                scrollButtons="auto"
                variant="scrollable"
            >
                {tabLabels.map(item => {
                    return <Tab key={item.tab} value={item.tab} label={item.name} />;
                })}
            </Tabs>

            {tab === "panel" && <CustomerPanel customer={customer} />}

            {tab === "client" && (
                <Grid container spacing={2}>
                    <Grid item container xs={12} spacing={1} justify="flex-end">
                        <ShowIf condition={AuthService.hasAuthorization("activate_customer") && !customer.is_active}>
                            <Grid item>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    startIcon={<CheckIcon />}
                                    onClick={() => { activateCustomer(customer); }}
                                >
                                    Ativar
                                </Button>
                            </Grid>
                        </ShowIf>
                        <ShowIf condition={AuthService.hasAuthorization("activate_customer") && customer.is_active}>
                            <Grid item>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    startIcon={<BlockIcon />}
                                    onClick={() => { deactivateCustomer(customer); }}
                                >
                                    Desativar
                                </Button>
                            </Grid>
                        </ShowIf>
                        <ShowIf condition={AuthService.hasAuthorization("change_customer")}>
                            <Grid item>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    startIcon={<EditOutlined />}
                                    component={Link} to={`/customer/edit/${customerId}`}
                                >
                                    Editar
                                </Button>
                            </Grid>
                        </ShowIf>
                        <ShowIf condition={AuthService.hasAuthorization("add_customer_finances_entries")}>
                            <Grid item>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={openAddCreditDialog}
                                    startIcon={<AddBox />}
                                >
                                    Crédito Avulso
                                </Button>
                            </Grid>
                        </ShowIf>
                        <ShowIf condition={AuthService.hasAuthorization("view_customer")}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleShowInsertCredits}
                                    startIcon={<AttachMoney />}
                                >
                                    Adicionar Créditos
                                </Button>
                            </Grid>
                        </ShowIf>
                       
                    </Grid>
                    <Grid item container md={12} spacing={2}>
                        <CustomerDetailData customer={customer} />
                        <CustomerDetailPlan customer={customer} />
                    </Grid>
                </Grid>
            )}

            {tab === "stores" && (
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <CustomerDetailStores customerId={Number(customerId)} />
                    </Grid>
                </Grid>
            )}

            {tab === "users" && (
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <CustomerUsers customerId={Number(customerId)} />
                    </Grid>
                </Grid>
            )}

            {tab === "releases" && (
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <CustomerOperations customerId={Number(customerId)} />
                    </Grid>
                </Grid>
            )}

            {tab === "integrations" && (
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <CustomerIntegrations customerId={Number(customerId)} />
                    </Grid>
                </Grid>
            )}

            {tab === "notes" && (
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <CustomerDebitNotes customerId={Number(customerId)} />
                    </Grid>
                </Grid>
            )}

            {tab === "block" && (
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <CustomerDetailBlockList customerId={Number(customerId)} />
                    </Grid>
                </Grid>
            )}
            
            <AddCreditsCustomer
                modal={{
                    open: showAddCreditDialog,
                    setOpen: setShowAddCreditDialog,
                }}
                customerId={Number(customerId)}
            />

            <Dialog
                open={openDialog}
                maxWidth="md"
                onClose={() => setOpenDialog(false)}
                aria-labelledby="form-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
            <DialogTitle id="form-dialog-title">
            <Grid container spacing={1}>
                <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
                >
                <Typography variant="h1">Pagamento</Typography>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpenDialog(false)}
                >
                    <Close />
                </IconButton>
                </Grid>
            </Grid>
            </DialogTitle>
            <DialogContent>
            <Grid container spacing={2} style={{ width: "320px" }}>
                <Grid item xs={12}>
                <Typography
                    variant="body1"
                    className="dark"
                    style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    }}
                >
                    <strong>Valor do pix</strong>
                    <strong>{pixDetails?.value ? numbro(pixDetails?.value).formatCurrency() : 0}</strong>
                </Typography>
                <Typography
                    variant="body1"
                    className="dark"
                    style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    }}
                >
                    <strong>Taxa</strong>
                    <strong>{pixDetails?.tax ? numbro(pixDetails?.tax).formatCurrency() : 0}</strong>
                </Typography>
                <hr />
                <Typography
                    variant="body1"
                    className="dark"
                    style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    }}
                >
                    <strong>Total</strong>
                    <strong>{pixDetails?.total ? numbro(pixDetails?.total).formatCurrency() : 0}</strong>
                </Typography>
                </Grid>

                <Grid item xs={12} style={{position: "relative", textAlign: "center"}}>
                {/* <QRCode value={pixDetails?.bank_slip_barcode || ""} /> */}
                </Grid>
                <Grid item xs={12}>
                <Typography
                    variant="body1"
                    className="dark"
                >
                    <strong>Pix Copia e Cola</strong>
                    <Grid container spacing={1}>
                    <Grid item >
                        <TextField
                        id="corporate_name"
                        className="mt-1"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={pixDetails?.bank_slip_barcode || ""}
                        disabled
                        onClick={(e) => {
                            setCopyPix(true); 
                            copyPixKey(pixDetails?.bank_slip_barcode || "")}
                        }
                        />
                    </Grid>
                    <Grid item style={{paddingTop: "8px"}}>
                        <Button variant="outlined" onClick={(e) => {
                        setCopyPix(true); 
                        copyPixKey(pixDetails?.bank_slip_barcode || "")
                        }} startIcon={<ContentCopyIcon />}>
                        Copiar
                        </Button>
                    </Grid>
                    </Grid>
                    
                
                
                    {copyPix ? <strong><span style={{color: 'green'}}>Copiado.</span></strong> : null}
                </Typography>
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
            <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={() => setOpenDialog(false)}
            >
                OK
            </Button>
            </DialogActions>
        </Dialog>
        </React.Fragment >
    );
};
