import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import {
    DATE_COLUMN_DEFAULTS,
    DEFAULT_TABLE_COMPONENTS,
    DEFAULT_TABLE_OPTIONS,
    PageableParams,
    TABLE_L10N_PTBR,
} from "../../components/TableTrinkets";
import MaterialTable, {
    MaterialTableProps,
    Query,
    QueryResult,
} from "@material-table/core";
import { VisibilityOutlined } from "@material-ui/icons";
import { DebitNoteFilter, IDebitNoteFilterParams, INITIAL_FILTER_PARAMS } from "./debitNoteFilter";
import DebitNoteService from "../../services/debitNotesService";
import { DebitNotesResponse } from "../../services/types/debitNotes";
import { decodeURLParams, URLParamType } from "../../utils/utils";

export const DebitNote = () => {
    const history = useHistory();
    const [filterParams, setFilterParams] = useState<IDebitNoteFilterParams>({
        ...INITIAL_FILTER_PARAMS,
        ...decodeURLParams(history.location.search, [
            { "date_after": URLParamType.DATE },
            { "date_before": URLParamType.DATE }
        ])
    });
    
    const tableRef = React.useRef<MaterialTableProps<DebitNotesResponse>>();

    const loadData = (query: Query<DebitNotesResponse>) =>
        new Promise<QueryResult<DebitNotesResponse>>((resolve, reject) => {
            DebitNoteService.getDebitNotes(filterParams.customerId,
                filterParams.date_after, filterParams.date_before, PageableParams.fromQuery(query))
                .then(response => {
                    resolve({
                        data: response.data,
                        page: query.page,
                        totalCount: response.count,
                    });
                });
        });

    const applyFilter = (params: IDebitNoteFilterParams) => {
        setFilterParams(params);
        if (tableRef.current?.onQueryChange) {
            tableRef.current.onQueryChange({
                page: 0,
            } as Query<DebitNotesResponse>);
        }
    };

    const showDetails = (item: DebitNotesResponse) => {
        history.push(`debitNotes/detail/${item.id}`);
    };

    return (
        <div>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Notas de Débito</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[
                                { label: "Notas de Débito", url: "/debitNotes" },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <DebitNoteFilter params={filterParams} customerId={null} onFilterChanged={applyFilter} />

            <MaterialTable
                tableRef={tableRef}
                columns={[
                    { field: "id", hidden: true },
                    {
                        ...DATE_COLUMN_DEFAULTS,
                        emptyValue: "---",
                        field: "reference_date",
                        title: "Data Referência",
                    },
                    {
                        field: "customer.corporate_name",
                        title: "Cliente",
                    },
                    {
                        field: "customer.trade_name",
                        title: "Nome Fantasia",
                    },
                ]}
                data={loadData}
                actions={[
                    {
                        icon: () => <VisibilityOutlined />,
                        tooltip: "Ver Detalhes",
                        onClick: (event, rowData) =>
                            showDetails(rowData as DebitNotesResponse),
                    },
                   /*  {
                        icon: () => <GetApp />,
                        tooltip: "Baixar Arquivo de Remessa",
                        onClick: (event, rowData) =>
                            download(rowData as DebitNotesResponse),
                    }, */
                ]}
                components={DEFAULT_TABLE_COMPONENTS}
                localization={TABLE_L10N_PTBR}
                options={DEFAULT_TABLE_OPTIONS}
            />
        </div>
    );
};
