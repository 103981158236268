import React, { useEffect } from "react";
import { Button, Checkbox,  CircularProgress,  FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CURRENT_DATE_TIME_FORMAT } from "../../../i18n";
import { useFormik } from "formik";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import * as Yup from "yup";
import { convertToNumber } from "../../../utils/utils";
import { LoadingDialog } from "../../../components/LoadingDialog";
import SettingsService from "../../../services/settingsService";
import { DeliverySettingsResponse } from "../../../services/types/deliveryRequest";
import { format } from "date-fns";

const INITIAL_FORM_VALUES: DeliverySettingsResponse = {
    queue_retries: 0,
    retry_notification_seconds: 0,
    acceptation_seconds: 0,
    extra_acceptation_time: 0, 
    minutes_to_discard: 0,
    advanced_speeder_points: 0,
    updated_at: new Date()
}

const validationSchema = Yup.object().shape({
    queue_retries: Yup.number()
        .required("Campo obrigatório")
        .transform((currentValue, originalValue) => convertToNumber(originalValue))
        .min(1, "Campo obrigatório"),
    retry_notification_seconds: Yup.number()
        .required("Campo obrigatório")
        .transform((currentValue, originalValue) => convertToNumber(originalValue))
        .min(1, "Campo obrigatório"),
    acceptation_seconds: Yup.number()
        .required("Campo obrigatório")
        .transform((currentValue, originalValue) => convertToNumber(originalValue))
        .min(1, "Campo obrigatório"),
    extra_acceptation_time: Yup.number()
        .required("Campo obrigatório")
        .transform((currentValue, originalValue) => convertToNumber(originalValue))
        .min(1, "Campo obrigatório"),
    minutes_to_discard: Yup.number()
        .required("Campo obrigatório")
        .transform((currentValue, originalValue) => convertToNumber(originalValue))
        .min(1, "Campo obrigatório"),
    advanced_speeder_points: Yup.number()
        .required("Campo obrigatório")
        .transform((currentValue, originalValue) => convertToNumber(originalValue))
        .min(1, "Campo obrigatório"),
});

export const DeliverySettings = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [state, setState] = React.useState({ confirmation: false });
    const [isShowLoadingDialog, showLoadingDialog] = React.useState(false);

    const handleChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const { confirmation } = state;

    const getDeliverySettings = async () => {
        showLoadingDialog(true);
        const response = await SettingsService.getDeliverySettings();
        formik.setValues(response);
        showLoadingDialog(false);
    };

    useEffect(() => {
        getDeliverySettings();
    }, []);

    const formik = useFormik({
        initialValues: INITIAL_FORM_VALUES,
        onSubmit: async (values) => {
            try {
                await SettingsService.updateDeliverySettings(values);
                getDeliverySettings();
                NotificationManager.success(
                    "Configurações de Entrega alteradas com Sucesso!",
                    "Configurações de Entrega"
                );
                setState({ "confirmation": false });
            } catch (error) {
                const { status, data } = error.response;
                if (status === 400) {
                    for (var key in data) {
                        var value = data[key];
                        formik.setFieldError(key, value);
                    }
                } else {
                    NotificationManager.error(
                        "Não foi possível alterar Configurações de Entrega!",
                        "Configurações de Entrega"
                    );
                }
            }
        },
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
    });

    return (
        <div>
            <LoadingDialog open={isShowLoadingDialog} message={""} />
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item md={12} style={{marginBottom: "10px"}}>
                        <Typography variant="subtitle1">
                            Última atualização: {format(formik.values.updated_at, CURRENT_DATE_TIME_FORMAT)}
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                    <Grid container spacing={2} md={12}>
                    
                    
                    <Grid item md={12}>
                        <TextField
                            type="number"
                            id="acceptation_seconds"
                            label="Segundos para Aceitar"
                            variant="outlined"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.acceptation_seconds}
                            error={!!formik.errors.acceptation_seconds}
                            helperText={formik.errors.acceptation_seconds}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        segundos
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            type="number"
                            label="Tempo Extra para Aceitar Corrida"
                            id="extra_acceptation_time"
                            variant="outlined"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.extra_acceptation_time}
                            error={!!formik.errors.extra_acceptation_time}
                            helperText={formik.errors.extra_acceptation_time}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        segundos
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            type="number"
                            label="Minutos para Descartar"
                            id="minutes_to_discard"
                            variant="outlined"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.minutes_to_discard}
                            error={!!formik.errors.minutes_to_discard}
                            helperText={formik.errors.minutes_to_discard}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        minutos
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            type="number"
                            id="advanced_speeder_points"
                            label="Quantidade de pontos para se tornar Speeder Raiz"
                            variant="outlined"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.advanced_speeder_points}
                            error={!!formik.errors.advanced_speeder_points}
                            helperText={formik.errors.advanced_speeder_points}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            type="number"
                            label="Novas Tentativas de Fila"
                            id="queue_retries"
                            variant="outlined"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.queue_retries}
                            error={!!formik.errors.queue_retries}
                            helperText={formik.errors.queue_retries}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            type="number"
                            id="retry_notification_seconds"
                            label="Segundos para Repetir a Notificação"
                            variant="outlined"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.retry_notification_seconds}
                            error={!!formik.errors.retry_notification_seconds}
                            helperText={formik.errors.retry_notification_seconds}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        segundos
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <FormControl component="fieldset">
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox color={"primary"} checked={confirmation} onChange={handleChange} name="confirmation" />}
                                    color={"primary"}
                                    label="Confirmar alteração dos dados."
                                />
                            </FormGroup>
                            <FormHelperText>Verifique todos os dados antes de salvar</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button color="primary" variant="contained" type="submit" disabled={formik.isSubmitting || confirmation === false}>
                            {formik.isSubmitting ? (
                            <CircularProgress size="1.5rem" color="inherit" />
                            ) : (
                            <span>Salvar</span>
                            )} 
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};