import React, { useEffect } from "react";
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import { TaxPaymentResponse } from "../../../services/types/financialEntry";
import { CurrencyInput } from "../../../components/CurrencyInput";
import { getNumberSpecs } from "../../../i18n";
import { useFormik } from "formik";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import * as Yup from "yup";
import { convertToNumber } from "../../../utils/utils";
import { PercentInput } from "../../../components/PercentInput";
import numbro from "numbro";
import { LoadingDialog } from "../../../components/LoadingDialog";
import SettingsService from "../../../services/settingsService";

const INITIAL_FORM_VALUES: TaxPaymentResponse = {
    credit_card_percentage_tax: 0,
    bank_slip_tax: 0,
    pix_tax: 0
}

const validationSchema = Yup.object().shape({
    credit_card_percentage_tax: Yup.number()
        .required("Campo obrigatório")
        .transform((currentValue, originalValue) => convertToNumber(originalValue))
        .min(0.01, "A porcentagem mínima é de 0.01%")
        .max(100, "A porcentagem máxima é de 100%"),
    bank_slip_tax: Yup.number()
        .required("Campo obrigatório")
        .transform((currentValue, originalValue) => numbro.unformat(originalValue))
        .moreThan(0, "Campo obrigatório"),
    pix_tax: Yup.number()
        .required("Campo obrigatório")
        .transform((currentValue, originalValue) => numbro.unformat(originalValue))
        .moreThan(0, "Campo obrigatório")
});

export const TaxPayment = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [state, setState] = React.useState({ confirmation: false });
    const [isShowLoadingDialog, showLoadingDialog] = React.useState(false);

    const handleChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const { confirmation } = state;

    const getTaxPayment = async () => {
        showLoadingDialog(true);
        const response = await SettingsService.getTaxPayment();
        formik.setValues(response);
        showLoadingDialog(false);
    };
    useEffect(() => {
        getTaxPayment();
    }, []);

    const formik = useFormik({
        initialValues: INITIAL_FORM_VALUES,
        onSubmit: async (values) => {
            try {
                await SettingsService.updateTaxPayment(values);
                getTaxPayment();
                NotificationManager.success(
                    "Taxas alteradas com Sucesso!",
                    "Taxas de Pagamento"
                );
                setState({ "confirmation": false });
            } catch (error) {
                const { status, data } = error.response;
                if (status === 400) {
                    for (var key in data) {
                        var value = data[key];
                        formik.setFieldError(key, value);
                    }
                } else {
                    NotificationManager.error(
                        "Não foi possível alterar Taxas de Pagamento!",
                        "Taxas de Pagamento"
                    );
                }
            }
        },
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
    });

    return (
        <div>
            <LoadingDialog open={isShowLoadingDialog} message={""} />
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <Grid container md={12} spacing={2}>
                            <Grid item md={12}>
                                <TextField
                                    id="bank_slip_tax"
                                    label="Taxa do Boleto"
                                    variant="outlined"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.bank_slip_tax}
                                    error={!!formik.errors.bank_slip_tax}
                                    helperText={formik.errors.bank_slip_tax}
                                    fullWidth
                                    InputProps={{
                                        inputComponent: CurrencyInput,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {getNumberSpecs().currency.symbol}
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    id="pix_tax"
                                    label="Taxa do Pix"
                                    variant="outlined"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.pix_tax}
                                    error={!!formik.errors.pix_tax}
                                    helperText={formik.errors.pix_tax}
                                    fullWidth
                                    InputProps={{
                                        inputComponent: CurrencyInput,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {getNumberSpecs().currency.symbol}
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    id="credit_card_percentage_tax"
                                    label="Porcentagem do Cartão de Crédito"
                                    variant="outlined"
                                    required={true}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                    }}
                                    value={formik.values.credit_card_percentage_tax}
                                    error={!!formik.errors.credit_card_percentage_tax}
                                    helperText={formik.errors.credit_card_percentage_tax}
                                    fullWidth
                                    InputProps={{
                                        inputComponent: PercentInput,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {" %"}
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        maxLength: 6
                                    }}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FormControl component="fieldset">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox color={"primary"} checked={confirmation} onChange={handleChange} name="confirmation" />}
                                            color={"primary"}
                                            label="Confirmar alteração dos dados."
                                        />
                                    </FormGroup>
                                    <FormHelperText>Verifique todos os dados antes de salvar</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button color="primary" variant="contained" type="submit" disabled={formik.isSubmitting || confirmation === false}>
                            {formik.isSubmitting ? (
                            <CircularProgress size="1.5rem" color="inherit" />
                            ) : (
                            <span>Salvar</span>
                            )}                            
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};