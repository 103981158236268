import React from "react";
import {
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { FormikHelpers, useFormik } from "formik";
import DatePicker from "react-datepicker";
import { CURRENT_DATE_TIME_FORMAT, DEFAULT_LOCALE } from "../../i18n";
import { useHistory } from "react-router";
import { encodeURLParams } from "../../utils/utils";

export interface IBlockListFilterParams {
  name: string,
  email: string,
  date_block_after: Date | null,
  date_block_before: Date | null,
}

export const INITIAL_FILTER_PARAMS: IBlockListFilterParams = {
  name: "",
  email: "",
  date_block_after: null,
  date_block_before: null,
}

interface IBlockListFilterProps {
  params?: IBlockListFilterParams;
  onFilterChanged: (params: IBlockListFilterParams) => void;
}

export const BlockListFilter: React.FC<IBlockListFilterProps> = ({ params = INITIAL_FILTER_PARAMS, onFilterChanged }) => {
  const history = useHistory();

  React.useEffect(() => {
    history.replace(encodeURLParams(history.location.pathname, params));
  }, [history, params]);

  const formik = useFormik<IBlockListFilterParams>({
    initialValues: params,
    onSubmit: (values: IBlockListFilterParams, formikHelpers: FormikHelpers<IBlockListFilterParams>) => {
      onFilterChanged(values);
    },
    onReset: (values: IBlockListFilterParams, formikHelpers: FormikHelpers<IBlockListFilterParams>) => {
      onFilterChanged(INITIAL_FILTER_PARAMS);
      formikHelpers.setValues(INITIAL_FILTER_PARAMS);
    },
  });


  return (
    <div className="page-filter">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>

          <Grid item md={3}>
            <TextField
              id="name"
              label="Nome do Entregador"
              onChange={formik.handleChange}
              value={formik.values.name}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item md={3}>
            <TextField
              id="email"
              label="Email do Entregador"
              onChange={formik.handleChange}
              value={formik.values.email}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xl={2} md={3}>
            <DatePicker
              id="date_block_after"
              name="date_block_after"
              onChange={val => {
                formik.setFieldValue("date_block_after", val);
              }}
              //maxDate={new Date()}
              selected={formik.values.date_block_after}
              startDate={formik.values.date_block_after}
              endDate={formik.values.date_block_before}
              selectsStart
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_TIME_FORMAT}
              isClearable
              autoComplete="off"
              timeCaption="Hora"
              showTimeSelect
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data e Hora - Início"
                variant="outlined"
                size="small"
                error={!!formik.errors.date_block_after}
                helperText={formik.errors.date_block_after}
                fullWidth />}
            />
          </Grid>

          <Grid item xl={2} md={3}>
            <DatePicker
              id="date_block_before"
              name="date_block_before"
              onChange={val => {
                formik.setFieldValue("date_block_before", val);
              }}
              //maxDate={new Date()}
              selected={formik.values.date_block_before}
              startDate={formik.values.date_block_after}
              endDate={formik.values.date_block_before}
              minDate={formik.values.date_block_after}
              selectsEnd
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_TIME_FORMAT}
              isClearable
              autoComplete="off"
              timeCaption="Hora"
              showTimeSelect
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data e Hora - Fim"
                variant="outlined"
                size="small"
                error={!!formik.errors.date_block_before}
                helperText={formik.errors.date_block_before}
                fullWidth />}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              Pesquisar
            </Button>
          </Grid>

          <Grid item>
            <Button variant="contained" color="secondary" type="reset">
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
