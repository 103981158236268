import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles, Theme, Typography } from "@material-ui/core";

import DeliveryRequestService from "../../../services/deliveryRequestService";
import { DeliveryRequest } from "../../../services/types/deliveryRequest";
import AddOccurrenceDialog, { AddOccurrenceDialogHandle } from "../../occurrences/AddOccurrenceDialog";
import MaterialTable, { MaterialTableProps } from "@material-table/core";
import { DATE_TIME_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";
import NoteAddIcon from "@material-ui/icons/NoteAdd"
import { ImgLoader } from "../../../components/ImgLoader";
import { formatPhoneNumber } from "../../../utils/formatters";
interface ICalledProps {
  deliveryRequest: DeliveryRequest;
  deliveryList: DeliveryRequest[] | undefined;
}

export const Called: React.FC<ICalledProps> = ({ deliveryRequest, deliveryList }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedCouriers, setSelectedCouriers] = React.useState<any[]>([]);
  const occurrenceDialog = React.createRef<AddOccurrenceDialogHandle>();
  const [courierId, setCourierId] = React.useState<number>();
  const [deliveries, setDeliveries] = React.useState<DeliveryRequest[]>([]);
  const tableRef = React.useRef<MaterialTableProps<any>>();
  const [
    countSelectedCouriers,
    setCountSelectedCouriers,
  ] = React.useState<any>();
  const loadSelectedCouriers = useCallback(async (id: number) => {
    const response = await DeliveryRequestService.getDeliveryRequestSelectedCouriers(
      id
    );
    setSelectedCouriers(response.data.couriers);
  }, []);

  useEffect(() => {
    loadSelectedCouriers(deliveryRequest.id);
  }, [deliveryRequest, loadSelectedCouriers]);

  useEffect(() => {
    setCountSelectedCouriers(selectedCouriers.length);
  }, [selectedCouriers]);

  useEffect(() => {
    if(deliveryList && deliveryList?.length > 0){
      setDeliveries(deliveryList);
    }  
  }, [deliveryList]);

  const handleRegisterOccurrence = (item: any) =>{
    if(item.id){
      setCourierId(item.id);
    }
  };
  
  useEffect(() => {
    if(courierId){
      occurrenceDialog.current?.open();
    }  
  }, [courierId, occurrenceDialog]);

  const addOccurrencesDone = async (): Promise<void> => {
    try {
      loadSelectedCouriers(deliveryRequest.id);
    } finally {
      return Promise.resolve();
    }
  }

  const cancelAddEditOccurrence = () => {
    setCourierId(undefined);
  } 

  return (
    <div className={classes.root}>
      <Typography variant="h3" style={{ marginBottom: "30px" }}>
        Foram buscados {countSelectedCouriers} entregadores
      </Typography>

      <AddOccurrenceDialog
        ref={occurrenceDialog}
        courierId={Number(courierId)}
        occurrenceId={null}
        deliveryList={deliveries}
        onAccept={addOccurrencesDone}
        onCancel={cancelAddEditOccurrence}
        />

          <MaterialTable
            tableRef={tableRef}
            columns={[
              { field: "id", hidden: true, width: 60 },
              {
                title: "",
                field: "photo",
                render: (rowData) => <ImgLoader src={rowData.photo} width={40} height={40} />,
              },
              { field: "name", title: "Nome"},
              {
                field: "phonenumber", title: "Telefone", emptyValue: "---",
                render: (rowData, type) => formatPhoneNumber(rowData.phonenumber)
             },
              {
                field: "vehicle_type", title: "Tipo de Veículo", emptyValue: "---",
                render: (rowData, type) => rowData.vehicle_type ? t("vehicle_type." + rowData.vehicle_type) : ""
              },
              { field: "has_accepted", title: "Resposta",
                render: (rowData, type) => {
                  let resposta = "---"
                  if (rowData.has_accepted == true) {
                    return "Aceitou a solicitação";
                  } else if (rowData.has_refused == true) {
                    return "Recusou a solicitação";
                  } else if (rowData.has_ignored == true) {
                    return "Ignorou a solicitação";
                  }
                  return resposta;
                } 
              },
              
              { field: "reason", title: "Motivo da Rejeição", emptyValue: "---",  
                render: (rowData, type) => rowData.reason === "" ?  "---" : rowData.reason 
              },
              { ...DATE_TIME_COLUMN_DEFAULTS, field: "selection_date", title: "Data e Hora", defaultSort: "desc" },
            ]}
            data={selectedCouriers}
            actions={[
              {
                  icon: () => <NoteAddIcon />,
                  tooltip: "Registrar Ocorrência",
                  onClick: (event, rowData) =>
                  handleRegisterOccurrence(rowData)
              },            
          ]}
            components={DEFAULT_TABLE_COMPONENTS}
            localization={TABLE_L10N_PTBR}
            options={{...DEFAULT_TABLE_OPTIONS, paging: false}}
          />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    borderPhoto: {
      borderRadius: "50%"
    }
  })
);
