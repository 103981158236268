import React, { ReactNode, useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AccessTime, MoreVert, CompareArrowsOutlined } from "@material-ui/icons";
import theme from "../../../styles/theme";
import { amber, pink } from "@material-ui/core/colors";
import { differenceInMinutes, format, isPast } from "date-fns";
import { CustomAvatar } from "../../../components/CustomAvatar";
import { DeliveryRequestStatus, IntegrationSource, PendingDeliveryRequest } from "../../../services/types/deliveryRequest";
import ShowIf from "../../../components/ShowIf";
import { CURRENT_DATE_TIME_FORMAT } from "../../../i18n";
import HighlightText from "../../../components/HighlightText";
import numbro from "numbro";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

export enum StatusSeverity {
  WARN,
  INFO,
  SUCCESS,
}

export interface CardStatusChip {
  label: string;
  severity: StatusSeverity;
}

export interface DeliveryRequestCardMenuAction {
  title: string;
  onClick: (deliveryRequest: PendingDeliveryRequest) => void;
  available?: boolean;
}

interface IDeliveryRequestCardNewProps {
  deliveryRequest: PendingDeliveryRequest;
  statusChip: CardStatusChip;
  highlight: string;
  onClick?: (deliveryRequest: PendingDeliveryRequest) => void;
  menuActions?: DeliveryRequestCardMenuAction[];
  children?: ReactNode;
}

export const DeliveryRequestCardNew: React.FC<IDeliveryRequestCardNewProps> = ({
  deliveryRequest,
  statusChip,
  highlight,
  onClick,
  menuActions = [],
  children,
}) => {
  const classes = useStyles();
  const anchorRef = React.useRef(null);
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const anchorReturnRef = React.useRef(null);
  const [loadingBtn, setLoadingBtn] = React.useState<boolean>(false);
  const [openPopoverReturn, setOpenPopoverReturn] = useState<boolean>(false);
  const [arrowReturnRef, setArrowReturnRef] = React.useState<HTMLElement | null>(null);

  const { t } = useTranslation();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  const determineCardClassName = (): string => {
    let className = "";

    /*
      If delayed should show delayed even if "status === NO_COURIER"
    */
    if (isPast(deliveryRequest.estimated_delivery_time)) {
      className = classes.delayedCard;
    }

    return className;
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setTimeout(() => setLoadingBtn(false), 200);
  };

  const handlePopoverReturnOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setOpenPopoverReturn(true);
  };

  const handlePopoverReturnClose = () => {
    setOpenPopoverReturn(false);
  };

  const getIntegrationIcon = (integration_source: IntegrationSource | null): React.ReactNode => {
    let result = <img src="/icons/speedy.png" title="Speedy" alt="Speedy" />;

    if (integration_source) {
      switch (integration_source) {
        case IntegrationSource.IFOOD:
          result = <img src="/icons/ifood.png" title="iFood" alt="iFood" />
          break;
        case IntegrationSource.MEU_XODO:
          result = <img src="/icons/meu_xodo.jpg" title="Meu Xodó" alt="Meu Xodó" />
          break;
        case IntegrationSource.DELIVERY_DIRETO:
          result = <img src="/icons/delivery_direto.png" title="Delivery Direto" alt="Delivery Direto" />
          break;
        case IntegrationSource.NEEMO:
            result = <img src="/icons/neemo.png" title="Neemo" alt="Neemo" />
            break;       
        default:
          break;
      }
    }

    return result;
  };

  const determineAvatarText = (): string | null => {
    let result: string | null = null;

    if ((deliveryRequest.status === DeliveryRequestStatus.NO_COURIER) && deliveryRequest.requested_time) {
        const minutes = differenceInMinutes(Date.now(), deliveryRequest.requested_time);
        result = minutes + " min";
    }

    return result;
  };

  return (
    <React.Fragment>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={event => {
          handleCloseMenu();
        }}
      >
        {menuActions.filter((action) => action.available).map((action) => (
          <MenuItem
            onMouseDown={event => event.stopPropagation()}
            disabled={loadingBtn}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              action.onClick(deliveryRequest);
              handleCloseMenu();
            }}
          >
            {action.title}
          </MenuItem>
        ))}
      </Menu>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="top"
        className={classes.popper}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: "window",
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <Paper elevation={5}>
          <span className={classes.arrow} ref={setArrowRef} />
          <Box className={classes.popoverContent}>
            <Typography variant="body2" noWrap>
              {deliveryRequest.requested_time && `Hora da Solicitação: ${format(deliveryRequest.requested_time, CURRENT_DATE_TIME_FORMAT)}`}
            </Typography>
            <Typography variant="body2" noWrap>
              {deliveryRequest.is_scheduled ? "Agendado para: " : "Previsão de Entrega: "}
              {format(deliveryRequest.estimated_delivery_time, CURRENT_DATE_TIME_FORMAT)}
            </Typography>
          </Box>
        </Paper>
      </Popper>

      <Popper
        open={openPopoverReturn}
        anchorEl={anchorReturnRef.current}
        placement="top"
        className={classes.popper}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: "window",
          },
          arrow: {
            enabled: true,
            element: arrowReturnRef,
          },
        }}
      >
        <Paper elevation={5}>
          <span className={classes.arrow} ref={setArrowReturnRef} />
          <Box className={classes.popoverContent}>
            <Typography variant="body2" noWrap>
              {t("delivery_request_return_type." + deliveryRequest.return_type)}
            </Typography>
          </Box>
        </Paper>
      </Popper>

      <Box mb={1}>
        <Card variant="outlined" className={determineCardClassName()}>
          <CardContent className={classes.content}>
            <CardActionArea
              className={classes.actionArea}
              onClick={() => {
                if (onClick) {
                  onClick(deliveryRequest);
                }
              }}
            >
              <CardHeader
                style={{ padding: 0 }}
                avatar={
                  <CustomAvatar 
                    size="md" 
                    text={determineAvatarText()} 
                    className={clsx(classes.avatarNoCourier, {
                        [classes.callingCourierPhoto]: ((deliveryRequest.status === DeliveryRequestStatus.NO_COURIER) && (deliveryRequest.courier_id)),
                    })}
                    img={deliveryRequest.courier_photo || ""} />
                }
                action={
                  <IconButton
                    color="primary"
                    onMouseDown={event => event.stopPropagation()}
                    onClick={handleOpenMenu}>
                    <MoreVert />
                  </IconButton>
                }
                title={
                  <React.Fragment>
                    <Grid container xs={12} spacing={1} alignItems="center">
                      <Grid item>
                        <Chip size="small" label={statusChip.label} className={clsx(classes.statusChip, {
                            [classes.blink]: (deliveryRequest.status === DeliveryRequestStatus.NO_COURIER && deliveryRequest.courier_id)
                        })} />
                      </Grid>
                    </Grid>
                    <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                      <HighlightText text={`<b>${deliveryRequest.store.name}</b> - ${deliveryRequest.region.name}`} words={[highlight]} />
                    </Typography>
                  </React.Fragment>
                }
                subheader={
                  <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                    {deliveryRequest.called_ifood ? (
                      "Chamado Entregador do iFood"
                    ) : (
                      (deliveryRequest.status === DeliveryRequestStatus.NO_COURIER) && (!deliveryRequest.courier_id) ?
                        <HighlightText text="Sem entregador" words={[highlight]} />
                          : deliveryRequest.courier_name                      
                    )}
                  </Typography>
                }
              >
              </CardHeader>
              <Divider></Divider>
              <Grid container spacing={1} className={classes.item}>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.order} style={{alignItems: "center", display: "flex"}} noWrap>
                    <HighlightText
                      text={`<b>#${deliveryRequest.order_number}</b> ${deliveryRequest.integration_instance ? deliveryRequest.integration_instance.initials + " " : ""}- ${deliveryRequest.destination_address.district} (${numbro(deliveryRequest.distance).format()} km)`}
                      words={[highlight]}
                    />
                  {deliveryRequest.return_type && deliveryRequest.return_type !== "none" && (
                    <Grid wrap="nowrap" ref={anchorReturnRef}
                    onMouseEnter={handlePopoverReturnOpen}
                    onMouseLeave={handlePopoverReturnClose} style={{alignItems: "center", display: "flex"}}>
                      <CompareArrowsOutlined style={{marginLeft: "5px"}} />
                    </Grid>
                  )}
                  </Typography>
                </Grid>
                <Grid item xs={12} container wrap="nowrap" spacing={1} ref={anchorRef} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                  <Grid item>
                    <AccessTime fontSize="small" />
                  </Grid>
                  <Grid item xs zeroMinWidth>
                    <Typography variant="body2" noWrap>
                      {format(deliveryRequest.estimated_delivery_time, CURRENT_DATE_TIME_FORMAT)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {getIntegrationIcon(deliveryRequest.source)}
                  </Grid>
                </Grid>
                <ShowIf condition={deliveryRequest.is_scheduled && (deliveryRequest.status === DeliveryRequestStatus.NEW)}>
                  <Grid item xs={12} container justify="center" wrap="nowrap" spacing={1} ref={anchorRef} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                    <Grid item xs={12}>
                      <strong>Agendado</strong>
                    </Grid>
                  </Grid>
                </ShowIf>
              </Grid>
            </CardActionArea>
          </CardContent>
          {children}
        </Card>
      </Box>
    </React.Fragment >
  );
};

const useStyles = makeStyles({
  order: {
    fontSize: "15px"
  },
  delayedCard: {
    borderTopColor: pink.A400,
    borderTopWidth: theme.shape.borderRadius * 1.5,
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  actionArea: {
    padding: theme.spacing(1),
  },
  item: {
    margin: "0",
  },
  statusChip: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#384953 !important',
    color: theme.palette.secondary.contrastText,
  },
  avatarNoCourier: {
    backgroundColor: amber[700],
    textAlign: "center",
    borderRadius: "50%"
  },
  infoChip: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff !important',
    border: `1px solid ${theme.palette.primary.main} !important`,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  warnChip: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff !important',
    border: `1px solid #ff9800 !important`,
    fontWeight: "bold",
    color: "#ff9800",
  },
  popoverContent: {
    padding: theme.spacing(2),
  },
  popper: {
    zIndex: 2000,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "0 100%",
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "100% 0",
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "100% 100%",
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "0 0",
      },
    },
  },
  arrow: {
    overflow: "hidden",
    position: "absolute",
    width: "1em",
    height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: "border-box",
    color: theme.palette.background.paper,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      boxShadow: theme.shadows[1],
      backgroundColor: "currentColor",
      transform: "rotate(45deg)",
    },
  },
  blink: {
    animation: "blinker 2s linear infinite",
  },
  callingCourierPhoto: {
    filter: "grayscale(100%)",
  },
});
