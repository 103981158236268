/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Button,
} from "@material-ui/core";
import { CustomBreadcrumbs } from "../../../components/CustomBreadcrumbs";
import { CourierDetailCard } from "./CourierDetailCard";
import { CourierDetailData } from "./CourierDetailData";
import { CourierDetailDocs } from "./CourierDetailDocs";
import { CourierWallet } from "./CourierWallet";
import { CourierOrderHistory } from "./CourierOrderHistory";
import { LoadingPage } from "../../../components/Loading";
import { useHistory, useParams } from "react-router";
import { EditOutlined } from "@material-ui/icons";
import ChangeCourierStatus from "../../../components/ChangeCourierStatus";
import { ActionChangeStatusCourier, CourierAccountStatus, CourierDetails } from "../../../services/types/courier";
import { CourierDetailLogs } from "./CourierDetailLogs";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import CourierService from "../../../services/courierService";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturned';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import AddCreditsToCourierDialog, { AddCreditsToCourierDialogHandle } from "./AddCreditsToCourierDialog";
import { CourierDetailRanking } from "./CourierDetailRanking";
import AuthService from "../../../services/authService";
import ShowIf from "../../../components/ShowIf";
import { CourierUserDetailData } from "./CourierUserDetailData";
import { CourierRegisterOccurences } from "./CourierRegisterOccurrences";
import AddRegisterDebitCourierDialog from "./AddRegisterDebitCourierDialog";
import { CourierAnnouncement } from "./CourierAnnouncement";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../../components/ConfirmationDialog";
import { CourierIndications } from "./CourierIndications";
import ReferralService from "../../../services/referralService";
import { ReferralSettings } from "../../../services/types/referral";
import { decodeURLParams, encodeURLParams, URLParamType } from "../../../utils/utils";
import AntecipateRemittanceDialog, { AntecipateRemittanceDialogHandle } from "./AntecipateRemittanceDialog";

interface Action {
  label: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  action: () => any;
}

interface DetailCourierProps {
  courierId: string;
}

export const DetailCourier: React.FC = () => {
  const history = useHistory();
  const { courierId } = useParams<DetailCourierProps>();

  const [tab, setTab] = React.useState<string>("details");
  const [showChangeCourierStatusDialog, setShowChangeCourierStatusDialog] = React.useState(false);
  const [actionChangeCourierStatus, setActionChangeCourierStatus] = React.useState(null);
  const [isLoading, showLoading] = React.useState<boolean>(false);
  const [isRefreshing, setRefreshing] = React.useState<boolean>(false);
  const addCreditsDialog = React.createRef<AddCreditsToCourierDialogHandle>();
  const addRegisterDebitDialog = React.createRef<AddCreditsToCourierDialogHandle>();
  const anticipateRemittanceDialog = React.createRef<AntecipateRemittanceDialogHandle>();
  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();

  const [courier, setCourier] = React.useState<CourierDetails>(); 
  const [referralSettings, setReferralSettings] = React.useState<ReferralSettings>({} as ReferralSettings); 

  const getCourier = useCallback(async (id: number) => {
    showLoading(true);
    try {
      const courierDetails = await CourierService.getCourier(id);
      setCourier(courierDetails);
    } catch (error) {
      NotificationManager.error(
        "Erro ao tentar carregar os dados do Entregador.",
        "Entregador"
      );
    } finally {
      showLoading(false);
    }
  }, []);

  const handleEdit = useCallback(() => {
    history.push(`/courier/edit/${courierId}`);
  }, [courierId]);

  React.useLayoutEffect(() => {
    const result: any = decodeURLParams(history.location.search, [{"menu": URLParamType.STRING}]);
    if(result && result.menu){
      setTab(result.menu);
    }      
}, [history.location.search]);

  const handleChange = (event: React.ChangeEvent<{}>, valueTab: string) => {
    history.replace(encodeURLParams(history.location.pathname, {menu: valueTab}));
    setTab(valueTab);
  };

  const handleSendActivation = useCallback(async () => {
    try {
      setRefreshing(true);
      await CourierService.activateCourier(Number(courierId));
      NotificationManager.success("Ativação do Entregador realizada com sucesso.", "Ativação de Entregador");
      await getCourier(Number(courierId));
    } catch (error) {
      NotificationManager.error("Ocorreu um erro na Ativação do Entregador.", "Ativação de Entregador");
    } finally {
      setRefreshing(false);
    }
  }, [courierId]);

  const openChangeCourierStatusDialog = (action: any) => {
    setShowChangeCourierStatusDialog(true);
    setActionChangeCourierStatus(action);
  };

  const addCreditsDone = async (): Promise<void> => {
    try {
      await getCourier(Number(courierId));
    } finally {
      return Promise.resolve();
    }
  }

  const handleCourierBankValid = async () => {
    confirmationDialog.current?.confirm(
      courier?.valid_bank_data ? "Bloquear Conta Bancária" : "Liberar Conta Bancária",
      <Typography>Você tem certeza que deseja <strong>{courier?.valid_bank_data ? "Bloquear" : "Liberar"}</strong> esta conta Bancária?</Typography>,
      courier?.valid_bank_data ? "Bloquear" : "Liberar",
      "Cancelar"
    ).then(() => {
      const valid_bank = courier?.valid_bank_data ? false : true;
      CourierService.changeStatusCourierBank(Number(courierId), valid_bank).then((resp) =>{
        const dialog = confirmationDialog.current;
        NotificationManager.success("Ação realizada com sucesso.", "Sucesso");
        dialog?.hide();
        getCourier(Number(courierId));
      }).catch((error) => {
        NotificationManager.error("Não foi possível realizar essa ação.", "Falha");
      });

    }).catch(() => { });
  };

  interface COURIER_ACTION {
    label: string;
    icon?: React.ReactNode;
    disabled?: boolean;
    action: () => void;
  }

  const EDIT_ACTION: COURIER_ACTION = {
    label: "Editar",
    icon: <EditOutlined />,
    action: () => handleEdit()
  };
  const ACTIVATE_ACTION: COURIER_ACTION = {
    label: "Ativar",
    disabled: isRefreshing,
    action: () => handleSendActivation()
  };
  const DENY_REGISTRATION_ACTION: COURIER_ACTION = {
    label: "Recusar cadastro",
    action: () => openChangeCourierStatusDialog(ActionChangeStatusCourier.DENY_REGISTRATION_COURIER)
  };
  const DEACTIVATE_ACTION: COURIER_ACTION = {
    label: "Desativar",
    action: () => openChangeCourierStatusDialog(ActionChangeStatusCourier.DEACTIVATE_COURIER)
  };
  const SUSPEND_ACTION: COURIER_ACTION = {
    label: "Suspender",
    action: () => openChangeCourierStatusDialog(ActionChangeStatusCourier.SUSPEND_COURIER)
  };
  const BAN_ACTION: COURIER_ACTION = {
    label: "Banir",
    action: () => openChangeCourierStatusDialog(ActionChangeStatusCourier.BAN_COURIER)
  };
  const REACTIVATE_ACTION: COURIER_ACTION = {
    label: "Reativar",
    action: () => openChangeCourierStatusDialog(ActionChangeStatusCourier.REACTIVATE_COURIER)
  }; 
  const RELEASE_BANK_ACCOUNT_COURIER: COURIER_ACTION = {
    label: courier?.valid_bank_data ? "Bloquear C. bancária" : "Liberar C. bancária",
    action: () => handleCourierBankValid()
  };

  const availableActions = (accountStatus?: CourierAccountStatus): Action[] => {
    let result: Action[] = [];

    if (AuthService.hasAuthorization("change_courier")) {
      result.push(EDIT_ACTION);
    }
    switch (accountStatus) {
      case CourierAccountStatus.REGISTRATION_ANALYSIS:
        if (AuthService.hasAuthorization("activate_courier")) {
          result.push(ACTIVATE_ACTION);
        }
        if (AuthService.hasAuthorization("deactivate_courier")) {
          result.push(DENY_REGISTRATION_ACTION);
        }
        break;
      case CourierAccountStatus.ACTIVE:
        if (AuthService.hasAuthorization("deactivate_courier")) {
          result.push(DEACTIVATE_ACTION);
        }
        if (AuthService.hasAuthorization("suspend_courier")) {
          result.push(SUSPEND_ACTION);
        }
        if (AuthService.hasAuthorization("ban_courier")) {
          result.push(BAN_ACTION);
        }
        if (AuthService.hasAuthorization("release_bank_account")) {
          result.push(RELEASE_BANK_ACCOUNT_COURIER);
        }
        break;
      case CourierAccountStatus.REGISTRATION_DENIED:
        result.push(ACTIVATE_ACTION);
        if (AuthService.hasAuthorization("deactivate_courier")) {
          result.push(DENY_REGISTRATION_ACTION);
        }
        break;
      case CourierAccountStatus.DATA_UPDATED:
        if (AuthService.hasAuthorization("deactivate_courier")) {
          result.push(DENY_REGISTRATION_ACTION);
        }
        if (AuthService.hasAuthorization("activate_courier")) {
          result.push(REACTIVATE_ACTION);
        }
        if (AuthService.hasAuthorization("release_bank_account")) {
          result.push(RELEASE_BANK_ACCOUNT_COURIER);
        }
        break;
      case CourierAccountStatus.SUSP_DATA_UPDATED:
        if (AuthService.hasAuthorization("deactivate_courier")) {
          result.push(DENY_REGISTRATION_ACTION);
        }
        if (AuthService.hasAuthorization("ban_courier")) {
          result.push(BAN_ACTION);
        }
        if (AuthService.hasAuthorization("activate_courier")) {
          result.push(REACTIVATE_ACTION);
        }
        if (AuthService.hasAuthorization("release_bank_account")) {
          result.push(RELEASE_BANK_ACCOUNT_COURIER);
        }
        break;
      case CourierAccountStatus.SUSP_DECLINE:
      case CourierAccountStatus.SUSP_INACTIVE:
      case CourierAccountStatus.SUSP_OTHERS:
        if (AuthService.hasAuthorization("ban_courier")) {
          result.push(BAN_ACTION);
        }
        if (AuthService.hasAuthorization("activate_courier")) {
          result.push(REACTIVATE_ACTION);
        }
        if (AuthService.hasAuthorization("release_bank_account")) {
          result.push(RELEASE_BANK_ACCOUNT_COURIER);
        }
        break;
      default:
        break;
    }

    return result;
  };

  const getConfigIndications = useCallback(async () => {
    try {
      const referralSettings = await ReferralService.getSettings();
      setReferralSettings(referralSettings);
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (courierId) {
      getCourier(Number(courierId));
      getConfigIndications();
    }
  }, [courierId]);

  return (
    <div>
      <AddCreditsToCourierDialog
        ref={addCreditsDialog}
        courierId={Number(courierId)}
        onAccept={addCreditsDone}
      />
       <AddRegisterDebitCourierDialog
        ref={addRegisterDebitDialog}
        courierId={Number(courierId)}
        onAccept={addCreditsDone}
      />
      <AntecipateRemittanceDialog
        ref={anticipateRemittanceDialog}
        courierId={Number(courierId)}
        onAccept={addCreditsDone}
      />
      <ConfirmationDialog ref={confirmationDialog} />

      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Detalhes do Entregador</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[
                { label: "Entregadores", url: "/courier" },
                {
                  label: "Detalhes do Entregador",
                  url: `/courier/detail/${courierId}`,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>

      {isLoading ? (
        <LoadingPage />
      ) : (
        <React.Fragment>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            className="tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Detalhes" value={"details"} />
            <Tab label="Carteira" value={"wallet"} />
            <Tab label="Histórico de entregas" value={"history"} />
            <Tab label="Ranking" value={"ranking"} />
            <Tab label="Usuário" value={"user"} />
            <Tab label="Registro de Ocorrências" value={"occurrences"} />
            <Tab label="Notificações" value={"announcement"} />
            <Tab label="Indicações" value={"indications"} />
          </Tabs>

          {tab === "details" && (
            <Grid container spacing={2}>
              <Grid item container xs={12} spacing={1} justify="flex-end">
                {availableActions(courier?.courier_account_status).map(item => (
                  <Grid item>
                    <Button color="secondary" variant="contained"
                      startIcon={item.icon}
                      style={{
                        width: "165px",
                      }}
                      disabled={item.disabled}
                      onClick={item.action}>
                      {item.label}
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <Grid item md={4}>
                <CourierDetailCard courier={courier} />
                <CourierDetailLogs />
              </Grid>
              <Grid item md={8}>
                <CourierDetailData courier={courier} />
                <CourierDetailDocs courier={courier} />
              </Grid>
            </Grid>
          )}

          {tab === "wallet" && (
            <Grid container spacing={2}>
              <Grid item container xs={12} spacing={1} justify="flex-end">
                <Grid item>
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{
                        width: "160px",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => { anticipateRemittanceDialog.current?.open(); }}
                      startIcon={<AccountBalanceWallet />}
                    >
                      Adiantamento
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{
                        width: "160px",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => { addRegisterDebitDialog.current?.open(); }}
                      startIcon={<AssignmentReturnIcon />}
                    >
                      Registrar Débito
                    </Button>
                </Grid>
                <Grid item>
                  <ShowIf condition={AuthService.hasAuthorization("add_courier_credits")}>
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{
                        width: "160px",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => { addCreditsDialog.current?.open(); }}
                      startIcon={<AttachMoneyIcon />}
                    >
                      Adicionar Crédito
                    </Button>
                  </ShowIf>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <CourierWallet courier={courier} />
              </Grid>
            </Grid>
          )}

          {tab === "history" && (
            <Grid container spacing={2}>
              <Grid item md={12}>
                <CourierOrderHistory courierId={Number(courierId)} />
              </Grid>
            </Grid>
          )}

          {tab === "ranking" && (
            <Grid container spacing={2}>
              <Grid item md={12}>
                <CourierDetailRanking courierId={Number(courierId)} />
              </Grid>
            </Grid>
          )}

          {tab === "user" && (
            <Grid container spacing={2}>
              <Grid item md={12}>
                <CourierUserDetailData courierId={Number(courierId)} />
              </Grid>
            </Grid>
          )}

          {tab === "occurrences" && (
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <CourierRegisterOccurences courierId={Number(courierId)} />
                </Grid>
            </Grid>
          )}

          {tab === "announcement" && (
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <CourierAnnouncement courierId={Number(courierId)} tripId={null} deliverySelected={null} />
                </Grid>
            </Grid>
          )}
          {tab === "indications" && (
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <CourierIndications courierId={Number(courierId)} referralSettings={referralSettings} />
                </Grid>
            </Grid>
          )}
        </React.Fragment>
      )}
      <ChangeCourierStatus
        modal={{
          open: showChangeCourierStatusDialog,
          setOpen: setShowChangeCourierStatusDialog,
        }}
        courierId={Number(courierId)}
        action={actionChangeCourierStatus}
      />
    </div >
  );
};
