import React, { useEffect, useLayoutEffect, useState } from "react";
import { makeStyles, createStyles, Theme, Grid, IconButton, Accordion, AccordionSummary, Typography, AccordionDetails, Button, Modal, Fade, TextField, MenuItem, Backdrop, AccordionActions, Card, debounce, Chip } from "@material-ui/core";

import {
  DirectionsBikeOutlined,
  DirectionsCarOutlined,
  MotorcycleOutlined,
  Storefront,
  HouseOutlined,
  Timeline,
  ArrowDownward
} from "@material-ui/icons";

import { format, formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";

import { InfoCardCourier } from "./CustomComponents/InfoCardCourier";
import { InfoCardCustomer } from "./CustomComponents/InfoCardCustomer";
import { MapContainer, TileLayer, ZoomControl, Tooltip, CircleMarker } from "react-leaflet";
import LeafletMarker from "../../../components/maps/LeafletMarker";
import L from "leaflet";
import { DeliveryRequest, DeliveryRequestAction, DeliveryRequestStatus, Trip, TripTrackingPoint } from "../../../services/types/deliveryRequest";
import CustomControl from "../../../components/maps/CustomControl";
import { CURRENT_DATE_TIME_FORMAT } from "../../../i18n";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import clsx from "clsx";
import TripService from "../../../services/tripService";
import CustomTableTrip from "./CustomComponents/CustomTableTrip";
import { Alert } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SecurityIcon from "@material-ui/icons/Security";
import { mapDeliveryRequestStatusToSeverity } from "./CustomComponents/deliveryRequestUtils";
import { useTranslation } from "react-i18next";
import DeliveryRequestService from "../../../services/deliveryRequestService";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import ShowIf from "../../../components/ShowIf";
import AuthService from "../../../services/authService";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../../components/ConfirmationDialog";
import numbro from "numbro";
import CorrectDeliveryValueDialog, { CorrectDeliveryValueDialogHandle } from "../SubPages/CustomComponents/CorrectDeliveryValueDialog";
import NewAnnouncementDialog, { NewAnnouncementDialogHandle } from "../../announcement/NewAnnouncementDialog";


interface SendDeliveryRequestAnnouncementForm {
  templateId: number | null;
  title: string;
  content: string;
}

interface IMarkerCoord {
  type: string;
  title: string;
  lat: number;
  lng: number;
}

interface IMapTripProps {
  trip: Trip;
  deliveryList: DeliveryRequest[] | undefined;
  isActive: boolean;
  refreshData: () => void;
}

export const MapTrip: React.FC<IMapTripProps> = ({ trip, deliveryList, isActive, refreshData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [markerCoords, setMarkerCoords] = useState<IMarkerCoord[]>();
  const [trackingPoints, setTrackingPoints] = useState<TripTrackingPoint[] | null>(null);
  const [mapBounds, setMapBounds] = useState<[number, number][]>();
  const [map, setMap] = useState<L.Map>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [firstDeliveryRequest, setFirstDeliveryRequest] = useState<DeliveryRequest>();
  const [deliverySelected, setDeliverySelected] = React.useState<DeliveryRequest | null>();
  const [availableChoicesDelivery, setAvailableChoicesDelivery] = React.useState<DeliveryRequestAction[]>([]);
  const [openIntervirDelivery, setOpenIntervirDelivery] = React.useState(false);
  const [actionIntervirDelivery, setActionIntervirDelivery] = React.useState<string>("");
  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
  const correctValueDeliveryDialog = React.createRef<CorrectDeliveryValueDialogHandle>();
  const newAnnouncementDialogHandle = React.createRef<NewAnnouncementDialogHandle>();
  const [courierId, setCourierId] = React.useState<number>(0);

  const [totalMinimum, setTotalMinimum] = React.useState<number>(0);
  const [totalValueAddKm, setTotalValueAddKm] = React.useState<number>(0);
  const [totalIncentivesCustomer, setTotalIncentivesCustomer] = React.useState<number>(0);
  const [totalIncentivesSpeedy, setTotalIncentivesSpeedy] = React.useState<number>(0);
  const [totalReturn, setTotalReturn] = React.useState<number>(0);
  const [totalValueCourier, setTotalValueCourier] = React.useState<number>(0);
  const [totalValueSpeedy, setTotalValueSpeedy] = React.useState<number>(0);
  const [totalIncentives, setTotalIncentives] = React.useState<number>(0);
  const [valueTotalTrip, setValueTotalTrip] = React.useState<number>(0);

  useEffect(() => {
    if (deliveryList) {
      setFirstDeliveryRequest(deliveryList[0]);

      let valueTotalMinimum = 0;
      let incentivesCustomer = 0;
      let incentivesSpeedy = 0;
      let valueTotalReturn = 0;
      let valueTotalAddKm = 0;
      let totalIncentives = 0;

      let totalValueCourier = 0;
      let totalValueSpeedy = 0;
      if(deliveryList.length > 0){
           deliveryList?.forEach(item => {
              valueTotalMinimum += item.minimum_value ? item.minimum_value : 0;              
              valueTotalReturn += item.return_value ? item.return_value : 0;
              valueTotalAddKm += item.additional_km_value ? item.additional_km_value : 0;
              if(item.incentive_json){
                incentivesCustomer += item.incentive_json.customer_value;
                incentivesSpeedy += item.incentive_json.speedy_value;
                totalIncentives = incentivesCustomer + incentivesSpeedy;
              };
              totalValueCourier += item.courier_value ? item.courier_value : 0;
              totalValueSpeedy += item.speedy_value ? item.speedy_value : 0;
           });
      };    
      setTotalMinimum(valueTotalMinimum);
      setTotalValueAddKm(valueTotalAddKm);
      setTotalIncentivesCustomer(incentivesCustomer);
      setTotalIncentivesSpeedy(incentivesSpeedy);
      setTotalReturn(valueTotalReturn);    
      setTotalValueCourier(totalValueCourier);
      setTotalValueSpeedy(totalValueSpeedy);
      setTotalIncentives(totalIncentives);
      setValueTotalTrip(valueTotalMinimum + valueTotalAddKm + totalIncentives + valueTotalReturn); 
    }
  }, [deliveryList]);

  const loadTrackingPoints = React.useCallback(() => {
    if (firstDeliveryRequest && firstDeliveryRequest.current_courier) {
      setLoading(true);
      TripService.getTripTracking(trip.id, firstDeliveryRequest.current_courier.id)
        .then((response) => {
          setTrackingPoints(response);
        })
        .catch((error) => {
          setTrackingPoints(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [firstDeliveryRequest, trip.id]);

  const toggleTrackingPoints = React.useCallback(() => {
    if (trackingPoints === null) {
      loadTrackingPoints();
    } else {
      setTrackingPoints(null);
    }
  }, [loadTrackingPoints, trackingPoints]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (trackingPoints !== null && isActive) {
        loadTrackingPoints();
      }
      refreshData();
    }, 20000);

    return function cleanup() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [refreshData, loadTrackingPoints, trackingPoints, isActive]);

  useEffect(() => {

  }, [trip]);

  useEffect(() => {
    if (deliveryList) {
      const _bounds: [number, number][] = [];
      const _coordsMarker: IMarkerCoord[] = [];

      if (trip?.courier_position) {
        const title = `Entregador ${trip.courier_position.updated_at ? "- Localização atualizada há " + formatDistanceToNow(trip.courier_position.updated_at, { locale: ptBR }) : ""}`;
        _bounds.push([trip.courier_position?.latitude, trip.courier_position?.longitude]);
        _coordsMarker.push({
          type: trip.courier?.vehicle_type,
          title: title,
          lat: trip.courier_position?.latitude,
          lng: trip.courier_position?.longitude,
        });
      }

      if (deliveryList.length > 0) {
        deliveryList.forEach(function (delivery) {
          _bounds.push([delivery.origin_address?.latitude, delivery.origin_address?.longitude])
          _coordsMarker.push({
            type: "STORE",
            title: "Loja",
            lat: delivery?.origin_address?.latitude,
            lng: delivery?.origin_address?.longitude,
          });

          const titleHouse = `#${delivery?.order_number} - ${delivery?.customer.customer_name}`;
          _bounds.push([delivery?.destination_address?.latitude, delivery?.destination_address?.longitude]);
          let typeHouse = "HOUSE";
          if (delivery.status === DeliveryRequestStatus.FINISHED) {
            typeHouse = "HOUSE_FINISH";
          }else if(delivery.status === DeliveryRequestStatus.RETURNING){
            typeHouse = "HOUSE_RETURNING"
          }else if(delivery.status === DeliveryRequestStatus.CANCELED
            || delivery.status === DeliveryRequestStatus.DISCARDED) {
            typeHouse = "HOUSE_CANCELED";
          } else {
            typeHouse = "HOUSE"
          }
          _coordsMarker.push({
            type: typeHouse,
            title: titleHouse,
            lat: delivery?.destination_address?.latitude,
            lng: delivery?.destination_address?.longitude,
          });

        });
      }
      setMapBounds(_bounds);
      setMarkerCoords(_coordsMarker);
    }
  }, [deliveryList, trip]);

  useLayoutEffect(() => {
    if (map && mapBounds) {
      try {
        map.getBounds();
      } catch (error) {
        map.fitBounds(mapBounds, { paddingTopLeft: [20, 40], paddingBottomRight: [20, 0] });
      }
    }
  }, [map, mapBounds, trackingPoints]);

  const iconMap = [
    {
      type: "CAR",
      icon: <DirectionsCarOutlined />,
      color: "#8E02F5",
    },
    {
      type: "BIKE",
      icon: <DirectionsBikeOutlined />,
      color: "#8E02F5",
    },
    {
      type: "MOTORCYCLE",
      icon: <MotorcycleOutlined />,
      color: "#8E02F5",
    },
    {
      type: "STORE",
      icon: <Storefront />,
      color: "#0080FF",
    },
    {
      type: "HOUSE",
      icon: <HouseOutlined />,
      color: "#0080FF",
    },
    {
      type: "HOUSE_FINISH",
      icon: <HouseOutlined />,
      color: "#4caf50",
    },
    {
      type: "HOUSE_CANCELED",
      icon: <HouseOutlined />,
      color: "#d74545",
    },
    {
      type: "HOUSE_RETURNING",
      icon: <HouseOutlined />,
      color: "#eb7405",
    }
  ];

  const handleOpenIntervirDelivery = (delivery: DeliveryRequest) => {
    if (delivery) {
      setActionIntervirDelivery("");
      getAvailableActionsDelivery(delivery);
      setDeliverySelected(delivery);
      setOpenIntervirDelivery(true);
    }
  };

  const handleCloseModalDelivery = () => {
    setActionIntervirDelivery("");
    setOpenIntervirDelivery(false);
    setDeliverySelected(null);

  };

  const getAvailableActionsDelivery = (delivery: DeliveryRequest) => {
    setAvailableChoicesDelivery(
      DeliveryRequestService.getAvailableDeliveryActions(delivery.status)
    );
  };

  const interverDelivery = async () => {
    if (actionIntervirDelivery && deliverySelected) {
      try {
        switch (actionIntervirDelivery) {
          case DeliveryRequestAction.REGISTER_ARRIVAL:
          case DeliveryRequestAction.REGISTER_DELIVER:
            await DeliveryRequestService.interveneInDeliveryRequest(deliverySelected.id, actionIntervirDelivery, "Intervenção Manual");
            NotificationManager.success(
              "Intervenção criada com sucesso",
              "Nova Intervenção"
            );
            break;
        }
        refreshData();
      } catch (error) {
        let errorMsg = "Ocorreu um erro ao cadastrar a intervenção";
        /*  const errorKeys = Object.keys(error.response.data);
         if (errorKeys.length) {
           errorMsg = error.response.data[errorKeys[0]];
         } */

        NotificationManager.error(errorMsg, "Nova Intervenção");
      } finally {
        handleCloseModalDelivery();
      }
    }
  }

  const refundDeliveryRequest = (deliveryId: number) => {
    const dialog = confirmationDialog.current;
    dialog?.confirm(
      "Estorno de Solicitação de Entrega",
      <React.Fragment>
        <Typography>
          Você tem certeza que deseja <strong>Estornar</strong> esta Solicitação de Entrega?
        </Typography>
        <Typography>
          Obs.: Não será possível reverter esta ação.
        </Typography>
      </React.Fragment>,
      "Estornar",
      "Cancelar")
      .then(async () => {
        DeliveryRequestService.refundDeliveryRequestById(Number(deliveryId))
          .then((response) => {
            refreshData();
            dialog?.hide();
            NotificationManager.success(
              "Solicitação de Entrega estornada com sucesso.",
              "Estorno de Solicitação de Entrega"
            );
          }).catch((error) => {
            NotificationManager.error(
              "Erro ao estornar a Solicitação de Entrega.",
              "Estorno de Solicitação de Entrega"
            );
          });
      }).catch(() => { });
  }

  const handleClickOpenAviso = (delivery: DeliveryRequest) => {
    if(delivery){
      setDeliverySelected(delivery);
      setCourierId(delivery.current_courier.id);
      newAnnouncementDialogHandle.current?.open();
    }
    
  };

  const handleCurrectValueDelivery = (delivery: DeliveryRequest) => {
    if (delivery) {
      setDeliverySelected(delivery);
      correctValueDeliveryDialog.current?.open();
    }
  };

  const correctDeliveryValueDone = async (): Promise<void> => {
    try {
      setDeliverySelected(null);
      refreshData(); 
    } finally {
      return Promise.resolve();
    }
  };

  const handleSplitDeliveryRequest = (tripId: number, deliveryId: number) => {
    const dialog = confirmationDialog.current;
    dialog?.confirm(
        "Separar Pedido de Entrega",
        "Você tem certeza que deseja Separar esse Pedido de Entrega?",
        "Separar",
        "Cancelar"
    ).then(async () => {
        try {
            await handleConfirmSplitTrip(tripId, deliveryId);
        } finally {
            dialog.hide();
        }
    }).catch(() => {
    });
  };

  
  const finishAdd = async (): Promise<void> => {
    try {
      await refreshData(); 
    } finally {
      return Promise.resolve();
    }
  };


  const handleConfirmSplitTrip = React.useMemo(() => debounce(async (tripIdSplit: number, deliveryIdSplit: number) => {
    if (tripIdSplit && deliveryIdSplit) {
        try {
            await TripService.removeDeliveryFromTrip(tripIdSplit, deliveryIdSplit);
            await refreshData();
            NotificationManager.success(
                "Entrega separada com sucesso!",
                "Sucesso"
            );
        } catch (error) {
            NotificationManager.error(
                "Não foi possível separar a entrega!",
                "Error"
            );
        }
    }
  }, 500), [refreshData]);

  const checkInsurance = (delivery: DeliveryRequest) => {
    if(!delivery.canceled_on_insurance_service && !delivery.registered_on_insurance_service){
      return "Sem Seguro";
    }else if(!delivery.canceled_on_insurance_service && delivery.registered_on_insurance_service){
      return "Com Seguro";
    }else if(delivery.canceled_on_insurance_service && delivery.registered_on_insurance_service){
      return "Seguro Cancelado";
    };
  };

  const formatIncentive = (incentiveCustomer: number, incentiveSpeedy: number) => {
    const total = incentiveCustomer + incentiveSpeedy;
    let result = "";
    if (total > 0) {
      result = ` + ${numbro(total).formatCurrency()} (Incentivos)`;
    }
    return result;
  }

  return (
    <React.Fragment>
      <LoadingOverlay open={isLoading} />

      <ConfirmationDialog ref={confirmationDialog} />

      <NewAnnouncementDialog ref={newAnnouncementDialogHandle}      
        couriers={[courierId]}
        tripId={trip.id}
        deliverySelected={deliverySelected ? deliverySelected : null}
        onAccept={finishAdd}
        onCancel={finishAdd} />

      <Grid container spacing={2}>
        <Grid item md={7}>
          <div className={classes.mapWrapper}>
            <MapContainer scrollWheelZoom={false} whenCreated={setMap} zoomControl={false}>
              <ZoomControl position="bottomright" />
              <CustomControl position="topright">
                <IconButton onClick={toggleTrackingPoints} title="Rastreamento"
                  className={clsx([{ [classes.activeTrackingButton]: !!trackingPoints }])}>
                  <Timeline />
                </IconButton>
              </CustomControl>
              <TileLayer
                attribution={'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {markerCoords?.map((marker, index) => {
                const iconType = iconMap.find(
                  (elem) => elem.type === marker.type
                );
                return (
                  <LeafletMarker
                    key={index}
                    position={[Number(marker.lat), Number(marker.lng)]}
                    color={iconType?.color}
                    internalIcon={iconType?.icon}
                    riseOnHover={true}
                  >
                    <Tooltip>{marker.title}</Tooltip>
                  </LeafletMarker>
                );
              })}
              {trackingPoints?.map((point, index) => {
                return (
                  <CircleMarker center={[point.latitude, point.longitude]} radius={5}
                    pathOptions={{ color: "red", fill: true, fillOpacity: .4 }}>
                    <Tooltip>{format(point.created_at, CURRENT_DATE_TIME_FORMAT)}</Tooltip>
                  </CircleMarker>
                );
              })}
            </MapContainer>
          </div>
        </Grid>

        <Grid item md={5}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <InfoCardCourier courier={trip?.courier} called_iFood={trip.called_ifood} />
            </Grid>
            <Grid item>
              <InfoCardCustomer content={firstDeliveryRequest?.customer} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Card style={{paddingLeft: "16px"}}>
                <Grid container spacing={2} className={classes.even_row}>      
                  <Grid item xl={2} md={2} sm={4} xs={12}>
                    <Typography variant="body1">Valor Mínimo</Typography>
                    <Typography variant="subtitle1">
                      {totalMinimum !== 0 ? numbro(totalMinimum).formatCurrency() : "---"}
                    </Typography>
                  </Grid>
                  <Grid item xl={2} md={2} sm={4} xs={12}>
                    <Typography variant="body1">Valor do Deslocamento</Typography>
                    <Typography variant="subtitle1">
                      {totalValueAddKm !== 0 ? numbro(totalValueAddKm).formatCurrency() : "---"}
                    </Typography>
                  </Grid>
                  <Grid item xl={2} md={2} sm={4} xs={12}>
                    <Typography variant="body1">Incentivos</Typography>
                    <Typography variant="subtitle1">
                      {totalIncentives ? `${numbro(totalIncentivesCustomer).formatCurrency()} (Cliente) + ${numbro(totalIncentivesSpeedy).formatCurrency()} (Speedy)` : "---"}
                    </Typography>
                  </Grid>
                  <Grid item xl={2} md={2} sm={4} xs={12}>
                    <Typography variant="body1">Valor de Retorno</Typography>
                    <Typography variant="subtitle1">
                      {totalReturn !== 0 ? numbro(totalReturn).formatCurrency() : "---"}
                    </Typography>
                  </Grid>
                  <Grid item xl={totalIncentives ? 2 : 1} md={totalIncentives ? 2 : 1} sm={4} xs={12} className={classes.backgroundResult}>
                    <Typography variant="body1">Entregador</Typography>
                    <Typography variant="subtitle1">
                      {totalValueCourier !== null ? `${numbro(totalValueCourier).formatCurrency()}` : "---"}
                      {formatIncentive(totalIncentivesCustomer, totalIncentivesSpeedy)}
                    </Typography>
                  </Grid>
                  <Grid item xl={1} md={1} sm={4} xs={12} className={classes.backgroundResult}>
                    <Typography variant="body1">Speedy</Typography>
                    <Typography variant="subtitle1">
                      {totalValueSpeedy !== null ? `${numbro(totalValueSpeedy).formatCurrency()}` : "---"}
                    </Typography>
                  </Grid>
                  <Grid item xl={totalIncentives ? 1 : 2}  md={totalIncentives ? 1 : 2} sm={4} xs={12} className={classes.backgroundResult}>
                    <Typography variant="body1">Valor da Entrega</Typography>
                    <Typography variant="subtitle1">
                      {valueTotalTrip !== 0 ? numbro(valueTotalTrip).formatCurrency() : "---"}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          {deliveryList &&
            deliveryList.sort((a, b) => (a.id > b.id) ? 1 : -1).map((delivery, index) => (
              <React.Fragment>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container spacing={4} wrap="nowrap" alignItems="center">
                      <Grid item xl={6} md={8}>
                        <Typography className={classes.heading}>
                          <b>{index + 1}°. </b>&nbsp;
                          <label>
                            <b>#{delivery.order_number}</b> {delivery.integration_instance ? delivery.integration_instance.initials + " " : ""}- {delivery.destination_address.district} ({numbro(delivery.distance).format() + " km"}, {delivery?.return_type === "none" ? "sem retorno" : <b>com retorno</b>})
                          </label>
                          <Chip icon={<SecurityIcon />} variant="outlined" className={clsx({
                                                [classes.chipSuccess]: !delivery.canceled_on_insurance_service && delivery.registered_on_insurance_service,
                                                [classes.chipWarning]: !delivery.canceled_on_insurance_service && !delivery.registered_on_insurance_service,
                                                [classes.chipDanger]: delivery.canceled_on_insurance_service && delivery.registered_on_insurance_service,
                                            })} label={checkInsurance(delivery)} color="secondary" />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Alert
                          className={classes.situationAccord}
                          severity={mapDeliveryRequestStatusToSeverity(delivery.status)}
                          icon={false}
                        >
                          {t("delivery_request_status." + delivery.status)}
                        </Alert>
                      </Grid>
                    </Grid>
                  </AccordionSummary>

                  <AccordionActions style={{ paddingTop: 0 }}>
                    <Grid container spacing={1} xs={12}>

                    {[DeliveryRequestStatus.NEW, DeliveryRequestStatus.NO_COURIER].includes(delivery.status) && deliveryList.length > 1 && (
                        <Grid item>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                              handleSplitDeliveryRequest(trip.id, delivery.id);
                            }}
                          >
                            Separar Entrega
                          </Button>
                        </Grid>
                      )}
                      {[DeliveryRequestStatus.ON_WAY, DeliveryRequestStatus.AT_DESTINY].includes(delivery.status) && (
                        <Grid item>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                              handleOpenIntervirDelivery(delivery);
                            }}
                          >
                            Intervir no fluxo de Entrega
                          </Button>
                        </Grid>
                      )}
                      <ShowIf condition={AuthService.hasAuthorization("refund_deliveryrequest")}>
                        {(!isActive && !delivery?.refunded && [
                          DeliveryRequestStatus.CANCELED,
                          DeliveryRequestStatus.FINISHED
                        ].includes(delivery.status)) && (
                            <Grid item>
                              <Button variant="contained" color="secondary"
                                onClick={() => { refundDeliveryRequest(delivery.id); }}>
                                Estornar Solicitação
                              </Button>
                            </Grid>
                          )}
                      </ShowIf>
                      {(delivery && ![
                        DeliveryRequestStatus.NEW,
                        DeliveryRequestStatus.NO_COURIER,
                        DeliveryRequestStatus.CANCELED,
                        DeliveryRequestStatus.FINISHED
                      ].includes(delivery.status)) && (
                          <Grid item>
                            <Button variant="contained" color="secondary"
                              onClick={() => { handleClickOpenAviso(delivery); }}>
                              Enviar Aviso
                            </Button>
                          </Grid>
                        )}
                       {(delivery && [DeliveryRequestStatus.FINISHED].includes(delivery.status)) && (
                          <Grid item>
                            <Button variant="contained" color="secondary"
                              onClick={() => { handleCurrectValueDelivery(delivery); }}>
                              Corrigir taxa de Entrega
                            </Button>
                          </Grid>
                        )} 
                    </Grid>
                  </AccordionActions>

                  <AccordionDetails style={{ display: "block" }}>
                    <CustomTableTrip data={delivery} />
                  </AccordionDetails>
                </Accordion>
              </React.Fragment>
            ))}
        </Grid>
      </Grid>
      <CorrectDeliveryValueDialog
        ref={correctValueDeliveryDialog}      
        deliveryId={deliverySelected ? Number(deliverySelected?.id) : 0}
        onAccept={correctDeliveryValueDone}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openIntervirDelivery}
        onClose={handleCloseModalDelivery}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openIntervirDelivery}>
          <div className={classes.paper}>
            <form>
              <Typography variant="h1">Intervenção Corrida</Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#EF4545",
                  maxWidth: 630,
                  padding: "10px 0",
                }}
              >
                A intervenção manual no fluxo da entrega só deverá ser
                utilizada em casos excepcionais onde o estabelecimento e/ou
                o entregador não atualizaram o status. Esta ação ficará
                registrada.
              </Typography>
              <Grid item md={12} className={classes.formActions}>
                <ArrowDownward
                  style={{
                    alignSelf: "center",
                    fontSize: "2rem",
                    marginTop: "1rem",
                  }}
                />
                <Grid item md={12} className={classes.textField}>
                  <TextField
                    id="action"
                    name="action"
                    label="Ação a ser realizada"
                    variant="outlined"
                    select
                    value={actionIntervirDelivery || ""}
                    onChange={(e) => {
                      if (e.target.value) {
                        setActionIntervirDelivery(e.target.value);
                      }
                    }}
                    fullWidth
                  >
                    <MenuItem value="" disabled>Selecione</MenuItem>
                    {availableChoicesDelivery.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {t("delivery_request_action." + item)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item md={12} className={classes.buttonWrapper}>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="button"
                    className={classes.button}
                    onClick={handleCloseModalDelivery}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={actionIntervirDelivery === ""}
                    onClick={interverDelivery}
                    className={classes.button}
                  >
                    Intervir
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstContainer: {
      display: "flex",
    },
    map: {
      width: "100%",

      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    mapWrapper: {
      position: "relative",
      width: "100%",
      borderRadius: "4px",
      overflow: "hidden",
      height: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
    },
    activeTrackingButton: {
      color: theme.palette.error.main,
    },
    situation: {
      padding: "0px 10px",
    },
    situationAccord: {
      padding: "0px 10px",
      display: "inline-block"
    },
    heading: {
      fontSize: theme.typography.pxToRem(20),
      color: theme.palette.text.secondary,
    },
    alinharCentro: {
      position: "absolute",
      top: "50%",
      transform: "translate(0, -50%)",
    },
    cardTitle: {
      fontSize: "24px",
      marginBottom: "0px",
      fontWeight: "bold",
    },
    button: {
      marginTop: "30px",
      marginLeft: "20px",
      minWidth: "150px",
      padding: "8px 30px",
      textTransform: "capitalize",
      borderRadius: "4px",
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "100%",
    },
    textField: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: "#fff",
      borderRadius: "4px",
      width: "100%",
      "& .MuiFormLabel-root": {
        color: theme.palette.primary.light,
      },
      "& label.Mui-focused": {
        color: theme.palette.primary.main,
      },
      "& .MuiInput-underline": {
        "&:before": {
          borderColor: theme.palette.primary.light,
        },
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "none",
      outline: "none",
      borderRadius: "4px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4, 4, 3),
    },
    formActions: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "strech",
    },
    buttonProgress: {
      position: "absolute",
    },
    infoContainer: {
      display: "flex",

      "& div:nth-of-type(2)": {
        marginLeft: "15px",
      },
    },
    chipSuccess: {
      color: "#4caf50",
      border: "1px solid #4caf50",
      marginLeft: "10px",
    },
    chipWarning: {
      color: "#ff9800",
      border: "1px solid #ff9800",
      marginLeft: "10px",
    },
    chipDanger: {
      color: "#f44336",
      border: "1px solid #f44336",
      marginLeft: "10px",
    },
    even_row: {
      padding: "8px 0px;",
    },
    backgroundResult: {
      background: "#ebebeb",
    },
  })
);
