import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { Link, useHistory } from "react-router-dom";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { CustomerFilter, ICustomerFilterParams, INITIAL_FILTER_PARAMS } from "./CustomerFilter";
import CustomerService from "../../services/customerService";
import CityService from "../../services/cityService";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { CustomerResponse } from "../../services/types/customer";
import { DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import { formatCityName, formatCPFCNPJ, formatPhoneNumber } from "../../utils/formatters";
import { VisibilityOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import ShowIf from "../../components/ShowIf";
import AuthService from "../../services/authService";
import { City } from "../../services/types/common";
import { decodeURLParams } from "../../utils/utils";

interface ICustomerProps { }

export const Customer: React.FC<ICustomerProps> = () => {
  const history = useHistory();

  const [cityList, setCityList] = useState<City[]>([]);
  const [filterParams, setFilterParams] = useState<ICustomerFilterParams>({
    ...INITIAL_FILTER_PARAMS,
    ...decodeURLParams(history.location.search)
  });

  const tableRef = React.useRef<MaterialTableProps<CustomerResponse>>();

  const loadData = (query: Query<CustomerResponse>) =>
    new Promise<QueryResult<CustomerResponse>>((resolve, reject) => {
      CustomerService.getCustomers(filterParams.trade_name, filterParams.email,
        filterParams.phonenumber, filterParams.registrationNumber,
        filterParams.city, undefined, undefined, PageableParams.fromQuery(query))
        .then(response => {
          resolve({
            data: response.data,
            page: query.page,
            totalCount: response.count,
          })
        })
    });

  const applyFilter = (params: ICustomerFilterParams) => {
    setFilterParams(params);
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({ page: 0 } as Query<CustomerResponse>);
    }
  }

  useEffect(() => {
    const getCities = async () => {
      const response = await CityService.getCities();
      setCityList(response);
    };

    getCities();
  }, []);

  const showDetails = async (item: CustomerResponse) => {
    history.push(`/customer/detail/${item.id}`);
  };

  return (
    <React.Fragment>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Clientes</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[{ label: "Clientes", url: "/customer" }]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <ShowIf condition={AuthService.hasAuthorization("add_customer")}>
            <Button color="primary" variant="contained"
              startIcon={<AddCircleOutlineRoundedIcon />}
              component={Link} to="/customer/new">
              Novo Cliente
            </Button>
          </ShowIf>
        </Grid>
      </Grid>

      <CustomerFilter params={filterParams} cityList={cityList} onFilterChanged={applyFilter} />

      <MaterialTable
        tableRef={tableRef}
        columns={[
          { field: "id", hidden: true, width: 60 },
          { field: "trade_name", title: "Nome Fantasia", defaultSort: "asc" },
          {
            field: "registration_number", title: "CNPJ / CPF",
            render: (rowData, type) => formatCPFCNPJ(rowData.registration_number)
          },
          { field: "contact_name", title: "Contato" },
          {
            field: "phonenumber", title: "Telefone",
            render: (rowData, type) => formatPhoneNumber(rowData.phonenumber)
          },
          { field: "email", title: "E-mail" },
          {
            field: "city", title: "Cidade",
            render: (rowData, type) => formatCityName(rowData.fiscal_address_city)
          },
          {
            field: "is_active", title: "Situação",
            render: (rowData, type) => (
              <Alert
                icon={false}
                severity={rowData.is_active ? "success" : "error"}
              >
                {rowData.is_active ? "Ativo" : "Inativo"}
              </Alert>
            ),
          },
        ]}
        data={loadData}
        actions={[
          {
            icon: () => <VisibilityOutlined />,
            tooltip: 'Ver Detalhes',
            onClick: (event, rowData) => showDetails(rowData as CustomerResponse)
          }
        ]}
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_L10N_PTBR}
        options={DEFAULT_TABLE_OPTIONS}
      />
    </React.Fragment>
  );
};
