import React, { useLayoutEffect } from "react";
import { useTranslation } from 'react-i18next';
import CourierService from "../../../services/courierService";
import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, createStyles, Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Theme, Typography } from "@material-ui/core";
import { CourierCustomerBlock, CourierUserDetails, CourierUserStatus } from "../../../services/types/courier";
import { useEffect } from "react";
import { Alert } from "@material-ui/lab";
import { addMinutes, format, formatRelative } from "date-fns";
import { CURRENT_DATE_TIME_FORMAT, DEFAULT_DATE_LOCALE } from "../../../i18n";
import { DeliveryRequest } from "../../../services/types/deliveryRequest";
import DeliveryRequestService from "../../../services/deliveryRequestService";
import { DeliveryRequestStatus } from "../../../services/types/deliveryRequest";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import { MapContainer, Marker, TileLayer, Tooltip, ZoomControl } from "react-leaflet";
import ChangePasswordDialog, { ChangePasswordDialogHandle } from "../../../components/ChangePasswordDialog";
import RefreshIcon from '@material-ui/icons/Refresh';
//@ts-ignore
import { NotificationManager } from "react-notifications";
import ShowIf from "../../../components/ShowIf";
import AuthService from "../../../services/authService";
import BlockCourierDialog, { BlockCourierDialogHandle } from "../../customer/detail/BlockCourierDialog";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../../components/ConfirmationDialog";
import { Add, Delete } from "@material-ui/icons";

interface ICourierUserDetailDataProps {
  courierId: number;
}

export const CourierUserDetailData: React.FC<ICourierUserDetailDataProps> = ({ courierId }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [courierUserDetail, setCourierUserDetail] = React.useState<CourierUserDetails>();
  const [deliveryRequest, setDeliveryRequest] = React.useState<DeliveryRequest>();
  const [isCourierUserStatusInaccurate, setCourierUserStatusInaccurate] = React.useState<boolean>(false);
  const [blockList, setBlockList] = React.useState<CourierCustomerBlock[]>([]);
  const [map, setMap] = React.useState<L.Map>();
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
  const changePasswordDialog = React.createRef<ChangePasswordDialogHandle>();
  const blockCourierDialog = React.createRef<BlockCourierDialogHandle>();

  const loadCourierUserStatusData = React.useCallback(async (courierId: number) => {
    try {
      const _courierStatusDetail = await CourierService.getCourierUserDetails(courierId);
      setCourierUserDetail(_courierStatusDetail);
      if (_courierStatusDetail.delivery_request_id) {
        const _deliveryRequest = await DeliveryRequestService.getDeliveryRequest(_courierStatusDetail.delivery_request_id);
        if (_deliveryRequest) {
          if ([
            DeliveryRequestStatus.NEW,
            DeliveryRequestStatus.NO_COURIER,
            DeliveryRequestStatus.FINISHED,
            DeliveryRequestStatus.CANCELED,
            DeliveryRequestStatus.DISCARDED].includes(_deliveryRequest.status)) {
            setDeliveryRequest(undefined);
            setCourierUserStatusInaccurate(true);
          } else if (courierId !== _deliveryRequest.current_courier?.id) {
            setDeliveryRequest(undefined);
            setCourierUserStatusInaccurate(true);
          }
        } else {
          setDeliveryRequest(undefined)
          setCourierUserStatusInaccurate(true);
        }
      } else {
        setDeliveryRequest(undefined);
        if (![CourierUserStatus.AVAILABLE, CourierUserStatus.UNAVAILABLE].includes(_courierStatusDetail.courier_status)) {
          setCourierUserStatusInaccurate(true);
        } else {
          setCourierUserStatusInaccurate(false);
        }
      }
    } catch (error) {
    }
  }, []);

  const loadCourierBlockList = React.useCallback(async (courierId: number) => {
    try {
      const _blockList = await CourierService.getCourierBlockList(courierId);
      setBlockList(_blockList.data);
    } catch (error) {
    }
  }, []);

  const refresh = React.useCallback(() => {
    return Promise.allSettled([
      loadCourierUserStatusData(courierId),
      loadCourierBlockList(courierId)
    ]);
  }, [courierId, loadCourierUserStatusData, loadCourierBlockList]);

  useEffect(() => {
    refresh();
  }, [courierId, refresh]);

  useLayoutEffect(() => {
    if (map && courierUserDetail) {
      try {
        if (courierUserDetail.latitude && courierUserDetail.longitude) {
          map.setView([courierUserDetail.latitude, courierUserDetail.longitude], 15);
        }
      } catch (error) {
      }
    }
  }, [map, courierUserDetail]);

  const handleChangePassword = () => {
    const isHardPassword = false;
    changePasswordDialog.current?.getPassword(isHardPassword)
      .then((password) => {
        CourierService.setCourierUserPassword(courierId, password)
          .then((response) => {
            NotificationManager.success("A senha do Entregador foi alterada com sucesso", "Alterar Senha do Entregador");
            changePasswordDialog.current?.hide();
          })
          .catch((error) => {
            const { status, data } = error.response;
            const errorMsgs: string[] = [];
            const errorFields: { field: string, value: string }[] = [];
            let errorMsg = "Ocorreu um erro ao alterar a senha do Entregador";
            if (status === 400) {
              for (var key in data) {
                var value = data[key];
                if (["password", "password_confirmation"].includes(key)) {
                  errorFields.push({ field: key, value: value });
                } else {
                  errorMsgs.push(value);
                }
              }

              if (errorMsgs.length > 0) {
                errorMsg = errorMsgs.join(". \n");
              }
            }

            if (errorFields.length > 0) {
              changePasswordDialog.current?.showErrors(errorFields);
            } else {
              NotificationManager.error(errorMsg, "Alterar Senha do Entregador");
            }
          });
      })
      .catch((error) => { });
  }

  const handleCorrectStatus = async () => {
    try {
      setLoading(true);
      await CourierService.resetCourierUserStatus(courierId);
      await refresh();
    } finally {
      setLoading(false);
    }
  }

  const handleRefresh = async () => {
    try {
      setLoading(true);
      await refresh();
    } finally {
      setLoading(false);
    }
  }

  const handleNewBlock = () => {
    blockCourierDialog.current?.add()
      .then((block) => {
        CourierService.blockCourier(block.courier, block.duration, block.reason, block.customer)
          .then(async (response) => {
            const dialog = blockCourierDialog.current;
            NotificationManager.success("Bloqueio salvo com sucesso.", "Novo Bloqueio");
            await refresh();
            dialog?.hide();
          })
          .catch((error) => {
            const { status, data } = error.response;
            const errorMsg = "Ocorreu um erro ao salvar o Bloqueio";
            let remainingErrorMsgs;
            if (status === 400) {
              remainingErrorMsgs = blockCourierDialog.current?.showFieldErrors(data);
            }
            NotificationManager.error(remainingErrorMsgs || errorMsg, "Novo Bloqueio");
          });
      })
      .catch(error => { });
  }

  const handleUnblock = (block: CourierCustomerBlock) => {
    confirmationDialog.current?.confirm(
      "Remover Bloqueio",
      <Typography>Você tem certeza que deseja <strong>Remover</strong> este Bloqueio?</Typography>,
      "Remover",
      "Cancelar"
    ).then(() => {
      CourierService.unblockCourier(block.id)
        .then(async (response) => {
          const dialog = confirmationDialog.current;
          NotificationManager.success("Bloqueio removido com sucesso.", "Bloqueio");
          await refresh();
          dialog?.hide();
        })
        .catch((error) => {
          NotificationManager.error("Não foi possível remover o Bloqueio.", "Bloqueio");
        });
    }).catch(() => { });
  };

  return (
    <React.Fragment>
      <LoadingOverlay open={isLoading} />

      <ConfirmationDialog ref={confirmationDialog} />
      <ChangePasswordDialog ref={changePasswordDialog} />
      <BlockCourierDialog ref={blockCourierDialog} courierId={courierId} />

      <Grid container spacing={2}>
        <Grid item md={5} xl={4}>
          <Card>
            <CardHeader
              title="Usuário"
              subheader={
                courierUserDetail ?
                  `Dados atualizados ${formatRelative(courierUserDetail.updated_at, new Date(), { locale: DEFAULT_DATE_LOCALE })}`
                  : ""
              }
              action={
                <IconButton
                  aria-label="Atualizar Dados"
                  title="Atualizar Dados"
                  onClick={() => {
                    handleRefresh();
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              }
            />
            <CardContent>
              <Grid container spacing={1}>
                {isCourierUserStatusInaccurate &&
                  (
                    <Grid xs={12}>
                      <Alert
                        severity="error"
                        action={
                          <Button
                            color="inherit"
                            onClick={() => { handleCorrectStatus(); }}>
                            Corrigir
                          </Button>
                        }>
                        O Entregador está com uma situação inconsistente.
                      </Alert>
                    </Grid>
                  )}
                <Grid xs={3} xl={3}>
                  <Typography>Situação:</Typography>
                </Grid>
                <Grid xs={9} xl={9}>
                  <Typography variant="subtitle1">
                    {courierUserDetail ? t("courier_user_status." + courierUserDetail.courier_status) : ""}
                  </Typography>
                </Grid>
                <Grid xs={3} xl={3}>
                  <Typography>Entrega:</Typography>
                </Grid>
                <Grid xs={9} xl={9}>
                  <Typography variant="subtitle1">
                    {deliveryRequest ? `#${deliveryRequest.order_number}` : "Sem Entrega"}
                  </Typography>
                </Grid>
                <Grid xs={3} xl={3}>
                  <Typography>Versão do APP:</Typography>
                </Grid>
                <Grid xs={9} xl={9}>
                  <Typography variant="subtitle1">
                    {courierUserDetail ? `${courierUserDetail.app_version}` : "Sem Informações"}
                  </Typography>
                </Grid>
                <Grid xs={3} xl={3}>
                  <Typography>Online:</Typography>
                </Grid>
                <Grid xs={9} xl={9}>
                  <Typography variant="subtitle1">
                    {courierUserDetail ? `${courierUserDetail.online ? "Sim" : "Não"}` : "Sem Informações"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <ShowIf condition={AuthService.hasAuthorization("change_courier_password")}>
                <Button
                  color="primary"
                  onClick={() => { handleChangePassword(); }}>
                  Alterar Senha
                </Button>
              </ShowIf>
            </CardActions>
            <Divider />
            <CardHeader
              title="Bloqueios"
              subheader={
                blockList.length === 0 ?
                  "Não há Bloqueio Ativo para este entregador"
                  : ""
              }
              action={
                <ShowIf condition={AuthService.hasAuthorization("add_blocklist")}>
                  <Button
                    startIcon={<Add />}
                    onClick={handleNewBlock}
                  >
                    Novo Bloqueio
                  </Button>
                </ShowIf>
              }
            />
            <CardContent className={classes.blockListContent}>
              <List dense={true} className={classes.blockListContent}>
                {blockList.map((item) => (
                  <ListItem button title={`Desde: ${format(item.created_at, CURRENT_DATE_TIME_FORMAT)}`}>
                    <ListItemText
                      primary={
                        <Typography variant="h6">
                          Cliente: {item.customers.length === 0 ? "Todos" : item.customers[0].trade_name}
                        </Typography>
                      }
                      secondary={(
                        <React.Fragment>
                          {
                            item.reason ?
                              <Typography>{`Motivo: ${item.reason}`}</Typography>
                              : <React.Fragment></React.Fragment>
                          }
                          {
                            item.undefined_time ?
                              <Typography>Bloqueado por tempo indeterminado</Typography> :
                              <Typography>{`Bloqueio válido até ${format(addMinutes(item.created_at, item.duration || 0), CURRENT_DATE_TIME_FORMAT)}`}</Typography>
                          }
                        </React.Fragment>
                      )}
                    />
                    <ShowIf condition={AuthService.hasAuthorization("change_blocklist")}>
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="remover bloqueio"
                          title="Remover Bloqueio"
                          onClick={() => handleUnblock(item)}
                        >
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ShowIf>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={7} xl={8}>
          <Card>
            <CardHeader title="Localização" />
            <CardMedia>
              <div className={classes.mapWrapper}>
                <MapContainer scrollWheelZoom={false} whenCreated={setMap} zoomControl={false}>
                  <ZoomControl position="bottomright" />
                  <TileLayer
                    attribution={'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {(courierUserDetail?.latitude && courierUserDetail?.longitude) &&
                    (
                      <Marker position={[courierUserDetail.latitude, courierUserDetail.longitude]}>
                        <Tooltip>Data: {format(courierUserDetail.updated_at, CURRENT_DATE_TIME_FORMAT)}</Tooltip>
                      </Marker>
                    )
                  }
                </MapContainer>
              </div>
            </CardMedia>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    map: {
      width: "100%",

      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    mapWrapper: {
      position: "relative",
      width: "100%",
      borderRadius: "4px",
      overflow: "hidden",
      height: "300px",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
    },
    blockListContent: {
      paddingTop: 0
    }
  })
);
