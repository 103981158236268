import React, { ReactNode } from "react";
import type { FC } from "react";

import { Box, Divider, Paper, Typography } from "@material-ui/core";

interface ScheduleColumnProps {
  title?: string;
  className?: string;
  titleClassName?: string;
  children?: ReactNode;
  headerActions?: ReactNode;
}

export const ScheduleColumn: FC<ScheduleColumnProps> = ({
  title,
  className,
  titleClassName,
  children,
  headerActions,
}) => {
  return (
    <Paper elevation={1} className={className}>
      <Box
        py={1}
        px={2}
        display="flex"
        alignItems="center"
        className={titleClassName}
      >
        <Typography
          noWrap
          color="inherit"
          variant="h6"
          title={title ? title : ""}
        >
          {title ? title : ""}  
        </Typography>
        {headerActions}
      </Box>
      <Divider />
      {children}
    </Paper>
  );
};
