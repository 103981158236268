import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";

import { DEFAULT_TABLE_OPTIONS, TableContainer, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { INITIAL_FILTER_PARAMS, ITopCouriersParams, TopCouriersFilter } from "./TopCouriersFilter";
import { TopCouriersResponse } from "../../services/types/topCouriers";
import ReportService from "../../services/reportService";
import RegionService from "../../services/regionService";
import theme from "../../styles/theme";
import { useHistory } from "react-router-dom";
import { decodeURLParams, URLParamType } from "../../utils/utils";
import { endOfDay, startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";

const TABLE_LOCALIZATION = {
  ...TABLE_L10N_PTBR,
  body: {
    ...TABLE_L10N_PTBR.body,
    emptyDataSourceMessage: "Nenhum registro encontrado"
  }
}

export const TopCouriers = () => {
  const history = useHistory();
  const [regionList, setRegionList] = useState<any[]>([]);
  const { t } = useTranslation();
  const [filterParams, setFilterParams] = useState<ITopCouriersParams>({
    ...INITIAL_FILTER_PARAMS,
    ...decodeURLParams(history.location.search, [
      { "startDate": URLParamType.DATE },
      { "endDate": URLParamType.DATE }
    ])
  });
  const tableRef = React.useRef<MaterialTableProps<TopCouriersResponse>>();

  const loadData = (query: Query<TopCouriersResponse>) => {
    const numberOfRegisters = filterParams.numberOfRegisters ? Number(filterParams.numberOfRegisters) : null;
    const startDate = filterParams.startDate ? startOfDay(filterParams.startDate) : null;
    const endDate = filterParams.endDate ? endOfDay(filterParams.endDate) : null;
    const region = filterParams.region ? Number(filterParams.region) : null;
    const vehicleType = filterParams.vehicleType ? Number(filterParams.vehicleType) : null;
    const minimumStores = filterParams.minimumStores ? Number(filterParams.minimumStores) : null;

    if (startDate == null || endDate == null) {
      TABLE_LOCALIZATION.body.emptyDataSourceMessage = "Selecione uma Data de Início e Data de Fim";
      return new Promise<QueryResult<TopCouriersResponse>>((resolve, reject) => {
        resolve({
          data: [],
          page: query.page,
          totalCount: 0,
        })
      })
    } else {
      TABLE_LOCALIZATION.body.emptyDataSourceMessage = "Nenhum registro encontrado";
      return new Promise<QueryResult<TopCouriersResponse>>((resolve, reject) => {
        ReportService.getTopCouriersEntries(numberOfRegisters, startDate, endDate,
          region, vehicleType, minimumStores)
          .then(response => {
            resolve({
              data: response.data,
              page: query.page,
              totalCount: response.count,
            })
          })
      });
    }
  }

  const applyFilter = (params: ITopCouriersParams) => {
    setFilterParams(params);
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({ page: 0 } as Query<TopCouriersResponse>);
    }
  }

  useEffect(() => {
    const getRegions = async () => {
      const response = await RegionService.loadAllRegions({
        ordering: "name",
      });
      setRegionList(response);
    };
    getRegions();
  }, []);

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Top Entregadores</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[{ label: "Top Entregadores", url: "/topCouriers" }]}
            />
          </Grid>
        </Grid>
      </Grid>

      <TopCouriersFilter params={filterParams} regionList={regionList} onFilterChanged={applyFilter} />

      <MaterialTable
        tableRef={tableRef}
        title=""
        columns={[
          {
            field: "tableData.position", title: "#", align: "right", width: 20,
            render: (rowData, type) => {
              (rowData as any).tableData.position = (rowData as any).tableData.id + 1;
              return (rowData as any).tableData.position;
            }
          },
          {
            field: "name", title: "Nome"
          },
          {
            field: "region_name", title: "Região"
          },
          {
            field: "vehicle_type", title: "Tipo de Veículo",
            render: (rowData, type) => t("vehicle_type." + rowData.vehicle_type)
          },
          {
            field: "counter", title: "Entregas", align: "right", width: 60
          },
        ]}
        data={loadData}
        components={{
          Container: props => (
            <TableContainer {...props} customStyles={{ paddingBotton: theme.spacing(2), paddingTop: theme.spacing(0) }} />
          )
        }}
        localization={TABLE_LOCALIZATION}
        options={{
          ...DEFAULT_TABLE_OPTIONS,
          paging: false,
          sorting: false,
          draggable: false,
          //exportButton: { csv: true },
          exportAllData: false,
          toolbar: true,
          search: false,
          columnsButton: false,
          fixedColumns: {}
        }}
      />

    </div>
  );
};