import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import {
    Grid,
    Typography,
    Button,
    TextField,
    makeStyles,
    MenuItem,
    CircularProgress,
    InputAdornment,
    Card,
    CardContent,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { CurrencyInput } from "../../components/CurrencyInput";
import { CURRENT_DATE_TIME_FORMAT, DEFAULT_LOCALE, getNumberSpecs } from "../../i18n";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import {
    CreateUpdateIncentive, IncentiveDetailResponse, IncentiveTimeMensage,
} from "../../services/types/incentive";
import IncentiveService from "../../services/incentiveService";
import RegionService from "../../services/regionService";
import { convertToNumber } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { differenceInMinutes, getHours, isPast, isToday} from "date-fns";
import { Alert } from "@material-ui/lab";
import { isEqual } from "lodash";

const MIN_HOURS_CREATE_INCENTIVE = 12; // 12 horas
const MIN_HOURS_IN_MINUTES_CREATE_INCENTIVE = 720; // 720 minutos === 12 horas

const validationSchema = Yup.object().shape({
    start_date: Yup.date().required("Campo obrigatório").nullable(),
    end_date: Yup.date().required("Campo obrigatório").nullable(),
    region: Yup.string().required("Campo obrigatório").nullable(),
    send_message: Yup.boolean(),
    send_message_pattern: Yup.string().when("send_message", {
      is: (value) => value === true,
      then: Yup.string().required("Campo obrigatório").nullable(),
      otherwise: Yup.string().optional().nullable(),
    }),

});

export const INITIAL_STATE: CreateUpdateIncentive = {
    start_date: null,
    end_date: null,
    speedy_value: 0,
    customer_value: 0,
    region: null,
    send_message: false,
    send_message_pattern: null
};

export enum INCENTIVE_ACTION {
    NEW = "NEW",
    EDIT = "EDIT"
};

interface NewEditIncentiveProps {
    action: INCENTIVE_ACTION;
    incentiveId?: string;
}

export const NewEditIncentive: React.FC<NewEditIncentiveProps> = ({ action, incentiveId }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const [regionList, setRegionList] = useState<any[]>([]);
    const [incentive, setIncentive] = useState<CreateUpdateIncentive>(INITIAL_STATE);
    const [isEditable, setIsEditable] = useState<boolean>(true);
    const [messageEditable, setMessageEditable] = useState<string>("");
    const [totalError, setTotalError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        RegionService.loadAllRegions({ ordering: "name" })
            .then(response => {
                setRegionList(response);
            })
            .catch(error => console.log(error));
    }, []);

    const validateIsEditable = (item: IncentiveDetailResponse) => {
        // Não permitir editar o incentivo caso o horário de uma mensagem já tenha sido enviado OU o horário do incentivo já iniciou;
        if(isPast(item.announcement.send_date) && isPast(item.start_date)){
            setIsEditable(false);
            setMessageEditable("MENSAGEM JÁ ENVIADA E HORÁRIO DO INCENTIVO JÁ INICIADO.");
        }else if(isPast(item.announcement.send_date) && !isPast(item.start_date)){
            setIsEditable(false);
            setMessageEditable("MENSAGEM JÁ ENVIADA.");
        }else if(isPast(item.start_date) && !isPast(item.announcement.send_date)){
            setIsEditable(false);
            setMessageEditable("HORÁRIO DO INCENTIVO JÁ INICIADO.");
        }else{
            setIsEditable(true); 
        };
    };

    useEffect(() => {
        const loadIncentive = async (id: number) => {
            const response = await IncentiveService.getIncentiveById(id);
            validateIsEditable(response);
            const incentive: CreateUpdateIncentive = {
                ...response,
                send_message: false,
                region: response.region ? response.region.id : null
            };

            setIncentive(incentive);
        };

        if (![INCENTIVE_ACTION.NEW, INCENTIVE_ACTION.EDIT].includes(INCENTIVE_ACTION[action])) {
            history.push({ pathname: "/incentive" });
        }

        if ([INCENTIVE_ACTION.EDIT].includes(action)) {
            if (incentiveId) {
                loadIncentive(Number(incentiveId));
            } else {
                // Se for EDIT e não houver ID volta para a consulta
                history.push({ pathname: "/incentive" });
            }
        }
    }, [action, incentiveId, history]);

    const formik = useFormik({
        initialValues: incentive,
        onSubmit: async (values: CreateUpdateIncentive) => {
            let errorMsg = "Ocorreu um erro ao salvar o Incentivo";
            if (action === INCENTIVE_ACTION.EDIT) {
                if(values.start_date && isPast(values.start_date)){
                    NotificationManager.error("Data e Hora Inicial não pode ser menor que Data e Hora Atual", "Data e Hora Inicial");
                    formik.setFieldError("start_date", "Data e Hora Inicial não pode ser menor que Data e Hora Atual.");
                    return;
                }
                if(values.start_date && values.end_date && 
                    differenceInMinutes(values.end_date, values.start_date) > MIN_HOURS_IN_MINUTES_CREATE_INCENTIVE){
                        NotificationManager.error(`Data e Hora Final não pode ser maior que ${MIN_HOURS_CREATE_INCENTIVE} horas da Data e Hora Inicial`, "Data e Hora Final");
                        formik.setFieldError("end_date", `Data e Hora Final não pode ser maior que ${MIN_HOURS_CREATE_INCENTIVE} horas da Data e Hora Inicial`);
                        return;
                }
                setIsLoading(true);
                IncentiveService.updateIncentive(Number(incentiveId), {
                    ...values,
                    speedy_value: values.speedy_value ? convertToNumber(values.speedy_value) : 0,
                    customer_value: values.customer_value ? convertToNumber(values.customer_value) : 0,
                    send_message: values.send_message,
                    send_message_pattern: values.send_message === false ? null : values.send_message_pattern
                })
                    .then((result) => {
                        NotificationManager.success(
                            "Incentivo salvo com sucesso",
                            "Novo Incentivo"
                        );
                        setIsLoading(false);
                        history.push("/incentive");
                    }).catch((error) => {
                        setIsLoading(false);
                        const { status, data } = error.response;
                        const errorMsgs: string[] = [];
                        if (status === 400) {
                            for (var key in data) {
                                var value = data[key];
                                if (key in values) {
                                    formik.setFieldError(key, value);
                                } else {
                                    errorMsgs.push(value);
                                }
                            }

                            if (errorMsgs.length > 0) {
                                errorMsg = errorMsgs.join(". \n");
                            }
                        }
                        NotificationManager.error(errorMsg, "Editar Incentivo");
                    });
            } else {
                if(values.start_date && isPast(values.start_date)){
                    NotificationManager.error("Data e Hora Inicial não pode ser menor que Data e Hora Atual", "Data e Hora Inicial");
                    formik.setFieldError("start_date", "Data e Hora Inicial não pode ser menor que Data e Hora Atual.");
                    return;
                }
                if(values.start_date && values.end_date && 
                    differenceInMinutes(values.end_date, values.start_date) > MIN_HOURS_IN_MINUTES_CREATE_INCENTIVE){
                        NotificationManager.error(`Data e Hora Final não pode ser maior que ${MIN_HOURS_CREATE_INCENTIVE} horas da Data e Hora Inicial`, "Data e Hora Final");
                        formik.setFieldError("end_date", `Data e Hora Final não pode ser maior que ${MIN_HOURS_CREATE_INCENTIVE} horas da Data e Hora Inicial`);
                        return;
                }
                setIsLoading(true);
                IncentiveService.addIncentive({
                    ...values,
                    speedy_value: values.speedy_value ? convertToNumber(values.speedy_value) : 0,
                    customer_value: values.customer_value ? convertToNumber(values.customer_value) : 0,
                    send_message: values.send_message,
                    send_message_pattern: values.send_message === false ? null : values.send_message_pattern
                }).then((result) => {
                    NotificationManager.success(
                        "Incentivo salvo com sucesso",
                        "Novo Incentivo"
                    );
                    setIsLoading(false);
                    history.push("/incentive");
                }).catch((error) => {
                    setIsLoading(false);
                    const { status, data } = error.response;
                    const errorMsgs: string[] = [];
                    if (status === 400) {
                        for (var key in data) {
                            var value = data[key];
                            if(key === "total_value"){
                                setTotalError(value);
                            }
                            if (key in values) {
                                formik.setFieldError(key, value);                               
                            } else {
                                errorMsgs.push(value);
                            }
                        }

                        if (errorMsgs.length > 0) {
                            errorMsg = errorMsgs.join(". \n");
                        }
                    }
                    NotificationManager.error(errorMsg, "Novo Incentivo");
                });
            }
        },
        validationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const returnDateMin = (dateSelect: Date) => {
        var now = new Date(dateSelect.getFullYear(), dateSelect.getMonth(), dateSelect.getDate(), dateSelect.getHours(), dateSelect.getMinutes());
        if(isToday(dateSelect)){
            now = new Date(2999,1,1, 23, 59);
        }       
        return now;
    }

    const returnDateMax = (dateSelect: Date) => {
        var now = new Date(dateSelect.getFullYear(), dateSelect.getMonth(), dateSelect.getDate(), dateSelect.getHours() + MIN_HOURS_CREATE_INCENTIVE, 0);     
        return now;
    }

    return (
        <>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">{action === INCENTIVE_ACTION.EDIT ? "Editar Incentivo" : "Novo Incentivo"}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[
                                { label: "Incentivos", url: "/incentive" },
                                {
                                    label: action === INCENTIVE_ACTION.EDIT ? "Editar Incentivo" : "Novo Incentivo",
                                    url: action === INCENTIVE_ACTION.EDIT ? `/incentive/detail/${incentiveId}` : "/incentive/new",
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <form onSubmit={formik.handleSubmit} noValidate>
                <Grid container spacing={2} direction="column" wrap="nowrap">
                    <Grid item>
                        <Card>
                            <CardContent>
                                <Typography variant="h2">Dados do Incentivo</Typography>
                                <Grid container justify="space-between">
                                    <Grid item xl={6} lg={8} md={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1">Configurações</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    select
                                                    label="Região"
                                                    name="region"
                                                    value={formik.values.region}
                                                    onChange={formik.handleChange}
                                                    error={!!formik.errors.region}
                                                    helperText={formik.errors.region}
                                                    disabled={action === INCENTIVE_ACTION.EDIT && isEditable === false}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    InputLabelProps={{ shrink: (!!formik.values.region) }}
                                                >
                                                    {regionList.map(region => (
                                                        <MenuItem
                                                            key={region.id}
                                                            value={region.id}
                                                        >
                                                            {region.name} - {region.state}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item sm={12} md={12} lg={6}>
                                                <DatePicker
                                                    id="start_date"
                                                    name="start_date"
                                                    onChange={val => {
                                                        formik.setFieldValue("start_date", val);
                                                        formik.setFieldValue("end_date", null);
                                                        if(val && isPast(val as Date)){
                                                            formik.setFieldError("start_date", "Data e Hora Inicial não pode ser menor que Data e Hora Atual.");
                                                        }else{
                                                            formik.setFieldError("start_date", undefined);
                                                        } 
                                                    }}
                                                    minDate={new Date()}
                                                    selected={formik.values.start_date}
                                                    startDate={formik.values.start_date}
                                                    endDate={formik.values.end_date}
                                                    minTime={new Date()}
                                                    maxTime={returnDateMin(formik.values.start_date || new Date(2999,1,1, 23, 59))}
                                                    timeIntervals={10}
                                                    selectsStart
                                                    locale={DEFAULT_LOCALE}
                                                    dateFormat={CURRENT_DATE_TIME_FORMAT}
                                                    disabled={action === INCENTIVE_ACTION.EDIT && isEditable === false}
                                                    timeCaption="Hora"
                                                    isClearable={action !== INCENTIVE_ACTION.EDIT}
                                                    showTimeSelect
                                                    autoComplete="off"
                                                    wrapperClassName="MuiFormControl-fullWidth"
                                                    required
                                                    customInput={<TextField
                                                        label="Data e Hora Inicial"
                                                        variant="outlined"
                                                        size="small"
                                                        error={!!formik.errors.start_date}
                                                        helperText={formik.errors.start_date}
                                                        fullWidth />}
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={12} lg={6}>
                                                <DatePicker
                                                    id="end_date"
                                                    name="end_date"
                                                    onChange={val => {
                                                        formik.setFieldValue("end_date", val);
                                                        if(val){
                                                            if(isPast(val as Date) || isEqual(val as Date, formik.values.start_date)){
                                                                formik.setFieldError("end_date", "Data e Hora Final não pode ser menor que Data e Hora de Inicio.");
                                                            }else{
                                                                formik.setFieldError("end_date", undefined);
                                                            } 
                                                        }
                                                    }}
                                                    selected={formik.values.end_date}
                                                    startDate={formik.values.start_date}
                                                    endDate={formik.values.end_date}
                                                    minDate={formik.values.start_date}
                                                    maxDate={returnDateMax(formik.values.start_date || new Date(2999,1,1, 23, 59))}
                                                    minTime={returnDateMin(formik.values.start_date || new Date())}
                                                    maxTime={new Date(getHours(formik.values.start_date || new Date()) + MIN_HOURS_CREATE_INCENTIVE)}
                                                    timeIntervals={10}
                                                    disabled={action === INCENTIVE_ACTION.EDIT && isEditable === false}
                                                    selectsEnd
                                                    locale={DEFAULT_LOCALE}
                                                    dateFormat={CURRENT_DATE_TIME_FORMAT}
                                                    timeCaption="Hora"
                                                    isClearable={action !== INCENTIVE_ACTION.EDIT}
                                                    showTimeSelect
                                                    autoComplete="off"
                                                    wrapperClassName="MuiFormControl-fullWidth"
                                                    required
                                                    customInput={<TextField
                                                        label="Data e Hora Final"
                                                        variant="outlined"
                                                        size="small"
                                                        error={!!formik.errors.end_date}
                                                        helperText={formik.errors.end_date}
                                                        fullWidth />}
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={12} lg={4}>
                                                <TextField
                                                    name="customer_value"
                                                    label="Valor dado pelo Cliente"
                                                    variant="outlined"
                                                    value={formik.values.customer_value}
                                                    error={!!formik.errors.customer_value}
                                                    disabled={action === INCENTIVE_ACTION.EDIT && isEditable === false}
                                                    helperText={
                                                        formik.errors.customer_value
                                                    }
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        setTotalError("");
                                                    }}
                                                    InputProps={{
                                                        inputComponent: CurrencyInput,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                {
                                                                    getNumberSpecs()
                                                                        .currency.symbol
                                                                }
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={12} lg={4}>
                                                <TextField
                                                    name="speedy_value"
                                                    label="Valor dado pela Speedy"
                                                    variant="outlined"
                                                    value={formik.values.speedy_value}
                                                    error={!!formik.errors.speedy_value}
                                                    helperText={formik.errors.speedy_value}
                                                    disabled={action === INCENTIVE_ACTION.EDIT && isEditable === false}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        setTotalError("");
                                                    }}
                                                    fullWidth
                                                    InputProps={{
                                                        inputComponent: CurrencyInput,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                {
                                                                    getNumberSpecs()
                                                                        .currency.symbol
                                                                }
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item sm={12} md={12} lg={4}>
                                                <TextField
                                                    name="total_value"
                                                    label="Valor Total"
                                                    variant="outlined"
                                                    value={(convertToNumber(formik.values.customer_value) || 0) + (convertToNumber(formik.values.speedy_value) || 0) }
                                                    disabled={true}
                                                    error={!!totalError}
                                                    helperText={totalError}
                                                    fullWidth
                                                    InputProps={{
                                                        inputComponent: CurrencyInput,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                {
                                                                    getNumberSpecs()
                                                                        .currency.symbol
                                                                }
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item md={12} lg={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                        checked={formik.values.send_message}
                                                        onChange={formik.handleChange}
                                                        disabled={action === INCENTIVE_ACTION.EDIT && isEditable === false}
                                                        name="send_message"
                                                        color="primary"
                                                        />
                                                    }
                                                    label="Enviar Mensagem"
                                                />
                                            </Grid>
                                            
                                            {formik.values.send_message && (
                                                <Grid item md={12} lg={12}>
                                                   <TextField
                                                    select
                                                    label="Quando Enviar"
                                                    name="send_message_pattern"
                                                    value={formik.values.send_message_pattern}
                                                    onChange={formik.handleChange}
                                                    error={!!formik.errors.send_message_pattern}
                                                    helperText={formik.errors.send_message_pattern}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    InputLabelProps={{ shrink: (!!formik.values.send_message_pattern) }}
                                                >
                                                        <MenuItem key={IncentiveTimeMensage.IMMEDIATE} value={IncentiveTimeMensage.IMMEDIATE} >
                                                            {t("incentive_time_mensage." + IncentiveTimeMensage.IMMEDIATE)}
                                                        </MenuItem>
                                                        <MenuItem key={IncentiveTimeMensage.THIRTY_MINS_BEFORE} value={IncentiveTimeMensage.THIRTY_MINS_BEFORE} >
                                                            {t("incentive_time_mensage." + IncentiveTimeMensage.THIRTY_MINS_BEFORE)}
                                                        </MenuItem>
                                                        <MenuItem key={IncentiveTimeMensage.ONE_HOUR_BEFORE} value={IncentiveTimeMensage.ONE_HOUR_BEFORE} >
                                                            {t("incentive_time_mensage." + IncentiveTimeMensage.ONE_HOUR_BEFORE)}
                                                        </MenuItem>
                                                        <MenuItem key={IncentiveTimeMensage.TWO_HOURS_BEFORE} value={IncentiveTimeMensage.TWO_HOURS_BEFORE} >
                                                            {t("incentive_time_mensage." + IncentiveTimeMensage.TWO_HOURS_BEFORE)}
                                                        </MenuItem>

                                                </TextField>
                                                </Grid>
                                            )}

                                        </Grid>
                                    </Grid>                                 
                                </Grid>
                                {action === INCENTIVE_ACTION.EDIT && isEditable === false && (
                                    <Grid item >
                                        <Alert severity="warning">
                                            {messageEditable}
                                        </Alert>
                                    </Grid>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item zeroMinWidth>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    tabIndex={12}
                                    onClick={() => history.goBack()}
                                >
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={isLoading || isEditable === false }
                                    type="submit"
                                >
                                    Salvar
                                    {isLoading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </Grid>                           
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

const useStyles = makeStyles({
    page: {
        padding: "10px 0 0",
    },
    field: {
        marginBottom: "20px",
    },
    helpDropzone: {
        marginTop: "5px",
    },
    buttonProgress: {
        position: "absolute",
    },
});
