import { DeliveryRequestStatus } from "./deliveryRequest";
import { FinancialEntryStatus } from "./financialEntry";
import { RegionResponse } from "./region";

export enum VehicleType {
    BIKE = "BIKE",
    MOTORCYCLE = "MOTORCYCLE",
    CAR = "CAR",
    VAN = "VAN",
}

export enum VehicleLoadCompartmentType {
    BAG = "BAG",
    TRUNK = "TRUNK",
}

export enum CourierAccountStatus {
    ACTIVE = "ACTIVE",
    SUSP_INACTIVE = "SUSP_INACTIVE",
    SUSP_DECLINE = "SUSP_DECLINE",
    SUSP_OTHERS = "SUSP_OTHERS",
    SUSP_DATA_UPDATED = "SUSP_DATA_UPDATED",
    BANNED = "BANNED",
    REGISTRATION_ANALYSIS = "REGISTRATION_ANALYSIS",
    REGISTRATION_DENIED = "REGISTRATION_DENIED",
    DATA_UPDATED = "DATA_UPDATED",
}

export enum ActionChangeStatusCourier {
    ACTIVATE_COURIER = "ACTIVATE_COURIER",
    REACTIVATE_COURIER = "REACTIVATE_COURIER",
    DEACTIVATE_COURIER = "DEACTIVATE_COURIER",
    SUSPEND_COURIER = "SUSPEND_COURIER",
    BAN_COURIER = "BAN_COURIER",
    DENY_REGISTRATION_COURIER = "DENY_REGISTRATION_COURIER",
    RELEASE_BANK_ACCOUNT_COURIER = "RELEASE_BANK_ACCOUNT_COURIER"
}

export enum DriversLicenseCategory {
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    AB = "AB",
    AC = "AC",
    AD = "AD",
    AE = "AE",
}

export enum CourierUserStatus {
    AVAILABLE = "AVAILABLE",
    BUSY = "BUSY",
    IN_STORE = "IN_STORE",
    OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY",
    AT_DESTINATION = "AT_DESTINATION",
    UNAVAILABLE = "UNAVAILABLE",
}

export enum BankAccountType {
    SAVING_ACCOUNT = "SAVING_ACCOUNT",
    CHECKING_ACCOUNT = "CHECKING_ACCOUNT",
    SALARY_ACCOUNT = "SALARY_ACCOUNT",
    DIGITAL_ACCOUNT = "DIGITAL_ACCOUNT",
    UNIVERSITY_ACCOUNT = "UNIVERSITY_ACCOUNT",
}

export enum SpeedyRankingType {
    INITIATE_SPEEDER = "INITIATE_SPEEDER",
    ADVANCED_SPEEDER = "ADVANCED_SPEEDER"
}

export enum PaymentMethod {
    PHYSICAL_PERSON = "PHYSICAL_PERSON",
    LEGAL_PERSON = "LEGAL_PERSON"
}

export interface CourierUserDetails {
    pk: string;
    _id: string;
    courier_id: number;
    created_at: Date;
    updated_at: Date;
    online: boolean;
    latitude: number | null;
    longitude: number | null;
    courier_status: CourierUserStatus;
    delivery_request_id: number | null;
    delivery_requests: number[] | null;
    trip_id: number | null;
    suspended: boolean;
    app_version: string;
}

export interface OnlineCourierUserDetails {
    id: number;
    courier_status: CourierUserStatus;
    last_update: Date;
    name: string;
    phonenumber: string;
    photo: string;
    vehicle_type: VehicleType;
    vehicle_load_compartment_type: VehicleLoadCompartmentType;
    delivery_request_id: number | null;
    latitude: number;
    longitude: number;
    new_ranking_points: number,
    speedy_ranking: SpeedyRankingType,
    requests_count: number,
    position: number
}

export interface CourierResponse {
    id: number,
    name: string,
    phonenumber: string,
    email: string,
    vehicle_type: VehicleType,
    vehicle_license_plate: string,
    city: string;
    state: string;
    region: RegionResponse,
    created_at: Date,
    courier_account_status: CourierAccountStatus,
    valid_bank_data: boolean,
    photo: string;
}

export interface CourierDeliveryRequestOrder {
    id: number;
    request_number: string;
    order_number: string;
    courier_name: string;
    customer_name: string;
    store_name: string;
    created_at: Date;
    requested_time: Date;
    closed_date: Date;
    status: DeliveryRequestStatus;
    delivery_value: number;
    courier_value: number;
    payment: FinancialEntryStatus;
}

export interface CourierOperation {
    id: number;
    delivery_request: {
        request_number: string;
        store_name: string;
        store_city: string;
    };
    created_at: Date;
    updated_at: Date;
    description: string;
    value: number;
    tax: number;
    total: number;
    type: string;
    status: string;
    confirmation_date: Date;
    payment_date: Date;
    canceled_date: Date;
    origin: string;
    document: string;
    bank_slip_key: string;
    bank_slip_barcode: string;
    bank_slip_custom_number: string;
    account: number;
}

export interface CourierFinancialStats {
    current_balance: number;
    today_earnings: number;
    current_week_earnigns: number;
    last_four_weeks_earnings: number;
    graph: {
        Sunday: number;
        Monday: number;
        Tuesday: number;
        Wednesday: number;
        Thursday: number;
        Friday: number;
        Saturday: number;
    }
}

export interface CourierDetails {
    id: number;
    photo: string;
    address_proof_image: string;
    drivers_license_front_image: string;
    drivers_license_back_image: string;
    vehicle_registration_front_image: string;
    vehicle_registration_back_image: string;
    speedy_ranking: string;
    region: RegionResponse;
    created_at: Date;
    updated_at: Date;
    courier_account_status: CourierAccountStatus;
    name: string;
    email: string;
    phonenumber: string;
    registration_number: string;
    state: string;
    city: string;
    postal_code: string;
    district: string;
    street: string;
    address_number: string;
    address_complement: string;
    mother_name: string;
    general_register: string;
    birthdate: Date | null;
    drivers_license_category: DriversLicenseCategory | null;
    drivers_license_number: string;
    drivers_license_expiration: Date | null;
    vehicle_type: VehicleType | null;
    vehicle_load_compartment_type: string;
    vehicle_license_plate: string;
    accredidation_date: Date;
    bank_code: string;
    bank_name: string;
    bank_account_type: BankAccountType | null;
    bank_agency: string;
    bank_account_code: string;
    bank_account_code_dv: string;
    has_accepted_terms: boolean;
    ranking_points: number;
    score: number;
    valid_bank_data: boolean;
    reviewed_by: number;
    payment_method: string;
    legal_person_registration_number: string;
}

export interface CreateUpdateCourier {
    photo: Blob;
    address_proof_image: Blob;
    drivers_license_front_image: Blob;
    drivers_license_back_image: Blob;
    vehicle_registration_front_image: Blob;
    vehicle_registration_back_image: Blob;
    region: number;
    name: string;
    email: string;
    phonenumber: string;
    registration_number: string;
    state: string;
    city: string;
    postal_code: string;
    district: string;
    street: string;
    address_number: string;
    address_complement: string;
    mother_name: string;
    general_register: string;
    birthdate: Date | null;
    drivers_license_category: DriversLicenseCategory | null;
    drivers_license_number: string;
    drivers_license_expiration: Date | null;
    vehicle_type: VehicleType | null;
    vehicle_load_compartment_type: string;
    vehicle_license_plate: string;
    bank_code: string;
    bank_name: string;
    bank_account_type: BankAccountType | null;
    bank_agency: string;
    bank_account_code: string;
    bank_account_code_dv: string;
}

export interface CourierDeliveryRelatoryRanking {
    accept_checkin_delays: number;
    accepted: number;
    total_deliveries: number;
    checkout_deliver_delays: number;
    finished: number;
    finished_0_3: number;
    finished_3_8: number;
    finished_8_gt: number;
    ignored: number;
    ranking_points: number;
    refused: number;
    speedy_ranking: SpeedyRankingType;
    stores: number;
}

export interface BlockedCourierCustomer {
    id: number;
    trade_name: string;
}

export interface CourierCustomerBlock {
    id: number;
    all_customers: boolean;
    customers: BlockedCourierCustomer[];
    expired: boolean;
    duration: number | null;
    undefined_time: boolean;
    reason: string;
    created_at: Date;
}

export enum CourierStatus {
}

export interface CourierStatusData {
    pk: string;
    _id: string;
    courier_id: number;
    created_at: Date;
    updated_at: Date;
    online: boolean;
    latitude: number;
    longitude: number;
    courier_status: CourierStatus;
    delivery_request_id: number;
    delivery_requests: any[];
    trip_id: number;
    suspended: boolean;
}

export interface QueueCourier {
    id: number;
    name: string;
    picture: string;
    position: number;
    vehicle_type: VehicleType;
    vehicle_load_compartment_type: VehicleLoadCompartmentType;
    courier_status: CourierStatusData;
}

export interface ReportCouriersResponse {
    approved_count: number;
    new_registers: number;
    pending_count: number;
    rejected_count: number;
}

export enum AccountStatus {
    ACTIVE = "ACTIVE",
    SUSP_INACTIVE = "SUSP_INACTIVE",
    SUSP_DECLINE = "SUSP_DECLINE",
    SUSP_OTHERS = "SUSP_OTHERS",
    SUSP_DATA_UPDATED = "SUSP_DATA_UPDATED",
    BANNED = "BANNED",
    REGISTRATION_ANALYSIS = "REGISTRATION_ANALYSIS",
    REGISTRATION_DENIED = "REGISTRATION_DENIED",
    DATA_UPDATED = "DATA_UPDATED",
}