import React from "react";
import { useParams } from "react-router";
import { ANNOUNCEMENT_ACTION, NewEditAnnouncement } from "./NewEditAnnouncement";

interface EditAnnouncementParams {
    announcementId: string;
}

export const CopyAnnouncement: React.FC = () => {
    const { announcementId } = useParams<EditAnnouncementParams>();

    return (
        <NewEditAnnouncement action={ANNOUNCEMENT_ACTION.COPY} announcementId={announcementId} />
    );
};
