import React, { useState } from "react";
import { Color } from "@material-ui/lab/Alert";
import { Grid, Button, Card, CardContent } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { CustomerFinancialOperationsFilter, ICustomerFinancialOperationsFilterParams, INITIAL_FILTER_PARAMS } from "./CustomerOperationsFilter";
import CustomerService from "../../../services/customerService";
import { useTranslation } from "react-i18next";
import fileDownload from "js-file-download";
import numbro from 'numbro';
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { LoadingDialog } from "../../../components/LoadingDialog";
import { CustomerFinancialOperation, CustomerFinancialOperationType } from "../../../services/types/customer";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";

interface IProps {
  customerId: number;
}

const mapPaymentStatus = (paymentType: string): Color => {
  switch (paymentType) {
    case CustomerFinancialOperationType.CREDIT:
      return "success";
    case CustomerFinancialOperationType.DEBIT:
      return "error";
    default:
      return "warning";
  }
};

const mapPaymentValue = (paymentType: string, value: number) => {
  switch (paymentType) {
    case CustomerFinancialOperationType.CREDIT:
      return `+ ${numbro(value).formatCurrency()}`;
    case CustomerFinancialOperationType.DEBIT:
      return `- ${numbro(value).formatCurrency()}`;
    default:
      return "";
  }
};

export const CustomerOperations: React.FC<IProps> = ({ customerId }) => {
  const { t } = useTranslation();

  const [isShowLoadingDialog, showLoadingDialog] = React.useState(false);
  const [filterParams, setFilterParams] = useState<ICustomerFinancialOperationsFilterParams>(INITIAL_FILTER_PARAMS);

  const tableRef = React.useRef<MaterialTableProps<CustomerFinancialOperation>>();

  const loadData = (query: Query<CustomerFinancialOperation>) =>
    new Promise<QueryResult<CustomerFinancialOperation>>((resolve, reject) => {
      CustomerService.getCustomerFinancialOperations(
        customerId, filterParams.start_date, filterParams.end_date,
        filterParams.description, filterParams.store_name,
        filterParams.status, filterParams.type,
        PageableParams.fromQuery(query)
      ).then(response => {
        resolve({
          data: response.data,
          page: query.page,
          totalCount: response.count,
        });
      }).catch((error) => reject(error));
    });

  const applyFilter = (params: ICustomerFinancialOperationsFilterParams) => {
    setFilterParams(params);
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({
        page: 0,
      } as Query<CustomerFinancialOperation>);
    }
  };

  const exportDataCustomerOperations = () => {
    showLoadingDialog(true);
    CustomerService.exportCustomerOperations(
      customerId, filterParams.start_date, filterParams.end_date,
      filterParams.description, filterParams.store_name,
      filterParams.status, filterParams.type
    ).then(res => {
      fileDownload(res.data, "lancamentos.xlsx");
    }).catch(err => {
      NotificationManager.error(
        "Erro ao fazer download do Arquivo de Lançamentos.",
        "Arquivo de Remessa"
      );
    }).finally(() => {
      showLoadingDialog(false);
    });
  };

  return (
    <>
      <LoadingDialog open={isShowLoadingDialog} message="Baixando o arquivo, aguarde ..." />
      <CustomerFinancialOperationsFilter params={filterParams} onFilterChanged={applyFilter} />
      <Card>
        <CardContent>
          <Grid container direction="row" spacing={1} justify="flex-end" style={{ marginBottom: "10px" }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<GetAppOutlinedIcon />}
              onClick={exportDataCustomerOperations}
              disabled={tableRef.current?.totalCount === 0}
            >
              Exportar
            </Button>
          </Grid>

          <MaterialTable
            tableRef={tableRef}
            columns={[
              { field: "id", hidden: true, width: 60 },
              { field: "created_at", title: "Data", type: "datetime", defaultSort: "desc" },
              { field: "description", title: "Descrição" },
              { field: "store_name", title: "Loja" },
              {
                field: "status", title: "Situação",
                render: (rowData, type) => rowData.status ? t("financial_entry_status." + rowData.status) : ""
              },
              {
                field: "courier_value", title: "Valor do Entregador", type: "currency", emptyValue: "---",
                render: (rowData, type) => rowData.courier_value === 0 ? "---" : numbro(rowData.courier_value).formatCurrency()
              },
              {
                field: "speedy_value", title: "Taxa Speedy", type: "currency", emptyValue: "---",
                render: (rowData, type) => rowData.speedy_value === 0 ? "---" : numbro(rowData.speedy_value).formatCurrency()
              },
              {
                field: "value", title: "Valor", type: "currency",
                render: (rowData, type) => (
                  <Alert severity={mapPaymentStatus(rowData.type)} icon={false}>
                    { mapPaymentValue(rowData.type, rowData.value)}
                  </Alert>
                ),
              },
            ]}
            data={loadData}
            components={{
              ...DEFAULT_TABLE_COMPONENTS,
              Container: (props: any) => <div {...props}></div>
            }}
            localization={TABLE_L10N_PTBR}
            options={DEFAULT_TABLE_OPTIONS}
          />
        </CardContent>
      </Card>
    </>
  );
};
