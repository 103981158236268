import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import numbro from 'numbro';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';

export const DEFAULT_LOCALE = "pt-BR";
export const CURRENT_LOCALE = DEFAULT_LOCALE;

export const DEFAULT_DATE_LOCALE = ptBR;
export const CURRENT_DATE_LOCALE = DEFAULT_DATE_LOCALE;
export const CURRENT_DATE_FORMAT = "dd/MM/yyyy";
export const CURRENT_DATE_TIME_FORMAT = "dd/MM/yyyy HH:mm";
export const CURRENT_DATE_FORMAT_NA = "yyyy-MM-dd";
export const CURRENT_DATE_FORMAT_MONTH_YEAR = "LLLL-yy"; //outubro-21
export const CURRENT_TIME_FORMAT = "HH:mm";


registerLocale(DEFAULT_LOCALE, CURRENT_DATE_LOCALE);
setDefaultLocale(DEFAULT_LOCALE);

const numberFormat: numbro.Format = {
  thousandSeparated: true,
  mantissa: 2,
  optionalMantissa: true,
  trimMantissa: false,
  average: false
}
const currencyFormat: numbro.Format = {
  thousandSeparated: true,
  mantissa: 2,
  optionalMantissa: false,
  trimMantissa: false,
  average: false,
  spaceSeparated: true
}
const ptBRNumberSpecs: numbro.NumbroLanguage = {
  languageTag: "pt-BR",
  delimiters: { thousands: ".", decimal: "," },
  abbreviations: { thousand: "mil", million: "milhões", billion: "b", trillion: "t" },
  ordinal: function () { return "º" },
  defaults: numberFormat,
  currency: { symbol: "R$", position: "prefix", code: "BRL" },
  currencyFormat: currencyFormat,
  formats: {
    fourDigits: { totalLength: 4, spaceSeparated: true, average: false },
    fullWithTwoDecimals: { output: "currency", mantissa: 2, spaceSeparated: true, thousandSeparated: true },
    fullWithTwoDecimalsNoCurrency: { mantissa: 2, thousandSeparated: true },
    fullWithNoDecimals: { output: "currency", spaceSeparated: true, thousandSeparated: true, mantissa: 0 }
  }
}
numbro.registerLanguage(ptBRNumberSpecs, true);

export const getNumberSpecs = (): numbro.NumbroLanguage => numbro.languageData(numbro.language());

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(HttpApi)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    lng: DEFAULT_LOCALE,
    preload: [DEFAULT_LOCALE],
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${process.env.REACT_APP_VERSION}`,
      reloadInterval: false
    }
  });

export default i18n;