import { formatISO, formatRFC3339 } from "date-fns";
import { apiAxios as axios } from "../store/api/config";
import { getURL, urls } from "../store/api/urls";
import { convertToBoolean, convertToDate, convertToNumber } from "../utils/utils";
import { IPageableParams, PagedResponse } from "./types/common";
import { ActionChangeStatusCourier, BlockedCourierCustomer, CourierAccountStatus, CourierCustomerBlock, CourierDeliveryRelatoryRanking, CourierDeliveryRequestOrder, CourierDetails, CourierFinancialStats, CourierOperation, CourierResponse, CourierUserDetails, CourierUserStatus, CreateUpdateCourier, OnlineCourierUserDetails, ReportCouriersResponse } from "./types/courier";
import { DeliveryRequestStatus } from "./types/deliveryRequest";
import { RankingCouriersResponse } from "./types/rankingCouriers";
import { ReferralCourierResponse } from "./types/referral";

export interface ICourierService {
  getCouriers: (
    name: string,
    email: string,
    phonenumber: string,
    createdAtAfter: Date | null,
    createdAtBefore: Date | null,
    courierAccountStatus: string | undefined,
    vehicleLicensePlate: string,
    vehicleType: string,
    regionsIds: number[],
    district: string,
    validBankData: boolean | null,
    pageParams: IPageableParams
  ) => Promise<PagedResponse<CourierResponse>>;

  getOnlineCouriersInRegion: (regionId: number) => Promise<OnlineCourierUserDetails[]>;

  getCourier: (courierId: number) => Promise<CourierDetails>;

  getCourierUserDetails: (courierId: number) => Promise<CourierUserDetails>;

  getCourierActivity: (courierId: number, startDate: Date, endDate: Date) => Promise<CourierOperation[]>;

  getCourierFinancialStats: (courierId: number) => Promise<CourierFinancialStats>;

  getCouriersByName: (name: string, courier_status?: CourierAccountStatus | undefined) => Promise<CourierResponse[]>;

  getCouriersByRegionOrderRanking: (regions: number[], speedy_ranking: string, pageParams: IPageableParams) => Promise<PagedResponse<RankingCouriersResponse>>;

  createCourier: (courier: CreateUpdateCourier) => Promise<any>;

  updateCourier: (courierId: number, courier: CreateUpdateCourier) => Promise<any>;

  activateCourier: (courierId: number) => Promise<any>;

  resetCourierUserStatus: (courierId: number) => Promise<any>;

  setCourierUserPassword: (courierId: number, password: string) => Promise<any>;

  changeStatusCourier: (
    courierId: number,
    reason: string,
    action: string | null
  ) => Promise<any>;

  addCredits: (
    courierId: number | null,
    description: string,
    value: number,
    customerId: number | null,
    customerValue: number,
    storeId: number | null,
    origin: string,
    deliveryId: number | null
  ) => Promise<any>;

  getLogs: (courierId: number) => Promise<any>;

  getOrderHistory: (
    courierId: number,
    request_number: string | null,
    order_number: string | null,
    status: DeliveryRequestStatus | null,
    customer_name: string | null,
    store_name: string | null,
    requested_time_after: Date | null,
    requested_time_before: Date | null,
    pageParams: IPageableParams
  ) => Promise<PagedResponse<CourierDeliveryRequestOrder>>;

  getBanks: () => Promise<any>;

  getDeliveriesRelatory: (courierId: number, startDate: string, endDate: string) => Promise<CourierDeliveryRelatoryRanking>;

  getCourierBlockList: (courierId: number) => Promise<PagedResponse<CourierCustomerBlock>>;

  blockCourier: (courierId: number, duration: number, reason: string, customerId?: number | null) => Promise<any>;

  unblockCourier: (blockId: number) => Promise<any>;

  addRegisterDebitCourier: (
    courierId: number,
    description: string,
    discount_value: number
  ) => Promise<any>;

  getProofPayment: (urlProof: string) => Promise<any>;

  changeStatusCourierBank: (courierId: number, valid_bank_data: boolean) => Promise<any>;

  getIndications: (courierId: number) => Promise<ReferralCourierResponse[]>;

  getReportCouriers: (
    region: number,
    date_after: Date,
    date_before: Date,
  ) => Promise<ReportCouriersResponse>;
};

function buildCourierFormData(courier: CreateUpdateCourier): FormData {
  const result = new FormData();

  const courierKeys = Object.keys(courier);
  courierKeys.forEach((key) => {
    const rawValue = (courier as any)[key];
    if (rawValue instanceof Blob) {
      result.append(key, rawValue);
    } else if (rawValue instanceof Date) {
      result.append(key, formatRFC3339(rawValue));
    } else {
      result.append(key, String((courier as any)[key]));
    }
  });

  return result;
};

const CourierService: ICourierService = {
  getCouriersByName: async (name: string, courier_status: CourierAccountStatus | undefined = undefined) => {
    const url = getURL(`${urls.COURIER}`, {page: 1, page_size: 75, ordering: "name", name: name, courier_account_status: courier_status});
    return axios.get(url)
      .then((response) => Promise.resolve(response.data.results))
      .catch((error) => Promise.reject(error));
  },

  getCouriersByRegionOrderRanking: async (regions: number[], speedy_ranking: string, pageParams: IPageableParams) => {
    pageParams.ordering = "new_ranking_points";
    const url = getURL(`${urls.COURIER}ranking/`, {
      ...pageParams,   
      regions: regions,
      speedy_ranking: speedy_ranking === " " ? null : speedy_ranking
    });
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then((response) => {
          const result: PagedResponse<RankingCouriersResponse> = {
            data: response.data.results,
            count: response.data.count,
          };
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  getCouriers: async (
    name: string,
    email: string,
    phonenumber: string,
    createdAtAfter: Date | null,
    createdAtBefore: Date | null,
    courierAccountStatus: string | undefined,
    vehicleLicensePlate: string,
    vehicleType: string,
    regionsIds: number[],
    district: string,
    validBankData: boolean | null,
    pageParams: IPageableParams
  ) => {
    const url = getURL(urls.COURIER, {
      ...pageParams,
      name: name,
      email: email,
      phonenumber: phonenumber,
      created_at_after: createdAtAfter ? formatRFC3339(createdAtAfter) : null,
      created_at_before: createdAtBefore ? formatRFC3339(createdAtBefore) : null,
      courier_account_status: courierAccountStatus,
      vehicle_license_plate: vehicleLicensePlate,
      vehicle_type: vehicleType,
      region: regionsIds,
      district: district,
      valid_bank_data: validBankData
    });
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then((response) => {
          const data: CourierResponse[] = response.data.results.map((item: any) => {
            return {
              ...item,
              id: Number(item.id),
              created_at: convertToDate(item.created_at)
            };
          });
          const result: PagedResponse<CourierResponse> = {
            data: data,
            count: response.data.count,
          };
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  getOnlineCouriersInRegion: (regionId: number) => {
    const url = `${urls.DELIVERY_REQUEST}get_region_couriers/`;
    return new Promise((resolve, reject) => {
      axios.post(url, { region: regionId })
      .then((response) => {
        const result: OnlineCourierUserDetails[] = response.data.results.map((item: any) => {
          return {
            ...item,
            last_update: convertToDate(item.last_update),
            delivery_request_id: convertToNumber(item.delivery_request_id),
            latitude: convertToNumber(item.latitude),
            longitude: convertToNumber(item.longitude),
          }
        });
        resolve(result);
      })
      .catch((error) => reject(error));
    })
  },

  getCourier: (courierId: number) => {
    return new Promise((resolve, reject) => {
      axios.get(`${urls.COURIER}${courierId}/`)
        .then((response) => {
          const result: CourierDetails = {
            ...response.data,
            created_at: convertToDate(response.data.created_at),
            updated_at: convertToDate(response.data.updated_at),
            birthdate: convertToDate(response.data.birthdate),
            drivers_license_expiration: convertToDate(response.data.drivers_license_expiration),
            accredidation_date: convertToDate(response.data.accredidation_date),
            has_accepted_terms: convertToBoolean(response.data.has_accepted_terms),
            ranking_points: convertToNumber(response.data.ranking_points, null),
            score: convertToNumber(response.data.score),
            valid_bank_data: convertToBoolean(response.data.valid_bank_data),
            reviewed_by: convertToNumber(response.data.reviewed_by)
          };
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  getCourierUserDetails: (courierId: number) => {
    return new Promise((resolve, reject) => {
      axios.get(`${urls.COURIER}${courierId}/reset_courier_status/`)
        .then((response) => {
          const result: CourierUserDetails = {
            ...response.data,
            created_at: convertToDate(response.data.created_at),
            updated_at: convertToDate(response.data.updated_at),
            online: convertToBoolean(response.data.online),
            latitude: convertToNumber(response.data.latitude),
            longitude: convertToNumber(response.data.longitude),
            suspended: convertToBoolean(response.data.suspended)
          };
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  getCourierActivity: (courierId: number, startDate: Date, endDate: Date) => {
    return new Promise((resolve, reject) => {
      axios.post(`${urls.COURIER_FINANCE}courier_operations/`, {
        courier: courierId,
        start_date: startDate ? formatISO(startDate, { representation: 'date' }) : null,
        end_date: endDate ? formatISO(endDate, { representation: 'date' }) : null
      })
        .then((response) => {
          const result: CourierOperation[] = response.data.map((item: any) => {
            return {
              ...item,
              created_at: convertToDate(item.created_at),
              updated_at: convertToDate(item.updated_at),
              value: convertToNumber(item.value),
              tax: convertToNumber(item.tax),
              total: convertToNumber(item.total),
              confirmation_date: convertToDate(item.confirmation_date),
              payment_date: convertToDate(item.payment_date),
              canceled_date: convertToDate(item.canceled_date),
              account: convertToNumber(item.account),
            };
          });
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  getCourierFinancialStats: (courierId: number) => {
    return new Promise((resolve, reject) => {
      axios.post(`${urls.COURIER_FINANCE}graphs/`, {
        courier: courierId
      })
        .then((response) => {
          const result: CourierFinancialStats = {
            ...response.data,
            current_balance: convertToNumber(response.data.current_balance, 0),
            today_earnings: convertToNumber(response.data.today_earnings, 0),
            current_week_earnigns: convertToNumber(response.data.current_week_earnigns, 0),
            last_four_weeks_earnings: convertToNumber(response.data.last_four_weeks_earnings, 0),
            graph: {
              Sunday: convertToNumber(response.data.graph.Sunday, 0),
              Monday: convertToNumber(response.data.graph.Monday, 0),
              Tuesday: convertToNumber(response.data.graph.Tuesday, 0),
              Wednesday: convertToNumber(response.data.graph.Wednesday, 0),
              Thursday: convertToNumber(response.data.graph.Thursday, 0),
              Friday: convertToNumber(response.data.graph.Friday, 0),
              Saturday: convertToNumber(response.data.graph.Saturday, 0),
            }
          };
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  createCourier: (courier: CreateUpdateCourier) => {
    return new Promise((resolve, reject) => {
      axios.post(urls.COURIER, buildCourierFormData(courier))
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateCourier: (courierId: number, courier: CreateUpdateCourier) => {
    const formData = new FormData();

    const courierKeys = Object.keys(courier);
    courierKeys.forEach((key) => {
      const rawValue = (courier as any)[key];
      if (rawValue instanceof Blob) {
        formData.append(key, rawValue);
      } else if (rawValue instanceof Date) {
        formData.append(key, formatRFC3339(rawValue));
      } else {
        formData.append(key, String((courier as any)[key]));
      }
    });

    return new Promise((resolve, reject) => {
      axios.patch(`${urls.COURIER}${courierId}/`, formData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  activateCourier: (id: number) => {
    return axios.post(`${urls.COURIER}activate_courier/`, { courier: id });
  },

  resetCourierUserStatus: (courierId: number) => {
    return axios.post(`${urls.COURIER}${courierId}/reset_courier_status/`, {
      trip_id: null,
      delivery_request_id: null,
      delivery_requests: [],
      courier_status: CourierUserStatus.AVAILABLE
    });
  },

  setCourierUserPassword: (courierId: number, password: string) => {
    return axios.post(`${urls.COURIER}${courierId}/change_password/`, {
      password: password,
      password_confirmation: password,
    });
  },

  changeStatusCourier: async (
    courierId: number,
    reason: string,
    action: string | null
  ) => {
    let url = "";
    switch (action) {
      case ActionChangeStatusCourier.REACTIVATE_COURIER:
        url = `${urls.COURIER}reactivate_courier/`;
        break;
      case ActionChangeStatusCourier.DEACTIVATE_COURIER:
        url = `${urls.COURIER}deactivate_courier/`;
        break;
      case ActionChangeStatusCourier.DENY_REGISTRATION_COURIER:
        url = `${urls.COURIER}deny_registration/`;
        break;
      case ActionChangeStatusCourier.SUSPEND_COURIER:
        url = `${urls.COURIER}suspend/`;
        break;
      case ActionChangeStatusCourier.BAN_COURIER:
        url = `${urls.COURIER}ban/`;
        break;
      default:
        return null;
    }
    return axios.post(url, { courier: courierId, reason });
  },

  changeStatusCourierBank: async (
    courierId: number,
    valid_bank_data: boolean
  ) => {
      let url = `${urls.COURIER}${courierId}/set_courier_bank_valid/`;
      return axios.put(url, { valid_bank_data: valid_bank_data });
  },

  addCredits: async (
    courierId: number | null,
    description: string,
    value: number,
    customerId: number | null,
    customerValue: number | null,
    storeId: number | null,
    origin: string,
    deliveryId: number | null
  ) => {
    return axios.post(`${urls.COURIER_FINANCE}add_credits/`, {
      courier: courierId,
      description: description,
      courier_value: value,
      origin: origin,
      customer: customerId,
      customer_value: customerValue,
      delivery_request: deliveryId,
      referenced_store: storeId === 0 ? null : storeId
    })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getLogs: async (courierId: number) => {
    return axios.get(`${urls.COURIER}${courierId}/logs/`);
  },

  getOrderHistory: (
    courierId: number,
    request_number: string | null,
    order_number: string | null,
    status: DeliveryRequestStatus | null,
    customer_name: string | null,
    store_name: string | null,
    requested_time_after: Date | null,
    requested_time_before: Date | null,
    pageParams: IPageableParams
  ) => {
    const url = `${urls.DELIVERY_REQUEST}operational_history/?page=${pageParams.page}&page_size=${pageParams.page_size}`;
    return new Promise((resolve, reject) => {
      axios.post(url, {
        courier: courierId,
        request_number: request_number ? request_number : undefined,
        order_number: order_number ? order_number : undefined,
        status: status ? status : undefined,
        customer_name: customer_name ? customer_name : undefined,
        store_name: store_name ? store_name : undefined,
        requested_time_after: requested_time_after ? formatRFC3339(requested_time_after) : undefined,
        requested_time_before: requested_time_before ? formatRFC3339(requested_time_before) : undefined,
        ordering: pageParams.ordering,
      }).then((response) => {
        const data: CourierDeliveryRequestOrder[] = response.data.results.map((item: any) => {
          return {
            ...item,
            id: Number(item.id),
            created_at: convertToDate(item.created_at),
            requested_time: convertToDate(item.requested_time),
            closed_date: convertToDate(item.closed_date),
            delivery_value: convertToNumber(item.delivery_value),
            courier_value: convertToNumber(item.courier_value),
          };
        });
        const result: PagedResponse<CourierDeliveryRequestOrder> = {
          data: data,
          count: response.data.count,
        };
        resolve(result);
      }).catch((error) => reject(error));
    });
  },

  getBanks: async () => {
    return axios.get(`${urls.BANKS}`);
  },

  getDeliveriesRelatory: (courierId: number, startDate: string, endDate: string) => {
    return new Promise((resolve, reject) => {
      axios.get(`${urls.COURIER}${courierId}/deliveries_relatory/?start_date=${startDate ? startDate : null}&end_date=${endDate ? endDate : null}`)
        .then((response) => {
          const result: CourierDeliveryRelatoryRanking = {
            ...response.data,
            accept_checkin_delays: convertToNumber(response.data.accept_checkin_delays),
            accepted: convertToNumber(response.data.accepted),
            checkout_deliver_delays: convertToNumber(response.data.checkout_deliver_delays),
            finished: convertToNumber(response.data.finished),
            finished_0_3: convertToNumber(response.data.finished_0_3),
            finished_3_8: convertToNumber(response.data.finished_3_8),
            finished_8_gt: convertToNumber(response.data.finished_8_gt),
            ignored: convertToNumber(response.data.ignored),
            ranking_points: convertToNumber(response.data.ranking_points),
            refused: convertToNumber(response.data.refused),
            speedy_ranking: response.data.speedy_ranking,
            stores: convertToNumber(response.data.stores),
            total_deliveries: response.data.total_deliveries == null ? 0 : convertToNumber(response.data.total_deliveries)
          };
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  getCourierBlockList: (courierId: number) => {
    const url = `${urls.COURIER}${courierId}/active_blocklist_records/`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(response => {
          const data: CourierCustomerBlock[] = response.data.map((item: any) => {
            let customers: BlockedCourierCustomer[] = [];
            if (item.customers) {
              customers = item.customers.map((customer: any) => customer);
            }

            return {
              ...item,
              all_customers: convertToBoolean(item.all_customers),
              customers: customers,
              expired: convertToBoolean(item.expired),
              duration: convertToNumber(item.duration),
              undefined_time: convertToBoolean(item.undefined_time),
              created_at: convertToDate(item.created_at)
            }
          });

          const result: PagedResponse<CourierCustomerBlock> = {
            data: data,
            count: data.length
          };

          resolve(result);
        })
        .catch(error => reject(error));
    });
  },

  blockCourier: (courierId: number, duration: number, reason: string, customerId: number | null = null) => {
    const url = urls.BLOCKLIST;
    return axios.post(url, {
      courier: courierId,
      customer: customerId,
      all_customers: (customerId === null),
      undefined_time: (duration === 0),
      duration: duration !== 0 ? duration : null,
      reason: reason
    })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  unblockCourier: (blockId: number) => {
    const url = `${urls.BLOCKLIST}${blockId}/expire_record/`;
    return axios.patch(url, { expired: true })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  
  addRegisterDebitCourier: (courierId: number, description: string, discount_value: number) => {
    const url = `${urls.COURIER_FINANCE}remove_credits/`;
    return axios.post(url, {courier: courierId, description: description, discount_value: discount_value})
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getProofPayment: async (urlProof: string) => {
    const url = urlProof;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                responseType: "text",
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => reject(error));
    });
  },

  getIndications: (courierId: number) => {
    const url = `${urls.COURIER}${courierId}/referral_codes/`;
    return new Promise((resolve, reject) => {
      axios.get(url)
      .then((response) => {
        const result: ReferralCourierResponse[] = response.data.map((item: any) => {
          return {
            ...item,
            created_at: convertToDate(item.created_at),
            revoked_at: item.revoked_at ? convertToDate(item.revoked_at) : null,
            closed_at: item.closed_at ? convertToDate(item.closed_at) : null,
            deliveries_count: convertToNumber(item.deliveries_count),
            revoked: convertToBoolean(item.revoked),
            closed: convertToBoolean(item.closed),
          }
        });
        resolve(result);
      })
      .catch((error) => reject(error));
    })
  },

  getReportCouriers: (
    region: number,
    date_after: Date,
    date_before: Date,
  ) => {
      const url = getURL(`${urls.COURIER}couriers_onboarding/`, {
          date_after: formatISO(date_after, { representation: "date" }),
          date_before: formatISO(date_before, { representation: "date" }),
          region: region
      });
      return new Promise((resolve, reject) => {
          axios
            .get(url).then((response) => {
              const result: ReportCouriersResponse = {
                ...response.data,
                approved_count: convertToNumber(response.data.approved_count),
                new_registers: convertToNumber(response.data.new_registers),
                pending_count: convertToNumber(response.data.pending_count),
                rejected_count: convertToNumber(response.data.rejected_count)
              };
              resolve(result);
            }).catch((error) => reject(error));
        });
  },

};

export default CourierService;
