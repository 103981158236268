import React from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
} from "@material-ui/core";
import { FormikHelpers, useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { DEFAULT_LOCALE, CURRENT_DATE_FORMAT } from "../../i18n";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { encodeURLParams, URLParamType } from "../../utils/utils";

export interface ITopCouriersParams {
  numberOfRegisters: number;
  startDate: Date | null;
  endDate: Date | null;
  region: number;
  vehicleType: number;
  minimumStores: number;
};

export const INITIAL_FILTER_PARAMS: ITopCouriersParams = {
  numberOfRegisters: 5,
  startDate: null,
  endDate: null,
  region: 0,
  vehicleType: 0,
  minimumStores: 1
};

interface ICreditsFilterProps {
  params?: ITopCouriersParams;
  regionList: any[];
  onFilterChanged: (params: ITopCouriersParams) => void;
};

const validationSchema = Yup.object().shape({
  numberOfRegisters: Yup.number()
    .min(5, "Valor mínimo 5")
    .max(100, "Limite 100")
    .required("Campo obrigatório"),
  startDate: Yup.date()
    .required("Campo obrigatório").nullable(),
  endDate: Yup.date()
    .required("Campo obrigatório").nullable(),
  minimumStores: Yup.number()
    .min(1, "Valor mínimo 1")
    .max(100, "Limite 100")
    .required("Campo obrigatório"),
});

export const TopCouriersFilter: React.FC<ICreditsFilterProps> = ({ params = INITIAL_FILTER_PARAMS, regionList, onFilterChanged }) => {
  const history = useHistory();

  React.useEffect(() => {
    history.replace(encodeURLParams(history.location.pathname, params, [
      { "startDate": URLParamType.DATE },
      { "endDate": URLParamType.DATE }
    ]));
  }, [history, params]);

  const formik = useFormik<ITopCouriersParams>({
    initialValues: params,
    onSubmit: (values: ITopCouriersParams, formikHelpers: FormikHelpers<ITopCouriersParams>) => {
      onFilterChanged(values);
    },
    onReset: (values: ITopCouriersParams, formikHelpers: FormikHelpers<ITopCouriersParams>) => {
      onFilterChanged(INITIAL_FILTER_PARAMS);
      formikHelpers.setValues(INITIAL_FILTER_PARAMS);
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <div className="page-filter">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          <Grid item md={2}>
            <TextField
              id="numberOfRegisters"
              label="Número de Registros"
              variant="outlined"
              value={formik.values.numberOfRegisters}
              onChange={(event) => {
                const clearedValue = event.target.value.replace(/\D/g, "");
                let value: number | String = "";
                if (clearedValue !== "") {
                  value = Number(clearedValue);
                }

                event.target.value = String(value);
                formik.handleChange(event);
              }}
              helperText={formik.errors.numberOfRegisters}
              error={!!formik.errors.numberOfRegisters}
              InputLabelProps={{ required: true }}
              fullWidth
            />
          </Grid>
          <Grid item md={2}>
            <DatePicker
              id="startDate"
              name="startDate"
              onChange={val => {
                formik.setFieldValue("startDate", val);
              }}
              maxDate={new Date()}
              selected={formik.values.startDate}
              startDate={formik.values.startDate}
              endDate={formik.values.endDate}
              selectsStart
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_FORMAT}
              timeCaption="Hora"
              isClearable
              autoComplete="off"
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data - Início"
                variant="outlined"
                size="small"
                autoComplete="false"
                error={!!formik.errors.startDate}
                helperText={formik.errors.startDate}
                InputLabelProps={{ required: true }}
                fullWidth />}
            />
          </Grid>
          <Grid item md={2}>
            <DatePicker
              id="endDate"
              name="endDate"
              onChange={val => {
                formik.setFieldValue("endDate", val);
              }}
              maxDate={new Date()}
              selected={formik.values.endDate}
              startDate={formik.values.startDate}
              endDate={formik.values.endDate}
              minDate={formik.values.startDate}
              selectsEnd
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_FORMAT}
              timeCaption="Hora"
              isClearable
              autoComplete="off"
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data - Fim"
                variant="outlined"
                size="small"
                autoComplete="false"
                error={!!formik.errors.endDate}
                helperText={formik.errors.endDate}
                InputLabelProps={{ required: true }}
                fullWidth />}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              id="region"
              name="region"
              label="Região"
              select
              onChange={formik.handleChange}
              value={formik.values.region}
              variant="outlined"
              fullWidth
            >
              <MenuItem value={0}>Todos</MenuItem>
              {regionList.map(region => (
                <MenuItem key={region.id} value={region.id}>
                  {region.name} - {region.state}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={2}>
            <TextField
              id="minimumStores"
              label="Quantidade de Lojas"
              variant="outlined"
              value={formik.values.minimumStores}
              onChange={(event) => {
                const clearedValue = event.target.value.replace(/\D/g, "");
                let value: number | String = "";
                if (clearedValue !== "") {
                  value = Number(clearedValue);
                }

                event.target.value = String(value);
                formik.handleChange(event);
              }}
              helperText={formik.errors.minimumStores}
              error={!!formik.errors.minimumStores}
              InputLabelProps={{ required: true }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              Pesquisar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" type="reset">
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
