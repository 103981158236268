import React from "react";
import {
  Grid,
  Button,
  Typography,
  Tooltip,
  debounce,
} from "@material-ui/core";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";
import { CustomerCourierBlock } from "../../../services/types/customer";
import CustomerService from "../../../services/customerService";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../../components/ConfirmationDialog";
import { Delete, ReportProblem } from "@material-ui/icons";
import AuthService from "../../../services/authService";
import { CURRENT_DATE_TIME_FORMAT } from "../../../i18n";
import { format } from "date-fns";
import CourierService from "../../../services/courierService";
import BlockCourierDialog, { BlockCourierDialogHandle } from "./BlockCourierDialog";
import ShowIf from "../../../components/ShowIf";

interface ICustomerDetailBlockList {
  customerId: number;
}

export const CustomerDetailBlockList: React.FC<ICustomerDetailBlockList> = ({ customerId }) => {

  const tableRef = React.useRef<MaterialTableProps<CustomerCourierBlock>>();
  const blockCourierDialog = React.createRef<BlockCourierDialogHandle>();
  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();

  const [tableSize, setTableSize] = React.useState<number>(0);

  const loadData = (query: Query<CustomerCourierBlock>) =>
    new Promise<QueryResult<CustomerCourierBlock>>((resolve, reject) => {
      CustomerService.getCustomerBlockList(customerId).then(response => {
        setTableSize(response.count);
        resolve({
          data: response.data,
          page: 0,
          totalCount: response.count,
        });
      }).catch((error) => reject(error));
    });

  const refresh = () => {
    console.warn("tableRef.current", tableRef.current);
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({
        page: 0,
      } as Query<CustomerCourierBlock>);
    }
  };

  const handleNewBlock = () => {
    blockCourierDialog.current?.add()
      .then((block) => {
        CourierService.blockCourier(block.courier, block.duration, block.reason, block.customer)
          .then((response) => {
            refresh();
            blockCourierDialog.current?.hide();
            NotificationManager.success("Bloqueio salvo com sucesso.", "Novo Bloqueio");
          })
          .catch((error) => {
            const { status, data } = error.response;
            const errorMsg = "Ocorreu um erro ao salvar o Bloqueio";
            let remainingErrorMsgs;
            if (status === 400) {
              remainingErrorMsgs = blockCourierDialog.current?.showFieldErrors(data);
            }
            NotificationManager.error(remainingErrorMsgs || errorMsg, "Novo Bloqueio");
          });
      })
      .catch(error => { });
  }

  const handleUnblock = (block: CustomerCourierBlock) => {
    confirmationDialog.current?.confirm(
      "Remover Bloqueio",
      <Typography>Você tem certeza que deseja <strong>Remover</strong> este Bloqueio?
        <Typography>Está ação pode demorar alguns segundos.</Typography>
      </Typography>,
      "Remover",
      "Cancelar"
    ).then(() => {
      unblock(block.id);
    }).catch(() => { });
  };


  const unblock = React.useMemo(() => debounce(async (idBlock: number) => {
    return new Promise((resolve, reject) => {
      CourierService.unblockCourier(idBlock)
        .then((response) => {
          NotificationManager.success("Bloqueio removido com sucesso.", "Bloqueio");
          refresh();
          confirmationDialog.current?.hide();
        })
        .catch((error) => {
          NotificationManager.error("Não foi possível remover o Bloqueio.", "Bloqueio");
        });
    });
  }, 4000), [confirmationDialog]);

  return (
    <React.Fragment>
      <BlockCourierDialog ref={blockCourierDialog} customerId={customerId} />

      <ConfirmationDialog ref={confirmationDialog} />

      <Grid container xs={12} spacing={2} justify="flex-end">
        <ShowIf condition={AuthService.hasAuthorization("add_blocklist")}>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleNewBlock}
            >
              Novo Bloqueio
            </Button>
          </Grid>
        </ShowIf>
        <Grid item xs={12}>
          <MaterialTable
            tableRef={tableRef}
            columns={[
              { field: "id", hidden: true, width: 120 },
              {
                field: "created_at", title: "Data",
                render: (rowData, type) =>
                  <React.Fragment>
                    {
                      rowData.all_customers ?
                        <Tooltip title="Bloqueado em todos Clientes">
                          <ReportProblem className="cellIcon" color="error"></ReportProblem>
                        </Tooltip>
                        :
                        <React.Fragment></React.Fragment>
                    }
                    {format(rowData.created_at, CURRENT_DATE_TIME_FORMAT)}
                  </React.Fragment>
              },
              { field: "courier.name", title: "Entregador" },
              {
                field: "duration", title: "Duração",
                render: (rowData, type) => rowData.undefined_time ? "Tempo Indeterminado" : `${rowData.duration} minutos`
              },
              { field: "reason", title: "Motivo" },
            ]}
            actions={AuthService.hasAuthorization("change_blocklist") ? [
              (_rowData) => {
                return {
                  icon: () => <Delete />,
                  tooltip: 'Remover Bloqueio',
                  hidden: _rowData.all_customers,
                  onClick: (event, rowData) => handleUnblock(rowData as CustomerCourierBlock)
                }
              }
            ] : []}
            data={loadData}
            components={{
              ...DEFAULT_TABLE_COMPONENTS,
              Pagination: (props: any) => <span>&nbsp;</span>
            }}
            localization={TABLE_L10N_PTBR}
            options={{
              ...DEFAULT_TABLE_OPTIONS,
              sorting: false,
              pageSize: tableSize,
              pageSizeOptions: []
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
