import React, { useCallback, useEffect, useState } from "react";
import { CURRENT_DATE_TIME_FORMAT, DEFAULT_LOCALE, getNumberSpecs } from "../../i18n";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Link, useParams, useHistory } from "react-router-dom";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { useTranslation } from "react-i18next";
import DedicatedService from "../../services/dedicatedService";
import { CreateUpdateDedicated, DaysWeek, ProposalType, StatusDedicated, TurnCreateUpdate, TurnResponse } from "../../services/types/dedicated";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import CustomerService from "../../services/customerService";
import { PageableParams } from "../../components/TableTrinkets";
import { CurrencyInput } from "../../components/CurrencyInput";
import { convertToNumber } from "../../utils/utils";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { VehicleType } from "../../services/types/courier";
import RegionService from "../../services/regionService";
import { Autocomplete } from "@material-ui/lab";
import ShowIf from "../../components/ShowIf";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from "clsx";

interface DedicatedNewEditProps {
  dedicatedId: string;
}

export interface Dedicated {
  id: number;
  description: string;
  first_datetime: Date | null;
  modal_allowed: string;
  proposal_type: ProposalType;
  recurrent: boolean;
  status: StatusDedicated;
  store: number;
  turns: TurnCreateUpdate[];
}

const INITIAL_FORM_VALUES: Dedicated = {
  id: 0,
  description: "",
  modal_allowed: "",
  first_datetime: null,
  proposal_type: ProposalType.MINIMUM_EARNINGS,
  recurrent: true,
  status: StatusDedicated.ACTIVE,
  store: 0,
  turns: []
};

const hoursNowMore = () => {
  const date = new Date();
  let newDate = new Date(date.setHours(date.getHours() + 1));
  return newDate;
};

const isToday = (dayWeek: string) => {
  const days = [DaysWeek.SUNDAY, DaysWeek.MONDAY, DaysWeek.TUESDAY, DaysWeek.WEDNESDAY, DaysWeek.THURSDAY, DaysWeek.FRIDAY, DaysWeek.SATURDAY];
  const today = new Date();
  const day = days[today.getDay()];
  return day === dayWeek;
}

const validationSchema = Yup.object().shape({
  first_datetime: Yup.date().required("Campo obrigatório").nullable(),
  modal_allowed: Yup.string().required("Campo obrigatório").nullable(),
  proposal_type: Yup.string().required("Campo obrigatório").nullable(),
  recurrent: Yup.boolean().required("Campo obrigatório").nullable(),
  store: Yup.number().required("Campo obrigatório").moreThan(0, "Campo obrigatório").nullable(),
});

export const DedicatedNewEdit: React.FC = () => {
  const { dedicatedId } = useParams<DedicatedNewEditProps>();
  const [dedicated, setDedicated] = useState<Dedicated>(INITIAL_FORM_VALUES);
  const { t } = useTranslation();
  const [storeList, setStoreList] = React.useState<any[]>([]);
  const [selectedRegion, setSelectedRegion] = React.useState<number>();
  const history = useHistory();
  const [regionList, setRegionList] = React.useState<any[]>([]);
  const classes = useStyles();
  const [inputListSunday, setInputListSunday] = React.useState<TurnCreateUpdate[]>([{ checked: dedicatedId ? false : isToday(DaysWeek.SUNDAY), start_time: new Date(), end_time: hoursNowMore(), day: DaysWeek.SUNDAY, number_of_slots: 1, value: 0 }]);
  const [inputListMonday, setInputListMonday] = React.useState<TurnCreateUpdate[]>([{ checked: dedicatedId ? false : isToday(DaysWeek.MONDAY), start_time: new Date(), end_time: hoursNowMore(), day: DaysWeek.MONDAY, number_of_slots: 1, value: 0 }]);
  const [inputListTuesday, setInputListTuesday] = React.useState<TurnCreateUpdate[]>([{ checked: dedicatedId ? false : isToday(DaysWeek.TUESDAY), start_time: new Date(), end_time: hoursNowMore(), day: DaysWeek.TUESDAY, number_of_slots: 1, value: 0 }]);
  const [inputListWednesday, setInputListWednesday] = React.useState<TurnCreateUpdate[]>([{ checked: dedicatedId ? false : isToday(DaysWeek.WEDNESDAY), start_time: new Date(), end_time: hoursNowMore(), day: DaysWeek.WEDNESDAY, number_of_slots: 1, value: 0 }]);
  const [inputListThursday, setInputListThursday] = React.useState<TurnCreateUpdate[]>([{ checked: dedicatedId ? false : isToday(DaysWeek.THURSDAY), start_time: new Date(), end_time: hoursNowMore(), day: DaysWeek.THURSDAY, number_of_slots: 1, value: 0 }]);
  const [inputListFriday, setInputListFriday] = React.useState<TurnCreateUpdate[]>([{ checked: dedicatedId ? false : isToday(DaysWeek.FRIDAY), start_time: new Date(), end_time: hoursNowMore(), day: DaysWeek.FRIDAY, number_of_slots: 1, value: 0 }]);
  const [inputListSaturday, setInputListSaturday] = React.useState<TurnCreateUpdate[]>([{ checked: dedicatedId ? false : isToday(DaysWeek.SATURDAY), start_time: new Date(), end_time: hoursNowMore(), day: DaysWeek.SATURDAY, number_of_slots: 1, value: 0 }]);

  function pad(number: number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  };

  const loadDedicated = useCallback(async (id: number) => {
    const response = await DedicatedService.getDedicatedById(id);
    let loadedDedicated: Dedicated = {
      id: response.id,
      description: response.description,
      first_datetime: response.first_datetime,
      recurrent: response.recurrent,
      store: response.store.id,
      modal_allowed: response.modal_allowed,
      proposal_type: response.proposal_type,
      status: response.status,
      turns: response.turns
    };
    setDedicated(loadedDedicated);
  }, []);

  const loadTurns = useCallback(async (id: number) => {
    const response = await DedicatedService.getTurnsDedicated(id);
    var dateNow = new Date();

    const resultFormated: TurnCreateUpdate[] = response.map((item: any) => {
      var start_date: any[] = item.start_time.split(':');
      var end_date: any[] = item.end_time.split(':');
      return {
        ...item,
        checked: true,
        start_time: new Date(dateNow.setHours(start_date[0], start_date[1], start_date[2])),
        end_time: new Date(dateNow.setHours(end_date[0], end_date[1], end_date[2])),
        value: convertToNumber(item.value),
      }
    });

    const listSunday: TurnCreateUpdate[] = [];
    const listMonday: TurnCreateUpdate[] = [];
    const listTuesday: TurnCreateUpdate[] = [];
    const listWednesday: TurnCreateUpdate[] = [];
    const listThursday: TurnCreateUpdate[] = [];
    const listFriday: TurnCreateUpdate[] = [];
    const listSaturday: TurnCreateUpdate[] = [];

    resultFormated.forEach((item) => {
      switch (item.day) {
        case DaysWeek.SUNDAY:
          listSunday.push(item);
          break;
        case DaysWeek.MONDAY:
          listMonday.push(item);
          break;
        case DaysWeek.TUESDAY:
          listTuesday.push(item);
          break;
        case DaysWeek.WEDNESDAY:
          listWednesday.push(item);
          break;
        case DaysWeek.THURSDAY:
          listThursday.push(item);
          break;
        case DaysWeek.FRIDAY:
          listFriday.push(item);
          break;
        case DaysWeek.SATURDAY:
          listSaturday.push(item);
          break;
      };
    });

    if (listSunday.length > 0) {
      setInputListSunday(listSunday);
    }

    if (listMonday.length > 0) {
      setInputListMonday(listMonday);
    }

    if (listTuesday.length > 0) {
      setInputListTuesday(listTuesday);
    }

    if (listWednesday.length > 0) {
      setInputListWednesday(listWednesday);
    }

    if (listThursday.length > 0) {
      setInputListThursday(listThursday);
    }

    if (listFriday.length > 0) {
      setInputListFriday(listFriday);
    }

    if (listSaturday.length > 0) {
      setInputListSaturday(listSaturday);
    }
  }, []);

  useEffect(() => {
    if (dedicatedId) {
      loadDedicated(Number(dedicatedId));
      loadTurns(Number(dedicatedId));
    }
  }, [dedicatedId, loadDedicated, loadTurns]);

  const getStores = useCallback(async (regionId?: number) => {
    let regionsId = regionId ? [regionId] : undefined;
    const response = await CustomerService.getStores(undefined, undefined,
      regionsId, undefined, new PageableParams(1, 100, "name"));
    setStoreList(response.data);
  }, []);

  React.useEffect(() => {
    const getRegions = async () => {
      const response = await RegionService.loadAllRegions({ "ordering": "name" });
      setRegionList(response);
    }
    getRegions();
    getStores();
  }, [getStores]);

  React.useEffect(() => {
    getStores(selectedRegion);
  }, [selectedRegion, getStores]);

  const formik = useFormik({
    initialValues: dedicated,
    onSubmit: async (values) => {
      try {
        if (values.store === 0) {
          formik.setFieldError("store", "Selecione uma Loja.");
        } else {
          formik.setFieldError("store", undefined);
          var mensagemSucesso = "";

          const sunday: TurnCreateUpdate[] = inputListSunday.filter((item) => item.checked === true);
          const monday: TurnCreateUpdate[] = inputListMonday.filter((item) => item.checked === true);
          const tuesday: TurnCreateUpdate[] = inputListTuesday.filter((item) => item.checked === true);
          const wednesday: TurnCreateUpdate[] = inputListWednesday.filter((item) => item.checked === true);
          const thursday: TurnCreateUpdate[] = inputListThursday.filter((item) => item.checked === true);
          const friday: TurnCreateUpdate[] = inputListFriday.filter((item) => item.checked === true);
          const saturday: TurnCreateUpdate[] = inputListSaturday.filter((item) => item.checked === true);

          const listTurns: TurnCreateUpdate[] = [...sunday, ...monday, ...tuesday, ...wednesday, ...thursday, ...friday, ...saturday];
          const turns = listTurns.map((item: any, index: number) => {
            const start_time = new Date(item.start_time);
            const end_time = new Date(item.end_time);
            return {
              ...item,
              start_time: pad(start_time.getHours()) + ":" + pad(start_time.getMinutes()) + ":" + pad(start_time.getSeconds()),
              end_time: pad(end_time.getHours()) + ":" + pad(end_time.getMinutes()) + ":" + pad(end_time.getSeconds()),
              day: item.day,
              number_of_slots: convertToNumber(item.number_of_slots),
              value: convertToNumber(item.value)
            };
          });

          if (!dedicatedId && turns.length === 0) {
            NotificationManager.error(
              "Necessário adicionar ao menos um Turno",
              "Agendamento"
            );
          } else if (turns.find((e) => e.value === 0)) {
            NotificationManager.error(
              "Necessário inserir um Valor válido para o Turno",
              "Agendamento"
            );
          } else {
            const dedicatedCreateUpdate: CreateUpdateDedicated = {
              ...values,
              first_datetime: values.first_datetime ? values.first_datetime : new Date(),
              description: values.description,
              recurrent: values.recurrent,
              store: values.store,
              modal_allowed: values.modal_allowed,
              proposal_type: values.proposal_type,
              turns: turns as TurnResponse[]
            };
            if (dedicatedId) {
              await DedicatedService.updateDedicated(Number(dedicatedId), dedicatedCreateUpdate);
              mensagemSucesso = "Agendamento atualizado com sucesso!";
            } else {
              await DedicatedService.addDedicated(dedicatedCreateUpdate);
              mensagemSucesso = "Agendamento adicionado com sucesso!";
            }

            NotificationManager.success(
              mensagemSucesso,
              "Agendamento"
            );
            setInputListSunday([]);
            setInputListMonday([]);
            setInputListTuesday([]);
            setInputListWednesday([]);
            setInputListThursday([]);
            setInputListFriday([]);
            setInputListSaturday([]);
            goBack();
          }
        }
      } catch (error) {
        const { status, data } = error.response;
        const errorMsgs: string[] = [];
        let errorMsg = dedicatedId ? "Não foi possível Atulizar Agendamento!" : "Não foi possível Adicionr Agendamento!";
        if (status === 400) {
          for (var key in data) {
            var value = data[key];
            if (key in values) {
              formik.setFieldError(key, value);
            } else {
              errorMsgs.push(value);
            }
          }

          if (errorMsgs.length > 0) {
            errorMsg = errorMsgs.join(". \n");
          }
        }
        NotificationManager.error(errorMsg, "Agendamento");
      }
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const goBack = () => {
    history.push(`/dedicated/`);
  }

  const handleChecked = (e: any, turn: TurnCreateUpdate, index: number) => {
    const value = e.target.checked;
    switch (turn.day) {
      case DaysWeek.SUNDAY:
        const listSunday = [...inputListSunday];
        listSunday.forEach(item => {
          item.checked = value;
        });
        setInputListSunday(listSunday);
        break;
      case DaysWeek.MONDAY:
        const listMonday = [...inputListMonday];
        listMonday.forEach(item => {
          item.checked = value;
        });
        setInputListMonday(listMonday);
        break;
      case DaysWeek.TUESDAY:
        const listTuesday = [...inputListTuesday];
        listTuesday.forEach(item => {
          item.checked = value;
        });
        setInputListTuesday(listTuesday);
        break;
      case DaysWeek.WEDNESDAY:
        const listWednesday = [...inputListWednesday];
        listWednesday.forEach(item => {
          item.checked = value;
        });
        setInputListWednesday(listWednesday);
        break;
      case DaysWeek.THURSDAY:
        const listThursday = [...inputListThursday];
        listThursday.forEach(item => {
          item.checked = value;
        });
        setInputListThursday(listThursday);
        break;
      case DaysWeek.FRIDAY:
        const listFriday = [...inputListFriday];
        listFriday.forEach(item => {
          item.checked = value;
        });
        setInputListFriday(listFriday);
        break;
      case DaysWeek.SATURDAY:
        const listSaturday = [...inputListSaturday];
        listSaturday.forEach(item => {
          item.checked = value;
        });
        setInputListSaturday(listSaturday);
        break;
    };
  };

  const handleInput = (inputName: string, inputValue: any, turn: TurnCreateUpdate, index: number) => {
    const value = inputValue;
    const atribute = inputName;
    switch (turn.day) {
      case DaysWeek.SUNDAY:
        const listSunday: any = [...inputListSunday];
        listSunday[index][atribute] = value;
        setInputListSunday(listSunday);
        break;
      case DaysWeek.MONDAY:
        const listMonday: any = [...inputListMonday];
        listMonday[index][atribute] = value;
        setInputListMonday(listMonday);
        break;
      case DaysWeek.TUESDAY:
        const listTuesday: any = [...inputListTuesday];
        listTuesday[index][atribute] = value;
        setInputListTuesday(listTuesday);
        break;
      case DaysWeek.WEDNESDAY:
        const listWednesday: any = [...inputListWednesday];
        listWednesday[index][atribute] = value;
        setInputListWednesday(listWednesday);
        break;
      case DaysWeek.THURSDAY:
        const listThursday: any = [...inputListThursday];
        listThursday[index][atribute] = value;
        setInputListThursday(listThursday);
        break;
      case DaysWeek.FRIDAY:
        const listFriday: any = [...inputListFriday];
        listFriday[index][atribute] = value;
        setInputListFriday(listFriday);
        break;
      case DaysWeek.SATURDAY:
        const listSaturday: any = [...inputListSaturday];
        listSaturday[index][atribute] = value;
        setInputListSaturday(listSaturday);
        break;
    };

  };

  const handleRemoveSunday = (index: number) => {
    const list = [...inputListSunday];
    list.splice(index, 1);
    setInputListSunday(list);
  };

  const handleRemoveMonday = (index: number) => {
    const list = [...inputListMonday];
    list.splice(index, 1);
    setInputListMonday(list);
  };

  const handleRemoveTuesday = (index: number) => {
    const list = [...inputListTuesday];
    list.splice(index, 1);
    setInputListTuesday(list);
  };

  const handleRemoveWednesday = (index: number) => {
    const list = [...inputListWednesday];
    list.splice(index, 1);
    setInputListWednesday(list);
  };

  const handleRemoveThursday = (index: number) => {
    const list = [...inputListThursday];
    list.splice(index, 1);
    setInputListThursday(list);
  };

  const handleRemoveFriday = (index: number) => {
    const list = [...inputListFriday];
    list.splice(index, 1);
    setInputListFriday(list);
  };

  const handleRemoveSaturday = (index: number) => {
    const list = [...inputListSaturday];
    list.splice(index, 1);
    setInputListSaturday(list);
  };

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h1">
                {dedicatedId ? "Atualizar Agendamento" : "Adicionar Agendamento"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomBreadcrumbs
                pathList={[
                  { label: "Propostas de Agendamento", url: "/dedicated" },
                  {
                    label: `${dedicatedId ? "Atualizar Agendamento" : "Adicionar Agendamento"}`,
                    url: `${dedicatedId ? `/dedicated/edit/${dedicatedId}` : "/dedicated/new"}`,
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} xs={12}>
        <Grid item md={12} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Card>
              <CardContent>
                <Typography variant="h2">{dedicatedId ? "Atualizar Agendamento" : "Adicionar Agendamento"} </Typography>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <DatePicker
                      id="first_datetime"
                      name="first_datetime"
                      onChange={(val, event: React.SyntheticEvent) => {
                        formik.setFieldValue("first_datetime", val);
                      }}
                      disabled={dedicatedId ? true : false}
                      minDate={new Date()}
                      selected={formik.values.first_datetime}
                      startDate={formik.values.first_datetime}
                      selectsStart
                      locale={DEFAULT_LOCALE}
                      dateFormat={CURRENT_DATE_TIME_FORMAT}
                      timeCaption="Hora"
                      showTimeSelect
                      autoComplete="off"
                      wrapperClassName="MuiFormControl-fullWidth"
                      required={true}
                      customInput={<TextField
                        label="Data e Hora Início"
                        variant="outlined"
                        size="small"
                        error={!!formik.errors.first_datetime}
                        helperText={formik.errors.first_datetime}
                        fullWidth />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="description"
                      label="Descrição"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      error={!!formik.errors.description}
                      helperText={formik.errors.description}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="proposal-type-label">Tipo de Proposta</InputLabel>
                      <Select
                        required={true}
                        id="proposal_type"
                        name="proposal_type"
                        labelId="proposal-type-label"
                        label="Tipo de Proposta"
                        value={formik.values.proposal_type}
                        error={!!formik.errors.proposal_type}
                        onChange={formik.handleChange}
                      >
                        <MenuItem value={ProposalType.MINIMUM_EARNINGS} key={ProposalType.MINIMUM_EARNINGS}>
                          {t(`proposal_type_dedicated.${ProposalType.MINIMUM_EARNINGS}`)}
                        </MenuItem>
                        <MenuItem value={ProposalType.PLUS_EARNINGS} key={ProposalType.PLUS_EARNINGS}>
                          {t(`proposal_type_dedicated.${ProposalType.PLUS_EARNINGS}`)}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <ShowIf condition={!dedicatedId}>
                    <Grid item md={2}>
                      <Autocomplete
                        id="region"
                        size="small"
                        value={selectedRegion}
                        options={regionList}                  
                        getOptionLabel={(option) => `${option.name} - ${option.state}`}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedRegion(newValue.id);
                            formik.setFieldValue("region", newValue.id);
                          } else {
                            setSelectedRegion(undefined);
                            formik.setFieldValue("region", undefined);
                            formik.setFieldValue("store", undefined);
                          }
                        }}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            name="region"
                            label="Região"
                            required={true}
                            onChange={formik.handleChange}
                            value={selectedRegion}
                            variant="outlined"
                            fullWidth
                          />
                        }
                      />
                    </Grid>
                  </ShowIf>


                  <Grid item md={3}>
                    <TextField
                      id="store"
                      name="store"
                      label={!selectedRegion ? "Loja (Selecione uma Região*)" : "Loja"}
                      select
                      onChange={formik.handleChange}
                      disabled={dedicatedId ? true : false || selectedRegion === undefined ? true : false}
                      value={formik.values.store}
                      variant="outlined"
                      error={!!formik.errors.store}
                      helperText={formik.errors.store}
                      fullWidth
                    >
                      <MenuItem selected={true} disabled={true} value={0}>Loja</MenuItem>
                      {storeList.map((store) => (
                        <MenuItem key={store.id} value={store.id}>
                          {store.name}
                        </MenuItem>
                      ))}
                    </TextField>                            
                  </Grid>

                  <Grid item xs={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="modal_allowed-label">Tipo de Veículo</InputLabel>
                      <Select
                        required={true}
                        id="modal_allowed"
                        name="modal_allowed"
                        labelId="modal_allowed-label"
                        label="Tipo de Veículo"
                        value={formik.values.modal_allowed}
                        onChange={formik.handleChange}
                        error={!!formik.errors.modal_allowed}
                      >
                        <MenuItem value={VehicleType.BIKE} key={VehicleType.BIKE}>
                          {t(`vehicle_type.${VehicleType.BIKE}`)}
                        </MenuItem>
                        <MenuItem value={VehicleType.MOTORCYCLE} key={VehicleType.MOTORCYCLE}>
                          {t(`vehicle_type.${VehicleType.MOTORCYCLE}`)}
                        </MenuItem>
                        <MenuItem value={VehicleType.CAR} key={VehicleType.CAR}>
                          {t(`vehicle_type.${VehicleType.CAR}`)}
                        </MenuItem>
                        <MenuItem value={VehicleType.VAN} key={VehicleType.VAN}>
                          {t(`vehicle_type.${VehicleType.VAN}`)}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="recurrent"
                          name="recurrent"
                          checked={formik.values.recurrent}
                          onChange={formik.handleChange}
                          color="primary"
                        />
                      }
                      label="Recorrente"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <br></br>
            <Card style={{ overflow: "visible" }}>
              <CardContent>
                <Typography variant="h2">Turnos</Typography>
                <Grid container spacing={2}>
                  <List>
                    {/* Domingo */}
                    {inputListSunday.map((item, index) => (
                      <ListItem alignItems="flex-start" key={index} className={clsx({ [classes.itemNoCheck]: index > 0 && item.checked === false })}>
                        <Grid item className={classes.minGrid}>
                          {index === 0 &&
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={item.day + index}
                                  name={item.day + index}
                                  checked={item.checked}
                                  onChange={e => handleChecked(e, item, index)}
                                  color="primary"
                                />
                              }
                              label={t("dedicated_week." + item.day)}
                            />
                          }
                          {index > 0 && item.checked === true &&
                            <Grid item className={classes.marginIcon}>
                              <SubdirectoryArrowRightIcon />
                            </Grid>
                          }

                        </Grid>
                        {item.checked === false ? (
                          index === 0 && (
                            <Grid item className={classes.marginInput}>
                              <Typography className={classes.marginTypograph} variant="body2"> Indisponível</Typography>
                            </Grid>
                          )
                        ) : (
                          <>
                            <Grid item className={classes.marginInput}>
                              <DatePicker
                                name={"start_time"}
                                selected={item.start_time}
                                onChange={e => handleInput("start_time", e, item, index)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Hora Inicial"
                                dateFormat="HH:mm"
                                required={true}
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                  label="Hora Inicial"
                                  variant="outlined"
                                  size="small"
                                  fullWidth />}
                              />
                            </Grid>

                            <Grid item className={classes.marginInput}>
                              <DatePicker
                                name={"end_time"}
                                selected={item.end_time}
                                onChange={e => handleInput("end_time", e, item, index)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Hora Final"
                                dateFormat="HH:mm"
                                required={true}
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                  label="Hora Final"
                                  variant="outlined"
                                  size="small"
                                  fullWidth />}
                              />
                            </Grid>

                            <Grid item className={classes.marginInput}>
                              <TextField
                                name={"number_of_slots"}
                                required={true}
                                id={"number_of_slots_" + index}
                                label="Número de Vagas"
                                variant="outlined"
                                type="number"
                                onChange={(e) => handleInput(e.target.name, e.target.value, item, index)}
                                value={item.number_of_slots}
                                fullWidth
                              />
                            </Grid>
                            <Grid item className={classes.marginInput}>
                              <TextField
                                id={"value_" + index}
                                name={"value"}
                                label="Valor"
                                variant="outlined"
                                onChange={e => handleInput(e.target.name, e.target.value, item, index)}
                                value={item.value}
                                fullWidth
                                required={true}
                                InputProps={{
                                  inputProps: {
                                    min: 0.1
                                  },
                                  inputComponent: CurrencyInput,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {getNumberSpecs().currency.symbol}
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{
                                  maxLength: 10,
                                  min: 0.1
                                }}
                              />
                            </Grid>
                            {inputListSunday.length !== 1 && item.checked === true &&
                              <Tooltip title="Remover Turno">
                                <IconButton className={classes.paddingBtn} edge="end" aria-label="Remover" onClick={(e) => handleRemoveSunday(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            {inputListSunday.length - 1 === index && item.checked === true &&
                              <Tooltip title="Adicionar Turno">
                                <IconButton className={classes.paddingBtn} edge="end" aria-label="Adicionar" onClick={() => {
                                  setInputListSunday([...inputListSunday, { checked: true, start_time: new Date(), end_time: hoursNowMore(), day: DaysWeek.SUNDAY, number_of_slots: 1, value: 0 }]);
                                }}>
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </>
                        )}
                      </ListItem>
                    ))}
                    {/* Segunda */}
                    {inputListMonday.map((item, index) => (
                      <ListItem alignItems="flex-start" key={index} className={clsx({ [classes.itemNoCheck]: index > 0 && item.checked === false })}>
                        <Grid item className={classes.minGrid}>
                          {index === 0 &&
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={item.day + index}
                                  name={item.day + index}
                                  checked={item.checked}
                                  onChange={e => handleChecked(e, item, index)}
                                  color="primary"
                                />
                              }
                              label={t("dedicated_week." + item.day)}
                            />
                          }
                          {index > 0 && item.checked === true &&
                            <Grid item className={classes.marginIcon}>
                              <SubdirectoryArrowRightIcon />
                            </Grid>
                          }
                        </Grid>
                        {item.checked === false ? (
                          index === 0 && (
                            <Grid item className={classes.marginInput}>
                              <Typography className={classes.marginTypograph} variant="body2"> Indisponível</Typography>
                            </Grid>
                          )
                        ) : (
                          <>
                            <Grid item className={classes.marginInput}>
                              <DatePicker
                                name={"start_time"}
                                selected={item.start_time}
                                onChange={e => handleInput("start_time", e, item, index)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Hora Inicial"
                                dateFormat="HH:mm"
                                required={true}
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                  label="Hora Inicial"
                                  variant="outlined"
                                  size="small"
                                  fullWidth />}
                              />
                            </Grid>

                            <Grid item className={classes.marginInput}>
                              <DatePicker
                                name={"end_time"}
                                selected={item.end_time}
                                onChange={e => handleInput("end_time", e, item, index)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Hora Final"
                                dateFormat="HH:mm"
                                required={true}
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                  label="Hora Final"
                                  variant="outlined"
                                  size="small"
                                  fullWidth />}
                              />
                            </Grid>

                            <Grid item className={classes.marginInput}>
                              <TextField
                                name={"number_of_slots"}
                                required={true}
                                id={"number_of_slots_" + index}
                                label="Número de Vagas"
                                variant="outlined"
                                type="number"
                                onChange={e => handleInput(e.target.name, e.target.value, item, index)}
                                value={item.number_of_slots}
                                fullWidth
                              />
                            </Grid>
                            <Grid item className={classes.marginInput}>
                              <TextField
                                id={"value_" + index}
                                name={"value"}
                                label="Valor"
                                variant="outlined"
                                onChange={e => handleInput(e.target.name, e.target.value, item, index)}
                                value={item.value}
                                fullWidth
                                required={true}
                                InputProps={{
                                  inputComponent: CurrencyInput,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {getNumberSpecs().currency.symbol}
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{
                                  maxLength: 10
                                }}
                              />
                            </Grid>
                            {inputListMonday.length !== 1 && item.checked === true &&
                              <Tooltip title="Remover Turno">
                                <IconButton className={classes.paddingBtn} edge="end" aria-label="Remover" onClick={(e) => handleRemoveMonday(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            {inputListMonday.length - 1 === index && item.checked === true &&
                              <Tooltip title="Adicionar Turno">
                                <IconButton className={classes.paddingBtn} edge="end" aria-label="Adicionar" onClick={() => {
                                  setInputListMonday([...inputListMonday, { checked: true, start_time: new Date(), end_time: hoursNowMore(), day: DaysWeek.MONDAY, number_of_slots: 1, value: 0 }]);
                                }}>
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </>
                        )}
                      </ListItem>
                    ))}
                    {/* Terça */}
                    {inputListTuesday.map((item, index) => (
                      <ListItem alignItems="flex-start" key={index} className={clsx({ [classes.itemNoCheck]: index > 0 && item.checked === false })}>
                        <Grid item className={classes.minGrid}>
                          {index === 0 &&
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={item.day + index}
                                  name={item.day + index}
                                  checked={item.checked}
                                  onChange={e => handleChecked(e, item, index)}
                                  color="primary"
                                />
                              }
                              label={t("dedicated_week." + item.day)}
                            />
                          }
                          {index > 0 && item.checked === true &&
                            <Grid item className={classes.marginIcon}>
                              <SubdirectoryArrowRightIcon />
                            </Grid>
                          }
                        </Grid>
                        {item.checked === false ? (
                          index === 0 && (
                            <Grid item className={classes.marginInput}>
                              <Typography className={classes.marginTypograph} variant="body2"> Indisponível</Typography>
                            </Grid>
                          )
                        ) : (
                          <>
                            <Grid item className={classes.marginInput}>
                              <DatePicker
                                name={"start_time"}
                                selected={item.start_time}
                                onChange={e => handleInput("start_time", e, item, index)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Hora Inicial"
                                dateFormat="HH:mm"
                                required={true}
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                  label="Hora Inicial"
                                  variant="outlined"
                                  size="small"
                                  fullWidth />}
                              />
                            </Grid>

                            <Grid item className={classes.marginInput}>
                              <DatePicker
                                name={"end_time"}
                                selected={item.end_time}
                                onChange={e => handleInput("end_time", e, item, index)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Hora Final"
                                dateFormat="HH:mm"
                                required={true}
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                  label="Hora Final"
                                  variant="outlined"
                                  size="small"
                                  fullWidth />}
                              />
                            </Grid>

                            <Grid item className={classes.marginInput}>
                              <TextField
                                name={"number_of_slots"}
                                required={true}
                                id={"number_of_slots_" + index}
                                label="Número de Vagas"
                                variant="outlined"
                                type="number"
                                onChange={e => handleInput(e.target.name, e.target.value, item, index)}
                                value={item.number_of_slots}
                                fullWidth
                              />
                            </Grid>
                            <Grid item className={classes.marginInput}>
                              <TextField
                                id={"value_" + index}
                                name={"value"}
                                label="Valor"
                                variant="outlined"
                                onChange={e => handleInput(e.target.name, e.target.value, item, index)}
                                value={item.value}
                                fullWidth
                                required={true}
                                InputProps={{
                                  inputComponent: CurrencyInput,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {getNumberSpecs().currency.symbol}
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{
                                  maxLength: 10
                                }}
                              />
                            </Grid>
                            {inputListTuesday.length !== 1 && item.checked === true &&
                              <Tooltip title="Remover Turno">
                                <IconButton className={classes.paddingBtn} edge="end" aria-label="Remover" onClick={(e) => handleRemoveTuesday(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            {inputListTuesday.length - 1 === index && item.checked === true &&
                              <Tooltip title="Adicionar Turno">
                                <IconButton className={classes.paddingBtn} edge="end" aria-label="Adicionar" onClick={() => {
                                  setInputListTuesday([...inputListTuesday, { checked: true, start_time: new Date(), end_time: hoursNowMore(), day: DaysWeek.TUESDAY, number_of_slots: 1, value: 0 }]);
                                }}>
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </>
                        )}
                      </ListItem>
                    ))}

                    {/* Quarta */}
                    {inputListWednesday.map((item, index) => (
                      <ListItem alignItems="flex-start" key={index} className={clsx({ [classes.itemNoCheck]: index > 0 && item.checked === false })}>
                        <Grid item className={classes.minGrid}>
                          {index === 0 &&
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={item.day + index}
                                  name={item.day + index}
                                  checked={item.checked}
                                  onChange={e => handleChecked(e, item, index)}
                                  color="primary"
                                />
                              }
                              label={t("dedicated_week." + item.day)}
                            />
                          }
                          {index > 0 && item.checked === true &&
                            <Grid item className={classes.marginIcon}>
                              <SubdirectoryArrowRightIcon />
                            </Grid>
                          }
                        </Grid>
                        {item.checked === false ? (
                          index === 0 && (
                            <Grid item className={classes.marginInput}>
                              <Typography className={classes.marginTypograph} variant="body2"> Indisponível</Typography>
                            </Grid>
                          )
                        ) : (
                          <>
                            <Grid item className={classes.marginInput}>
                              <DatePicker
                                name={"start_time"}
                                selected={item.start_time}
                                onChange={e => handleInput("start_time", e, item, index)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Hora Inicial"
                                dateFormat="HH:mm"
                                required={true}
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                  label="Hora Inicial"
                                  variant="outlined"
                                  size="small"
                                  fullWidth />}
                              />
                            </Grid>

                            <Grid item className={classes.marginInput}>
                              <DatePicker
                                name={"end_time"}
                                selected={item.end_time}
                                onChange={e => handleInput("end_time", e, item, index)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Hora Final"
                                dateFormat="HH:mm"
                                required={true}
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                  label="Hora Final"
                                  variant="outlined"
                                  size="small"
                                  fullWidth />}
                              />
                            </Grid>

                            <Grid item className={classes.marginInput}>
                              <TextField
                                name={"number_of_slots"}
                                required={true}
                                id={"number_of_slots_" + index}
                                label="Número de Vagas"
                                variant="outlined"
                                type="number"
                                onChange={e => handleInput(e.target.name, e.target.value, item, index)}
                                value={item.number_of_slots}
                                fullWidth
                              />
                            </Grid>
                            <Grid item className={classes.marginInput}>
                              <TextField
                                id={"value_" + index}
                                name={"value"}
                                label="Valor"
                                variant="outlined"
                                onChange={e => handleInput(e.target.name, e.target.value, item, index)}
                                value={item.value}
                                fullWidth
                                required={true}
                                InputProps={{
                                  inputComponent: CurrencyInput,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {getNumberSpecs().currency.symbol}
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{
                                  maxLength: 10
                                }}
                              />
                            </Grid>
                            {inputListWednesday.length !== 1 && item.checked === true &&
                              <Tooltip title="Remover Turno">
                                <IconButton className={classes.paddingBtn} edge="end" aria-label="Remover" onClick={(e) => handleRemoveWednesday(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            {inputListWednesday.length - 1 === index && item.checked === true &&
                              <Tooltip title="Adicionar Turno">
                                <IconButton className={classes.paddingBtn} edge="end" aria-label="Adicionar" onClick={() => {
                                  setInputListWednesday([...inputListWednesday, { checked: true, start_time: new Date(), end_time: hoursNowMore(), day: DaysWeek.WEDNESDAY, number_of_slots: 1, value: 0 }]);
                                }}>
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </>
                        )}
                      </ListItem>
                    ))}

                    {/* Quinta */}
                    {inputListThursday.map((item, index) => (
                      <ListItem alignItems="flex-start" key={index} className={clsx({ [classes.itemNoCheck]: index > 0 && item.checked === false })}>
                        <Grid item className={classes.minGrid}>
                          {index === 0 &&
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={item.day + index}
                                  name={item.day + index}
                                  checked={item.checked}
                                  onChange={e => handleChecked(e, item, index)}
                                  color="primary"
                                />
                              }
                              label={t("dedicated_week." + item.day)}
                            />
                          }
                          {index > 0 && item.checked === true &&
                            <Grid item className={classes.marginIcon}>
                              <SubdirectoryArrowRightIcon />
                            </Grid>
                          }
                        </Grid>
                        {item.checked === false ? (
                          index === 0 && (
                            <Grid item className={classes.marginInput}>
                              <Typography className={classes.marginTypograph} variant="body2"> Indisponível</Typography>
                            </Grid>
                          )
                        ) : (
                          <>
                            <Grid item className={classes.marginInput}>
                              <DatePicker
                                name={"start_time"}
                                selected={item.start_time}
                                onChange={e => handleInput("start_time", e, item, index)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Hora Inicial"
                                dateFormat="HH:mm"
                                required={true}
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                  label="Hora Inicial"
                                  variant="outlined"
                                  size="small"
                                  fullWidth />}
                              />
                            </Grid>

                            <Grid item className={classes.marginInput}>
                              <DatePicker
                                name={"end_time"}
                                selected={item.end_time}
                                onChange={e => handleInput("end_time", e, item, index)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Hora Final"
                                dateFormat="HH:mm"
                                required={true}
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                  label="Hora Final"
                                  variant="outlined"
                                  size="small"
                                  fullWidth />}
                              />
                            </Grid>

                            <Grid item className={classes.marginInput}>
                              <TextField
                                name={"number_of_slots"}
                                required={true}
                                id={"number_of_slots_" + index}
                                label="Número de Vagas"
                                variant="outlined"
                                type="number"
                                onChange={e => handleInput(e.target.name, e.target.value, item, index)}
                                value={item.number_of_slots}
                                fullWidth
                              />
                            </Grid>
                            <Grid item className={classes.marginInput}>
                              <TextField
                                id={"value_" + index}
                                name={"value"}
                                label="Valor"
                                variant="outlined"
                                onChange={e => handleInput(e.target.name, e.target.value, item, index)}
                                value={item.value}
                                fullWidth
                                required={true}
                                InputProps={{
                                  inputComponent: CurrencyInput,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {getNumberSpecs().currency.symbol}
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{
                                  maxLength: 10
                                }}
                              />
                            </Grid>
                            {inputListThursday.length !== 1 && item.checked === true &&
                              <Tooltip title="Remover Turno">
                                <IconButton className={classes.paddingBtn} edge="end" aria-label="Remover" onClick={(e) => handleRemoveThursday(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            {inputListThursday.length - 1 === index && item.checked === true &&
                              <Tooltip title="Adicionar Turno">
                                <IconButton className={classes.paddingBtn} edge="end" aria-label="Adicionar" onClick={() => {
                                  setInputListThursday([...inputListThursday, { checked: true, start_time: new Date(), end_time: hoursNowMore(), day: DaysWeek.THURSDAY, number_of_slots: 1, value: 0 }]);
                                }}>
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </>
                        )}
                      </ListItem>
                    ))}

                    {/* Sexta */}
                    {inputListFriday.map((item, index) => (
                      <ListItem alignItems="flex-start" key={index} className={clsx({ [classes.itemNoCheck]: index > 0 && item.checked === false })}>
                        <Grid item className={classes.minGrid}>
                          {index === 0 &&
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={item.day + index}
                                  name={item.day + index}
                                  checked={item.checked}
                                  onChange={e => handleChecked(e, item, index)}
                                  color="primary"
                                />
                              }
                              label={t("dedicated_week." + item.day)}
                            />
                          }
                          {index > 0 && item.checked === true &&
                            <Grid item className={classes.marginIcon}>
                              <SubdirectoryArrowRightIcon />
                            </Grid>
                          }
                        </Grid>
                        {item.checked === false ? (
                          index === 0 && (
                            <Grid item className={classes.marginInput}>
                              <Typography className={classes.marginTypograph} variant="body2"> Indisponível</Typography>
                            </Grid>
                          )
                        ) : (
                          <>
                            <Grid item className={classes.marginInput}>
                              <DatePicker
                                name={"start_time"}
                                selected={item.start_time}
                                onChange={e => handleInput("start_time", e, item, index)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Hora Inicial"
                                dateFormat="HH:mm"
                                required={true}
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                  label="Hora Inicial"
                                  variant="outlined"
                                  size="small"
                                  fullWidth />}
                              />
                            </Grid>

                            <Grid item className={classes.marginInput}>
                              <DatePicker
                                name={"end_time"}
                                selected={item.end_time}
                                onChange={e => handleInput("end_time", e, item, index)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Hora Final"
                                dateFormat="HH:mm"
                                required={true}
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                  label="Hora Final"
                                  variant="outlined"
                                  size="small"
                                  fullWidth />}
                              />
                            </Grid>

                            <Grid item className={classes.marginInput}>
                              <TextField
                                name={"number_of_slots"}
                                required={true}
                                id={"number_of_slots_" + index}
                                label="Número de Vagas"
                                variant="outlined"
                                type="number"
                                onChange={e => handleInput(e.target.name, e.target.value, item, index)}
                                value={item.number_of_slots}
                                fullWidth
                              />
                            </Grid>
                            <Grid item className={classes.marginInput}>
                              <TextField
                                id={"value_" + index}
                                name={"value"}
                                label="Valor"
                                variant="outlined"
                                onChange={e => handleInput(e.target.name, e.target.value, item, index)}
                                value={item.value}
                                fullWidth
                                required={true}
                                InputProps={{
                                  inputComponent: CurrencyInput,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {getNumberSpecs().currency.symbol}
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{
                                  maxLength: 10
                                }}
                              />
                            </Grid>
                            {inputListFriday.length !== 1 && item.checked === true &&
                              <Tooltip title="Remover Turno">
                                <IconButton className={classes.paddingBtn} edge="end" aria-label="Remover" onClick={(e) => handleRemoveFriday(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            {inputListFriday.length - 1 === index && item.checked === true &&
                              <Tooltip title="Adicionar Turno">
                                <IconButton className={classes.paddingBtn} edge="end" aria-label="Adicionar" onClick={() => {
                                  setInputListFriday([...inputListFriday, { checked: true, start_time: new Date(), end_time: hoursNowMore(), day: DaysWeek.FRIDAY, number_of_slots: 1, value: 0 }]);
                                }}>
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </>
                        )}
                      </ListItem>
                    ))}

                    {/* Sábado */}
                    {inputListSaturday.map((item, index) => (
                      <ListItem alignItems="flex-start" key={index} className={clsx({ [classes.itemNoCheck]: index > 0 && item.checked === false })}>
                        <Grid item className={classes.minGrid}>
                          {index === 0 &&
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={item.day + index}
                                  name={item.day + index}
                                  checked={item.checked}
                                  onChange={e => handleChecked(e, item, index)}
                                  color="primary"
                                />
                              }
                              label={t("dedicated_week." + item.day)}
                            />
                          }
                          {index > 0 && item.checked === true &&
                            <Grid item className={classes.marginIcon}>
                              <SubdirectoryArrowRightIcon />
                            </Grid>
                          }
                        </Grid>
                        {item.checked === false ? (
                          index === 0 && (
                            <Grid item className={classes.marginInput}>
                              <Typography className={classes.marginTypograph} variant="body2"> Indisponível</Typography>
                            </Grid>
                          )
                        ) : (
                          <>
                            <Grid item className={classes.marginInput}>
                              <DatePicker
                                name={"start_time"}
                                selected={item.start_time}
                                onChange={e => handleInput("start_time", e, item, index)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Hora Inicial"
                                dateFormat="HH:mm"
                                required={true}
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                  label="Hora Inicial"
                                  variant="outlined"
                                  size="small"
                                  fullWidth />}
                              />
                            </Grid>

                            <Grid item className={classes.marginInput}>
                              <DatePicker
                                name={"end_time"}
                                selected={item.end_time}
                                onChange={e => handleInput("end_time", e, item, index)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Hora Final"
                                dateFormat="HH:mm"
                                required={true}
                                wrapperClassName="MuiFormControl-fullWidth"
                                customInput={<TextField
                                  label="Hora Final"
                                  variant="outlined"
                                  size="small"
                                  fullWidth />}
                              />
                            </Grid>

                            <Grid item className={classes.marginInput}>
                              <TextField
                                name={"number_of_slots"}
                                required={true}
                                id={"number_of_slots_" + index}
                                label="Número de Vagas"
                                variant="outlined"
                                type="number"
                                onChange={e => handleInput(e.target.name, e.target.value, item, index)}
                                value={item.number_of_slots}
                                fullWidth
                              />
                            </Grid>
                            <Grid item className={classes.marginInput}>
                              <TextField
                                id={"value_" + index}
                                name={"value"}
                                label="Valor"
                                variant="outlined"
                                onChange={e => handleInput(e.target.name, e.target.value, item, index)}
                                value={item.value}
                                fullWidth
                                required={true}
                                InputProps={{
                                  inputComponent: CurrencyInput,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {getNumberSpecs().currency.symbol}
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{
                                  maxLength: 10
                                }}
                              />
                            </Grid>
                            {inputListSaturday.length !== 1 && item.checked === true &&
                              <Tooltip title="Remover Turno">
                                <IconButton className={classes.paddingBtn} edge="end" aria-label="Remover" onClick={(e) => handleRemoveSaturday(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            {inputListSaturday.length - 1 === index && item.checked === true &&
                              <Tooltip title="Adicionar Turno">
                                <IconButton className={classes.paddingBtn} edge="end" aria-label="Adicionar" onClick={() => {
                                  setInputListSaturday([...inputListSaturday, { checked: true, start_time: new Date(), end_time: hoursNowMore(), day: DaysWeek.SATURDAY, number_of_slots: 1, value: 0 }]);
                                }}>
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </>
                        )}
                      </ListItem>
                    ))}
                  </List>

                </Grid>
              </CardContent>
            </Card>
            <Grid item style={{ marginTop: "10px" }}>
              <Button variant="contained" color="secondary" component={Link} to={"/dedicated"} style={{ marginRight: "10px" }}>
                Voltar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
                startIcon={formik.isSubmitting && (
                  <CircularProgress size="1.5rem" color="inherit" />
                )}
              >
                Salvar
              </Button>
            </Grid>

          </form>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddingBtn: {
      padding: "8px 8px",
      margin: "0px 5px",
    },
    marginTypograph: {
      marginTop: "8px",
      color: "#9e9e9e",
    },
    minGrid: {
      minWidth: "150px",
    },
    marginInput: {
      marginLeft: "5px",
      marginRight: "5px"
    },
    marginIcon: {
      marginLeft: "35%",
      marginRight: "5px",
      color: "#526272"
    },
    itemNoCheck: {
      paddingTop: "0px",
      paddingBottom: "0px"
    }
  })
);