import React from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
} from "@material-ui/core";
import { FormikHelpers, useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { FinancialEntryOrigin, FinancialEntryStatus } from "../../services/types/financialEntry";
import { useTranslation } from "react-i18next";
import { DEFAULT_LOCALE, CURRENT_DATE_FORMAT } from "../../i18n";
import { Autocomplete } from "@material-ui/lab";
import { useHistory } from "react-router";
import { encodeURLParams, URLParamType } from "../../utils/utils";
import { City } from "../../services/types/common";

export interface ICreditsFilterParams {
  createdAtAfter: Date | null;
  createdAtBefore: Date | null;
  paymentDateAfter: Date | null;
  paymentDateBefore: Date | null;
  customer: string;
  origin: FinancialEntryOrigin | "";
  city: number | undefined;
  status: FinancialEntryStatus | "";
};

export const INITIAL_FILTER_PARAMS: ICreditsFilterParams = {
  createdAtAfter: null,
  createdAtBefore: null,
  paymentDateAfter: null,
  paymentDateBefore: null,
  customer: "",
  origin: "",
  city: undefined,
  status: ""
};

interface ICreditsFilterProps {
  params?: ICreditsFilterParams;
  customerList: any[];
  cityList: City[];
  onFilterChanged: (params: ICreditsFilterParams) => void;
  onChangeCity: (city: City | undefined) => void;
};

export const CreditsFilter: React.FC<ICreditsFilterProps> = ({ params = INITIAL_FILTER_PARAMS, customerList, cityList, onFilterChanged, onChangeCity }) => {
  const { t } = useTranslation();
  const [city, setCity] = React.useState<City>();
  const history = useHistory();

  React.useEffect(() => {
    history.replace(encodeURLParams(history.location.pathname, params, [
      { "createdAtAfter": URLParamType.DATE },
      { "createdAtBefore": URLParamType.DATE },
      { "paymentDateAfter": URLParamType.DATE },
      { "paymentDateBefore": URLParamType.DATE }
    ]));
  }, [history, params]);

  const formik = useFormik<ICreditsFilterParams>({
    initialValues: params,
    onSubmit: (values: ICreditsFilterParams, formikHelpers: FormikHelpers<ICreditsFilterParams>) => {
      onFilterChanged(values);
    },
    onReset: (values: ICreditsFilterParams, formikHelpers: FormikHelpers<ICreditsFilterParams>) => {
      onFilterChanged(INITIAL_FILTER_PARAMS);
      formikHelpers.setValues(INITIAL_FILTER_PARAMS);
    }
  });

  return (
    <div className="page-filter">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Autocomplete
              id="city"
              size="small"
              value={city}
              options={cityList}
              getOptionLabel={(option) => `${option.name} - ${option.state_initials}`}
              onChange={(event, newValue) => {
                if (newValue) {
                  setCity(newValue);
                  formik.setFieldValue("city", newValue.id);
                  onChangeCity(newValue);
                } else {
                  setCity(undefined);
                  formik.setFieldValue("city", undefined);
                  onChangeCity(undefined);
                }
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  name="city"
                  label="Cidade"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  variant="outlined"
                  fullWidth
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <TextField
              id="customer"
              name="customer"
              label="Cliente"
              select
              value={formik.values.customer}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
            >
              <MenuItem selected={true} value="">Todos</MenuItem>
              {customerList.map((customer) => (
                <MenuItem key={customer.id} value={customer.id}>
                  {customer.corporate_name}
                </MenuItem>
              ))}

            </TextField>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <TextField
              id="origin"
              name="origin"
              label="Origem"
              select
              value={formik.values.origin}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value={FinancialEntryOrigin.BANK_SLIP}>
                {t("financial_entry_origin." + FinancialEntryOrigin.BANK_SLIP)}
              </MenuItem>
              <MenuItem value={FinancialEntryOrigin.TRANSFER}>
                {t("financial_entry_origin." + FinancialEntryOrigin.TRANSFER)}
              </MenuItem>
              <MenuItem value={FinancialEntryOrigin.DELIVERY}>
                {t("financial_entry_origin." + FinancialEntryOrigin.DELIVERY)}
              </MenuItem>
              <MenuItem value={FinancialEntryOrigin.PIX}>
                {t("financial_entry_origin." + FinancialEntryOrigin.PIX)}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <TextField
              id="status"
              name="status"
              label="Situação"
              select
              onChange={formik.handleChange}
              value={formik.values.status}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="">Todas</MenuItem>
              <MenuItem value={FinancialEntryStatus.CONFIRMED}>{t("financial_entry_status." + FinancialEntryStatus.CONFIRMED)}</MenuItem>
              <MenuItem value={FinancialEntryStatus.PROCESSING}>{t("financial_entry_status." + FinancialEntryStatus.PROCESSING)}</MenuItem>
              <MenuItem value={FinancialEntryStatus.PAID}>{t("financial_entry_status." + FinancialEntryStatus.PAID)}</MenuItem>
              <MenuItem value={FinancialEntryStatus.CANCELED}>{t("financial_entry_status." + FinancialEntryStatus.CANCELED)}</MenuItem>

            </TextField>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <DatePicker
              id="createdAtAfter"
              name="createdAtAfter"
              onChange={(val, event: React.SyntheticEvent) => {
                formik.setFieldValue("createdAtAfter", val);
                event.preventDefault();
              }}
              maxDate={new Date()}
              selected={formik.values.createdAtAfter}
              startDate={formik.values.createdAtAfter}
              endDate={formik.values.createdAtBefore}
              selectsStart
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_FORMAT}
              timeCaption="Hora"
              isClearable
              autoComplete="off"
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data Inicial do Lançamento"
                variant="outlined"
                size="small"
                error={!!formik.errors.createdAtAfter}
                helperText={formik.errors.createdAtAfter}
                fullWidth />}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <DatePicker
              id="createdAtBefore"
              name="createdAtBefore"
              onChange={(val, event: React.SyntheticEvent) => {
                formik.setFieldValue("createdAtBefore", val);
                event.preventDefault();
              }}
              maxDate={new Date()}
              selected={formik.values.createdAtBefore}
              startDate={formik.values.createdAtBefore}
              endDate={formik.values.createdAtAfter}
              selectsStart
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_FORMAT}
              timeCaption="Hora"
              isClearable
              autoComplete="off"
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data Final do Lançamento"
                variant="outlined"
                size="small"
                error={!!formik.errors.createdAtBefore}
                helperText={formik.errors.createdAtBefore}
                fullWidth />}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <DatePicker
              id="paymentDateAfter"
              name="paymentDateAfter"
              onChange={(val, event: React.SyntheticEvent) => {
                formik.setFieldValue("paymentDateAfter", val);
                event.preventDefault();
              }}
              maxDate={new Date()}
              selected={formik.values.paymentDateAfter}
              startDate={formik.values.paymentDateAfter}
              endDate={formik.values.paymentDateBefore}
              selectsStart
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_FORMAT}
              timeCaption="Hora"
              isClearable
              autoComplete="off"
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data Inicial do Pagamento"
                variant="outlined"
                size="small"
                error={!!formik.errors.paymentDateAfter}
                helperText={formik.errors.paymentDateAfter}
                fullWidth />}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <DatePicker
              id="paymentDateBefore"
              name="paymentDateBefore"
              onChange={(val, event: React.SyntheticEvent) => {
                formik.setFieldValue("paymentDateBefore", val);
                event.preventDefault();
              }}
              maxDate={new Date()}
              selected={formik.values.paymentDateBefore}
              startDate={formik.values.paymentDateBefore}
              endDate={formik.values.paymentDateAfter}
              selectsStart
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_FORMAT}
              timeCaption="Hora"
              isClearable
              autoComplete="off"
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data Final do Pagamento"
                variant="outlined"
                size="small"
                error={!!formik.errors.paymentDateBefore}
                helperText={formik.errors.paymentDateBefore}
                fullWidth />}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              Pesquisar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" type="reset">
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
