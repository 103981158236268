import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardContent,
    Grid,
    Typography,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { LoadingPage } from "../../components/Loading";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import DedicatedService from "../../services/dedicatedService";
import { DedicatedResponse, StatusDedicated, TurnResponse } from "../../services/types/dedicated";
import { Block, Check, Edit } from "@material-ui/icons";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../components/ConfirmationDialog";
import { useHistory } from "react-router-dom";
import { DedicatedTurnsDetails } from "./customComponents/dedicatedTurnsDetails";

interface DedicatedDetailProps {
   dedicatedId: string;
};

export const DedicatedDetail: React.FC = () => {
    const { dedicatedId } = useParams<DedicatedDetailProps>();
    const [dedicated, setDedicated] = useState<DedicatedResponse>();
    const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
    const history = useHistory();
    const [turns, setTurns] = React.useState<TurnResponse[]>([]);

    const loadDedicated = async (id: number) => {
        const response = await DedicatedService.getDedicatedById(id);
        setDedicated(response);
    };

    const loadTurnsDedicated = async (id: number) => {
        const response = await DedicatedService.getTurnsDedicated(id);
        setTurns(response);
    };

    useEffect(() => {
        if (dedicatedId) {
            loadDedicated(Number(dedicatedId));
            loadTurnsDedicated(Number(dedicatedId));
        }
    }, [dedicatedId]);

    const openEditDedicated = () => {
        if(dedicatedId){
          history.push(`/dedicated/edit/${dedicatedId}`);
        }
    };

    const refresh = () => {
        if (dedicatedId) {
            loadDedicated(Number(dedicatedId));
            loadTurnsDedicated(Number(dedicatedId));
        }
    };

    const handleActivateDedicated = () => {
        if(dedicated){
          const dialog = confirmationDialog.current;  
          dialog?.confirm(
            "Ativar Proposta de Agendamento",
            <Typography>Você tem certeza que deseja <strong>Ativar</strong> esta Proposta de Agendamento?</Typography>,
            "Ativar",
            "Cancelar"
          ).then(() => {
            DedicatedService.activeDedicated(dedicated.id)
              .then((response) => {
                NotificationManager.success("Ativado com sucesso.", "Proposta de Agendamento");
                refresh();
                dialog?.hide();
              })
              .catch((error) => {
                NotificationManager.error("Não foi possível Ativado a Proposta de Agendamento.", "Proposta de Agendamento");
              });
          }).catch(() => { });
        }
    };

    const handleDeactivateDedicated = () => {
        if(dedicated){
        const dialog = confirmationDialog.current;  
        dialog?.confirm(
            "Desativar Proposta de Agendamento",
            <Typography>Você tem certeza que deseja <strong>Desativar</strong> esta Proposta de Agendamento?</Typography>,
            "Desativar",
            "Cancelar"
        ).then(() => {
            DedicatedService.diasbleDedicated(dedicated.id)
                .then((response) => {
                NotificationManager.success("Desativado com sucesso.", "Proposta de Agendamento");
                refresh();
                dialog?.hide();
                })
                .catch((error) => {
                NotificationManager.error("Não foi possível Desativar a Proposta de Agendamento.", "Proposta de Agendamento");
                });
            }).catch(() => { });
        }
    };

    return (
        <div>
            <ConfirmationDialog ref={confirmationDialog} />

            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h1">
                                Detalhes Agendamento
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomBreadcrumbs
                                pathList={[
                                    { label: "Propostas de Agendamento", url: "/dedicated" },
                                    {
                                        label: "Detalhes da Proposta de Agendamento",
                                        url: `/dedicated/detail/${dedicatedId}`,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
              
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant="contained"
                            color="secondary"
                                startIcon={<Edit />}
                                onClick={openEditDedicated}>
                                Editar
                            </Button>
                        </Grid>
                        <Grid item style={{marginRight: "16px"}}>                   
                            {dedicated && dedicated.status === StatusDedicated.ACTIVE ? (
                                <Button color="secondary" variant="contained"
                                    startIcon={<Block />}
                                    onClick={handleDeactivateDedicated}>
                                    Desativar Proposta Agendamento
                                </Button>
                            ) : (
                                <Button color="secondary" variant="contained"
                                    startIcon={<Check />}
                                    onClick={handleActivateDedicated}>
                                    Ativar Proposta Agendamento
                                </Button>
                            )}                  
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {!dedicated ? (
                <LoadingPage />
            ) : (
                <Grid container spacing={2} xs={12}>
                    <Grid item md={12} xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h2">Detalhes</Typography>
                                <DedicatedTurnsDetails dedicated={dedicated} turns={turns} />
                            </CardContent>
                        </Card>
                    </Grid>                                     
                </Grid>               
            )}          
        </div>
    );
};
