import React, { useMemo, useState } from "react";
import { useFormik } from "formik";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import {
  Button,
  CircularProgress,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";
import * as Yup from "yup";
import { Autocomplete } from "@material-ui/lab";
import CourierService from "../../services/courierService";
import { CourierResponse } from "../../services/types/courier";
import { IPageableParams } from "../../store/ducks/requests";
import DedicatedService from "../../services/dedicatedService";

interface AddCourierBlockListForm {
  courier: number | null;
  reason: string;
  region: number | null,
}

const INITIAL_FORM_VALUES: AddCourierBlockListForm = {
  courier: null,
  reason: "",
  region: null
};

interface AddRegisterDebitDialogProps {
  regionList: any[];
  onAccept?: () => Promise<void>;
}

export interface AddCourierBlockListDialogHandle {
  open: () => void;
  close: () => void;
}

const validationSchema = Yup.object().shape({
  reason: Yup.string().required("Campo obrigatório")
});

export const AddCourierBlockListDialog: React.ForwardRefRenderFunction<
  AddCourierBlockListDialogHandle,
  AddRegisterDebitDialogProps
> = (props, ref) => {
  const [open, setOpen] = useState<boolean>(false);
  const [courierList, setCourierList] = useState<CourierResponse[]>([]);
  const [courierSearchValue, setCourierSearchValue] = useState<string>("");
  const [selectedCourier, setSelectedCourier] = useState<CourierResponse>();
  const [selectedRegion, setSelectedRegion] = React.useState<number>();

  React.useImperativeHandle(ref, () => {
    return {
      open() {
        show();
      },

      close() {
        hide();
      },
    };
  });

  const hide = () => {
    clear();
    setOpen(false);
    formik.resetForm();
  };

  const show = () => {
    clear();
    setOpen(true);
  };

  const clear = () => {
    setSelectedRegion(undefined);
    setSelectedCourier(undefined);
    setCourierSearchValue("");
    setCourierList([]);
  }

  const updateCourierList = useMemo(() => debounce((name: string, regionId?: number) => {
    const DEFAULT_PAGINATION: IPageableParams = {
      page: 1,
      page_size: 10,
      ordering: "name"
    };
    if (name.trim().length > 3) {
      CourierService.getCouriers(name, "", "", null, null, undefined, "", "", regionId ? [regionId] : [], "", null, DEFAULT_PAGINATION).then((response) => {
        setCourierList(response.data);
      });
    } else {
      setCourierList([]);
    }
  }, 300), []);

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: async (values) => {
      try {
        formik.setFieldError("courier", undefined);
        formik.setFieldError("reason", undefined);
        if (values.courier && values.reason) {
          await DedicatedService.addCourierBlock(values.courier, values.reason)
          if (props.onAccept) {
            await props.onAccept();
          }
          hide();
          NotificationManager.success("Entregador Bloqueado com sucesso.", "Sucesso");
        } else if (!values.courier) {
          formik.setFieldError("courier", "Selecione um Entregador. Digite pelo menos 4 caracteres para realizar a busca.");
        } else if (values.reason.trim().length === 0) {
          formik.setFieldError("reason", "Campo obrigatório.");
        }
      } catch (error) {
        const { status, data } = error.response;
        if (status === 400) {
          for (var key in data) {
            var value = data[key];
            formik.setFieldError(key, value);
          }
        } else {
          NotificationManager.error("Não foi possível Bloquear o Entregador", "Error");
        }
      }
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Dialog
      open={open}
      onClose={hide}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="form-dialog-title">Selecionar Entregador Para Bloquear</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Autocomplete
                id="region"
                size="small"
                value={selectedRegion}
                options={props.regionList}
                getOptionLabel={(option) => `${option.name} - ${option.state}`}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedRegion(newValue.id);
                  } else {
                    setSelectedRegion(undefined);
                  }
                }}

                renderInput={(params) =>
                  <TextField
                    {...params}
                    name="region"
                    label="Região"
                    onChange={formik.handleChange}
                    value={formik.values.region}
                    variant="outlined"
                    fullWidth
                  />
                }
              />
            </Grid>

            <Grid item sm={12}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  id="courierId"
                  size="small"
                  value={selectedCourier}
                  inputValue={courierSearchValue}
                  options={courierList}
                  disabled={!selectedRegion}
                  getOptionLabel={(courier: CourierResponse) => courier.name}
                  loadingText="Aguarde ..."
                  noOptionsText="Sem opções"
                  onChange={(e, newValue) => {
                    formik.setFieldError("courier", undefined);
                    if (newValue) {
                      setSelectedCourier(newValue);
                      formik.setFieldValue("courier", newValue.id);
                    } else {
                      setSelectedCourier(undefined);
                      formik.setFieldValue("courier", null);
                    }
                  }}
                  onInputChange={(e: object, value: string, reason: string) => {
                    setCourierSearchValue(value);
                    if (reason === "input") {
                      updateCourierList(value, selectedRegion);
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label="Entregador"
                      variant="outlined"
                      error={!!formik.errors.courier}
                      helperText={formik.errors.courier || "Digite pelo menos 4 caracteres para realizar a busca"}
                      fullWidth
                    />
                  }
                />
              </FormControl>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <TextField
                  id="reason"
                  label="Motivo"
                  variant="outlined"
                  disabled={!selectedRegion}
                  onChange={formik.handleChange}
                  value={formik.values.reason}
                  error={!!formik.errors.reason}
                  helperText={formik.errors.reason}
                  required={true}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide}>Cancelar</Button>
          <Button
            color="primary"
            type="submit"
            disabled={formik.isSubmitting || !selectedRegion}
            startIcon={
              formik.isSubmitting && (
                <CircularProgress size="1.5rem" color="inherit" />
              )
            }
          >
            Bloquear Entregador
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default React.forwardRef(AddCourierBlockListDialog);
