import Geocode from "react-geocode";
const key = process.env.REACT_APP_MAPS_KEY;

interface Coordinates {
  readonly latitude: number;
  readonly longitude: number;
}

interface Position {
  readonly coords: Coordinates;
}

export const locationByIP = async () => {
  const getCoords = async () => {
    const pos: Position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    return {
      lng: pos.coords.longitude,
      lat: pos.coords.latitude,
    };
  };
  return await getCoords();
};

export const locationByAddress = async (address: string) => {
  try {
    Geocode.setApiKey(key || "");
    Geocode.setLanguage("pt");
    Geocode.setRegion("br");
    Geocode.enableDebug();

    const response = await Geocode.fromAddress(address);
    const { lat, lng } = response.results[0].geometry.location;
    return {
      lat: lat,
      lng: lng,
    };
  } catch (error) {
    console.error("GEOCODE: ", error);
    return {
      lat: 0,
      lng: 0,
    };
  }
};

export default locationByAddress;
