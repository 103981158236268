import React, { useCallback, useEffect } from "react";
import {
  Grid,
  createStyles,
  Theme,
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
//@ts-ignore
import { NotificationManager } from "react-notifications";

import { Map } from "./SubPages/Map";
import { Called } from "./SubPages/Called";
import { Timeline } from "./SubPages/Timeline";
import { Occurrences } from "./SubPages/Occurrences";
//import { Search } from "./SubPages/Search";
import { useHistory } from "react-router-dom";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { useParams } from "react-router-dom";
import DeliveryRequestService from "../../services/deliveryRequestService";
import { DeliveryRequest, DeliveryRequestStatus, IntegrationSource, PendingDeliveryRequest, PendingTrip, Store, TripStatus } from "../../services/types/deliveryRequest";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../components/ConfirmationDialog";
import AuthService from "../../services/authService";
import ShowIf from "../../components/ShowIf";
import { AnnouncementType, AnnouncementTemplate, CreateUpdateAnnouncement, DeliveryForType } from "../../services/types/announcement";
import AnnouncementsService from "../../services/announcementsService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addHours } from "date-fns";
import TripService from "../../services/tripService";
import SelectCourierDialog from "../mainDashboard/customComponents/selectCourierDialog";
import { RegionResponse } from "../../services/types/region";
import { OnlineCourierUserDetails } from "../../services/types/courier";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import CorrectDeliveryValueDialog, { CorrectDeliveryValueDialogHandle } from "./SubPages/CustomComponents/CorrectDeliveryValueDialog";
import { Releases } from "./SubPages/Releases";
import { IPageableParams } from "../../store/ducks/requests";
import { DashboardDeliveriesFilterParams, DELIVERIES_INITIAL_FILTER_PARAMS } from "../mainDashboard/views/dashboardDeliveriesFilter";
import JoinDeliveriesDialog, { JoinDeliveriesDialogHandle } from "../mainDashboard/customComponents/joinDeliveriesOnTripDialog";
import { decodeURLParams, encodeURLParams, URLParamType } from "../../utils/utils";


const validationSchema = Yup.object().shape({
  templateId: Yup.number()
    .required("Campo obrigatório"),
  title: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .required("Campo obrigatório"),
  content: Yup.string()
    .max(250, "Máximo de 250 caracteres excedido")
    .required("Campo obrigatório"),
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  tabValue: any;
}

interface IParams {
  requestId: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {tabValue === index && <Box>{children}</Box>}
    </div>
  );
}

interface StyledTabProps {
  label: string;
  disabled?: boolean;
}

interface SendDeliveryRequestAnnouncementForm {
  templateId: number | null;
  title: string;
  content: string;
}

const INITIAL_STATE: SendDeliveryRequestAnnouncementForm = {
  templateId: null,
  title: "",
  content: "",
};





export const RequestDetails: React.FC = () => {
  const classes = useStyles();
  const { requestId } = useParams<IParams>();
  const [tabValue, setTabValue] = React.useState<string>("map");
  const [isActive, setActive] = React.useState<boolean>(false);
  const [deliveryRequest, setDeliveryRequest] = React.useState<DeliveryRequest>();
  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
  const [announcementTemplateList, setAnnouncementTemplateList] = React.useState<AnnouncementTemplate[]>();
  const [openDialogAviso, setOpenDialogAviso] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openCourierDialog, setOpenCourierDialog] = React.useState<boolean>(false);
  const [store, setStore] = React.useState<Store>();
  const [region, setRegion] = React.useState<RegionResponse>();
  const history = useHistory();
  const [openLoadingOverlay, showLoadingOverlay] = React.useState(false);
  const correctValueDeliveryDialog = React.createRef<CorrectDeliveryValueDialogHandle>();
  const [deliveriesFilterParams, setDeliveriesFilterParams] = React.useState<DashboardDeliveriesFilterParams>(DELIVERIES_INITIAL_FILTER_PARAMS);
  const [deliveryRequestList, setDeliveryRequestList] = React.useState<PendingDeliveryRequest[]>([]);
  const joinDeliveriesDialog = React.createRef<JoinDeliveriesDialogHandle>();
  const [tripList, setTripList] = React.useState<PendingTrip[]>([]);
  const [noCourierList, setNoCourierList] = React.useState<PendingTrip[]>([]);

  const goToTrip = useCallback((idTrip: number) => {
    if (idTrip) {
      history.push(`/requests/trip/${idTrip}`);
    }
  }, [history]);

  React.useLayoutEffect(() => {
    const result: any = decodeURLParams(history.location.search, [{"menu": URLParamType.STRING}]);
    if(result && result.menu){
      setTabValue(result.menu);
    }      
}, [history.location.search]);

  const loadData = useCallback(async (requestId, goTrip: boolean) => {
    const deliveryRequest = await DeliveryRequestService.getDeliveryRequest(Number(requestId));

    setDeliveryRequest(deliveryRequest);
    if (deliveryRequest) {
      const storeDelivery: Store = {
        id: deliveryRequest.customer.customer_id,
        name: deliveryRequest.customer.customer_name,
        latitude: deliveryRequest.origin_address.latitude,
        longitude: deliveryRequest.origin_address.longitude,
      };
      const regionDelivery: RegionResponse = {
        id: deliveryRequest.customer.region.id,
        name: deliveryRequest.customer.region.name,
        state: deliveryRequest.origin_address.state,
        metropolitan_area: "",
        support_phone: "",
        max_ignore_count: 0,
        ignore_timeout_minutes: 0,
        coordinates: []
      };
      setRegion(regionDelivery);
      setStore(storeDelivery);
      if (goTrip && deliveryRequest.trip_id) {
        goToTrip(deliveryRequest.trip_id);
      }
      if ([DeliveryRequestStatus.FINISHED, DeliveryRequestStatus.CANCELED].includes(deliveryRequest.status)) {
        setActive(false);
      } else {
        setActive(true);
      }
    } else {
      setActive(false);
    }
  }, [goToTrip]);

  const refreshData = useCallback(async () => {
    if (isActive) {
      loadData(requestId, false);
    }
  }, [loadData, requestId, isActive]);

  useEffect(() => {
    const loadTemplateNotification = async () => {
      const listTemplates = await AnnouncementsService.getAnnouncementTemplates();
      setAnnouncementTemplateList(listTemplates);
    };

    loadTemplateNotification();
  }, []);

  const formik = useFormik({
    initialValues: INITIAL_STATE,
    onSubmit: async (values: SendDeliveryRequestAnnouncementForm) => {
      if (deliveryRequest) {
        setLoading(true);
        const newAnnouncement: CreateUpdateAnnouncement = {
          related_trip: deliveryRequest.trip_id ? deliveryRequest.trip_id : null,
          title: values.title,
          content: values.content,
          couriers: [deliveryRequest.current_courier.id],
          delivery_for: DeliveryForType.SPECIFIC_COURIERS,
          announcement_type: AnnouncementType.IMMEDIATE,
          only_active_couriers: false,
          expire_date: addHours(new Date(), 1)
        };

        AnnouncementsService.sendAnnouncement(newAnnouncement)
          .then((response) => {
            setLoading(false);
            handleClose();
            NotificationManager.success(
              "Aviso enviado com sucesso",
              "Novo Aviso"
            );
          }).catch((error) => {
            setLoading(false);
            NotificationManager.error(
              "Erro ao enviar o Aviso",
              "Novo Aviso"
            );
          });
      }
    },
    validationSchema,
    enableReinitialize: false,
    validateOnChange: false,
    validateOnBlur: false,
  });


  const handleClickOpen = () => {
    formik.resetForm();
    setOpenDialogAviso(true);
  };

  const handleClose = () => {
    setOpenDialogAviso(false);
    formik.resetForm();
  };

  const handleSubmit = () => {
    formik.submitForm();
  }

  const canCallIFood = (): boolean => {
    let result = false;

    if (deliveryRequest && !deliveryRequest.called_ifood
      && deliveryRequest.metadata?.available
      && deliveryRequest.integration_instance.integration_source === IntegrationSource.IFOOD) {
      if ([DeliveryRequestStatus.NEW, DeliveryRequestStatus.NO_COURIER].includes(deliveryRequest.status)) {
        result = true;
      }
    }

    return result;
  }

  const callIFood = () => {
    confirmationDialog.current?.confirm(
      "Chamar Entregador do iFood",
      <React.Fragment>
        <Typography>
          Você tem certeza que deseja <strong>Chamar um Entregador do iFood</strong>?
        </Typography>
        <Typography>
          Obs.: Não será possível reverter esta ação.
        </Typography>
      </React.Fragment>,
      "Chamar"
    ).then(() => {
      DeliveryRequestService.lookForIFoodCourier(Number(requestId))
        .then((response) => {
          loadData(requestId, false);
          confirmationDialog.current?.hide();
          NotificationManager.success(
            "Entregador do iFood chamado com sucesso.",
            "Chamar Entregador do iFood"
          );
        }).catch((error) => {
          NotificationManager.error(
            "Não foi possível chamar Entregador do iFood.",
            "Chamar Entregador do iFood"
          );
        });
    }).catch(() => { });
  }

  const refundDeliveryRequest = () => {
    confirmationDialog.current?.confirm(
      "Estorno de Solicitação de Entrega",
      <React.Fragment>
        <Typography>
          Você tem certeza que deseja <strong>Estornar</strong> esta Solicitação de Entrega?
        </Typography>
        <Typography>
          Obs.: Não será possível reverter esta ação.
        </Typography>
      </React.Fragment>,
      "Estornar"
    ).then(() => {
      DeliveryRequestService.refundDeliveryRequestById(Number(requestId))
        .then((response) => {
          loadData(requestId, false);
          confirmationDialog.current?.hide();
          NotificationManager.success(
            "Solicitação de Entrega estornada com sucesso.",
            "Estorno de Solicitação de Entrega"
          );
        }).catch((error) => {
          NotificationManager.error(
            "Erro ao estornar a Solicitação de Entrega.",
            "Estorno de Solicitação de Entrega"
          );
        });
    }).catch(() => { });
  }

  const cancelTrip = () => {
    confirmationDialog.current?.confirm(
      "Cancelamento de Entrega",
      <React.Fragment>
        <Typography>
          Você tem certeza que deseja <strong>Cancelar</strong> esta Entrega?
        </Typography>
      </React.Fragment>,
      "Cancelar"
    ).then(() => {
      if (deliveryRequest) {
        DeliveryRequestService.cancelDeliveryRequestById(deliveryRequest.id)
          .then((response) => {
            loadData(requestId, false);
            confirmationDialog.current?.hide();
            NotificationManager.success(
              "Entrega cancelada com sucesso.",
              "Cancelamento de Entrega"
            );
          }).catch((error) => {
            NotificationManager.error(
              "Erro ao Cancelar a Entrega.",
              "Cancelamento de Entrega"
            );
          });
      }
    }).catch(() => { });
  }

  const cancelCourierCall = () => {
    confirmationDialog.current?.confirm(
      "Parar Chamada",
      <React.Fragment>
        <Typography>
          Você tem certeza que deseja <strong>Parar</strong> de chamar o Entregador?
        </Typography>
      </React.Fragment>,
      "Parar"
    ).then(() => {
      if (deliveryRequest) {
        TripService.resetTripById(deliveryRequest.trip_id)
          .then((response) => {
            loadData(requestId, false);
            confirmationDialog.current?.hide();
            NotificationManager.success(
              "Entregador parou de ser chamado.",
              "Parar Chamada"
            );
          }).catch((error) => {
            NotificationManager.error(
              "Erro ao parar de chamar o Entregador.",
              "Parar Chamada"
            );
          });
      }
    }).catch(() => { });
  }

  useEffect(() => {
    loadData(requestId, false);
  }, [loadData, requestId]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    history.replace(encodeURLParams(history.location.pathname, {menu: newValue}));
    setTabValue(newValue);
  };

  function interpolate(template: string, context: any) {
    return template.replace(/[$]{([^}]+)}/g, function (_, path) {
      const properties = path.split('.');
      return properties.reduce((prev: any, curr: any) => prev && prev[curr], context);
    })
  }

  const handleSearchCourierForDelivery = async () => {
    let title = "Solicitar Entregador";
    let message = "Você tem certeza que deseja solicitar entregador?";
    let acceptButtonLabel = "Solicitar";
    const dialog = confirmationDialog.current;
    if (deliveryRequest) {
      dialog?.confirm(title, message, acceptButtonLabel, "Cancelar")
        .then(async () => {
          try {
            await requestCourier(deliveryRequest);
          } finally {
            dialog?.hide();
          }
        }).catch(() => { });
    }

  };

  const requestCourier = (deliveryRequest: DeliveryRequest) => {
    return new Promise<boolean>((resolve, reject) => {
      DeliveryRequestService.requestCourierToDeliveryRequestById(deliveryRequest.id)
        .then((response) => {
          resolve(true);
          loadData(Number(deliveryRequest.id), true);
          NotificationManager.success(
            "Entregador solicitado com sucesso!",
            "Sucesso"
          );
        })
        .catch((error) => {
          reject(false);
          const { status, data } = error.response;
          if (status === 400) {
            let errors: string[] = [];
            for (var key in data) {
              var value = data[key];
              errors.push(value);
            }
            NotificationManager.error(
              errors.join(".\n"),
              "Error"
            );
          } else {
            NotificationManager.error(
              "Não foi possível solicitar entregador!",
              "Oops"
            );
          }
        });
    });
  }

  const handleSetCourierForDelivery = () => {
    if (deliveryRequest) {
      setOpenCourierDialog(true);
    }
  };

  const handleCourierSelected = async (courier: OnlineCourierUserDetails) => {
    if (deliveryRequest && courier) {
      try {
        await DeliveryRequestService.requestCourierToDeliveryRequestById(deliveryRequest.id, courier.id);
        NotificationManager.success(
          "Entregador solicitado com sucesso!",
          "Sucesso"
        );
        loadData(Number(deliveryRequest.id), true);
        setOpenCourierDialog(false);

      } catch (error) {
        const { status, data } = error.response;
        if (status === 400) {
          let errors: string[] = [];
          for (var key in data) {
            var value = data[key];
            errors.push(value);
          }
          NotificationManager.error(
            errors.join(".\n"),
            "Error"
          );
        } else {
          NotificationManager.error(
            "Não foi possível solicitar entregador!",
            "Error"
          );
        }
      }
    }
  };

  const handleDiscardClick = () => {
    const dialog = confirmationDialog.current;
    dialog?.confirm(
      "Descartar Entrega",
      "Você tem certeza que deseja Descartar esta Entrega? Esta ação não poderá ser desfeita.",
      "Descartar",
      "Cancelar"
    ).then(async () => {
      try {
        await discardDeliveryRequest(Number(requestId));
      } finally {
        dialog.hide();
      }
    }).catch(() => { });
  };

  const discardDeliveryRequest = (deliveryRequestId: number): Promise<boolean> => {
    showLoadingOverlay(true);
    return new Promise<boolean>((resolve, reject) => {
      DeliveryRequestService.discardDeliveryRequestById(deliveryRequestId)
        .then((response) => {
          showLoadingOverlay(false);
          loadData(Number(deliveryRequestId), true);
          resolve(true);
          NotificationManager.success(
            "Solicitação descartada com sucesso!",
            "Sucesso"
          );
        })
        .catch((error) => {
          showLoadingOverlay(false);
          reject(false);
          const { status, data } = error.response;
          if (status === 400) {
            let errors: string[] = [];
            for (var key in data) {
              var value = data[key];
              errors.push(value);
            }
            NotificationManager.error(
              errors.join(".\n"),
              "Error"
            );
          } else {
            NotificationManager.error(
              "Não foi possível descartar a solicitação!",
              "Error"
            );
          }
        });
    })
  }

  const handleCurrectValueDelivery = () => {
    if (deliveryRequest) {
      correctValueDeliveryDialog.current?.open();
    }
  };

  const correctDeliveryValueDone = async (): Promise<void> => {
    try {
      if (deliveryRequest) {
        loadData(Number(deliveryRequest.id), false);
      }
    } finally {
      return Promise.resolve();
    }
  };

  const refreshPendingDeliveryRequests = (
    regionsIds: number[],
    storesIds: number[],
    retrieveNew: boolean | null,
    pageParams: IPageableParams
  ) => {
    DeliveryRequestService.getPendingDeliveryRequests(
      regionsIds,
      storesIds,
      [DeliveryRequestStatus.NEW],
      retrieveNew,
      pageParams
    )
      .then((response) => {
        setDeliveryRequestList(response.data);
        refreshTrips();
      })
      .catch((error) => { });
  };

  const refreshPendingTripRequests = (
    regionsIds: number[],
    storesIds: number[],
    statusTrip: TripStatus | null,
    pageParams: IPageableParams
  ) => {
    TripService.getTrips(
      regionsIds,
      storesIds,
      statusTrip,
      pageParams
    )
      .then((response) => {
        setTripList(response.data);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    const storedFilter = localStorage.getItem("dashboardDeliveries:filter");
    if (storedFilter) {
      const params: DashboardDeliveriesFilterParams = JSON.parse(storedFilter);
      if (params.regionsIds) {
        if (!params.storesIds) {
          params.storesIds = [];
        }
        setDeliveriesFilterParams(params);
      }
    }
  }, []);

  React.useEffect(() => {
    const _noCourierList: PendingTrip[] = [];
    tripList.forEach(trip => {
      switch (trip.status) {
        case TripStatus.NEW:
        case TripStatus.NO_COURIER:
          _noCourierList.push(trip);
          break;
      }
    });

    if (deliveryRequest) {
      const availableDeliveries: PendingDeliveryRequest[] = deliveryRequestList.filter((delivery) => {
        return (delivery.store.id === deliveryRequest.customer.store_id);
      });
      const availableTrips: PendingTrip[] = [];
      _noCourierList.forEach((trip) => {
        if ([TripStatus.NEW, TripStatus.NO_COURIER].includes(trip.status)
          && (trip.store.id === deliveryRequest.customer.store_id)) {
          availableTrips.push(trip);
        }
      });
      joinDeliveriesDialog.current?.joinDeliveries(deliveryRequest, deliveryRequest.customer.store_id, deliveryRequest.customer.store_name, availableDeliveries, availableTrips);
    }

  }, [tripList]);

  const refreshTrips = () => {
    if (deliveriesFilterParams.regionsIds?.length > 0) {
      const TRIP_DEFAULT_PAGINATION: IPageableParams = {
        page: 1,
        page_size: 200,
        ordering: "created_at",
      };
      refreshPendingTripRequests(deliveriesFilterParams.regionsIds, [], null, TRIP_DEFAULT_PAGINATION);
    } else {
      setTripList([]);
    }
  };

  const refreshDeliveries = () => {
    const DEFAULT_PAGINATION: IPageableParams = {
      page: 1,
      page_size: 200,
      ordering: "estimated_delivery_time"
    };
    if (deliveriesFilterParams.regionsIds?.length > 0) {
      refreshPendingDeliveryRequests(deliveriesFilterParams.regionsIds, [], true, DEFAULT_PAGINATION);
    } else {
      setDeliveryRequestList([]);
    }
  };

 /*  const handleJoinDelivery = useCallback(() => {
   
  },[deliveryRequest, deliveryRequestList, joinDeliveriesDialog, noCourierList]);

  React.useEffect(() => {
    handleJoinDelivery()
  }, [noCourierList, handleJoinDelivery]); */

  const refreshSuccess = () => {
    if (deliveryRequest) {
      loadData(Number(deliveryRequest.id), true);
    }
  };

  return (
    <div>
      <LoadingOverlay open={openLoadingOverlay} />

      <ConfirmationDialog ref={confirmationDialog} />

      <JoinDeliveriesDialog ref={joinDeliveriesDialog} onSuccess={refreshSuccess} />

      {region && (
        <SelectCourierDialog
          open={openCourierDialog}
          title="Direcionar para Entregador"
          region={region}
          referenceStore={store}
          onCloseRequested={() => setOpenCourierDialog(false)}
          selectedCourier={handleCourierSelected}
        />
      )}

      <Dialog
        open={openDialogAviso}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Enviar Aviso"}</DialogTitle>
        <DialogContent>
          <Grid item style={{ marginBottom: "15px" }}>
            <TextField
              id="templateId"
              name="templateId"
              label="Tipo do Aviso"
              select
              fullWidth
              variant="outlined"
              value={formik.values.templateId || 0}
              onChange={(e) => {
                formik.handleChange(e);
                if (announcementTemplateList) {
                  const result = announcementTemplateList.find(item => item.id === Number(e.target.value));
                  if (result?.title) {
                    formik.setFieldValue("title", interpolate(result.title, { delivery: deliveryRequest }))
                  }
                  if (result?.content) {
                    formik.setFieldValue("content", interpolate(result.content, { delivery: deliveryRequest }))
                  }
                }
              }}
            >
              <MenuItem disabled={true} value={0}>
                Tipo do Aviso
              </MenuItem>
              {announcementTemplateList?.map((template) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item style={{ marginBottom: "15px" }}>
            <TextField
              label="Título"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={!!formik.errors.title}
              helperText={formik.errors.title}
              variant="outlined"
              required={true}
              inputProps={{
                maxLength: 100
              }}
              fullWidth
            />
          </Grid>
          <Grid item style={{ marginBottom: "15px" }}>
            <TextField
              label="Mensagem"
              name="content"
              multiline
              rows={5}
              value={formik.values.content}
              error={!!formik.errors.content}
              helperText={"Máx. 250 caracteres" || formik.errors.content}
              onChange={formik.handleChange}
              variant="outlined"
              inputProps={{
                maxLength: 250
              }}
              required={true}
              fullWidth
            />
          </Grid>
          <Typography>
            Obs.: Não será possível reverter esta ação.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} disabled={loading} color="primary" autoFocus>
            Enviar{" "}
            {loading && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container justify="space-between" className="page-title">
        <Grid item>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Typography variant="h1">Detalhes da Solicitação</Typography>
            </Grid>

            <Grid item>
              <CustomBreadcrumbs
                pathList={[
                  { label: "Solicitações", url: "/requests" },
                  { label: "Detalhes da Solicitação", url: `/requests/detail/${requestId}` },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{width: "100%"}}>
          <Grid container spacing={1} style={{justifyContent: "end"}}>
            {deliveryRequest && (
              <ShowIf condition={[
                DeliveryRequestStatus.NEW,
                DeliveryRequestStatus.NO_COURIER,
                DeliveryRequestStatus.ACCEPTED,
                DeliveryRequestStatus.AWAITING_RELEASE,
                DeliveryRequestStatus.AWAITING_COLLECTION,
                DeliveryRequestStatus.ON_WAY].includes(deliveryRequest.status)}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={refreshDeliveries}
                  >
                    {`${[DeliveryRequestStatus.NEW,DeliveryRequestStatus.NO_COURIER].includes(deliveryRequest.status) ? "Juntar Entregas" : "Adicionar Entrega"}`}
                  </Button>
                </Grid>
              </ShowIf>
            )}
            <ShowIf condition={canCallIFood()}>
              <Grid item>
                <Button variant="contained" color="secondary"
                  onClick={() => { callIFood(); }}>
                  Chamar Entregador do iFood
                </Button>
              </Grid>
            </ShowIf>
            {deliveryRequest && (
              <ShowIf condition={[DeliveryRequestStatus.FINISHED].includes(deliveryRequest.status)}>
                <Grid item>
                  <Button variant="contained" color="secondary"
                    onClick={() => {
                      handleCurrectValueDelivery();
                    }}>
                    Corrigir taxa de Entrega
                  </Button>
                </Grid>
              </ShowIf>
            )}

            <ShowIf condition={AuthService.hasAuthorization("refund_deliveryrequest")}>
              {(!isActive && !deliveryRequest?.refunded) && (
                <Grid item>
                  <Button variant="contained" color="secondary"
                    onClick={() => { refundDeliveryRequest(); }}>
                    Estornar Solicitação
                  </Button>
                </Grid>
              )}
            </ShowIf>
            {(deliveryRequest && ![
              DeliveryRequestStatus.NEW,
              DeliveryRequestStatus.NO_COURIER,
              DeliveryRequestStatus.CANCELED,
              DeliveryRequestStatus.FINISHED,
              DeliveryRequestStatus.DISCARDED
            ].includes(deliveryRequest.status)) && (
                <Grid item>
                  <Button variant="contained" color="secondary"
                    onClick={() => { handleClickOpen(); }}>
                    Enviar Aviso
                  </Button>
                </Grid>
              )}
            {deliveryRequest && (
              <ShowIf condition={[DeliveryRequestStatus.NEW, DeliveryRequestStatus.NO_COURIER].includes(deliveryRequest.status)}>
                <Grid item>
                  <Button variant="contained" color="secondary"
                    onClick={() => {
                      handleSearchCourierForDelivery();
                    }}>
                    Solicitar Entregador
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="secondary"
                    onClick={() => {
                      handleSetCourierForDelivery();
                    }}>
                    Direcionar para Entregador
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDiscardClick}
                  >
                    Descartar Entrega
                  </Button>
                </Grid>
              </ShowIf>
            )}


            {(AuthService.hasAuthorization("cancel_deliveryrequest")
              && deliveryRequest
              && (deliveryRequest.current_courier)
              && [
                DeliveryRequestStatus.NO_COURIER,
                DeliveryRequestStatus.ACCEPTED
              ].includes(deliveryRequest.status))
              && (
                <Grid item>
                  <Button variant="contained" color="secondary"
                    onClick={() => { cancelCourierCall(); }}>
                    Parar Chamada
                  </Button>
                </Grid>
              )}
            <ShowIf condition={AuthService.hasAuthorization("cancel_deliveryrequest")}>
              {isActive && deliveryRequest && ![DeliveryRequestStatus.DISCARDED].includes(deliveryRequest.status) && (
                <Grid item>
                  <Button variant="contained" color="secondary"
                    onClick={() => { cancelTrip(); }}>
                    Cancelar Entrega
                  </Button>
                </Grid>
              )}
            </ShowIf>
          </Grid>
        </Grid>

        <Grid item style={{ width: "100%" }}>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            className="tabs"
          >
            <Tab label="Mapa" value={"map"} />
            <Tab label="Linha do Tempo" value={"timeline"} />
            <Tab label="Chamados" value={"called"} />
            {deliveryRequest && [DeliveryRequestStatus.FINISHED].includes(deliveryRequest.status) && (
              <Tab label="Correção da Taxa" value={"releases"} />
            )};

            {/*isActive && (<CustomTab label="Busca" />)*/}
            {/* <CustomTab label="Ocorrências" disabled/> */}
          </Tabs>
        </Grid>
      </Grid>
      {deliveryRequest && (
        <React.Fragment>
          <TabPanel tabValue={tabValue} index={"map"}>
            <Map deliveryRequest={deliveryRequest} isActive={isActive} refreshData={refreshData} />
          </TabPanel>
          <TabPanel tabValue={tabValue} index={"timeline"}>
            <Timeline deliveryRequest={deliveryRequest} />
          </TabPanel>
          <TabPanel tabValue={tabValue} index={"called"}>
            <Called deliveryRequest={deliveryRequest} deliveryList={[deliveryRequest]} />
          </TabPanel>
          <TabPanel tabValue={tabValue} index={"releases"}>
            <Releases deliveryRequest={deliveryRequest} />
          </TabPanel>
        </React.Fragment>
      )}
      {/*deliveryRequest && isActive && (
        <TabPanel tabValue={tabValue} index={3}>
          <Search deliveryRequest={deliveryRequest} />
        </TabPanel>
      )*/}

      <TabPanel tabValue={tabValue} index={"occurrences"}>
        <Occurrences />
      </TabPanel>

      <CorrectDeliveryValueDialog
        ref={correctValueDeliveryDialog}
        deliveryId={deliveryRequest ? Number(deliveryRequest?.id) : 0}
        onAccept={correctDeliveryValueDone}
      />

    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      position: "absolute",
    }
  })
);
