import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  MenuItem,
  Button,
  TextField,
  InputAdornment,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";

import { Actions as settingsActions } from "../../store/ducks/settings";
import { ISettigs } from "../../store/types/settings";
import { IGlobalState } from "../../store/types/state";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  minimum_delivery_value: Yup.number().required("Campo obrigatório"),
  base_additional_km_value: Yup.string().required("Campo obrigatório"),
  base_return_value: Yup.number().required("Campo obrigatório"),
});
export const Settings: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { settings, additional_km } = useSelector(
    (state: IGlobalState) => state.settings
  );

  const [additional, setAdditional] = useState(
    settings.base_additional_km_value || ""
  );

  const updateSettings = (settings: ISettigs) =>
    settingsActions.updateSettings(dispatch, settings);

  const getSettings = () => settingsActions.getSettings(dispatch);

  const getAdditionalKm = () => settingsActions.getAdditionalKm(dispatch);

  const formik = useFormik({
    initialValues: settings,
    onSubmit: async (values) => {
      let {
        minimum_delivery_value,
        base_additional_km_value,
        base_return_value,
      } = values;

      updateSettings({
        minimum_delivery_value: parseFloat(`${minimum_delivery_value}`).toFixed(
          2
        ),
        base_additional_km_value: parseFloat(
          `${base_additional_km_value}`
        ).toFixed(2),
        base_return_value: parseFloat(`${base_return_value}`).toFixed(2),
      });
    },
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
  });

  useEffect(() => {
    getAdditionalKm();
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik.values.base_additional_km_value) {
      setAdditional(formik.values.base_additional_km_value);
    }
  }, [formik.values, formik.values.base_additional_km_value]);

  return (
    <div className={classes.root}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h1" >Configuração</Typography>
        </Grid>
      </Grid>
      <Grid container justify="space-between">
        <Grid item>
          <CustomBreadcrumbs
            pathList={[
              { label: "Configurações", url: "/manager/configuration" },
            ]}
          />
        </Grid>
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
          <Typography variant="h3">Valores do KM</Typography>
          <Grid container spacing={2} className={classes.row}>
            <Grid item md={4}>
              <TextField
                id="minimum_delivery_value"
                label="Valor KM mínimo"
                variant="standard"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                  inputProps: { min: 0.0, max: 10000.0, step: 0.01 },
                }}
                value={formik.values.minimum_delivery_value}
                onChange={formik.handleChange}
                error={!!formik.errors.minimum_delivery_value}
                helperText={formik.errors.minimum_delivery_value}
                className={classes.textField}
                fullWidth
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="base_additional_km_value"
                select
                label="Valor KM adicional"
                variant="standard"
                value={additional}
                onChange={(e) => {
                  formik.setFieldValue(
                    "base_additional_km_value",
                    e.target.value
                  );
                }}
                error={!!formik.errors.base_additional_km_value}
                helperText={formik.errors.base_additional_km_value}
                className={classes.textField}
                fullWidth
              >
                {additional_km.length > 0 &&
                  additional_km.map((option) => (
                    <MenuItem key={option.id} value={option.price}>
                      {`R$ ${option.price}`}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item md={4}>
              <TextField
                id="base_return_value"
                label="Valor KM de retorno"
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                  inputProps: { min: 0.0, max: 10000.0, step: 0.01 },
                }}
                type="number"
                value={formik.values.base_return_value}
                onChange={formik.handleChange}
                error={!!formik.errors.base_return_value}
                helperText={formik.errors.base_return_value}
                className={classes.textField}
                fullWidth
              />
            </Grid>
          </Grid>
          </CardContent>
        </Card>

        <Grid container spacing={2}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={classes.button}
            >
              Cadastrar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "10px 12px",
    },
    button: {
      marginTop: "20px",
      width: "150px",
      textTransform: "capitalize",
      borderRadius: "4px",
    },
    textField: {
      display: "flex",
      justifyContent: "center",
      // alignItems: "flex-end",
    },
    row: {
      marginBottom: "10px",
    },
    helper: {
      color: theme.palette.error.main,
    },
  })
);
