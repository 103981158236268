import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import { StylesProvider, MuiThemeProvider } from "@material-ui/core/styles";
import '@material-ui/styles' // <-- Here

import theme from "./styles/theme";
import store from "./store";
import { App } from "./App";
import "./i18n";
import "./assets/css/app.css";
import { LoadingPage } from "./components/Loading";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <Suspense fallback={<LoadingPage />}>
            <App />
          </Suspense>
        </MuiThemeProvider>
      </StylesProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
