import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  CardContent,
  Card,
  makeStyles,
  Theme,
  createStyles,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { Autocomplete } from "@material-ui/lab";
import RegionService from "../../services/regionService";
import CommercialPlanService from "../../services/commercialPlanService";
import { EMPTY_COMMERCIAL_PLAN, CreateUpdateCommercialPlan, CreateUpdateAdditionalRulesPlan } from "../../services/types/commercialPlan";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { CurrencyInput } from "../../components/CurrencyInput";
import { getNumberSpecs } from "../../i18n";
import numbro from "numbro";
import { LoadingDialog } from "../../components/LoadingDialog";
import { VehicleType } from "../../services/types/courier";
import PinDropIcon from '@material-ui/icons/PinDrop';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import { convertToNumber } from "../../utils/utils";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  minimum_value_distance: Yup.number().positive().nullable().required("Campo obrigatório"),
  bike_minimum_speedy_value: Yup.number().moreThan(0, "Campo obrigatório").required("Campo obrigatório"),
  bike_minimum_courier_value: Yup.number().moreThan(0, "Campo obrigatório").required("Campo obrigatório"),
  motorcycle_minimum_speedy_value: Yup.number().moreThan(0, "Campo obrigatório").required("Campo obrigatório"),
  motorcycle_minimum_courier_value: Yup.number().moreThan(0, "Campo obrigatório").required("Campo obrigatório"),
  car_minimum_speedy_value: Yup.number().moreThan(0, "Campo obrigatório").required("Campo obrigatório"),
  car_minimum_courier_value: Yup.number().moreThan(0, "Campo obrigatório").required("Campo obrigatório"),
  van_minimum_speedy_value: Yup.number().moreThan(0, "Campo obrigatório").required("Campo obrigatório"),
  van_minimum_courier_value: Yup.number().moreThan(0, "Campo obrigatório").required("Campo obrigatório"),
});

interface NewEditCommercialPlanParams {
  commercialPlanId?: string;
}
export const EditCommercialPlan: React.FC = ()  => {
  const history = useHistory();
  const classes = useStyles();
  const [regionList, setRegionList] = useState<any[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const { commercialPlanId } = useParams<NewEditCommercialPlanParams>();
  const [loadingDialogMessage, setLoadingDialogMessage] = React.useState("");
  const [isShowLoadingDialog, showLoadingDialog] = React.useState(false);
  const [commercialPlanFormData, setCommercialPlanFormData] = React.useState<CreateUpdateCommercialPlan>(EMPTY_COMMERCIAL_PLAN);
  const [inputListBike, setInputListBike] = React.useState<CreateUpdateAdditionalRulesPlan[]>([]);
  const [inputListMotorcycle, setInputListMotorcycle] = React.useState<CreateUpdateAdditionalRulesPlan[]>([]);
  const [inputListCar, setInputListCar] = React.useState<CreateUpdateAdditionalRulesPlan[]>([]);
  const [inputListVan, setInputListVan] = React.useState<CreateUpdateAdditionalRulesPlan[]>([]);
  const [errorBike, setErrorBike] = useState<string | null>(null);
  const [errorMotorcycle, setErrorMotorcycle] = useState<string | null>(null);
  const [errorCar, setErrorCar] = useState<string | null>(null);
  const [errorVan, setErrorVan] = useState<string | null>(null);

  const loadPlan = React.useCallback(async (id: number) => {
    setLoadingDialogMessage("Aguarde...");
    showLoadingDialog(true);
    try {
      const plan = await CommercialPlanService.getCommercialPlanById(id);
      setSelectedRegion(plan.region);

      setInputListBike(plan.bike_additional_km_rules);
      setInputListMotorcycle(plan.motorcycle_additional_km_rules);
      setInputListCar(plan.car_additional_km_rules);
      setInputListVan(plan.van_additional_km_rules);

      setCommercialPlanFormData({
        ...plan,
        region: plan.region?.id
      });
    } finally {
      showLoadingDialog(false);
    }
  }, []);

  useEffect(() => {
    console.log("commercialPlanFormData", commercialPlanFormData);
  }, [commercialPlanFormData]);

  const formik = useFormik({
    initialValues: commercialPlanFormData,
    onSubmit: (values: CreateUpdateCommercialPlan) => {
      values.bike_additional_km_rules = inputListBike;
      values.motorcycle_additional_km_rules = inputListMotorcycle;
      values.car_additional_km_rules = inputListCar;
      values.van_additional_km_rules = inputListVan;

      CommercialPlanService.updateCommercialPlan(values)
        .then(response => {
          NotificationManager.success("Plano comercial atualizado com sucesso.");
          history.push({ pathname: "/commercialPlan" });
        })
        .catch(error => {
          NotificationManager.error(
            "Ocorreu um erro ao tentar atualizar o plano comercial. Por favor, tente novamente. "
          );
        });
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  useEffect(() => {
    if (commercialPlanId) {
      loadPlan(Number(commercialPlanId));
    }
  }, [loadPlan, commercialPlanId]);

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    RegionService
      .loadAllRegions({ "ordering": "name" })
      .then((response) => {
        setRegionList(response);
      })
      .catch((error) => { });
  }, []);

  const handleInput = (inputName: string, inputValue: any, type: string, index: number) => {
    const value = inputValue;
    const atribute = inputName;
    switch (type) {
      case VehicleType.BIKE:
        const listBike: any = [...inputListBike];
        listBike[index][atribute] = value;
        setInputListBike(listBike);
        break;
      case VehicleType.MOTORCYCLE:
        const listMotorcycle: any = [...inputListMotorcycle];
        listMotorcycle[index][atribute] = value;
        setInputListMotorcycle(listMotorcycle);
        break;
      case VehicleType.CAR:
        const listCar: any = [...inputListCar];
        listCar[index][atribute] = value;
        setInputListCar(listCar);
        break;
      case VehicleType.VAN:
        const listVan: any = [...inputListVan];
        listVan[index][atribute] = value;
        setInputListVan(listVan);
        break;
    };
  };

  const handleAddItem = (type: string, lastEndKm: string) => {
    switch (type) {
      case VehicleType.BIKE:
        setInputListBike([...inputListBike, {start_m: lastEndKm, end_m: null, speedy_value: "00.00", courier_value: "00.00" }]);    
        break;
      case VehicleType.MOTORCYCLE:
        setInputListMotorcycle([...inputListMotorcycle, {start_m: lastEndKm, end_m: null, speedy_value: "00.00", courier_value: "00.00" }]);
        break;
      case VehicleType.CAR:
        setInputListCar([...inputListCar, {start_m: lastEndKm, end_m: null, speedy_value: "00.00", courier_value: "00.00" }]);
        break;
      case VehicleType.VAN:
        setInputListVan([...inputListVan, {start_m: lastEndKm, end_m: null, speedy_value: "00.00", courier_value: "00.00" }]);
        break;
    };
  };

  const handleRemoveItem = (index: number, type: string) => {    
    switch (type) {
      case VehicleType.BIKE:
        const listBike = [...inputListBike];
        listBike.splice(index, 1);
        setInputListBike(listBike);
        break;
      case VehicleType.MOTORCYCLE:
        const listMotorcycle = [...inputListMotorcycle];
        listMotorcycle.splice(index, 1);
        setInputListMotorcycle(listMotorcycle);
        break;
      case VehicleType.CAR:
        const listCar = [...inputListCar];
        listCar.splice(index, 1);
        setInputListCar(listCar);
        break;
      case VehicleType.VAN:
        const listVan = [...inputListVan];
        listVan.splice(index, 1);
        setInputListVan(listVan);
        break;
    };
  };

  const handleValidate = () => {
    let isValid: boolean = true;
    if(inputListBike.length > 0){
      const lastItemBike: CreateUpdateAdditionalRulesPlan = inputListBike[inputListBike.length - 1];
      if(lastItemBike.end_m !== null && lastItemBike.end_m !== ""){
        setErrorBike("Necessário deixar o campo vazio.");
        isValid = false;
      };
    };
    if(inputListMotorcycle.length > 0){
      const lastItemMotorcycle: CreateUpdateAdditionalRulesPlan = inputListMotorcycle[inputListMotorcycle.length - 1];
      if(lastItemMotorcycle.end_m !== null && lastItemMotorcycle.end_m !== ""){
        setErrorMotorcycle("Necessário deixar o campo vazio.");
        isValid = false;
      };
    };
    if(inputListCar.length > 0){
      const lastItemCar: CreateUpdateAdditionalRulesPlan = inputListCar[inputListCar.length - 1];
      if(lastItemCar.end_m !== null && lastItemCar.end_m !== ""){
        setErrorCar("Necessário deixar o campo vazio.");
        isValid = false;
      };
    };
    if(inputListVan.length > 0){
      const lastItemVan: CreateUpdateAdditionalRulesPlan = inputListVan[inputListVan.length - 1];
      if(lastItemVan.end_m !== null && lastItemVan.end_m !== ""){
        setErrorVan("Necessário deixar o campo vazio.");
        isValid = false;
      };
    };
    if(isValid){
      formik.handleSubmit();
    };
  };

  const changeKmsAdditionalInitial = (value: string) => {
    const listBike: any = [...inputListBike];
    if(inputListBike.length > 0){
      listBike[0]["start_m"] = value ? value : "0";
      setInputListBike(listBike);
    };

    const listMotorcycle: any = [...inputListMotorcycle];
    if(inputListMotorcycle.length > 0){
      listMotorcycle[0]["start_m"] = value ? value : "0";
      setInputListMotorcycle(listMotorcycle);
    };

    const listCar: any = [...inputListCar];
    if(inputListMotorcycle.length > 0){
      listCar[0]["start_m"] = value ? value : "0";
      setInputListCar(listCar);
    };

    const listVan: any = [...inputListVan];
    if(inputListMotorcycle.length > 0){
      listVan[0]["start_m"] = value ? value : "0";
      setInputListVan(listVan);
    };

    if(inputListBike.length > 0 && Number(listBike[0]["start_m"]) >= Number(listBike[0]["end_m"])){
      listBike[0]["end_m"] = null;
      setInputListBike([listBike[0]]);
    };
    if(inputListMotorcycle.length > 0 && Number(listMotorcycle[0]["start_m"]) >= Number(listMotorcycle[0]["end_m"])){
      listMotorcycle[0]["end_m"] = null;
      setInputListMotorcycle([listMotorcycle[0]]);
    };
    if(inputListMotorcycle.length > 0 && Number(listCar[0]["start_m"]) >= Number(listCar[0]["end_m"])){
      listCar[0]["end_m"] = null;
      setInputListCar([listCar[0]]);
    };
    if(inputListMotorcycle.length > 0 && Number(listVan[0]["start_m"]) >= Number(listVan[0]["end_m"])){
      listVan[0]["end_m"] = null;
      setInputListVan([listVan[0]]);
    };
  };

  return (
    <div>
      <LoadingDialog open={isShowLoadingDialog} message={loadingDialogMessage} />
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Editar Plano Comercial</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[
                { label: "Planos Comerciais", url: "/commercialPlan" },
                { label: "Editar Plano Comercial", url: "/commercialPlan/edit/:" },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <Card className={classes.styleCard}>
          <CardContent>
            <Typography variant="h2">Dados Gerais</Typography>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <TextField
                  id="name"
                  label="Nome"
                  variant="outlined"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  helperText={formik.errors.name}
                  error={!!formik.errors.name}
                  InputLabelProps={{ required: true }}
                  autoFocus
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <Autocomplete
                  id="region"
                  size="small"
                  value={selectedRegion}
                  options={regionList}
                  getOptionLabel={(option) => `${option.name} - ${option.state}`}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedRegion(newValue);
                      formik.setFieldValue("region", newValue.id);
                    } else {
                      setSelectedRegion(null);
                      formik.setFieldValue("region", null);
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      name="region"
                      label="Região"
                      variant="outlined"
                      value={formik.values.region ? formik.values.region.id : null}
                      onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                      fullWidth
                      error={!!formik.errors.region}
                      helperText={formik.errors.region}
                      InputLabelProps={{ required: false }}
                    />
                  }
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  id="minimum_value_distance"
                  label="Distância do valor mínimo (m)"
                  variant="outlined"
                  placeholder="Valor em metros, ex: 2000 = 2km"
                  value={formik.values.minimum_value_distance ? formik.values.minimum_value_distance : 0}
                  onChange={(e)=>{
                    formik.handleChange(e);
                    changeKmsAdditionalInitial(e.target.value);
                  }}
                  helperText={formik.errors.minimum_value_distance}
                  error={!!formik.errors.minimum_value_distance}
                  InputLabelProps={{ required: true }}
                  autoFocus
                  fullWidth
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    Os campos com (*) são de preenchimento obrigatório.
                </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card className={classes.styleCard}>
          <CardContent>
            <Typography variant="h2">Bicicleta</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Table className="table-noborder">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Valor Mínimo</TableCell>
                      <TableCell colSpan={2}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <TextField
                          label="Valor mínimo Speedy"
                          id="bike_minimum_speedy_value"
                          name="bike_minimum_speedy_value"
                          value={formik.values.bike_minimum_speedy_value}
                          error={!!formik.errors.bike_minimum_speedy_value}
                          helperText={formik.errors.bike_minimum_speedy_value}
                          required={true}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>                    
                      {inputListBike.length === 0 &&
                        <TableCell style={{width: "100px", textAlign: "center"}}>
                          <Tooltip title="Adicionar Km adicional" className={classes.paddingBtn}>
                            <IconButton edge="end" aria-label="Adicionar" onClick={() => {
                                  handleAddItem(VehicleType.BIKE, formik.values.minimum_value_distance);                               
                                }}>
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      }                                           
                      <TableCell>
                        <TextField
                          label="Valor mínimo Entregador"
                          id="bike_minimum_courier_value"
                          name="bike_minimum_courier_value"
                          value={formik.values.bike_minimum_courier_value}
                          error={!!formik.errors.bike_minimum_courier_value}
                          helperText={formik.errors.bike_minimum_courier_value}
                          required={true}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                    {inputListBike.map((item, index) => (
                    <>
                    {index === 0 && (
                      <Typography className={classes.styleKmsAdicionais}>kms Adicionais</Typography>
                    )}                   
                    <TableRow key={"bike_"+index} className={classes.paddingTableCell}>
                      <TableCell className={classes.paddingTableCell}>
                        <TextField
                          label={"KM Inicial (m)"}
                          id={"start_m_bike_"+index}
                          name={"start_m_bike_"+index}
                          value={item.start_m}
                          type="number"
                          onChange={(e) => handleInput("start_m", e.target.value, VehicleType.BIKE, index)}                         
                          InputProps={{
                            startAdornment: <InputAdornment position="start" style={{color:"#9e9e9e"}}><PinDropIcon /></InputAdornment>,
                          }}
                          disabled={true}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell className={classes.paddingTableCell}>
                      <TextField
                          label="KM Final (m)"
                          id={"end_m_bike_"+index}
                          name={"end_m_bike_"+index}
                          value={item.end_m}
                          type="number"
                          onChange={(e) => {
                            setErrorBike(null);
                            const value = e.target.value !== "" ? e.target.value : null;
                            handleInput("end_m", value, VehicleType.BIKE, index)
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start" style={{color:"#9e9e9e"}}><PinDropIcon /></InputAdornment>,
                          }}
                          disabled={inputListBike.length - 1 !== index}
                          variant="outlined"
                          placeholder="Indeterminado"
                          fullWidth
                          error={inputListBike.length - 1 === index ? !!errorBike : false}
                          helperText={inputListBike.length - 1 === index ? errorBike : ""}
                        />                          
                      </TableCell>
                      
                      <TableCell style={{width: "150px", textAlign: "center"}}>
                        {inputListBike.length - 1 === index && 
                        <>
                          <Tooltip title="Adicionar Km adicional" className={classes.paddingBtn}>
                            <IconButton edge="end" aria-label="Adicionar" onClick={() => {
                                const lastStartValue = Number(inputListBike[inputListBike.length - 1].start_m);
                                const lastEndValue = Number(inputListBike[inputListBike.length - 1].end_m);

                                if(!lastEndValue || lastEndValue <= lastStartValue){
                                  setErrorBike("O quilometro final precisa ser maior que o inicial.");
                                }else{
                                  setErrorBike(null);
                                  let lastEndKm: any = "";
                                  if(inputListBike[index].end_m){
                                    lastEndKm = inputListBike[index].end_m
                                  }
                                  handleAddItem(VehicleType.BIKE, lastEndKm);
                                }
                                }}>
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remover Km adicional" className={classes.paddingBtn}>
                           <IconButton edge="end" aria-label="Remover" onClick={(e) => {   
                             setErrorBike(null);
                             handleRemoveItem(index, VehicleType.BIKE);
                             }}>
                             <DeleteIcon />
                           </IconButton>
                         </Tooltip>
                        </>
                        }                      
                      </TableCell>
                       
                      <TableCell className={classes.paddingTableCell}>
                        <TextField
                          label="Speedy"
                          id={"speedy_value_bike_"+index}
                          name={"speedy_value_bike_"+index}
                          value={item.speedy_value}
                          required={true}
                          onChange={(e) => handleInput("speedy_value", convertToNumber(e.target.value), VehicleType.BIKE, index)}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell className={classes.paddingTableCell}>
                        <TextField
                          label="Entregador"
                          id={"courier_value_bike_"+index}
                          name={"courier_value_bike_"+index}
                          value={item.courier_value}
                          required={true}
                          onChange={(e) => handleInput("courier_value", convertToNumber(e.target.value), VehicleType.BIKE, index)}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                    </>
                  ))}
                  </TableBody>
                </Table>
                <Table className="table-noborder">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Valor mínimo de retorno</TableCell>
                      <TableCell colSpan={2}>Valor do Km de retorno</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <TextField
                          label="Speedy"
                          id="bike_minimum_speedy_return_value"
                          name="bike_minimum_speedy_return_value"
                          value={formik.values.bike_minimum_speedy_return_value}
                          error={!!formik.errors.bike_minimum_speedy_return_value}
                          helperText={formik.errors.bike_minimum_speedy_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Entregador"
                          id="bike_minimum_courier_return_value"
                          name="bike_minimum_courier_return_value"
                          value={formik.values.bike_minimum_courier_return_value}
                          error={!!formik.errors.bike_minimum_courier_return_value}
                          helperText={formik.errors.bike_minimum_courier_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Speedy"
                          id="bike_additional_km_speedy_return_value"
                          name="bike_additional_km_speedy_return_value"
                          value={formik.values.bike_additional_km_speedy_return_value}
                          error={!!formik.errors.bike_additional_km_speedy_return_value}
                          helperText={formik.errors.bike_additional_km_speedy_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Entregador"
                          id="bike_additional_km_courier_return_value"
                          name="bike_additional_km_courier_return_value"
                          value={formik.values.bike_additional_km_courier_return_value}
                          error={!!formik.errors.bike_additional_km_courier_return_value}
                          helperText={formik.errors.bike_additional_km_courier_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card className={classes.styleCard}>
          <CardContent>
            <Typography variant="h2">Moto</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Table className="table-noborder">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Valor Mínimo</TableCell>
                      <TableCell colSpan={2}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <TextField
                          label="Speedy"
                          id="motorcycle_minimum_speedy_value"
                          name="motorcycle_minimum_speedy_value"
                          value={formik.values.motorcycle_minimum_speedy_value}
                          error={!!formik.errors.motorcycle_minimum_speedy_value}
                          helperText={formik.errors.motorcycle_minimum_speedy_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      {inputListMotorcycle.length === 0 &&
                        <TableCell style={{width: "100px", textAlign: "center"}}>
                          <Tooltip title="Adicionar Km adicional" className={classes.paddingBtn}>
                            <IconButton edge="end" aria-label="Adicionar" onClick={() => {
                                  handleAddItem(VehicleType.MOTORCYCLE, formik.values.minimum_value_distance);                               
                                }}>
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      } 
                      <TableCell>
                        <TextField
                          label="Entregador"
                          id="motorcycle_minimum_courier_value"
                          name="motorcycle_minimum_courier_value"
                          value={formik.values.motorcycle_minimum_courier_value}
                          error={!!formik.errors.motorcycle_minimum_courier_value}
                          helperText={formik.errors.motorcycle_minimum_courier_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>

                    {inputListMotorcycle.map((item, index) => (
                    <>
                    {index === 0 && (
                      <Typography className={classes.styleKmsAdicionais}>kms Adicionais</Typography>
                    )}     
                    <TableRow key={"motorcycle_"+index} className={classes.paddingTableCell}>
                      <TableCell className={classes.paddingTableCell}>
                        <TextField
                          label={"KM Inicial (m)"}
                          id={"start_m_motorcycle_"+index}
                          name={"start_m_motorcycle_"+index}
                          value={item.start_m}
                          type="number"
                          onChange={(e) => handleInput("start_m", e.target.value, VehicleType.MOTORCYCLE, index)}
                          InputProps={{
                            startAdornment: <InputAdornment position="start" style={{color:"#9e9e9e"}}><PinDropIcon /></InputAdornment>,
                          }}
                          disabled={true}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell className={classes.paddingTableCell}>
                      <TextField
                          label="KM Final (m)"
                          id={"end_m_motorcycle_"+index}
                          name={"end_m_motorcycle_"+index}
                          value={item.end_m}
                          type="number"
                          onChange={(e) => {
                            setErrorMotorcycle(null);
                            const value = e.target.value !== "" ? e.target.value : null;
                            handleInput("end_m", value, VehicleType.MOTORCYCLE, index)
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start" style={{color:"#9e9e9e"}}><PinDropIcon /></InputAdornment>,
                          }}
                          disabled={inputListMotorcycle.length - 1 !== index}
                          variant="outlined"
                          placeholder="Indeterminado"
                          fullWidth
                          error={inputListMotorcycle.length - 1 === index ? !!errorMotorcycle : false}
                          helperText={inputListMotorcycle.length - 1 === index ? errorMotorcycle : ""}
                        />                          
                      </TableCell>
                      
                      <TableCell style={{width: "150px", textAlign: "center"}}>
                        {inputListMotorcycle.length - 1 === index &&
                        <>
                          <Tooltip title="Adicionar Km adicional" className={classes.paddingBtn}>
                            <IconButton edge="end" aria-label="Adicionar" onClick={() => {
                                const lastStartValue = Number(inputListMotorcycle[inputListMotorcycle.length - 1].start_m);
                                const lastEndValue = Number(inputListMotorcycle[inputListMotorcycle.length - 1].end_m);
                                if(!lastEndValue || lastEndValue <= lastStartValue){
                                  setErrorMotorcycle("O quilometro final precisa ser maior que o inicial.");
                                }else{
                                  setErrorMotorcycle(null);
                                  let lastEndKm: any = "";
                                  if(inputListMotorcycle[index].end_m){
                                    lastEndKm = inputListMotorcycle[index].end_m
                                  }
                                  handleAddItem(VehicleType.MOTORCYCLE, lastEndKm);
                                }
                                }}>
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remover Km adicional" className={classes.paddingBtn}>
                            <IconButton edge="end" aria-label="Remover" onClick={(e) => {   
                              setErrorMotorcycle(null);
                              handleRemoveItem(index, VehicleType.MOTORCYCLE);
                              }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                        }                      
                      </TableCell>
                       
                      <TableCell className={classes.paddingTableCell}>
                        <TextField
                          label="Speedy"
                          id={"speedy_value_motorcycle_"+index}
                          name={"speedy_value_motorcycle_"+index}
                          value={Number(item.speedy_value)}
                          onChange={(e) => handleInput("speedy_value", convertToNumber(e.target.value), VehicleType.MOTORCYCLE, index)}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell className={classes.paddingTableCell}>
                        <TextField
                          label="Entregador"
                          id={"courier_value_motorcycle"+index}
                          name={"courier_value_motorcycle"+index}
                          value={Number(item.courier_value)}
                          onChange={(e) => handleInput("courier_value", convertToNumber(e.target.value), VehicleType.MOTORCYCLE, index)}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                    </>
                  ))}
                  </TableBody>
                </Table>
                <Table className="table-noborder">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Valor mínimo de retorno</TableCell>
                      <TableCell colSpan={2}>Valor do Km de retorno</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <TextField
                          label="Speedy"
                          id="motorcycle_minimum_speedy_return_value"
                          name="motorcycle_minimum_speedy_return_value"
                          value={formik.values.motorcycle_minimum_speedy_return_value}
                          error={!!formik.errors.motorcycle_minimum_speedy_return_value}
                          helperText={formik.errors.motorcycle_minimum_speedy_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Entregador"
                          id="motorcycle_minimum_courier_return_value"
                          name="motorcycle_minimum_courier_return_value"
                          value={formik.values.motorcycle_minimum_courier_return_value}
                          error={!!formik.errors.motorcycle_minimum_courier_return_value}
                          helperText={formik.errors.motorcycle_minimum_courier_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Speedy"
                          id="motorcycle_additional_km_speedy_return_value"
                          name="motorcycle_additional_km_speedy_return_value"
                          value={formik.values.motorcycle_additional_km_speedy_return_value}
                          error={!!formik.errors.motorcycle_additional_km_speedy_return_value}
                          helperText={formik.errors.motorcycle_additional_km_speedy_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Entregador"
                          id="motorcycle_additional_km_courier_return_value"
                          name="motorcycle_additional_km_courier_return_value"
                          value={formik.values.motorcycle_additional_km_courier_return_value}
                          error={!!formik.errors.motorcycle_additional_km_courier_return_value}
                          helperText={formik.errors.motorcycle_additional_km_courier_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card className={classes.styleCard}>
          <CardContent>
            <Typography variant="h2">Carro</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Table className="table-noborder">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Valor Mínimo</TableCell>
                      <TableCell colSpan={2}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <TextField
                          label="Speedy"
                          id="car_minimum_speedy_value"
                          name="car_minimum_speedy_value"
                          value={formik.values.car_minimum_speedy_value}
                          error={!!formik.errors.car_minimum_speedy_value}
                          helperText={formik.errors.car_minimum_speedy_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      {inputListCar.length === 0 &&
                        <TableCell style={{width: "100px", textAlign: "center"}}>
                          <Tooltip title="Adicionar Km adicional" className={classes.paddingBtn}>
                            <IconButton edge="end" aria-label="Adicionar" onClick={() => {
                                  handleAddItem(VehicleType.CAR, formik.values.minimum_value_distance);                               
                                }}>
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      } 
                      <TableCell>
                        <TextField
                          label="Entregador"
                          id="car_minimum_courier_value"
                          name="car_minimum_courier_value"
                          value={formik.values.car_minimum_courier_value}
                          error={!!formik.errors.car_minimum_courier_value}
                          helperText={formik.errors.car_minimum_courier_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                    {inputListCar.map((item, index) => (
                    <>
                    {index === 0 &&(
                      <Typography className={classes.styleKmsAdicionais}>kms Adicionais</Typography>
                    )}     
                    <TableRow key={"car_"+index} className={classes.paddingTableCell}>
                      <TableCell className={classes.paddingTableCell}>
                        <TextField
                          label={"KM Inicial (m)"}
                          id={"start_m_car_"+index}
                          name={"start_m_car_"+index}
                          value={item.start_m}
                          type="number"
                          onChange={(e) => handleInput("start_m", e.target.value, VehicleType.CAR, index)}
                          InputProps={{
                            startAdornment: <InputAdornment position="start" style={{color:"#9e9e9e"}}><PinDropIcon /></InputAdornment>,
                          }}
                          disabled={true}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell className={classes.paddingTableCell}>
                      <TextField
                          label="KM Final (m)"
                          id={"end_m_car_"+index}
                          name={"end_m_car_"+index}
                          value={item.end_m}
                          type="number"
                          onChange={(e) => {
                            setErrorCar(null);
                            const value = e.target.value !== "" ? e.target.value : null;
                            handleInput("end_m", value, VehicleType.CAR, index)
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start" style={{color:"#9e9e9e"}}><PinDropIcon /></InputAdornment>,
                          }}
                          disabled={inputListCar.length - 1 !== index}
                          variant="outlined"
                          placeholder="Indeterminado"
                          fullWidth
                          error={inputListCar.length - 1 === index ? !!errorCar : false}
                          helperText={inputListCar.length - 1 === index ? errorCar : ""}
                        />                          
                      </TableCell>
                      
                      <TableCell style={{width: "150px", textAlign: "center"}}>
                        {inputListCar.length - 1 === index &&
                        <>
                          <Tooltip title="Adicionar Km adicional" className={classes.paddingBtn}>
                            <IconButton edge="end" aria-label="Adicionar" onClick={() => {
                                const lastStartValue = Number(inputListCar[inputListCar.length - 1].start_m);
                                const lastEndValue = Number(inputListCar[inputListCar.length - 1].end_m);
                                if(!lastEndValue || lastEndValue <= lastStartValue){
                                  setErrorCar("O quilometro final precisa ser maior que o inicial.");
                                }else{
                                  setErrorCar(null);
                                  let lastEndKm: any = "";
                                  if(inputListCar[index].end_m){
                                    lastEndKm = inputListCar[index].end_m
                                  }
                                  handleAddItem(VehicleType.CAR, lastEndKm);
                                }
                                }}>
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remover Km adicional" className={classes.paddingBtn}>
                            <IconButton edge="end" aria-label="Remover" onClick={(e) => {   
                              setErrorCar(null);
                              handleRemoveItem(index, VehicleType.CAR);
                              }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                        }                      
                      </TableCell>
                       
                      <TableCell className={classes.paddingTableCell}>
                        <TextField
                          label="Speedy"
                          id={"speedy_value_car_"+index}
                          name={"speedy_value_car_"+index}
                          value={item.speedy_value}
                          onChange={(e) => handleInput("speedy_value", convertToNumber(e.target.value), VehicleType.CAR, index)}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          inputProps={{
                            maxLength: 10
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell className={classes.paddingTableCell}>
                        <TextField
                          label="Entregador"
                          id={"courier_value_car_"+index}
                          name={"courier_value_car_"+index}
                          value={item.courier_value}
                          onChange={(e) => handleInput("courier_value", convertToNumber(e.target.value), VehicleType.CAR, index)}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                    </>
                  ))}
                  </TableBody>
                </Table>
                <Table className="table-noborder">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Valor mínimo de retorno</TableCell>
                      <TableCell colSpan={2}>Valor do Km de retorno</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <TextField
                          label="Speedy"
                          id="car_minimum_speedy_return_value"
                          name="car_minimum_speedy_return_value"
                          value={formik.values.car_minimum_speedy_return_value}
                          error={!!formik.errors.car_minimum_speedy_return_value}
                          helperText={formik.errors.car_minimum_speedy_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Entregador"
                          id="car_minimum_courier_return_value"
                          name="car_minimum_courier_return_value"
                          value={formik.values.car_minimum_courier_return_value}
                          error={!!formik.errors.car_minimum_courier_return_value}
                          helperText={formik.errors.car_minimum_courier_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Speedy"
                          id="car_additional_km_speedy_return_value"
                          name="car_additional_km_speedy_return_value"
                          value={formik.values.car_additional_km_speedy_return_value}
                          error={!!formik.errors.car_additional_km_speedy_return_value}
                          helperText={formik.errors.car_additional_km_speedy_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Entregador"
                          id="car_additional_km_courier_return_value"
                          name="car_additional_km_courier_return_value"
                          value={formik.values.car_additional_km_courier_return_value}
                          error={!!formik.errors.car_additional_km_courier_return_value}
                          helperText={formik.errors.car_additional_km_courier_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card className={classes.styleCard}>
          <CardContent>
            <Typography variant="h2">Furgão</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Table className="table-noborder">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Valor Mínimo</TableCell>
                      <TableCell colSpan={2}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <TextField
                          label="Speedy"
                          id="van_minimum_speedy_value"
                          name="van_minimum_speedy_value"
                          value={formik.values.van_minimum_speedy_value}
                          error={!!formik.errors.van_minimum_speedy_value}
                          helperText={formik.errors.van_minimum_speedy_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      {inputListVan.length === 0 &&
                        <TableCell style={{width: "100px", textAlign: "center"}}>
                          <Tooltip title="Adicionar Km adicional" className={classes.paddingBtn}>
                            <IconButton edge="end" aria-label="Adicionar" onClick={() => {
                                  handleAddItem(VehicleType.VAN, formik.values.minimum_value_distance);                               
                                }}>
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      } 
                      <TableCell>
                        <TextField
                          label="Entregador"
                          id="van_minimum_courier_value"
                          name="van_minimum_courier_value"
                          value={formik.values.van_minimum_courier_value}
                          error={!!formik.errors.van_minimum_courier_value}
                          helperText={formik.errors.van_minimum_courier_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                    {inputListVan.map((item, index) => (
                    <>
                    {index === 0 && (
                      <Typography className={classes.styleKmsAdicionais}>kms Adicionais</Typography>
                    )}     
                    <TableRow key={"van_"+index} className={classes.paddingTableCell}>
                      <TableCell className={classes.paddingTableCell}>
                        <TextField
                          label={"KM Inicial (m)"}
                          id={"start_m_van_"+index}
                          name={"start_m_van_"+index}
                          value={item.start_m}
                          type="number"
                          onChange={(e) => handleInput("start_m", e.target.value, VehicleType.VAN, index)}
                          InputProps={{
                            startAdornment: <InputAdornment position="start" style={{color:"#9e9e9e"}}><PinDropIcon /></InputAdornment>,
                          }}
                          disabled={true}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell className={classes.paddingTableCell}>
                      <TextField
                          label="KM Final (m)"
                          id={"end_m_van_"+index}
                          name={"end_m_van_"+index}
                          value={item.end_m}
                          type="number"
                          onChange={(e) => {
                            setErrorVan(null);
                            const value = e.target.value !== "" ? e.target.value : null;
                            handleInput("end_m", value, VehicleType.VAN, index)
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start" style={{color:"#9e9e9e"}}><PinDropIcon /></InputAdornment>,
                          }}
                          disabled={inputListVan.length - 1 !== index}
                          variant="outlined"
                          placeholder="Indeterminado"
                          fullWidth
                          error={inputListVan.length - 1 === index ? !!errorVan : false}
                          helperText={inputListVan.length - 1 === index ? errorVan : ""}
                        />                          
                      </TableCell>
                      
                      <TableCell style={{width: "150px", textAlign: "center"}}>
                        {inputListVan.length - 1 === index &&
                        <>
                          <Tooltip title="Adicionar Km adicional" className={classes.paddingBtn}>
                            <IconButton edge="end" aria-label="Adicionar" onClick={() => {
                                const lastStartValue = Number(inputListVan[inputListVan.length - 1].start_m);
                                const lastEndValue = Number(inputListVan[inputListVan.length - 1].end_m);
                                if(!lastEndValue || lastEndValue <= lastStartValue){
                                  setErrorVan("O quilometro final precisa ser maior que o inicial.");
                                }else{
                                  setErrorVan(null);
                                  let lastEndKm: any = "";
                                  if(inputListVan[index].end_m){
                                    lastEndKm = inputListVan[index].end_m
                                  }
                                  handleAddItem(VehicleType.VAN, lastEndKm);
                                }
                                }}>
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remover Km adicional" className={classes.paddingBtn}>
                            <IconButton edge="end" aria-label="Remover" onClick={(e) => {   
                              setErrorVan(null);
                              handleRemoveItem(index, VehicleType.VAN);
                              }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                        }                
                      </TableCell>                       
                      <TableCell className={classes.paddingTableCell}>
                        <TextField
                          label="Speedy"
                          id={"speedy_value_van_"+index}
                          name={"speedy_value_van_"+index}
                          value={item.speedy_value}
                          onChange={(e) => handleInput("speedy_value", convertToNumber(e.target.value), VehicleType.VAN, index)}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell className={classes.paddingTableCell}>
                        <TextField
                          label="Entregador"
                          id={"courier_value_van_"+index}
                          name={"courier_value_van_"+index}
                          value={item.courier_value}
                          onChange={(e) => handleInput("courier_value", convertToNumber(e.target.value), VehicleType.VAN, index)}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                    </>
                  ))}
                  </TableBody>
                </Table>
                <Table className="table-noborder">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Valor mínimo de retorno</TableCell>
                      <TableCell colSpan={2}>Valor do Km de retorno</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <TextField
                          label="Speedy"
                          id="van_minimum_speedy_return_value"
                          name="van_minimum_speedy_return_value"
                          value={formik.values.van_minimum_speedy_return_value}
                          error={!!formik.errors.van_minimum_speedy_return_value}
                          helperText={formik.errors.van_minimum_speedy_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Entregador"
                          id="van_minimum_courier_return_value"
                          name="van_minimum_courier_return_value"
                          value={formik.values.van_minimum_courier_return_value}
                          error={!!formik.errors.van_minimum_courier_return_value}
                          helperText={formik.errors.van_minimum_courier_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Speedy"
                          id="van_additional_km_speedy_return_value"
                          name="van_additional_km_speedy_return_value"
                          value={formik.values.van_additional_km_speedy_return_value}
                          error={!!formik.errors.van_additional_km_speedy_return_value}
                          helperText={formik.errors.van_additional_km_speedy_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Entregador"
                          id="van_additional_km_courier_return_value"
                          name="van_additional_km_courier_return_value"
                          value={formik.values.van_additional_km_courier_return_value}
                          error={!!formik.errors.van_additional_km_courier_return_value}
                          helperText={formik.errors.van_additional_km_courier_return_value}
                          onChange={(e) => formik.setFieldValue(e.target.id, numbro.unformat(e.target.value))}
                          required={true}
                          InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: <InputAdornment position="start">{getNumberSpecs().currency.symbol}</InputAdornment>,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Grid container spacing={2}>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              type="button"
              onClick={handleCancel}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" onClick={handleValidate} type="button">
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddingBtn: {
      padding: "8px 8px",
      margin: "0px 5px",
    },
    paddingTableCell: {
      paddingTop: "8px !important",
    },
    styleCard: {
      marginBottom: "10px !important",
    },
    styleKmsAdicionais: {
      color: "rgba(0, 0, 0, 0.87)",
      padding: "4px 8px",
      wordWrap: "break-word",
      fontFamily: "Nunito Sans,sans-serif !important",
      fontWeight: "bold",
  
    }
  })
);