import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AnnouncementsService from "../../services/announcementsService";
import { AnnouncementResponseDetail, DeliveryForType } from "../../services/types/announcement";
import { format } from "date-fns";
import { CURRENT_DATE_TIME_FORMAT } from "../../i18n";
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@material-ui/core";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { EditOutlined, FileCopyOutlined } from "@material-ui/icons";
import ShowIf from "../../components/ShowIf";
import AuthService from "../../services/authService";
import { convertDeliveryForTypeToOption } from "./announcementUtils";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

interface DetailAnnouncementParams {
    announcementId: string;
}

export const DetailAnnouncement: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [announcement, setAnnouncement] = useState<AnnouncementResponseDetail>();
    const { announcementId } = useParams<DetailAnnouncementParams>();

    useEffect(() => {
        const loadAnnouncement = async (id: number) => {
            const response = await AnnouncementsService.getAnnouncementById(id);
            setAnnouncement(response);
        };

        if (announcementId) {
            loadAnnouncement(Number(announcementId));
        }
    }, [announcementId]);

    const showDetailsCourier = async (id: number) => {
        history.push(`/courier/detail/${id}`);
      };

    return (
        <Grid
            container
            justify="space-between"
            alignItems="center"
            className="page-title"
            spacing={2}>
            <Grid item>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h1">Detalhes do Aviso</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[
                                { label: "Avisos", url: "/announcement" },
                                {
                                    label: "Detalhes do Aviso",
                                    url: `/announcement/detail/${announcementId}`,
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <Grid container spacing={1}>
                    <Grid item>
                        <ShowIf condition={AuthService.hasAuthorization("add_announcement")}>
                            <Button variant="contained" color="secondary"
                                startIcon={<FileCopyOutlined />}
                                component={Link} to={`/announcement/copy/${announcementId}`}>
                                Cópiar
                            </Button>
                        </ShowIf>
                    </Grid>
                    {!(announcement?.sent) && (
                        <Grid item>
                            <ShowIf condition={AuthService.hasAuthorization("change_announcement")}>
                                <Button variant="contained" color="secondary"
                                    startIcon={<EditOutlined />}
                                    component={Link} to={`/announcement/edit/${announcementId}`}>
                                    Editar
                                </Button>
                            </ShowIf>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h2">Dados do Aviso</Typography>
                        <Table className="table-bordered">
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Envio</Typography>
                                        <Typography variant="subtitle1">
                                            {announcement && t(
                                                "announcement_delivery_for_options." + 
                                                convertDeliveryForTypeToOption(announcement.delivery_for, announcement.only_active_couriers)
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>Local de Exibição</Typography>
                                        <Typography variant="subtitle1">
                                            {t(
                                                "announcement_screen_type." +
                                                announcement?.screen
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>Tipo de Envio</Typography>
                                        <Typography variant="subtitle1">
                                            {t(
                                                "announcement_type." +
                                                announcement?.announcement_type
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>Data de Envio</Typography>
                                        <Typography variant="subtitle1">
                                            {announcement?.send_date
                                                ? format(announcement.send_date, CURRENT_DATE_TIME_FORMAT)
                                                : "---"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>Data de Expiração</Typography>
                                        <Typography variant="subtitle1">
                                            {announcement?.expire_date
                                                ? format(announcement.expire_date, CURRENT_DATE_TIME_FORMAT)
                                                : "---"}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {announcement?.region && (
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Typography>Região</Typography>
                                            <Typography variant="subtitle1">
                                                {announcement?.region?.name} -
                                                {announcement?.region?.state}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Typography>Título</Typography>
                                        <Typography variant="subtitle1">
                                            {announcement?.title}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Typography>Mensagem</Typography>
                                        <Typography variant="subtitle1">
                                            {announcement?.content}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {announcement?.picture && (
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Typography>Arquivo</Typography>
                                            <img
                                                height="130"
                                                width="300"
                                                src={announcement?.picture || ""}
                                                alt="Arquivo"
                                            />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
                {announcement?.couriers && announcement.delivery_for === DeliveryForType.SPECIFIC_COURIERS && (
                    <Grid item xs={4} style={{marginTop: "10px"}}>
                        <Card>
                            <CardContent>
                                <Typography variant="h2">Entregadores</Typography>

                                <List component="nav" style={{width: "100%"}}>
                                    {announcement?.couriers.map((courier, index) => (

                                    <ListItem alignItems="flex-start" button key={index} onClick={() => showDetailsCourier(courier.id)} divider>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <AccountCircleIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={courier.name} secondary={courier.phonenumber +" - "+ courier.region.state} />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="details" onClick={() => showDetailsCourier(courier.id)}>
                                                <ChevronRightIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    ))}
                                   
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                )}
            </Grid>
        </Grid>
    );
};
