import React from "react";
import type { FC } from "react";

import { Table, TableBody, TableRow, TableCell, Typography, Grid, Theme, makeStyles } from "@material-ui/core";
import { DaysWeek, DedicatedResponse, TurnResponse } from "../../../services/types/dedicated";
import { format } from "date-fns";
import { CURRENT_DATE_TIME_FORMAT } from "../../../i18n";
import { useTranslation } from "react-i18next";
import { TurnCard } from "./turnCard";
import { TurnColumn } from "./turnColumn";
import clsx from "clsx";

interface DedicatedTurnsDetailsProps {
  dedicated: DedicatedResponse;
  turns: TurnResponse[];
};

const weekDays: DaysWeek[] = [
    DaysWeek.SUNDAY,
    DaysWeek.MONDAY,
    DaysWeek.TUESDAY,
    DaysWeek.WEDNESDAY,
    DaysWeek.THURSDAY,
    DaysWeek.FRIDAY,
    DaysWeek.SATURDAY
];

export const DedicatedTurnsDetails: FC<DedicatedTurnsDetailsProps> = ({
    dedicated,
    turns,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const getTurnsForDay = (day: DaysWeek) => {
        const turnsFilter = turns.filter(item => item.day === day)
        return turnsFilter ? turnsFilter : [];
    };
    
    const isToday = (dayWeek: string) => {
        const today = new Date();
        const day = weekDays[today.getDay()];
        return day === dayWeek;
    };

  return (
    <>
    <Table className="table-bordered">
        <TableBody>
            <TableRow>
                <TableCell>
                    <Typography>Data de Ínicio</Typography>
                    <Typography variant="subtitle1">
                        {dedicated?.first_datetime ? format(dedicated?.first_datetime, CURRENT_DATE_TIME_FORMAT) : "---"}
                    </Typography>
                </TableCell> 
                <TableCell >
                    <Typography>Loja</Typography>
                    <Typography variant="subtitle1">
                        {dedicated?.store ? dedicated?.store.name : "---"}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>Região</Typography>
                    <Typography variant="subtitle1">
                        {dedicated?.store?.region ? dedicated?.store?.region.name : "---"}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>Status</Typography>
                    <Typography variant="subtitle1">
                    {t("status_dedicated." + dedicated?.status)}
                    </Typography>
                </TableCell>                                                                                                              
            </TableRow>
            <TableRow>        
                <TableCell>
                    <Typography>Tipo de Proposta</Typography>
                    <Typography variant="subtitle1">
                        {t("proposal_type_dedicated." + dedicated.proposal_type)}
                    </Typography>
                </TableCell>                                 
                <TableCell>
                    <Typography>Modal Permitido</Typography>
                    <Typography variant="subtitle1">
                        {t("vehicle_type." + dedicated.modal_allowed)}
                    </Typography>
                </TableCell>  
                <TableCell>
                    <Typography>Descrição</Typography>
                    <Typography variant="subtitle1">
                        {dedicated.description.trim().length === 0 ? "---" : dedicated.description}
                    </Typography>
                </TableCell>                         
                <TableCell>
                    <Typography>Recorrente</Typography>
                    <Typography variant="subtitle1">
                        {dedicated?.recurrent ? "Sim" : "Não"}
                    </Typography>
                </TableCell>                                          
            </TableRow>                      
        </TableBody>
    </Table>

    <Grid container style={{margin: "15px 0px"}}>
        <Grid item >
            <Typography variant="h2">
                Turnos
            </Typography>
        </Grid>                        
    </Grid>
    <Grid container spacing={0} wrap="nowrap" className={classes.page}>
        {weekDays.map((day, index) => (
            <Grid item xs className={classes.weekColumnsGrid}>
                <TurnColumn
                title={t("dedicated_week." +day)}
                className={classes.list}
                titleClassName={clsx(classes.columnTitle, { 
                    [classes.todayColumnTitle]: isToday(day),
                    [classes.firstColumnTitle]: (index === 0),
                    [classes.lastColumnTitle]: (index === (weekDays.length - 1)),
                    })}>
                <div className={classes.listContent}>
                    {getTurnsForDay(day).map((turnFilter) => (
                        <TurnCard turn={turnFilter}></TurnCard>
                    ))}
                </div>
                </TurnColumn>
            </Grid>
        ))}
    </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
    page: {
      flex: 1,
      display: "flex",
      height: "50vh",
    },
    weekColumnsGrid: {
      height: "calc(60vh - 108px)",
    },
    list: {
      display: "flex",
      flexFlow: "column",
      flex: 1,
      maxHeight: "100%",
      height: "100%",
    },
    listContent: {
      flex: 1,
      marginTop: 0,
      padding: theme.spacing(1),
      overflow: "auto",
    },
    columnTitle: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      textTransform: "capitalize",
      position: "relative",
      borderWidth: "0 1px 0 0",
      borderStyle: "solid",
      borderColor: "rgba(0, 0, 0, 0.25)",
    },
    todayColumnTitle: {
      backgroundColor: "#d99528",
    },
    firstColumnTitle: {
      borderRadius: "4px 0 0 0",
    },
    lastColumnTitle: {
      borderRadius: "0 4px 0 0",
      borderWidth: "0",
    }
  }));