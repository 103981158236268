import React from "react";
import { useParams } from "react-router-dom";

import { DebitNoteDetails } from "./customComponents/debitNotesDetails";

interface DetailsDebitNoteProps {
    debitNoteId: string;
}

export const DetailsDebitNote: React.FC = () => {
    const { debitNoteId } = useParams<DetailsDebitNoteProps>();

    return (
       <DebitNoteDetails debitNoteId={Number(debitNoteId)} />
    );
};
