import React from "react";
import { useFormik } from "formik";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import CustomerService from "../services/customerService";
import { CurrencyInput } from "./CurrencyInput";
import { getNumberSpecs } from "../i18n";

interface IAddCreditsCustomerProps {
  modal: { open: boolean; setOpen(value: boolean): void };
  customerId: number;
}

export const AddCreditsCustomer: React.FC<IAddCreditsCustomerProps> = ({
  modal,
  customerId,
}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: { description: "", value: "" },
    onSubmit: async (values) => {
      try {
        await CustomerService.addCredits(
          customerId,
          values.description,
          Number(values.value.replace(".", "").replace(",", "."))
        );
        handleClose();
        NotificationManager.success(
          "Crédito inserido com sucesso!",
          "Lançamento de crédito"
        );
      } catch (error) {
        const { status, data } = error.response;
        if (status === 400) {
          for (var key in data) {
            var value = data[key];
            formik.setFieldError(key, value);
          }
        } else {
          NotificationManager.error(
            "Não foi possível inserir o crédito!",
            "Lançamento de crédito"
          );
        }
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    modal.setOpen(false);
  };

  return (
    <Dialog
      open={modal.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <form onSubmit={formik.handleSubmit} className={classes.root}>
        <DialogTitle id="form-dialog-title">{"Inserir Crédito"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="description"
                label="Descrição"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.description}
                error={!!formik.errors.description}
                helperText={formik.errors.description}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="value"
                label="Valor"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.value}
                error={!!formik.errors.value}
                helperText={formik.errors.value}
                fullWidth
                InputProps={{
                  inputComponent: CurrencyInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      {getNumberSpecs().currency.symbol}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 10
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? (
              <CircularProgress size="1.5rem" color="inherit" />
            ) : (
              <span>Inserir</span>
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
);

export default AddCreditsCustomer;
