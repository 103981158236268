import React, { ReactNode } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import theme from "../../../styles/theme";
import { amber, pink } from "@material-ui/core/colors";
import { differenceInMinutes, isPast } from "date-fns";
import { CustomAvatar } from "../../../components/CustomAvatar";
import { TripDeliveryRequest, TripStatus, PendingTrip } from "../../../services/types/deliveryRequest";
import ShowIf from "../../../components/ShowIf";
import { TripCardItem } from "./tripCardItem";
import HighlightText from "../../../components/HighlightText";
import { MoreVert } from "@material-ui/icons";
//@ts-ignore
import { SpeedyRankingType } from "../../../services/types/courier";
import clsx from "clsx";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import PopoverRanking from "./popoverRanking";

interface ITripCardProps {
  trip: PendingTrip;
  highlight: string;
  onClick?: (trip: PendingTrip) => {};
  splitTripAction?: (trip: PendingTrip, delivery: TripDeliveryRequest) => void;
  menuActions?: TripCardMenuAction[];
  showDeliveryStatus?: boolean;
  showTripStatus?: boolean;
  children?: ReactNode;
}

export interface TripCardMenuAction {
  title: string;
  onClick: (trip: PendingTrip) => void;
  available?: boolean;
}

export const TripCard: React.FC<ITripCardProps> = ({
  trip,
  highlight,
  onClick,
  splitTripAction,
  menuActions = [],
  showDeliveryStatus = true,
  showTripStatus = true,
  children
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rankingAnchorEl, setRankingAnchorEl] = React.useState<HTMLElement | null>(null);
  const [loadingBtn, setLoadingBtn] = React.useState<boolean>(false);
  const openRankingPopover = Boolean(rankingAnchorEl);

  const handlePopoverRankingOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setRankingAnchorEl(event.currentTarget);
  };

  const handlePopoverRankingClose = () => {
    setRankingAnchorEl(null);
  };

  const determineCardClassName = (): string => {
    let className = "";

    /*
      If delayed should show delayed even if "status === NO_COURIER"
    */
    for (let i = 0; i < trip.deliveries.length; i++) {
      if (isPast(new Date(trip.deliveries[i].estimated_delivery_time))) {
        className = classes.cardDelayed;
        break;
      }
    }

    return className;
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setMenuAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
    setTimeout(() => setLoadingBtn(false), 200);
  };

  const determineAvatarText = (): string | null => {
    let result: string | null = null;

    if ((trip.status === TripStatus.NO_COURIER) && trip.requested_time) {
      const minutes = differenceInMinutes(Date.now(), trip.requested_time);
      result = minutes + " min";
    }
    return result;
  };

  return (
    <React.Fragment>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={event => {
          handleCloseMenu();
        }}
      >
        {menuActions.filter((action) => action.available).map((action) => (
          <MenuItem
            onMouseDown={event => event.stopPropagation()}
            disabled={loadingBtn}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              action.onClick(trip);
              handleCloseMenu();
            }}
          >
            {action.title}
          </MenuItem>
        ))}
      </Menu>
      <PopoverRanking
        openPopover={openRankingPopover}
        anchorEl={rankingAnchorEl}
        speedyRanking={trip?.courier_speedy_ranking}
        newRankingPoints={trip?.courier_new_ranking_points}
        requestsCount={trip?.courier_requests_count}
        handlePopoverClose={handlePopoverRankingClose}
      />

      <Box mb={1}>
        <Card variant="outlined">
          <CardContent className={classes.content}>
            <CardActionArea
              className={classes.actionArea}
              onClick={() => {
                if (onClick) {
                  onClick(trip);
                }
              }}>
              <CardHeader
                style={{ padding: 0 }}
                avatar={
                  <CustomAvatar
                    size="md"
                    text={determineAvatarText()}
                    className={clsx(classes.avatarNoCourier, {
                      [classes.callingCourierPhoto]: ((trip.status === TripStatus.SEARCHING_COURIER)),
                    })}
                    img={trip.courier_photo || ""} />
                }
                action={
                  <ShowIf condition={menuActions.filter((action) => action.available).length > 0}>
                    <IconButton
                      color="primary"
                      onMouseDown={event => event.stopPropagation()}
                      onClick={handleOpenMenu}>
                      <MoreVert />
                    </IconButton>
                  </ShowIf>
                }
                title={
                  <React.Fragment>
                    <Grid container xs={12} spacing={1} alignItems="center">
                      {showTripStatus && (
                        <Grid item>
                          <Chip
                            className={clsx(classes.statusChip, {
                              [classes.blink]: ((trip.status === TripStatus.SEARCHING_COURIER))
                            })}
                            size="small"
                            label={
                              ((trip.status === TripStatus.NO_COURIER) && (trip.courier_id)) ?
                                "Chamando Entregador"
                                : t('trip_status.' + trip.status)
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                      <HighlightText text={`<b>${trip.store.name}</b> - ${trip.region.name}`} words={[highlight]} />
                    </Typography>
                  </React.Fragment>
                }
                subheader={
                  <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                    {trip.courier_id && (
                      <Typography style={{ marginRight: "3px" }} aria-owns={openRankingPopover ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverRankingOpen}
                        onMouseLeave={handlePopoverRankingClose}>
                        <ShowIf condition={trip.courier_speedy_ranking === SpeedyRankingType.INITIATE_SPEEDER}>
                          <StarBorderIcon fontSize="small" style={{ paddingTop: "1px" }} />
                        </ShowIf>
                        <ShowIf condition={trip.courier_speedy_ranking === SpeedyRankingType.ADVANCED_SPEEDER}>
                          <StarIcon fontSize="small" className={clsx(classes.advancedSpeederStar)} />
                        </ShowIf>
                      </Typography>
                    )}
                    {trip.courier_name && (
                      <HighlightText text={trip.courier_name ? trip.courier_name : "Sem entregador"} words={[highlight]} />
                    )}
                    {(!trip.courier_name && trip.called_ifood) && (
                      "Chamado Entregador do iFood"
                    )}
                  </Typography>
                }
              >
              </CardHeader>

              {trip.deliveries.map((deliveryRequest: TripDeliveryRequest, index: number) => (
                <React.Fragment key={index}>
                  <Divider></Divider>
                  <TripCardItem
                    trip={trip}
                    deliveryRequest={deliveryRequest}
                    highlight={highlight}
                    action={onClick}
                    splitTripAction={splitTripAction}
                    showDeliveryStatus={showDeliveryStatus}
                  />
                </React.Fragment>
              ))}
            </CardActionArea>
          </CardContent>
          {children}
        </Card>
      </Box>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  order: {
    fontSize: "15px"
  },
  cardDelayed: {
    borderTopColor: pink.A400,
    borderTopWidth: theme.shape.borderRadius * 1.5,
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  actionArea: {
    padding: theme.spacing(1),
  },
  statusChip: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#384953 !important',
    color: theme.palette.secondary.contrastText,
  },
  advancedSpeederStar: {
    color: theme.palette.primary.main
  },
  blink: {
    animation: "blinker 2s linear infinite",
  },
  callingCourierPhoto: {
    filter: "grayscale(100%)",
  },
  cardNoCourier: {
    borderLeftColor: amber[700],
    borderLeftWidth: theme.shape.borderRadius * 1.5
  },
  avatarNoCourier: {
    backgroundColor: amber[700],
    textAlign: "center",
    borderRadius: "50%"
  }
});
