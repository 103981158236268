import React from "react";
import {
  Grid,
  makeStyles,
} from "@material-ui/core";
import { useFormik } from "formik";
import { RegionResponse } from "../../../services/types/region";
import SearchInput from "../../../components/SearchInput";
import DashboardSelectMenu from "../customComponents/dashboardSelectMenu";

export interface DashboardMapFilterParams {
  regionId: number | null;
}

export const MAP_INITIAL_FILTER_PARAMS: DashboardMapFilterParams = {
  regionId: null,
};

interface DashboardMapFilterProps {
  params?: DashboardMapFilterParams;
  regionList: RegionResponse[];
  onSearchValueChanged: (value: string) => void;
  onFilterChanged: (params: DashboardMapFilterParams) => void;
}

export const DashboardMapFilter: React.FC<DashboardMapFilterProps> = ({
  params = MAP_INITIAL_FILTER_PARAMS,
  regionList = [],
  onSearchValueChanged,
  onFilterChanged,
}) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = React.useState<string>("");

  React.useEffect(() => {
    formik.setValues(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const formik = useFormik({
    initialValues: params,
    onSubmit: (values) => {
      onFilterChanged({ ...values });
    },
    onReset: (values) => {
      onFilterChanged({ ...values });
    },
  });

  return (
    <Grid container spacing={2}>
      <Grid item md={3}>
        <SearchInput
          id="searchValue"
          placeholder="Busca Rápida"
          value={searchValue}
          fullWidth
          onChange={(e) => {
            setSearchValue(e.currentTarget.value);
            onSearchValueChanged(e.currentTarget.value);
          }}
        />
      </Grid>
      <Grid item md>
        <DashboardSelectMenu
          placeholder="Selecione uma Região"
          triggerType="chip"
          triggerClassName={classes.chip}
          itens={regionList.map(region => {
            return { value: region.id, label: region.name }
          })}
          selectedValue={params.regionId || ""}
          onSelect={(value) => {
            formik.setFieldValue("regionId", value);
            formik.submitForm();
          }}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  chip: {
    marginTop: "4px",
  },
});
