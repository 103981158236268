import { combineReducers } from "redux";
import appReducer from "./ducks/app";
import userReducer from "./ducks/user";
import authReducer from "./ducks/auth";
import customerReducer from "./ducks/customer";
import settingsReducer from "./ducks/settings";
import requestsReducer from "./ducks/requests";

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  auth: authReducer,
  customer: customerReducer,
  settings: settingsReducer,
  requests: requestsReducer,
});

export default rootReducer;
