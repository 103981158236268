import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Routes from "./utils/Routes";
import { AppContext } from "./utils/appContext";
//@ts-ignore
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { configExpiredAccessTokenInterceptor } from "./store/api/config";
import { Actions as authActions } from "./store/ducks/auth";

interface IAppProps {}

export const App: React.FC<IAppProps> = () => {
  const dispatch = useDispatch();
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const disconnect = () => authActions.disconnect(dispatch);
  const history = useHistory();

  const onLoad = async () => {
    const accessToken = await localStorage.getItem("@speedy:access");
    const expToken = await localStorage.getItem("@speedy:exp");
    const tokenExpired = Date.now().toString().slice(0,10) >= (expToken || "0");
    userHasAuthenticated(!!accessToken && !tokenExpired);
  };

  const handleDisconnect = () => {
    userHasAuthenticated(false);
    disconnect();
    history.push("/");
  }

  if (isAuthenticated) {
    configExpiredAccessTokenInterceptor(handleDisconnect);
  }

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
      <NotificationContainer />
      <Routes />
    </AppContext.Provider>
  );
};
