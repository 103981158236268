import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { capitalize } from "../../utils/capitalize";
import CommercialPlanService from "../../services/commercialPlanService";
import RegionService from "../../services/regionService";
import { DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { VisibilityOutlined } from "@material-ui/icons";
import { CommercialPlanResponse } from "../../services/types/commercialPlan";
import { ICommercialPlanFilterParams, CommercialPlanFilter, INITIAL_FILTER_PARAMS } from "./commercialPlanFilter";
import ShowIf from "../../components/ShowIf";
import AuthService from "../../services/authService";
import { decodeURLParams } from "../../utils/utils";

export const CommercialPlan = () => {
  const history = useHistory();
  const [regionList, setRegionList] = useState<any[]>([]);
  const [filterParams, setFilterParams] = useState<ICommercialPlanFilterParams>({
    ...INITIAL_FILTER_PARAMS,
    ...decodeURLParams(history.location.search)
  });

  const tableRef = React.useRef<MaterialTableProps<CommercialPlanResponse>>();

  const loadData = (query: Query<CommercialPlanResponse>) => {
    return new Promise<QueryResult<CommercialPlanResponse>>((resolve, reject) => {
      CommercialPlanService.getComercialPlans(
        filterParams.name,
        (filterParams.region ? [Number(filterParams.region)] : []),
        PageableParams.fromQuery(query))
        .then(response => {
          resolve({
            data: response.data,
            page: query.page,
            totalCount: response.count,
          })
        })
    });
  }

  const applyFilter = (params: ICommercialPlanFilterParams) => {
    setFilterParams(params);
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({ page: 0 } as Query<CommercialPlanResponse>);
    }
  }

  useEffect(() => {
    const getRegions = async () => {
      const response = await RegionService.loadAllRegions({ "ordering": "name" });
      setRegionList(response);
    }

    getRegions();
  }, []);

  const showDetails = async (item: CommercialPlanResponse) => {
    history.push(`/commercialPlan/detail/${item.id}`);
  };

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Planos Comerciais</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[{ label: "Planos Comerciais", url: "/commercialPlan" }]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <ShowIf condition={AuthService.hasAuthorization("add_commercialplan")}>
            <Button color="primary" variant="contained"
              startIcon={<AddCircleOutlineRoundedIcon />}
              component={Link} to="/commercialPlan/new">
              Novo Plano Comercial
            </Button>
          </ShowIf>
        </Grid>
      </Grid>

      <CommercialPlanFilter params={filterParams} regionList={regionList} onFilterChanged={applyFilter} />

      <MaterialTable
        tableRef={tableRef}
        columns={[
          { field: "id", hidden: true, width: 60 },
          { field: "name", title: "Nome", defaultSort: "asc" },
          {
            field: "region_name", title: "Região",
            render: (rowData, type) => rowData.region ? `${capitalize(rowData.region.name)} - ${rowData.region.state}` : "---"
          },
        ]}
        data={loadData}
        actions={[
          {
            icon: () => <VisibilityOutlined />,
            tooltip: 'Ver Detalhes',
            onClick: (event, rowData) => showDetails(rowData as CommercialPlanResponse)
          }
        ]}
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_L10N_PTBR}
        options={{
          ...DEFAULT_TABLE_OPTIONS,
          draggable: false
        }}
      />
    </div>
  );
};