import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { CustomAvatar } from "../../../components/CustomAvatar";
import { useTranslation } from 'react-i18next';
import Alert from "@material-ui/lab/Alert";
import { mapCourierAccountStatusToSeverity } from "../courierUtils";
import { CourierDetails } from "../../../services/types/courier";

interface ICourierDetailCardProps {
  courier?: CourierDetails;
}

export const CourierDetailCard: React.FC<ICourierDetailCardProps> = ({
  courier,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          spacing={1}
        >
          {courier !== undefined && (
            <React.Fragment>
              <Grid item>
                <CustomAvatar img={courier.photo} size="lg" />
              </Grid>
              <Grid item>
                <Typography variant="h1">{courier.name}</Typography>
              </Grid>
              <Grid item>
                <Alert severity={mapCourierAccountStatusToSeverity(courier.courier_account_status)} icon={false}>
                  {t('courier_account_status.' + courier.courier_account_status)}
                </Alert>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
