import { Color } from "@material-ui/lab/Alert";
import { UserStatus } from "../../services/types/user";

export const mapUserStatusToSeverity = (user_status: UserStatus): Color => {
    switch (user_status) {
        case UserStatus.ACTIVE:
            return "success";
        case UserStatus.BLOCKED:
            return "error";
        default:
            return "warning";
    }
};
