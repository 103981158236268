import { SpeedyRankingType, VehicleLoadCompartmentType, VehicleType } from "./courier";
import { RegionResponse } from "./region";

export enum DeliveryRequestStatus {
    NEW = "NEW",
    NO_COURIER = "NO_COURIER",
    SEARCHING_COURIER = "SEARCHING_COURIER",
    ACCEPTED = "ACCEPTED",
    AWAITING_RELEASE = "AWAITING_RELEASE",
    AWAITING_COLLECTION = "AWAITING_COLLECTION",
    ON_WAY = "ON_WAY",
    AT_DESTINY = "AT_DESTINY",
    DELIVERED = "DELIVERED",
    RETURNING = "RETURNING",
    BACK_TO_STORE = "BACK_TO_STORE",
    FINISHED = "FINISHED",
    CANCELED = "CANCELED",
    DISCARDED = "DISCARDED"
}

export enum TripStatus{
    NEW = "NEW",
    NO_COURIER = "NO_COURIER",
    SEARCHING_COURIER = "SEARCHING_COURIER",
    ACCEPTED = "ACCEPTED",
    AWAITING_RELEASE= "AWAITING_RELEASE",
    AWAITING_COLLECTION = "AWAITING_COLLECTION",
    ON_WAY = "ON_WAY",
    BACK_TO_STORE = "BACK_TO_STORE",
    FINISHED = "FINISHED",
    CANCELED = "CANCELED",
    PARTIALLY_CANCELED = "PARTIALLY_CANCELED",  
    RETURNING = "RETURNING",
    DISCARDED = "DISCARDED"
}

export enum DeliveryRequestEvent {
    CREATED = "CREATED",
    COURIER_SELECTED = "COURIER_SELECTED",
    COURIER_IGNORED_REQUEST = "COURIER_IGNORED_REQUEST",
    COURIER_REFUSED_REQUEST = "COURIER_REFUSED_REQUEST",
    COURIER_ACCEPTED_REQUEST = "COURIER_ACCEPTED_REQUEST",
    COURIER_CHECK_IN = "COURIER_CHECK_IN",
    STORE_RELEASED_ORDER = "STORE_RELEASED_ORDER",
    COURIER_DELIVERED_ORDER = "COURIER_DELIVERED_ORDER",
    COURIER_CHECK_OUT = "COURIER_CHECK_OUT",
    COURIER_ARRIVED_DESTINATION = "COURIER_ARRIVED_DESTINATION",
    CUSTOMER_CANCELED_ORDER = "CUSTOMER_CANCELED_ORDER",
    COURIER_CHECK_IN_FINISH = "COURIER_CHECK_IN_FINISH",
    DELIVERY_FINISHED = "DELIVERY_FINISHED",
    COURIER_IGNORED_NOTIFICATION = "COURIER_IGNORED_NOTIFICATION",
    ORDER_CANCEL_COMPLETED = "ORDER_CANCEL_COMPLETED"
}

export enum TripRequestEvent {
    CREATED = "CREATED",
    START_SEARCHING_COURIER = "START_SEARCHING_COURIER",
    COURIER_SELECTED = "COURIER_SELECTED",
    COURIER_IGNORED_TRIP = "COURIER_IGNORED_TRIP",
    COURIER_REFUSED_TRIP = "COURIER_REFUSED_TRIP",
    COURIER_ACCEPTED_TRIP = "COURIER_ACCEPTED_TRIP",
    COURIER_CHECK_IN_TRIP = "COURIER_CHECK_IN_TRIP",
    COURIER_CHECK_OUT_TRIP = "COURIER_CHECK_OUT_TRIP",
    CUSTOMER_CANCELED_TRIP = "CUSTOMER_CANCELED_TRIP",
    COURIER_CHECK_IN_FINISH_TRIP = "COURIER_CHECK_IN_FINISH_TRIP",
    TRIP_FINISHED = "TRIP_FINISHED",
    COURIER_IGNORED_NOTIFICATION = "COURIER_IGNORED_NOTIFICATION",
    TRIP_CANCEL_COMPLETED = "TRIP_CANCEL_COMPLETED",
    TRIP_REFUND_COMPLETED = "TRIP_REFUND_COMPLETED",
    ACK_NOTIFICATION = "ACK_NOTIFICATION",
    RESEND_TRIP = "RESEND_TRIP",
    STORE_RELEASED_ORDERS = "STORE_RELEASED_ORDERS",
    TRIP_RESETED = "TRIP_RESETED",
    TRIP_DISCARDED = "TRIP_DISCARDED"
}

export enum DeliveryRequestAction {
    CHECK_IN = "CHECK_IN",
    RELEASE_ORDER = "RELEASE_ORDER",
    CHECK_OUT = "CHECK_OUT",
    REGISTER_ARRIVAL = "REGISTER_ARRIVAL",
    REGISTER_DELIVER = "REGISTER_DELIVER",
    REGISTER_CHECK_IN_BACK = "REGISTER_CHECK_IN_BACK",
    FINISHED = "FINISHED",
    CANCELED = "CANCELED",
}

export enum DeliveryRequestReturnType {
    NONE = "none",
    POS = "pos",
    MONEY = "din",
}

export interface FinishedDeliveryRequest {
    order_number: string;
    order_date: Date;
    consignee: string;
    consignee_phone_number: string;
    return_type: DeliveryRequestReturnType;
    postal_code: string;
    street: string;
    address_number: string;
    address_complement?: string;
    district: string;
    city: string;
    state: string;
    latitude: any;
    longitude: any;
    store: string | number;
    current_courier: string | number;
    addressType: string;
}

export interface DeliveryRequestLog {
    id: number;
    courier: number | null;
    event: string;
    reason: string;
    manual_action: boolean;
    created_at: Date;
    is_courier_action: boolean;
    made_by: number;
    delivery_request: DeliveryRequestLog;
}

export interface DeliveryRequestLog {
    id: number;
    order_number: string;
    request_number: string;
}

export interface TripCourier {
    id: number;
    name: string;
    phonenumber: string;
    photo: string;
    vehicle_type: VehicleType;
    vehicle_load_compartment_type: VehicleLoadCompartmentType;
    new_ranking_points: number;
    speedy_ranking: SpeedyRankingType;
    requests_count: number;
    app_version: string;
}

interface DeliveryRequestStore {
    customer_id: number;
    customer_name: string;
    store_id: number;
    store_name: string;
    phonenumber: string;
    region: Region;
}

export interface Address {
    postal_code: string;
    street: string;
    address_number: string;
    address_complement: string;
    delivery_observations: string;
    district: string;
    city: string;
    state: string;
    latitude: number;
    longitude: number;
}

export interface Incentive {
    customer_value: number;
    speedy_value: number;
    total: number;
}

export interface CourierPosition {
    courier_id: number;
    latitude: number;
    longitude: number;
    delivery_request_id: number;
    delivery_requests: any;
    trip_id: number;
    updated_at: Date;
}

export interface DeliveryRequestMetadata {
    available: boolean;
}

export interface DeliveryRequest {
    id: number;
    created_at: Date;
    requested_time: Date;
    request_number: string;
    order_number: string;
    status: DeliveryRequestStatus;
    return_type: DeliveryRequestReturnType;
    distance: number;
    compartment_type: VehicleLoadCompartmentType;
    delivery_value: number;
    minimum_value: number;
    additional_km_value: number;
    return_value: number;
    speedy_value: number;
    courier_value: number;
    courier_cancel_value: number;
    origin_address: Address;
    destination_address: Address;
    courier_position: CourierPosition;
    consignee: string;
    consignee_phone_number: string;
    current_courier: TripCourier;
    customer: DeliveryRequestStore;
    acceptation_distance: number;
    time_to_arrive_store: number;
    estimated_delivery_time: Date;
    estimated_delivery_time_after_release: Date;
    manual_creation: boolean;
    route: any;
    refunded: boolean;
    incentive_json: Incentive;
    integration_instance: IntegrationInstance;
    external_id: string;
    is_scheduled: boolean;
    canceled: boolean;
    trip_id: number;
    trip_number: string;
    called_ifood: boolean;
    metadata: DeliveryRequestMetadata;
    canceled_on_insurance_service: boolean;
    registered_on_insurance_service: boolean;
    closed_on_insurance_service: boolean;
    value_total: number;
}

export interface DeliveryRequestResponse {
    id: number,
    request_number: string,
    order_number: string,
    courier_name: string,
    store_name: string,
    created_at: Date,
    requested_time: Date,
    status: DeliveryRequestStatus,
    manual_creation: boolean
}

export interface PendingDeliveryRequest {
    id: number;
    request_number: string;
    status: DeliveryRequestStatus;
    created_at: Date;
    requested_time: Date;
    order_number: string;
    customer_id: number;
    customer_name: string;
    store: Store;
    courier_id: number;
    courier_name: string;
    courier_phonenumber: string;
    courier_photo: string;
    estimated_delivery_time: Date;
    estimated_delivery_time_after_release: Date;
    destination_address: Address;
    distance: number;
    integration_instance: IntegrationInstance;
    source: IntegrationSource;
    region: RegionResponse;
    courier_new_ranking_points: number;
    courier_requests_count: number;
    courier_speedy_ranking: SpeedyRankingType;
    is_scheduled: boolean;
    called_ifood: boolean;
    return_type: string;
}

export interface PendingTrip {
    id: number;
    deliveries: TripDeliveryRequest[];
    courier_id: number;
    courier_name: string;
    courier_phonenumber: string;
    courier_photo: string;
    created_at: Date;
    updated_at: Date;
    number: string;
    start_date: Date;
    end_date: Date;
    status: TripStatus;
    store: Store;
    distance: number;
    region: RegionResponse;
    requested_time: Date;
    courier_new_ranking_points: number;
    courier_requests_count: number;
    courier_speedy_ranking: SpeedyRankingType;
    called_ifood: boolean;
}

export interface TripDeliveryRequest {
    id: number;
    request_number: string;
    order_number: string;
    created_at: Date;
    requested_time: Date;
    is_scheduled: boolean;
    estimated_delivery_time: Date;
    estimated_delivery_time_after_release: Date;
    status: DeliveryRequestStatus;
    destination_address: Address;
    source: IntegrationSource;
    integration_instance: IntegrationInstance;
    distance: number;
    current_courier: TripCourier;
    customer: DeliveryRequestStore;
    return_type: string;
    called_ifood: boolean;
    addition_request_is_ringing: boolean;
}

export interface Trip {
    id: number;
    created_at: Date;
    updated_at: Date;
    number: string;
    start_date: Date;
    end_date: Date;
    status: TripStatus;
    store: Store;
    has_return: boolean;
    courier: TripCourier;
    deliveries: TripDeliveryRequest[];
    region: RegionResponse;
    courier_value: number;
    total_value: number;
    called_ifood: boolean;
    customer_incentive_value: number;
    courier_incentive_value: number;
    canceled: boolean;
    courier_position: CourierPosition;
}

export enum IntegrationSource {
    SPEEDY = "SPEEDY",
    IFOOD = "IFOOD",
    MEU_XODO = "MEU_XODO",
    DELIVERY_DIRETO = "DELIVERY_DIRETO",
    NEEMO = "NEEMO"
}

export interface IntegrationInstance {
    id: number;
    name: string;
    integration_source: IntegrationSource;
    initials: string;
}

export interface TripTrackingPoint {
    created_at: Date;
    latitude: number;
    longitude: number;
}

export interface Store {
    id: number;
    latitude: number;
    longitude: number;
    name: string;
}

export interface Region {
    id: number;
    name: string;
}

export interface DeliveryOccurencesResponse {
    id: number,
    close_date: Date | null,
    created_at: Date,
    order_number: string,
    request_number: string,
    requested_time: Date,
    status: DeliveryRequestStatus,
    store: Store | null,
    trip: Trip | null
}

export interface StatsDashboard {
    canceled: number,
    finished: number,
    region: number,
    region_name: string,
    total: number,
}

export enum OriginStatus{
    DELIVERY = "DELIVERY",
    INCENTIVE = "INCENTIVE",
    DISCOUNT = "DISCOUNT",
    DEDICATED_FEE = "DEDICATED_FEE",
    OTHER = "OTHER",
    TRANSFER = "TRANSFER",
    ANTICIPATION = "ANTICIPATION",
    REWARD = "REWARD"
}

export interface ValuesPerRegionsResponse {
    per_regions: RegionValues[],
    total: number,
}

export interface RegionValues {
    id: number,
    name: string,
    value: number,
}

export interface RegionDeliveries {
    id: number,
    name: string,
    quantity: number,
}

export enum TypeFilter{
    PASSEDED_ON = "PASSEDED_ON",
    EARNINGS = "EARNINGS",
    QUANTITY_DELIVERIES = "QUANTITY_DELIVERIES",
    NUMBER_DELIVERIES = "NUMBER_DELIVERIES",
    OCCURRENCES = "OCCURRENCES",
}

export interface DeliveriesPerRegionPerDay {
    region_id: number,
    region_name: string,
    dates: DeliveriesPerDay[]
}

export interface DeliveriesPerDay {
    date: Date,
    quantity: number
}

export interface DeliverySettingsResponse {
    queue_retries: number,
    retry_notification_seconds: number,
    acceptation_seconds: number,
    extra_acceptation_time: number, 
    minutes_to_discard: number,
    advanced_speeder_points: number
    updated_at: Date
}