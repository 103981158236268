import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
    DATE_COLUMN_DEFAULTS,
    DEFAULT_TABLE_COMPONENTS,
    DEFAULT_TABLE_OPTIONS,
    PageableParams,
    TABLE_L10N_PTBR,
} from "../../../components/TableTrinkets";
import MaterialTable, {
    MaterialTableProps,
    Query,
    QueryResult,
} from "@material-table/core";
import { VisibilityOutlined } from "@material-ui/icons";
import DebitNoteService from "../../../services/debitNotesService";
import { DebitNotesResponse } from "../../../services/types/debitNotes";
import { decodeURLParams, URLParamType } from "../../../utils/utils";
import { DebitNoteFilter, IDebitNoteFilterParams, INITIAL_FILTER_PARAMS } from "../../debitNotes/debitNoteFilter";
import DebitNotesDetailsDialog from "../../debitNotes/customComponents/debitNotesDetailsDialog";

interface ICustomerDebitNotes {
    customerId: number;
}

export const CustomerDebitNotes: React.FC<ICustomerDebitNotes> = ({ customerId }) => {
    const history = useHistory();
    const [openDetails, setOpenDetails] = React.useState<boolean>(false);
    const [debitNoteId, setDebitNoteId] = React.useState<number>(0);

    const [filterParams, setFilterParams] = useState<IDebitNoteFilterParams>({
        ...INITIAL_FILTER_PARAMS,
        ...decodeURLParams(history.location.search, [
            { "date_after": URLParamType.DATE },
            { "date_before": URLParamType.DATE }
        ])
    });
    
    const tableRef = React.useRef<MaterialTableProps<DebitNotesResponse>>();

    const loadData = (query: Query<DebitNotesResponse>) =>
        new Promise<QueryResult<DebitNotesResponse>>((resolve, reject) => {
            DebitNoteService.getDebitNotes(customerId,
                filterParams.date_after, filterParams.date_after, PageableParams.fromQuery(query))
                .then(response => {
                    resolve({
                        data: response.data,
                        page: query.page,
                        totalCount: response.count,
                    });
                });
        });

    const applyFilter = (params: IDebitNoteFilterParams) => {
        setFilterParams(params);
        if (tableRef.current?.onQueryChange) {
            tableRef.current.onQueryChange({
                page: 0,
            } as Query<DebitNotesResponse>);
        }
    };
    
    const refresh = () => {
        setOpenDetails(false);
        setDebitNoteId(0);
    }

    const showDetails = (item: DebitNotesResponse) => {
        setDebitNoteId(item.id);
    };

    React.useEffect(() => {
        if(debitNoteId !== 0){
            setOpenDetails(true);
        }      
    }, [debitNoteId]);

    return (
        <div>
            <DebitNoteFilter params={filterParams} customerId={customerId} onFilterChanged={applyFilter} />

            <DebitNotesDetailsDialog modal={{ open: openDetails, setOpen: setOpenDetails }} debitNoteId={debitNoteId} refresh={refresh} /> 

            <MaterialTable
                tableRef={tableRef}
                columns={[
                    { field: "id", hidden: true },
                    {
                        ...DATE_COLUMN_DEFAULTS,
                        emptyValue: "---",
                        field: "reference_date",
                        title: "Data Referência",
                    },
                    {
                        field: "customer.corporate_name",
                        title: "Cliente",
                    },
                    {
                        field: "customer.trade_name",
                        title: "Nome Fantasia",
                    },
                ]}
                data={loadData}
                actions={[
                    {
                        icon: () => <VisibilityOutlined />,
                        tooltip: "Ver Detalhes",
                        onClick: (event, rowData) =>
                            showDetails(rowData as DebitNotesResponse),
                    },
                ]}
                components={DEFAULT_TABLE_COMPONENTS}
                localization={TABLE_L10N_PTBR}
                options={DEFAULT_TABLE_OPTIONS}
            />
        </div>
    );
};
