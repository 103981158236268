import React, { useEffect, useState, useCallback, FormEvent, useMemo } from "react";
import { Grid, Box, TextField, MenuItem, Button, Typography, debounce } from "@material-ui/core";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";

import Alert, { Color } from "@material-ui/lab/Alert";

import UserModal from "./UserModal";
import {
  EMPTY_CUSTOMER_USER,
  ICustomerUser,
} from "../../../store/types/customer";
import { useTranslation } from "react-i18next";
import MaterialTable from "@material-table/core";
import { DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";
import { Check, EditOutlined, VpnKey } from "@material-ui/icons";
import { formatPhoneNumber } from "../../../utils/formatters";
import ShowIf from "../../../components/ShowIf";
import AuthService from "../../../services/authService";
import { CustomerUsersResponse, StoreResponse } from "../../../services/types/customer";
import CustomerService from "../../../services/customerService";
import { IPageableParams } from "../../../services/types/common";
import ChangePasswordDialog, { ChangePasswordDialogHandle } from "../../../components/ChangePasswordDialog";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../../components/ConfirmationDialog";
import Block from "@material-ui/icons/Block";

interface IFilter {
  getUsers(filter: any): void;
  setFilter(filter: any): void;
  storeList: StoreResponse[];
  onFilterChanged(filter: ICustomerUserFilterParams): void;
}

interface IProps {
  customerId: number;
}

export interface ICustomerUserFilterParams {
  name: string | null;
  store: number | null;
}

export const INITIAL_FILTER_PARAMS: ICustomerUserFilterParams = {
  name: null,
  store: null,
};

const CustomerUsersFilters: React.FC<IFilter> = ({
  getUsers,
  setFilter,
  storeList,
  onFilterChanged
}) => {
  const [fields, setFields] = useState({
    name: "",
    store: 0,
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setFilter({ ...fields, store: fields.store === 0 ? "" : fields.store });
    const params: ICustomerUserFilterParams = {
      name: fields.name,
      store: fields.store,
    };
    onFilterChanged(params);
  };
  const handleClear = () => {
    setFields({ name: "", store: 0 });
  };

  const handleChange = (field: string, value: any) => {
    setFields((prevState) => {
      return { ...prevState, [field]: value };
    });
  };

  return (
    <div className="page-filter" style={{ minWidth: "32vw" }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <TextField
              label="Nome"
              name="name"
              onChange={(e) => handleChange("name", e.target.value)}
              value={fields.name}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item md={6}>
            <TextField
              label="Loja"
              name="store"
              select
              value={fields.store}
              onChange={(e) => handleChange("store", Number(e.target.value))}
              variant="outlined"
              fullWidth
            >
              <MenuItem value={0}>Todas</MenuItem>
              {storeList?.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleSubmit}
            >
              Pesquisar
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleClear}
            >
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export const CustomerUsers: React.FC<IProps> = ({ customerId }) => {
  const [storeList, setStoreList] = useState<StoreResponse[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [user, setUser] = useState<ICustomerUser>();
  const changePasswordDialog = React.createRef<ChangePasswordDialogHandle>();
  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
  const [customerUsers, setCustomerUsers] = React.useState<CustomerUsersResponse[]>([]);
  const [filter, setFilter] = React.useState(INITIAL_FILTER_PARAMS);

  const { t } = useTranslation();

  const getUsers = useCallback(async () => {
    try {
      const response = await CustomerService.getUsers({
        ...filter,
        customer: Number(customerId),
      });
      setCustomerUsers(response.results);
    } catch (error) {
    } finally {
    }
  }, [filter, customerId]);

  const loadStores = useCallback(() => {
    CustomerService.getStores(undefined, Number(customerId), undefined, undefined,
      ({ page: 1, page_size: 100 } as IPageableParams))
      .then((response) => {
        setStoreList(response.data);
      }).catch((error) => { });
  }, [customerId]);

  const mapCustomerUserStatus = (userStatus: string | undefined): Color => {
    switch (userStatus) {
      case "ACTIVE":
        return "success";
      case "BLOCKED":
        return "error";
      default:
        return "warning";
    }
  };

  useEffect(() => {
    getUsers();
    loadStores();
  }, [getUsers, loadStores]);

  const handleAddUser = (customerUser: ICustomerUser) => {
    const newUser = { ...customerUser, customer: customerId };
    CustomerService.createCustomerUser(newUser)
      .then((response) =>{
        NotificationManager.success("Usuário cadastrado com sucesso");
        getUsers();
        setModalOpen(false);
      }).catch((error) => { 
        let errorMsg = "Ocorreu um erro ao cadastrar o usuário para este cliente.";        
        if (error) {
            const errorItems = error.response.data;
            const errorMsgs: string[] = [];
            for (var key in errorItems) {
                var value = errorItems[key];
                errorMsgs.push(value);
            }
            if (errorMsgs.length > 0) {
                errorMsg = errorMsgs.join(". \n");
            }
        }
        NotificationManager.error(
            errorMsg,
            "Cadastrar Usuário"
        );
    });
  };

  const handleUpdateUser = (customerUser: ICustomerUser) => {
    CustomerService.updateCustomerUser(customerUser)
    .then((response) =>{
      NotificationManager.success("Usuário atualizado com sucesso");
      getUsers();
      setModalOpen(false);
    }).catch((error) => { 
      let errorMsg = "Ocorreu um erro ao atualizar o usuário para este cliente.";        
        if (error) {
            const errorItems = error.response.data;
            const errorMsgs: string[] = [];
            for (var key in errorItems) {
                var value = errorItems[key];
                errorMsgs.push(value);
            }
            if (errorMsgs.length > 0) {
                errorMsg = errorMsgs.join(". \n");
            }
        }
        NotificationManager.error(
            errorMsg,
            "Atualizar Usuário"
        );
    });
  };

  const handleClearCustomerUser = () => {
    setUser(EMPTY_CUSTOMER_USER);
  };

  const openEditUser = (item: any) => {
    CustomerService.getCustomerUser(item.id)
    .then((response) =>{
      const [first_name, ...restName] = response.data.name.split(" ");
      const last_name = restName.join(" ");

      const customerUser = {
      ...response.data,
      first_name,
      last_name,
      };
      setUser(customerUser);
      setModalOpen(true);
    }).catch((error) => { 
      NotificationManager.error("Ocorreu um erro ao buscar usuário do cliente.");
    });  
  };

  const openChangePassword = async (item: any) => {
    const userId = item.id;
    if (userId) {
      const isHardPassword = true;
      let shouldRetry = false;
      do {
        try {
          const password = await changePasswordDialog.current?.getPassword(isHardPassword);
          if (password) {
            const passwordChanged = await setPassword(userId, password);
            if (passwordChanged) {
              shouldRetry = false;
              NotificationManager.success("A senha do Usuário foi alterada com sucesso", "Alterar Senha do Entregador");
              changePasswordDialog.current?.hide();
            } else {
              shouldRetry = true;
            }
          }
        } catch (error) { }
      } while (shouldRetry);
    }
  };

  const handleActiveUser = async (item: any) => {
    confirmationDialog.current?.confirm(
      "Ativar Usuário",
      <Typography>Você tem certeza que deseja <strong>Ativa</strong> o usuário <b>{item.name}</b>?</Typography>,
      "Ativar",
      "Cancelar"
    ).then(() => {
      activeUser(item.id);
    }).catch(() => { });
  };

  const activeUser = useMemo(() => debounce((id: number) => {
    CustomerService.reactivateUser(id)
    .then((response) => {         
      const dialog = confirmationDialog.current;
      getUsers().finally(() => {
        dialog?.hide();
      });
      NotificationManager.success("Sucesso ao Ativar Usuário.", "Ativação");          
    })
    .catch((error) => {
      NotificationManager.error("Não foi possível Ativar o Usuário.", "Ativação");
    });
  }, 50), [confirmationDialog, getUsers]);

  const handleSuspendedUser = async (item: any) => {
    confirmationDialog.current?.confirm(
      "Ativar Usuário",
      <Typography>Você tem certeza que deseja <strong>Suspender</strong> o usuário <b>{item.name}</b>?</Typography>,
      "Suspender",
      "Cancelar"
    ).then(() => {
      suspendedUser(item.id);
    }).catch(() => { });
  };
  
  const suspendedUser = useMemo(() => debounce((id: number) => {
    CustomerService.deactivateUser(id)
    .then((response) => {          
      const dialog = confirmationDialog.current;
      getUsers().finally(() => {
        dialog?.hide();
      });
      NotificationManager.success("Sucesso ao Suspender Usuário.", "Suspender");                                         
    })
    .catch((error) => {
      NotificationManager.error("Não foi possível Suspender o Usuário.", "Suspender");
    });
  }, 50), [confirmationDialog, getUsers]);

  const setPassword = async (userId: number, newPassword: string) => {
    try {
      await CustomerService.setCustomerUserPassword(userId, newPassword);
      return true;
    } catch (error) {
      const { status, data } = error.response;
      const errorMsgs: string[] = [];
      const errorFields: { field: string, value: string }[] = [];
      let errorMsg = "Ocorreu um erro ao alterar a senha do Entregador";
      if (status === 400) {
        for (var key in data) {
          var value = data[key];
          if (["password", "password_confirmation"].includes(key)) {
            errorFields.push({ field: key, value: value });
          } else {
            errorMsgs.push(value);
          }
        }

        if (errorMsgs.length > 0) {
          errorMsg = errorMsgs.join(". \n");
        }
      }

      if (errorFields.length > 0) {
        changePasswordDialog.current?.showErrors(errorFields);
      } else {
        NotificationManager.error(errorMsg, "Alterar Senha do Entregador");
      }
      return false;
    }
  }

  const applyFilter = (params: ICustomerUserFilterParams) => {
    if(params.store === 0){
      params.store = null;
    }    
    setFilter(params);
  };

  return (
    <React.Fragment>
      <ConfirmationDialog ref={confirmationDialog} />

      <Box mt={1} style={{ display: "flex", justifyContent: "flex-end" }}>
        <ShowIf condition={AuthService.hasAuthorization("add_customeruser")}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setModalOpen(true)}
            startIcon={<AddCircleOutlineRoundedIcon />}
          >
            Novo Usuário
          </Button>
        </ShowIf>
      </Box>
      <Box mt={1} mb={2} style={{ display: "flex", alignItems: "center" }}>
        <CustomerUsersFilters
          getUsers={getUsers}
          setFilter={setFilter}
          storeList={storeList}
          onFilterChanged={applyFilter}
        />
      </Box>

      <MaterialTable
        columns={[
          { field: "id", hidden: true, width: 60 },
          { field: "name", title: "Nome", defaultSort: "asc" },
          { field: "email", title: "E-mail" },
          {
            field: "phonenumber", title: "Telefone", emptyValue: "---",
            render: (rowData, type) => rowData.phonenumber ? formatPhoneNumber(rowData.phonenumber) : "---"
          },
          {
            field: "store", title: "Loja", emptyValue: "Todas",
            render: (rowData, type) => rowData.store.name
          },
          {
            field: "user_status", title: "Situação",
            render: (rowData, type) => (
              <Alert severity={mapCustomerUserStatus(rowData.user_status)} icon={false}>
                {t("user_status." + rowData.user_status)}
              </Alert>
            )
          }
        ]}
        data={customerUsers}
        actions={AuthService.hasAuthorization("change_customeruser") ? [          
          {
            icon: () => <EditOutlined />,
            tooltip: 'Editar',
            onClick: (event, rowData) => openEditUser(rowData)
          },
          {
            icon: () => <VpnKey />,
            tooltip: 'Mudar Senha',
            onClick: (event, rowData) => openChangePassword(rowData)
          },
          (rowData) => rowData.user_status === "WAITING_ACTIVATION" || rowData.user_status === "SUSPENDED" ?
            {
              icon: () => <Check />,
              tooltip: 'Ativar',
              onClick: (event, rowData) => handleActiveUser(rowData)
            } : {            
              icon: () => <Block />,
              tooltip: 'Suspender',
              hidden: rowData.user_status === "BLOCKED" ? true : false,
              onClick: (event, rowData) => handleSuspendedUser(rowData)
            }
          ,
                           
        ] : []}
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_L10N_PTBR}
        options={{
          ...DEFAULT_TABLE_OPTIONS,
          paging: false
        }}
      />

      <UserModal
        modal={{
          open: isModalOpen,
          setOpen: setModalOpen,
        }}
        storeList={storeList}
        handleClearUser={handleClearCustomerUser}
        customer_user={user}
        handleAddCustomerUser={handleAddUser}
        handleUpdateCustomerUser={handleUpdateUser}
      />

      <ChangePasswordDialog ref={changePasswordDialog} />
    </React.Fragment>
  );
};
