import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Home } from "../pages/home";
import { User } from "../pages/user";
import AuthenticatedRoute from "./AuthenticatedRoute";
import { UnauthenticatedRoute } from "./UnauthenticatedRoute";
import { Login } from "../pages/login";
import { EditUser } from "../pages/user/EditUser";
import { DetailUser } from "../pages/user/DetailUser";
import { RequestPassword } from "../pages/requestPassword";
import { NewUser } from "../pages/user/NewUser";
import { ResetPassword } from "../pages/resetPassword";
import { ChangePassword } from "../pages/changePassword";
import { MainDashboard } from "../pages/mainDashboard";
import { Courier } from "../pages/courier";
import { RequestsHistory } from "../pages/requestsHistory";
import { NewDeliveryRequest } from "../pages/newDeliveryRequest";
import { DetailCourier } from "../pages/courier/detail";
import { DetailCustomer } from "../pages/customer/detail";
import { Customer } from "../pages/customer";
import { EditCourier } from "../pages/courier/EditCourier";
import { NewCourier } from "../pages/courier/NewCourier";
import { NewCustomer } from "../pages/customer/NewCustomer";
import { EditCustomer } from "../pages/customer/EditCustomer";
import { Settings } from "../pages/settings";
import { RequestDetails } from "../pages/requestDetails";
import { Activate } from "../pages/activate";
import { Regions } from "../pages/regions";
import { NewRegion } from "../pages/regions/NewRegion";
import { DetailsRegion } from "../pages/regions/DetailsRegion";
import { EditRegion } from "../pages/regions/EditRegion";
import { Remittances } from "../pages/remittance";
import { DetailsRemittance } from "../pages/remittance/DetailsRemittance";
import { Credit } from "../pages/credit";
import { CommercialPlan } from "../pages/commercialPlan";
import { Announcement } from "../pages/announcement";
import { NewAnnouncement } from "../pages/announcement/NewAnnouncement";
import { EditAnnouncement } from "../pages/announcement/EditAnnouncement";
import { CopyAnnouncement } from "../pages/announcement/CopyAnnouncement";
import { DetailAnnouncement } from "../pages/announcement/DetailAnnouncement";
import { Incentives } from "../pages/incentives";
import { NewCommercialPlan } from "../pages/commercialPlan/newCommercialPlan";
import { DetailsCommercialPlan } from "../pages/commercialPlan/detailsCommercialPlan";
import { DetailIncentive } from "../pages/incentives/DetailIncentive";
import { EditCommercialPlan } from "../pages/commercialPlan/editCommercialPlan";
import { StoreCourierQueue } from "../pages/customer/detail/StoreCourierQueue";
import { TopCouriers } from "../pages/reports";
import AuthService from "../services/authService";
import { NewIncentive } from "../pages/incentives/NewIncentive";
import { EditIncentive } from "../pages/incentives/EditIncentive";
import { RequestDetailsTrip } from "../pages/requestDetails/trip";
import { Occurrences } from "../pages/occurrences";
import { DetailsOccurrence } from "../pages/occurrences/DetailsOccurrence";
import { DetailsDebitNote } from "../pages/debitNotes/DetailsDebitNote";
import { RankingCourier } from "../pages/ranking";
import { DedicatedSlots } from "../pages/dedicated";
import { DedicatedDetail } from "../pages/dedicated/DedicatedDetail";
import { DedicatedNewEdit } from "../pages/dedicated/DedicatedNewEdit";
import { Panel } from "../pages/panel";
import { DebitNote } from "../pages/debitNotes";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { CommissionReports } from "../pages/commissionReports";
import { NotRechargingCustomers } from "../pages/notRechargingCustomers";
import { ReportCouriers } from "../pages/reportCouriers";
import { Configurations } from "../pages/configurations";
import { NewReferral } from "../pages/newReferral";
import { ReportCustomersActive } from "../pages/reportCustomersActive";

export default function Routes() {
  const classes = useStyles();

  return (
    <div className={classes.appContainer}>
      <Switch>
        <UnauthenticatedRoute exact path="/login">
          <Login />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/request-password">
          <RequestPassword />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/new-password/:uid/:token">
          <ResetPassword />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/activate">
          <Activate />
        </UnauthenticatedRoute>
        <AuthenticatedRoute exact path="/home">
          <Home />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/manager">
          <ManagerRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/user">
          <UserRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/courier">
          <CourierRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/customer">
          <CustomerRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/storeQueue">
          <StoreQueueRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/requests">
          <RequestRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/announcement">
          <AnnouncementRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/incentive">
          <IncentivesRoutes />
        </AuthenticatedRoute>/
        <AuthenticatedRoute path="/occurrences">
          <OccurrencesRoutes />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/dedicated">
          <DedicatedRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/operational">
          <OperationalRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/region">
          <RegionRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/remittance">
          <RemittanceRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/credit">
          <CreditRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/debitNotes">
          <DebitNotesRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/commercialPlan">
          <CommercialPlanRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/commissionReports">
          <CommissionReportsRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/panel">
          <PanelRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/topCouriers">
          <TopCouriersRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/rankingCouriers">
          <RankingCouriersRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/notRechargingCustomers">
          <NotRechargingCustomersRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/reportCouriers">
          <ReportCouriersRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/configurations">
          <ConfigurationsRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/newReferral">
          <NewReferralRoutes />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/reportCustomersActive">
          <ReportCustomersActiveRoutes />
        </AuthenticatedRoute>
        <Redirect from="*" to="/operational/dashboard" />
      </Switch>
    </div>
  );
}

const requireAuth = (component: any, authorization: string | string[]): any => {
  if (AuthService.hasAuthorization(authorization)) {
    return component;
  }
  return Home;
}

const UserRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(User, "view_user")} exact path={path} />
      <Route component={requireAuth(DetailUser, "view_user")} path={`${path}/detail/:userId`} />
      <Route component={requireAuth(NewUser, "add_user")} path={`${path}/new`} />
      <Route component={requireAuth(EditUser, "change_user")} path={`${path}/edit/:userId`} />
    </Switch>
  );
};

const ManagerRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={ChangePassword} exact path={`${path}/profile/edit`} />
      <Route component={Settings} exact path={`${path}/configuration`} />
    </Switch>
  );
};

const CourierRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(Courier, "view_courier")} exact path={path} />
      <Route component={requireAuth(DetailCourier, "view_courier")} path={`${path}/detail/:courierId`} />
      <Route component={requireAuth(NewCourier, "add_courier")} path={`${path}/new`} />
      <Route component={requireAuth(EditCourier, "change_courier")} path={`${path}/edit/:courierId`} />
    </Switch>
  );
};

const CustomerRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(Customer, "view_customer")} exact path={path} />
      <Route component={requireAuth(DetailCustomer, "view_customer")} path={`${path}/detail/:customerId`} />
      <Route component={requireAuth(NewCustomer, "add_customer")} exact path={`${path}/new`} />
      <Route component={requireAuth(EditCustomer, "change_customer")} path={`${path}/edit/:customerId`} />
    </Switch>
  );
};

const StoreQueueRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(StoreCourierQueue, "view_deliveryrequest")} exact path={path} />
    </Switch>
  );
};

const RequestRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(RequestsHistory, "view_deliveryrequest")} exact path={path} />
      <Route component={requireAuth(RequestDetails, "view_deliveryrequest")} path={`${path}/detail/:requestId`} />
      <Route component={requireAuth(RequestDetailsTrip, "view_deliveryrequest")} path={`${path}/trip/:tripId`} />
      <Route component={requireAuth(NewDeliveryRequest, "view_deliveryrequest")} path={`${path}/new`} />
    </Switch>
  );
};

const AnnouncementRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(Announcement, "view_announcement")} exact path={path} />
      <Route component={requireAuth(DetailAnnouncement, "view_announcement")} exact path={`${path}/detail/:announcementId`} />
      <Route component={requireAuth(NewAnnouncement, "add_announcement")} exact path={`${path}/new`} />
      <Route component={requireAuth(EditAnnouncement, "change_announcement")} exact path={`${path}/edit/:announcementId`} />
      <Route component={requireAuth(CopyAnnouncement, "add_announcement")} exact path={`${path}/copy/:announcementId`} />
    </Switch>
  );
};

const IncentivesRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(Incentives, "view_incentivedynamic")} exact path={path} />
      <Route component={requireAuth(DetailIncentive, "view_incentivedynamic")} exact path={`${path}/detail/:incentiveId`} />
      <Route component={requireAuth(NewIncentive, "add_incentivedynamic")} exact path={`${path}/new`} />
      <Route component={requireAuth(EditIncentive, "change_incentivedynamic")} exact path={`${path}/edit/:incentiveId`} />
    </Switch>
  );
};

const OccurrencesRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(Occurrences, "view_occurrences")} exact path={path} />
      <Route component={requireAuth(DetailsOccurrence, "view_occurrences")} exact path={`${path}/detail/:occurrenceId`} />

    </Switch>
  );
};

const DedicatedRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(DedicatedSlots, "view_dedicatedproposal")} exact path={path} />
      <Route component={requireAuth(DedicatedDetail, "view_dedicatedproposal")} exact path={`${path}/detail/:dedicatedId`} />
      <Route component={requireAuth(DedicatedNewEdit, "add_dedicatedproposal")} exact path={`${path}/new`} />
      <Route component={requireAuth(DedicatedNewEdit, "change_dedicatedproposal")} exact path={`${path}/edit/:dedicatedId`} />
    </Switch>
  );
};


const OperationalRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(MainDashboard, "view_deliveryrequest")} exact path={`${path}/dashboard`} />
    </Switch>
  );
};

const RegionRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(Regions, "view_region")} exact path={`${path}/`} />
      <Route component={requireAuth(DetailsRegion, "view_region")} exact path={`${path}/detail/:regionId`} />
      <Route component={requireAuth(NewRegion, "add_region")} exact path={`${path}/new`} />
      <Route component={requireAuth(EditRegion, "change_region")} exact path={`${path}/edit/:regionId`} />
    </Switch>
  );
};

const RemittanceRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(Remittances, "view_remittance")} exact path={`${path}/`} />
      <Route component={requireAuth(DetailsRemittance, "view_remittance")} exact path={`${path}/detail/:remittanceId`} />
    </Switch>
  );
};

const DebitNotesRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(DebitNote, "view_debit_note")} exact path={`${path}/`} />
      <Route component={requireAuth(DetailsDebitNote, "view_debit_note")} exact path={`${path}/detail/:debitNoteId`} />
    </Switch>
  );
};

const CreditRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(Credit, "view_customer_finances_entries")} exact path={`${path}/`} />
    </Switch>
  );
};

const CommercialPlanRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(CommercialPlan, "view_commercialplan")} exact path={`${path}/`} />
      <Route component={requireAuth(NewCommercialPlan, "add_commercialplan")} path={`${path}/new`} />
      <Route component={requireAuth(EditCommercialPlan, "change_commercialplan")} exact path={`${path}/edit/:commercialPlanId`} />
      <Route component={requireAuth(DetailsCommercialPlan, "view_commercialplan")} exact path={`${path}/detail/:commercialPlanId`} />
    </Switch>
  );
};

const CommissionReportsRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={requireAuth(CommissionReports, "view_commission")} exact path={`${path}/`} />
    </Switch>
  );
};

const TopCouriersRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={TopCouriers} exact path={`${path}/`} />
    </Switch>
  );
};

const RankingCouriersRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={RankingCourier} exact path={`${path}/`} />
    </Switch>
  );
};

const NotRechargingCustomersRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={NotRechargingCustomers} exact path={`${path}/`} />
    </Switch>
  );
};

const ReportCouriersRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={ReportCouriers} exact path={`${path}/`} />
    </Switch>
  );
};

const ConfigurationsRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={Configurations} exact path={`${path}/`} />
    </Switch>
  );
};


const NewReferralRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={NewReferral} exact path={`${path}/`} />
    </Switch>
  );
};

const PanelRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={Panel} exact path={`${path}/`} />
    </Switch>
  );
};

const ReportCustomersActiveRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={ReportCustomersActive} exact path={`${path}/`} />
    </Switch>
  );
};


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appContainer: {
      display: "flex",
      height: "100%",
    },
  })
);
