import { RegionResponse } from "./region";

export interface CommercialPlanResponse {
    id: number;
    name: string;
    minimum_value_distance: number;
    region: RegionResponse;
    created_at: Date;
    updated_at: Date;
}

export interface CommercialPlanDetails {
    id: number;
    name: string;
    minimum_value_distance: number;
    region: RegionResponse;
    created_at: Date;
    updated_at: Date;

    bike_minimum_speedy_value: number;
    bike_minimum_courier_value: number;
    bike_additional_km_speedy_value: number;
    bike_additional_km_courier_value: number;
    bike_minimum_speedy_return_value: number;
    bike_minimum_courier_return_value: number;
    bike_additional_km_speedy_return_value: number;
    bike_additional_km_courier_return_value: number;
    bike_different_value_for_shared_trip: boolean;
    bike_minimum_shared_trip_speedy_value: number;
    bike_minimum_shared_trip_courier_value: number;
    bike_additional_km_shared_trip_speedy_value: number;
    bike_additional_km_shared_trip_courier_value: number;
    bike_minimum_shared_trip_speedy_return_value: number;
    bike_minimum_shared_trip_courier_return_value: number;
    bike_additional_km_shared_trip_speedy_return_value: number;
    bike_additional_km_shared_trip_courier_return_value: number;
    bike_additional_km_rules: CreateUpdateAdditionalRulesPlan[];

    motorcycle_minimum_speedy_value: number;
    motorcycle_minimum_courier_value: number;
    motorcycle_additional_km_speedy_value: number;
    motorcycle_additional_km_courier_value: number;
    motorcycle_minimum_speedy_return_value: number;
    motorcycle_minimum_courier_return_value: number;
    motorcycle_additional_km_speedy_return_value: number;
    motorcycle_additional_km_courier_return_value: number;
    motorcycle_different_value_for_shared_trip: boolean;
    motorcycle_minimum_shared_trip_speedy_value: number;
    motorcycle_minimum_shared_trip_courier_value: number;
    motorcycle_additional_km_shared_trip_speedy_value: number;
    motorcycle_additional_km_shared_trip_courier_value: number;
    motorcycle_minimum_shared_trip_speedy_return_value: number;
    motorcycle_minimum_shared_trip_courier_return_value: number;
    motorcycle_additional_km_shared_trip_speedy_return_value: number;
    motorcycle_additional_km_shared_trip_courier_return_value: number;
    motorcycle_additional_km_rules: CreateUpdateAdditionalRulesPlan[];

    car_minimum_speedy_value: number;
    car_minimum_courier_value: number;
    car_additional_km_speedy_value: number;
    car_additional_km_courier_value: number;
    car_minimum_speedy_return_value: number;
    car_minimum_courier_return_value: number;
    car_additional_km_speedy_return_value: number;
    car_additional_km_courier_return_value: number;
    car_different_value_for_shared_trip: boolean;
    car_minimum_shared_trip_speedy_value: number;
    car_minimum_shared_trip_courier_value: number;
    car_additional_km_shared_trip_speedy_value: number;
    car_additional_km_shared_trip_courier_value: number;
    car_minimum_shared_trip_speedy_return_value: number;
    car_minimum_shared_trip_courier_return_value: number;
    car_additional_km_shared_trip_speedy_return_value: number;
    car_additional_km_shared_trip_courier_return_value: number;
    car_additional_km_rules: CreateUpdateAdditionalRulesPlan[];

    van_minimum_speedy_value: number;
    van_minimum_courier_value: number;
    van_additional_km_speedy_value: number;
    van_additional_km_courier_value: number;
    van_minimum_speedy_return_value: number;
    van_minimum_courier_return_value: number;
    van_additional_km_speedy_return_value: number;
    van_additional_km_courier_return_value: number;
    van_different_value_for_shared_trip: boolean;
    van_minimum_shared_trip_speedy_value: number;
    van_minimum_shared_trip_courier_value: number;
    van_additional_km_shared_trip_speedy_value: number;
    van_additional_km_shared_trip_courier_value: number;
    van_minimum_shared_trip_speedy_return_value: number;
    van_minimum_shared_trip_courier_return_value: number;
    van_additional_km_shared_trip_speedy_return_value: number;
    van_additional_km_shared_trip_courier_return_value: number;
    van_additional_km_rules: CreateUpdateAdditionalRulesPlan[];
}

export interface CreateUpdateCommercialPlan {
    id?: number;
    name: string;
    cancel_fee_speedy: number;
    cancel_fee_courier: number;
    region: any;
    minimum_value_distance: any;

    bike_minimum_speedy_value: number;
    bike_minimum_courier_value: number;
    bike_additional_km_speedy_value: number;
    bike_additional_km_courier_value: number;
    bike_minimum_speedy_return_value: number;
    bike_minimum_courier_return_value: number;
    bike_additional_km_speedy_return_value: number;
    bike_additional_km_courier_return_value: number;
    bike_different_value_for_shared_trip: boolean;
    bike_minimum_shared_trip_speedy_value: number;
    bike_minimum_shared_trip_courier_value: number;
    bike_additional_km_shared_trip_speedy_value: number;
    bike_additional_km_shared_trip_courier_value: number;
    bike_minimum_shared_trip_speedy_return_value: number;
    bike_minimum_shared_trip_courier_return_value: number;
    bike_additional_km_shared_trip_speedy_return_value: number;
    bike_additional_km_shared_trip_courier_return_value: number;
    bike_additional_km_rules: CreateUpdateAdditionalRulesPlan[];

    motorcycle_minimum_speedy_value: number;
    motorcycle_minimum_courier_value: number;
    motorcycle_additional_km_speedy_value: number;
    motorcycle_additional_km_courier_value: number;
    motorcycle_minimum_speedy_return_value: number;
    motorcycle_minimum_courier_return_value: number;
    motorcycle_additional_km_speedy_return_value: number;
    motorcycle_additional_km_courier_return_value: number;
    motorcycle_different_value_for_shared_trip: boolean;
    motorcycle_minimum_shared_trip_speedy_value: number;
    motorcycle_minimum_shared_trip_courier_value: number;
    motorcycle_additional_km_shared_trip_speedy_value: number;
    motorcycle_additional_km_shared_trip_courier_value: number;
    motorcycle_minimum_shared_trip_speedy_return_value: number;
    motorcycle_minimum_shared_trip_courier_return_value: number;
    motorcycle_additional_km_shared_trip_speedy_return_value: number;
    motorcycle_additional_km_shared_trip_courier_return_value: number;
    motorcycle_additional_km_rules: CreateUpdateAdditionalRulesPlan[];

    car_minimum_speedy_value: number;
    car_minimum_courier_value: number;
    car_additional_km_speedy_value: number;
    car_additional_km_courier_value: number;
    car_minimum_speedy_return_value: number;
    car_minimum_courier_return_value: number;
    car_additional_km_speedy_return_value: number;
    car_additional_km_courier_return_value: number;
    car_different_value_for_shared_trip: boolean;
    car_minimum_shared_trip_speedy_value: number;
    car_minimum_shared_trip_courier_value: number;
    car_additional_km_shared_trip_speedy_value: number;
    car_additional_km_shared_trip_courier_value: number;
    car_minimum_shared_trip_speedy_return_value: number;
    car_minimum_shared_trip_courier_return_value: number;
    car_additional_km_shared_trip_speedy_return_value: number;
    car_additional_km_shared_trip_courier_return_value: number;
    car_additional_km_rules: CreateUpdateAdditionalRulesPlan[];

    van_minimum_speedy_value: number;
    van_minimum_courier_value: number;
    van_additional_km_speedy_value: number;
    van_additional_km_courier_value: number;
    van_minimum_speedy_return_value: number;
    van_minimum_courier_return_value: number;
    van_additional_km_speedy_return_value: number;
    van_additional_km_courier_return_value: number;
    van_different_value_for_shared_trip: boolean;
    van_minimum_shared_trip_speedy_value: number;
    van_minimum_shared_trip_courier_value: number;
    van_additional_km_shared_trip_speedy_value: number;
    van_additional_km_shared_trip_courier_value: number;
    van_minimum_shared_trip_speedy_return_value: number;
    van_minimum_shared_trip_courier_return_value: number;
    van_additional_km_shared_trip_speedy_return_value: number;
    van_additional_km_shared_trip_courier_return_value: number;
    van_additional_km_rules: CreateUpdateAdditionalRulesPlan[];
}
export interface CreateUpdateAdditionalRulesPlan {
    start_m: string
    end_m: string | null;
    speedy_value: string;
    courier_value: string;
}

export interface CommercialPlanResponse {
    id: number;
    name: string;
    minimum_value_distance: number;
    region: RegionResponse;
    created_at: Date;
    updated_at: Date;
}

export interface CommercialPlanDetails {
    id: number;
    name: string;
    minimum_value_distance: number;
    region: RegionResponse;
    created_at: Date;
    updated_at: Date;
    cancel_fee_speedy: number;
    cancel_fee_courier: number;
    bike_minimum_speedy_value: number;
    bike_minimum_courier_value: number;
    bike_additional_km_speedy_value: number;
    bike_additional_km_courier_value: number;
    bike_minimum_speedy_return_value: number;
    bike_minimum_courier_return_value: number;
    bike_additional_km_speedy_return_value: number;
    bike_additional_km_courier_return_value: number;
    bike_different_value_for_shared_trip: boolean;
    bike_minimum_shared_trip_speedy_value: number;
    bike_minimum_shared_trip_courier_value: number;
    bike_additional_km_shared_trip_speedy_value: number;
    bike_additional_km_shared_trip_courier_value: number;
    bike_minimum_shared_trip_speedy_return_value: number;
    bike_minimum_shared_trip_courier_return_value: number;
    bike_additional_km_shared_trip_speedy_return_value: number;
    bike_additional_km_shared_trip_courier_return_value: number;
    bike_additional_km_rules: CreateUpdateAdditionalRulesPlan[];

    motorcycle_minimum_speedy_value: number;
    motorcycle_minimum_courier_value: number;
    motorcycle_additional_km_speedy_value: number;
    motorcycle_additional_km_courier_value: number;
    motorcycle_minimum_speedy_return_value: number;
    motorcycle_minimum_courier_return_value: number;
    motorcycle_additional_km_speedy_return_value: number;
    motorcycle_additional_km_courier_return_value: number;
    motorcycle_different_value_for_shared_trip: boolean;
    motorcycle_minimum_shared_trip_speedy_value: number;
    motorcycle_minimum_shared_trip_courier_value: number;
    motorcycle_additional_km_shared_trip_speedy_value: number;
    motorcycle_additional_km_shared_trip_courier_value: number;
    motorcycle_minimum_shared_trip_speedy_return_value: number;
    motorcycle_minimum_shared_trip_courier_return_value: number;
    motorcycle_additional_km_shared_trip_speedy_return_value: number;
    motorcycle_additional_km_shared_trip_courier_return_value: number;
    motorcycle_additional_km_rules: CreateUpdateAdditionalRulesPlan[];

    car_minimum_speedy_value: number;
    car_minimum_courier_value: number;
    car_additional_km_speedy_value: number;
    car_additional_km_courier_value: number;
    car_minimum_speedy_return_value: number;
    car_minimum_courier_return_value: number;
    car_additional_km_speedy_return_value: number;
    car_additional_km_courier_return_value: number;
    car_different_value_for_shared_trip: boolean;
    car_minimum_shared_trip_speedy_value: number;
    car_minimum_shared_trip_courier_value: number;
    car_additional_km_shared_trip_speedy_value: number;
    car_additional_km_shared_trip_courier_value: number;
    car_minimum_shared_trip_speedy_return_value: number;
    car_minimum_shared_trip_courier_return_value: number;
    car_additional_km_shared_trip_speedy_return_value: number;
    car_additional_km_shared_trip_courier_return_value: number;
    car_additional_km_rules: CreateUpdateAdditionalRulesPlan[];

    van_minimum_speedy_value: number;
    van_minimum_courier_value: number;
    van_additional_km_speedy_value: number;
    van_additional_km_courier_value: number;
    van_minimum_speedy_return_value: number;
    van_minimum_courier_return_value: number;
    van_additional_km_speedy_return_value: number;
    van_additional_km_courier_return_value: number;
    van_different_value_for_shared_trip: boolean;
    van_minimum_shared_trip_speedy_value: number;
    van_minimum_shared_trip_courier_value: number;
    van_additional_km_shared_trip_speedy_value: number;
    van_additional_km_shared_trip_courier_value: number;
    van_minimum_shared_trip_speedy_return_value: number;
    van_minimum_shared_trip_courier_return_value: number;
    van_additional_km_shared_trip_speedy_return_value: number;
    van_additional_km_shared_trip_courier_return_value: number;
    van_additional_km_rules: CreateUpdateAdditionalRulesPlan[];
}

export const EMPTY_COMMERCIAL_PLAN: CreateUpdateCommercialPlan = {
    name: "",
    cancel_fee_speedy: 0,
    cancel_fee_courier: 0,
    region: null,
    minimum_value_distance: null,
    bike_minimum_speedy_value: 0,
    bike_minimum_courier_value: 0,
    bike_additional_km_speedy_value: 0,
    bike_additional_km_courier_value: 0,
    bike_minimum_speedy_return_value: 0,
    bike_minimum_courier_return_value: 0,
    bike_additional_km_speedy_return_value: 0,
    bike_additional_km_courier_return_value: 0,
    bike_different_value_for_shared_trip: false,
    bike_minimum_shared_trip_speedy_value: 0,
    bike_minimum_shared_trip_courier_value: 0,
    bike_additional_km_shared_trip_speedy_value: 0,
    bike_additional_km_shared_trip_courier_value: 0,
    bike_minimum_shared_trip_speedy_return_value: 0,
    bike_minimum_shared_trip_courier_return_value: 0,
    bike_additional_km_shared_trip_speedy_return_value: 0,
    bike_additional_km_shared_trip_courier_return_value: 0,
    bike_additional_km_rules: [],

    motorcycle_minimum_speedy_value: 0,
    motorcycle_minimum_courier_value: 0,
    motorcycle_additional_km_speedy_value: 0,
    motorcycle_additional_km_courier_value: 0,
    motorcycle_minimum_speedy_return_value: 0,
    motorcycle_minimum_courier_return_value: 0,
    motorcycle_additional_km_speedy_return_value: 0,
    motorcycle_additional_km_courier_return_value: 0,
    motorcycle_different_value_for_shared_trip: false,
    motorcycle_minimum_shared_trip_speedy_value: 0,
    motorcycle_minimum_shared_trip_courier_value: 0,
    motorcycle_additional_km_shared_trip_speedy_value: 0,
    motorcycle_additional_km_shared_trip_courier_value: 0,
    motorcycle_minimum_shared_trip_speedy_return_value: 0,
    motorcycle_minimum_shared_trip_courier_return_value: 0,
    motorcycle_additional_km_shared_trip_speedy_return_value: 0,
    motorcycle_additional_km_shared_trip_courier_return_value: 0,
    motorcycle_additional_km_rules: [],

    car_minimum_speedy_value: 0,
    car_minimum_courier_value: 0,
    car_additional_km_speedy_value: 0,
    car_additional_km_courier_value: 0,
    car_minimum_speedy_return_value: 0,
    car_minimum_courier_return_value: 0,
    car_additional_km_speedy_return_value: 0,
    car_additional_km_courier_return_value: 0,
    car_different_value_for_shared_trip: false,
    car_minimum_shared_trip_speedy_value: 0,
    car_minimum_shared_trip_courier_value: 0,
    car_additional_km_shared_trip_speedy_value: 0,
    car_additional_km_shared_trip_courier_value: 0,
    car_minimum_shared_trip_speedy_return_value: 0,
    car_minimum_shared_trip_courier_return_value: 0,
    car_additional_km_shared_trip_speedy_return_value: 0,
    car_additional_km_shared_trip_courier_return_value: 0,
    car_additional_km_rules: [],

    van_minimum_speedy_value: 0,
    van_minimum_courier_value: 0,
    van_additional_km_speedy_value: 0,
    van_additional_km_courier_value: 0,
    van_minimum_speedy_return_value: 0,
    van_minimum_courier_return_value: 0,
    van_additional_km_speedy_return_value: 0,
    van_additional_km_courier_return_value: 0,
    van_different_value_for_shared_trip: false,
    van_minimum_shared_trip_speedy_value: 0,
    van_minimum_shared_trip_courier_value: 0,
    van_additional_km_shared_trip_speedy_value: 0,
    van_additional_km_shared_trip_courier_value: 0,
    van_minimum_shared_trip_speedy_return_value: 0,
    van_minimum_shared_trip_courier_return_value: 0,
    van_additional_km_shared_trip_speedy_return_value: 0,
    van_additional_km_shared_trip_courier_return_value: 0,
    van_additional_km_rules: [],
  };