import React from "react";
import { Card, CardActionArea, CardContent, Chip, Grid, makeStyles, Typography,  } from "@material-ui/core";
import { CustomAvatar } from "../../../components/CustomAvatar";
import { useTranslation } from "react-i18next";
import theme from "../../../styles/theme";
import { OnlineCourierUserDetails, SpeedyRankingType } from "../../../services/types/courier";
import { formatPhoneNumber } from "../../../utils/formatters";
import clsx from "clsx";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import PopoverRanking from "./popoverRanking";
import ShowIf from "../../../components/ShowIf";

interface ICourierDetailCardProps {
    courier: OnlineCourierUserDetails;
    selected?: boolean;
    action?: (courier: OnlineCourierUserDetails) => void;
}

export const CourierCard: React.FC<ICourierDetailCardProps> = ({ courier, selected = false, action }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Card variant="outlined" className={clsx(classes.card,
            { [classes.selectedCard]: selected },
        )}>
            <CardActionArea onClick={() => {
                if (action) {
                    action(courier);
                }
            }}>
                <CardContent className={classes.content}>
                    <Grid container wrap="nowrap" spacing={1}>
                        <Grid item>
                            <CustomAvatar size="md" img={courier.photo} />
                        </Grid>
                        <Grid item xs={12} sm container spacing={0}>
                            <Grid item xs={10} lg title={courier.name} zeroMinWidth aria-owns={open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}>                                                               
                                <Typography variant="subtitle1" noWrap>
                                    <ShowIf condition={courier.speedy_ranking === SpeedyRankingType.INITIATE_SPEEDER}>
                                        <StarBorderIcon
                                        className={classes.styleStarRanking}
                                        fontSize="small"                                   
                                        />
                                    </ShowIf>
                                    <ShowIf condition={courier.speedy_ranking === SpeedyRankingType.ADVANCED_SPEEDER}>
                                        <StarIcon
                                        className={clsx(classes.styleStarRanking, classes.advancedSpeederStar)}
                                        fontSize="small"
                                        />
                                    </ShowIf>
                                    {courier.name}
                                </Typography>
                            </Grid>
                            <Grid item>                                                                     
                                <Chip
                                    size="small"
                                    className={classes.statusChip}
                                    label={t('courier_user_status.' + courier.courier_status)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" noWrap>
                                    {formatPhoneNumber(courier.phonenumber)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>

            <PopoverRanking openPopover={open} anchorEl={anchorEl} 
                speedyRanking={courier?.speedy_ranking} 
                newRankingPoints={courier?.new_ranking_points} 
                requestsCount={courier?.requests_count} 
                handlePopoverClose={handlePopoverClose} 
            />

        </Card>   
    );
}

const useStyles = makeStyles({
    content: {
        padding: theme.spacing(1) / 2,
    },
    statusChip: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        height: "20px !important",
        fontSize: "0.8rem !important",
    },
    card: {
        position: "relative",
        zIndex: 1
    },
    selectedCard: {
        borderColor: theme.palette.warning.main,
        borderWidth: "4px",
        margin: "-3px",
        zIndex: 2
    },
    paper: {
        padding: theme.spacing(2),
    },
    popover: {
        pointerEvents: 'none',
    },
    cardTitle: {
        fontSize: "24px",
        marginBottom: "8px",
        fontWeight: "bold",
    },
    styleStarRanking: {
        position: "relative",
        top: "3px",
        marginRight: "4px",
    },
    advancedSpeederStar: {
        color: theme.palette.primary.main
    }
});