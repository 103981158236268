import React, { ReactNode } from "react";
import {
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Card,
  CardContent,
  Link,
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from "../../../../utils/formatters";
import ShowIf from "../../../../components/ShowIf";
import { SpeedyRankingType } from "../../../../services/types/courier";
import clsx from "clsx";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import PopoverRanking from "../../../mainDashboard/customComponents/popoverRanking";
import { TripCourier } from "../../../../services/types/deliveryRequest";
import { ImgLoader } from "../../../../components/ImgLoader";
import { useHistory } from "react-router";

interface IProps {
  children?: ReactNode;
  courier?: TripCourier;
  called_iFood: boolean;
}

export const InfoCardCourier = ({ children, courier, called_iFood }: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const goToCourier = () => {
    if (courier) {
      history.push(`/courier/detail/${courier.id}`);
    }
  }

  const open = Boolean(anchorEl);
  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography className={classes.cardTitle} variant="h4">
            Dados do Entregador
          </Typography>

          <div style={{ display: "flex", alignItems: "center" }}>
            {courier?.photo && (
              <ImgLoader 
                src={courier?.photo}
                className={classes.cardImage}
              />
            )}

            <div style={{ marginLeft: "20px", width: "100%" }}>
              <Typography
                variant="body1"
                style={{ margin: 0 }}
              >
                Nome
              </Typography>
              <Typography variant="subtitle1" aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={courier ? handlePopoverOpen : undefined}
                onMouseLeave={handlePopoverClose}>
                {courier ? (
                  <React.Fragment>
                    <ShowIf condition={courier.speedy_ranking === SpeedyRankingType.INITIATE_SPEEDER}>
                      <StarBorderIcon
                        className={classes.styleStarRanking}
                        fontSize="small"
                      />
                    </ShowIf>
                    <ShowIf condition={courier.speedy_ranking === SpeedyRankingType.ADVANCED_SPEEDER}>
                      <StarIcon
                        className={clsx(classes.styleStarRanking, classes.advancedSpeederStar)}
                        fontSize="small"
                      />
                    </ShowIf>
                    <Link href="#" onClick={goToCourier} underline="none">
                      {courier?.name}
                    </Link>
                    
                  </React.Fragment>
                ) : (
                  called_iFood ? "Chamado Entregador do iFood" : "---"
                )}
              </Typography>

              <div className={classes.infoContainer}>
                <div>
                  <Typography variant="body1" style={{ margin: 0 }}>
                    Tipo do veículo:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      margin: 0,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {courier ? "" : "---"}
                    {courier && t('vehicle_type.' + courier.vehicle_type)}
                    {courier?.vehicle_load_compartment_type && (
                      <span className={classes.vehicleContainerTag}>
                        {t('vehicle_load_compartment_type.' + courier.vehicle_load_compartment_type)}
                      </span>
                    )}
                  </Typography>
                </div>

                <div style={{marginLeft: "auto"}}>
                  <Typography variant="body1" style={{ margin: 0 }}>
                    Celular
                  </Typography>
                  <Typography variant="subtitle1">
                    {courier ? formatPhoneNumber(courier?.phonenumber) : "---"}
                  </Typography>
                </div>
                {courier?.app_version && (
                  <div>
                    <Typography variant="body1" style={{ margin: 0 }}>
                      Versão do APP
                    </Typography>
                    <Typography variant="subtitle1">
                      {courier.app_version ? courier.app_version : "---"}
                    </Typography>
                  </div>
                )}
                
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <PopoverRanking openPopover={open} anchorEl={anchorEl}
        speedyRanking={courier?.speedy_ranking}
        newRankingPoints={Number(courier?.new_ranking_points)}
        requestsCount={Number(courier?.requests_count)}
        handlePopoverClose={handlePopoverClose}
      />
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardTitle: {
      fontSize: "24px",
      marginBottom: "8px",
      fontWeight: "bold",
    },
    cardImage: {
      width: "70px",
      height: "70px",
      borderRadius: "50%",
    },
    infoContainer: {
      display: "flex",
      marginTop: "16px",

      "& div:nth-of-type(3)": {
        marginLeft: "auto",
      },
    },
    vehicleContainerTag: {
      background: "#A5B4C1",
      color: "#FFF",
      width: "70px",
      borderRadius: "16px",
      padding: "0px 0px",
      fontSize: "12px",
      textAlign: "center",
      marginLeft: "10px",
    },
    styleStarRanking: {
      position: "relative",
      top: "3px",
      marginRight: "4px",
    },
    advancedSpeederStar: {
      color: theme.palette.primary.main
    }
  })
);
