import React from "react";
import type { FC } from "react";

import {
  Divider,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import theme from "../../../styles/theme";
import { SpeedyRankingType } from "../../../services/types/courier";

interface PopoverRankingProps {
  openPopover: boolean;
  anchorEl: HTMLElement | null;
  speedyRanking?: SpeedyRankingType | undefined;
  newRankingPoints?: number | undefined;
  requestsCount?: number | undefined;
  handlePopoverClose: (value: string) => void;
}

const PopoverRanking: FC<PopoverRankingProps> = ({
  openPopover,
  anchorEl,
  speedyRanking,
  newRankingPoints,
  requestsCount,
  handlePopoverClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Popover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={openPopover}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography className={classes.cardTitle} variant="h6">
        Ranking
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 10, marginTop: 10 }}>
        Nível:{" "}
        <strong>
          {speedyRanking ? t(`courier_type.${speedyRanking}`) : "---"}
        </strong>
      </Typography>

      <Divider />
      <Typography variant="body1" style={{ marginBottom: 10, marginTop: 10 }}>
        Quantidade de Pontos:{" "}
        <strong>{newRankingPoints ? newRankingPoints : "---"}</strong>
      </Typography>

      <Divider />
      <Typography variant="body1" style={{ marginBottom: 10, marginTop: 10 }}>
        Quantidade de Entregas:{" "}
        <strong>{requestsCount ? requestsCount : "---"}</strong>
      </Typography>
    </Popover>
  );
};

export default PopoverRanking;

const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
  },
  popover: {
    pointerEvents: "none",
  },
  cardTitle: {
    fontSize: "20px",
    marginBottom: "8px",
    fontWeight: "bold",
  },
});
