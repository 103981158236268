import { formatRFC3339 } from "date-fns";
import { apiAxios as axios } from "../store/api/config";
import { getURL, urls } from "../store/api/urls";
import { convertToBoolean, convertToDate, convertToNumber } from "../utils/utils";
import { IPageableParams, PagedResponse } from "./types/common";
import { GravityStatus, RegisterOccurrences } from "./types/occurrences";

export interface IOccurrencesService {

  getOccurrenceById: (id: number) => Promise<RegisterOccurrences>;

  getRegistersOccurrences: (
      courierId: number | null,
      gravity: GravityStatus | null,
      number: string | null,
      request_number: string | null,
      blocked: boolean | null,
      requested_time_after: Date | null,
      requested_time_before: Date | null,
      regions: number[],
      pageParams: IPageableParams
    ) => Promise<PagedResponse<RegisterOccurrences>>;

    AddRegisterOccurrence: (courierId: number, created_at: Date, gravity: GravityStatus, duration: number, description: string, deliveryId: number | null, customerId: number | null, reason: string, isBlockList: boolean) => Promise<any>;

    UpdateOccurrence: (occurrenceId: number, occurence_date: Date, gravity: GravityStatus, description: string, deliveryId: number | null) => Promise<any>;

};

const OccurrencesService: IOccurrencesService = {

    getOccurrenceById: (id: number) => {
      return new Promise((resolve, reject) => {
      axios.get(`${urls.OCCURENCES}${id}/`)
        .then((response) => {
          var deliveryRequest;
          if(response.data.delivery_request){
            deliveryRequest = {
              ...response.data.delivery_request, 
              id: convertToNumber(response.data.delivery_request.id),                  
              called_ifood: convertToBoolean(response.data.delivery_request.called_ifood),
              estimated_delivery_time: convertToDate(response.data.delivery_request.estimated_delivery_time),
              created_at: convertToDate(response.data.delivery_request.created_at),
            };
          }
          var blockList;
          if(response.data.blocklist_record){
            blockList = {
              ...response.data.blocklist_record, 
              id: convertToNumber(response.data.blocklist_record.id),                  
              all_customers: convertToBoolean(response.data.blocklist_record.all_customers),
              created_at: convertToDate(response.data.blocklist_record.created_at),
              duration: convertToNumber(response.data.blocklist_record.duration),
              expired: convertToBoolean(response.data.blocklist_record.expired),
              undefined_time: convertToBoolean(response.data.blocklist_record.undefined_time),
              updated_at: convertToDate(response.data.blocklist_record.updated_at),
            };
          }

          const result: RegisterOccurrences = {
            ...response.data,
            id: convertToNumber(response.data.id),
            created_at: convertToDate(response.data.created_at),
            occurence_date: convertToDate(response.data.occurence_date),
            delivery_request: deliveryRequest,
            courier: response.data.courier,
            blocklist_record: blockList, 
            updated_at: convertToDate(response.data.updated_at),

          };
          resolve(result);
        })
        .catch(error => reject(error));
      });
    },

    getRegistersOccurrences: (
        courierId: number | null,
        gravity: GravityStatus | null,
        number: string | null,
        request_number: string | null,
        blocked: boolean | null,
        requested_time_after: Date | null,
        requested_time_before: Date | null,
        regions: number[],
        pageParams: IPageableParams
      ) => {
        const url = getURL(`${urls.OCCURENCES}`, {
          ...pageParams,
          courier: courierId ? courierId : null,
          created_at_after: requested_time_after
              ? formatRFC3339(requested_time_after)
              : null,
          created_at_before: requested_time_before
              ? formatRFC3339(requested_time_before)
              : null,
          number: number ? number : null,
          gravity: gravity ? gravity : null,
          request_number: request_number ? request_number : null,
          had_block_record: blocked ? blocked : null,
          regions: regions
      });

      

        return new Promise((resolve, reject) => {
          axios.get(url).then((response) => {

            const resultFormat: RegisterOccurrences[] = response.data.results.map((item: any) => {            
              var deliveryRequest;
              if(item.delivery_request){
                deliveryRequest = {
                  ...item.delivery_request, 
                  id: convertToNumber(item.delivery_request.id),                  
                  called_ifood: convertToBoolean(item.delivery_request.called_ifood),
                  estimated_delivery_time: convertToDate(item.delivery_request.estimated_delivery_time),
                  created_at: convertToDate(item.delivery_request.created_at),
                };
              }
              var blockList;
              if(item.blockList){
                blockList = {
                  ...item.blockList, 
                  id: convertToNumber(item.blockList.id),                  
                  all_customers: convertToBoolean(item.blockList.all_customers),
                  created_at: convertToDate(item.blockList.created_at),
                  duration: convertToNumber(item.blockList.duration),
                  expired: convertToBoolean(item.blockList.expired),
                  undefined_time: convertToBoolean(item.blockList.undefined_time),
                  updated_at: convertToDate(item.blockList.updated_at),
                };
              }

              return {
                  ...item,
                  id: convertToNumber(item.id),
                  created_at: convertToDate(item.created_at),
                  occurence_date: convertToDate(item.occurence_date),
                  delivery_request: deliveryRequest,
                  courier: item.courier,
                  blocklist_record: blockList                 
              }
            });
            const result: PagedResponse<RegisterOccurrences> = {
              data: resultFormat,
              count: response.data.count,
            };
            resolve(result);
          }).catch((error) => reject(error));
        });
      },

      AddRegisterOccurrence: (courierId: number, created_at: Date, gravity: GravityStatus, duration: number, description: string, deliveryId: number | null, customerId: number | null = null, reason: string, isBlockList: boolean) => {
        const url = urls.OCCURENCES;
        const blocklistRecord = {
          customer: customerId,
          created_at: formatRFC3339(created_at),
          all_customers: (customerId === null),
          undefined_time: (duration === 0),
          duration: duration !== 0 ? duration : null,
          reason: reason
        }
        return axios.post(url, {
          occurence_date: formatRFC3339(created_at),
          courier: courierId,
          gravity: gravity,
          description: description,
          delivery_request: deliveryId,
          blocklist_record: isBlockList === true ? blocklistRecord : null,
        })
          .then(response => Promise.resolve(response.data))
          .catch(error => Promise.reject(error));
      },

      UpdateOccurrence: (occurrenceId: number, occurence_date: Date, gravity: GravityStatus, description: string, deliveryId: number | null) => {
        const url = `${urls.OCCURENCES}${occurrenceId}/`;
        return axios.patch(url, {
          occurence_date: occurence_date,
          gravity: gravity,
          description: description,
          delivery_request: deliveryId,
        })
          .then(response => Promise.resolve(response.data))
          .catch(error => Promise.reject(error));
      },
      

}

export default OccurrencesService;