import { RegionResponse } from "./region";

export enum IncentiveTimeMensage {
    IMMEDIATE = "IMMEDIATE",
    THIRTY_MINS_BEFORE  = "THIRTY_MINS_BEFORE",
    ONE_HOUR_BEFORE = "ONE_HOUR_BEFORE",
    TWO_HOURS_BEFORE = "TWO_HOURS_BEFORE"
}

export interface AnnouncementIncentive {
    announcement_type: IncentiveTimeMensage;
    canceled: boolean;
    content: string;
    couriers: [];
    delivery_for: string;
    expire_date: Date;
    id: number;
    only_active_couriers: boolean;
    only_active_regions: boolean;
    picture: string;
    region: RegionResponse;
    screen: string;
    send_date: Date;
    sent: boolean;
    title: string;
}

export interface IncentiveResponse {
    id: number;
    created_at?: string;
    updated_at?: string;
    start_date: string;
    end_date: string;
    speedy_value: string;
    customer_value: string;
    title: string;
    message: string;
    region: RegionResponse;
}

export interface IncentiveDetailResponse {
    id: number;
    is_active: boolean;
    start_date: Date;
    end_date: Date;
    created_at: Date;
    updated_at: Date;
    speedy_value: number;
    customer_value: number;
    title: string;
    message: string;
    region: RegionResponse;
    announcement: AnnouncementIncentive;
    send_message: boolean;
    send_message_pattern: IncentiveTimeMensage | null;
}

export interface CreateUpdateIncentive {
    start_date: Date | null;
    end_date: Date | null;
    speedy_value: number | null;
    customer_value: number | null;
    region: number | null;
    send_message: boolean;
    send_message_pattern: IncentiveTimeMensage | null;
}
