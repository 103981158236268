import React from "react";
import { Chip } from "@material-ui/core";

export interface ChipListItem {
  label: string;
  data?: any;
  className?: string;
  onClick?: (data: any) => void;
  onDelete?: (data: any) => void;
}

interface DashboardChipListProps {
  chipList?: ChipListItem[];
}

export const DashboardChipList: React.FC<DashboardChipListProps> = ({
  chipList
}) => {
  return (
    <React.Fragment>
      {chipList && (
        chipList.map((chip, index) => (
          <Chip
            key={index}
            className={chip.className}
            variant="outlined"
            label={chip.label}
            onClick={chip.onClick ? () => chip.onClick && chip.onClick(chip.data) : undefined}
            onDelete={chip.onDelete ? () => chip.onDelete && chip.onDelete(chip.data) : undefined}
          />
        ))
      )}
    </React.Fragment>
  );
};
