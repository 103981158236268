import React, { useCallback, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { DashboardTimeline } from "../../../components/DashboardTimeline";
import DeliveryRequestService from "../../../services/deliveryRequestService";
import { DeliveryRequest, DeliveryRequestLog } from "../../../services/types/deliveryRequest";

interface ITimelineProps {
  deliveryRequest: DeliveryRequest;
}

export const Timeline: React.FC<ITimelineProps> = ({ deliveryRequest }) => {
  const classes = useStyles();
  const [logList, setLogList] = React.useState<DeliveryRequestLog[]>([]);
  const loadLogs = useCallback(async (id: number) => {
    const response = await DeliveryRequestService.getDeliveryRequestLogs(id);
    setLogList(response);
  }, []);

  useEffect(() => {
    loadLogs(deliveryRequest.id);
  }, [deliveryRequest, loadLogs]);

  return (
    <div className={classes.root}>
      <DashboardTimeline logs={logList} />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  })
);
