import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FinancialEntryStatus } from "../../../services/types/financialEntry";
import { CustomerFinancialOperationType } from "../../../services/types/customer";
import { useFormik } from "formik";
import { CURRENT_DATE_FORMAT, DEFAULT_LOCALE } from "../../../i18n";
import DatePicker from "react-datepicker";

export interface ICustomerFinancialOperationsFilterParams {
  start_date: Date | null;
  end_date: Date | null;
  description: string;
  store_name: string;
  status: FinancialEntryStatus | null;
  type: CustomerFinancialOperationType | null;
}

export const INITIAL_FILTER_PARAMS: ICustomerFinancialOperationsFilterParams = {
  start_date: null,
  end_date: null,
  description: "",
  store_name: "",
  status: null,
  type: null,
};

interface ICustomerFinancialOperationsFilterProps {
  params?: ICustomerFinancialOperationsFilterParams;
  onFilterChanged: (params: ICustomerFinancialOperationsFilterParams) => void;
}

export const CustomerFinancialOperationsFilter: React.FC<ICustomerFinancialOperationsFilterProps> = ({
  params = INITIAL_FILTER_PARAMS,
  onFilterChanged
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: params,
    onSubmit: values => {
      onFilterChanged(values);
    },
    onReset: values => {
      onFilterChanged(values);
    },
  });

  return (
    <div className="page-filter">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container direction="row" spacing={2}>
          <Grid item md={2}>
            <TextField
              label="Descrição"
              id="description"
              name="description"
              onChange={formik.handleChange}
              fullWidth
              value={formik.values.description}
              variant="outlined"
            />
          </Grid>

          <Grid item md={2}>
            <TextField
              label="Loja"
              id="store_name"
              name="store_name"
              onChange={formik.handleChange}
              fullWidth
              value={formik.values.store_name}
              variant="outlined"
            />
          </Grid>

          <Grid item md={2}>
            <TextField
              id="status"
              name="status"
              label="Situação"
              select
              onChange={formik.handleChange}
              value={formik.values.status}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="">Todas</MenuItem>
              <MenuItem value={FinancialEntryStatus.CONFIRMED}>{t("financial_entry_status." + FinancialEntryStatus.CONFIRMED)}</MenuItem>
              <MenuItem value={FinancialEntryStatus.PROCESSING}>{t("financial_entry_status." + FinancialEntryStatus.PROCESSING)}</MenuItem>
              <MenuItem value={FinancialEntryStatus.PAID}>{t("financial_entry_status." + FinancialEntryStatus.PAID)}</MenuItem>
            </TextField>
          </Grid>

          <Grid item md={2}>
            <DatePicker
              id="start_date"
              name="start_date"
              onChange={val => {
                formik.setFieldValue("start_date", val);
              }}
              maxDate={new Date()}
              selected={formik.values.start_date}
              startDate={formik.values.start_date}
              endDate={formik.values.end_date}
              selectsStart
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_FORMAT}
              isClearable
              autoComplete="off"
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data Inicial"
                variant="outlined"
                size="small"
                error={!!formik.errors.start_date}
                helperText={formik.errors.start_date}
                fullWidth />}
            />
          </Grid>

          <Grid item md={2}>
            <DatePicker
              id="end_date"
              name="end_date"
              onChange={val => {
                formik.setFieldValue("end_date", val);
              }}
              maxDate={new Date()}
              selected={formik.values.end_date}
              startDate={formik.values.start_date}
              endDate={formik.values.end_date}
              minDate={formik.values.start_date}
              selectsEnd
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_FORMAT}
              isClearable
              autoComplete="off"
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data Final"
                variant="outlined"
                size="small"
                error={!!formik.errors.end_date}
                helperText={formik.errors.end_date}
                fullWidth />}
            />
          </Grid>

          <Grid item md={2}>
            <TextField
              id="type"
              name="type"
              label="Tipo do Lançamento"
              select
              onChange={formik.handleChange}
              value={formik.values.type}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="DEBIT">Débito</MenuItem>
              <MenuItem value="CREDIT">Crédito</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginTop: "10px" }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              Pesquisar
            </Button>
          </Grid>

          <Grid item>
            <Button variant="contained" color="secondary" type="reset">
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};