import React from "react";
import {
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { FormikHelpers, useFormik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { useHistory } from "react-router";
import { encodeURLParams } from "../../utils/utils";
import { Autocomplete } from "@material-ui/lab";
import { UserResponse, UserStatus } from "../../services/types/user";
import AuthService from "../../services/authService";

export interface ICommissionReportsFilterParams {
  customer: string;
  management_user: number | null;
};

export const INITIAL_FILTER_PARAMS: ICommissionReportsFilterParams = {
  customer: "",
  management_user: null,
};

interface ICommissionReportsFilterProps {
  params?: ICommissionReportsFilterParams;
  managementUsers: UserResponse[];
  onFilterChanged: (params: ICommissionReportsFilterParams) => void;
};

export const CommissionReportsFilter: React.FC<ICommissionReportsFilterProps> = ({ params = INITIAL_FILTER_PARAMS, managementUsers, onFilterChanged }) => {
  const history = useHistory();
  const [user, setUser] = React.useState<UserResponse>();
  const [viewForm, setViewForm] = React.useState<boolean>(true);

  React.useEffect(() => {
    history.replace(encodeURLParams(history.location.pathname, params, []));
  }, [history, params]);

  const formik = useFormik<ICommissionReportsFilterParams>({
    initialValues: params,
    onSubmit: (values: ICommissionReportsFilterParams, formikHelpers: FormikHelpers<ICommissionReportsFilterParams>) => {
      onFilterChanged(values);
    },
    onReset: (values: ICommissionReportsFilterParams, formikHelpers: FormikHelpers<ICommissionReportsFilterParams>) => {
      onFilterChanged(INITIAL_FILTER_PARAMS);
      formikHelpers.setValues(INITIAL_FILTER_PARAMS);
    }
  });

  React.useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      const filteredManagementUsers = managementUsers.filter((item) => item.id === currentUser.id);
      if (filteredManagementUsers.length > 0) {
        formik.setFieldValue("management_user", currentUser.id);
        setUser(filteredManagementUsers[0]);
        formik.handleSubmit();
      }
    }
    if (!AuthService.hasAuthorization("change_customer_management_user_to_any")) {
      setViewForm(false);
    }
  }, [managementUsers]);

  return (
    <div className="page-filter">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          {!viewForm ? (
            <Grid item md={3}>
              <TextField
                id="management_user"
                label="Usuário"
                value={user?.name}
                fullWidth
                disabled
                variant="outlined"
              />
            </Grid>
          ) : (
            <>
              <Grid item md={3}>
                <Autocomplete
                  id="management_user"
                  size="small"
                  value={user}
                  options={managementUsers}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setUser(newValue);
                      formik.setFieldValue("management_user", newValue.id);
                    } else {
                      setUser(undefined);
                      formik.setFieldValue("management_user", null);
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      name="management_user"
                      label="Usuário"
                      onChange={formik.handleChange}
                      value={formik.values.management_user}
                      variant="outlined"
                      fullWidth
                    />
                  }
                />
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  Pesquisar
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </div>
  );
};
