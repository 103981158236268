import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import {
    DATE_TIME_COLUMN_DEFAULTS,
    DEFAULT_TABLE_COMPONENTS,
    DEFAULT_TABLE_OPTIONS,
    PageableParams,
    TABLE_L10N_PTBR,
} from "../../components/TableTrinkets";
import AnnouncementsService from "../../services/announcementsService";
import MaterialTable, {
    MaterialTableProps,
    Query,
    QueryResult,
} from "@material-table/core";
import { AnnouncementResponse } from "../../services/types/announcement";
import { VisibilityOutlined } from "@material-ui/icons";
import {
    INITIAL_FILTER_PARAMS,
    IAnnouncemetFilterParams,
    AnnouncemetFilter,
} from "./FilterAnnouncement";
import ShowIf from "../../components/ShowIf";
import AuthService from "../../services/authService";
import { convertDeliveryForTypeToOption } from "./announcementUtils";
import { decodeURLParams, URLParamType } from "../../utils/utils";

interface IAnnouncementsProps { }

export const Announcement: React.FC<IAnnouncementsProps> = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [filterParams, setFilterParams] = useState<IAnnouncemetFilterParams>({
        ...INITIAL_FILTER_PARAMS,
        ...decodeURLParams(history.location.search, [
            { "send_date_after": URLParamType.DATE_TIME },
            { "send_date_before": URLParamType.DATE_TIME }
        ])
    });

    const tableRef = React.useRef<MaterialTableProps<AnnouncementResponse>>();

    const loadData = (query: Query<AnnouncementResponse>) =>
        new Promise<QueryResult<AnnouncementResponse>>((resolve, reject) => {
            AnnouncementsService.getAnnouncements(
                filterParams,
                PageableParams.fromQuery(query)
            ).then(response => {
                resolve({
                    data: response.data,
                    page: query.page,
                    totalCount: response.count,
                });
            });
        });

    const applyFilter = (params: IAnnouncemetFilterParams) => {
        setFilterParams(params);
        if (tableRef.current?.onQueryChange) {
            tableRef.current.onQueryChange({
                page: 0,
            } as Query<AnnouncementResponse>);
        }
    };

    const showDetails = async (item: AnnouncementResponse) => {
        history.push(`/announcement/detail/${item.id}`);
    };

    return (
        <div>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Avisos</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[
                                { label: "Avisos", url: "/announcement" },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <ShowIf condition={AuthService.hasAuthorization("add_announcement")}>
                        <Button color="primary" variant="contained"
                            component={Link} to={"/announcement/new"}
                            startIcon={<AddCircleOutlineRoundedIcon />}>
                            Novo Aviso
                        </Button>
                    </ShowIf>
                </Grid>
            </Grid>

            <AnnouncemetFilter params={filterParams} onFilterChanged={applyFilter} />

            <MaterialTable
                tableRef={tableRef}
                columns={[
                    { field: "id", hidden: true, width: 60 },
                    {
                        ...DATE_TIME_COLUMN_DEFAULTS,
                        field: "send_date",
                        title: "Data de Envio",
                    },
                    { field: "title", title: "Título" },
                    {
                        field: "delivery_for",
                        title: "Envio",
                        render: (rowData, type) =>
                            t(
                                "announcement_delivery_for_options."
                                + convertDeliveryForTypeToOption(rowData.delivery_for, rowData.only_active_couriers)
                            )
                    },
                    {
                        field: "screen",
                        title: "Local",
                        render: (rowData, type) =>
                            t("announcement_screen_type." + rowData.screen)
                    },
                ]}
                data={loadData}
                actions={[
                    {
                        icon: () => <VisibilityOutlined />,
                        tooltip: "Ver Detalhes",
                        onClick: (event, rowData) =>
                            showDetails(rowData as AnnouncementResponse)
                    },
                ]}
                components={DEFAULT_TABLE_COMPONENTS}
                localization={TABLE_L10N_PTBR}
                options={{
                    ...DEFAULT_TABLE_OPTIONS, 
                    draggable: false
                }}
            />
        </div>
    );
};
