import React, { useCallback, useEffect } from "react";
import {
  Grid,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
  TextField,
  MenuItem,
  makeStyles,
  Modal,
  Fade,
  Backdrop,
  debounce,
  CircularProgress,
} from "@material-ui/core";
//@ts-ignore
import { NotificationManager } from "react-notifications";

import { Occurrences } from "./SubPages/Occurrences";
//import { Search } from "./SubPages/Search";

import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { useParams } from "react-router-dom";
import DeliveryRequestService from "../../services/deliveryRequestService";
import { DeliveryRequest, DeliveryRequestAction, DeliveryRequestStatus, IntegrationSource, PendingDeliveryRequest, PendingTrip, Trip, TripStatus } from "../../services/types/deliveryRequest";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../components/ConfirmationDialog";
import ShowIf from "../../components/ShowIf";
import TripService from "../../services/tripService";
import { MapTrip } from "./SubPages/MapTrip";
import { OnlineCourierUserDetails } from "../../services/types/courier";
import { ArrowDownward } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import SelectCourierDialog from "../mainDashboard/customComponents/selectCourierDialog";
import { TimelineTrip } from "./SubPages/TimeLineTrip";
import { CalledTrip } from "./SubPages/CalledTrip";
import { AnnouncementTrip } from "./SubPages/announcementTrip";
import JoinDeliveriesDialog, { JoinDeliveriesDialogHandle } from "../mainDashboard/customComponents/joinDeliveriesOnTripDialog";
import { DashboardDeliveriesFilterParams, DELIVERIES_INITIAL_FILTER_PARAMS } from "../mainDashboard/views/dashboardDeliveriesFilter";
import { IPageableParams } from "../../store/ducks/requests";


const CustomTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      minWidth: 72,
      fontSize: "16px",
      fontWeight: 200,
      margin: "16px 60px 0 0",
      "&:hover": {
        color: "#4980FF",
        opacity: 1,
      },
      "&:focus": {
        color: "#4980FF",
        fontWeight: 700,
      },
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  tabValue: any;
}

interface IParams {
  tripId: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {tabValue === index && <Box>{children}</Box>}
    </div>
  );
}

interface StyledTabProps {
  label: string;
  disabled?: boolean;
}

interface SendDeliveryRequestAnnouncementForm {
  templateId: number | null;
  title: string;
  content: string;
}

export const RequestDetailsTrip: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tripId } = useParams<IParams>();
  const [tabValue, setTabValue] = React.useState(0);
  const [deliveryRequest, setDeliveryRequest] = React.useState<DeliveryRequest>();
  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingSetCourier, setLoadingSetCourier] = React.useState<boolean>(false);
  const [loadingDiscard, setLoadingDiscard] = React.useState<boolean>(false);
  const [trip, setTrip] = React.useState<Trip>({} as Trip);
  const [deliveryList, setDeliveryList] = React.useState<DeliveryRequest[]>();
  const [openCourierDialog, setOpenCourierDialog] = React.useState<boolean>(false);
  const [openIntervirTrip, setOpenIntervirTrip] = React.useState(false);
  const [actionIntervirTrip, setActionIntervirTrip] = React.useState<string>("");
  const [availableChoicesTrip, setAvailableChoicesTrip] = React.useState<DeliveryRequestAction[]>([]);
  const [isIFoodAvailable, setIFoodAvailable] = React.useState<boolean>(false);
  const [showResetTrip, setShowResetTrip] = React.useState(true);
  const joinDeliveriesDialog = React.createRef<JoinDeliveriesDialogHandle>();
  const [deliveriesFilterParams, setDeliveriesFilterParams] = React.useState<DashboardDeliveriesFilterParams>(DELIVERIES_INITIAL_FILTER_PARAMS);
  const [tripList, setTripList] = React.useState<PendingTrip[]>([]);
  const [noCourierList, setNoCourierList] = React.useState<PendingTrip[]>([]);
  const [deliveryRequestList, setDeliveryRequestList] = React.useState<PendingDeliveryRequest[]>([]);
  const [openJoinTrips, setOpenJoinTrips] = React.useState<boolean>(false);
  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);


  const loadData = useCallback(async (tripId: number) => {
    try {
      const trip = await TripService.getTripById(tripId);
      setTrip(trip);
      const promises = trip.deliveries.map((delivery) => {
        return DeliveryRequestService.getDeliveryRequest(delivery.id);
      });
      const deliveriesList = await Promise.all(promises);
      setDeliveryList(deliveriesList);
      if (deliveriesList) {
        const iFoodAvailableList = deliveriesList.filter((delivery) => delivery.metadata?.available);
        setIFoodAvailable(iFoodAvailableList.length > 0);
        setDeliveryRequest(deliveriesList[0]);

        deliveriesList.forEach((item) => {
          if (![DeliveryRequestStatus.NEW,
          DeliveryRequestStatus.NO_COURIER,
          DeliveryRequestStatus.ACCEPTED,
          DeliveryRequestStatus.AWAITING_RELEASE,
          DeliveryRequestStatus.AWAITING_COLLECTION,
          DeliveryRequestStatus.ON_WAY].includes(item.status)) {
            setShowResetTrip(false);
          }
        });
      }
    } catch (error) {
      alert(error);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (trip) {
        loadData(Number(tripId));
      }
    }, 20000);

    return function cleanup() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [tripId, trip, loadData]);


  const refreshData = useCallback(async () => {
    if (tripId) {
      loadData(Number(tripId));
    }
  }, [tripId, loadData]);

  useEffect(() => {
    setAvailableChoicesTrip(
      TripService.getAvailableTripResquestActions(trip.status, trip.has_return)
    );
  }, [trip]);

  const handleCancelTrip = () => {
    const dialog = confirmationDialog.current;
    dialog?.confirm(
      "Cancelamento da Viagem",
      <React.Fragment>
        <Typography>
          Você tem certeza que deseja <strong>Cancelar</strong> esta Entrega?
        </Typography>
      </React.Fragment>,
      "Cancelar"
    ).then(async () => {
      try {
        await cancelTrip();
        dialog?.hide();
      } catch (error) {
      }
    }).catch(() => { });
  }

  const cancelTrip = React.useMemo(() => debounce(async () => {
    return new Promise((resolve, reject) => {
      TripService.cancelTripById(Number(tripId))
        .then((response) => {
          loadData(Number(tripId));
          NotificationManager.success(
            "Viagem cancelada com sucesso.",
            "Cancelamento da Viagem"
          );
          resolve(true);
        }).catch((error) => {
          reject(error);
          NotificationManager.error(
            "Erro ao Cancelar a Viagem.",
            "Cancelamento da Viagem"
          );
        })
    });
  }, 500), [loadData, tripId]);

  useEffect(() => {
    loadData(Number(tripId));
  }, [loadData, tripId]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSearchCourierForTrip = async () => {
    let title = "Solicitar Entregador";
    let message = "Você tem certeza que deseja solicitar entregador?";
    let acceptButtonLabel = "Solicitar";
    const dialog = confirmationDialog.current;
    dialog?.confirm(title, message, acceptButtonLabel, "Cancelar")
      .then(async () => {
        try {
          setLoading(true);
          await requestCourier();
        } finally {
          dialog?.hide();
        }
      }).catch(() => { });
  };

  const requestCourier = React.useMemo(() => debounce(async () => {
    return new Promise<boolean>((resolve, reject) => {
      TripService.searchCourierForTrip(Number(tripId))
        .then((response) => {
          setTimeout(() => {
            refreshData();
            setLoading(false);
            resolve(true);
            NotificationManager.success(
              "Entregador solicitado com sucesso!",
              "Sucesso"
            );
          }, 200);
        })
        .catch((error) => {
          setLoading(false);
          reject(false);
          const { status, data } = error.response;
          if (status === 400) {
            let errors: string[] = [];
            for (var key in data) {
              var value = data[key];
              errors.push(value);
            }
            NotificationManager.error(
              errors.join(".\n"),
              "Error"
            );
          } else {
            NotificationManager.error(
              "Não foi possível solicitar entregador!",
              "Oops"
            );
          }
        });
    });
  }, 500), [refreshData, tripId]);

  const handleSetCourierForTrip = () => {
    if (trip && trip.region.id) {
      setOpenCourierDialog(true);
    }
  };

  const handleResetTrip = async () => {
    let title = "Reabrir Entrega(s)";
    let message = "Você tem certeza que deseja reabrir esta(s) entrega(s)?";
    let acceptButtonLabel = "Reabrir";
    if ([TripStatus.SEARCHING_COURIER].includes(trip.status)) {
      title = "Parar de Chamar Entregador";
      message = "Você tem certeza que deseja Parar de chamar Entregador para essa(s) entrega(s)?";
      acceptButtonLabel = "Parar";
    }
    const dialog = confirmationDialog.current;
    dialog?.confirm(title, message, acceptButtonLabel, "Cancelar")
      .then(async () => {
        try {
          await resetTrip();
        } finally {
          dialog?.hide();
        }
      }).catch(() => { });
  }

  const resetTrip = React.useMemo(() => debounce(async () => {
    let successMessage = "Entrega(s) reaberta(s) com sucesso!";
    let failedMessage = "Não foi possível reabrir a(s) entrega(s)!";
    if ([TripStatus.SEARCHING_COURIER].includes(trip.status)) {
      successMessage = "O Entregador parou de ser chamado com sucesso!";
      failedMessage = "Não foi possível parar de chamar o Entregador!";
    }
    try {
      await TripService.resetTripById(Number(tripId));
      setTimeout(() => {
        refreshData();
        setLoading(false);
        NotificationManager.success(
          successMessage,
          "Sucesso"
        );
      }, 200);
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        let errors: string[] = [];
        for (var key in data) {
          var value = data[key];
          errors.push(value);
        }
        NotificationManager.error(
          errors.join(".\n"),
          "Error"
        );
      } else {
        NotificationManager.error(
          failedMessage,
          "Oops"
        );
      }
    }
  }, 500), [refreshData, tripId, trip]);

  const handleReleaseTrip = () => {
    const dialog = confirmationDialog.current;
    dialog?.confirm(
      "Liberar Coleta",
      "Você tem certeza que deseja Liberar Coleta?",
      "Liberar Coleta",
      "Cancelar"
    ).then(async () => {
      try {
        setLoadingAction(true);
        const success = "Coleta liberada com sucesso!";
        const error = "Não foi possível liberar a coleta!";
        await intervirInTrip(Number(tripId), DeliveryRequestAction.RELEASE_ORDER, success, error);
      } catch (error) {
      } finally {
        dialog?.hide();
      }
    }).catch(() => { });
  };

  const handleFinishTrip = () => {
    let title = "Finalizar Viagem";
    let message = "Você tem certeza que deseja Finalizar esta Viagem? Esta ação não poderá ser desfeita.";
    let acceptButtonLabel = "Finalizar";
    const dialog = confirmationDialog.current;
    dialog?.confirm(title, message, acceptButtonLabel, "Cancelar")
      .then(async () => {
        try {
          setLoadingAction(true);
          const success = "Entrega(s) finalizada(s) com sucesso!";
          const error = "Não foi possível finalizar a(s) entrega(s)!";
          await intervirInTrip(Number(tripId), DeliveryRequestAction.FINISHED, success, error);
        } finally {
          dialog?.hide();
        }
      }).catch(() => { });
  };

  const intervirInTrip = React.useMemo(() => debounce(async (tripId: number, action: DeliveryRequestAction, messageSuccess: string, messageFail: string) => {
    return new Promise<boolean>((resolve, reject) => {
      TripService.interveneInTrip(Number(tripId), action)
        .then((response) => {
          loadData(Number(tripId));
          resolve(true);
          NotificationManager.success(
            messageSuccess,
            "Sucesso"
          );
          setLoadingAction(false);
        })
        .catch((error) => {
          reject(false);
          const { status, data } = error.response;
          if (status === 400) {
            let errors: string[] = [];
            for (var key in data) {
              var value = data[key];
              errors.push(value);
            }
            NotificationManager.error(
              errors.join(".\n"),
              "Error"
            );
          } else {
            NotificationManager.error(
              messageFail,
              "Oops"
            );
          }
          setLoadingAction(false);
        });
    });
  }, 500), [loadData]);

  const handleOpenIntervirTrip = async () => {
    setOpenIntervirTrip(true);
  };

  const handleCloseModalTrip = () => {
    setOpenIntervirTrip(false);
  };

  const interverTrip = React.useMemo(() => debounce(async () => {
    if (actionIntervirTrip && actionIntervirTrip !== "") {
      try {
        switch (actionIntervirTrip) {
          case DeliveryRequestAction.CHECK_IN:
          case DeliveryRequestAction.RELEASE_ORDER:
          case DeliveryRequestAction.CHECK_OUT:
          case DeliveryRequestAction.REGISTER_CHECK_IN_BACK:
          case DeliveryRequestAction.FINISHED:
            await TripService.interveneInTrip(Number(tripId), actionIntervirTrip);
            break;
        }
        loadData(Number(tripId));
        NotificationManager.success(
          "Intervenção criada com sucesso",
          "Nova Intervenção"
        );
      } catch (error) {
        let errorMsg = "Ocorreu um erro ao cadastrar a intervenção";
        /* const errorKeys = Object.keys(error.response.data);
        if (errorKeys.length) {
          errorMsg = error.response.data[errorKeys[0]];
        }
 */
        NotificationManager.error(errorMsg, "Nova Intervenção");
      } finally {
        handleCloseModalTrip();
      }
    }
  }, 500), [loadData, actionIntervirTrip, tripId]);

  const handleCourierSelected = (courier: OnlineCourierUserDetails) => {
    return new Promise<void>(async (resolve, reject) => {
      if (courier) {
        setLoadingSetCourier(true);
        try {
          await TripService.searchCourierForTrip(Number(tripId), courier.id);
          NotificationManager.success(
            "Entregador solicitado com sucesso!",
            "Sucesso"
          );
          loadData(Number(tripId));
          setOpenCourierDialog(false);
          resolve();
        } catch (error) {
          const { status, data } = error.response;
          if (status === 400) {
            let errors: string[] = [];
            for (var key in data) {
              var value = data[key];
              errors.push(value);
            }
            NotificationManager.error(
              errors.join(".\n"),
              "Error"
            );
          } else {
            NotificationManager.error(
              "Não foi possível solicitar entregador!",
              "Oops"
            );
          }
          reject();
        } finally {
          setLoadingSetCourier(false);
        }
      } else {
        reject();
      }
    });
  };

  const openDiscardConfirmationDialog = () => {
    const dialog = confirmationDialog.current;
    dialog?.confirm(
      "Descartar Viagem",
      "Você tem certeza que deseja Descartar esta Viagem? Esta ação não poderá ser desfeita.",
      "Descartar",
      "Cancelar"
    ).then(async () => {
      try {
        setLoadingDiscard(true);
        await discardTripRequest(Number(tripId));
        dialog.hide();
      } catch (error) {
      } finally {
        setLoadingDiscard(false);
      }
    }).catch(() => { });
  };

  const discardTripRequest = React.useMemo(() => debounce(async (tripRequestId: number) => {
    return new Promise<boolean>((resolve, reject) => {
      TripService.discardTrip(tripRequestId)
        .then((response) => {
          loadData(tripRequestId)
          resolve(true);
          NotificationManager.success(
            "Viagem descartada com sucesso!",
            "Sucesso"
          );
        })
        .catch((error) => {
          reject(false);
          const { status, data } = error.response;
          if (status === 400) {
            let errors: string[] = [];
            for (var key in data) {
              var value = data[key];
              errors.push(value);
            }
            NotificationManager.error(
              errors.join(".\n"),
              "Error"
            );
          } else {
            NotificationManager.error(
              "Não foi possível descartar a viagem!",
              "Oops"
            );
          }
        });
    })
  }, 500), [loadData]);

  const canCallIFood = (): boolean => {
    let result = false;

    if (trip && !trip.called_ifood && isIFoodAvailable) {
      if ([TripStatus.NEW, TripStatus.NO_COURIER].includes(trip.status)) {
        if (deliveryList && (deliveryList.length === 1)
          && (deliveryList[0].integration_instance.integration_source === IntegrationSource.IFOOD)) {
          result = true;
        }
      }
    }

    return result;
  };

  const refreshPendingTripRequests = (
    regionsIds: number[],
    storesIds: number[],
    statusTrip: TripStatus | null,
    pageParams: IPageableParams
  ) => {
    TripService.getTrips(
      regionsIds,
      storesIds,
      statusTrip,
      pageParams
    )
      .then((response) => {
        setTripList(response.data);
        refreshDeliveries();
      })
      .catch((error) => { });
  };

  const refreshPendingDeliveryRequests = (
    regionsIds: number[],
    storesIds: number[],
    retrieveNew: boolean | null,
    pageParams: IPageableParams
  ) => {
    DeliveryRequestService.getPendingDeliveryRequests(
      regionsIds,
      storesIds,
      [DeliveryRequestStatus.NEW],
      retrieveNew,
      pageParams
    )
      .then((response) => {
        setDeliveryRequestList(response.data);
        setOpenJoinTrips(true);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    const storedFilter = localStorage.getItem("dashboardDeliveries:filter");
    if (storedFilter) {
      const params: DashboardDeliveriesFilterParams = JSON.parse(storedFilter);
      if (params.regionsIds) {
        if (!params.storesIds) {
          params.storesIds = [];
        }
        setDeliveriesFilterParams(params);
      }
    }
  }, []);

  const refreshTrips = () => {
    if (deliveriesFilterParams.regionsIds?.length > 0) {
      const TRIP_DEFAULT_PAGINATION: IPageableParams = {
        page: 1,
        page_size: 200,
        ordering: "created_at",
      };
      refreshPendingTripRequests(deliveriesFilterParams.regionsIds, [], null, TRIP_DEFAULT_PAGINATION);
    } else {
      setTripList([]);
    }
  };

  const refreshDeliveries = () => {
    const DEFAULT_PAGINATION: IPageableParams = {
      page: 1,
      page_size: 200,
      ordering: "estimated_delivery_time"
    };
    if (deliveriesFilterParams.regionsIds?.length > 0) {
      refreshPendingDeliveryRequests(deliveriesFilterParams.regionsIds, [], true, DEFAULT_PAGINATION);
    } else {
      setDeliveryRequestList([]);
    }
  };

  React.useEffect(() => {
    const _noCourierList: PendingTrip[] = [];
    tripList.forEach(trip => {
      switch (trip.status) {
        case TripStatus.NEW:
        case TripStatus.NO_COURIER:
          _noCourierList.push(trip);
          break;
      }
    });
    setNoCourierList(_noCourierList);
  }, [tripList]);

  const handleJoinTrips = useCallback(() => {
    const availableDeliveries: PendingDeliveryRequest[] = deliveryRequestList.filter((delivery) => {
      return (delivery.store.id === trip.store.id);
    });

    const availableTrips: PendingTrip[] = [];
    noCourierList.forEach((noCouriertrip) => {
      if ([TripStatus.NEW, TripStatus.NO_COURIER].includes(noCouriertrip.status)
        && (noCouriertrip.store.id === trip.store.id)) {
        availableTrips.push(noCouriertrip);
      }
    });

    joinDeliveriesDialog.current?.addToTrip(trip, availableDeliveries, availableTrips);
  }, [deliveryRequestList, noCourierList, trip, joinDeliveriesDialog]);

  React.useEffect(() => {
    if (openJoinTrips) {
      handleJoinTrips();
    }
  }, [openJoinTrips, handleJoinTrips]);

  const callIFood = () => {
    confirmationDialog.current?.confirm(
      "Chamar Entregador do iFood",
      <React.Fragment>
        <Typography>
          Você tem certeza que deseja <strong>Chamar um Entregador do iFood</strong>?
        </Typography>
        <Typography>
          Obs.: Não será possível reverter esta ação.
        </Typography>
      </React.Fragment>,
      "Chamar"
    ).then(() => {
      const requestId = trip.deliveries[0].id;
      DeliveryRequestService.lookForIFoodCourier(requestId)
        .then((response) => {
          loadData(Number(tripId));
          confirmationDialog.current?.hide();
          NotificationManager.success(
            "Entregador do iFood chamado com sucesso.",
            "Chamar Entregador do iFood"
          );
        }).catch((error) => {
          NotificationManager.error(
            "Não foi possível chamar Entregador do iFood.",
            "Chamar Entregador do iFood"
          );
        });
    }).catch(() => { });
  };

  const refreshSuccess = () => {
    if (tripId) {
      loadData(Number(tripId));
      setOpenJoinTrips(false);
    }
  };

  const onClose = () => {
    setOpenJoinTrips(false);
  };

  const isPossibleCancel = () => {
    let isVisibleCancel: boolean = true;
    if(trip.deliveries){
        //Corridas que não podem cancelar
        let filterDeliveries = trip.deliveries.filter(deliverie => [
            DeliveryRequestStatus.RETURNING,
            DeliveryRequestStatus.BACK_TO_STORE,
            DeliveryRequestStatus.CANCELED, 
            DeliveryRequestStatus.DISCARDED, 
            DeliveryRequestStatus.FINISHED].includes(deliverie.status)
            );

        //Verifica quantidade de corridas disponíveis  
        if(filterDeliveries.length === trip.deliveries.length){
            isVisibleCancel = false;
        };
    }
    return isVisibleCancel;
  };

  return (
    <div>
      <ConfirmationDialog ref={confirmationDialog} />

      <JoinDeliveriesDialog ref={joinDeliveriesDialog} onSuccess={refreshSuccess} onClose={onClose} />

      <Grid container justify="space-between" className="page-title">
        <Grid item>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Typography variant="h1">Detalhes da Viagem - #{trip.number}</Typography>
            </Grid>

            <Grid item>
              <CustomBreadcrumbs
                pathList={[
                  { label: "Solicitações", url: "/requests" },
                  { label: "Detalhes da Viagem", url: `/requests/trip/${tripId}` },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{width: "100%"}}>
          <Grid container spacing={1} style={{justifyContent: "end"}}>
            <ShowIf condition={[
              TripStatus.NEW,
              TripStatus.NO_COURIER,
              TripStatus.ACCEPTED,
              TripStatus.AWAITING_RELEASE,
              TripStatus.AWAITING_COLLECTION].includes(trip.status)}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={refreshTrips}
                >
                 {`${[TripStatus.NEW,TripStatus.NO_COURIER].includes(trip.status) ? "Juntar Entregas" : "Adicionar Entrega"}`} 
                </Button>
              </Grid>
            </ShowIf>

            <ShowIf condition={canCallIFood()}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loading || loadingSetCourier || loadingDiscard}
                  onClick={callIFood}
                >
                  {loading ? (
                    <CircularProgress size="1.7rem" color="inherit" />
                  ) : (
                    "Chamar Entregador do iFood"
                  )}

                </Button>
              </Grid>
            </ShowIf>
            {[TripStatus.NEW, TripStatus.NO_COURIER].includes(trip.status) && (
              <React.Fragment>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={loading || loadingSetCourier || loadingDiscard}
                    onClick={handleSearchCourierForTrip}
                  >
                    {loading ? (
                      <CircularProgress size="1.7rem" color="inherit" />
                    ) : (
                      "Solicitar Entregador"
                    )}

                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={loading || loadingSetCourier || loadingDiscard}
                    onClick={handleSetCourierForTrip}
                  >
                    {loadingSetCourier ? (
                      <CircularProgress size="1.7rem" color="inherit" />
                    ) : (
                      "Direcionar para Entregador"
                    )}

                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={loading || loadingSetCourier || loadingDiscard}
                    onClick={openDiscardConfirmationDialog}
                  >
                    {loadingDiscard ? (
                      <CircularProgress size="1.7rem" color="inherit" />
                    ) : (
                      "Descartar Viagem"
                    )}
                  </Button>
                </Grid>
              </React.Fragment>
            )}
            <ShowIf condition={[
              TripStatus.SEARCHING_COURIER,
              TripStatus.ACCEPTED,
              TripStatus.AWAITING_RELEASE,
              TripStatus.AWAITING_COLLECTION,
              TripStatus.ON_WAY].includes(trip.status)}>
              <ShowIf condition={showResetTrip}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleResetTrip}
                  >
                    {[TripStatus.SEARCHING_COURIER].includes(trip.status) ? "Parar de chamar Entregador" : "Reiniciar Viagem"}
                  </Button>
                </Grid>
              </ShowIf>
            </ShowIf>
            {![
              TripStatus.NEW,
              TripStatus.NO_COURIER,
              TripStatus.SEARCHING_COURIER,
              TripStatus.FINISHED,
              TripStatus.CANCELED,
              TripStatus.PARTIALLY_CANCELED,
              TripStatus.DISCARDED
            ].includes(trip.status) && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleOpenIntervirTrip}
                  >
                    Intervenção
                  </Button>
                </Grid>
              )}
            {[TripStatus.AWAITING_RELEASE].includes(trip.status) && (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loadingAction}
                  onClick={handleReleaseTrip}
                >
                  {loadingAction ? (
                    <CircularProgress size="1.7rem" color="inherit" />
                  ) : (
                    "Liberar Coleta"
                  )}
                </Button>
              </Grid>
            )}
            {!trip.canceled && (
              ![TripStatus.NEW,
                TripStatus.NO_COURIER,
                TripStatus.SEARCHING_COURIER,
                TripStatus.CANCELED,
                TripStatus.FINISHED,
                TripStatus.DISCARDED].includes(trip.status) && (
                    <Grid item>
                      <Button
                        onClick={handleFinishTrip}
                        variant="contained"
                        color="secondary"
                        disabled={loadingAction}
                      >
                        {loadingAction ? (
                          <CircularProgress size="1.7rem" color="inherit" />
                        ) : (
                          "Finalizar Entrega(s)"
                        )}
                      </Button>
                    </Grid>
                )
            )}
            
            {isPossibleCancel() && (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCancelTrip}
                >
                  Cancelar Entrega(s)
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item style={{ width: "100%" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            style={{
              marginBottom: "16px",
              borderBottom: "1px solid #526272",
            }}
          >
            <CustomTab label="Mapa" />
            <CustomTab label="Linha do Tempo" />
            <CustomTab label="Chamados" />
            <CustomTab label="Avisos" />
            {/*isActive && (<CustomTab label="Busca" />)*/}
            {/* <CustomTab label="Ocorrências" disabled/> */}
          </Tabs>
        </Grid>
      </Grid>
      {deliveryRequest && (
        <React.Fragment>
          <TabPanel tabValue={tabValue} index={0}>
            <MapTrip trip={trip} deliveryList={deliveryList} isActive={false} refreshData={refreshData} />
          </TabPanel>
          <TabPanel tabValue={tabValue} index={1}>
            <TimelineTrip trip={trip} refreshData={refreshData} />
          </TabPanel>
          <TabPanel tabValue={tabValue} index={2}>
            <CalledTrip trip={trip} deliveryList={deliveryList} />
          </TabPanel>
          <TabPanel tabValue={tabValue} index={3}>
            <AnnouncementTrip trip={trip} />
          </TabPanel>
        </React.Fragment>
      )}
      {/*deliveryRequest && isActive && (
        <TabPanel tabValue={tabValue} index={3}>
          <Search deliveryRequest={deliveryRequest} />
        </TabPanel>
      )*/}

      <SelectCourierDialog
        open={openCourierDialog}
        title="Direcionar para Entregador"
        region={trip.region}
        referenceStore={trip.store}
        onCloseRequested={() => setOpenCourierDialog(false)}
        selectedCourier={handleCourierSelected}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openIntervirTrip}
        onClose={handleCloseModalTrip}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openIntervirTrip}>
          <div className={classes.paper}>
            <form>
              <Typography variant="h1">Intervenção</Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#EF4545",
                  maxWidth: 630,
                  padding: "10px 0",
                }}
              >
                A intervenção manual no fluxo da entrega só deverá ser
                utilizada em casos excepcionais onde o estabelecimento e/ou
                o entregador não atualizaram o status. Esta ação ficará
                registrada.
              </Typography>
              <Grid item md={12} className={classes.formActions}>
                <ArrowDownward
                  style={{
                    alignSelf: "center",
                    fontSize: "2rem",
                    marginTop: "1rem",
                  }}
                />
                <Grid item md={12} className={classes.textField}>
                  <TextField
                    id="action"
                    name="action"
                    label="Ação a ser realizada"
                    variant="outlined"
                    select
                    value={actionIntervirTrip || ""}
                    onChange={(e) => {
                      if (e.target.value) {
                        setActionIntervirTrip(e.target.value);
                      }
                    }}
                    fullWidth
                  >
                    <MenuItem value="" disabled>Selecione</MenuItem>
                    {availableChoicesTrip.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {t("delivery_request_action." + item)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item md={12} className={classes.buttonWrapper}>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="button"
                    className={classes.button}
                    onClick={handleCloseModalTrip}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={actionIntervirTrip === ""}
                    onClick={interverTrip}
                    className={classes.button}
                  >
                    Intervir
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>

      <TabPanel tabValue={tabValue} index={4}>
        <Occurrences />
      </TabPanel>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      position: "absolute",
    },
    button: {
      marginTop: "30px",
      marginLeft: "20px",
      minWidth: "150px",
      padding: "8px 30px",
      textTransform: "capitalize",
      borderRadius: "4px",
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "100%",
    },
    textField: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: "#fff",
      borderRadius: "4px",
      width: "100%",
      "& .MuiFormLabel-root": {
        color: theme.palette.primary.light,
      },
      "& label.Mui-focused": {
        color: theme.palette.primary.main,
      },
      "& .MuiInput-underline": {
        "&:before": {
          borderColor: theme.palette.primary.light,
        },
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "none",
      outline: "none",
      borderRadius: "4px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4, 4, 3),
    },
    formActions: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "strech",
    }
  })
);
