import { UserStatus } from "../../services/types/user";

//a
export interface IUser {
  id?: number;
  email: string;
  user_status: UserStatus;
  user_type?: string;
  permissions?: string[];
  name?: string;
  first_name: string;
  last_name: string;
  registration_number: string;
  phonenumber?: string;
  groups: Array<number>;
  state: string;
  street: string;
  city: string;
  postal_code: string;
  district: string;
  address_number: string;
  address_complement: string;
}

export interface IGroup {
  id: number;
  name: string;
}

export const EMPTY_USER = {
  first_name: "",
  last_name: "",
  user_status: UserStatus.WAITING_ACTIVATION,
  email: "",
  registration_number: "",
  phonenumber: "",
  groups: new Array<number>(),
  state: "",
  street: "",
  city: "",
  postal_code: "",
  district: "",
  address_number: "",
  address_complement: "",
};
