import React, { useEffect, useState } from "react";
import { Actions as customerActions } from "../../../store/ducks/customer";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  Card,
  Grid,
} from "@material-ui/core";
import { CommercialPlanResponse } from "../../../services/types/commercialPlan";
import { CustomerDetails } from "../../../services/types/customer";
import UserService from "../../../services/userService";
import { formatPhoneNumber } from "../../../utils/formatters";

interface ICustomerDetailCardProps {
  customer: CustomerDetails;
}

export interface ICommercialPlanFilterParams {
  name: string;
  region: string;
}

export const CustomerDetailPlan: React.FC<ICustomerDetailCardProps> = ({ customer }) => {
  const [plan, setPlan] = useState<CommercialPlanResponse>();
  const [managementUser, setManagementUser] = useState<any>();

  useEffect(() => {
    const getPlan = () => {
      customerActions.getPlan(customer.commercial_plan).then((plan) => {
        setPlan(plan);
      })
        .catch(() => { })
    };

    const getManagementUser = () => {
      if(customer.management_user){
        UserService.getUser(customer.management_user).then((users) => {
          if (users) {
            setManagementUser(users);
          }
        }).catch(() => { });
      }
    };

    if (customer.commercial_plan) {
      getPlan();
    }   
    getManagementUser();   
  }, [customer]);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h2">
              Comercial
            </Typography>
            <Table className="table-bordered" >
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>Plano</Typography>
                    <Typography variant="subtitle1">{plan?.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Região</Typography>
                    <Typography variant="subtitle1">{plan?.region?.name || "---"}</Typography>
                  </TableCell>
                </TableRow>
                {managementUser && (
                  <TableRow>
                    <TableCell>
                      <Typography>Agente Comercial</Typography>
                      <Typography variant="subtitle1">{managementUser?.first_name} {managementUser?.last_name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Telefone</Typography>
                      <Typography variant="subtitle1">{formatPhoneNumber(managementUser?.phonenumber)}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
};