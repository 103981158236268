import React, { useState } from "react";
import { useFormik } from "formik";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { CurrencyInput } from "../../../components/CurrencyInput";
import { getNumberSpecs } from "../../../i18n";
import { convertToNumber } from "../../../utils/utils";
import RemittanceService from "../../../services/remittanceService";
import numbro from "numbro";


interface AntecipateRemittanceForm {
    anticipation_fee: number;

}

const INITIAL_FORM_VALUES: AntecipateRemittanceForm = {
    anticipation_fee: 0,
}


interface AntecipateRemittanceDialogProps {
  courierId: number;
  onAccept?: () => Promise<void>;
}

export interface AntecipateRemittanceDialogHandle {
  open: () => void;
  close: () => void;
}

const validationSchema = Yup.object().shape({
    anticipation_fee: Yup.number()
    .required("Campo obrigatório")
    .transform((currentValue, originalValue) => convertToNumber(originalValue))
    .moreThan(0, "Campo obrigatório").nullable()
    
});

export const AntecipateRemittanceDialog: React.ForwardRefRenderFunction<AntecipateRemittanceDialogHandle, AntecipateRemittanceDialogProps> = (props, ref) => {
  const [open, setOpen] = useState<boolean>(false);

  React.useImperativeHandle(ref, () => {
    return ({
      open() {
        show();
      },

      close() {
        hide();
      }
    });
  });

  const hide = () => {
    setOpen(false);
    formik.resetForm();
  }

  const show = () => {
    setOpen(true);
  }

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: async (values) => {
      try {
          if(values.anticipation_fee !== null){
            await RemittanceService.singleRemittance(
                [props.courierId],
                convertToNumber(values.anticipation_fee) || 0
              );
              if (props.onAccept) {
                await props.onAccept();
              }
              hide();
              NotificationManager.success(
                "Adiantamento realizado com sucesso!",
                "Adiantamento"
              );
          }else{
            formik.setFieldError("anticipation_fee", "Necessário informar Taxa de Adiantamento.");
          }
       
      } catch (error) {
        let errorMsg = "Não foi possível realizar o Adiantamento!";        
        if (error) {
            const errorItems = error.response.data;
            const errorMsgs: string[] = [];
            for (var key in errorItems) {
                var value = errorItems[key];
                formik.setFieldError(key, value);
                errorMsgs.push(value);
            }
            if (errorMsgs.length > 0) {
                errorMsg = errorMsgs.join(". \n");
            }
        }
        NotificationManager.error(
            errorMsg,
            "Adiantamento"
        );
      }
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Dialog
      open={open}
      onClose={hide}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="form-dialog-title">Adiantamento</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="anticipation_fee"
                label="Taxa de Adiantamento"
                variant="outlined"
                required={true}
                onChange={formik.handleChange}
                value={formik.values.anticipation_fee}
                error={!!formik.errors.anticipation_fee}
                helperText={formik.errors.anticipation_fee}
                fullWidth
                InputProps={{
                  inputComponent: CurrencyInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      {getNumberSpecs().currency.symbol}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 10
                }}
              />
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    Esta operação irá deduzir <b>{numbro(formik.values.anticipation_fee || 0).formatCurrency()}</b> da carteira do entregador e irá gerar uma remessa com os valores restantes. Tem certeza que deseja continuar?
                </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide}>
            Cancelar
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={formik.isSubmitting || !formik.values.anticipation_fee || convertToNumber(formik.values.anticipation_fee) === 0}
            startIcon={formik.isSubmitting && (
              <CircularProgress size="1.5rem" color="inherit" />
            )}
          >
            Adiantar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default React.forwardRef(AntecipateRemittanceDialog);
