import React, { useEffect } from "react";
import { useFormik } from "formik";
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Card,
  Box,
  CardContent,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";

import { Actions as authActions } from "../../store/ducks/auth";
import { useSelector, useDispatch } from "react-redux";
import { IGlobalState } from "../../store/types/state";
import { LoadingComponent } from "../../components/Loading";
import * as Yup from "yup";
import { useHistory } from "react-router";

interface IChangePasswordProps { }

const validationSchema = Yup.object().shape({
  old_password: Yup.string()
    .min(6, "Mínimo de 6 caracteres")
    .required("Campo obrigatório"),
  new_password1: Yup.string()
    .min(6, "Mínimo de 6 caracteres")
    .max(50, "Senha muito longa")
    .required("Campo obrigatório"),
  new_password2: Yup.string()
    .oneOf([Yup.ref("new_password1"), ""], "As senhas não conferem")
    .required("Campo obrigatório"),
});
export const ChangePassword: React.FC<IChangePasswordProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { error, status } = useSelector(
    (state: IGlobalState) => state.auth
  );

  useEffect(() => {
    if (status === "changed")
      setTimeout(() => history.push({ pathname: "/" }), 5000);
  }, [history, status]);

  const formik = useFormik({
    initialValues: { old_password: "", new_password1: "", new_password2: "" },
    onSubmit: async (credentials: {
      old_password: string;
      new_password1: string;
      new_password2: string;
    }) => {
      await authActions.changePassword(dispatch, credentials);
    },
    validationSchema,
  });

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
      spacing={5}
    >
      <Grid container>
        <Typography variant="h1">Alterar senha</Typography>
        <CustomBreadcrumbs
          pathList={[{ label: "Meu perfil", url: "/manager/profile/edit" }]}
        />
      </Grid>
      <Grid item>
        <Card className={classes.card}>
          <Box p={1} pt={5}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} md={12} className={classes.alert}>
                {error && (
                  <Alert severity="error" variant="filled">
                    {error}
                  </Alert>
                )}
                {status === "changed" && (
                  <Alert severity="success" variant="filled">
                    Sua senha foi alterada com sucesso
                  </Alert>
                )}
              </Grid>
            </Grid>
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Typography variant="h3">Altere sua senha.</Typography>
                <TextField
                  id="old_password"
                  label="Senha atual"
                  variant="outlined"

                  type="password"
                  value={formik.values.old_password}
                  onChange={formik.handleChange}
                  error={!!formik.errors.old_password}
                  helperText={formik.errors.old_password}
                  className={classes.textField}
                  fullWidth
                />
                <TextField
                  id="new_password1"
                  label="Nova senha"
                  variant="outlined"

                  type="password"
                  value={formik.values.new_password1}
                  onChange={formik.handleChange}
                  error={!!formik.errors.new_password1}
                  helperText={formik.errors.new_password1}
                  className={classes.textField}
                  fullWidth
                />
                <TextField
                  id="new_password2"
                  label="Confirmar nova senha"
                  variant="outlined"

                  type="password"
                  value={formik.values.new_password2}
                  onChange={formik.handleChange}
                  error={!!formik.errors.new_password2}
                  helperText={formik.errors.new_password2}
                  className={classes.textField}
                  fullWidth
                />
                <Button variant="contained" color="primary" type="submit"
                  disabled={formik.isSubmitting}>
                  {formik.isSubmitting ? (
                    <LoadingComponent width="30px" />
                  ) : (
                      <span>Salvar</span>
                    )}
                </Button>
              </form>
            </CardContent>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: 36,
    },
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    card: {
      minWidth: 450,
    },
    button: {
      width: 1,
    },
    alert: {
      width: "94%",
    },
  })
);
