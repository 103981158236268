import React, { useState } from "react";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AccessTime, CallSplit, CompareArrowsOutlined, SportsMotorsports } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import theme from "../../../styles/theme";
import { format, isPast } from "date-fns";
import { TripDeliveryRequest, PendingTrip, IntegrationSource, DeliveryRequestStatus } from "../../../services/types/deliveryRequest";
import ShowIf from "../../../components/ShowIf";
import { CURRENT_DATE_TIME_FORMAT } from "../../../i18n";
import HighlightText from "../../../components/HighlightText";
import numbro from "numbro";
import clsx from "clsx";

interface ITripCardItemProps {
  trip: PendingTrip;
  deliveryRequest: TripDeliveryRequest;
  highlight: string;
  action?: (trip: PendingTrip) => {};
  splitTripAction?: (trip: PendingTrip, delivery: TripDeliveryRequest) => void;
  showDeliveryStatus?: boolean;
}

export const TripCardItem: React.FC<ITripCardItemProps> = ({
  trip,
  deliveryRequest,
  highlight,
  action,
  splitTripAction,
  showDeliveryStatus = true,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const anchorRef = React.useRef(null);
  const anchorReturnRef = React.useRef(null);

  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [arrowReturnRef, setArrowReturnRef] = React.useState<HTMLElement | null>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [openPopoverReturn, setOpenPopoverReturn] = useState<boolean>(false);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  const handlePopoverReturnOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setOpenPopoverReturn(true);
  };

  const handlePopoverReturnClose = () => {
    setOpenPopoverReturn(false);
  };

  const getIntegrationIcon = (integration_source: IntegrationSource | null): React.ReactNode => {
    let result = <img src="/icons/speedy.png" title="Speedy" alt="Speedy" />;

    if (integration_source) {
      switch (integration_source) {
        case IntegrationSource.IFOOD:
          result = <img src="/icons/ifood.png" title="iFood" alt="iFood" />
          break;
        case IntegrationSource.MEU_XODO:
          result = <img src="/icons/meu_xodo.jpg" title="Meu Xodó" alt="Meu Xodó" />
          break;
        case IntegrationSource.DELIVERY_DIRETO:
          result = <img src="/icons/delivery_direto.png" title="Delivery Direto" alt="Delivery Direto" />
          break;
        case IntegrationSource.NEEMO:
            result = <img src="/icons/neemo.png" title="Neemo" alt="Neemo" />
            break;     
        default:
          break;
      }
    }

    return result;
  };

  const determineClassNameItem = (): string => {
    let className = "";
    if (isPast(new Date(deliveryRequest.estimated_delivery_time))) {
      className = classes.cardItemNoTime;
    }   
    return className;
  };

  return (
    <React.Fragment>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="top"
        className={classes.popper}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: "window",
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <Paper elevation={5}>
          <span className={classes.arrow} ref={setArrowRef} />
          <Box className={classes.popoverContent}>
            <Typography variant="body2" noWrap>
              Hora da Solicitação: <b>{deliveryRequest.requested_time
                ? format(deliveryRequest.requested_time, CURRENT_DATE_TIME_FORMAT)
                : "---"}</b>
            </Typography>
            <Typography variant="body2" noWrap>
              {deliveryRequest.is_scheduled ? "Agendado para: " : "Previsão de Entrega: "}
              <b>{format(deliveryRequest.estimated_delivery_time, CURRENT_DATE_TIME_FORMAT)}</b>
            </Typography>
            <Typography variant="body2" noWrap>
              Tempo Estimado do Entregador: <b>{deliveryRequest?.estimated_delivery_time_after_release
                  ? format(deliveryRequest?.estimated_delivery_time_after_release, CURRENT_DATE_TIME_FORMAT)
                  : "---"}</b>
            </Typography>
          </Box>
        </Paper>
      </Popper>

      <Popper
        open={openPopoverReturn}
        anchorEl={anchorReturnRef.current}
        placement="top"
        className={classes.popper}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: "window",
          },
          arrow: {
            enabled: true,
            element: arrowReturnRef,
          },
        }}
      >
        <Paper elevation={5}>
          <span className={classes.arrow} ref={setArrowReturnRef} />
          <Box className={classes.popoverContent}>
            <Typography variant="body2" noWrap>
              {t("delivery_request_return_type." + deliveryRequest.return_type)}
            </Typography>
          </Box>
        </Paper>
      </Popper>

      {![DeliveryRequestStatus.BACK_TO_STORE, DeliveryRequestStatus.RETURNING, DeliveryRequestStatus.FINISHED].includes(deliveryRequest.status) && 
        <Grid className={determineClassNameItem()}></Grid>
      }    
      <Grid container spacing={0} className={classes.item}>
        <Grid item container sm>
          <Grid item xs={12} sm container spacing={0} justify="space-between">
            <Grid item zeroMinWidth>
              <Typography variant="body1" className={classes.order} style={{alignItems: "center", display: "flex"}} noWrap>             
                <HighlightText
                  text={`<b>#${deliveryRequest.order_number}</b> ${deliveryRequest.integration_instance ? deliveryRequest.integration_instance.initials + " " : ""}- ${deliveryRequest.destination_address.district} (${numbro(deliveryRequest.distance).format()} km)`}
                  words={[highlight]}
                />
               
                {deliveryRequest.return_type && deliveryRequest.return_type !== "none" && (
                  <Grid wrap="nowrap" ref={anchorReturnRef}
                   onMouseEnter={handlePopoverReturnOpen}
                   onMouseLeave={handlePopoverReturnClose} style={{alignItems: "center", display: "flex"}}>
                    <CompareArrowsOutlined style={{marginLeft: "5px"}} />
                  </Grid>
                )}
              </Typography>            
            </Grid>
            {showDeliveryStatus && (
              <Grid item>
                <Chip
                  className={
                    clsx(classes.statusChipDefault, {
                      [classes.vermelho]: ((DeliveryRequestStatus.CANCELED === deliveryRequest.status)),
                      [classes.verde]: ((DeliveryRequestStatus.FINISHED === deliveryRequest.status)),
                      [classes.azul]: ((DeliveryRequestStatus.AT_DESTINY === deliveryRequest.status)),
                      [classes.cinza]: ((DeliveryRequestStatus.ON_WAY === deliveryRequest.status)),
                      [classes.laranja]: ((DeliveryRequestStatus.RETURNING === deliveryRequest.status)),
                      [classes.blink]: (deliveryRequest.status === DeliveryRequestStatus.NO_COURIER && deliveryRequest.current_courier)
                    })}
                  size="small"
                  label={ 
                    ((DeliveryRequestStatus.NO_COURIER === deliveryRequest.status) && (deliveryRequest.current_courier)) ?
                    "Chamando Entregador"
                    : t('delivery_request_status.' + deliveryRequest.status)
                    }
                />
              </Grid>
            )}

            {deliveryRequest.addition_request_is_ringing && (
              <Grid item>
                <Chip
                  className={
                    clsx(classes.statusChipDefault, {
                      [classes.blink]: deliveryRequest.addition_request_is_ringing === true
                    })}
                  size="small"
                  label={ 
                    deliveryRequest.addition_request_is_ringing === true ?
                    "Chamando Entregador"
                    : t('delivery_request_status.' + deliveryRequest.status)
                    }
                />
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            container
            wrap="nowrap"
            spacing={1}
            ref={anchorRef}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            style={{ marginTop: "3px" }}
          >
            <Grid item>
              <AccessTime fontSize="small" />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography variant="body2" noWrap>
                {deliveryRequest?.estimated_delivery_time
                  ? format(deliveryRequest?.estimated_delivery_time, CURRENT_DATE_TIME_FORMAT)
                  : "---"}
                {![DeliveryRequestStatus.BACK_TO_STORE, DeliveryRequestStatus.RETURNING, DeliveryRequestStatus.FINISHED].includes(deliveryRequest.status) && 
                  deliveryRequest.estimated_delivery_time_after_release && isPast(new Date(deliveryRequest.estimated_delivery_time_after_release)) && (
                    <Chip
                      className={classes.chipAtrasado}
                      icon={<SportsMotorsports style={{color: "white", fontSize: "10px"}} />}
                      size="small"
                      label={"Atrasado"}
                    /> 
                  )
                }                                
              </Typography>
            </Grid>
            <Grid item>
              {getIntegrationIcon(deliveryRequest.source)}
            </Grid>
          </Grid>
        </Grid>
        <ShowIf condition={(splitTripAction !== undefined) && (trip.deliveries?.length > 1)}>
          <Grid item>
            <IconButton aria-label="separar entrega" title="Separar Entrega"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                if (splitTripAction) {
                  splitTripAction(trip, deliveryRequest);
                }
              }}>
              <CallSplit />
            </IconButton>
          </Grid>
        </ShowIf>
      </Grid>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  order: {
    fontSize: "15px"
  },
  item: {
    margin: "4px 0",
  },
  statusChip: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#384953 !important',
    color: theme.palette.secondary.contrastText,
  },
  statusChipDefault: {
    borderRadius: theme.shape.borderRadius,
    height: "22px !important",
    backgroundColor: "#384953 !important",
    color: theme.palette.secondary.contrastText,
  },
  verde: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#4caf50 !important",
    color: '#FFFFFF !important',
    height: "22px !important",
  },
  vermelho: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#f44336 !important",
    color: '#FFFFFF !important',
    height: "22px !important",
  },
  azul: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#0080ff !important",
    color: '#FFFFFF !important',
    height: "22px !important",
  },
  cinza: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#384953 !important",
    color: '#FFFFFF !important',
    height: "22px !important",
  },
  laranja:{
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ed7d05 !important",
    color: '#FFFFFF !important',
    height: "22px !important",
  },
  popoverContent: {
    padding: theme.spacing(2),
  },
  popper: {
    zIndex: 2000,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "0 100%",
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "100% 0",
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "100% 100%",
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "0 0",
      },
    },
  },
  arrow: {
    overflow: "hidden",
    position: "absolute",
    width: "1em",
    height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: "border-box",
    color: theme.palette.background.paper,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      boxShadow: theme.shadows[1],
      backgroundColor: "currentColor",
      transform: "rotate(45deg)",
    },
  },
  cardItemNoTime: {
    borderTop: "5px solid #f50057",
    marginTop: "3px",
    borderRadius: "10px",
  },
  chipAtrasado: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ed7d05 !important",
    color: '#FFFFFF !important',
    height: "18px !important",
    marginLeft: "10px",
    fontSize: "1rem !important",
    paddingBottom: "0px"
  },
  blink: {
    animation: "blinker 2s linear infinite",
  },
});
