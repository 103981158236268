import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Card,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
//@ts-ignore
import { NotificationManager } from "react-notifications";

import { useFormik } from "formik";

import * as Yup from "yup";

import { useHistory } from "react-router";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import GeoJsonMap from "../../components/maps/GeoJsonMap";
import _ from "lodash";
import { City, State } from "../../services/types/common";
import CityService from "../../services/cityService";
import { CreateUpdateRegion } from "../../services/types/region";
import RegionService from "../../services/regionService";
import { PhoneInput } from "../../components/PhoneInput";
import { mobilePhoneMaskRegex, phoneMaskRegex } from "../../utils/masks";

const validationSchema = Yup.object().shape({
  name: Yup.string().max(100, "Máximo de 100 caracteres excedido").required("Campo obrigatório"),
  support_phone: Yup.string()
    .test("isValidNumber", "Telefone inválido", (phone?: string | null) => {
      return (phone?.match(phoneMaskRegex) !== null) || (phone?.match(mobilePhoneMaskRegex) !== null);
    })
    .required("Campo obrigatório"),
  state: Yup.string().required("Campo origatório"),
  metropolitan_area: Yup.string().required("Campo obrigatório"),
  max_ignore_count: Yup.number().required("Campo Obrigatório"),
  ignore_timeout_minutes: Yup.number().required("Campo Obrigatório"),
  //coordinates_json: Yup.object().required("Arquivo GeoJSON é obrigatório")
});

const INITIAL_STATE: CreateUpdateRegion = {
  name: "",
  state: "",
  metropolitan_area: "",
  coordinates_json: undefined,
  support_phone: "",
  max_ignore_count: 0,
  ignore_timeout_minutes: 0
};

export const NewRegion: React.FC = () => {
  const history = useHistory();

  const [cityList, setCityList] = useState<City[]>([]);
  const [stateList, setStateList] = useState<State[]>([]);

  const [geoJson, setGeoJson] = useState();

  React.useEffect(() => {
    CityService.getCities()
      .then(response => {
        setCityList(response);
      })
      .catch(error => console.log(error));

    CityService.getStates()
      .then(response => {
        setStateList(response);
      })
      .catch(error => console.log(error));
  }, []);

  const formik = useFormik({
    initialValues: INITIAL_STATE,
    onSubmit: (regionValues) => {
      if (_.isUndefined(geoJson) || _.isEmpty(geoJson)) {
        NotificationManager.error(
          "Você deve selecionar um arquivo GeoJSON"
        );
        return;
      } else {
        regionValues.coordinates_json = geoJson;
      }

      RegionService.createRegion(regionValues)
        .then((response) => {
          NotificationManager.success(
            "Região salva com sucesso",
            "Nova Região"
          );
          history.push({ pathname: "/region" });
        })
        .catch((error) => {
          NotificationManager.error(
            "Erro ao salvar a Região",
            "Nova Região"
          );
          if (error) {
            const errorItems = error.response.data;
            for (var key in errorItems) {
              var value = errorItems[key];
              formik.setFieldError(key, value);
            }
          }
        });
    },
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Nova Região</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[
                { label: "Regiões", url: "/region" },
                { label: "Nova Região", url: "/region/new" },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item container xs={12} lg={4} spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h2">Dados da Região</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Nome"
                        variant="outlined"
                        fullWidth
                        value={formik.values.name}
                        error={!!formik.errors.name}
                        helperText={formik.errors.name}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="support_phone"
                        label="Telefone do Suporte"
                        variant="outlined"
                        fullWidth
                        value={formik.values.support_phone}
                        error={!!formik.errors.support_phone}
                        helperText={formik.errors.support_phone}
                        onChange={formik.handleChange}
                        InputLabelProps={{ required: true }}
                        InputProps={{
                          inputComponent: PhoneInput,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="metropolitan_area"
                        name="metropolitan_area"
                        label="Área Metropolitana (Cidade)"
                        fullWidth
                        select
                        variant="outlined"
                        value={formik.values.metropolitan_area}
                        onChange={formik.handleChange}
                        error={!!formik.errors.metropolitan_area}
                        helperText={formik.errors.metropolitan_area}
                      >
                        <MenuItem value="">Selecione</MenuItem>
                        {cityList.map((city) => (
                          <MenuItem key={city.id} value={city.name}>
                            {city.name} - {city.state_initials}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" error={!!formik.errors.state} fullWidth>
                        <InputLabel id="label_state">Estado</InputLabel>
                        <Select
                          labelId="label_state"
                          label="Estado"
                          id="state"
                          name="state"
                          value={formik.values.state}
                          onChange={formik.handleChange}
                        >
                          <MenuItem value="">Selecione</MenuItem>
                          {stateList.map((state) => (
                            <MenuItem key={state.id} value={state.initials}>
                              {state.initials} - {state.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText id="state_helper">
                          {formik.errors.state}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h2">Configurações para retirar das Filas</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="max_ignore_count"
                          label="Qtd. de Solicitações Ignoradas"
                          variant="outlined"
                          fullWidth
                          type="number"
                          value={formik.values.max_ignore_count}
                          error={!!formik.errors.max_ignore_count}
                          helperText={formik.errors.max_ignore_count}
                          onChange={formik.handleChange}
                          InputLabelProps={{ required: true }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="ignore_timeout_minutes"
                          label="Tempo para Retirar da Fila (em minutos)"
                          variant="outlined"
                          fullWidth
                          type="number"
                          value={formik.values.ignore_timeout_minutes}
                          error={!!formik.errors.ignore_timeout_minutes}
                          helperText={formik.errors.ignore_timeout_minutes}
                          onChange={formik.handleChange}
                          InputLabelProps={{ required: true }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={8}>
            <div className="map-region">
              <GeoJsonMap setGeoJson={setGeoJson} />
            </div>
          </Grid>

          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>
          </Grid>

          <Grid item>
            <Button
              color="primary"
              variant="contained"
              type="button"
              disabled={formik.isSubmitting}
              onClick={() => formik.handleSubmit()}
            >
              Salvar{" "}
              {formik.isSubmitting && (
                <CircularProgress size={24} />
              )}
            </Button>
          </Grid>

        </Grid>
      </form>
    </>
  );
};
