import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import cep from "cep-promise";
import * as Yup from "yup";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";

import { locationByAddress, locationByIP } from "../../utils/getLocation";
import { capitalize } from "../../utils/capitalize";
import {
  phoneMaskRegex,
  postalCodeMaskRegex,
  noSpecialCharactersRegex,
} from "../../utils/masks";

import { EMPTY_STORE, IStore } from "../../store/types/customer";

import { PhoneInput } from "../../components/PhoneInput";
import { PostCodeInput } from "../../components/PostCodeInput";
import { SimpleMap } from "../../components/maps/SimpleMap";
import { City } from "../../services/types/common";
import CityService from "../../services/cityService";

interface INewStoreModal {
  modal: {
    open: boolean;
    setOpen(value: boolean): void;
  };
  handleAddStore(value: IStore): void;
  handleUpdateStore(value: IStore): void;
  cleanStore(): void;
  store?: IStore;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().max(100, "Máximo de 150 caracteres excedido").required("Campo obrigatório"),
  email: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .email("E-mail inválido")
    .required("Campo obrigatório"),
  latitude: Yup.number().required("Campo obrigatório"),
  longitude: Yup.number().required("Campo obrigatório"),
  phonenumber: Yup.string()
    .max(15, "Telefone inválido")
    .min(14, "Telefone inválido")
    .matches(phoneMaskRegex, "Telefone inválido")
    .required("Campo obrigatório"),
  postal_code: Yup.string()
    .matches(postalCodeMaskRegex, "CEP inválido")
    .required("Campo obrigatório"),
  street: Yup.string().max(100, "Máximo de 100 caracteres excedido").required("Campo obrigatório"),
  city: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .matches(
      noSpecialCharactersRegex,
      "Caracteres especiais não são permitidos"
    )
    .required("Campo obrigatório"),
  district: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .matches(
      noSpecialCharactersRegex,
      "Caracteres especiais não são permitidos"
    )
    .required("Campo obrigatório"),
  address_number: Yup.string().max(10, "Máximo de 10 caracteres excedido"),
  address_complement: Yup.string().max(50, "Máximo de 50 caracteres excedido"),
  contact_name: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .required("Campo obrigatório"),
});

const StoreModal: React.FC<INewStoreModal> = ({
  modal,
  handleAddStore,
  handleUpdateStore,
  store,
  cleanStore,
}) => {
  const classes = useStyles();
  const [cityList, setCityList] = useState<City[]>([]);

  const [storeFormData, setStoreFormData] = useState<IStore>(EMPTY_STORE);
  const [update, setUpdate] = useState(false);
  const [position, setPosition] = useState({ latitude: 0, longitude: 0 });
  const [fullAddress, setFullAddress] = useState({
    cep: "",
    street: "",
    neighborhood: "",
    city: "",
    state: ""
  });

  const getCities = async () => {
    const response = await CityService.getCities();
    setCityList(response);
  };

  useEffect(() => {
    getCities();
  }, []);

  const handleClose = () => {
    formik.resetForm();
    cleanStore();
    modal.setOpen(false);
  };

  const formik = useFormik({
    initialValues: storeFormData,
    onSubmit: (newStore) => {
      if (store?.latitude) {
        handleUpdateStore({
          ...newStore,
          latitude: position.latitude,
          longitude: position.longitude,
        });
      } else {
        handleAddStore({
          ...newStore,
          latitude: position.latitude,
          longitude: position.longitude,
        });
      }
      handleClose();
    },
    enableReinitialize: true,
    validationSchema,
    validateOnChange: true,
  });

  const getPosition = useCallback(async (address) => {
    locationByAddress(address).then((pos) => {
      if (pos.lat !== 0) {
        setPosition({
          latitude: pos.lat,
          longitude: pos.lng,
        });
      }
    });
  }, []);

  const getInitialPosition = useCallback(async (address) => {
    locationByIP().then((pos) => {
      if (pos.lat !== 0) {
        setPosition({
          latitude: pos.lat,
          longitude: pos.lng,
        });
      }
    }).catch(() => {
      locationByAddress(address).then((pos) => {
        if (pos.lat !== 0) {
          setPosition({
            latitude: pos.lat,
            longitude: pos.lng,
          });
        }
      });
    });
  }, []);

  const getFormattedAddress = (address: any, number: string): string => {
    let result = address.street;
    if (number && number.length) {
      result += `, ${number}`;
    }
    if (address.neighborhood.length) {
      result += `, ${address.neighborhood}`;
    }
    if (address.city.length) {
      result += `, ${address.city}`;
    }
    if (address.city.length) {
      result += ` - ${address.state}`;
    }
    return result + ", Brasil";
  }

  const handleCepChange = (cepRequested: string) => {
    cep(cepRequested)
      .then((cepResponse) => {
        let fullAddress = {
          cep: cepRequested,
          street: cepResponse.street,
          neighborhood: cepResponse.neighborhood,
          city: cepResponse.city,
          state: cepResponse.state
        };
        setFullAddress(fullAddress);
        formik.setFieldValue(`street`, cepResponse.street);
        // formik.setFieldTouched("street", true, false);
        formik.setFieldValue(`district`, cepResponse.neighborhood);
        // formik.setFieldTouched("district", true, false);
        console.error(getFormattedAddress(fullAddress, formik.values["address_number"]));
        getPosition(getFormattedAddress(fullAddress, formik.values["address_number"]));
      })
      .catch(() => {
        setFullAddress({
          cep: cepRequested,
          street: formik.values['street'],
          neighborhood: formik.values['district'],
          city: "",
          state: ""
        });
        formik.setFieldError(`postal_code`, "CEP não encontrado");
      });
  };

  const handleAddressNumberChange = (addressNumber: string) => {
    if (addressNumber) {
      console.error(getFormattedAddress(fullAddress, addressNumber));
      getPosition(getFormattedAddress(fullAddress, addressNumber));
    }
  };

  useEffect(() => {
    if (store?.latitude) {
      setUpdate(true);
      setStoreFormData(
        {
          ...store,
          phonenumber: store.phonenumber.replace(
            /(\d{2})(\d{4})(\d{4,5})/g,
            "($1) $2-$3"
          ),
        }
      );
      setPosition({
        latitude: store.latitude,
        longitude: store.longitude,
      });
    } else {
      setUpdate(false);
      setStoreFormData(EMPTY_STORE);
      getInitialPosition("Brasil");
    }
  }, [store, getInitialPosition]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal.open}
        onClose={() => { }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal.open}>
          <div className={classes.paper}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Grid item className={classes.formWrapper}>
                    <Typography variant="h2" className={classes.title}>
                      {update ? "Atualizar Loja" : "Adicionar Loja"}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <TextField
                          id="name"
                          label="Nome fantasia"
                          variant="outlined"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.name ? formik.errors.name : ""
                          }
                          error={!!formik.touched.name && !!formik.errors.name}
                          fullWidth
                        />
                      </Grid>

                      <Grid item md={6}>
                        <TextField
                          id="contact_name"
                          label="Pessoa para contato"
                          variant="outlined"
                          value={formik.values.contact_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.contact_name
                              ? formik.errors.contact_name
                              : ""
                          }
                          error={
                            !!formik.touched.contact_name &&
                            !!formik.errors.contact_name
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          id="phonenumber"
                          label="Celular"
                          variant="outlined"
                          value={formik.values.phonenumber}
                          onChange={formik.handleChange}
                          fullWidth
                          error={!!formik.errors.phonenumber}
                          helperText={formik.errors.phonenumber}
                          InputProps={{
                            inputComponent: PhoneInput,
                          }}
                        />
                      </Grid>
                      <Grid item md={8}>
                        <TextField
                          id="email"
                          label="E-mail"
                          variant="outlined"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.email ? formik.errors.email : ""
                          }
                          error={
                            !!formik.touched.email && !!formik.errors.email
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          id="postal_code"
                          label="CEP"
                          variant="outlined"
                          value={formik.values.postal_code}
                          onChange={formik.handleChange}
                          onBlur={(e) => handleCepChange(e.target.value)}
                          fullWidth
                          error={!!formik.touched.postal_code && !!formik.errors.postal_code}
                          helperText={formik.errors.postal_code}
                          InputProps={{
                            inputComponent: PostCodeInput,
                          }}
                        />
                      </Grid>
                      <Grid item md={8}>
                        <TextField
                          id="street"
                          label="Rua"
                          variant="outlined"
                          value={formik.values.street || ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          error={
                            !!formik.touched.street && !!formik.errors.street
                          }
                          helperText={
                            formik.touched.street ? formik.errors.street : ""
                          }
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="address_number"
                          label="Nº"
                          variant="outlined"
                          value={formik.values.address_number || ""}
                          onChange={formik.handleChange}
                          onBlur={(e) => {
                            formik.handleBlur(e);
                            handleAddressNumberChange(e.target.value);
                          }}
                          helperText={
                            formik.touched.address_number
                              ? formik.errors.address_number
                              : ""
                          }
                          error={
                            !!formik.touched.address_number &&
                            !!formik.errors.address_number
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="address_complement"
                          label="Complemento"
                          variant="outlined"
                          value={formik.values.address_complement}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.address_complement
                              ? formik.errors.address_complement
                              : ""
                          }
                          error={
                            !!formik.touched.address_complement &&
                            !!formik.errors.address_complement
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="district"
                          label="Bairro"
                          variant="outlined"
                          value={formik.values.district || ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.district
                              ? formik.errors.district
                              : ""
                          }
                          error={
                            !!formik.touched.district &&
                            !!formik.errors.district
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="cities-label">Cidade</InputLabel>
                          <Select
                            labelId="cities-label"
                            label="Cidade"
                            id="city"
                            name="city"
                            value={formik.values.city || ""}
                            onChange={formik.handleChange}
                          >
                            {cityList.map((city) => (
                              <MenuItem key={city.id} value={city.id}>
                                {`${capitalize(city.name)} - ${city.state_initials}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={handleClose}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          startIcon={<ControlPointIcon />}
                        >
                          {update ? "Atualizar Loja" : "Adicionar Loja"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.maps} md={6}>
                  <SimpleMap
                    setPosition={setPosition}
                    latitude={position.latitude}
                    longitude={position.longitude}
                  />
                  <Grid item className={classes.mapsInfo} md={12}>
                    <Typography className={classes.mapsInfoText}>
                      Ajuste a localização da loja no mapa
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default StoreModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "none",
      outline: "none",
      borderRadius: "4px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 3, 2),
      width: "90vw",
    },
    formWrapper: {
      marginTop: "20px",
    },

    maps: {
      position: "relative",
      marginTop: "20px",
    },

    mapsInfo: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      bottom: 30,
    },
    mapsInfoText: {
      background: "#fff",
      width: "80%",
      padding: 12,
      borderRadius: "4px",
      fontWeight: "bold",
      boxShadow: theme.shadows[5],
    },
    title: {
      marginBottom: "10px",
    },
    button: {
      marginTop: "30px",
      minWidth: "150px",
      padding: "8px 30px",
      marginLeft: "10px",
      textTransform: "capitalize",
      borderRadius: "4px",
    },
    textField: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#fff",
      borderRadius: "4px",
      width: "100%",

      "& .MuiFormControl-root": {
        margin: 0,
        padding: 0,
      },
      "& .MuiFormLabel-root": {
        color: theme.palette.primary.light,
      },
      "& label.Mui-focused": {
        color: theme.palette.primary.main,
      },
      "& .MuiInput-underline": {
        "&:before": {
          borderColor: theme.palette.primary.light,
        },
      },
    },
  })
);
