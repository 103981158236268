import { CourierResponse } from "./courier";
import { TripCourier } from "./deliveryRequest";

export enum ProposalType {
    MINIMUM_EARNINGS = "MINIMUM_EARNINGS",
    PLUS_EARNINGS = "PLUS_EARNINGS",
}

export enum StatusDedicated {
    ACTIVE = "ACTIVE",
    CLOSED = "CLOSED"
}

export interface DedicatedResponse {
    id: number;
    description: string;
    first_datetime: Date | null;
    modal_allowed: string;
    proposal_type: ProposalType;
    recurrent: boolean;
    status: StatusDedicated;
    store: StoreDedicated;
    turns: TurnResponse[];
}

export interface CreateUpdateDedicated {
    id: number;
    store: number;
    proposal_type: ProposalType;
    description: string;
    modal_allowed: string;
    recurrent: boolean;    
    first_datetime: Date;
    turns: TurnResponse[];
}

export interface TurnResponse {
    start_time: string;
    end_time: string;
    day: string;
    number_of_slots: number;
    value: number;
}

export interface TurnCreateUpdate {
    checked: boolean;
    start_time: Date;
    end_time: Date;
    day: string;
    number_of_slots: number;
    value: number;
}

export interface SlotResponse{
    id: number;
    courier: TripCourier;
    end_datetime: Date;
    is_done: boolean;
    proposal_id: number;
    start_datetime: Date;
    store_id: number;
    store_name: string;
    value: number;
    region_id: number;
    region_name: string;
    contact_name: string;
    contact_phone: string;
    quantity_earned: number;
    current_earnings: number;
}

export interface StoreDedicated{
    id: number;
    name: string;
    region: RegionDedicated
}

export interface RegionDedicated {
    id: number;
    name: string;
}
export interface BlockDedicatedResponse {
    courier: CourierResponse;
    created_at: Date;
    duration: string;
    expired: boolean;
    id: number;
    reason: string;
    undefined_time: boolean;
    updated_at: Date;
}

export enum DaysWeek {
    SUNDAY = "SUNDAY",
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY"
}