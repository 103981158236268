import { createMuiTheme} from "@material-ui/core/styles";

declare module "@material-ui/core/styles" {
  interface ThemeOptions {
    [key: string]: any;
  }
}

const palette = {
  primary: {
    light: '#a5b4c1',
    main: '#0080ff',
    // dark: será calculada com base em palette.primary.main,
    contrastText: '#ffffff',
  },
  secondary: {
    light: '#a5b4c1',
    main: '#526272',
    // dark: será calculada com base palette.secondary.main,
    contrastText: '#ffffff',
  },
  // Usado por `getContrastText()` para maximizar o contraste entre
  // o plano de fundo e o texto.
  contrastThreshold: 3,
  // Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
  // dois índices dentro de sua paleta tonal.
  // Por exemplo, mude de Red 500 para Red 300 ou Red 700.
  tonalOffset: 0.2,
}

const typography = {
  //fontFamily: "Roboto, Helvetica, Arial, sans-serif",//"Nunito Sans, sans-serif",
  fontFamily: "Nunito Sans, sans-serif",

  h1: {
    fontWeight: 700,
    fontSize: '24px',
    color: palette.secondary.main,
  },
  h2: {
    fontWeight: 700,
    fontSize: '18px',
    color: palette.secondary.main,
  },
  h3: {
    fontWeight: 700,
    fontSize: '16px',
    color: palette.secondary.main,
  },
  subtitle1: {
    fontWeight: 700,
    fontSize: '14px',
    color: palette.secondary.main,
  },
  body1: {
    fontWeight: 400,
    fontSize: '14px',
    color: palette.secondary.main,
  },
  body2: {
    fontWeight: 400,
    fontSize: '14px',
    color: palette.secondary.main,
  },
  button: {
    fontWeight: 400,
    fontSize: '14px',
  }
}

const theme = createMuiTheme({palette, typography});

export default theme;