import React, { useCallback, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { Typography, Grid, Table, TableBody, TableCell, TableRow, TableHead, Button, Card, CardContent, createStyles, makeStyles, Theme } from "@material-ui/core";
import { LoadingDialog } from "../../components/LoadingDialog";
import numbro from 'numbro';
import CommercialPlanService from "../../services/commercialPlanService";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { CommercialPlanDetails } from "../../services/types/commercialPlan";
import { EditOutlined } from "@material-ui/icons";
import ShowIf from "../../components/ShowIf";
import AuthService from "../../services/authService";

interface IParams {
  commercialPlanId: string;
}

export const DetailsCommercialPlan: React.FC = () => {
  const [isShowLoadingDialog, showLoadingDialog] = React.useState(false);
  const [loadingDialogMessage, setLoadingDialogMessage] = React.useState("");
  const { commercialPlanId } = useParams<IParams>();
  const [commercialPlan, setCommercialPlan] = React.useState<CommercialPlanDetails>();
  const classes = useStyles();

  const refreshData = useCallback(async (id: number) => {
    setLoadingDialogMessage("Aguarde...");
    showLoadingDialog(true);
    try {
      const response: any = await CommercialPlanService.getCommercialPlanById(id);
      setCommercialPlan(response);
    } catch (error) {
      NotificationManager.error(
        "Erro ao tentar carregar os dados do Plano Comercial.",
        "Plano Comercial"
      );
    } finally {
      showLoadingDialog(false);
    }
  }, []);

  useEffect(() => {
    refreshData(Number(commercialPlanId));
  }, [commercialPlanId, refreshData]);

  return (
    <React.Fragment>
      <LoadingDialog open={isShowLoadingDialog} message={loadingDialogMessage} />
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Detalhes do Plano Comercial</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[
                { label: "Planos Comerciais", url: "/commercialPlan" },
                { label: "Detalhes do Plano Comercial", url: `/commercialPlan/detail/${commercialPlanId}` },
              ]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <ShowIf condition={AuthService.hasAuthorization("change_commercialplan")}>
            <Button color="secondary" variant="contained"
              startIcon={<EditOutlined />}
              component={Link} to={`/commercialPlan/edit/${commercialPlanId}`}>
              Editar
            </Button>
          </ShowIf>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.styleCard}>
            <CardContent>
              <Typography variant="h2">Dados Gerais</Typography>
              <Table className="table-bordered">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>Nome</Typography>
                      <Typography variant="subtitle1">{commercialPlan?.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Região</Typography>
                      <Typography variant="subtitle1">{commercialPlan?.region ? (commercialPlan?.region.name) : "---"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Distância do valor mínimo (m)</Typography>
                      <Typography variant="subtitle1">{commercialPlan?.minimum_value_distance}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Card className={classes.styleCard}>
            <CardContent>
              <Typography variant="h2">Bicicleta</Typography>
              <Table className="table-bordered" >
                <TableHead className={classes.styleRowTitle}>
                  <TableRow>
                    <TableCell colSpan={2}>Valor mínimo da entrega</TableCell>
                    <TableCell colSpan={2}>Valor do Km adicional</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.bike_minimum_speedy_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.bike_minimum_courier_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.bike_additional_km_speedy_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.bike_additional_km_courier_value)).formatCurrency()}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
                {commercialPlan?.bike_additional_km_rules && commercialPlan?.bike_additional_km_rules.length > 0 && (
                  <>
                  <TableHead className={classes.styleRowTitle}>
                    <TableRow>
                      <TableCell colSpan={2}>Kms Adicionais</TableCell>
                      <TableCell colSpan={2}></TableCell>
                    </TableRow>
                  </TableHead>
                  {commercialPlan?.bike_additional_km_rules && commercialPlan?.bike_additional_km_rules.map((item, index) => (                   
                    <TableRow key={"bike_"+index}>
                    <TableCell>
                      <Typography>Km Inicial (m)</Typography>
                      <Typography variant="subtitle1">{Number(item.start_m)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Km Final (m)</Typography>
                      <Typography variant="subtitle1">{item.end_m ? Number(item.end_m) : "Indefinido"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(item.speedy_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(item.courier_value)).formatCurrency()}</Typography>
                    </TableCell>
                  </TableRow>
                  ))}
                  </>
                 )}             
                <TableHead className={classes.styleRowTitle}>
                  <TableRow>
                    <TableCell colSpan={2}>Valor mínimo de retorno</TableCell>
                    <TableCell colSpan={2}>Valor do Km adicional de retorno</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.bike_minimum_speedy_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.bike_minimum_courier_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.bike_additional_km_speedy_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.bike_additional_km_courier_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Card className={classes.styleCard}>
            <CardContent>
              <Typography variant="h2">Moto</Typography>
              <Table className="table-bordered" >
                <TableHead className={classes.styleRowTitle}>
                  <TableRow>
                    <TableCell colSpan={2}>Valor mínimo da entrega</TableCell>
                    <TableCell colSpan={2}>Valor do Km adicional</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.motorcycle_minimum_speedy_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.motorcycle_minimum_courier_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.motorcycle_additional_km_speedy_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.motorcycle_additional_km_courier_value)).formatCurrency()}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
                {commercialPlan?.motorcycle_additional_km_rules && commercialPlan?.motorcycle_additional_km_rules.length > 0 && (
                  <>
                  <TableHead className={classes.styleRowTitle}>
                    <TableRow>
                      <TableCell colSpan={2}>Kms Adicionais</TableCell>
                      <TableCell colSpan={2}></TableCell>
                    </TableRow>
                  </TableHead>
                  {commercialPlan?.motorcycle_additional_km_rules && commercialPlan?.motorcycle_additional_km_rules.map((item, index) => (                   
                    <TableRow key={"motorcycle_"+index}>
                    <TableCell>
                      <Typography>Km Inicial (m)</Typography>
                      <Typography variant="subtitle1">{Number(item.start_m)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Km Final (m)</Typography>
                      <Typography variant="subtitle1">{item.end_m ? Number(item.end_m) : "Indefinido"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(item.speedy_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(item.courier_value)).formatCurrency()}</Typography>
                    </TableCell>
                  </TableRow>
                  ))}
                  </>
                 )} 
                <TableHead className={classes.styleRowTitle}>
                  <TableRow>
                    <TableCell colSpan={2}>Valor mínimo de retorno</TableCell>
                    <TableCell colSpan={2}>Valor do Km adicional de retorno</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.motorcycle_minimum_speedy_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.motorcycle_minimum_courier_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.motorcycle_additional_km_speedy_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.motorcycle_additional_km_courier_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Card className={classes.styleCard}>
            <CardContent>
              <Typography variant="h2">Carro</Typography>
              <Table className="table-bordered" >
                <TableHead className={classes.styleRowTitle}>
                  <TableRow>
                    <TableCell colSpan={2}>Valor mínimo da entrega</TableCell>
                    <TableCell colSpan={2}>Valor do Km adicional</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.car_minimum_speedy_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.car_minimum_courier_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.car_additional_km_speedy_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.car_additional_km_courier_value)).formatCurrency()}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
                {commercialPlan?.car_additional_km_rules && commercialPlan?.car_additional_km_rules.length > 0 && (
                  <>
                  <TableHead className={classes.styleRowTitle}>
                    <TableRow>
                      <TableCell colSpan={2}>Kms Adicionais</TableCell>
                      <TableCell colSpan={2}></TableCell>
                    </TableRow>
                  </TableHead>
                  {commercialPlan?.car_additional_km_rules && commercialPlan?.car_additional_km_rules.map((item, index) => (                    
                    <TableRow key={"car_"+index}>
                    <TableCell>
                      <Typography>Km Inicial (m)</Typography>
                      <Typography variant="subtitle1">{Number(item.start_m)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Km Final (m)</Typography>
                      <Typography variant="subtitle1">{item.end_m ? Number(item.end_m) : "Indefinido"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(item.speedy_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(item.courier_value)).formatCurrency()}</Typography>
                    </TableCell>
                  </TableRow>
                  ))}
                  </>
                 )} 
                <TableHead className={classes.styleRowTitle}>
                  <TableRow>
                    <TableCell colSpan={2}>Valor mínimo de retorno</TableCell>
                    <TableCell colSpan={2}>Valor do Km adicional de retorno</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.car_minimum_speedy_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.car_minimum_courier_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.car_additional_km_speedy_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.car_additional_km_courier_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Card className={classes.styleCard}>
            <CardContent>
              <Typography variant="h2">Furgão</Typography>
              <Table className="table-bordered" >
                <TableHead className={classes.styleRowTitle}>
                  <TableRow>
                    <TableCell colSpan={2}>Valor mínimo da entrega</TableCell>
                    <TableCell colSpan={2}>Valor do Km adicional</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.van_minimum_speedy_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.van_minimum_courier_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.van_additional_km_speedy_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.van_additional_km_courier_value)).formatCurrency()}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
                {commercialPlan?.van_additional_km_rules && commercialPlan?.van_additional_km_rules.length > 0 && (
                  <>
                  <TableHead className={classes.styleRowTitle}>
                    <TableRow>
                      <TableCell colSpan={2}>Kms Adicionais</TableCell>
                      <TableCell colSpan={2}></TableCell>
                    </TableRow>
                  </TableHead>
                  {commercialPlan?.van_additional_km_rules && commercialPlan?.van_additional_km_rules.map((item, index) => (                    
                    <TableRow key={"van_"+index}>
                    <TableCell>
                      <Typography>Km Inicial (m)</Typography>
                      <Typography variant="subtitle1">{Number(item.start_m)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Km Final (m)</Typography>
                      <Typography variant="subtitle1">{item.end_m ? Number(item.end_m) : "Indefinido"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(item.speedy_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(item.courier_value)).formatCurrency()}</Typography>
                    </TableCell>
                  </TableRow>
                  ))}
                  </>
                 )} 
                <TableHead className={classes.styleRowTitle}>
                  <TableRow>
                    <TableCell colSpan={2}>Valor mínimo de retorno</TableCell>
                    <TableCell colSpan={2}>Valor do Km adicional de retorno</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.van_minimum_speedy_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.van_minimum_courier_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Speedy</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.van_additional_km_speedy_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Entregador</Typography>
                      <Typography variant="subtitle1">{numbro(Number(commercialPlan?.van_additional_km_courier_return_value)).formatCurrency()}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    styleCard: {
      marginBottom: "10px !important",
    },
    styleRowTitle: {
      backgroundColor: "#eaf3fb",
    }
  })
);