import React, { useState, useCallback } from "react";
import { useFormik } from "formik";
import cep from "cep-promise";
import * as Yup from "yup";
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  TextField,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { locationByAddress } from "../../utils/getLocation";
import {
  phoneMaskRegex,
  postalCodeMaskRegex,
  noSpecialCharactersRegex,
} from "../../utils/masks";
import { PhoneInput } from "../../components/PhoneInput";
import { PostCodeInput } from "../../components/PostCodeInput";
import { SimpleMap } from "../../components/maps/SimpleMap";
import { City } from "../../services/types/common";
import { CreateUpdateStore, StoreResponse } from "../../services/types/customer";
import ShowIf from "../../components/ShowIf";
import { unaccent } from "../../utils/utils";
import { formatPhoneNumber } from "../../utils/formatters";
import { apiAxios } from "../../store/api/config";
import { UploadImage } from "../../components/UploadImage";
import { CustomAvatar } from "../../components/CustomAvatar";

interface AddEditStoreDialogProps {
  cityList: City[];
}

export interface AddEditStoreDialogHandle {
  add: (customerId: number) => Promise<CreateUpdateStore>;
  edit: (store: StoreResponse) => Promise<CreateUpdateStore>;
  showFieldErrors: (errors: any) => string | null;
  hide: () => void;
}

interface ImageField {
  src: Blob | string | null,
  error: string,
  status: string
};

const INITIAL_IMAGE = {
  photo: { src: null, error: "", status: "" } as ImageField,
};

interface AddEditStoreFormData {
  name: string;
  contact_name: string;
  phonenumber: string;
  email: string;
  postal_code: string;
  street: string;
  address_number: string;
  address_complement: string;
  district: string;
  city: number | "";
  photo?: Blob;
  photoText: string;
  use_priority_queue: boolean;
}

const INITIAL_FORM_VALUES: AddEditStoreFormData = {
  name: "",
  contact_name: "",
  phonenumber: "",
  email: "",
  postal_code: "",
  street: "",
  address_number: "",
  address_complement: "",
  district: "",
  city: "",
  photoText: "",
  use_priority_queue: true
}

interface IImage {
  title: string;
  src: string;
}
interface FullAddress {
  postal_code: string
  street: string,
  number?: string,
  complement?: string,
  district: string,
  city: string,
  state: string
}

const validationSchema = Yup.object().shape({
  name: Yup.string().max(100, "Máximo de 150 caracteres excedido").required("Campo obrigatório"),
  email: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .email("E-mail inválido")
    .required("Campo obrigatório"),
  phonenumber: Yup.string()
    .min(14, "Telefone inválido max")
    .max(15, "Telefone inválido min")
    .matches(phoneMaskRegex, "Telefone inválido")
    .required("Campo obrigatório"),
  postal_code: Yup.string()
    .matches(postalCodeMaskRegex, "CEP inválido")
    .required("Campo obrigatório"),
  street: Yup.string().max(100, "Máximo de 100 caracteres excedido").required("Campo obrigatório"),
  city: Yup.number().required("Campo obrigatório"),
  district: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .matches(
      noSpecialCharactersRegex,
      "Caracteres especiais não são permitidos"
    )
    .required("Campo obrigatório"),
  address_number: Yup.string().max(10, "Máximo de 10 caracteres excedido"),
  address_complement: Yup.string().max(50, "Máximo de 50 caracteres excedido"),
  contact_name: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .required("Campo obrigatório"),
});

const AddEditStoreDialog: React.ForwardRefRenderFunction<AddEditStoreDialogHandle, AddEditStoreDialogProps> = (props, ref) => {
  const classes = useStyles();

  const [resolveReject, setResolveReject] = React.useState<any[]>([]);
  const [resolve, reject] = resolveReject;

  const [customerId, setCustomerId] = React.useState<number>();
  const [latitude, setLatitude] = React.useState<number>(0);
  const [longitude, setLongitude] = React.useState<number>(0);

  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  const [update, setUpdate] = useState(false);
  const [image, setImage] = useState(INITIAL_IMAGE);
  const [imageToPreview, setImageToPreview] = useState<IImage>({ title: "", src: "" });
  const [fileSrc, setFileSrc] = React.useState<string>("");
  const [fileType, setFileType] = React.useState<string>();

  React.useImperativeHandle(ref, () => ({
    add(customerId: number): Promise<CreateUpdateStore> {
      return add(customerId);
    },

    edit(store: StoreResponse): Promise<CreateUpdateStore> {
      return edit(store);
    },

    showFieldErrors(errors: any) {
      const remainingErrorMsgs: string[] = [];
      let errorMsg = null;
      for (var key in errors) {
        var value = errors[key];
        if (key in INITIAL_FORM_VALUES) {
          formik.setFieldError(key, value);
        } else {
          remainingErrorMsgs.push(value);
        }
      }

      if (remainingErrorMsgs.length > 0) {
        errorMsg = remainingErrorMsgs.join(". \n");
      }
      return errorMsg;
    },

    hide() {
      close();
    }
  }));

  const add = (customerId: number): Promise<CreateUpdateStore> => {
    setCustomerId(customerId);
    setUpdate(false);
    setSubmitting(false);
    setFileSrc("");
    formik.resetForm();
    return new Promise<CreateUpdateStore>((resolve, reject) => {
      setResolveReject([resolve, reject]);
    });
  }

  const edit = (store: StoreResponse): Promise<CreateUpdateStore> => {
    setCustomerId(store.customer);
    setUpdate(true);
    setSubmitting(false);
    setLatitude(store.latitude);
    setLongitude(store.longitude);
    setFileSrc("");
    formik.resetForm();
    formik.setValues({
      name: store.name,
      contact_name: store.contact_name,
      phonenumber: formatPhoneNumber(store.phonenumber),
      email: store.email,
      postal_code: store.postal_code,
      street: store.street,
      address_number: store.address_number,
      address_complement: store.address_complement,
      district: store.district,
      city: store.city,
      photoText: store.photo,
      use_priority_queue: store.use_priority_queue
    });
    setFileSrc(store.photo);
    return new Promise<CreateUpdateStore>((resolve, reject) => {
      setResolveReject([resolve, reject]);
    });
  }

  const close = () => {
    setCustomerId(undefined);
    setResolveReject([]);
    formik.resetForm();
    setSubmitting(false);
  }

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: (values) => {
      if(fileSrc === ""){
        delete values.photo;
      }else{
        if (image.photo.src instanceof Blob) {
          values.photo = image.photo.src;
        }else{
          delete values.photo;
        }
      }
      setSubmitting(true);
      resolve({
        ...values,
        customer: customerId,
        latitude: latitude,
        longitude: longitude
      });
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  const handleClose = () => {
    reject();
    close();
  };

  const setPosition = useCallback((coords: { latitude: number, longitude: number }) => {
    setLatitude(coords.latitude);
    setLongitude(coords.longitude);
  }, []);

  const getCityById = (cityId: number): City | null => {
    let result = null;
    for (let i = 0; i < props.cityList.length; i++) {
      if (props.cityList[i].id === cityId) {
        result = props.cityList[i];
        break;
      }
    }
    return result;
  }

  const getCityByNameAndState = (name: string, state: string): City | null => {
    let result = null;
    const nameToSearch = unaccent(name).toLowerCase();
    const stateToSearch = state.toLowerCase();
    for (let i = 0; i < props.cityList.length; i++) {
      if ((unaccent(props.cityList[i].name).toLowerCase() === nameToSearch)
        && (props.cityList[i].state_initials.toLowerCase() === stateToSearch)) {
        result = props.cityList[i];
        break;
      }
    }
    return result;
  }

  const updatePosition = useCallback(async (fullAddress: FullAddress) => {
    console.warn("fullAddress", fullAddress);
    const formattedAddress = getFormattedAddress(fullAddress);
    console.warn("formattedAddress", formattedAddress);
    locationByAddress(formattedAddress).then((pos) => {
      if (pos.lat !== 0) {
        setPosition({
          latitude: pos.lat,
          longitude: pos.lng,
        });
      }
    });
  }, [setPosition]);

  const getFormattedAddress = (address: FullAddress): string => {
    const addressArray: string[] = [];

    if (address.street && address.street.trim().length) {
      addressArray.push(address.street.trim())
    }
    if (address.number && address.number.trim().length) {
      addressArray.push(address.number.trim());
    }
    if (address.complement && address.complement.trim().length) {
      // Complemento retirado por preenchimento errado pelos usuários e consequente erro de localização do Google
      //addressArray.push(address.complement.trim());
    }
    if (address.district && address.district.trim().length) {
      addressArray.push(address.district.trim());
    }
    if (address.city && address.city.trim().length) {
      addressArray.push(address.city.trim());
    }

    let result = addressArray.join(", ");
    if (address.state && address.state.trim().length) {
      result += ` - ${address.state}`;
    }
    return result + ", Brasil";
  };

  const handleCepChange = (postal_code: string) => {
    if (postal_code?.trim()) {
      cep(postal_code)
        .then((values: any) => {
          console.warn("cep result", values);
          let city = getCityByNameAndState(values.city, values.state);
          formik.setFieldError("postal_code", "");
          formik.setFieldValue("street", values.street);
          formik.setFieldValue("district", values.neighborhood);
          formik.setFieldValue("city", city ? city.id : "");

          const fullAddress: FullAddress = {
            postal_code: postal_code,
            street: values.street,
            number: formik.values.address_number,
            complement: formik.values.address_complement,
            district: values.neighborhood,
            city: city?.name || "",
            state: city?.state_initials || "",
          }

          updatePosition(fullAddress);
        })
        .catch((error: any) => {
          formik.setFieldError("postal_code", "CEP inválido");
          formik.setFieldValue("street", "");
          formik.setFieldValue("district", "");
          formik.setFieldValue("city", "");
        });
    }
  };

  const handleAddressNumberChange = (addressNumber: string) => {
    if (addressNumber) {
      let city = null;
      if (formik.values.city) {
        city = getCityById(formik.values.city);
      }
      const fullAddress: FullAddress = {
        postal_code: formik.values.postal_code,
        street: formik.values.street,
        number: addressNumber,
        complement: formik.values.address_complement,
        district: formik.values.district,
        city: city?.name || "",
        state: city?.state_initials || "",
      }
      updatePosition(fullAddress);
    }
  };

  const handleChangeImages = (
    imageField: keyof typeof INITIAL_IMAGE,
    imageValue: string | File | null
  ) => {
    const status = image[imageField].status === "" ? "loaded" : "changed";
    if (imageValue === null) {
      imageValue = new File([], "");
    }
    setImage(oldValues => {
      return {
        ...oldValues,
        [imageField]: { src: imageValue, error: "", status: status },
      }
    });
  };

  // Load data
  React.useEffect(() => {
    const src: any = imageToPreview.src;
    if (src !== "") {
      if (src instanceof File) {
        if (src.size > 0) {
          setFileSrc(URL.createObjectURL(src));
          if (src.type.startsWith("image")) {
            setFileType("image");
          } else {
            setFileType("object");
          }
        }
      } else if (src.startsWith("data:image")) {
        setFileSrc(src);
        setFileType("image");
      } else if (src.startsWith("data:")) {
        setFileSrc(src);
        setFileType("object");
      } else {
        setFileSrc("");
        setFileType(undefined);
        apiAxios.get(`${src}`,
          {
            responseType: 'arraybuffer',
          }).then((response) => {
            const blob = new Blob([response.data], {
              type: response.headers["content-type"],
            });
  
            if (blob.type.startsWith("image")) {
              setFileType("image");
            } else {
              setFileType("object");
            }
            let objectURL = URL.createObjectURL(blob);
            setFileSrc(objectURL);
          }).catch((error) => {
  
          });
      }
    } else {
      setFileSrc("");
      setFileType(undefined);
    }
  }, [imageToPreview.src]);

  return (
    <Dialog
      open={resolveReject.length === 2}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          {update ? "Editar Loja" : "Nova Loja"}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                      <CustomAvatar img={fileSrc} useCache={false} size={"lg"} />      
                  </Grid>
                  <Grid item md={9} style={{alignItems: "baseline"}}>                    
                    <UploadImage
                        error={!!image.photo.error}
                        name="photo"
                        initialFile={formik.values.photoText}
                        onChange={(image, url) => {
                          handleChangeImages("photo", image);
                          if (url) {
                            setImageToPreview({
                              title: "Loja",
                              src: url
                            });
                          }else{
                            setImageToPreview({
                              title: "Loja",
                              src: ""
                            });
                            setFileSrc("");
                          }                     
                        }}

                        title="Foto" />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      id="name"
                      label="Nome fantasia"
                      variant="outlined"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.errors.name}
                      error={!!formik.errors.name}
                      fullWidth
                    />
                  </Grid>

                  <Grid item md={6}>
                    <TextField
                      id="contact_name"
                      label="Pessoa para contato"
                      variant="outlined"
                      value={formik.values.contact_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.errors.contact_name}
                      error={!!formik.errors.contact_name}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      id="phonenumber"
                      label="Celular"
                      variant="outlined"
                      value={formik.values.phonenumber}
                      onChange={formik.handleChange}
                      fullWidth
                      error={!!formik.errors.phonenumber}
                      helperText={formik.errors.phonenumber}
                      InputProps={{
                        inputComponent: PhoneInput,
                      }}
                    />
                  </Grid>
                  <Grid item md={8}>
                    <TextField
                      id="email"
                      label="E-mail"
                      variant="outlined"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.errors.email}
                      error={!!formik.errors.email}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      id="postal_code"
                      label="CEP"
                      variant="outlined"
                      value={formik.values.postal_code}
                      onChange={formik.handleChange}
                      onBlur={(e) => handleCepChange(e.target.value)}
                      fullWidth
                      error={!!formik.errors.postal_code}
                      helperText={formik.errors.postal_code}
                      InputProps={{
                        inputComponent: PostCodeInput,
                      }}
                    />
                  </Grid>
                  <Grid item md={8}>
                    <TextField
                      id="street"
                      label="Rua"
                      variant="outlined"
                      value={formik.values.street}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      error={!!formik.errors.street}
                      helperText={formik.errors.street}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      id="address_number"
                      label="Nº"
                      variant="outlined"
                      value={formik.values.address_number}
                      onChange={formik.handleChange}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        handleAddressNumberChange(e.target.value);
                      }}
                      helperText={formik.errors.address_number}
                      error={!!formik.errors.address_number}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      id="address_complement"
                      label="Complemento"
                      variant="outlined"
                      value={formik.values.address_complement}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.errors.address_complement}
                      error={!!formik.errors.address_complement}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      id="district"
                      label="Bairro"
                      variant="outlined"
                      value={formik.values.district}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.errors.district}
                      error={!!formik.errors.district}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Cidade"
                      id="city"
                      variant="outlined"
                      name="city"
                      select
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      helperText={formik.errors.city}
                      error={!!formik.errors.city}
                      fullWidth
                    >
                      {props.cityList.map((city) => (
                        <MenuItem key={city.id} value={city.id}>
                          {`${city.name} - ${city.state_initials}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {update === false && (
                    <Grid item md={12} lg={12}>
                      <FormControlLabel
                          control={
                              <Checkbox
                              checked={formik.values.use_priority_queue}
                              onChange={formik.handleChange}                            
                              name="use_priority_queue"
                              color="primary"
                              />
                          }
                          label="Priorização na Fila"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.maps} md={6}>
              <SimpleMap
                setPosition={setPosition}
                latitude={latitude}
                longitude={longitude}
              />
              <Grid item className={classes.mapsInfo} md={12}>
                <Typography className={classes.mapsInfoText}>
                  Ajuste a localização da loja no mapa
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button
            disabled={isSubmitting}
            type="submit"
            color="primary"
          >
            <ShowIf condition={isSubmitting}>
              <CircularProgress size="1.5rem" color="inherit" style={{ marginRight: "0.5rem" }} />
            </ShowIf>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default React.forwardRef(AddEditStoreDialog);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    maps: {
      position: "relative",
    },
    mapsInfo: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      bottom: 30,
    },
    mapsInfoText: {
      background: "#fff",
      width: "80%",
      padding: 12,
      borderRadius: "4px",
      fontWeight: "bold",
      boxShadow: theme.shadows[5],
    },
  })
);
