import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import { StoreResponse } from "../../../services/types/customer";
import { FormikHelpers, useFormik } from "formik";
import { useHistory } from "react-router";
import { encodeURLParams } from "../../../utils/utils";
import { RegionResponse } from "../../../services/types/region";

export interface IStoreCourierQueueFilterParams {
  storeId: number | "";
  regionId: number | "";
}

export const INITIAL_FILTER_PARAMS: IStoreCourierQueueFilterParams = {
  storeId: "",
  regionId: "",
};

interface IStoreCourierQueueFilterProps {
  params?: IStoreCourierQueueFilterParams;
  regionList: RegionResponse[];
  storeList: StoreResponse[];
  onRefresh: () => void;
  onFilterChanged: (params: IStoreCourierQueueFilterParams) => void;
}

export const StoreCourierQueueFilter: React.FC<IStoreCourierQueueFilterProps> =
  ({ params = INITIAL_FILTER_PARAMS, regionList, storeList, onRefresh, onFilterChanged }) => {

    const history = useHistory();

    React.useEffect(() => {
      history.replace(encodeURLParams(history.location.pathname, params));
    }, [history, params]);

    const formik = useFormik<IStoreCourierQueueFilterParams>({
      initialValues: params,
      onSubmit: (values: IStoreCourierQueueFilterParams, formikHelpers: FormikHelpers<IStoreCourierQueueFilterParams>) => {
        onFilterChanged(values);
      }
    });

    return (
      <div className="page-filter">
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={3}>
              <TextField
                id="regionId"
                name="regionId"
                label="Região"
                required={true}
                select
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue('storeId', '');
                  formik.submitForm();
                }}
                value={formik.values.regionId}
                variant="outlined"
                fullWidth
              >
                {regionList.map((region) => (
                  <MenuItem key={region.id} value={region.id}>
                    {region.name} - {region.state}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={4}>
              <TextField
                id="storeId"
                name="storeId"
                select
                label="Loja"
                required={true}
                value={formik.values.storeId}
                disabled={storeList.length === 0}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.submitForm();
                }}
                variant="outlined"
                fullWidth
              >
                {storeList.map((store) => (
                  <MenuItem key={store.id} value={store.id}>
                    {store.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.values.storeId === ""}
                onClick={()=>onRefresh()}
              >
                Atualizar Fila
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };
