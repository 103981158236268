import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";

import { useTranslation } from "react-i18next";
import { CreditsFilter, ICreditsFilterParams, INITIAL_FILTER_PARAMS } from "./CreditsFilter";
import CustomerService from "../../services/customerService";
import { CURRENCY_COLUMN_DEFAULTS, DATE_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import { CustomerFinancialEntryResponse } from "../../services/types/financialEntry";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { useHistory } from "react-router-dom";
import { decodeURLParams, URLParamType } from "../../utils/utils";
import { City } from "../../services/types/common";
import CityService from "../../services/cityService";

export const Credit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [filterParams, setFilterParams] = useState<ICreditsFilterParams>({
    ...INITIAL_FILTER_PARAMS,
    ...decodeURLParams(history.location.search, [
      { "createdAtAfter": URLParamType.DATE },
      { "createdAtBefore": URLParamType.DATE },
      { "paymentDateAfter": URLParamType.DATE },
      { "paymentDateBefore": URLParamType.DATE }
    ])
  });
  const [citySelected, setCitySelected] = useState<City>();
  const [cityList, setCityList] = useState<City[]>([]);

  const tableRef = React.useRef<MaterialTableProps<CustomerFinancialEntryResponse>>();

  const loadData = (query: Query<CustomerFinancialEntryResponse>) => {
    const customerId = filterParams.customer ? Number(filterParams.customer) : null;
    const createdAtAfter = filterParams.createdAtAfter ? filterParams.createdAtAfter : null;
    const createdAtBefore = filterParams.createdAtBefore ? filterParams.createdAtBefore : null;
    const paymentDateAfter = filterParams.paymentDateAfter ? filterParams.paymentDateAfter : null;
    const paymentDateBefore = filterParams.paymentDateBefore ? filterParams.paymentDateBefore : null;
    const origin = filterParams.origin ? filterParams.origin : undefined;
    const status = filterParams.status ? filterParams.status : undefined;
    return new Promise<QueryResult<CustomerFinancialEntryResponse>>((resolve, reject) => {
      CustomerService.getCreditFinancialEntries(customerId, createdAtAfter, createdAtBefore,
        paymentDateAfter, paymentDateBefore, origin, status, PageableParams.fromQuery(query))
        .then(response => {
          resolve({
            data: response.data,
            page: query.page,
            totalCount: response.count,
          })
        })
    });
  }

  useEffect(() => {
    const getCities = async () => {
        const response = await CityService.getCities();
        setCityList(response);
    };

    getCities();
}, []);

  const applyFilter = (params: ICreditsFilterParams) => {
    setFilterParams(params);
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({ page: 0 } as Query<CustomerFinancialEntryResponse>);
    }
  }

  const onChangeCity = (city: City | undefined) => {
    setCitySelected(city);
  }

  useEffect(() => {
    const getCustomers = async () => {
      const response = await CustomerService.getCustomers("", "", "", "", citySelected?.name, true, undefined, new PageableParams(1, 500, "trade_name"));
      setCustomerList(response.data);
    };

    getCustomers();
  }, [citySelected]);

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Créditos</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[{ label: "Créditos", url: "/credits" }]}
            />
          </Grid>
        </Grid>
      </Grid>

      <CreditsFilter params={filterParams} customerList={customerList} cityList={cityList} onFilterChanged={applyFilter} onChangeCity={onChangeCity} />

      <MaterialTable
        tableRef={tableRef}
        columns={[
          { field: "id", hidden: true, width: 60 },
          {
            ...DATE_COLUMN_DEFAULTS,
            field: "created_at", title: "Data do Lançamento", defaultSort: "desc"
          },
          {
            ...DATE_COLUMN_DEFAULTS, emptyValue: "---",
            field: "payment_date", title: "Data do Pagamento"
          },
          { field: "customer_trade_name", title: "Cliente" },
          {
            ...CURRENCY_COLUMN_DEFAULTS,
            field: "value", title: "Valor"
          },
          {
            ...CURRENCY_COLUMN_DEFAULTS,
            field: "tax", title: "Taxas"
          },
          {
            ...CURRENCY_COLUMN_DEFAULTS,
            field: "total", title: "Valor Todal"
          },
          {
            field: "origin", title: "Origem", emptyValue: "---",
            render: (rowData, type) => t("financial_entry_origin." + rowData.origin)
          },
          {
            field: "status", title: "Situação",
            render: (rowData, type) => t("financial_entry_status." + rowData.status)
          },
        ]}
        data={loadData}
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_L10N_PTBR}
        options={DEFAULT_TABLE_OPTIONS}
      />

    </div>
  );
};