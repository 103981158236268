import React, { useEffect, useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import {
    DeliveryRequestFilter,
    IDeliveryRequestFilterParams,
    INITIAL_FILTER_PARAMS,
} from "./DeliveryRequestFilter";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { useTranslation } from "react-i18next";
import DeliveryRequestService from "../../services/deliveryRequestService";
import CustomerService from "../../services/customerService";
import RegionService from "../../services/regionService";
import {
    DATE_TIME_COLUMN_DEFAULTS,
    DEFAULT_TABLE_COMPONENTS,
    DEFAULT_TABLE_OPTIONS,
    PageableParams,
    TABLE_L10N_PTBR,
} from "../../components/TableTrinkets";
import { mapDeliveryRequestStatusToSeverity } from "./deliveryRequestUtils";
import { DeliveryRequestResponse } from "../../services/types/deliveryRequest";
import MaterialTable, {
    MaterialTableProps,
    Query,
    QueryResult,
} from "@material-table/core";
import { VisibilityOutlined } from "@material-ui/icons";
import { convertToBoolean, convertToNumber, decodeURLParams, URLParamType } from "../../utils/utils";
import ShowIf from "../../components/ShowIf";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { Link } from "react-router-dom";
import AuthService from "../../services/authService";



interface IRequestsProps { }

export const RequestsHistory: React.FC<IRequestsProps> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [storeList, setStoreList] = useState<any[]>([]);
    const [regionList, setRegionList] = useState<any[]>([]);
    const [filterParams, setFilterParams] = useState<IDeliveryRequestFilterParams>({
        ...INITIAL_FILTER_PARAMS,
        ...decodeURLParams(history.location.search, [
            { "requested_time_after": URLParamType.DATE_TIME },
            { "requested_time_before": URLParamType.DATE_TIME }
        ])
    });

    const tableRef = React.useRef<MaterialTableProps<DeliveryRequestResponse>>();

    const loadData = (query: Query<DeliveryRequestResponse>) =>
        new Promise<QueryResult<DeliveryRequestResponse>>((resolve, reject) => {
            DeliveryRequestService.getDeliveryRequests(
                filterParams.request_number, filterParams.order_number,
                filterParams.requested_time_after, filterParams.requested_time_before,
                filterParams.courier_name, filterParams.store_id,
                filterParams.status, convertToBoolean(filterParams.manual_creation),
                convertToNumber(filterParams.region), PageableParams.fromQuery(query)
            ).then(response => {
                resolve({
                    data: response.data,
                    page: query.page,
                    totalCount: response.count,
                });
            }).catch((error) => reject(error));
        });

    const applyFilter = (params: IDeliveryRequestFilterParams) => {
        setFilterParams(params);
        if (tableRef.current?.onQueryChange) {
            tableRef.current.onQueryChange({
                page: 0,
            } as Query<DeliveryRequestResponse>);
        }
    };

    const selectRegion = (regionId: number | null) => {
        if (regionId) {
            getStores([regionId]);
        } else {
            setStoreList([]);
        }
    };

    const getStores = async (regionsIds: number[]) => {
        const response = await CustomerService.getStores(undefined, undefined,
            regionsIds, undefined, new PageableParams(1, 100, "name"));
        setStoreList(response.data);
    };

    useEffect(() => {

        const getRegions = async () => {
            const response = await RegionService.loadAllRegions({
                ordering: "name",
            });
            setRegionList(response);
        };
        getRegions();
    }, []);

    const showDetails = async (item: DeliveryRequestResponse) => {
        history.push(`/requests/detail/${item.id}`);
    };

    return (
        <>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Histórico de Solicitações</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[{ label: "Histórico de Solicitações", url: "/requests" }]}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <ShowIf condition={AuthService.hasAuthorization("add_delivery")}>
                        <Button color="primary" variant="contained"
                            startIcon={<AddCircleOutlineRoundedIcon />}
                            component={Link} to="/requests/new">
                            Entrega Realizada
                        </Button>
                    </ShowIf>
                </Grid>
            </Grid>

            <DeliveryRequestFilter
                params={filterParams}
                storeList={storeList}
                regionList={regionList}
                selectRegion={selectRegion}
                onFilterChanged={applyFilter}
            />

            <MaterialTable
                tableRef={tableRef}
                columns={[
                    { field: "id", hidden: true, width: 60 },
                    {
                        field: "manual_creation",
                        title: "Tipo de Solicitação",
                        render: (rowData, type) =>
                            rowData.manual_creation ? "Manual" : "Busca",
                    },
                    { field: "request_number", title: "Solicitação" },
                    { field: "order_number", title: "Pedido" },
                    { field: "courier_name", title: "Entregador", emptyValue: "---" },
                    { field: "store_name", title: "Loja" },
                    {
                        ...DATE_TIME_COLUMN_DEFAULTS,
                        field: "requested_time",
                        title: "Data e Hora",
                        defaultSort: "desc",
                        emptyValue: "---"
                    },
                    {
                        field: "status",
                        title: "Situação",
                        render: (rowData, type) => (
                            <Alert
                                severity={mapDeliveryRequestStatusToSeverity(
                                    rowData.status
                                )}
                                icon={false}
                            >
                                {t("delivery_request_status." + rowData.status)}
                            </Alert>
                        ),
                    },
                ]}
                data={loadData}
                actions={[
                    {
                        icon: () => <VisibilityOutlined />,
                        tooltip: "Ver Detalhes",
                        onClick: (event, rowData) =>
                            showDetails(rowData as DeliveryRequestResponse),
                    },
                ]}
                components={DEFAULT_TABLE_COMPONENTS}
                localization={TABLE_L10N_PTBR}
                options={{
                    ...DEFAULT_TABLE_OPTIONS,
                    pageSize: 25,
                    pageSizeOptions: [25, 50, 100]
                }}
            />
        </>
    );
};
