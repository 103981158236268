import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import RegionService from "../../services/regionService";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import { ReferralCodesCreate } from "../../services/types/referral";
import ReferralService from "../../services/referralService";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import * as Yup from "yup";
import numbro from "numbro";
import SelectCourier from "../announcement/SelectCourier";
import { $enum } from "ts-enum-util";
import { CourierAccountStatus } from "../../services/types/courier";

export enum TypeCreateReferral {
    ALL_COURIERS = "ALL_COURIERS",
    SPECIFIC_COURIERS = "SPECIFIC_COURIERS",
    PER_REGION = "PER_REGION"
}

export interface ReferralCreate {
    couriers: number[],
    all_couriers: TypeCreateReferral,
    number_of_codes: number,
    region: number | null
}

export const INITIAL_FORM_VALUES: ReferralCreate = {
    couriers: [],
    all_couriers: TypeCreateReferral.ALL_COURIERS,
    number_of_codes: 1,
    region: null
};

const validationSchema = Yup.object().shape({
    couriers: Yup.array()
        .when("all_couriers", {
            is: (value) => value === TypeCreateReferral.SPECIFIC_COURIERS,
            then: Yup.array().min(1, "É necessário informar pelo menos 1 entregador"),
            otherwise: Yup.array().nullable().optional()
        }),
    region: Yup.number()
         .when("all_couriers", {
             is: (value) => value === TypeCreateReferral.PER_REGION,
             then: Yup.number().nullable().required("Campo obrigatório"),
             otherwise: Yup.number().nullable().optional()
         }),
    number_of_codes: Yup.number()
        .required("Campo obrigatório")
        .transform((currentValue, originalValue) => numbro.unformat(originalValue))
        .moreThan(0, "Campo obrigatório")
        .max(2, "Certifique-se de que este valor seja inferior ou igual a 2")
});

export const NewReferral = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [selectedRegion, setSelectedRegion] = useState<any>(null);
    const [initialCouriers, setInitialCouriers] = useState<any[]>([]);

    const [regionList, setRegionList] = useState<any[]>([]);

    useEffect(() => {
        const getRegions = async () => {
            const response = await RegionService.loadAllRegions({
                ordering: "name",
            });
            setRegionList(response);
        };
        getRegions();
    }, []);

    const formik = useFormik({
        initialValues: INITIAL_FORM_VALUES,
        onSubmit: async (values) => {
            try {
                let isAllCouriers: boolean = values.all_couriers === TypeCreateReferral.ALL_COURIERS ? true : false;
                const addReferral: ReferralCodesCreate = {
                    couriers: values.couriers ? values.couriers : [],
                    all_couriers: isAllCouriers,
                    number_of_codes: values.number_of_codes,
                    region: values.region ? values.region : null
                }

                  await ReferralService.createReferralCodes(addReferral);
                  NotificationManager.success(
                      "Códigos de Indicação criado com Sucesso!",
                      "Códigos de Indicação"
                  );
                  formik.setFieldValue("all_couriers", TypeCreateReferral.ALL_COURIERS);
                  handleClearInputs();
                  
            } catch (error) {
                const { status, data } = error.response;
                if (status === 400) {
                    for (var key in data) {
                        var value = data[key];
                        formik.setFieldError(key, value);
                    }
                } else {
                    NotificationManager.error(
                        "Não foi possível criar Códigos de Indicação!",
                        "Códigos de Indicação"
                    );
                }
            }
        },
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
    });

    const handleCouriersChange = (listCouriersId: number[]) => {
        formik.setFieldValue("couriers", listCouriersId);
    };

    const handleClearInputs = () => {
        setInitialCouriers([]);
        formik.setFieldValue("couriers", []);
        formik.setFieldValue("region", null);
        setSelectedRegion(null);
    };

    return (
        <div>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Adicionar Códigos de Indicação</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[{ label: "Adicionar Códigos de Indicação", url: "/newReferral" }]}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <form onSubmit={formik.handleSubmit}>
                <Grid container md={12} spacing={2}>
                    <Grid item md={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h2">Adicionar Códigos de Indicação</Typography>
                                <Grid container spacing={2} direction="column">
                                <Grid item md={12}>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                row
                                                aria-label="all_couriers"
                                                name="all_couriers"
                                                value={formik.values.all_couriers}                                              
                                                onChange={(e)=>{
                                                    formik.handleChange(e);
                                                    handleClearInputs();
                                                }}
                                            >
                                                {$enum(TypeCreateReferral).map(
                                                    (option: string) => {
                                                        return (
                                                            <FormControlLabel
                                                                key={option}
                                                                control={<Radio />}
                                                                value={option}
                                                                disabled={formik.isSubmitting}
                                                                label={t("referral_create_for_options." + option)}
                                                            />
                                                        );
                                                    }
                                                )}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {formik.values.all_couriers === TypeCreateReferral.SPECIFIC_COURIERS && (
                                        <>
                                            <Grid item xl={6} md={8} sm={10} xs={12}>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item>
                                                        <Typography variant="subtitle1">Selecione os Entregadores</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <SelectCourier
                                                            formik={formik}
                                                            status_courier={CourierAccountStatus.ACTIVE}
                                                            initialCouriers={initialCouriers}
                                                            onCouriersChange={(e)=>{
                                                                handleCouriersChange(e);
                                                            }} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}

                                    {formik.values.all_couriers === TypeCreateReferral.PER_REGION && (
                                        <>
                                            <Grid item xl={2} md={3} sm={4} xs={12}>
                                                <Autocomplete
                                                    id="region"
                                                    size="small"
                                                    value={selectedRegion}
                                                    options={regionList}
                                                    getOptionLabel={option =>
                                                        `${option.name} - ${option.state}`
                                                    }
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setSelectedRegion(newValue);
                                                            formik.setFieldValue("region", newValue.id);
                                                        } else {
                                                            setSelectedRegion(null);
                                                            formik.setFieldValue("region", null);
                                                        }
                                                    }}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            name="region"
                                                            label="Região"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.region}
                                                            error={!!formik.errors.region}
                                                            helperText={formik.errors.region}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </>
                                    )}


                                    <Grid item xl={2} md={3} sm={4} xs={12} style={{marginTop: formik.values.all_couriers === TypeCreateReferral.SPECIFIC_COURIERS ? "15px" : "5px"}}>
                                        <TextField
                                            id="number_of_codes"
                                            label="Quantidade de Códigos"
                                            variant="outlined"
                                            type="number"
                                            required={true}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                            }}
                                            value={formik.values.number_of_codes}
                                            error={!!formik.errors.number_of_codes}
                                            helperText={formik.errors.number_of_codes}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "15px" }}>
                    <Grid item>
                        <Button color="primary" variant="contained" type="submit" disabled={formik.isSubmitting}>
                            {formik.isSubmitting ? (
                                <CircularProgress size="1.5rem" color="inherit" />
                            ) : (
                                <span>Salvar</span>
                            )}
                        </Button>
                    </Grid>
                </Grid>

            </form>
        </div>
    );
};