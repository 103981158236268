import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import ShowIf from "../../../components/ShowIf";
import { CourierResponse } from "../../../services/types/courier";
import CourierService from "../../../services/courierService";
import { debounce } from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { ImgLoader } from "../../../components/ImgLoader";
import { CustomerResponse } from "../../../services/types/customer";
import CustomerService from "../../../services/customerService";
import { PageableParams } from "../../../components/TableTrinkets";

interface BlockCourierDialogProps {
  customerId?: number;
  courierId?: number;
}

export interface BlockCourierDialogHandle {
  add: () => Promise<BlockCourierFormResponse>;
  showFieldErrors: (errors: any) => string | null;
  hide: () => void;
}

export interface BlockCourierFormResponse {
  courier: number,
  customer: number | null
  duration: number,
  reason: string
}

export interface BlockCourierFormData {
  courier: number | null,
  customer: number | null
  duration: number,
  reason: string
}

const INITIAL_FORM_VALUES: BlockCourierFormData = {
  courier: null,
  customer: null,
  duration: 0,
  reason: ""
}

const validationSchema = Yup.object().shape({
  courier: Yup.number().required("Campo obrigatório"),
  duration: Yup.number().required("Campo obrigatório"),
  reason: Yup.string().required("Campo obrigatório")
});

const BlockCourierDialog: React.ForwardRefRenderFunction<BlockCourierDialogHandle, BlockCourierDialogProps> = (props, ref) => {
  const [resolveReject, setResolveReject] = React.useState<any[]>([]);
  const [resolve, reject] = resolveReject;

  const [courierList, setCourierList] = useState<CourierResponse[]>([]);
  const [selectedCourier, setSelectedCourier] = useState<CourierResponse>();
  const [courierSearchValue, setCourierSearchValue] = useState<string>("");

  const [customerList, setCustomerList] = React.useState<CustomerResponse[]>([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState<CustomerResponse>();
  const [customerSearchValue, setCustomerSearchValue] = React.useState<string>("");

  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  React.useImperativeHandle(ref, () => ({
    add(): Promise<BlockCourierFormResponse> {
      return add();
    },

    showFieldErrors(errors: any) {
      const remainingErrorMsgs: string[] = [];
      let errorMsg = null;
      for (var key in errors) {
        var value = errors[key];
        if (key in INITIAL_FORM_VALUES) {
          formik.setFieldError(key, value);
        } else {
          remainingErrorMsgs.push(value);
        }
      }

      if (remainingErrorMsgs.length > 0) {
        errorMsg = remainingErrorMsgs.join(". \n");
      }
      return errorMsg;
    },

    hide() {
      close();
    }
  }));

  const add = (): Promise<BlockCourierFormResponse> => {
    setSubmitting(false);
    setSelectedCourier(undefined);
    setSelectedCustomer(undefined);
    formik.resetForm();
    return new Promise<BlockCourierFormResponse>((resolve, reject) => {
      setResolveReject([resolve, reject]);
    });
  }

  const close = () => {
    setResolveReject([]);

    setCourierList([]);
    setCourierSearchValue("");

    setCustomerList([]);
    setCustomerSearchValue("");

    setSelectedCourier(undefined);
    setSelectedCustomer(undefined);
    formik.resetForm();
    setSubmitting(false);
  }

  const updateCourierList = React.useMemo(() => debounce((name: string) => {
    if (name.trim().length > 3) {
      CourierService.getCouriersByName(name).then(data => {
        setCourierList(data);
      });
    } else {
      setCourierList([]);
    }
  }, 300, { trailing: true }), [setCourierList]);

  const updateCustomerList = React.useMemo(() => debounce((name: string) => {
    if (name?.trim().length > 2) {
      CustomerService.getCustomers(name, undefined, undefined, undefined, undefined, undefined, undefined, new PageableParams(1, 25, "trade_name"))
        .then((response) => {
          setCustomerList(response.data);
        });
    }
  }, 300, { trailing: true }), [setCustomerList]);

  const formik = useFormik({
    initialValues: {
      ...INITIAL_FORM_VALUES,
      courier: props.courierId || INITIAL_FORM_VALUES.courier,
      customer: props.customerId || INITIAL_FORM_VALUES.customer,
    },
    onSubmit: (values) => {
      resolve(values);
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  const handleClose = () => {
    reject();
    close();
  };

  return (
    <Dialog
      open={resolveReject.length === 2}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          Bloquear Entregador
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <ShowIf condition={props.courierId === undefined}>
              <Grid item sm={12}>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    options={courierList}
                    getOptionLabel={(courier) => `${courier.name} - ${courier.region.name} - ${courier.region.state}`}
                    value={selectedCourier}
                    size="small"
                    inputValue={courierSearchValue}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setSelectedCourier(newValue);
                        formik.setFieldValue("courier", newValue.id);
                      } else {
                        setSelectedCourier(undefined);
                        formik.setFieldValue("courier", null);
                      }
                    }}
                    onInputChange={(e: object, value: string, reason: string) => {
                      setCourierSearchValue(value);
                      if (reason === "input") {
                        updateCourierList(value);
                      }
                    }}
                    loadingText="Aguarde ..."
                    noOptionsText="Sem opções"
                    renderOption={(courier) => (
                      <React.Fragment>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item>
                            <ImgLoader src={courier.photo} width={40} height={40} />
                          </Grid>
                          <Grid item>
                            {`${courier.name} - ${courier.region.name} - ${courier.region.state}`}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Entregador"
                        helperText={formik.errors.courier || "Digite pelo menos 4 letras para realizar a consulta"}
                        error={!!formik.errors.courier}
                        required={true}
                        InputLabelProps={{ required: true }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </ShowIf>

            <ShowIf condition={props.customerId === undefined}>
              <Grid item sm={12}>
                <Autocomplete
                  options={customerList}
                  getOptionLabel={(customer: CustomerResponse) => customer.trade_name}
                  value={selectedCustomer}
                  size="small"
                  inputValue={customerSearchValue}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSelectedCustomer(newValue);
                      formik.setFieldValue("customer", newValue.id);
                    } else {
                      setSelectedCustomer(undefined);
                      formik.setFieldValue("customer", null);
                    }
                  }}
                  onInputChange={(e: object, value: string, reason: string) => {
                    setCustomerSearchValue(value);
                    if (reason === "input") {
                      updateCustomerList(value);
                    }
                  }}
                  loadingText="Aguarde ..."
                  noOptionsText="Sem opções"
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label="Cliente - Deixe em branco para 'Todos'"
                      helperText={formik.errors.customer || "Digite pelo menos 3 caracteres para realizar a busca"}
                      error={!!formik.errors.customer}
                      variant="outlined"
                      fullWidth
                    />
                  }
                />
              </Grid>
            </ShowIf>

            <Grid item sm={12}>
              <TextField
                id="duration"
                label="Duração - 0 (zero) para tempo indeterminado"
                variant="outlined"
                type="number"
                value={formik.values.duration}
                onChange={formik.handleChange}
                helperText={formik.errors.duration}
                error={!!formik.errors.duration}
                fullWidth
                required={true}
                InputLabelProps={{ required: true }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">minutos</InputAdornment>,
                }}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                id="reason"
                label="Motivo"
                variant="outlined"
                value={formik.values.reason}
                onChange={formik.handleChange}
                helperText={formik.errors.reason}
                error={!!formik.errors.reason}
                fullWidth
                required={true}
                InputLabelProps={{ required: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button
            disabled={isSubmitting}
            type="submit"
            color="primary"
          >
            <ShowIf condition={isSubmitting}>
              <CircularProgress size="1.5rem" color="inherit" style={{ marginRight: "0.5rem" }} />
            </ShowIf>
            Bloquear
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default React.forwardRef(BlockCourierDialog);
