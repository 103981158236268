import { AnnouncementResponseDetail, AnnouncementType, CreateUpdateAnnouncement, DeliveryForType, ScreenType } from "../../services/types/announcement";

export enum DeliveryForOptions {
    ALL_COURIERS = "ALL_COURIERS",
    ACTIVE_COURIERS = "ACTIVE_COURIERS",
    REGION = "REGION",
    REGION_ACTIVE_COURIERS = "REGION_ACTIVE_COURIERS",
    SPECIFIC_COURIERS = "SPECIFIC_COURIERS"
}

export interface AnnouncementForm {
    title: string;
    content: string;
    region: number | "";
    screen: ScreenType | "";
    announcement_type: AnnouncementType;
    send_date?: Date;
    expire_date?: Date;
    picture: string;
    delivery_for: DeliveryForOptions;
    couriers: number[];
    only_active_couriers: boolean;
    related_trip: number | null;
}

export function convertDeliveryForTypeToOption(type: DeliveryForType, only_active_couriers: boolean): DeliveryForOptions {
    let delivery_option: DeliveryForOptions;
    switch (type) {
        case DeliveryForType.ALL_COURIERS:
            if (only_active_couriers) {
                delivery_option = DeliveryForOptions.ACTIVE_COURIERS;
            } else {
                delivery_option = DeliveryForOptions.ALL_COURIERS;
            }
            break;
        case DeliveryForType.REGION:
            if (only_active_couriers) {
                delivery_option = DeliveryForOptions.REGION_ACTIVE_COURIERS;
            } else {
                delivery_option = DeliveryForOptions.REGION;
            }
            break;
        default:
            delivery_option = DeliveryForOptions.SPECIFIC_COURIERS;
            break;
    }

    return delivery_option;
}

export function convertAnnouncementDetailToAnnouncementForm(announcement: AnnouncementResponseDetail): AnnouncementForm {
    const delivery_option = convertDeliveryForTypeToOption(announcement.delivery_for, announcement.only_active_couriers);

    const result: AnnouncementForm = {
        related_trip: announcement.related_trip,
        title: announcement.title,
        content: announcement.content,
        region: announcement.region ? announcement.region.id : "",
        screen: announcement.screen ? announcement.screen : "",
        announcement_type: announcement.announcement_type,
        send_date: announcement.send_date ? announcement.send_date : undefined,
        expire_date: announcement.expire_date ? announcement.expire_date : undefined,
        picture: announcement.picture ? announcement.picture : "",
        delivery_for: delivery_option,
        couriers: Array.isArray(announcement.couriers) ? announcement.couriers.map(courier => courier.id) : [],
        only_active_couriers: announcement.only_active_couriers
    };

    return result;
}

export function convertAnnouncementFormToCreateUpdateAnnouncement(values: AnnouncementForm): CreateUpdateAnnouncement {
    let couriers: number[];
    let region: number | undefined;
    let only_active_couriers: boolean;
    let delivery_for: DeliveryForType;
    switch (values.delivery_for) {
        case DeliveryForOptions.ALL_COURIERS:
            delivery_for = DeliveryForType.ALL_COURIERS;
            region = undefined;
            couriers = [];
            only_active_couriers = false;
            break;
        case DeliveryForOptions.ACTIVE_COURIERS:
            delivery_for = DeliveryForType.ALL_COURIERS;
            region = undefined;
            couriers = [];
            only_active_couriers = true;
            break;
        case DeliveryForOptions.REGION:
            delivery_for = DeliveryForType.REGION;
            region = values.region ? values.region : undefined;
            couriers = [];
            only_active_couriers = false;
            break;
        case DeliveryForOptions.REGION_ACTIVE_COURIERS:
            delivery_for = DeliveryForType.REGION;
            region = values.region ? values.region : undefined;
            couriers = [];
            only_active_couriers = true;
            break;
        default:
            delivery_for = DeliveryForType.SPECIFIC_COURIERS;
            region = undefined;
            couriers = values.couriers;
            only_active_couriers = false;
            break;
    }

    let send_date: Date | undefined;
    if (values.announcement_type === AnnouncementType.IMMEDIATE) {
        send_date = undefined;
    } else {
        send_date = values.send_date ? values.send_date : undefined;
    }

    const result: CreateUpdateAnnouncement = {
        related_trip: values.related_trip,
        title: values.title,
        content: values.content,
        region: region,
        screen: values.screen,
        announcement_type: values.announcement_type,
        send_date: send_date,
        expire_date: values.expire_date ? values.expire_date : undefined,
        picture: values.picture ? values.picture : undefined,
        delivery_for: delivery_for,
        couriers: couriers,
        only_active_couriers: only_active_couriers
    };

    return result;
}