import React from "react";
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, TextField } from "@material-ui/core";
import ShowIf from "../../../components/ShowIf";
import { UUIDInput } from "../../../components/UUIDInput";
import { AddCustomerIntegration, CredentialsIntegration, CustomerIntegrationSource, EditCustomerIntegration, StoreResponse } from "../../../services/types/customer";
import * as Yup from "yup";
import { noSpecialCharactersRegex, UUID_REGEX } from "../../../utils/masks";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import CustomerService from "../../../services/customerService";

interface AddEditCustomerIntegrationDialogProps {
    storeList: StoreResponse[];
    onSaved?: () => void;
    onError?: () => void;
}

export interface AddEditCustomerIntegrationDialogHandle {
    add: () => void;
    edit: (id: number) => void;
}

interface AddEditCustomerIntegrationForm {
    name: string;
    integration_source: CustomerIntegrationSource | "";
    initials: string;
    store_id: number | "";
    store_identifier: string;
    key: string | undefined;
    x_deliverydireto_id: string | undefined;
    delivery_direto_user: string;
    delivery_direto_password: string | undefined;
    password_confirmation: string | undefined;
    external_email: string | undefined;
}

const INITIAL_VALUES: AddEditCustomerIntegrationForm = {
    store_id: "",
    name: "",
    initials: "",
    integration_source: "",
    store_identifier: "",
    key: "",
    x_deliverydireto_id: "",
    delivery_direto_user: "",
    delivery_direto_password: "",
    password_confirmation: "",
    external_email: ""
};

const AddCustomerIntegrationValidation = Yup.object().shape({
    store_id: Yup.number()
        .required("Campo obrigatório"),
    name: Yup.string()
        .max(20, "Máximo de 20 caracteres excedido")
        .matches(noSpecialCharactersRegex, "O nome não pode conter caracteres especiais")
        .required("Campo obrigatório"),
    initials: Yup.string()
        .max(5, "Máximo de 5 caracteres excedido")
        .matches(noSpecialCharactersRegex, "As iniciais não podem conter caracteres especiais")
        .required("Campo obrigatório"),
    integration_source: Yup.string().required("Campo obrigatório"),
    store_identifier: Yup.string()
        .max(36, "Máximo de 36 caracteres excedido")
        .matches(UUID_REGEX, "UUID inválido")
        .when("integration_source", {
            is: (value) => [CustomerIntegrationSource.IFOOD].includes(value),
            then: Yup.string().nullable().required("Campo obrigatório"),
            otherwise: Yup.string().nullable().optional()
        }),
    key: Yup.string()
        .max(100, "Máximo de 100 caracteres excedido")
        .when("integration_source", {
            is: (value) => [CustomerIntegrationSource.DELIVERY_DIRETO, CustomerIntegrationSource.MEU_XODO, CustomerIntegrationSource.NEEMO].includes(value),
            then: Yup.string().nullable().required("Campo obrigatório"),
            otherwise: Yup.string().nullable().optional()
        }),     
    delivery_direto_user: Yup.string()
        .max(100, "Máximo de 100 caracteres excedido")
        .when("integration_source", {
            is: (value) => [CustomerIntegrationSource.DELIVERY_DIRETO].includes(value),
            then: Yup.string().nullable().required("Campo obrigatório"),
            otherwise: Yup.string().nullable().optional()
        }),
    x_deliverydireto_id: Yup.string()
        .max(100, "Máximo de 100 caracteres excedido")
        .when("integration_source", {
            is: (value) => [CustomerIntegrationSource.DELIVERY_DIRETO].includes(value),
            then: Yup.string().nullable().required("Campo obrigatório"),
            otherwise: Yup.string().nullable().optional()
        }),    
    delivery_direto_password: Yup.string()
        .min(6, "Mínimo de 6 caracteres")
        .max(50, "Senha muito longa")
        .when("integration_source", {
            is: (value) => [CustomerIntegrationSource.DELIVERY_DIRETO].includes(value),
            then: Yup.string().nullable().required("Campo obrigatório"),
            otherwise: Yup.string().nullable().optional()
        }),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("delivery_direto_password"), ""], "As senhas não conferem")
        .when("integration_source", {
            is: (value) => [CustomerIntegrationSource.DELIVERY_DIRETO].includes(value),
            then: Yup.string().nullable().required("Campo obrigatório"),
            otherwise: Yup.string().nullable().optional()
        }),  
    external_email: Yup.string()
        .email('Insira um email válido')
        .max(100, "Máximo de 100 caracteres excedido")
        .when("integration_source", {
            is: (value) => [CustomerIntegrationSource.MEU_XODO].includes(value),
            then: Yup.string().nullable().required("Campo obrigatório"),
            otherwise: Yup.string().nullable().optional()
        }),   
            
});

const EditCustomerIntegrationValidation = Yup.object().shape({
    store_id: Yup.number()
        .required("Campo obrigatório"),
    name: Yup.string()
        .max(20, "Máximo de 20 caracteres excedido")
        .matches(noSpecialCharactersRegex, "O nome não pode conter caracteres especiais")
        .required("Campo obrigatório"),
    initials: Yup.string()
        .max(5, "Máximo de 5 caracteres excedido")
        .matches(noSpecialCharactersRegex, "As iniciais não podem conter caracteres especiais")
        .required("Campo obrigatório"),
    integration_source: Yup.string().required("Campo obrigatório"),
    delivery_direto_user: Yup.string()
    .max(36, "Máximo de 36 caracteres excedido")
    .when("integration_source", {
        is: (value) => [CustomerIntegrationSource.DELIVERY_DIRETO].includes(value),
        then: Yup.string().nullable().required("Campo obrigatório"),
        otherwise: Yup.string().nullable().optional()
    }),
});

export const AddEditCustomerIntegrationDialog: React.ForwardRefRenderFunction<AddEditCustomerIntegrationDialogHandle, AddEditCustomerIntegrationDialogProps> = (props, ref) => {
    const { t } = useTranslation();

    const [id, setId] = React.useState<number>();
    const [open, setOpen] = React.useState<boolean>(false);
    const [checked, setChecked] = React.useState(false);
    const handleChangeCheck = (event: any) => {
        setChecked(event.target.checked);
    };
    const [isEdit, setIsEdit] = React.useState(false);

    React.useImperativeHandle(ref, () => ({
        add() {
            add();
        },

        edit(id: number) {
            edit(id);
        }
    }));

    function add() {
        formik.resetForm();
        setChecked(false);
        setIsEdit(false);
        setOpen(true);
    }

    const edit = async (id: number) => {
        try {
            setId(id);
            formik.resetForm();
            const customerIntegration = await CustomerService.getCustomerIntegrationById(id);
            const values: AddEditCustomerIntegrationForm = {
                store_id: customerIntegration.store.id,
                name: customerIntegration.name,
                initials: customerIntegration.initials,
                integration_source: customerIntegration.integration_source,
                store_identifier: customerIntegration.credentials.key || "",//customerIntegration.store_identifier,
                key: customerIntegration.credentials.key ? customerIntegration.credentials.key : undefined,
                x_deliverydireto_id: customerIntegration.credentials.x_deliverydireto_id,
                delivery_direto_user: customerIntegration.credentials.delivery_direto_user,
                delivery_direto_password: customerIntegration.delivery_direto_password,
                password_confirmation: customerIntegration.password_confirmation,
                external_email: customerIntegration.credentials.external_email || ""
            };
            formik.setValues(values);
            setChecked(false);
            setIsEdit(true);
            setOpen(true);
        } catch (error) {
            NotificationManager.error(
                "Não foi possível recuperar os dados da Integração.",
                "Integração"
            );
        }
    }

    const close = () => {
        setOpen(false);
        setId(undefined);
        setChecked(false);
        setIsEdit(false);
        formik.resetForm();
    }

    const formik = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: async (values) => {
            try {
                if (id) {
                    const credentials : CredentialsIntegration = {
                        delivery_direto_user: values.delivery_direto_user,
                        delivery_direto_password: values.delivery_direto_password && values.password_confirmation ? values.delivery_direto_password : undefined,
                        password_confirmation: values.delivery_direto_password && values.password_confirmation ? values.password_confirmation : undefined,
                        key: values.key,
                        x_deliverydireto_id: values.x_deliverydireto_id,
                        external_email: values.external_email
                    }
                    const integration: EditCustomerIntegration = {
                        name: values.name,
                        initials: values.initials,
                        credentials: credentials                      
                    }
                    await CustomerService.updateCustomerIntegration(id, integration);
                } else {
                    const integration: AddCustomerIntegration = {
                        ...values,
                        store_id: Number(values.store_id),
                        integration_source: (values.integration_source as CustomerIntegrationSource),
                    }
                    await CustomerService.createCustomerIntegration(integration);                 
                }

                NotificationManager.success(
                    "Integração salva com sucesso",
                    "Integração"
                );

                close();
                if (props.onSaved) {
                    props.onSaved();
                }
            } catch (error) {
                const { status, data } = error.response;
                const errorMsgs: string[] = [];
                let errorMsg = "Ocorreu um erro ao salvar a integração";
                if (status === 400) {
                    for (var key in data) {
                        var value = data[key];
                        if (key in values) {
                            formik.setFieldError(key, value);
                        } else {
                            errorMsgs.push(value);
                        }
                    }

                    if (errorMsgs.length > 0) {
                        errorMsg = errorMsgs.join(". \n");
                    }
                }
                NotificationManager.error(errorMsg, "Integração");
            }
        },
        validationSchema: id ? EditCustomerIntegrationValidation : AddCustomerIntegrationValidation,
        validateOnChange: false,
        validateOnBlur: false,
    });

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={open}
        >
            <form onSubmit={formik.handleSubmit} noValidate>
                <DialogTitle id="confirmation-dialog-title">
                    Integração
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <TextField
                                id="store_id"
                                name="store_id"
                                disabled={!!id}
                                select
                                label="Loja"
                                value={formik.values.store_id}
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                            >
                                {props.storeList.map(store => (
                                    <MenuItem key={store.id} value={store.id}>
                                        {store.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id="name"
                                label="Nome"
                                variant="outlined"
                                size="small"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                error={!!formik.errors.name}
                                helperText={formik.errors.name}
                                fullWidth
                                inputProps={{ maxLength: 20 }}
                                InputLabelProps={{ required: true }}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                id="initials"
                                label="Iniciais"
                                variant="outlined"
                                size="small"
                                onChange={formik.handleChange}
                                value={formik.values.initials}
                                error={!!formik.errors.initials}
                                helperText={formik.errors.initials}
                                fullWidth
                                inputProps={{ maxLength: 5 }}
                                InputLabelProps={{ required: true }}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                id="integration_source"
                                name="integration_source"
                                disabled={!!id}
                                select
                                label="Aplicativo"
                                value={formik.values.integration_source}
                                onChange={formik.handleChange}
                                variant="outlined"
                                fullWidth
                                required
                            >
                                <MenuItem value={CustomerIntegrationSource.IFOOD}>{t("customer_integration_source." + CustomerIntegrationSource.IFOOD)}</MenuItem>
                                <MenuItem value={CustomerIntegrationSource.DELIVERY_DIRETO}>{t("customer_integration_source." + CustomerIntegrationSource.DELIVERY_DIRETO)}</MenuItem>
                                <MenuItem value={CustomerIntegrationSource.MEU_XODO}>{t("customer_integration_source." + CustomerIntegrationSource.MEU_XODO)}</MenuItem>
                                <MenuItem value={CustomerIntegrationSource.NEEMO}>{t("customer_integration_source." + CustomerIntegrationSource.NEEMO)}</MenuItem>

                            </TextField>
                        </Grid>
                        <ShowIf condition={formik.values.integration_source === CustomerIntegrationSource.IFOOD}>
                            <Grid item md={12}>
                                <TextField
                                    disabled={!!id}
                                    id="store_identifier"
                                    label="Identificador no Aplicativo"
                                    variant="outlined"
                                    size="small"
                                    onChange={formik.handleChange}
                                    value={formik.values.store_identifier}
                                    error={!!formik.errors.store_identifier}
                                    helperText={formik.errors.store_identifier}
                                    fullWidth
                                    inputProps={{ maxLength: 36 }}
                                    InputLabelProps={{ required: formik.values.integration_source === CustomerIntegrationSource.IFOOD ? true : false }}
                                    InputProps={{
                                        inputComponent: UUIDInput,
                                    }}
                                />
                            </Grid>
                        </ShowIf>
                        <ShowIf condition={[
                            CustomerIntegrationSource.DELIVERY_DIRETO, 
                            CustomerIntegrationSource.MEU_XODO, 
                            CustomerIntegrationSource.NEEMO].includes(formik.values.integration_source as CustomerIntegrationSource)}>
                            <Grid item md={12}>                       
                                <TextField
                                    disabled={!!id}
                                    id="key"
                                    label="ID da Loja"
                                    variant="outlined"
                                    size="small"
                                    onChange={formik.handleChange}
                                    value={formik.values.key}
                                    error={!!formik.errors.key}
                                    helperText={formik.errors.key}
                                    fullWidth
                                    InputLabelProps={{ required: [
                                        CustomerIntegrationSource.DELIVERY_DIRETO, 
                                        CustomerIntegrationSource.MEU_XODO, 
                                        CustomerIntegrationSource.NEEMO].includes(formik.values.integration_source as CustomerIntegrationSource) ? true : false }}
                                />
                            </Grid>
                        </ShowIf>
                        <ShowIf condition={formik.values.integration_source === CustomerIntegrationSource.DELIVERY_DIRETO}>    
                            <Grid item md={12}>                      
                                <TextField
                                    disabled={!!id}
                                    id="x_deliverydireto_id"
                                    label="Identificador Delivery Direto"
                                    variant="outlined"
                                    size="small"
                                    onChange={formik.handleChange}
                                    value={formik.values.x_deliverydireto_id}
                                    error={!!formik.errors.x_deliverydireto_id}
                                    helperText={formik.errors.x_deliverydireto_id}
                                    fullWidth
                                    InputLabelProps={{ required: formik.values.integration_source === CustomerIntegrationSource.DELIVERY_DIRETO ? true : false }}
                                />
                            </Grid>
                            <Grid item md={12}>                     
                                <TextField
                                    id="delivery_direto_user"
                                    label="Usuário"
                                    variant="outlined"
                                    size="small"
                                    onChange={formik.handleChange}
                                    value={formik.values.delivery_direto_user}
                                    error={!!formik.errors.delivery_direto_user}
                                    helperText={formik.errors.delivery_direto_user}
                                    fullWidth
                                    InputLabelProps={{ required: formik.values.integration_source === CustomerIntegrationSource.DELIVERY_DIRETO ? true : false }}
                                />
                            </Grid>
                            <ShowIf condition={isEdit}>
                                <Grid item md={12}>  
                                    <FormControlLabel
                                    control={<Checkbox checked={checked} onChange={(e) => {
                                        handleChangeCheck(e)}} />}
                                    label="Definir Senha"
                                    />
                                </Grid>
                            </ShowIf>
                                                        
                            <ShowIf condition={checked || !isEdit}>
                                <Grid item md={6}>                       
                                    <TextField
                                        id="delivery_direto_password"
                                        label={checked ? "Nova Senha" : "Senha"}
                                        variant="outlined"
                                        size="small"
                                        type="password"
                                        onChange={formik.handleChange}
                                        value={formik.values.delivery_direto_password}
                                        error={!!formik.errors.delivery_direto_password}
                                        helperText={formik.errors.delivery_direto_password}
                                        fullWidth
                                        InputLabelProps={{ required: formik.values.integration_source === CustomerIntegrationSource.DELIVERY_DIRETO ? true : false }}
                                    />
                                </Grid>
                                <Grid item md={6}>                      
                                    <TextField
                                        id="password_confirmation"
                                        label={checked ? "Confirmar Nova Senha" : "Confirmar Senha"}
                                        variant="outlined"
                                        size="small"
                                        type="password"
                                        onChange={formik.handleChange}
                                        value={formik.values.password_confirmation}
                                        error={!!formik.errors.password_confirmation}
                                        helperText={formik.errors.password_confirmation}
                                        fullWidth
                                        InputLabelProps={{ required: formik.values.integration_source === CustomerIntegrationSource.DELIVERY_DIRETO ? true : false }}
                                    />
                                </Grid>
                            </ShowIf>
                            
                        </ShowIf>

                        <ShowIf condition={formik.values.integration_source === CustomerIntegrationSource.MEU_XODO}>
                            <Grid item md={12}>
                                <TextField
                                    disabled={!!id}
                                    id="external_email"
                                    label="Email Externo"
                                    variant="outlined"
                                    size="small"
                                    type={formik.values.integration_source === CustomerIntegrationSource.MEU_XODO ? "email" : "text"}
                                    onChange={formik.handleChange}
                                    value={formik.values.external_email}
                                    error={!!formik.errors.external_email}
                                    helperText={formik.errors.external_email}
                                    fullWidth
                                    inputProps={{ maxLength: 150 }}
                                    InputLabelProps={{ required: formik.values.integration_source === CustomerIntegrationSource.MEU_XODO ? true : false }}
                                />
                            </Grid>
                        </ShowIf>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => close()} color="secondary">
                        Cancelar
                    </Button>
                    <Button
                        disabled={formik.isSubmitting}
                        type="submit"
                        color="primary"
                    >
                        <ShowIf condition={formik.isSubmitting}>
                            <CircularProgress size="1.5rem" color="inherit" style={{ marginRight: "0.5rem" }} />
                        </ShowIf>
                        Salvar
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default React.forwardRef(AddEditCustomerIntegrationDialog);