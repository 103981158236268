import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, makeStyles, TextField, Theme } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import ShowIf from "./ShowIf";

interface ChangePasswordData {
    password: string;
    password_confirmation: string;
}

export interface ChangePasswordDialogHandle {
    getPassword: (isHardPassword: boolean) => Promise<string>;
    showErrors: (errors: { field: string, value: string }[]) => void;
    hide: () => void;
}

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, "Mínimo de 6 caracteres")
        .max(32, "Senha muito longa")
        .required("Campo obrigatório"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), ""], "As senhas informadas não são iguais")
        .required("Campo obrigatório")
});

export const ChangePasswordDialog: React.ForwardRefRenderFunction<ChangePasswordDialogHandle> = (props, ref) => {
    const classes = useStyles();

    const [isSubmiting, setSubmiting] = React.useState<boolean>(false);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);

    const [resolveReject, setResolveReject] = React.useState<any[]>([]);
    const [resolve, reject] = resolveReject;
    const [isHardPassword, setIsHardPassword] = React.useState<boolean>(false);

    React.useImperativeHandle(ref, () => ({
        getPassword(isHardPassword: boolean): Promise<string> {
            setIsHardPassword(isHardPassword);
            return show();
        },

        showErrors(errors: { field: string, value: string }[]) {
            setSubmiting(false);
            errors.forEach(error => {
                formik.setFieldError(error.field, error.value);
            });
        },

        hide() {
            close();
        }
    }));

    const show = (): Promise<string> => {
        setSubmiting(false);
        return new Promise<string>((resolve, reject) => {
            setResolveReject([resolve, reject]);
        });
    }

    const close = () => {
        setResolveReject([]);
        formik.resetForm();
        setShowPassword(false);
        setShowPasswordConfirmation(false);
        setSubmiting(false);
    }

    const formik = useFormik({
        initialValues: { password: "", password_confirmation: "" },
        onSubmit: async (values: ChangePasswordData) => {
            setSubmiting(true);
            resolve(values.password);
        },
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false
    });

    const handleClose = () => {
        reject();
        close();
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowPasswordConfirmation = () => {
        setShowPasswordConfirmation(!showPasswordConfirmation);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Dialog
            open={resolveReject.length === 2}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <form onSubmit={formik.handleSubmit} className={classes.root}>
                <DialogTitle id="form-dialog-title">Alterar Senha</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>                       
                        {isHardPassword ? (
                            <Alert severity="info">
                                A Nova Senha deve conter no mínimo 6 (seis) caracteres e deve conter pelo menos 1 (uma) letra maiúscula, 1 (uma) minúscula e 1 (um) número.
                            </Alert>
                        ) : (
                            <Alert severity="info">
                            A Nova Senha deve conter no mínimo 6 (seis) caracteres.
                        </Alert>
                        )}                
                    </DialogContentText>
                    <TextField
                        id="password"
                        label="Nova Senha"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={!!formik.errors.password}
                        helperText={formik.errors.password}
                        fullWidth
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton aria-label="alterna a visibilidade da senha"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}>
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    <TextField
                        id="password_confirmation"
                        label="Confirmar Nova Senha"
                        variant="outlined"
                        type={showPasswordConfirmation ? 'text' : 'password'}
                        value={formik.values.password_confirmation}
                        onChange={formik.handleChange}
                        error={!!formik.errors.password_confirmation}
                        helperText={formik.errors.password_confirmation}
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton aria-label="alterna a visibilidade da senha"
                                    onClick={handleClickShowPasswordConfirmation}
                                    onMouseDown={handleMouseDownPassword}>
                                    {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button color="primary" type="submit" disabled={isSubmiting}>
                        <ShowIf condition={isSubmiting}>
                            <CircularProgress size="1.5rem" color="inherit" style={{ marginRight: "0.5rem" }} />
                        </ShowIf>
                        Alterar
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1)
            },
        },
    }),
);

export default React.forwardRef(ChangePasswordDialog);