import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import Image from "material-ui-image";
import { apiAxios } from "../store/api/config";

interface IFileModalViewProps {
  isOpen: boolean;
  closeModal(): void;
  title: string;
  src: string | File | null | undefined;
  aspectRatio?: number;
}

export const FileModalView: React.FC<IFileModalViewProps> = ({
  isOpen,
  closeModal,
  title,
  src,
  aspectRatio = 1
}) => {
  const [fileSrc, setFileSrc] = React.useState<string>("");
  const [fileType, setFileType] = React.useState<string>();

  const close = () => {
    closeModal();
  }

  // Load data
  React.useEffect(() => {
    if (src) {
      if (src instanceof File) {
        if (src.size > 0) {
          setFileSrc(URL.createObjectURL(src));
          if (src.type.startsWith("image")) {
            setFileType("image");
          } else {
            setFileType("object");
          }
        }
      } else if (src.startsWith("data:image")) {
        setFileSrc(src);
        setFileType("image");
      } else if (src.startsWith("data:")) {
        setFileSrc(src);
        setFileType("object");
      } else {
        setFileSrc("");
        setFileType(undefined);
        apiAxios.get(`${src}`,
          {
            responseType: 'arraybuffer',
          }).then((response) => {
            const blob = new Blob([response.data], {
              type: response.headers["content-type"],
            });
  
            if (blob.type.startsWith("image")) {
              setFileType("image");
            } else {
              setFileType("object");
            }
            let objectURL = URL.createObjectURL(blob);
            setFileSrc(objectURL);
          }).catch((error) => {
  
          });
      }
    } else {
      setFileSrc("");
      setFileType(undefined);
    }
  }, [src]);

  return (
    <Dialog open={isOpen} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {fileType === "image" ?
          <Image src={fileSrc} aspectRatio={aspectRatio} /> :
          <object width="100%" height="100%" data={fileSrc} type="application/pdf">
          </object>
        }
      </DialogContent>
    </Dialog>
  );
};
