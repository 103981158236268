import { apiAxios } from "../api/config";
import { urls } from "../api/urls";
import {
  ICustomer,
  IStore,
  ICity,
  ICustomerUser,
  ICreateCustomer,
  IUpdateCustomer,
} from "../types/customer";
import { AppDispatch } from "..";
//@ts-ignore
import { NotificationManager } from "react-notifications";

export const Types = {
  TOGGLE_MENU: "TOGGLE_MENU",
  GET_CUSTOMER_REQUEST: "GET_CUSTOMER_REQUEST",
  GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",
  GET_CUSTOMER_FAILURE: "GET_CUSTOMER_FAILURE",
  UPDATE_CUSTOMER_REQUEST: "UPDATE_CUSTOMER_REQUEST",
  UPDATE_CUSTOMER_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
  UPDATE_CUSTOMER_FAILURE: "UPDATE_CUSTOMER_FAILURE",
  CREATE_CUSTOMER_REQUEST: "CREATE_CUSTOMER_REQUEST",
  CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
  CREATE_CUSTOMER_FAILURE: "CREATE_CUSTOMER_FAILURE",
  GET_CITIES: "GET_CITIES",
  ADD_CUSTOMER_STORE: "ADD_CUSTOMER_STORE",
  DELETE_CUSTOMER_STORE: "DELETE_CUSTOMER_STORE",
  DELETE_CUSTOMER_FAILURE: "DELETE_CUSTOMER_FAILURE",
  GET_CUSTOMER_USERS_REQUEST: "GET_CUSTOMER_USERS_REQUEST",
  GET_CUSTOMER_USERS_SUCCESS: "GET_CUSTOMER_USERS_SUCCESS",
  GET_CUSTOMER_USERS_FAILURE: "GET_CUSTOMER_USERS_FAILURE",
  CLEAR_CUSTOMER_USER: "CLEAR_CUSTOMER_USER",
  GET_CUSTOMER_USER_REQUEST: "GET_CUSTOMER_USER_REQUEST",
  GET_CUSTOMER_USER_SUCCESS: "GET_CUSTOMER_USER_SUCCESS",
  GET_CUSTOMER_USER_FAILURE: "GET_CUSTOMER_USER_FAILURE",
  CREATE_CUSTOMER_USER_REQUEST: "CREATE_CUSTOMER_USER_REQUEST",
  CREATE_CUSTOMER_USER_SUCCESS: "CREATE_CUSTOMER_USER_SUCCESS",
  CREATE_CUSTOMER_USER_FAILURE: "CREATE_CUSTOMER_USER_FAILURE",
  UPDATE_CUSTOMER_USER_REQUEST: "UPDATE_CUSTOMER_USER_REQUEST",
  UPDATE_CUSTOMER_USER_SUCCESS: "UPDATE_CUSTOMER_USER_SUCCESS",
  UPDATE_CUSTOMER_USER_FAILURE: "UPDATE_CUSTOMER_USER_FAILURE",
  SET_CUSTOMER_USERS_FILTER: "SET_CUSTOMER_USERS_FILTER",
  CLEAR_CUSTOMER_USERS_FILTER: "CLEAR_CUSTOMER_USERS_FILTER",
  CLEAR_CUSTOMER_OPERATIONS: "CLEAR_CUSTOMER_OPERATIONS",
};

export interface IState {
  total: number;
  stores: Array<IStore>;
  users: Array<ICustomerUser>;
  customer_user: ICustomerUser;
  customer_users: {
    data: Array<ICustomerUser>;
    total: number;
    filter: {
      name: string;
      store: number | string | null;
      page: number;
      page_size: number | null;
    };
  };
  customer: ICustomer;
  isLoading: boolean;
  error: any;
  state: string;
  cities: ICity[];
}

const INITIAL_STATE = {
  stores: new Array<IStore>(),
  users: new Array<ICustomerUser>(),
  customer_user: {} as ICustomerUser,
  customer_users: {
    data: new Array<ICustomerUser>(),
    total: 0,
    filter: {
      name: "",
      store: "",
      page: 1,
      page_size: 10,
    },
  },
  total: 0,
  customer: {} as ICustomer,
  isLoading: false,
  error: "",
  state: "",
  cities: new Array<ICity>(),
  filter: {
    page: 1,
    page_size: 10,
    ordering: "id",
    name: "",
    email: "",
    phonenumber: "",
    cnpj: "",
    city: "",
  },
};

export default (
  state = INITIAL_STATE,
  action: { type: string; payload?: any }
): IState => {
  switch (action.type) {
    case Types.GET_CUSTOMER_REQUEST:
      return { ...state, isLoading: true, error: "" };
    case Types.GET_CUSTOMER_SUCCESS:
      return { ...state, customer: action.payload.customer, isLoading: false };
    case Types.GET_CUSTOMER_FAILURE:
      return { ...state, error: action.payload.error, isLoading: false };
    case Types.CREATE_CUSTOMER_REQUEST:
      return { ...state, isLoading: true, error: "", state: "" };
    case Types.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.payload.customer,
        state: "customer-saved",
        isLoading: false,
      };
    case Types.CREATE_CUSTOMER_FAILURE:
      return { ...state, error: action.payload.error, isLoading: false };
    case Types.UPDATE_CUSTOMER_REQUEST:
      return { ...state, isLoading: true, error: "", state: "" };
    case Types.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        state: "updated",
      };
    case Types.UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        state: "",
      };

    case Types.DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        state: "",
      };

    case Types.ADD_CUSTOMER_STORE:
      const stores =
        state.customer.stores && state.customer.stores.length
          ? state.customer.stores
          : [action.payload];

      return {
        ...state,
        customer: {
          ...state.customer,
          stores: stores.map((item) => {
            if (item.id === action.payload.id) {
              return action.payload;
            } else {
              return item;
            }
          }),
        },
      };
    case Types.DELETE_CUSTOMER_STORE:
      return {
        ...state,
        customer: {
          ...state.customer,
          stores: state?.customer?.stores?.filter(
            (item) => item.id !== action.payload
          ),
        },
      };
    case Types.GET_CITIES:
      return { ...state, cities: action.payload.cities };
    case Types.GET_CUSTOMER_USERS_REQUEST:
      return { ...state, isLoading: true, error: "" };
    case Types.GET_CUSTOMER_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        isLoading: false,
      };
    case Types.GET_CUSTOMER_USERS_FAILURE:
      return { ...state, error: action.payload.error, isLoading: false };
    case Types.CLEAR_CUSTOMER_USER:
      return { ...state, customer_user: INITIAL_STATE.customer_user };
    case Types.GET_CUSTOMER_USER_REQUEST:
      return { ...state, isLoading: true, error: "" };
    case Types.GET_CUSTOMER_USER_SUCCESS:
      return {
        ...state,
        customer_user: action.payload.customerUser,
        isLoading: false,
      };
    case Types.GET_CUSTOMER_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        state: "",
      };

    case Types.CREATE_CUSTOMER_USER_REQUEST:
      return { ...state, isLoading: true, error: "" };
    case Types.CREATE_CUSTOMER_USER_SUCCESS:
      return {
        ...state,
        users: state.users.concat([{ ...action.payload.customerUser }]),
        isLoading: false,
        state: "saved",
      };
    case Types.CREATE_CUSTOMER_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        state: "fail",
      };

    case Types.UPDATE_CUSTOMER_USER_REQUEST:
      return { ...state, isLoading: true, error: "", state: "" };
    case Types.UPDATE_CUSTOMER_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload.updatedUser;
          }
          return item;
        }),
        isLoading: false,
        state: "updated",
      };
    case Types.UPDATE_CUSTOMER_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        state: "fail",
      };

    case Types.SET_CUSTOMER_USERS_FILTER:
      return {
        ...state,
        customer_users: {
          ...state.customer_users,
          filter: action.payload,
        },
      };
    case Types.CLEAR_CUSTOMER_USERS_FILTER:
      return {
        ...state,
        customer_users: {
          ...state.customer_users,
          filter: INITIAL_STATE.customer_users.filter,
        },
      };
    default:
      return { ...state };
  }
};

export const Actions = {
  getCustomer: (dispatch: AppDispatch, customerId: number) => {
    dispatch({
      type: Types.GET_CUSTOMER_REQUEST,
    });
    apiAxios
      .get(`${urls.CUSTOMER}${customerId}/`)
      .then((response) => {
        dispatch({
          type: Types.GET_CUSTOMER_SUCCESS,
          payload: {
            customer: {
              ...response.data,
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.GET_CUSTOMER_FAILURE,
          payload: { error },
        });
      });
  },

  createCustomer: (dispatch: AppDispatch, customer: ICreateCustomer) => {
    dispatch({
      type: Types.CREATE_CUSTOMER_REQUEST,
    });
    apiAxios
      .post(urls.CUSTOMER, customer)
      .then((response) => {
        dispatch({
          type: Types.CREATE_CUSTOMER_SUCCESS,
          payload: { customer: response.data },
        });
        NotificationManager.success(
          "Cliente criado com sucesso",
          "Novo Cliente"
        );
      })
      .catch((error) => {
        let errorMsg = "Ocorreu um erro ao cadastrar o cliente";

        const errorKeys = Object.keys(error.response.data);
        if (errorKeys.length) {
          errorMsg = error.response.data[errorKeys[0]];
        }
        dispatch({
          type: Types.CREATE_CUSTOMER_FAILURE,
          payload: { error },
        });
        NotificationManager.error(errorMsg, "Novo Cliente");
      });
  },
  
  updateCustomer: (dispatch: AppDispatch, customer: IUpdateCustomer) => {
    dispatch({
      type: Types.UPDATE_CUSTOMER_REQUEST,
    });
    apiAxios
      .put(`${urls.CUSTOMER}${customer.id}/`, customer)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_CUSTOMER_SUCCESS,
          payload: { customer: response.data },
        });
        NotificationManager.success(
          "Cliente atualizado com sucesso",
          "Novo Cliente"
        );
      })
      .catch((error) => {
        let errorMsg = "Ocorreu um erro ao editar o cliente";
        const errorKeys = Object.keys(error.response.data);
        if (errorKeys.length) {
          errorMsg = error.response.data[errorKeys[0]];
        }
        dispatch({
          type: Types.UPDATE_CUSTOMER_FAILURE,
          payload: { errorMsg },
        });
        NotificationManager.error(errorMsg, "Erro");
      });
  },

  getCities: (dispatch: AppDispatch) => {
    apiAxios.get(urls.CITY).then((response) => {
      dispatch({
        type: Types.GET_CITIES,
        payload: { cities: response.data },
      });
    });
  },

  getPlan: (id: number) : Promise<any> => {
    return apiAxios.get(`${urls.COMMERCIAL_PLAN}${id}/`)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
  },

  getPlans: () : Promise<any> =>  {
    return apiAxios.get(`${urls.COMMERCIAL_PLAN}`)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
  },

};
