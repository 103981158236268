import React, { ReactNode } from "react";
import {
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Card,
  CardContent,
} from "@material-ui/core";
import { formatPhoneNumber } from "../../../../utils/formatters";

interface IProps {
  children?: ReactNode;
  content: {
    customer_id: number;
    customer_name: string;
    store_id: number;
    store_name: string;
    phonenumber: string;
  } | undefined;
}

export const InfoCardCustomer = ({ children, content }: IProps) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography className={classes.cardTitle} variant="h4">
            Dados do Cliente
        </Typography>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginLeft: "20px", width: "100%" }}>
              <Typography variant="body1" style={{ margin: 0 }}>
                Nome do Cliente
            </Typography>
              <Typography variant="subtitle1">
                {content?.customer_name}
              </Typography>

              <div className={classes.infoContainer}>
                <div>
                  <Typography variant="body1" style={{ margin: 0 }}>
                    Nome da Loja
                  </Typography>
                  <Typography variant="subtitle1">
                    {content?.store_name}
                  </Typography>
                </div>

                <div>
                  <Typography variant="body1" style={{ margin: 0 }}>
                    Telefone
                  </Typography>
                  <Typography variant="subtitle1">
                    {content ? formatPhoneNumber(content?.phonenumber) : "---"}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    "& p": {
      color: "#526272",
      fontSize: "14px",
    },
    card: {
      padding: "30px 38px",

      "&:nth-of-type(2)": {
        marginTop: "20px",
      },
    },
    cardTitle: {
      fontSize: "24px",
      marginBottom: "8px",
      fontWeight: "bold",
    },
    cardImage: {
      width: "70px",
      height: "70px",
      borderRadius: "50%",
    },
    infoContainer: {
      display: "flex",
      marginTop: "16px",

      "& div:nth-of-type(2)": {
        marginLeft: "auto",
      },
    },
    vehicleContainerTag: {
      background: "#A5B4C1",
      color: "#FFF",
      width: "70px",
      borderRadius: "16px",
      padding: "6px 0",
      fontSize: "12px",
      textAlign: "center",
      marginLeft: "10px",
    },
  })
);
