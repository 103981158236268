import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
//@ts-ignore
import {  DeliveryRequestLog, TripRequestEvent } from "../services/types/deliveryRequest";
import { TimelineItemTrip } from "./TimeLineItemTrip";

interface IProps {
  logs: DeliveryRequestLog[];
}

export const DashboardTimelineTrip = ({
  logs,
}: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.timelineContainer}>
        {logs &&
          logs.length &&
          logs.map(
            (item, index) =>
              item.event !==
              TripRequestEvent.COURIER_IGNORED_NOTIFICATION && (
                <TimelineItemTrip data={item} key={index} />
              )
          )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& h3": {
        fontSize: "1rem",
      },
      "& p": {
        fontSize: ".8 rem",
      },
    },
    timelineContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "column-reverse",
      position: "relative",

      "&::after": {
        borderLeft: "2px dashed #526272",
        content: "''",
        position: "absolute",
        left: "calc(50% - 1px)",
        width: "2px",
        height: "100%",
        opacity: 0.8,
      },
    },
  })
);
