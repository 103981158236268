import { apiAxios as axios } from "../store/api/config";
import { urls } from "../store/api/urls";
import { City, State } from "./types/common";

export interface ICityService {
    getCities: () => Promise<City[]>;
    getStates: () => Promise<State[]>;
}

const CityService: ICityService = {
    getCities: async () => {
        return new Promise((resolve, reject) => {
            axios.get(urls.CITY)
                .then((response) => {
                    const result: City[] = response.data;
                    resolve(result);
                })
                .catch((error) => reject(error));
        });
    },

    getStates: async () => {
        return new Promise((resolve, reject) => {
            axios.get(urls.BRAZILIAN_STATE)
                .then((response) => {
                    const result: State[] = response.data.results;
                    resolve(result);
                })
                .catch((error) => reject(error));
        });
    },
}

export default CityService;