import React, { useEffect, useState } from "react";
import { Button, Grid, Tooltip, Typography } from "@material-ui/core";
import {
  CourierFilter,
  ICourierFilterParams,
  INITIAL_FILTER_PARAMS,
} from "./CourierFilter";
import { Link, useHistory } from "react-router-dom";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { capitalize } from "../../utils/capitalize";
import { useTranslation } from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import CourierService from "../../services/courierService";
import { startOfDay, endOfDay } from "date-fns";
import RegionService from "../../services/regionService";
import { DATE_COLUMN_DEFAULTS, DATE_TIME_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import { mapCourierAccountStatusToSeverity } from "./courierUtils";
import { CourierResponse } from "../../services/types/courier";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { formatPhoneNumber } from "../../utils/formatters";
import { ReportProblem, VisibilityOutlined } from "@material-ui/icons";
import ShowIf from "../../components/ShowIf";
import AuthService from "../../services/authService";
import { decodeURLParams, URLParamType } from "../../utils/utils";

interface ICourierProps { }

export const Courier: React.FC<ICourierProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [regionList, setRegionList] = useState<any[]>([]);
  const [filterParams, setFilterParams] = useState<ICourierFilterParams>({
    ...INITIAL_FILTER_PARAMS,
    ...decodeURLParams(history.location.search, [
      { "createdAtAfter": URLParamType.DATE_TIME },
      { "createdAtBefore": URLParamType.DATE_TIME }
    ])
  });

  const tableRef = React.useRef<MaterialTableProps<CourierResponse>>();

  const loadData = (query: Query<CourierResponse>) => {
    const createdAtAfter = filterParams.createdAtAfter ? startOfDay(filterParams.createdAtAfter) : null;
    const createdAtBefore = filterParams.createdAtBefore ? endOfDay(filterParams.createdAtBefore) : null;
    const validBankData: boolean | null = filterParams.invalidBankData ? false : null;

    return new Promise<QueryResult<CourierResponse>>((resolve, reject) => {
      CourierService.getCouriers(
        filterParams.name,
        filterParams.email,
        filterParams.phonenumber,
        createdAtAfter,
        createdAtBefore,
        filterParams.courierAccountStatus ? filterParams.courierAccountStatus : undefined,
        filterParams.vehicleLicensePlate,
        filterParams.vehicleType,
        filterParams.region ? [Number(filterParams.region)] : [],
        filterParams.district,
        validBankData,
        PageableParams.fromQuery(query))
        .then(response => {
          resolve({
            data: response.data,
            page: query.page,
            totalCount: response.count,
          })
        })
    });
  }

  const applyFilter = (params: ICourierFilterParams) => {
    setFilterParams(params);
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({ page: 0 } as Query<CourierResponse>);
    }
  }

  useEffect(() => {
    const getRegions = async () => {
      const response = await RegionService.loadAllRegions({ "ordering": "name" });
      setRegionList(response);
    }
    getRegions();
  }, []);

  const showDetails = async (item: CourierResponse) => {
    history.push(`/courier/detail/${item.id}`);
  };

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Entregadores</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[{ label: "Entregadores", url: "/courier" }]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <ShowIf condition={AuthService.hasAuthorization("add_courier")}>
            <Button color="primary" variant="contained"
              startIcon={<AddCircleOutlineRoundedIcon />}
              component={Link} to="/courier/new">
              Novo entregador
            </Button>
          </ShowIf>
        </Grid>
      </Grid>

      <CourierFilter params={filterParams} regionList={regionList} onFilterChanged={applyFilter} />

      <MaterialTable
        tableRef={tableRef}
        columns={[
          { field: "id", hidden: true, width: 60 },
          {
            field: "name", title: "Nome", defaultSort: "asc",
            render: (rowData, type) => (
              <React.Fragment>
                {
                  !rowData.valid_bank_data ?
                    <Tooltip title="Dados Bancários Inválidos">
                      <ReportProblem className="cellIcon" color="error"></ReportProblem>
                    </Tooltip>
                    :
                    <React.Fragment></React.Fragment>
                }
                {rowData.name}
              </React.Fragment>
            )
          },
          {
            field: "phonenumber", title: "Telefone",
            render: (rowData, type) => formatPhoneNumber(rowData.phonenumber)
          },
          { field: "email", title: "E-mail" },
          {
            field: "vehicle_type", title: "Veículo",
            render: (rowData, type) => t("vehicle_type." + rowData.vehicle_type)
          },
          {
            field: "vehicle_license_plate", title: "Placa", emptyValue: "---",
            render: (rowData, type) => rowData.vehicle_license_plate?.toUpperCase()
          },
          {
            field: "region_name", title: "Região",
            render: (rowData, type) => rowData.region ? `${capitalize(rowData.region.name)} - ${rowData.region.state}` : "---"
          },
          {
            ...DATE_COLUMN_DEFAULTS,
            field: "birthdate", title: "Dt. Nascimento"
          },
          {
            ...DATE_TIME_COLUMN_DEFAULTS,
            field: "created_at", title: "Dt. Cadastro"
          },
          {
            field: "courier_account_status", title: "Situação",
            render: (rowData, type) => (
              <Alert icon={false}
                severity={mapCourierAccountStatusToSeverity(rowData.courier_account_status)}
              >
                {t("courier_account_status." + rowData.courier_account_status)}
              </Alert>
            )
          },
        ]}
        data={loadData}
        actions={[
          {
            icon: () => <VisibilityOutlined />,
            tooltip: 'Ver Detalhes',
            onClick: (event, rowData) => showDetails(rowData as CourierResponse)
          }
        ]}
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_L10N_PTBR}
        options={{
          ...DEFAULT_TABLE_OPTIONS,
          draggable: false
        }}
      />
    </div>
  );
};
