import React from "react";

import { Box, Button, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { Dedicated } from "./Dedicated";
import { BlockListDedicated } from "./BlockListDedicated";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { Link } from "react-router-dom";
import ShowIf from "../../components/ShowIf";
import AuthService from "../../services/authService";
import AddCourierBlockListDialog, { AddCourierBlockListDialogHandle } from "./AddCourierBlockListDialog";
import RegionService from "../../services/regionService";
import { useHistory } from "react-router-dom";
import { decodeURLParams, encodeURLParams, URLParamType } from "../../utils/utils";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  tabValue: any;
}

interface IParams {
  tripId: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {tabValue === index && <Box>{children}</Box>}
    </div>
  );
}

interface StyledTabProps {
  label: string;
  disabled?: boolean;
}

export const DedicatedSlots: React.FC  = () => {
    const [tabValue, setTabValue] = React.useState<string>("schedules");
    const addCourierBlockListDialog = React.createRef<AddCourierBlockListDialogHandle>();
    const [regionList, setRegionList] = React.useState<any[]>([]);
    const [addCourierRefresh, setAddCourierRefresh] = React.useState<boolean>(false);
    const history = useHistory();

    React.useLayoutEffect(() => {
      const result: any = decodeURLParams(history.location.search, [{"menu": URLParamType.STRING}]);
      if(result && result.menu){
        setTabValue(result.menu);
      }      
    }, [history.location.search]);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
      history.replace(encodeURLParams(history.location.pathname, {menu: newValue}));
      setTabValue(newValue);
    };

    const handleAddCourierBlockList = () => {
      addCourierBlockListDialog.current?.open();
    };

    React.useEffect(() => {
      const getRegions = async () => {
        const response = await RegionService.loadAllRegions({ "ordering": "name" });
        setRegionList(response);
      }
      getRegions();
    }, []);

    const finishAdd = async (): Promise<void> => {
      try {
        setAddCourierRefresh(true); 
      } finally {
        setAddCourierRefresh(false); 
        return Promise.resolve();
      }
  };
   
    return (     
      <React.Fragment>
        <AddCourierBlockListDialog ref={addCourierBlockListDialog} regionList={regionList} onAccept={finishAdd} />

        <Grid
            container
            justify="space-between"
            alignItems="center"
            className="page-title"
        >
            <Grid item>
              <Grid item xs={12}>
                  <Typography variant="h1">Propostas de Agendamento</Typography>
              </Grid>
              <Grid item xs={12}>
                  <CustomBreadcrumbs
                  pathList={[
                      { label: "Propostas de Agendamento", url: "/dedicated" },
                  ]}
                  />
              </Grid>
            </Grid>
            <Grid item>
              <ShowIf condition={AuthService.hasAuthorization("add_dedicatedproposal") && tabValue === "schedules"}>
                <Button color="primary" variant="contained"
                    startIcon={<AddCircleOutlineRoundedIcon />}
                    component={Link} to="/dedicated/new">
                    Adicionar Agendamento
                </Button>
              </ShowIf>
              <ShowIf condition={AuthService.hasAuthorization("add_blocklist") && tabValue === "block"}>
                <Button color="primary" variant="contained"
                    startIcon={<AddCircleOutlineRoundedIcon />}
                    onClick={() => {handleAddCourierBlockList()}}>
                    Bloquear Entregador
                </Button>
              </ShowIf>
            </Grid>
            
            <Grid item style={{ width: "100%" }}>
              <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                className="tabs"
              >
              <Tab label="Agendamentos" value={"schedules"} />
              <Tab label="Bloqueios" value={"block"} />
            </Tabs>
          </Grid>
        </Grid> 

        <TabPanel tabValue={tabValue} index={"schedules"}>
          <Dedicated />
        </TabPanel>
        <TabPanel tabValue={tabValue} index={"block"}>
          <BlockListDedicated refreshData={addCourierRefresh} />
        </TabPanel>

      </React.Fragment>

      
    );
};