import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { DATE_TIME_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import { Grid, Typography } from "@material-ui/core";
import { Block, VisibilityOutlined, Check } from "@material-ui/icons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DedicatedService from "../../services/dedicatedService";
import { DedicatedResponse, StatusDedicated } from "../../services/types/dedicated";
import { useHistory } from "react-router-dom";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../components/ConfirmationDialog";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { DedicatedFilter, IDedicatedFilterParams, INITIAL_FILTER_PARAMS } from "./DedicatedFilter";
import { decodeURLParams, URLParamType } from "../../utils/utils";

export const Dedicated: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [filterParams, setFilterParams] = useState<IDedicatedFilterParams>({
    ...INITIAL_FILTER_PARAMS,
    ...decodeURLParams(history.location.search, [
      { "requested_time_after": URLParamType.DATE_TIME },
      { "requested_time_before": URLParamType.DATE_TIME }
    ])
  });
  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
  const tableRef = React.useRef<MaterialTableProps<DedicatedResponse>>();

  const loadData = (query: Query<DedicatedResponse>) =>
    new Promise<QueryResult<DedicatedResponse>>((resolve, reject) => {
      DedicatedService.getDedicated(filterParams.store, filterParams.region,
        filterParams.requested_time_after,
        filterParams.requested_time_before, PageableParams.fromQuery(query)
      ).then(response => {
        resolve({
          data: response.data,
          page: query.page,
          totalCount: response.count,
        });
      }).catch((error) => reject(error));
    });

  const applyFilter = (params: IDedicatedFilterParams) => {
    setFilterParams(params);
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({
        page: 0,
      } as Query<DedicatedResponse>);
    }
  };

  const refresh = () => {
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({
        page: 0,
      } as Query<DedicatedResponse>);
    }
  };

  const showDetails = (item: DedicatedResponse) => {
    history.push(`/dedicated/detail/${item.id}`);
  };

  const openEditDedicated = (item: DedicatedResponse) => {
    if (item) {
      history.push(`/dedicated/edit/${item.id}`);
    }
  };

  const handleDeactivateDedicated = (item: DedicatedResponse) => {
    if (item) {
      confirmationDialog.current?.confirm(
        "Desativar Proposta de Agendamento",
        <Typography>Você tem certeza que deseja <strong>Desativar</strong> esta Proposta de Agendamento?</Typography>,
        "Desativar",
        "Cancelar"
      ).then(() => {
        DedicatedService.diasbleDedicated(item.id)
          .then((response) => {
            NotificationManager.success("Desativado com sucesso.", "Proposta de Agendamento");
            refresh();
            confirmationDialog.current?.hide();
          })
          .catch((error) => {
            NotificationManager.error("Não foi possível Desativar a Proposta de Agendamento.", "Proposta de Agendamento");
          });
      }).catch(() => { });
    }
  };

  const handleActivateDedicated = (item: DedicatedResponse) => {
    if (item) {
      confirmationDialog.current?.confirm(
        "Ativar Proposta de Agendamento",
        <Typography>Você tem certeza que deseja <strong>Ativar</strong> esta Proposta de Agendamento?</Typography>,
        "Ativar",
        "Cancelar"
      ).then(() => {
        DedicatedService.activeDedicated(item.id)
          .then((response) => {
            NotificationManager.success("Ativado com sucesso.", "Proposta de Agendamento");
            refresh();
            confirmationDialog.current?.hide();
          })
          .catch((error) => {
            NotificationManager.error("Não foi possível Ativado a Proposta de Agendamento.", "Proposta de Agendamento");
          });
      }).catch(() => { });
    }
  };


  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ position: "relative", marginTop: 0 }}
      >
      </Grid>
      <ConfirmationDialog ref={confirmationDialog} />

      <DedicatedFilter params={filterParams} onFilterChanged={applyFilter} />

      <MaterialTable
        tableRef={tableRef}
        columns={[
          { field: "id", hidden: true, width: 60 },
          {
            ...DATE_TIME_COLUMN_DEFAULTS,
            field: "first_datetime",
            title: "Data e Hora Início"
          },
          {
            field: "recurrent", title: "Recorrente",
            render: (rowData, type) => rowData.recurrent ? "Sim" : "Não"
          },
          {
            field: "description", title: "Descrição",
            render: (rowData, type) => rowData.description === "" ? "---" : rowData.description
          },
          {
            field: "proposal_type", title: "Tipo",
            render: (rowData, type) => rowData.proposal_type ? t("proposal_type_dedicated." + rowData.proposal_type) : "---"
          },
          {
            field: "store.region.name", title: "Região",
          },
          {
            field: "store.name", title: "Loja",
          },
          {
            field: "status", title: "Status",
            render: (rowData, type) => t("status_dedicated." + rowData.status)
          },

        ]}
        actions={[
          (rowData) => rowData.status === StatusDedicated.ACTIVE ?
            {
              icon: () => <Block />,
              tooltip: 'Fechar',
              onClick: (event, rowData) => handleDeactivateDedicated(rowData as DedicatedResponse)
            } : {
              icon: () => <Check />,
              tooltip: 'Ativar',
              onClick: (event, rowData) => handleActivateDedicated(rowData as DedicatedResponse)
            }
          ,
          {
            icon: () => <EditOutlinedIcon />,
            tooltip: 'Editar',
            onClick: (event, rowData) => openEditDedicated(rowData as DedicatedResponse)
          },
          {
            icon: () => <VisibilityOutlined />,
            tooltip: "Ver Detalhes",
            onClick: (event, rowData) =>
              showDetails(rowData as DedicatedResponse),
          },

        ]}
        data={loadData}
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_L10N_PTBR}
        options={DEFAULT_TABLE_OPTIONS}
      />
    </div>
  );
};