import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import jwtDecode from 'jwt-decode';
import AuthService from "../../services/authService";

function getAccessToken() {
  return localStorage.getItem("@speedy:access");
}

function getRefreshToken() {
  return localStorage.getItem("@speedy:refresh");
}

export const apiAxios = axios.create({
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  }
});

apiAxios.interceptors.request.use((request) => {
  const token = getAccessToken();
  if (token) {
    request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  }
  return request;
}, (error) => {
  const status = error.response.status;
  if (status === 403) {
    //TODO mostrar mensagem de erro dizendo que o usuário não tem permissão para isso
  }
  return Promise.reject(error);
});

export const configExpiredAccessTokenInterceptor = (disconnect: Function) => {
  const refreshAuthLogic = (failedRequest: any) => AuthService.getNewToken(getRefreshToken()).then(tokenRefreshResponse => {
    const jwtDecoded: any = jwtDecode(tokenRefreshResponse.data.access);

    localStorage.setItem("@speedy:refresh", tokenRefreshResponse.data.refresh);
    localStorage.setItem("@speedy:access", tokenRefreshResponse.data.access);
    localStorage.setItem("@speedy:exp", jwtDecoded.exp || 0);

    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.access;
    return Promise.resolve();
  }).catch(error => {
    disconnect();
    return Promise.reject(error);
  });

  createAuthRefreshInterceptor(apiAxios, refreshAuthLogic);
}
