import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles, Theme, Typography } from "@material-ui/core";

import { Trip } from "../../../services/types/deliveryRequest";
import TripService from "../../../services/tripService";
import { convertDeliveryForTypeToOption } from "../../announcement/announcementUtils";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { AnnouncementResponse } from "../../../services/types/announcement";
import { DATE_TIME_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";
import { useHistory } from "react-router-dom";
import { VisibilityOutlined } from "@material-ui/icons";

interface IAnnouncementTripProps {
  trip: Trip;
}

export const AnnouncementTrip: React.FC<IAnnouncementTripProps> = ({ trip }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const tableRef = React.useRef<MaterialTableProps<AnnouncementResponse>>();
  const history = useHistory();

  const loadData = (query: Query<AnnouncementResponse>) =>
    new Promise<QueryResult<AnnouncementResponse>>((resolve, reject) => {
        TripService.getAnnouncementByTrip(
            trip.id
        ).then(response => {
            resolve({
                data: response.data,
                page: 0,
                totalCount: response.count,
            });
        });
    });

  const showDetails = async (item: AnnouncementResponse) => {
    history.push(`/announcement/detail/${item.id}`);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3">
        Avisos da Viagem
      </Typography>
        <MaterialTable
            tableRef={tableRef}
            columns={[
                { field: "id", hidden: true, width: 60 },
                {
                    ...DATE_TIME_COLUMN_DEFAULTS,
                    field: "send_date",
                    title: "Data de Envio",
                },
                { field: "title", title: "Título" },
                {
                    field: "delivery_for",
                    title: "Envio",
                    render: (rowData, type) =>
                        t(
                            "announcement_delivery_for_options."
                            + convertDeliveryForTypeToOption(rowData.delivery_for, rowData.only_active_couriers)
                        )
                },
                {
                    field: "screen",
                    title: "Local",
                    render: (rowData, type) =>
                        t("announcement_screen_type." + rowData.screen)
                },
            ]}
            data={loadData}
            actions={[
                {
                    icon: () => <VisibilityOutlined />,
                    tooltip: "Ver Detalhes",
                    onClick: (event, rowData) =>
                        showDetails(rowData as AnnouncementResponse)
                },
            ]}
            components={DEFAULT_TABLE_COMPONENTS}
            localization={TABLE_L10N_PTBR}
            options={{
                ...DEFAULT_TABLE_OPTIONS,
                paging: false
            }}
        />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  })
);
