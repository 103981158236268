import React from "react";
import { CircularProgress, Dialog, DialogTitle, Grid } from "@material-ui/core";

export interface LoadingDialogProps {
    open: boolean;
    message: string;
}

export function LoadingDialog(props: LoadingDialogProps) {
    const { message, open } = props;

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open} disableBackdropClick={true} disableEscapeKeyDown={true}>
            <DialogTitle id="simple-dialog-title" style={{ paddingBottom: "8px" }}>
                <Grid container spacing={2}>
                    <Grid item>
                        <CircularProgress size={25} />
                    </Grid>
                    <Grid item>
                        {message}
                    </Grid>
                </Grid>
            </DialogTitle>
        </Dialog>
    );
}
