import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IGlobalState } from "../store/types/state";
import * as Yup from "yup";
import { useFormik } from "formik";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { Button, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, makeStyles, TextField, Theme } from "@material-ui/core";

import { Actions as authActions } from "../store/ducks/auth";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

interface IChangeOwnPasswordProps {
    modal: { open: boolean; setOpen(value: boolean): void; };
}

const validationSchema = Yup.object().shape({
    old_password: Yup.string()
        .min(6, "Mínimo de 6 caracteres")
        .max(32, "Senha muito longa")
        .required("Campo obrigatório"),
    new_password1: Yup.string()
        .notOneOf([Yup.ref("old_password"), ""], "A Nova Senha deve ser diferente da Senha Atual")
        .min(6, "Mínimo de 6 caracteres")
        .max(32, "Senha muito longa")
        .required("Campo obrigatório"),
    new_password2: Yup.string()
        .oneOf([Yup.ref("new_password1"), ""], "As senhas não conferem")
        .required("Campo obrigatório")
});

export const ChangeOwnPassword: React.FC<IChangeOwnPasswordProps> = ({ modal }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
    const [showNewPassword1, setShowNewPassword1] = useState<boolean>(false);
    const [showNewPassword2, setShowNewPassword2] = useState<boolean>(false);

    const { error, status } = useSelector(
        (state: IGlobalState) => state.auth
    );

    const formik = useFormik({
        initialValues: { old_password: "", new_password1: "", new_password2: "" },
        onSubmit: async (credentials: {
            old_password: string;
            new_password1: string;
            new_password2: string;
        }) => {
            authActions.changePassword(dispatch, credentials);
        },
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false
    });

    const handleClose = () => {
        setShowOldPassword(false);
        setShowNewPassword1(false);
        setShowNewPassword2(false);
        formik.resetForm();
        modal.setOpen(false);
    };

    const handleClickShowOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };

    const handleClickShowNewPassword1 = () => {
        setShowNewPassword1(!showNewPassword1);
    };

    const handleClickShowNewPassword2 = () => {
        setShowNewPassword2(!showNewPassword2);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (error) {
            const errorItems = error.response.data;
            for (var key in errorItems) {
                var value = errorItems[key];
                formik.setFieldError(key, value);
            }
        }
        if (status === "changed") {
            handleClose();
            NotificationManager.success("Senha alterada com sucesso!", "Alteração de Senha");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    return (
        <Dialog open={modal.open} onClose={handleClose} aria-labelledby="form-dialog-title"
            aria-describedby="alert-dialog-description" disableBackdropClick={true}
            disableEscapeKeyDown={true}>
            <form onSubmit={formik.handleSubmit} className={classes.root}>
                <DialogTitle id="form-dialog-title">Alteração de Senha</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText><Alert severity="info">A Nova Senha deve conter no mínimo 6 (seis) caracteres, pelo menos 1 (uma) letra maiúscula, 1 (uma) minúscula e 1 (um) número.</Alert></DialogContentText>
                    <TextField
                        id="old_password"
                        label="Senha Atual"
                        variant="outlined"
                        type={showOldPassword ? 'text' : 'password'}
                        value={formik.values.old_password}
                        onChange={formik.handleChange}
                        error={!!formik.errors.old_password}
                        helperText={formik.errors.old_password}
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton aria-label="alterna a visibilidade da senha"
                                    onClick={handleClickShowOldPassword}
                                    onMouseDown={handleMouseDownPassword}>
                                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                    <TextField
                        id="new_password1"
                        label="Nova Senha"
                        variant="outlined"
                        type={showNewPassword1 ? 'text' : 'password'}
                        value={formik.values.new_password1}
                        onChange={formik.handleChange}
                        error={!!formik.errors.new_password1}
                        helperText={formik.errors.new_password1}
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton aria-label="alterna a visibilidade da senha"
                                    onClick={handleClickShowNewPassword1}
                                    onMouseDown={handleMouseDownPassword}>
                                    {showNewPassword1 ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                    <TextField
                        id="new_password2"
                        label="Confirmar Nova Senha"
                        variant="outlined"
                        type={showNewPassword2 ? 'text' : 'password'}
                        value={formik.values.new_password2}
                        onChange={formik.handleChange}
                        error={!!formik.errors.new_password2}
                        helperText={formik.errors.new_password2}
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton aria-label="alterna a visibilidade da senha"
                                    onClick={handleClickShowNewPassword2}
                                    onMouseDown={handleMouseDownPassword}>
                                    {showNewPassword2 ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>CANCELAR</Button>
                    <Button color="primary" type="submit" disabled={formik.isSubmitting}>
                        {formik.isSubmitting ? (<CircularProgress size="1.5rem" color="inherit" />) : (<span>ALTERAR</span>)}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1)
            },
        },
    }),
);

export default ChangeOwnPassword;