import React from "react";
import { getNumberSpecs } from "../i18n";

//@ts-ignore
import CurrencyInputDef from 'react-currency-input';

export const CurrencyInput = (props: any) => {
  return <CurrencyInputDef
    maxLength={6}
    onChangeEvent={props.onChange}
    decimalSeparator={getNumberSpecs().delimiters.decimal}
    thousandSeparator={getNumberSpecs().delimiters.thousands}
    precision={getNumberSpecs().currencyFormat?.mantissa}
    {...props}
    onChange={undefined}
  />
};
