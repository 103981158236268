import React from "react";
import { Grid, Breadcrumbs } from "@material-ui/core";
import { StyledBreadcrumb } from "./StyledBreadcrumb";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import { useHistory } from "react-router-dom";

interface ICustomBreadcrumbsProps {
  pathList: { label: string; url: string }[];
}

export const CustomBreadcrumbs: React.FC<ICustomBreadcrumbsProps> = ({
  pathList,
}) => {
  const history = useHistory();

  const handleLinkClick = (pathname: string) => {
    history.push({ pathname });
  };
  return (
    <Grid container>
      <Grid item>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            icon={<HomeIcon />}
            onClick={() => handleLinkClick("/operational/dashboard")}
          />
          {pathList.map((path) => (
            <StyledBreadcrumb
              key={path.label}
              component="a"
              href="#"
              label={path.label}
              onClick={() => handleLinkClick(path.url)}
            />
          ))}
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};
