import React, { useState, useEffect, useCallback } from "react";
import { Chart } from 'react-google-charts'
import { useParams } from 'react-router-dom'
import {
  Typography,
  makeStyles,
  styled,
  createStyles,
  Theme,
  Grid,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
import { DescriptionOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next';
import { format, startOfDay, subDays } from 'date-fns'
import numbro from 'numbro';
import { CURRENT_DATE_FORMAT, CURRENT_DATE_TIME_FORMAT, DEFAULT_LOCALE } from "../../../i18n";
import { CourierDetails, CourierFinancialStats, CourierOperation } from "../../../services/types/courier";
import CourierService from "../../../services/courierService";
import { endOfDay } from "date-fns/esm";
import DatePicker from "react-datepicker";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { OriginStatus } from "../../../services/types/deliveryRequest";
import { jsPDF } from "jspdf";

interface ICourierWalletProps {
  courier?: CourierDetails;
}

interface ICardGain {
  value: number;
  title: string;
}

interface IMoneyText {
  value: number;
  type: string;
  origin: string;
}

interface ITableOperations {
  data: CourierOperation[]
}

interface IParams {
  courierId: string;
}

const MoneyText: React.FC<IMoneyText> = ({ value, type, origin }) => {
  let money = { negative: false, value: "" };
  if (origin === OriginStatus.DISCOUNT) {
    money = { negative: true, value: `${numbro(value).formatCurrency()}` };
  } else {
    money = (type === "CREDIT")
      ? { negative: false, value: `+${numbro(value).formatCurrency()}` }
      : { negative: true, value: `-${numbro(value).formatCurrency()}` }
  }


  const MoneyTypo = styled(Typography)({
    color: money.negative ? '#E86262' : '#6CBE54',
  })

  return <MoneyTypo variant="subtitle1">{money.value}</MoneyTypo>
}

const CardGain: React.FC<ICardGain> = ({ value, title }) => {
  const classes = useStyles();

  return (
    <Paper
      elevation={4}
      className={classes.cardGain}>
      <Typography variant="h1">{numbro(value).formatCurrency()}</Typography>
      <Typography variant="body1">{title}</Typography>
    </Paper >
  )
}

const determineTitle = (item: CourierOperation) => {
  let result = item.delivery_request ? `Entrega #${item.delivery_request?.request_number}` : "Entrega";
  switch (item.origin as OriginStatus) {
    case OriginStatus.DELIVERY:
      result = item.delivery_request ? `Entrega #${item.delivery_request?.request_number}` : "Entrega";
      if (item.type === "DEBIT") {
        result = item.delivery_request ? `Estorno - ${item.delivery_request.request_number}` : "Estorno";
      }
      break;
    case OriginStatus.TRANSFER:
      result = "Transferência";
      break;
    case OriginStatus.INCENTIVE:
      result = "Taxa de Incentivo";
      break;
    case OriginStatus.DEDICATED_FEE:
      result = "Taxa de Dedicado";
      break;
    case OriginStatus.OTHER:
      result = "Crédito Avulso";
      if (item.type === "DEBIT") {
        result = "Débito Avulso";
      }
      break;
    case OriginStatus.ANTICIPATION:
      result = "Antecipação";
      break;
    case OriginStatus.DISCOUNT:
      result = "Débito Avulso";
      break;
    case OriginStatus.REWARD:
      result = "Recompensa por Indicação";
      break;
    default:
      break;
  }

  return result;
}

export const CourierWallet: React.FC<ICourierWalletProps> = () => {
  const classes = useStyles();
  const { courierId } = useParams<IParams>();
  const { t } = useTranslation();

  const [dateStart, setDateStart] = useState<Date | null>(new Date());
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());

  const [proof, setProof] = useState<HTMLElement | any>(null);

  const [chartTypeList] = useState([
    { label: "Semana", value: "WEEK" },
    { label: "Mês", value: "MONTH" },
    { label: "3 Meses", value: "PERIOD" },
  ])
  const [tableTypeList] = useState([
    { label: "Hoje", value: 0 },
    { label: "Últimos 7 dias", value: 7 },
    { label: "Últimos 30 dias", value: 30 },
    { label: "Customizado", value: 999 },
  ])

  const [chartType, setChartType] = useState<string>(() => chartTypeList[0].value)
  const [tableType, setTableType] = useState<number>(() => tableTypeList[1].value)
  const [activity, setActivity] = useState<CourierOperation[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [financialStats, setFinancialStats] = useState<CourierFinancialStats>({
    current_balance: 0,
    today_earnings: 0,
    current_week_earnigns: 0,
    last_four_weeks_earnings: 0,
    graph: {
      Sunday: 0,
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
    }
  });

  const loadActivity = useCallback(async (courierId: number, startDate: Date, endDate: Date) => {
    try {
      if (startDate && endDate) {
        const activity = await CourierService.getCourierActivity(courierId, startDate, endDate);
        setActivity(activity);
      }
    } catch (error) { }
  }, []);

  const loadFinancialStats = useCallback(async (courierId: number) => {
    try {
      const financialStats = await CourierService.getCourierFinancialStats(courierId);
      setFinancialStats(financialStats);
    } catch (error) { }
  }, []);

  const [graphData, setGraphData] = useState<any[]>([]);

  const [options] = useState({
    legend: 'none',
    curveType: 'function',
    backgroundColor: 'transparent',
    chartArea: { width: '85%', right: 0 },
    vAxis: { viewWindowMode: "explicit", viewWindow: { min: 0 }, format: 'currency' },
  });

  useEffect(() => {
    if (financialStats) {
      const data = Object.entries(financialStats.graph)
        .map((item) => [t('days_week.' + item[0]), item[1]])
      setGraphData([["Dia", "Valor"], ...data])
    }
  }, [financialStats, t])

  useEffect(() => {
    if (tableType !== 999) {
      let today = new Date();
      loadActivity(Number(courierId), startOfDay(subDays(today, tableType)), endOfDay(today));
    }
  }, [tableType, loadActivity, courierId]);

  useEffect(() => {
    if (tableType === 999 && dateStart && dateEnd) {
      loadActivity(Number(courierId), startOfDay(dateStart), endOfDay(dateEnd));
    }
  }, [dateStart, dateEnd, loadActivity, courierId, tableType]);

  useEffect(() => {
    loadFinancialStats(Number(courierId));
  }, [chartType, loadFinancialStats, courierId]);

  const showDialogComprovant = (item: CourierOperation) => {
    if (item.document) {
      CourierService.getProofPayment(item.document).then((res) => {
        setProof(res.data);
        handleOpen();
      }).catch((error) => {
        NotificationManager.error(
          "Erro ao buscar o Comprovante.",
          "Comprovante"
        );
      });
    }
  }

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setProof(null);
  };

  const handleDownloadPdf = () => {
    //comprovante
    const iFrame = document.getElementById("receipt");
    let iFrameBody = (iFrame as HTMLIFrameElement).contentDocument?.getElementsByTagName("body")[0];
    if (iFrameBody) {
      const pdf = new jsPDF("p", "px", "a4");
      pdf.html(iFrameBody, {
        callback: function (res) {
          pdf.save("comprovante.pdf");
        },
        width: 300,
        windowWidth: 600,
        x: 75
      });
    }
  }

  const handleOpenNewWindow = () => {
    //comprovante
    const iFrame = document.getElementById("receipt");
    (iFrame as HTMLIFrameElement).contentWindow?.focus();
    (iFrame as HTMLIFrameElement).contentWindow?.print();
  }

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justify="space-between">
        <Grid item md={6}>
          <div className={classes.cardGainGroup}>
            <CardGain
              value={financialStats ? financialStats.current_balance : 0}
              title="Saldo em carteira" />
            <CardGain
              value={financialStats ? financialStats.today_earnings : 0}
              title="Ganhos de Hoje" />
            <CardGain
              value={financialStats ? financialStats.current_week_earnigns : 0}
              title="Ganhos na Semana" />
            <CardGain
              value={financialStats ? financialStats.last_four_weeks_earnings : 0}
              title="Média Semanal" />
          </div>

          <Paper elevation={4} className={classes.chartWrapper}>
            <FormControl>
              <Select
                labelId="graph-range-label"
                id="graph-range"
                value={chartType}
                onChange={(e) => setChartType(e.target.value as string)}
                disableUnderline
                disabled
              >
                {chartTypeList.map((item, index) => (
                  <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Chart
              height="100%"
              width="100%"
              chartType="LineChart"
              data={graphData || []}
              options={options}
              chartLanguage="pt-BR"
            />
          </Paper>
        </Grid>

        <Grid item md={6}>
          <Paper className={classes.tableWrapper}>
            <Grid item className={classes.filterGain}>
              <FormControl style={{ marginBottom: '20px' }}>
                <Select
                  labelId="table-range-label"
                  id="table-range"
                  value={tableType}
                  onChange={(e) => setTableType(e.target.value as number)}
                  disableUnderline
                >
                  {tableTypeList.map((item, index) => (
                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {
                tableType === 999 ? (
                  <React.Fragment>
                    <DatePicker
                      id="rangeStart"
                      name="rangeStart"
                      required={true}
                      onChange={(val: any, event: React.SyntheticEvent) => {
                        event.preventDefault();
                        if (val) {
                          setDateStart(startOfDay(val));
                        } else {
                          setDateStart(null);
                        }
                      }}
                      maxDate={dateEnd}
                      selected={dateStart}
                      startDate={dateStart}
                      endDate={dateEnd}
                      selectsStart
                      locale={DEFAULT_LOCALE}
                      dateFormat={CURRENT_DATE_FORMAT}
                      isClearable
                      wrapperClassName="MuiFormControl"
                      autoComplete="off"
                      customInput={<TextField
                        label="Início"
                        variant="outlined"
                        size="small"
                        error={dateStart == null}
                        helperText={dateStart == null && "Data de início é obrigatória"}
                        InputProps={{ autoComplete: "off" }}
                      />}
                    />

                    <DatePicker
                      id="rangeEnd"
                      name="rangeEnd"
                      required={true}
                      onChange={(val: any, event: React.SyntheticEvent) => {
                        event.preventDefault();
                        if (val) {
                          setDateEnd(endOfDay(val));
                        } else {
                          setDateEnd(null);
                        }
                      }}
                      maxDate={new Date()}
                      minDate={dateStart}
                      selected={dateEnd}
                      startDate={dateStart}
                      endDate={dateEnd}
                      selectsEnd
                      locale={DEFAULT_LOCALE}
                      dateFormat={CURRENT_DATE_FORMAT}
                      isClearable
                      wrapperClassName="MuiFormControl"
                      customInput={<TextField
                        label="Fim"
                        variant="outlined"
                        size="small"
                        error={dateEnd == null}
                        helperText={dateEnd == null && "Data de fim é obrigatória"}
                        InputProps={{ autoComplete: "off" }}
                      />}
                    />
                  </React.Fragment>
                ) : (
                  <Typography style={{ fontSize: 12, marginLeft: 20 }}>
                    {`${format(subDays(new Date(), tableType), CURRENT_DATE_FORMAT)} - ${format(new Date(), CURRENT_DATE_FORMAT)}`}
                  </Typography>
                )
              }
            </Grid>
            <Grid style={{ overflowY: 'auto', maxHeight: '65vh', width: '100%' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <NewTableCell>Descrição</NewTableCell>
                    <NewTableCell>Data</NewTableCell>
                    <NewTableCell>Valor</NewTableCell>
                    <NewTableCell>Detalhes</NewTableCell>
                  </TableRow>
                </TableHead>
                {!activity.length
                  ? <Typography
                    style={{ margin: '12px 8px' }}
                  > Nenhum registro encontrado.
                  </Typography>
                  : <TableBody>
                    {activity.map((row, index) => (
                      <TableRow key={index}>
                        <NewTableCell>
                          <Typography variant="subtitle1">{
                            determineTitle(row)
                          }</Typography>
                          <Typography variant="body1" style={{ fontSize: 12 }}>
                            {row.type === "CREDIT"
                              ? row.delivery_request ? `${row.delivery_request?.store_name} ${row.delivery_request?.store_city}` : ""
                              : "Automática - Speedy"
                            }
                          </Typography>
                        </NewTableCell>
                        <NewTableCell>
                          <Typography variant="body1">
                            {format(row.created_at, [OriginStatus.DELIVERY].includes(row.origin as OriginStatus) ? CURRENT_DATE_TIME_FORMAT : CURRENT_DATE_FORMAT)}
                          </Typography>
                        </NewTableCell>
                        <NewTableCell>
                          <MoneyText value={row.value} type={row.type} origin={row.origin} />
                        </NewTableCell>
                        <NewTableCell>
                          {row.type === "DEBIT" && row.document && (
                            <Button size="small" color="default" onClick={() => { showDialogComprovant(row) }} startIcon={<DescriptionOutlined fontSize="inherit" />}>
                              Comprovante
                            </Button>
                          )}
                        </NewTableCell>
                      </TableRow>
                    ))}
                  </TableBody>}
              </Table>
            </Grid >
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        scroll="paper"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent dividers={true}>
          <iframe id="receipt" title="Comprovante" style={{ width: "100%", height: "100%" }} frameBorder={0} srcDoc={proof} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleOpenNewWindow}>
            Imprimir
          </Button>
          <Button variant="outlined" color="primary" onClick={handleDownloadPdf}>
            Baixar
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const NewTableCell = withStyles({
  root: {
    borderBottom: "none",
  }
})(TableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "10px 12px",
    },
    tabs: {
      marginTop: "20px",
      borderBottom: "1px solid #8e8e8e",
    },
    cardGainGroup: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      columnGap: "50px",
      rowGap: "30px",
      marginBottom: "30px"
    },
    cardGain: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      padding: "30px 0",
      whiteSpace: "nowrap",
    },
    chartWrapper: {
      padding: "15px 20px 20px",
    },
    tableWrapper: {
      padding: "30px",
      marginLeft: "50px",
    },
    filterGain: {
      display: 'flex',
      alignItems: 'baseline',
    },
    dialogPaper: {
      height: '100%',
    },
  })
);
