import React from "react";
import { useFormik } from "formik";
import { Grid, Card, TextField, Typography, Button, CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as LogoNameIcon } from "../../assets/img/logo-azul.svg";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router";
import PasswordService from '../../services/passwordService';
//@ts-ignore
import { NotificationManager } from "react-notifications";

interface IActivateProps { }

const parseQueryString = (string: any) => {
  return string.slice(1).split("&")
    .map((queryParam: string) => {
      let kvp = queryParam.split("=")
      return { key: kvp[0], value: kvp[1] }
    })
    .reduce((query: { [x: string]: any; }, kvp: { key: string | number; value: any; }) => {
      query[kvp.key] = kvp.value
      return query
    }, {})
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Mínimo de 6 caracteres")
    .max(50, "Senha muito longa")
    .required("Campo obrigatório"),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), ""], "As senhas não conferem")
    .required("Campo obrigatório"),
});
export const Activate: React.FC<IActivateProps> = () => {
  const history = useHistory();

  const queryParams = parseQueryString(useLocation().search)
  const params: { uid: string; token: string; } = queryParams;

  const formik = useFormik({
    initialValues: { password: "", password_confirmation: "" },
    onSubmit: async (values) => {
      try {
        await PasswordService.setFirstPassword(params.uid, params.token, values.password);
        NotificationManager.success("Ativação de usuário", "Usuário ativado com sucesso");
        history.push({ pathname: "/" });
      } catch (error) {
        if (error.response.data) {
          const errorKeys = Object.keys(error.response.data);
          errorKeys.forEach(key => {
            if (key === "uid" || key === "token") {
              NotificationManager.error("Ativação de usuário", "Não foi possível ativar seu usuário! O link utilizado expirou, entre em contato com o suporte.");
              history.push({ pathname: "/" });
            } else if (key === "non_field_errors") {
              const errorMsg: string[] = error.response.data[key];
              formik.setFieldError("password", errorMsg.join('. ').replaceAll("['", "").replaceAll("']", ""));
            } else {
              const errorMsg: string[] = error.response.data[key];
              formik.setFieldError(key, errorMsg.join('. ').replaceAll("['", "").replaceAll("']", ""));
            }
          });
        }
      }
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  return (
    <div className="container-unauthenticated">
      <Card className="card-unauthenticated">
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <LogoNameIcon width={150} fill="#fff" />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h1">Olá, defina sua senha.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Alert severity="info">A Nova Senha deve conter no mínimo 6 (seis) caracteres e deve conter pelo menos 1 (uma) letra maiúscula, 1 (uma) minúscula e 1 (um) número.</Alert>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Senha"
                id="password"
                type="password"
                value={formik.values.password}
                error={!!formik.errors.password}
                helperText={formik.errors.password}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Confirmar senha"
                id="password_confirmation"
                type="password"
                value={formik.values.password_confirmation}
                error={!!formik.errors.password_confirmation}
                helperText={formik.errors.password_confirmation}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <CircularProgress size="1.5rem" color="inherit" />
                ) : (
                    <span>Salvar</span>
                  )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </div>
  );
};