import React, { useState, useEffect, useMemo } from "react";
import { formatPhoneNumber } from "../../utils/formatters";
import {
    Grid,
    FormControl,
    TextField,
    Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import CourierService from "../../services/courierService";
import { debounce } from "lodash";
import MaterialTable from "@material-table/core";
import { DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import { ImgLoader } from "../../components/ImgLoader";
import { CourierAccountStatus, CourierResponse } from "../../services/types/courier";

interface ISelectCourierProps {
    initialCouriers: any[];
    status_courier?: CourierAccountStatus | undefined;
    onCouriersChange: (selectedCourierIds: number[]) => void;
    formik: any;
}

const TABLE_LOCALIZATION = {
    ...TABLE_L10N_PTBR,
    body: {
        ...TABLE_L10N_PTBR.body,
        emptyDataSourceMessage: "Nenhum Entregador Selecionado"
    }
}

const SelectCourier: React.FC<ISelectCourierProps> = ({ initialCouriers, onCouriersChange, status_courier = undefined, formik }) => {
    const [courierList, setCourierList] = useState<CourierResponse[]>([]);
    const [courierSearchValue, setCourierSearchValue] = useState<string>("");
    const [selectedCourier, setSelectedCourier] = useState<CourierResponse | null>(null);
    const [couriers, setCouriers] = useState<any[]>(initialCouriers);

    const updateCourierList = useMemo(() => debounce((name: string, status_account_courier: CourierAccountStatus | undefined) => {
        if (name.trim().length > 3) {
            CourierService.getCouriersByName(name, status_account_courier).then(data => {
                const couriersFiltered: CourierResponse[] = data.filter(item => !couriers.find(courier => courier.id === item?.id));
                setCourierList(couriersFiltered);
            });
        } else {
            setCourierList([]);
        }
    }, 300, { trailing: true }), [couriers]);

    useEffect(() => {
        updateCourierList(courierSearchValue, status_courier);
    }, [courierSearchValue, updateCourierList, status_courier]);

    const triggerCouriersChange = (couriers: any[]) => {
        const courierIdList = couriers.map(courier => courier.id);
        onCouriersChange(courierIdList);
    }

    const addCourier = () => {
        if (!couriers.find(courier => courier.id === selectedCourier?.id)) {
            couriers.push(selectedCourier);
            formik.setFieldError("couriers", undefined);
            triggerCouriersChange(couriers);
        }
        setSelectedCourier(null);
        setCourierSearchValue("");
    }

    const removeCourier = (courier: CourierResponse) => {
        let couriersSelected = [...couriers];
        couriersSelected.splice(couriersSelected.findIndex(function(i){
            return i.id === courier.id;
        }), 1);
        setCouriers(couriersSelected);
        triggerCouriersChange(couriersSelected);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={7}>
                <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                        id="couriers"
                        options={courierList}
                        getOptionLabel={(courier) => `${courier.name} - ${courier.region.name} - ${courier.region.state}`}
                        value={selectedCourier}
                        defaultValue={selectedCourier}
                        size="small"
                        inputValue={courierSearchValue}
                        onChange={(e, newValue) => {
                            if (newValue) {
                                setSelectedCourier(newValue);
                            } else {
                                setSelectedCourier(null);
                            }
                        }}
                        onInputChange={(event, newInputValue) => {
                            setCourierSearchValue(newInputValue);
                        }}
                        loadingText="Aguarde ..."
                        noOptionsText="Sem opções"
                        renderOption={(courier) => (
                            <React.Fragment>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <ImgLoader src={courier.photo} width={40} height={40} />
                                    </Grid>
                                    <Grid item>
                                        {`${courier.name} - ${courier.region.name} - ${courier.region.state}`}
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Entregador"
                                helperText={formik.errors.couriers || "Digite pelo menos 4 letras para realizar a consulta"}
                                error={!!formik.errors.couriers}
                                variant="outlined"
                            />
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4} lg={5}>
                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={selectedCourier === null}
                    onClick={addCourier}
                >
                    Adicionar
                </Button>
            </Grid>

            <Grid item xs={12}>
                <MaterialTable
                    columns={[
                        { field: "id", hidden: true, width: 60 },
                        { field: "name", title: "Nome", defaultSort: "asc" },
                        {
                            field: "phonenumber", title: "Telefone", emptyValue: "",
                            render: (rowData, type) => formatPhoneNumber(rowData.phonenumber)
                        },
                        {
                            field: "region", title: "Região", emptyValue: "",
                            render: (rowData, type) => rowData.region.name + " - " + rowData.region.state
                        }
                    ]}
                    data={couriers}
                    actions={[
                        {
                            icon: () => <DeleteOutlineOutlinedIcon />,
                            tooltip: 'Remover',
                            onClick: (event, rowData) => removeCourier(rowData)
                        }
                    ]}
                    components={{
                        ...DEFAULT_TABLE_COMPONENTS,
                        Container: (props: any) => <div {...props}></div>
                    }}
                    localization={TABLE_LOCALIZATION}
                    options={{
                        ...DEFAULT_TABLE_OPTIONS,
                        paging: false,
                        draggable: false
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default SelectCourier;
