import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Grid, Typography } from "@material-ui/core";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import RegionService from "../../services/regionService";
import MaterialTable, {
    MaterialTableProps,
    Query,
    QueryResult,
} from "@material-table/core";
import {
    CURRENCY_COLUMN_DEFAULTS,
    DATE_TIME_COLUMN_DEFAULTS,
    DEFAULT_TABLE_COMPONENTS,
    DEFAULT_TABLE_OPTIONS,
    PageableParams,
    TABLE_L10N_PTBR,
} from "../../components/TableTrinkets";
import { IncentiveResponse } from "../../services/types/incentive";
import IncentiveService from "../../services/incentiveService";
import {
    INITIAL_FILTER_PARAMS,
    IIncentiveFilterParams,
    IncentiveFilter,
} from "./FilterIncentive";
import { VisibilityOutlined } from "@material-ui/icons";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import ShowIf from "../../components/ShowIf";
import AuthService from "../../services/authService";
import { decodeURLParams, URLParamType } from "../../utils/utils";

export const Incentives: React.FC = () => {
    const history = useHistory();
    const [filterParams, setFilterParams] = useState<IIncentiveFilterParams>({
        ...INITIAL_FILTER_PARAMS,
        ...decodeURLParams(history.location.search, [
            { "start_date_after": URLParamType.DATE_TIME },
            { "start_date_before": URLParamType.DATE_TIME },
            { "end_date_after": URLParamType.DATE_TIME },
            { "end_date_before": URLParamType.DATE_TIME },
        ])
    });
    const [regionList, setRegionList] = useState<any[]>([]);

    const tableRef = React.useRef<MaterialTableProps<IncentiveResponse>>();

    const loadData = (query: Query<IncentiveResponse>) =>
        new Promise<QueryResult<IncentiveResponse>>((resolve, reject) => {
            IncentiveService.getIncentives(
                filterParams,
                PageableParams.fromQuery(query)
            ).then(response => {
                resolve({
                    data: response.data,
                    page: query.page,
                    totalCount: response.count,
                });
            });
        });

    const applyFilter = (params: IIncentiveFilterParams) => {
        setFilterParams(params);
        if (tableRef.current?.onQueryChange) {
            tableRef.current.onQueryChange({
                page: 0,
            } as Query<IncentiveResponse>);
        }
    };

    const showDetails = async (item: IncentiveResponse) => {
        history.push(`/incentive/detail/${item.id}`);
    };

    useEffect(() => {
        const getRegions = async () => {
            const response = await RegionService.loadAllRegions({
                ordering: "name",
            });
            setRegionList(response);
        };
        getRegions();
    }, []);

    return (
        <div>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Incentivos</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[
                                { label: "Incentivos", url: "/incentive" },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <ShowIf condition={AuthService.hasAuthorization("add_incentivedynamic")}>
                        <Button color="primary" variant="contained"
                            startIcon={<AddCircleOutlineRoundedIcon />}
                            component={Link} to="/incentive/new">
                            Novo Incentivo
                        </Button>
                    </ShowIf>
                </Grid>
            </Grid>

            <IncentiveFilter
                params={filterParams}
                regionList={regionList}
                onFilterChanged={applyFilter}
            />

            <MaterialTable
                tableRef={tableRef}
                columns={[
                    { field: "id", hidden: true, width: 60 },
                    {
                        ...DATE_TIME_COLUMN_DEFAULTS,
                        field: "created_at",
                        title: "Criado Em",
                    },
                    {
                        ...DATE_TIME_COLUMN_DEFAULTS,
                        field: "start_date",
                        title: "Data do Início",
                    },
                    {
                        ...DATE_TIME_COLUMN_DEFAULTS,
                        field: "end_date",
                        title: "Data do Fim",
                    },
                    { field: "title", title: "Título" },
                    {
                        ...CURRENCY_COLUMN_DEFAULTS,
                        field: "speedy_value",
                        title: "Valor da Speedy",
                    },
                    {
                        ...CURRENCY_COLUMN_DEFAULTS,
                        field: "customer_value",
                        title: "Valor do Cliente",
                    },
                    {
                        field: "region",
                        title: "Região",
                        render: (rowData, type) =>
                            `${rowData.region.name} - ${rowData.region.state}`,
                    },
                ]}
                data={loadData}
                actions={[
                    {
                        icon: () => <VisibilityOutlined />,
                        tooltip: "Ver Detalhes",
                        onClick: (event, rowData) =>
                            showDetails(rowData as IncentiveResponse),
                    },
                ]}
                components={DEFAULT_TABLE_COMPONENTS}
                localization={TABLE_L10N_PTBR}
                options={DEFAULT_TABLE_OPTIONS}
            />
        </div>
    );
};
