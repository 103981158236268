import { formatISO } from "date-fns";
import { apiAxios as axios } from "../store/api/config";
import { getURL, urls } from "../store/api/urls";
import { convertToDate } from "../utils/utils";
import { IPageableParams, PagedResponse } from "./types/common";
import { DebitNotesResponse, EntriesResponse } from "./types/debitNotes";

export interface IDebitNoteServiceService {
    getDebitNotes: (
        customerId: number | null,
        date_after: Date | null,
        date_before: Date | null,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<DebitNotesResponse>>;

    getDebitNote: (debitNoteId: number) => Promise<any>;

    getTransaction: (transactionId: number) => Promise<any>;

    uploadInvoiceFile: (
        debitNoteId: number,
        fileContent: Blob
    ) => Promise<any>;

    downloadInvoice: (url: string) => Promise<any>;

    downloadDebitNote: (debitNoteId: number) => Promise<any>;

    removeInvoice: (debitNoteId: number) => Promise<any>;
}

const DebitNoteService: IDebitNoteServiceService = {
    getDebitNotes: async (
        customerId: number | null,
        date_after: Date | null,
        date_before: Date | null,
        pageParams: IPageableParams
    ) => {
        let dateAfter = date_after ? formatISO(date_after, { representation: 'date' }) : null;
        let dateBefore = date_before ? formatISO(date_before, { representation: 'date' }) : null;

        const url = getURL(urls.DEBIT_REPORTS, {
            ...pageParams,
            customer: customerId,
            reference_date_after: dateAfter,
            reference_date_before: dateBefore,
        });
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then(response => {
                    const data: DebitNotesResponse[] = response.data.results.map((item: any) => {
                        return {
                            ...item,
                            id: Number(item.id),
                            created_at: convertToDate(item.created_at),
                            emission_date: convertToDate(item.emission_date),
                            reference_date: convertToDate(item.reference_date),
                            updated_at: convertToDate(item.emission_date),
                        };
                    });
                    const result: PagedResponse<DebitNotesResponse> = {
                        data: data,
                        count: response.data.count,
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    getDebitNote: async (debitNoteId: number) => {
        const url = `${urls.DEBIT_REPORTS}${debitNoteId}/`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then(response => {

                    const entries: EntriesResponse[] = response.data.entries.map((item: any) => {
                        return {
                            ...item,
                            id: Number(item.id),
                            account: Number(item.account),
                        };

                    });

                    const result = {
                        ...response.data,
                        id: Number(response.data.id),
                        created_at: convertToDate(response.data.created_at),
                        emission_date: convertToDate(response.data.emission_date),
                        reference_date: convertToDate(response.data.reference_date),
                        updated_at: convertToDate(response.data.emission_date),
                        entries: entries,
                    }
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    getTransaction: async (transactionId: number) => {
        const url = `${urls.TRANSACTION}${transactionId}/`;

        return axios.get(url);
    },


    uploadInvoiceFile: async (
        debitNoteId: number,
        fileContent: Blob
    ) => {
        const url = `${urls.DEBIT_REPORTS}${debitNoteId}/add_invoice/`;

        const formData = new FormData();
        formData.append("invoice", fileContent);

        return axios.patch(url, formData);
    },

    downloadInvoice: async (urlNote: string) => {
        const url = urlNote;

        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    responseType: "blob",
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => reject(error));
        });
    },

    downloadDebitNote: (debitNoteId: number) => {
        const url = `${urls.DEBIT_REPORTS}${debitNoteId}/pdf_report/`;

        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    responseType: "blob",
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => reject(error));
        });
    },

    removeInvoice: (debitNoteId: number) => {
        const url = `${urls.DEBIT_REPORTS}${debitNoteId}/remove_invoice/`;

        return new Promise((resolve, reject) => {
            axios
                .put(url, {})
                .then(response => {
                    resolve(response);
                })
                .catch(error => reject(error));
        });
    }
};

export default DebitNoteService;
