import { formatISO, parseISO } from "date-fns";
import numbro from "numbro";
import queryString, { ParseOptions, StringifyOptions } from "query-string";

export function convertToBoolean(value: any, defaultValue: boolean | null = null): boolean | null {
    let result: boolean | null = defaultValue;

    if (value !== null) {
        if (typeof value === "boolean") {
            result = value;
        } else if (typeof value === "string") {
            const strValue = value.trim().toLowerCase();
            if (strValue === "true") {
                result = true;
            } else if (strValue === "false") {
                result = false;
            }
        } else if (typeof value === "number") {
            if (value === 0) {
                result = false;
            } else {
                result = true;
            }
        }
    }

    return result;
}

export function convertToNumber(value: any, defaultValue: number | null = null): number | null {
    let result: number | null = defaultValue;

    if (!isNullOrUndefined(value)) {
        if ((typeof value === "string") && (value.trim() === "")) {
            result = defaultValue;
        } else {
            result = Number(value);
            if (isNaN(result)) {
                result = numbro.unformat(value);

                if (result === undefined) {
                    result = defaultValue;
                }
            }
        }
    }

    return result;
}

export function isValidDate(value: any): boolean {
    return value instanceof Date && isFinite(value.getTime());
}

function isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
}

export function convertToDate(value: any, defaultValue: Date | null = null): Date | null {
    let result: Date | null = defaultValue;

    if (value) {
        if (typeof value === "string") {
            result = parseISO(value);
        } else if (typeof value === "number") {
            result = new Date(value);
        } else if (value instanceof Date) {
            result = value;
        }
    }

    return isValidDate(result) ? result : defaultValue;
}

export const distance = (lat1: number, lon1: number, lat2: number, lon2: number, unit: string) => {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    }
    else {
        let radlat1 = Math.PI * lat1 / 180;
        let radlat2 = Math.PI * lat2 / 180;
        let theta = lon1 - lon2;
        let radtheta = Math.PI * theta / 180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === "K") { dist = dist * 1.609344 }
        if (unit === "N") { dist = dist * 0.8684 }
        if (unit === "m") { dist = dist * 1609.344 }
        return dist;
    }
}

export interface Coordinate {
    latitude: number;
    longitude: number;
}

export const calcNeSwBounds = (coords: Coordinate[]): { ne: Coordinate, sw: Coordinate } | null => {
    let result: { ne: Coordinate, sw: Coordinate } | null = null;

    if (coords && coords.length) {
        const latitudes: number[] = [];
        const longitudes: number[] = [];
        coords.forEach(coord => {
            latitudes.push(coord.latitude);
            longitudes.push(coord.longitude);
        });

        let latNE = Math.max(...latitudes);
        let lngNE = Math.max(...longitudes);
        let latSW = Math.min(...latitudes);
        let lngSW = Math.min(...longitudes);

        result = {
            ne: { latitude: latNE, longitude: lngNE },
            sw: { latitude: latSW, longitude: lngSW }
        }
    }

    return result;
}

export function unaccent(str: string): string {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export enum URLParamType {
    DATE,
    DATE_TIME,
    NUMBER,
    BOOLEAN,
    STRING
}

export type EncodeDecodeTip = {
    [any: string]: URLParamType;
}

function getTipType(paramName: string, tips?: EncodeDecodeTip[]): URLParamType | undefined {
    let result: URLParamType | undefined = undefined;
    if (tips) {
        for (let tip of tips) {
            if (tip[paramName] !== undefined) {
                result = tip[paramName];
                break;
            }
        }
    }
    return result;
}

function formatParamValue(value: any, type: URLParamType): string {
    let result = "";

    if (value) {
        switch (type) {
            case URLParamType.DATE:
                result = formatISO(new Date(value), { representation: 'date' });
                break;
            case URLParamType.DATE_TIME:
                result = formatISO(new Date(value));
                break;
            default:
                result = String(value);
                break;
        }
    }

    return result;
}

function decodeParamValue(value: any, type: URLParamType): any {
    let result = null;

    if (value) {
        switch (type) {
            case URLParamType.DATE:
            case URLParamType.DATE_TIME:
                result = convertToDate(value);
                break;
            case URLParamType.NUMBER:
                result = convertToNumber(value);
                break;
            case URLParamType.BOOLEAN:
                result = convertToBoolean(value);
                break;
            default:
                result = String(value);
                break;
        }
    }

    return result;
}

export function decodeURLParams(query: string, tips?: EncodeDecodeTip[]): any {
    let result = null;

    const options: { parseBooleans: true, parseNumbers: true } & ParseOptions = {
        parseBooleans: true,
        parseNumbers: true,
        arrayFormat: `bracket-separator`,
        arrayFormatSeparator: ',',
        parseFragmentIdentifier: true
    }
    
    result = queryString.parse(query, options);
    for (let key of Object.keys(result)) {
        const type = getTipType(key, tips);
        if (type !== undefined) {
            result[key] = decodeParamValue(result[key], type);
        }
    }

    return result;
}

export function encodeURLParams(path: string, params: { [any: string]: any }, tips?: EncodeDecodeTip[]): string {
    const preprocessedParams = { ...params };

    for (let key of Object.keys(preprocessedParams)) {
        const type = getTipType(key, tips);
        if (type !== undefined) {
            preprocessedParams[key] = formatParamValue(preprocessedParams[key], type);
        } else {
            if (preprocessedParams[key] instanceof Date) {
                preprocessedParams[key] = formatISO(new Date(preprocessedParams[key]));
            }
        }
    }
    const options: StringifyOptions = {
        skipNull: true,
        skipEmptyString: true,
        encode: true,
        strict: false,
        arrayFormat: 'bracket-separator',
        arrayFormatSeparator: ','
    };
    return queryString.stringifyUrl({ url: path, query: preprocessedParams }, options);
}
