import React, { useEffect, useLayoutEffect, useState } from "react";
import { makeStyles, createStyles, Theme, Grid, IconButton } from "@material-ui/core";

import {
  DirectionsBikeOutlined,
  DirectionsCarOutlined,
  MotorcycleOutlined,
  Storefront,
  HouseOutlined,
  Timeline
} from "@material-ui/icons";

import { format, formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";

import { InfoCardCourier } from "./CustomComponents/InfoCardCourier";
import { InfoCardCustomer } from "./CustomComponents/InfoCardCustomer";
import CustomTable from "./CustomComponents/CustomTable";
import { MapContainer, TileLayer, ZoomControl, Tooltip, CircleMarker } from "react-leaflet";
import LeafletMarker from "../../../components/maps/LeafletMarker";
import L from "leaflet";
import { DeliveryRequest, DeliveryRequestStatus, TripTrackingPoint } from "../../../services/types/deliveryRequest";
import CustomControl from "../../../components/maps/CustomControl";
import { CURRENT_DATE_TIME_FORMAT } from "../../../i18n";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import clsx from "clsx";
import TripService from "../../../services/tripService";

interface IMarkerCoord {
  type: string;
  title: string;
  lat: number;
  lng: number;
}

interface IMapProps {
  deliveryRequest: DeliveryRequest;
  isActive: boolean;
  refreshData: () => void;
}

export const Map: React.FC<IMapProps> = ({ deliveryRequest, isActive, refreshData }) => {
  const classes = useStyles();
  const [markerCoords, setMarkerCoords] = useState<IMarkerCoord[]>();
  const [trackingPoints, setTrackingPoints] = useState<TripTrackingPoint[] | null>(null);
  const [mapBounds, setMapBounds] = useState<[number, number][]>();
  const [map, setMap] = useState<L.Map>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const loadTrackingPoints = React.useCallback(() => {
    if (deliveryRequest.current_courier) {
      setLoading(true);
      TripService.getTripTracking(deliveryRequest.trip_id, deliveryRequest.current_courier.id)
        .then((response) => {
          setTrackingPoints(response);
        })
        .catch((error) => {
          setTrackingPoints(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [deliveryRequest.current_courier, deliveryRequest.trip_id]);

  const toggleTrackingPoints = React.useCallback(() => {
    if (trackingPoints === null) {
      loadTrackingPoints();
    } else {
      setTrackingPoints(null);
    }
  }, [loadTrackingPoints, trackingPoints]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (trackingPoints !== null && isActive) {
        loadTrackingPoints();
      }
      refreshData();
    }, 20000);

    return function cleanup() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [refreshData, loadTrackingPoints, trackingPoints, isActive]);

  useEffect(() => {
    if (deliveryRequest?.origin_address) {
      const bounds: [number, number][] = [
        [deliveryRequest?.origin_address?.latitude, deliveryRequest?.origin_address?.longitude],
        [deliveryRequest?.destination_address?.latitude, deliveryRequest?.destination_address?.longitude]
      ];
      let typeHouse = "HOUSE";
      if(deliveryRequest.status === DeliveryRequestStatus.FINISHED){
        typeHouse = "HOUSE_FINISH";
      }else if(deliveryRequest.status === DeliveryRequestStatus.RETURNING){
        typeHouse = "HOUSE_RETURNING"
      }else if(deliveryRequest.status === DeliveryRequestStatus.CANCELED 
        || deliveryRequest.status === DeliveryRequestStatus.DISCARDED){
        typeHouse = "HOUSE_CANCELED";
      }else{
        typeHouse = "HOUSE"
      }
      const titleHouse = `#${deliveryRequest?.order_number} - ${deliveryRequest?.customer.customer_name}`;
      const _coordsMarker = [
        {
          type: "STORE",
          title: "Loja",
          lat: deliveryRequest?.origin_address?.latitude,
          lng: deliveryRequest?.origin_address?.longitude,
        },
        {
          type: typeHouse,
          title: titleHouse,
          lat: deliveryRequest?.destination_address?.latitude,
          lng: deliveryRequest?.destination_address?.longitude,
        },
      ]
      if ((deliveryRequest?.courier_position?.updated_at !== null) && (deliveryRequest?.courier_position?.updated_at !== undefined)) {
        const title = `Entregador - Localização atualizada há ${formatDistanceToNow(deliveryRequest?.courier_position.updated_at, { locale: ptBR })}`;
        bounds.push([deliveryRequest?.courier_position?.latitude, deliveryRequest?.courier_position?.longitude]);
        _coordsMarker.push({
          type: deliveryRequest?.current_courier?.vehicle_type,
          title: title,
          lat: deliveryRequest?.courier_position?.latitude,
          lng: deliveryRequest?.courier_position?.longitude,
        })
      }
      setMapBounds(bounds);
      setMarkerCoords(_coordsMarker);
    }
  }, [deliveryRequest]);

  useLayoutEffect(() => {
    if (map && mapBounds) {
      try {
        map.getBounds();
      } catch (error) {
        map.fitBounds(mapBounds, { paddingTopLeft: [20, 40], paddingBottomRight: [20, 0] });
      }
    }
  }, [map, mapBounds, trackingPoints]);

  const iconMap = [
    {
      type: "CAR",
      icon: <DirectionsCarOutlined />,
      color: "#8E02F5",
    },
    {
      type: "BIKE",
      icon: <DirectionsBikeOutlined />,
      color: "#8E02F5",
    },
    {
      type: "MOTORCYCLE",
      icon: <MotorcycleOutlined />,
      color: "#8E02F5",
    },
    {
      type: "STORE",
      icon: <Storefront />,
      color: "#0080FF",
    },
    {
      type: "HOUSE",
      icon: <HouseOutlined />,
      color: "#0080FF",
    },
    {
      type: "HOUSE_FINISH",
      icon: <HouseOutlined />,
      color: "#4caf50",
    },
    {
      type: "HOUSE_CANCELED",
      icon: <HouseOutlined />,
      color: "#d74545",
    },
    {
      type: "HOUSE_RETURNING",
      icon: <HouseOutlined />,
      color: "#eb7405",
    }
  ];

  return (
    <React.Fragment>
      <LoadingOverlay open={isLoading} />
      <Grid container spacing={2}>
        <Grid item md={7}>
          <div className={classes.mapWrapper}>
            <MapContainer scrollWheelZoom={false} whenCreated={setMap} zoomControl={false}>
              <ZoomControl position="bottomright" />
              <CustomControl position="topright">
                <IconButton onClick={toggleTrackingPoints} title="Rastreamento"
                  className={clsx([{ [classes.activeTrackingButton]: !!trackingPoints }])}>
                  <Timeline />
                </IconButton>
              </CustomControl>
              <TileLayer
                attribution={'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {markerCoords?.map((marker, index) => {
                const iconType = iconMap.find(
                  (elem) => elem.type === marker.type
                );
                return (
                  <LeafletMarker
                    key={index}
                    position={[Number(marker.lat), Number(marker.lng)]}
                    color={iconType?.color}
                    internalIcon={iconType?.icon}
                    riseOnHover={true}
                  >
                    <Tooltip>{marker.title}</Tooltip>
                  </LeafletMarker>
                );
              })}
              {trackingPoints?.map((point, index) => {
                return (
                  <CircleMarker center={[point.latitude, point.longitude]} radius={5}
                    pathOptions={{ color: "red", fill: true, fillOpacity: .4 }}>
                    <Tooltip>{format(point.created_at, CURRENT_DATE_TIME_FORMAT)}</Tooltip>
                  </CircleMarker>
                );
              })}
            </MapContainer>
          </div>
        </Grid>

        <Grid item md={5}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <InfoCardCourier courier={deliveryRequest?.current_courier} called_iFood={deliveryRequest?.called_ifood} />
            </Grid>
            <Grid item>
              <InfoCardCustomer content={deliveryRequest?.customer} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <CustomTable data={deliveryRequest} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstContainer: {
      display: "flex",
    },
    map: {
      width: "100%",

      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    mapWrapper: {
      position: "relative",
      width: "100%",
      borderRadius: "4px",
      overflow: "hidden",
      height: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
    },
    activeTrackingButton: {
      color: theme.palette.error.main,
    }
  })
);
