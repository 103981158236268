import React from "react";
import { useParams } from "react-router";
import { INCENTIVE_ACTION, NewEditIncentive } from "./NewEditIncentive";

interface EditIncentiveParams {
    incentiveId: string;
}

export const EditIncentive: React.FC = () => {
    const { incentiveId } = useParams<EditIncentiveParams>();

    return (
        <NewEditIncentive action={INCENTIVE_ACTION.EDIT} incentiveId={incentiveId} />
    );
};

