import { apiAxios as axios } from "../store/api/config";
import { getURL, urls } from "../store/api/urls";
import { AnnouncementIncentive, CreateUpdateIncentive } from "./types/incentive";
import { convertToBoolean, convertToDate, convertToNumber } from "../utils/utils";
import { IPageableParams, PagedResponse } from "./types/common";
import { IncentiveDetailResponse, IncentiveResponse } from "./types/incentive";
import { formatRFC3339 } from "date-fns";
import { IIncentiveFilterParams } from "../pages/incentives/FilterIncentive";

export interface IIncentivesService {
    getIncentives: (
        incentiveParams: IIncentiveFilterParams,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<IncentiveResponse>>;

    getIncentiveById: (id: number) => Promise<IncentiveDetailResponse>;

    addIncentive: (incentive: CreateUpdateIncentive) => Promise<any>;

    updateIncentive: (incentiveId: number, incentive: CreateUpdateIncentive) => Promise<any>;
}

const IncentivesService: IIncentivesService = {
    getIncentives: async (
        incentiveParams: IIncentiveFilterParams,
        pageParams: IPageableParams
    ) => {
        const url = getURL(urls.INCENTIVE_DYNAMICS, {
            ...incentiveParams,
            start_date_after: incentiveParams.start_date_after ? formatRFC3339(incentiveParams.start_date_after) : null,
            start_date_before: incentiveParams.start_date_before ? formatRFC3339(incentiveParams.start_date_before) : null,
            end_date_after: incentiveParams.end_date_after ? formatRFC3339(incentiveParams.end_date_after) : null,
            end_date_before: incentiveParams.end_date_before ? formatRFC3339(incentiveParams.end_date_before) : null,
            ...pageParams,
        });
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then(response => {
                    const data: IncentiveResponse[] = response.data.results.map(
                        (item: any) => {
                            return { ...item, id: Number(item.id) };
                        }
                    );
                    const result: PagedResponse<IncentiveResponse> = {
                        data: data,
                        count: response.data.count,
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    getIncentiveById: async (id: number) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${urls.INCENTIVE_DYNAMICS}${id}/`)
                .then(response => {
                    const data = response.data;
                    const announcement: AnnouncementIncentive = {
                        ...data.announcement,
                        id: data.announcement ? convertToNumber(data.announcement.id) : null,
                        expire_date: data.announcement ? convertToDate(data.announcement.expire_date) : null,
                        send_date: data.announcement ? convertToDate(data.announcement.send_date) : null,
                        only_active_couriers: data.announcement ? convertToBoolean(data.announcement.only_active_couriers) : null,
                        only_active_regions: data.announcement ? convertToBoolean(data.announcement.only_active_regions) : null,
                        sent: data.announcement ? convertToBoolean(data.announcement.sent) : null,
                    }

                    const result: IncentiveDetailResponse = {
                        ...data,
                        created_at: convertToDate(data.created_at),
                        updated_at: convertToDate(data.updated_at),
                        start_date: convertToDate(data.start_date),
                        end_date: convertToDate(data.end_date),
                        speedy_value: convertToNumber(data.speedy_value),
                        customer_value: convertToNumber(data.customer_value),
                        announcement: announcement,
                    }
                    resolve(result)
                })
                .catch(error => reject(error));
        });
    },

    addIncentive: async (incentive: CreateUpdateIncentive) => {
        return new Promise((resolve, reject) => {
            axios
                .post(urls.INCENTIVE_DYNAMICS, incentive)
                .then(response => resolve(response))
                .catch(error => reject(error));
        });
    },

    updateIncentive: (incentiveId: number, incentive: CreateUpdateIncentive) => {
        /* const formData = new FormData();
        formData.append("end_date", formatRFC3339(end_date)); */
        return new Promise((resolve, reject) => {
            axios
                .patch(`${urls.INCENTIVE_DYNAMICS}${incentiveId}/`, incentive)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }
};

export default IncentivesService;
