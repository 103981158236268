/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Fade from "@material-ui/core/Fade";
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import { EMPTY_CUSTOMER_USER, ICustomerUser } from "../../../store/types/customer";
import { mobilePhoneMaskRegex } from "../../../utils/masks";
import { LoadingComponent } from "../../../components/Loading";
import { PhoneInput } from "../../../components/PhoneInput";
import { useSelector } from "react-redux";
import { IGlobalState } from "../../../store/types/state";
import { StoreResponse } from "../../../services/types/customer";
import { formatPhoneNumber } from "../../../utils/formatters";

interface INewUserModal {
  modal: {
    open: boolean;
    setOpen(value: boolean): void;
  };
  storeList: StoreResponse[];
  handleAddCustomerUser(value: ICustomerUser): void;
  handleUpdateCustomerUser(value: ICustomerUser): void;
  handleClearUser(): void;
  customer_user?: ICustomerUser;
}

const validationSchema = Yup.object().shape({
  first_name: Yup.string().max(100, "Máximo de 50 caracteres excedido").required("Campo obrigatório"),
  last_name: Yup.string().max(100, "Máximo de 100 caracteres excedido").required("Campo obrigatório"),
  email: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .email("E-mail inválido")
    .required("Campo obrigatório"),
  phonenumber: Yup.string()
    .max(15, "Telefone inválido")
    .min(15, "Telefone inválido")
    .matches(mobilePhoneMaskRegex, "Telefone inválido"),
});

const UserModal: React.FC<INewUserModal> = ({
  modal,
  storeList,
  handleAddCustomerUser,
  handleUpdateCustomerUser,
  handleClearUser,
  customer_user,
}) => {
  const classes = useStyles();

  const [update, setUpdate] = useState(false);

  const { state, error, isLoading } = useSelector(
    (state: IGlobalState) => state.customer
  );

  const handleClose = () => {
    formik.resetForm();
    handleClearUser();
    modal.setOpen(false);
  };

  const formik = useFormik({
    initialValues: EMPTY_CUSTOMER_USER,
    onSubmit: (newCustomerUser) => {
      const parsedUser = {
        ...newCustomerUser,
        store: newCustomerUser.store === 0 ? null : newCustomerUser.store,
        phonenumber: newCustomerUser.phonenumber === "" ? "" : newCustomerUser.phonenumber,
      };

      if (customer_user?.id) {
        handleUpdateCustomerUser(parsedUser);
      } else {
        handleAddCustomerUser(parsedUser);
      }
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (error) {
      const errorItems = error.response.data;
      for (var key in errorItems) {
        var value = errorItems[key];
        formik.setFieldError(key, value);
      }
    }
    if (state !== "fail") handleClose();
  }, [state, error]);

  useEffect(() => {
    if (customer_user?.id) {
      setUpdate(true);
      formik.setValues(
        {
          ...customer_user,
          store: customer_user.store ? customer_user.store.id : 0,
          phonenumber: customer_user.phonenumber ? formatPhoneNumber(customer_user.phonenumber) : ""
        } || EMPTY_CUSTOMER_USER
      );
    } else {
      setUpdate(false);
      formik.resetForm();
    }
  }, [customer_user]);

  return (
    <div>
      <Dialog
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        open={modal.open}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
      >
        <Fade in={modal.open}>
          {isLoading ? (
            <LoadingComponent />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <DialogTitle id="form-dialog-title">
                {update ? "Editar usuário" : "Adicionar usuário"}
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item md={5}>
                    <TextField
                      id="first_name"
                      label="Nome *"
                      variant="standard"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      helperText={formik.errors.first_name}
                      error={!!formik.errors.first_name}
                      autoFocus
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextField
                      id="last_name"
                      label="Sobrenome *"
                      variant="standard"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                      helperText={formik.errors.last_name}
                      error={!!formik.errors.last_name}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="formatted-text-mask-input">
                        Celular
                      </InputLabel>
                      <Input
                        id="phonenumber"
                        value={formik.values.phonenumber}
                        onChange={formik.handleChange}
                        fullWidth
                        inputComponent={PhoneInput}
                        error={!!formik.errors.phonenumber}
                      />
                      <FormHelperText
                        id="phonenumber-helper"
                        className={classes.helper}
                      >
                        {formik.errors.phonenumber}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <TextField
                      id="email"
                      label="E-mail *"
                      variant="standard"
                      value={formik.values.email}
                      onChange={(event) => {
                        formik.setFieldValue(
                          event.target.id,
                          event.target.value.trim()
                        );
                      }}
                      helperText={formik.errors.email}
                      error={!!formik.errors.email}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      error={!!formik.errors.store}
                    >
                      <InputLabel shrink id="store-label">
                        Loja *
                      </InputLabel>
                      <Select
                        labelId="store-label"
                        id="store"
                        name="store"
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                        value={formik.values.store}
                        onChange={formik.handleChange}
                        error={!!formik.errors.store}
                      >
                        <MenuItem value={0}>Todas</MenuItem>
                        {storeList?.map((store) => (
                          <MenuItem value={store.id}>{store.name}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText id="store-helper">
                        {formik.errors.store}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Typography style={{ fontSize: "10px", marginTop: "20px" }}>
                  Os campos com (*) são de preenchimento obrigatório.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button type="button" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button color="primary" type="submit">
                  {update ? "Salvar" : "Cadastrar"}
                </Button>
              </DialogActions>
            </form>
          )}
        </Fade>
      </Dialog>
    </div>
  );
};

export default UserModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helper: {
      color: theme.palette.error.main,
    },
  })
);
