import { apiAxios } from "../api/config";
import { urls } from "../api/urls";
import { IUser, IGroup } from "../types/user";
import { AppDispatch } from "..";
//@ts-ignore
import { NotificationManager } from "react-notifications";

export const Types = {
  TOGGLE_MENU: "TOGGLE_MENU",
  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",
  GET_GROUPS: "GET_GROUPS",
  SET_USER_FILTER: "SET_USER_FILTER",
  CLEAR_USERS: "CLEAR_USERS",
};

export interface IState {
  isMenuOpen: boolean;
  users: Array<IUser>;
  user: IUser;
  total: number;
  groups: Array<IGroup>;
  isLoading: boolean;
  error: any;
  state: string;
  filter: {
    page: number;
    page_size: number;
    ordering: string;
    name: string;
    email: string;
    user_status: string;
    groups?: string;
  };
}

const INITIAL_STATE = {
  isMenuOpen: false,
  users: new Array<IUser>(),
  user: {} as IUser,
  total: 0,
  groups: [] as Array<IGroup>,
  isLoading: false,
  error: "",
  state: "",
  filter: {
    page: 1,
    page_size: 10,
    ordering: "id",
    name: "",
    email: "",
    user_status: "",
  },
};

export default (
  state = INITIAL_STATE,
  action: { type: string; payload?: any }
): IState => {
  switch (action.type) {
    case Types.CLEAR_USERS:
      return {
        ...state,
        users: INITIAL_STATE.users,
        filter: INITIAL_STATE.filter,
      };
    case Types.TOGGLE_MENU:
      return { ...state, isMenuOpen: !state.isMenuOpen };
    case Types.GET_USER_REQUEST:
      return { ...state, isLoading: true, error: "" };
    case Types.GET_USER_SUCCESS:
      return { ...state, user: action.payload.user, isLoading: false };
    case Types.GET_USER_FAILURE:
      return { ...state, error: action.payload.error, isLoading: false };
    case Types.CREATE_USER_REQUEST:
      return { ...state, isLoading: true, error: "", state: "" };
    case Types.CREATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.concat([{ ...action.payload.user }]),
        state: "saved",
        isLoading: false,
      };
    case Types.CREATE_USER_FAILURE:
      return { ...state, error: action.payload.error, isLoading: false };
    case Types.UPDATE_USER_REQUEST:
      return { ...state, isLoading: true, error: "", state: "" };
    case Types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.concat([{ ...action.payload.user }]),
        isLoading: false,
        state: "updated",
      };
    case Types.UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        state: "",
      };
    case Types.GET_GROUPS:
      return { ...state, groups: action.payload.groups };
    case Types.SET_USER_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.payload },
      };

    default:
      return { ...state };
  }
};

export const Actions = {
  clearUsers: (dispatch: AppDispatch) => {
    dispatch({
      type: Types.CLEAR_USERS,
    });
  },
  getUser: (dispatch: AppDispatch, userId: number) => {
    dispatch({
      type: Types.GET_USER_REQUEST,
    });
    apiAxios
      .get(`${urls.USER}${userId}/`)
      .then((response) => {
        dispatch({
          type: Types.GET_USER_SUCCESS,
          payload: { user: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.GET_USER_FAILURE,
          payload: { error },
        });
      });
  },
  createUser: (dispatch: AppDispatch, user: IUser) => {
    dispatch({
      type: Types.CREATE_USER_REQUEST,
    });
    // const newUser = { ...user,  groups: [1] };
    apiAxios
      .post(urls.USER, user)
      .then((response) => {
        dispatch({
          type: Types.CREATE_USER_SUCCESS,
          payload: { user: response.data },
        });
        NotificationManager.success(
          "Usuário criado com sucesso",
          "Novo Usuário"
        );
      })
      .catch((error) => {
        dispatch({
          type: Types.CREATE_USER_FAILURE,
          payload: { error },
        });
        if (error.response.status !== 400) {
          NotificationManager.error(
            "Ocorreu um erro ao cadastrar o usuário",
            "Novo Usuário"
          );
        }
      });
  },
  updateUser: (dispatch: AppDispatch, user: IUser) => {
    dispatch({
      type: Types.UPDATE_USER_REQUEST,
    });
    apiAxios
      .patch(`${urls.USER}${user.id}/`, user)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_USER_SUCCESS,
          payload: { user: response.data },
        });
        NotificationManager.success(
          "Usuário atualizado com sucesso",
          "Novo Usuário"
        );
      })
      .catch((error) => {
        dispatch({
          type: Types.UPDATE_USER_FAILURE,
          payload: { error },
        });
        if (error.response.status !== 400) {
          NotificationManager.error(
            "Ocorreu um erro ao atualizar o usuário",
            "Novo Usuário"
          );
        }
      });
  },
  getGroups: () => (dispatch: AppDispatch) => {
    apiAxios.get(urls.USER_GROUP).then((response) => {
      dispatch({
        type: Types.GET_GROUPS,
        payload: { groups: response.data.results },
      });
    });
  },
  sendActivate: (dispatch: AppDispatch, userId: number) => {
    apiAxios
      .post(`${urls.ACTIVATE}resend/`, {
        user: userId.toString(),
      })
      .then((response) => {
        NotificationManager.success(
          "E-mail enviado com sucesso",
          "Reenvio de ativação"
        );
      })
      .catch((error) => {
        NotificationManager.error(
          "Não foi possivel reenviar o email.",
          "Reenvio de ativação"
        );
      });
  },
};
