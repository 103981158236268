import React from "react";
import { useFormik } from "formik";
import { Grid, Card, TextField, Typography, Button, CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as LogoNameIcon } from "../../assets/img/logo-azul.svg";
import * as Yup from "yup";
import { useRouteMatch, useHistory } from "react-router";
import PasswordService from "../../services/passwordService";
//@ts-ignore
import { NotificationManager } from "react-notifications";

interface IChangePasswordProps { }

const validationSchema = Yup.object().shape({
  new_password1: Yup.string()
    .min(6, "Mínimo de 6 caracteres")
    .max(50, "Senha muito longa")
    .required("Campo obrigatório"),
  new_password2: Yup.string()
    .oneOf([Yup.ref("new_password1"), ""], "As senhas não conferem")
    .required("Campo obrigatório"),
});

export const ResetPassword: React.FC<IChangePasswordProps> = () => {
  const history = useHistory();

  const { params }: { params: { token: string; uid: string } } = useRouteMatch();

  const formik = useFormik({
    initialValues: { new_password1: "", new_password2: "" },
    onSubmit: async (values: {
      new_password1: string;
      new_password2: string;
    }) => {
      try {
        await PasswordService.resetPassword(params.uid, params.token, values.new_password1, values.new_password2);
        NotificationManager.success("Recuperar senha", "Senha alterada com sucesso");
        history.push({ pathname: "/" });
      } catch (error) {
        if (error.response.data) {
          const errorKeys = Object.keys(error.response.data);
          errorKeys.forEach(key => {
            if (key === "uid" || key === "token") {
              NotificationManager.error("Recuperar senha", "Não foi possível alterar sua senha! O link utilizado expirou, repita todo o procedimento novamente.");
              history.push({ pathname: "/request-password" });
            } else if (key === "non_field_errors") {
              const errorMsg: string[] = error.response.data[key];
              formik.setFieldError("new_password1", errorMsg.join('. ').replaceAll("['", "").replaceAll("']", ""));
            } else {
              const errorMsg: string[] = error.response.data[key];
              formik.setFieldError(key, errorMsg.join('. ').replaceAll("['", "").replaceAll("']", ""));
            }
          });
        }
      }
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  return (
    <div className="container-unauthenticated">
      <Card className="card-unauthenticated">
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <LogoNameIcon width={150} fill="#fff" />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h1">Olá, defina sua nova senha.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Alert severity="info">A Nova Senha deve conter no mínimo 6 (seis) caracteres e deve conter pelo menos 1 (uma) letra maiúscula, 1 (uma) minúscula e 1 (um) número.</Alert>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Nova senha"
                id="new_password1"
                type="password"
                value={formik.values.new_password1}
                error={!!formik.errors.new_password1}
                helperText={formik.errors.new_password1}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Confirmar nova senha"
                id="new_password2"
                type="password"
                value={formik.values.new_password2}
                error={!!formik.errors.new_password2}
                helperText={formik.errors.new_password2}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <CircularProgress size="1.5rem" color="inherit" />
                ) : (
                    <span>Salvar</span>
                  )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </div>
  );
};