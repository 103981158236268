import React, { useCallback, useEffect, useState } from "react";
import { Grid, makeStyles, Paper, Theme, Typography, createStyles, Table, TableBody, TableRow, TableCell, TableHead, Button } from "@material-ui/core";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { useHistory } from "react-router-dom";
import { decodeURLParams, URLParamType } from "../../utils/utils";
import { endOfDay, startOfDay } from "date-fns";
import RegionService from "../../services/regionService";
import { Alert } from "@material-ui/lab";
import { INITIAL_FILTER_PARAMS, IReportCustomersActiveParams, ReportCustomersActiveFilter } from "./ReportCustomersActiveFilter";
import CustomerService from "../../services/customerService";
import { ReportCustomersActiveResponse, StoresCustomersActiveResponse } from "../../services/types/customer";
import MaterialTable from "@material-table/core";
import numbro from "numbro";
import { CURRENCY_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import { LoadingDialog } from "../../components/LoadingDialog";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import fileDownload from "js-file-download";
//@ts-ignore
import { NotificationManager } from "react-notifications";

interface ICardGain {
    value: any;
    title: string;
}

const CardGain: React.FC<ICardGain> = ({ value, title }) => {
    const classes = useStyles();
    return (
        <Paper
            elevation={4}
            className={classes.cardGain}>
            <Typography variant="h1">{value}</Typography>
            <Typography variant="body1">{title}</Typography>
        </Paper >
    )
}

export const ReportCustomersActive = () => {
    const history = useHistory();
    const classes = useStyles();
    const [filterParams, setFilterParams] = useState<IReportCustomersActiveParams>({
        ...INITIAL_FILTER_PARAMS,
        ...decodeURLParams(history.location.search, [
            { "date_after": URLParamType.DATE },
            { "date_before": URLParamType.DATE }
        ])
    });
    const [regionList, setRegionList] = useState<any[]>([]);
    const [customerList, setCustomerList] = useState<any[]>([]);
    const [reportCustomers, setReportCustomers] = useState<ReportCustomersActiveResponse[]>([]);
    const [errorFilter, setErrorFilter] = useState<string>("");
    const [countDeliveries, setCountDeliveries] = useState<number>(0);
    const [countRecharges, setCountRecharges] = useState<number>(0);
    const [countSpeedyValues, setCountSpeedyValues] = useState<number>(0);
    const [countCustomers, setCountCustomers] = useState<number>(0);
    const [isShowLoadingDialog, showLoadingDialog] = React.useState(false);

    const loadData = useCallback(() => {
        const region = filterParams.region !== 0 ? Number(filterParams.region) : null;
        const startDate = filterParams.date_after ? startOfDay(filterParams.date_after) : null;
        const endDate = filterParams.date_before ? endOfDay(filterParams.date_before) : null;
        const customer = filterParams.customer !== 0 ? filterParams.customer : null;
        if (startDate == null || endDate == null) {
            setErrorFilter("Selecione o Mês/Ano para Filtrar as Informações.");
        } else {
            setErrorFilter("");
            showLoadingDialog(true);
            CustomerService.getReportCustomersActive(region, customer, startDate, endDate)
                .then(response => {
                    let totalDeliveries = 0, totalRecharges = 0, totalSpeedyValues = 0, totalCustomers = 0;
                    response.map((item) => {
                        let totalMonthQuantity = 0, totalMonthRecharge = 0, totalMonthSpeedyValue = 0;
                        totalCustomers += item.stores.length ? item.stores.length : 0;
                        item.stores.map((store) => {
                            totalMonthQuantity += store.deliveries_count ? store.deliveries_count : 0;
                            totalMonthRecharge += store.recharges_value ? store.recharges_value : 0;
                            totalMonthSpeedyValue += store.speedy_value ? store.speedy_value : 0;

                            totalDeliveries += store.deliveries_count ? store.deliveries_count : 0;
                            totalRecharges += store.recharges_value ? store.recharges_value : 0;
                            totalSpeedyValues += store.speedy_value ? store.speedy_value : 0;
                        });
                        item.total_quantity_delivery = totalMonthQuantity;
                        item.total_value_recharge = totalMonthRecharge;
                        item.total_speedy_value = totalMonthSpeedyValue;
                        
                    });
                    setCountDeliveries(totalDeliveries);
                    setCountRecharges(totalRecharges);
                    setCountSpeedyValues(totalSpeedyValues);
                    setCountCustomers(totalCustomers);
                    setReportCustomers(response);
                    showLoadingDialog(false);
                }).catch((error)=>{
                    showLoadingDialog(false);
                });
        }
    }, [filterParams]);

    const applyFilter = (params: IReportCustomersActiveParams) => {
        setFilterParams(params);
    }

    useEffect(() => {
        loadData();
    }, [filterParams, loadData]);


    useEffect(() => {
        const getRegions = async () => {
            const response = await RegionService.loadAllRegions({
                ordering: "name",
            });
            setRegionList(response);
        };
        getRegions();
    }, []);

    const getCustomers = async (region_id: number) => {
        const region = region_id !== 0 ? region_id : undefined;
        const response = await CustomerService.getCustomers("", "", "", "", "", true, region, new PageableParams(1, 500, "trade_name"));
        setCustomerList(response.data);
    };
    useEffect(() => {
        getCustomers(filterParams.region);
    }, []);

    const exportDataReportCustomers = () => {
        const region = filterParams.region !== 0 ? Number(filterParams.region) : null;
        const startDate = filterParams.date_after ? startOfDay(filterParams.date_after) : null;
        const endDate = filterParams.date_before ? endOfDay(filterParams.date_before) : null;
        const customer = filterParams.customer !== 0 ? filterParams.customer : null;
        if (startDate == null || endDate == null) {
            setErrorFilter("Selecione o Mês/Ano para Filtrar as Informações.");
        } else {
            setErrorFilter("");
            showLoadingDialog(true);
            CustomerService.exportReportCustomersActive(region, customer, startDate, endDate).then(res => {
            fileDownload(res.data, "relatório clientes ativos.xlsx");
            }).catch(err => {
            NotificationManager.error(
                "Erro ao fazer download do Arquivo de Lançamentos.",
                "Arquivo de Remessa"
            );
            }).finally(() => {
            showLoadingDialog(false);
            });
        }
       
    };

    return (
        <div>
            <LoadingDialog open={isShowLoadingDialog} message={"Carregando..."} />
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Relatório de Clientes Ativos</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[{ label: "Relatório de Clientes Ativos", url: "/reportCustomersActive" }]}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <ReportCustomersActiveFilter params={filterParams} regionList={regionList} customerList={customerList} onRegionChanged={getCustomers} onFilterChanged={applyFilter} />

            {errorFilter !== "" && (
                <Grid item>
                    <Alert severity={errorFilter === "" ? "warning" : "info"}>{errorFilter}</Alert>
                </Grid>
            )}


            <Grid container direction="row" justify="flex-end" style={{ marginBottom: "10px" }}>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<GetAppOutlinedIcon />}
                    onClick={exportDataReportCustomers}
                    disabled={reportCustomers.length === 0}
                    >
                    Exportar
                </Button>
            </Grid>
            <Grid container>
                <Grid item md={12}>
                    <MaterialTable
                        columns={[
                            { field: "region_id", hidden: true, width: 60 },
                            {
                                title: 'Região', field: 'region_name',                              
                            },
                        ]}
                        data={reportCustomers}
                        title="Clientes Ativos"
                        detailPanel={(row) => {
                            return (
                                <Table style={{ width: "95%", marginLeft: "5%" }} size="small" aria-label="purchases">                                  
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={350}>Cliente</TableCell>
                                            <TableCell>Nome da Loja</TableCell>
                                            <TableCell align="left">Quantidade de pedidos no mês</TableCell>
                                            <TableCell align="right">Valor recarregado no mês&nbsp;(R$)</TableCell>
                                            <TableCell align="right">Valor Speedy no mês&nbsp;(R$)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.rowData.stores.map((rowItem: StoresCustomersActiveResponse) => (
                                            <TableRow key={rowItem.customer_id}>
                                                <TableCell component="th" scope="row">
                                                    {rowItem.customer_name}
                                                </TableCell>
                                                <TableCell >
                                                    {rowItem.store_name}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {rowItem.deliveries_count}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {numbro(Number(rowItem.recharges_value)).formatCurrency()}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {numbro(Number(rowItem.speedy_value)).formatCurrency()}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow key={row.rowData.region_id}>
                                            <TableCell component="th" scope="row">                                          
                                            </TableCell>
                                            <TableCell className={classes.backgroundTotal}>
                                            <b>TOTAL:</b>
                                            </TableCell>
                                            <TableCell align="left" className={classes.backgroundTotal}>
                                                <b>{row.rowData.total_quantity_delivery}</b>
                                            </TableCell>
                                            <TableCell align="right" className={classes.backgroundTotal}>
                                                <b>{numbro(Number(row.rowData.total_value_recharge)).formatCurrency()}</b>
                                            </TableCell>
                                            <TableCell align="right" className={classes.backgroundTotal}>
                                                <b>{numbro(Number(row.rowData.total_speedy_value)).formatCurrency()}</b>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            )
                        }}
                        onRowClick={(event, rowData, togglePanel) => { if (togglePanel) togglePanel() }}
                        components={DEFAULT_TABLE_COMPONENTS}
                        localization={TABLE_L10N_PTBR}
                        options={{
                            ...DEFAULT_TABLE_OPTIONS,
                            paging: false,
                        }}
                    />

                    <div className={classes.cardGainGroup}>
                        <CardGain
                            value={countDeliveries}
                            title="Total de Pedidos no Mês" />
                        <CardGain
                            value={numbro(Number(countRecharges)).formatCurrency()}
                            title="Valor Total Recarregado no Mês" />
                        <CardGain
                            value={numbro(Number(countSpeedyValues)).formatCurrency()}
                            title="Valor Total Speedy no Mês" />
                        <CardGain
                            value={countCustomers}
                            title="Total de Clientes no Mês" />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardGainGroup: {
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            columnGap: "40px",
            rowGap: "30px",
            marginBottom: "30px",
            marginTop: "20px"
        },
        cardGain: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "30px 0",
            whiteSpace: "nowrap",
        },
        backgroundTotal: {
            background: "rgb(233 229 229)"
        }
    })
);
