import { RegionResponse } from "./region";

export enum ScreenType {
    HOMEPAGE = "HOMEPAGE",
    ANNOUNCEMENTS_TAB = "ANNOUNCEMENTS_TAB",
}

export enum AnnouncementType {
    SCHEDULED = "SCHEDULED",
    IMMEDIATE = "IMMEDIATE",
}

export enum DeliveryForType {
    ALL_COURIERS = "ALL_COURIERS",
    REGION = "REGION",
    SPECIFIC_COURIERS = "SPECIFIC_COURIERS"
}

interface Courier {
    id: number;
    name: string;
    email: string;
    phonenumber: string;
    region: RegionResponse;
}

export interface AnnouncementResponse {
    id: number;
    title: string;
    content: string;
    region: number | null;
    screen: ScreenType;
    announcement_type: AnnouncementType;
    send_date: Date;
    expire_date: Date;
    picture: string | null;
    delivery_for: DeliveryForType;
    sent: boolean;
    only_active_couriers: boolean;
}

export interface AnnouncementResponseDetail {
    id: number;
    title: string;
    content: string;
    region: RegionResponse;
    screen: ScreenType;
    announcement_type: AnnouncementType;
    send_date: Date;
    expire_date: Date;
    picture?: string;
    delivery_for: DeliveryForType;
    couriers: Courier[];
    sent: boolean;
    only_active_couriers: boolean;
    related_trip: number;
}

export interface CreateUpdateAnnouncement {
    related_trip: number | null;
    title: string;
    content: string;
    region?: number | "";
    screen?: ScreenType | "";
    announcement_type?: AnnouncementType | "";
    send_date?: Date;
    expire_date?: Date;
    picture?: string;
    delivery_for?: DeliveryForType;
    couriers: number[];
    only_active_couriers: boolean;
}

export interface AnnouncementTemplate {
    id: number;
    name: string,
    title: string,
    content: string
}
