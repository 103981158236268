import React, { useState, useEffect, useCallback } from "react";
import {
    Grid,
    Typography,
    FormControl,
    Select,
    MenuItem,
    makeStyles,
    createStyles,
    Theme,
    Paper,
    Divider,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    InputAdornment,
    Card,
    CardContent,
} from "@material-ui/core";
import CustomerService from "../../../services/customerService";
import { EditOutlined } from "@material-ui/icons";
import { CurrencyInput } from "../../../components/CurrencyInput";
import { getNumberSpecs } from "../../../i18n";
import { useFormik } from "formik";
import * as Yup from "yup";
import numbro from "numbro";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { CustomerDeliveryStats, CustomerDetails, StoreResponse } from "../../../services/types/customer";
import ShowIf from "../../../components/ShowIf";
import AuthService from "../../../services/authService";

interface ICustomerExtraDetailStores {
    customer: CustomerDetails;
}

const validationSchema = Yup.object().shape({
    waste_limit: Yup.number()
        .required("Campo obrigatório")
        .transform((currentValue, originalValue) =>
            numbro.unformat(originalValue)
        )
        .max(0, "O valor máximo do limite de crédito é R$ 0,00")
        .min(-99999, "O valor mínimo do limite de crédito é R$ -99.999,00"),
});

export const CustomerPanel: React.FC<ICustomerExtraDetailStores> = ({
    customer,
}) => {
    const classes = useStyles();

    const [openCreditLimitDialog, showCreditLimitDialog] = useState<boolean>(false);
    const [wasteLimit, setWasteLimit] = useState<number>(0);
    const [stores, setStores] = useState<StoreResponse[]>([]);
    const [storeSelected, setStoreSelected] = useState<number>(0);
    const [deliveryStats, setDeliveryStats] = useState<CustomerDeliveryStats>();

    const loadDeliveryStats = useCallback(async (storeId: number) => {
        const response = await CustomerService.getCustomerDeliveryStats(customer.id, storeId);
        setDeliveryStats(response);
    }, [customer]);

    useEffect(() => {
        if (customer?.stores?.length) {
            setStores(customer.stores);
            loadDeliveryStats(storeSelected);
        }
        setWasteLimit(customer.waste_limit ? customer.waste_limit : 0);
    }, [customer, storeSelected, loadDeliveryStats]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setStoreSelected(event.target.value as number);
    };

    const formik = useFormik({
        initialValues: { waste_limit: 0 },
        onSubmit: values => {
            showCreditLimitDialog(false);
            CustomerService.updateCreditLimit(customer.id, values.waste_limit)
                .then(response => {
                    setWasteLimit(Number(response.waste_limit));
                    NotificationManager.success(
                        "Limite de crédito alterado com sucesso."
                    );
                })
                .catch(error => {
                    formik.setFieldValue("waste_limit", wasteLimit);
                    NotificationManager.error(
                        "Ocorreu um erro ao tentar alterar o limite de crédito deste cliente."
                    );
                });
        },
        onReset: values => {
            showCreditLimitDialog(false);
            formik.setFieldValue("waste_limit", wasteLimit);
        },
        validationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const handleClickOpen = () => {
        formik.setFieldValue("waste_limit", wasteLimit);
        showCreditLimitDialog(true);
    };

    const handleClose = () => {
        showCreditLimitDialog(false);
    };

    return (
        <React.Fragment>
            <Dialog
                open={openCreditLimitDialog}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <form
                    onSubmit={formik.handleSubmit}
                    onReset={formik.handleReset}
                >
                    <DialogTitle id="form-dialog-title">
                        Alterar Limite de Crédito
                    </DialogTitle>
                    <DialogContent dividers>
                        <TextField
                            label="Limite de Crédito"
                            id="waste_limit"
                            value={formik.values.waste_limit}
                            onChange={(e) => {
                                const value = Math.abs(numbro.unformat(String(e.target.value))) * -1;
                                formik.setFieldValue("waste_limit", value);
                            }}
                            InputProps={{
                                inputComponent: CurrencyInput,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {getNumberSpecs().currency.symbol}
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{
                                maxLength: 10,
                                allowNegative: true,
                            }}
                            variant="outlined"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" type="reset">
                            Cancelar
                        </Button>
                        <Button color="primary" type="submit">
                            Alterar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Grid container direction="row" spacing={2}>
                <Grid item sm={4}>
                    <FormControl variant="outlined" fullWidth>
                        <Select value={storeSelected} onChange={handleChange}>
                            <MenuItem value={0}>Todas as lojas</MenuItem>
                            {stores.map(store => {
                                return (
                                    <MenuItem key={store.id} value={store.id}>
                                        {store.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item sm={2}></Grid>

                <Grid item sm={6}>
                    <Paper className={classes.cardStore}>
                        <Grid container spacing={2} direction="row">
                            <Grid item sm={5}>
                                <Typography variant="subtitle1">
                                    {customer.corporate_name}
                                </Typography>
                            </Grid>
                            <Grid item sm={7} container direction="column">
                                <Divider orientation="vertical"></Divider>
                                <Grid
                                    item
                                    style={{
                                        textAlign: "right",
                                        marginRight: "45px",
                                    }}
                                >
                                    <Typography variant="body1">
                                        Saldo atual:{" "}
                                        <Typography
                                            variant="subtitle1"
                                            component="span"
                                        >
                                            {Intl.NumberFormat("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                            }).format(
                                                deliveryStats
                                                    ? deliveryStats.current_balance
                                                    : 0
                                            )}
                                        </Typography>
                                    </Typography>
                                </Grid>
                                <Grid item style={{ textAlign: "right" }}>
                                    <Typography variant="body1">
                                        Limite de crédito:{" "}
                                        <Typography
                                            variant="subtitle1"
                                            component="span"
                                        >
                                            {Intl.NumberFormat("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                            }).format(wasteLimit)}
                                        </Typography>
                                        <ShowIf type="visibilityHidden" condition={AuthService.hasAuthorization("change_customer_credit_limit")}>
                                            <IconButton
                                                onClick={handleClickOpen}
                                                aria-label="Alterar Limite de Crédito"
                                                title="Alterar Limite de Crédito"
                                                style={{ padding: "4px 12px" }}
                                            >
                                                <EditOutlined />
                                            </IconButton>
                                        </ShowIf>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {deliveryStats && (
                    <React.Fragment>
                        <Grid item sm={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h1" align="center">
                                        {numbro(deliveryStats.today_expenses).formatCurrency()}
                                    </Typography>
                                    <Typography variant="body1" align="center">Consumo Hoje</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item sm={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h1" align="center">
                                        {numbro(deliveryStats.average_weekly_expenses).formatCurrency()}
                                    </Typography>
                                    <Typography variant="body1" align="center">Média Semanal</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item sm={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h1" align="center">
                                        {numbro(deliveryStats.today_deliveries).format({ mantissa: 0 })}
                                    </Typography>
                                    <Typography variant="body1" align="center">Entregas Hoje</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item sm={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h1" align="center">
                                        {numbro(deliveryStats.average_weekly_deliveries).format({ mantissa: 0 })}
                                    </Typography>
                                    <Typography variant="body1" align="center">Média de Entregas Semanal</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item sm={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h1" align="center">
                                        {numbro(deliveryStats.weekly_expenses).formatCurrency()}
                                    </Typography>
                                    <Typography variant="body1" align="center">Consumo Semanal</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item sm={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h1" align="center">
                                        {numbro(deliveryStats.average_ticket).formatCurrency()}
                                    </Typography>
                                    <Typography variant="body1" align="center">Ticket Médio</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item sm={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h1" align="center">
                                        {numbro(deliveryStats.weekly_deliveries).format({ mantissa: 0 })}
                                    </Typography>
                                    <Typography variant="body1" align="center">Entregas da Semana</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item sm={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h1" align="center">
                                        {numbro(deliveryStats.weekly_canceled_deliveries).format({ mantissa: 0 })}
                                    </Typography>
                                    <Typography variant="body1" align="center">Entregas Canceladas Semanal</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardStore: {
            padding: theme.spacing(2),
            fontSize: "14px",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
            boxShadow: "0 3px 6px rgba(0,0,0,.16)",
        },
    })
);
