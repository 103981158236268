import React from "react";
import { TextMaskCustom } from "./TextMaskCustom";
import { registrationNumberMask, cnpjNumberMask } from "../utils/masks";

export const CPF_CNPJInput = (props: any) => {
  const mask = (rawValue: string) => {
    let result = registrationNumberMask;
    if (rawValue !== null && rawValue !== undefined) {
      const digits = rawValue.match(/\d/g)?.join("");
      if (digits && (digits.length > 11)) {
        result = cnpjNumberMask;
      }
    }
    return result;
  }

  return <TextMaskCustom {...props} mask={mask} />;
};