import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Theme,
  Typography,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { ImgLoader } from "../../../components/ImgLoader";
import { CourierUserStatus, OnlineCourierUserDetails, SpeedyRankingType } from "../../../services/types/courier";
import { useTranslation } from "react-i18next";
import ShowIf from "../../../components/ShowIf";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import RefreshIcon from '@material-ui/icons/Refresh';
import clsx from "clsx";
import { formatPhoneNumber } from "../../../utils/formatters";
import { RegionResponse } from "../../../services/types/region";
import { Store } from "../../../services/types/deliveryRequest";
import { distance } from "../../../utils/utils";
import SearchInput from "../../../components/SearchInput";
import numbro from "numbro";
import CourierService from "../../../services/courierService";
import { debounce } from "lodash";

interface SelectCourierDialogProps {
  open: boolean;
  title?: string;
  region: RegionResponse;
  referenceStore?: Store;
  onCloseRequested: () => void;
  selectedCourier: (courier: OnlineCourierUserDetails) => Promise<void>;
}

interface OnlineCourier extends OnlineCourierUserDetails {
  distanceInKm: number;
}

const BlueSwitch = withStyles({
  switchBase: {
      '&$checked': {
          color: "#2196f3",
      },
  },
  checked: {},
  track: {},
})(Switch);

export const SelectCourierDialog: React.FC<SelectCourierDialogProps> = (
  { open, title = "Direcionar para Entregador", region, referenceStore, onCloseRequested, selectedCourier }
) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isRefreshing, setIsRefreshing] = React.useState<boolean>(false);
  const [searchCourier, setSearchCourier] = useState<string>("");
  const [availableCourierList, setAvailableCourierList] = React.useState<OnlineCourier[]>([]);
  const [filteredCourierList, setFilteredCourierList] = useState<OnlineCourier[]>([]);
  const [disableActions, setDisableActions] = React.useState<boolean>(false);
  const [isAllCouriers, setIsAllCouriers] = React.useState<boolean>(false);

  const refreshCouriers = React.useCallback(() => {
    setIsRefreshing(true);
    CourierService.getOnlineCouriersInRegion(region.id)
      .then((couriers) => {
        var filterCouriers = couriers;
        if(isAllCouriers === false){
          filterCouriers = couriers.filter((courier) => courier.courier_status === CourierUserStatus.AVAILABLE)
        }

        const allCouriers = filterCouriers
          .map((courier) => {
            let courierDistance = 0;
            if (referenceStore) {
              courierDistance = distance(courier.latitude, courier.longitude, referenceStore.latitude, referenceStore.longitude, "K");
            }
            return {
              ...courier,
              distanceInKm: courierDistance
            };
          });
 
        if (referenceStore) {
          allCouriers.sort((a, b) => {
            return a.distanceInKm - b.distanceInKm;
          });
        }

        setAvailableCourierList(allCouriers);
      })
      .catch((error) => { })
      .finally(() => {
        setIsRefreshing(false);
      })
  }, [referenceStore, region, isAllCouriers]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllCouriers(event.target.checked);
  };

  useEffect(() => {
    if (availableCourierList) {
      const filtered = availableCourierList.filter((courier) => {
        const searchValue = searchCourier.trim().toLocaleLowerCase();

        if (searchValue === "") {
          return true;
        }

        if (courier.name?.toLocaleLowerCase().includes(searchValue)) {
          return true;
        }

        if (courier.phonenumber?.toLocaleLowerCase().includes(searchValue)) {
          return true;
        }

        if (t("vehicle_type." + courier.vehicle_type).toLocaleLowerCase().includes(searchValue)) {
          return true;
        }

        if (t("vehicle_load_compartment_type." + courier.vehicle_load_compartment_type).toLocaleLowerCase().includes(searchValue)) {
          return true;
        }

        return false;
      });

      setFilteredCourierList(filtered);
    }
  }, [searchCourier, availableCourierList, t, referenceStore]);

  useEffect(() => {
    if (open) {
      refreshCouriers();
    }
  }, [open, refreshCouriers])

  const formatDistance = (value: number) => {
    value = Math.trunc(value * 100) / 100;

    return numbro(value).format();
  }

  const handleSelectCourier = React.useMemo(() => debounce((courier: OnlineCourierUserDetails) => {
    setDisableActions(true);
    selectedCourier(courier).then(() => { })
      .catch((error) => { })
      .finally(() => { setDisableActions(false); });
  }, 500), [selectedCourier]);

  const handleClose = () => {
    onCloseRequested();
    setAvailableCourierList([]);
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="dialog-row-title"
      aria-describedby="dialog-row-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id="dialog-row-title">
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="h2">
              {title || "Fila de Entregadores"} - {region?.name}
            </Typography>
          </Grid>
          <Grid item xs>
            <IconButton
              aria-label="close"
              onClick={refreshCouriers}
              disabled={isRefreshing}
              title="Atualizar Entregadores"
            >
              {isRefreshing ? (
                <CircularProgress size={21} />
              ) : (
                <RefreshIcon />
              )}
            </IconButton>
          </Grid>
          <Grid item style={{ paddingRight: 0 }}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              disabled={disableActions}
            >
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <SearchInput
              id="searchCourier"
              placeholder="Nome, Veículo, Compartimento ou Telefone"
              fullWidth
              value={searchCourier}
              onChange={(event) => {
                let value = event.target.value;
                if (value) {
                  setSearchCourier(value);
                } else {
                  setSearchCourier("");
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormGroup row className={classes.positionSwitch}>
                <FormControlLabel
                    control={<BlueSwitch size="small" checked={isAllCouriers} onChange={handleChange} name="only_available" />}
                    label={<Typography style={{ fontSize: "14px", fontWeight: "bold" }}>Todos os Entregadores</Typography>}
                    labelPlacement="start"
                />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogModal}>
        <DialogContentText>
          <Grid container spacing={1} direction="column" justify="flex-start">

            {!isRefreshing && filteredCourierList && filteredCourierList.length === 0 && (
              <Grid item xs={12}>
                <Typography variant="h3">
                  Nenhum entregador disponível encontrado.
                </Typography>
              </Grid>
            )}

            {referenceStore && filteredCourierList.map((courier) => (
              <Grid item xs={12} key={courier.id}>
                <Paper className={classes.card} variant="outlined">
                  <Grid container spacing={2} direction="row" wrap="nowrap" justify="space-between" alignItems="center">
                    <Grid item container wrap="nowrap" spacing={1} alignItems="center">
                      <Grid item>
                        <Avatar className={classes.large} alt={courier.name}>
                          <ImgLoader src={String(courier.photo)} className={classes.large} />
                        </Avatar>
                      </Grid>
                      <Grid item zeroMinWidth>
                        <Typography noWrap variant="subtitle2" className={classes.cardTitle} title={courier.name}>
                          <ShowIf condition={courier.speedy_ranking === SpeedyRankingType.INITIATE_SPEEDER}>
                            <StarBorderIcon
                              className={classes.styleStarRanking}
                              fontSize="small"
                            />
                          </ShowIf>
                          <ShowIf condition={courier.speedy_ranking === SpeedyRankingType.ADVANCED_SPEEDER}>
                            <StarIcon
                              className={clsx(classes.styleStarRanking, classes.advancedSpeederStar)}
                              fontSize="small"
                            />
                          </ShowIf>
                          {`${courier.name}  (${formatDistance(courier.distanceInKm)} km → ${referenceStore.name})`}
                        </Typography>

                        <Typography variant="subtitle1" className={classes.cardSubtitle}>
                          {t("vehicle_type." + courier.vehicle_type) + " | "}
                          {t("vehicle_load_compartment_type." + courier.vehicle_load_compartment_type) + " | "}
                          {formatPhoneNumber(courier.phonenumber)}
                        </Typography>

                        <Typography variant="body2" className={classes.chip}>
                          Pontuação: {courier.new_ranking_points}
                        </Typography>
                        <Typography variant="body2" className={courier.courier_status === CourierUserStatus.AVAILABLE ? classes.chip : classes.chipBusy}>
                          Situação: {t('courier_user_status.' + courier.courier_status)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        disabled={disableActions || isRefreshing}
                        onClick={() => {
                          handleSelectCourier(courier);
                        }}
                      >
                        Solicitar
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(1),
  },
  cardTitle: {
    fontWeight: "bold",
    color: "#526272",
    lineHeight: "1.1",
  },
  cardSubtitle: {
    color: "#526272",
    fontSize: "12px"
  },
  chip: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0080FF",
    color: "#fff",
    fontWeight: "bold",
    padding: "0 8px",
    borderRadius: "10px",
    fontSize: "12px",
    marginRight: "10px"
  },
  chipBusy: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ff9800",
    color: "#fff",
    fontWeight: "bold",
    padding: "0 8px",
    borderRadius: "10px",
    fontSize: "12px",
    marginRight: "10px"
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  styleStarRanking: {
    position: "relative",
    top: "3px",
    marginRight: "4px",
  },
  advancedSpeederStar: {
    color: theme.palette.primary.main
  },
  dialogModal: {
    minHeight: "600px !important"
  }, 
  positionSwitch: {
    /* position: "absolute",
    right: "16px",
    color: "white", */
  },
}));

export default SelectCourierDialog;
