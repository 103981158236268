import { emphasize, withStyles, Theme } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

export const StyledBreadcrumb = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#f1f5f9",
    height: theme.spacing(3),
    color: theme.palette.primary.light,
    fontSize: "1rem",
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
    "& .MuiChip-icon": {
      color: theme.palette.primary.light,
    },
  },
}))(Chip) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591