import React, { useState } from "react";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { DATE_TIME_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import { Grid, Typography } from "@material-ui/core";
import { Block} from "@material-ui/icons";
import DedicatedService from "../../services/dedicatedService";
import { BlockDedicatedResponse } from "../../services/types/dedicated";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../components/ConfirmationDialog";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { BlockListFilter, IBlockListFilterParams, INITIAL_FILTER_PARAMS } from "./BlockListFilter";
import { useHistory } from "react-router-dom";
import { decodeURLParams, URLParamType } from "../../utils/utils";
interface BlockListDedicatedProps {
  refreshData: boolean;
};

export const BlockListDedicated: React.FC<BlockListDedicatedProps> = ({refreshData}) => {
  const history = useHistory();
  const [filterParams, setFilterParams] = useState<IBlockListFilterParams>({
    ...INITIAL_FILTER_PARAMS, 
    ...decodeURLParams(history.location.search, [
      { "date_block_after": URLParamType.DATE_TIME },
      { "date_block_before": URLParamType.DATE_TIME }
    ])
  });
  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
  const tableRef = React.useRef<MaterialTableProps<BlockDedicatedResponse>>();

  const loadData = (query: Query<BlockDedicatedResponse>) =>
    new Promise<QueryResult<BlockDedicatedResponse>>((resolve, reject) => {
      const expired_block = false;
      DedicatedService.getBlockListDedicated(filterParams.name, filterParams.email,
          filterParams.date_block_after, filterParams.date_block_before, expired_block , PageableParams.fromQuery(query)
      ).then(response => {
        resolve({
          data: response.data,
          page: query.page,
          totalCount: response.count,
        });
      }).catch((error) => reject(error));
  });
  
  const applyFilter = (params: IBlockListFilterParams) => {
    setFilterParams(params);
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({
        page: 0,
      } as Query<BlockDedicatedResponse>);
    }
  };

  const refresh = () => {
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({
        page: 0,
      } as Query<BlockDedicatedResponse>);
    }
  };

  React.useEffect(() => {
    if(refreshData){
      refresh(); 
    }
  }, [refreshData]);

  const handleExpiredBlock = (item: BlockDedicatedResponse) => {
    if(item){
      confirmationDialog.current?.confirm(
        "Desativar Bloqueio",
        <Typography>Você tem certeza que deseja <strong>Desativar</strong> este Bloqueio?</Typography>,
        "Desativar",
        "Cancelar"
      ).then(() => {
        DedicatedService.expiredBlockDedicated(item.id)
          .then((response) => {
            NotificationManager.success("Desativado com sucesso.", "Bloqueio");
            refresh();
            confirmationDialog.current?.hide();
          })
          .catch((error) => {
            NotificationManager.error("Não foi possível Desativar o Bloqueio.", "Bloqueio");
          });
      }).catch(() => { });
    }
  };
    
  return (
      <div>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{position: "relative", marginTop: 0}}
        >
      </Grid> 
      <ConfirmationDialog ref={confirmationDialog} />

      <BlockListFilter params={filterParams} onFilterChanged={applyFilter} />

      <MaterialTable
        tableRef={tableRef}
        columns={[
          { field: "id", hidden: true, width: 60 },
          {
            ...DATE_TIME_COLUMN_DEFAULTS, 
            field: "created_at", 
            title: "Criado em"
          },
          { 
            field: "courier.name", title: "Entregador"
          },
          { 
            field: "courier.email", title: "Email"
          },
          { field: "reason", title: "Motivo" },
        ]}
        actions={[
          {
              icon: () => <Block />,
              tooltip: 'Excluir Bloqueio',              
              onClick: (event, rowData) => handleExpiredBlock(rowData as BlockDedicatedResponse),
          },
        ]}
        data={loadData}
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_L10N_PTBR}
        options={DEFAULT_TABLE_OPTIONS}
      />
    </div>
  );
};