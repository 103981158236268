import React from "react";
import {
  Grid,
  TextField,
  Button
} from "@material-ui/core";
import { FormikHelpers, useFormik } from "formik";
import { Autocomplete } from "@material-ui/lab";
import { useHistory } from "react-router";
import { encodeURLParams } from "../../utils/utils";

export interface ICommercialPlanFilterParams {
  name: string;
  region: string;
}

export const INITIAL_FILTER_PARAMS: ICommercialPlanFilterParams = {
  name: "",
  region: ""
};

interface IFilterCommercialPlanProps {
  params?: ICommercialPlanFilterParams;
  regionList: any[];
  onFilterChanged: (params: ICommercialPlanFilterParams) => void;
}

export const CommercialPlanFilter: React.FC<IFilterCommercialPlanProps> = ({
  params = INITIAL_FILTER_PARAMS,
  regionList,
  onFilterChanged,
}) => {
  const [selectedRegion, setSelectedRegion] = React.useState<any>(null);
  const history = useHistory();

  React.useEffect(() => {
    history.replace(encodeURLParams(history.location.pathname, params));
  }, [history, params]);

  const formik = useFormik<ICommercialPlanFilterParams>({
    initialValues: params,
    onSubmit: (values: ICommercialPlanFilterParams, formikHelpers: FormikHelpers<ICommercialPlanFilterParams>) => {
      onFilterChanged(values);
    },
    onReset: (values: ICommercialPlanFilterParams, formikHelpers: FormikHelpers<ICommercialPlanFilterParams>) => {
      onFilterChanged(INITIAL_FILTER_PARAMS);
      formikHelpers.setValues(INITIAL_FILTER_PARAMS);
    },
  });

  return (
    <div className="page-filter">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <TextField
              id="name"
              label="Nome"
              onChange={formik.handleChange}
              value={formik.values.name}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item md={5}>
            <Autocomplete
              id="region"
              size="small"
              value={selectedRegion}
              options={regionList}
              getOptionLabel={(option) => `${option.name} - ${option.state}`}
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedRegion(newValue);
                  formik.setFieldValue("region", newValue.id);
                } else {
                  setSelectedRegion(null);
                  formik.setFieldValue("region", null);
                }
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  name="region"
                  label="Região"
                  onChange={formik.handleChange}
                  value={formik.values.region}
                  variant="outlined"
                  fullWidth
                />
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              Pesquisar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" type="reset">
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
