import { apiAxios } from "../api/config";
import { urls } from "../api/urls";
import { 
  INearbyCouriers, 
  ICurrentCourier,
  IReasons
} from "../types/requests";
import { AppDispatch } from "..";
//@ts-ignore
import { NotificationManager } from "react-notifications";

export const Types = {
  GET_NEARBY_COURIERS_REQUEST: "GET_NEARBY_COURIERS_REQUEST",
  GET_NEARBY_COURIERS_SUCCESS: "GET_NEARBY_COURIERS_SUCCESS",
  GET_NEARBY_COURIERS_FAILURE: "GET_NEARBY_COURIERS_FAILURE",
  POST_SET_COURIER_REQUEST: "POST_SET_COURIER_REQUEST",
  POST_SET_COURIER_SUCCESS: "POST_SET_COURIER_SUCCESS",
  POST_SET_COURIER_FAILURE: "POST_SET_COURIER_FAILURE",
  GET_SET_COURIER_REASONS_REQUEST: "GET_SET_COURIER_REASONS_REQUEST",
  GET_SET_COURIER_REASONS_SUCCESS: "GET_SET_COURIER_REASONS_SUCCESS",
  GET_SET_COURIER_REASONS_FAILURE: "GET_SET_COURIER_REASONS_FAILURE",
  CLEAR_NEARBY_COURIERS: "CLEAR_NEARBY_COURIERS",
};

export interface IState {
  nearby_couriers: Array<INearbyCouriers>;
  current_courier:ICurrentCourier,
  reasons: Array<IReasons>;
  isLoading: boolean;
  error: string;
  state: string;
}

export interface IPageableParams {
  page: number;
  page_size: number;
  ordering: string | null;
}

const INITIAL_STATE = {
  nearby_couriers: new Array<INearbyCouriers>(),
  current_courier: {} as ICurrentCourier,
  reasons: new Array<IReasons>(),
  isLoading: false,
  error: "",
  state: "",
};

export default (
  state = INITIAL_STATE,
  action: { type: string; payload?: any }
): IState => {
  switch (action.type) {
    case Types.GET_NEARBY_COURIERS_REQUEST:
      return { ...state, error: "" };
    case Types.GET_NEARBY_COURIERS_SUCCESS:
      return { 
        ...state, 
        nearby_couriers: action.payload.nearby_couriers,
        current_courier: action.payload.current_courier,
      };
    case Types.GET_NEARBY_COURIERS_FAILURE:
      return { ...state, error: action.payload.error };

    case Types.POST_SET_COURIER_REQUEST:
      return { ...state, error: "" };
    case Types.POST_SET_COURIER_SUCCESS:
      return { ...state };
    case Types.POST_SET_COURIER_FAILURE:
      return { ...state, error: action.payload.error };

    case Types.GET_SET_COURIER_REASONS_REQUEST:
      return { ...state, error: "" };
    case Types.GET_SET_COURIER_REASONS_SUCCESS:
      return { ...state, reasons: action.payload.reasons };
    case Types.GET_SET_COURIER_REASONS_FAILURE:
      return { ...state, error: action.payload.error };

    case Types.CLEAR_NEARBY_COURIERS:
      return {
        ...state,
        nearby_couriers: INITIAL_STATE.nearby_couriers,
      }

    default:
      return { ...state };
  }
};

export const Actions = {
  clearNearbyCouriers: (dispatch: AppDispatch) => {
    dispatch({
      type: Types.CLEAR_NEARBY_COURIERS
    })
  },
  getNearbyCouriers: (dispatch: AppDispatch, requestId: number) => {
    dispatch({
      type: Types.GET_NEARBY_COURIERS_REQUEST,
    });

    apiAxios
      .post(`${urls.DELIVERY_REQUEST}${requestId}/nearby_couriers/`, {})
      .then((response) => {
        dispatch({
          type: Types.GET_NEARBY_COURIERS_SUCCESS,
          payload: { 
            nearby_couriers: response.data.nearby_couriers, 
            current_courier: response.data.current_courier
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.GET_NEARBY_COURIERS_FAILURE,
          payload: { error },
        });
      });
  },

  postSetCourier: (dispatch: AppDispatch, requestId: number, params: any) => {
    dispatch({
      type: Types.POST_SET_COURIER_REQUEST,
    });
    apiAxios
      .post(`${urls.DELIVERY_REQUEST}${requestId}/set_courier/`, {
        ...params
      })
      .catch((error) => {
        let errorMsg = "Ocorreu um erro ao chamar o novo entregador";
        const errorKeys = Object.keys(error.response.data);
        if (errorKeys.length) {
          errorMsg = error.response.data[errorKeys[0]];
        }

        dispatch({
          type: Types.POST_SET_COURIER_FAILURE,
          payload: { error },
        });
        NotificationManager.error(errorMsg, "Novo Entregador");
      });
  },

  getSetCourierReason: (dispatch: AppDispatch) => {
    dispatch({
      type: Types.GET_SET_COURIER_REASONS_REQUEST,
    });
    apiAxios
      .get(`${urls.SET_COURIER_REASON}`)
      .then((response) => {
        dispatch({
          type: Types.GET_SET_COURIER_REASONS_SUCCESS,
          payload: { 
            reasons: response.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.GET_SET_COURIER_REASONS_FAILURE,
          payload: { error },
        });
      });
  }

};
