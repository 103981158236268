import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";

import { CURRENCY_COLUMN_DEFAULTS, DEFAULT_TABLE_OPTIONS, PageableParams, TableContainer, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import theme from "../../styles/theme";
import { useHistory } from "react-router-dom";
import { decodeURLParams, URLParamType } from "../../utils/utils";
import { endOfDay, startOfDay } from "date-fns";
import { NotRechargingCustomersResponse } from "../../services/types/customer";
import CustomerService from "../../services/customerService";
import { INITIAL_FILTER_PARAMS, INotRechargingCustomersParams, NotRechargingCustomersFilter } from "./NotRechargingCustomersFilter";
import { formatPhoneNumber } from "../../utils/formatters";
import CityService from "../../services/cityService";
import { City } from "../../services/types/common";

const TABLE_LOCALIZATION = {
    ...TABLE_L10N_PTBR,
    body: {
        ...TABLE_L10N_PTBR.body,
        emptyDataSourceMessage: "Nenhum registro encontrado"
    }
}

export const NotRechargingCustomers = () => {
    const history = useHistory();
    const [filterParams, setFilterParams] = useState<INotRechargingCustomersParams>({
        ...INITIAL_FILTER_PARAMS,
        ...decodeURLParams(history.location.search, [
            { "date_after": URLParamType.DATE },
            { "date_before": URLParamType.DATE }
        ])
    });
    const tableRef = React.useRef<MaterialTableProps<NotRechargingCustomersResponse>>();
    const [cityList, setCityList] = useState<City[]>([]);

    const loadData = (query: Query<NotRechargingCustomersResponse>) => {
        const city = filterParams.fiscal_address_city ? Number(filterParams.fiscal_address_city) : null;
        const startDate = filterParams.date_after ? startOfDay(filterParams.date_after) : null;
        const endDate = filterParams.date_before ? endOfDay(filterParams.date_before) : null;

        if (startDate == null || endDate == null || city == null) {
            TABLE_LOCALIZATION.body.emptyDataSourceMessage = "Selecione o Mês/Ano e Cidade";
            return new Promise<QueryResult<NotRechargingCustomersResponse>>((resolve, reject) => {
                resolve({
                    data: [],
                    page: query.page,
                    totalCount: 0,
                })
            })
        } else {
            TABLE_LOCALIZATION.body.emptyDataSourceMessage = "Nenhum registro encontrado";
            return new Promise<QueryResult<NotRechargingCustomersResponse>>((resolve, reject) => {
                CustomerService.getNotRechargingCustomers(city, startDate, endDate, PageableParams.fromQuery(query))
                    .then(response => {
                        resolve({
                            data: response.data,
                            page: query.page,
                            totalCount: response.count,
                        })
                    })
            });
        }
    }

    const applyFilter = (params: INotRechargingCustomersParams) => {
        setFilterParams(params);
        if (tableRef.current?.onQueryChange) {
            tableRef.current.onQueryChange({ page: 0 } as Query<NotRechargingCustomersResponse>);
        }
    }

    useEffect(() => {
        const getCities = async () => {
            const response = await CityService.getCities();
            setCityList(response);
        };

        getCities();
    }, []);

    return (
        <div>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Clientes que não Recarregaram no Mês</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[{ label: "Não Recarregaram", url: "/notRechargingCustomers" }]}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <NotRechargingCustomersFilter params={filterParams} cityList={cityList} onFilterChanged={applyFilter} />

            <MaterialTable
                tableRef={tableRef}
                title=""
                columns={[
                    { field: "id", hidden: true, width: 60 },
                    {
                        field: "trade_name", title: "Nome", defaultSort: "asc"
                    },
                    {
                        field: "phonenumber", title: "Telefone",
                        render: (rowData, type) => rowData.phonenumber ? formatPhoneNumber(rowData.phonenumber) : "---",
                    },
                    {
                        field: "email", title: "Email"
                    },
                    {
                        field: "contact_name", title: "Nome do Contato"
                    },
                    {
                        ...CURRENCY_COLUMN_DEFAULTS, field: "current_balance", title: "Saldo na Plataforma"
                    },
                ]}
                data={loadData}
                components={{
                    Container: props => (
                        <TableContainer {...props} customStyles={{ paddingBotton: theme.spacing(2), paddingTop: theme.spacing(0) }} />
                    )
                }}
                localization={TABLE_LOCALIZATION}
                options={{
                    ...DEFAULT_TABLE_OPTIONS
                }}
            />

        </div>
    );
};