export enum UserStatus {
    WAITING_ACTIVATION = "WAITING_ACTIVATION",
    ACTIVE = "ACTIVE",
    BLOCKED = "BLOCKED",
    SUSPENDED = "SUSPENDED"
}

export interface UserResponse {
    id: number,
    name: string,
    email: string,
    groups: UserGroup[],
    user_status: UserStatus
}

export interface UserGroup {
    id: number,
    name: string
}

export interface LoggedUser {
    id: number,
    name: string,
    email: string,
    user_type: string,
    groups: UserGroup[],
    permissions: string[]
}