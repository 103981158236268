import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import DedicatedService from "../../../services/dedicatedService";
import { DedicatedResponse, TurnResponse } from "../../../services/types/dedicated";
import { LoadingPage } from "../../../components/Loading";
import { DedicatedTurnsDetails } from "../../dedicated/customComponents/dedicatedTurnsDetails";

interface DedicatedDetailsDialogProps {
};

export interface DedicatedDetailsDialogHandle {
  open: (dedicatedId: number) => void;
  close: () => void;
};

export const DedicatedDetailsDialog: React.ForwardRefRenderFunction<
    DedicatedDetailsDialogHandle,
    DedicatedDetailsDialogProps
> = (props, ref) => {
  const [open, setOpen] = useState<boolean>(false);
  const [dedicated, setDedicated] = useState<DedicatedResponse>();
  const [turns, setTurns] = React.useState<TurnResponse[]>([]);


  React.useImperativeHandle(ref, () => {
    return {
      open(dedicatedId: number) {
        show(dedicatedId);
      },

      close() {
        hide();
      },
    };
  });

  const hide = () => {
    setOpen(false);
    setTimeout(() => {
      setTurns([]);
      setDedicated(undefined);
    }, 200);
  };

  const show = (dedicatedId: number) => {  
    if (dedicatedId) {
      setOpen(true);
      loadDedicated(dedicatedId);
      loadTurnsDedicated(dedicatedId);   
    }
  };

  const loadDedicated = async (id: number) => {
      const response = await DedicatedService.getDedicatedById(id);
      setDedicated(response);
  };

  const loadTurnsDedicated = async (id: number) => {
      const response = await DedicatedService.getTurnsDedicated(id);
      setTurns(response);
  };

  return (
    <Dialog
      open={open}
      onClose={hide}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth={'lg'}
    >
        <DialogTitle id="form-dialog-title">Detalhes do Agendamento</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12}>
            {dedicated ? (
               <>
                <DedicatedTurnsDetails dedicated={dedicated} turns={turns} />
               </> 
            ) : (
              <LoadingPage />
            )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide}>Fechar</Button>
        </DialogActions>
    </Dialog>
  );
};

export default React.forwardRef(DedicatedDetailsDialog);
