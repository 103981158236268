import React from "react";
import { makeStyles, createStyles, Theme, CardContent, Card } from "@material-ui/core";

export const Occurrences: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>

        </CardContent>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  })
);
