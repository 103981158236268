import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import { PhoneInput } from "../../components/PhoneInput";
import { CPF_CNPJInput } from "../../components/CPF_CNPJInput";
import { FormikHelpers, useFormik } from "formik";
import { useHistory } from "react-router";
import { encodeURLParams } from "../../utils/utils";

export interface ICustomerFilterParams {
  trade_name: string;
  email: string;
  phonenumber: string;
  registrationNumber: string;
  city: string;
};

export const INITIAL_FILTER_PARAMS: ICustomerFilterParams = {
  trade_name: "",
  email: "",
  phonenumber: "",
  registrationNumber: "",
  city: ""
};

interface ICustomerFilterProps {
  params?: ICustomerFilterParams;
  cityList: any[];
  onFilterChanged: (params: ICustomerFilterParams) => void;
}

export const CustomerFilter: React.FC<ICustomerFilterProps> = ({ params = INITIAL_FILTER_PARAMS, cityList, onFilterChanged }) => {
  const history = useHistory();

  React.useEffect(() => {
    history.replace(encodeURLParams(history.location.pathname, params));
  }, [history, params]);

  const cleanString = (text: string): string => {
    return text.replace(/[()-./\s]/g, "");
  };

  const formik = useFormik<ICustomerFilterParams>({
    initialValues: params,
    onSubmit: (values: ICustomerFilterParams, formikHelpers: FormikHelpers<ICustomerFilterParams>) => {
      const phonenumber = cleanString(values.phonenumber);
      const registrationNumber = cleanString(values.registrationNumber);
      const newValues = {
        ...values,
        phonenumber: phonenumber,
        registrationNumber: registrationNumber
      };

      onFilterChanged(newValues);
    },
    onReset: (values: ICustomerFilterParams, formikHelpers: FormikHelpers<ICustomerFilterParams>) => {
      onFilterChanged(INITIAL_FILTER_PARAMS);
      formikHelpers.setValues(INITIAL_FILTER_PARAMS);
    }
  });

  return (
    <div className="page-filter">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <TextField
              label="Nome Fantasia"
              id="trade_name"
              onChange={formik.handleChange}
              value={formik.values.trade_name}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              label="E-mail"
              id="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              id="phonenumber"
              label="Celular"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.phonenumber}
              fullWidth
              InputProps={{
                inputComponent: PhoneInput,
              }}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              id="registrationNumber"
              label="CNPJ / CPF"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.registrationNumber}
              fullWidth
              InputProps={{
                inputComponent: CPF_CNPJInput,
              }}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              id="city"
              name="city"
              label="Cidade"
              select
              onChange={formik.handleChange}
              value={formik.values.city}
              variant="outlined"
              fullWidth
            >
              <MenuItem value={0}>Todas</MenuItem>
              {cityList.map((city) => (
                <MenuItem key={city.id} value={city.name}>
                  {city.name} - {city.state_initials}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              Pesquisar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" type="reset">
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
