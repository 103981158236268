const Types = {
  TOGGLE_MENU: "TOGGLE_MENU",
};

export interface IState {
  isMenuOpen: boolean;
}

const INITIAL_STATE = {
  isMenuOpen: true,
};

export default (
  state = INITIAL_STATE,
  action: { type: string; payload?: any }
): IState => {
  switch (action.type) {
    case Types.TOGGLE_MENU:
      return { ...state, isMenuOpen: !state.isMenuOpen };
    default:
      return { ...state };
  }
};

export const Actions = {
  toggleMenu: () => (dispatch: any) =>
    dispatch({
      type: Types.TOGGLE_MENU,
    }),
};
