import React from "react";
import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import CourierService from "../services/courierService";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router";
import { ActionChangeStatusCourier } from "../services/types/courier";

interface IChangeCourierStatusProps {
  modal: { open: boolean; setOpen(value: boolean): void };
  courierId: number;
  action: string | null;
}

export const ChangeCourierStatus: React.FC<IChangeCourierStatusProps> = ({
  modal,
  courierId,
  action,
}) => {
  const history = useHistory();
  const formik = useFormik({
    initialValues: { reason: "" },
    onSubmit: async (values) => {
      try {
        await CourierService.changeStatusCourier(
          courierId,
          values.reason,
          action
        );
        handleClose();
        history.push("/courier");
        NotificationManager.success(
          "Ação realizada com sucesso!",
          "Entregador"
        );
      } catch (error) {
        NotificationManager.error(
          "Não foi possível executar a ação. Entre em contato com o suporte!",
          "Entregador"
        );
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    modal.setOpen(false);
  };

  return (
    <Dialog
      open={modal.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="form-dialog-title">
          {action === ActionChangeStatusCourier.REACTIVATE_COURIER &&
            "Reativar entregador"}
          {action === ActionChangeStatusCourier.DEACTIVATE_COURIER &&
            "Desativar entregador"}
          {action === ActionChangeStatusCourier.DENY_REGISTRATION_COURIER &&
            "Recusar cadastro do entregador"}
          {action === ActionChangeStatusCourier.BAN_COURIER &&
            "Banir entregador"}
          {action === ActionChangeStatusCourier.SUSPEND_COURIER &&
            "Suspender entregador"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="reason"
                label="Descrição"
                variant="outlined"
                multiline
                rows={4}
                onChange={formik.handleChange}
                value={formik.values.reason}
                error={!!formik.errors.reason}
                helperText={formik.errors.reason}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            CANCELAR
          </Button>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            SALVAR
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangeCourierStatus;
