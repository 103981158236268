import { formatRFC3339 } from "date-fns";
import { apiAxios as axios } from "../store/api/config";
import { getURL, urls } from "../store/api/urls";
import { convertToBoolean, convertToDate, convertToNumber } from "../utils/utils";
import { IPageableParams, PagedResponse } from "./types/common";
import { BlockDedicatedResponse, CreateUpdateDedicated, DedicatedResponse, SlotResponse, TurnResponse } from "./types/dedicated";

export interface IDedicatedService {

  getDedicatedById: (id: number) => Promise<any>;

  getDedicated: (
    store: number | null,
    region: number | null,
    start_datetime_after: Date | null,
    start_datetime_before: Date | null,
    pageParams: IPageableParams
  ) => Promise<PagedResponse<DedicatedResponse>>;

  addDedicated: (create: CreateUpdateDedicated) => Promise<any>;

  updateDedicated: (dedicatedId: number, item: CreateUpdateDedicated) => Promise<any>;

  activeDedicated: (dedicatedId: number) => Promise<any>;

  diasbleDedicated: (dedicatedId: number) => Promise<any>;

  getSlotsDedicated: (dedicatedId: number) => Promise<SlotResponse[]>;

  addCourierSlot: (slotId: number, courierId: number) => Promise<any>;

  removeCourierSlot: (slotId: number) => Promise<any>;

  markAsResolved: (slotId: number, is_done: boolean) => Promise<any>;

  getSlots: (
    regionsIds: number[],
    start_datetime_after: Date | null,
    start_datetime_before: Date | null,
    pageParams: IPageableParams
  ) => Promise<PagedResponse<SlotResponse>>;

  getBlockListDedicated: (
    name: string,
    email: string,
    date_block_after: Date | null,
    date_block_before: Date | null,
    expired_block: boolean,
    pageParams: IPageableParams
  ) => Promise<PagedResponse<BlockDedicatedResponse>>;

  expiredBlockDedicated: (blockId: number) => Promise<any>;

  addCourierBlock: (courier: number, reason: string) => Promise<any>;

  getTurnsDedicated: (id: number) => Promise<TurnResponse[]>;

};

const DedicatedService: IDedicatedService = {

  getDedicatedById: (id: number) => {
    return new Promise((resolve, reject) => {
      axios.get(`${urls.DEDICATED_PROPOSALS}${id}/`)
        .then((response) => {
          const result: DedicatedResponse = {
            ...response.data,
            id: convertToNumber(response.data.id),
            end_datetime: convertToDate(response.data.end_datetime),
            first_datetime: convertToDate(response.data.first_datetime),
            number_of_slots: convertToNumber(response.data.number_of_slots),
            recurrent: convertToBoolean(response.data.recurrent),
            start_datetime: convertToDate(response.data.start_datetime),
            value: convertToNumber(response.data.value)

          };
          resolve(result);
        })
        .catch(error => reject(error));
    });
  },

  getDedicated: (
    store: number | null,
    region: number | null,
    start_datetime_after: Date | null,
    start_datetime_before: Date | null,
    pageParams: IPageableParams
  ) => {
    const url = getURL(`${urls.DEDICATED_PROPOSALS}`, {
      ...pageParams,
      first_datetime_after: start_datetime_after
        ? formatRFC3339(start_datetime_after)
        : null,
      first_datetime_before: start_datetime_before
        ? formatRFC3339(start_datetime_before)
        : null,
      store: store ? store : null,
      region: region ? region : null,
    });
    return new Promise((resolve, reject) => {
      axios.get(url).then((response) => {
        const resultFormat: DedicatedResponse[] = response.data.results.map((item: any) => {
          return {
            ...item,
            id: convertToNumber(item.id),
            end_datetime: convertToDate(item.end_datetime),
            first_datetime: convertToDate(item.first_datetime),
            number_of_slots: convertToNumber(item.number_of_slots),
            recurrent: convertToBoolean(item.recurrent),
            start_datetime: convertToDate(item.start_datetime)
          }
        });
        const result: PagedResponse<DedicatedResponse> = {
          data: resultFormat,
          count: response.data.count,
        };
        resolve(result);
      }).catch((error) => reject(error));
    });
  },

  addDedicated: (item: CreateUpdateDedicated) => {
    const url = urls.DEDICATED_PROPOSALS;
    return axios.post(url, item)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  updateDedicated: (dedicatedId: number, item: CreateUpdateDedicated) => {
    const url = `${urls.DEDICATED_PROPOSALS}${dedicatedId}/`;
    return axios.patch(url, {
      id: dedicatedId,
      description: item.description,
      modal_allowed: item.modal_allowed,
      proposal_type: item.proposal_type,
      recurrent: item.recurrent,
      store: item.store,
      turns: item.turns
    })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  activeDedicated: (dedicatedId: number) => {
    const url = `${urls.DEDICATED_PROPOSALS}${dedicatedId}/open/`;
    return axios.patch(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  diasbleDedicated: (dedicatedId: number) => {
    const url = `${urls.DEDICATED_PROPOSALS}${dedicatedId}/close/`;
    return axios.patch(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getSlotsDedicated: (dedicatedId: number) => {
    const url = `${urls.DEDICATED_PROPOSALS}${dedicatedId}/slots/`;

    return new Promise((resolve, reject) => {
      axios.get(url).then((response) => {
        const result: SlotResponse[] = response.data.results.map((item: any) => {
          return {
            ...item,
            id: convertToNumber(item.id),
            end_datetime: convertToDate(item.end_datetime),
            is_done: convertToBoolean(item.is_done),
            start_datetime: convertToDate(item.start_datetime),
            proposal: convertToNumber(item.proposal),
          }
        });

        resolve(result);
      }).catch((error) => reject(error));
    });

  },

  addCourierSlot: (slotId: number, courierId: number) => {
    const url = `${urls.DEDICATED_SLOTS}${slotId}/join/`;
    return axios.post(url, { courier: courierId })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  removeCourierSlot: (slotId: number) => {
    const url = `${urls.DEDICATED_SLOTS}${slotId}/free_slot/`;
    return axios.post(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  markAsResolved: (slotId: number, is_done: boolean) => {
    const url = `${urls.DEDICATED_SLOTS}${slotId}/mark_as_resolved/`;
    return axios.post(url, { is_done: is_done })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getSlots: (
    regionsIds: number[],
    start_datetime_after: Date | null,
    start_datetime_before: Date | null,
    pageParams: IPageableParams
  ) => {
    const url = getURL(`${urls.DEDICATED_SLOTS}`, {
      ...pageParams,
      region: regionsIds ? regionsIds : [],
      start_datetime_after: start_datetime_after
        ? formatRFC3339(start_datetime_after)
        : null,
      start_datetime_before: start_datetime_before
        ? formatRFC3339(start_datetime_before)
        : null,
      store: null,
      has_courier: null,
    });
    return new Promise((resolve, reject) => {
      axios.get(url).then((response) => {
        const resultFormat: SlotResponse[] = response.data.results.map((item: any) => {
          return {
            ...item,
            id: convertToNumber(item.id),
            end_datetime: convertToDate(item.end_datetime),
            first_datetime: convertToDate(item.first_datetime),
            number_of_slots: convertToNumber(item.number_of_slots),
            recurrent: convertToBoolean(item.recurrent),
            start_datetime: convertToDate(item.start_datetime),
            store: convertToNumber(item.store),
            value: convertToNumber(item.value),
            proposal_id: convertToNumber(item.proposal_id),
            quantity_earned: convertToNumber(item.quantity_earned),
            current_earnings: convertToNumber(item.current_earnings),
            region_id: convertToNumber(item.region_id),
          }
        });
        const result: PagedResponse<SlotResponse> = {
          data: resultFormat,
          count: response.data.count,
        };
        resolve(result);
      }).catch((error) => reject(error));
    });
  },

  getBlockListDedicated: (
    name: string,
    email: string,
    date_block_after: Date | null,
    date_block_before: Date | null,
    expired_block: boolean,
    pageParams: IPageableParams) => {
    return new Promise((resolve, reject) => {
      const url = getURL(`${urls.DEDICATED_BLOCK_LIST}`, {
        ...pageParams,
        courier_name: name,
        courier_email: email,
        expired: expired_block,
        created_at_after: date_block_after
          ? formatRFC3339(date_block_after)
          : null,
        created_at_before: date_block_before
          ? formatRFC3339(date_block_before)
          : null
      });

      axios.get(url)
        .then((response) => {
          const resultFormat: BlockDedicatedResponse[] = response.data.results.map((item: any) => {
            let courier = null;
            if (item.courier) {
              courier = {
                ...item.courier,
                id: convertToNumber(item.courier.id)
              };
            }
            return {
              ...item,
              id: convertToNumber(item.id),
              courier: courier,
              created_at: convertToDate(item.created_at),
              expired: convertToBoolean(item.expired),
              updated_at: convertToDate(item.updated_at),
              undefined_time: convertToBoolean(item.undefined_time),
            }
          });
          const result: PagedResponse<BlockDedicatedResponse> = {
            data: resultFormat,
            count: response.data.count,
          };
          resolve(result);
        })
        .catch(error => reject(error));
    });
  },

  expiredBlockDedicated: (blockId: number) => {
    const url = `${urls.DEDICATED_BLOCK_LIST}${blockId}/expire_record/`;
    return axios.patch(url)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  addCourierBlock: (courierId: number, reason: string) => {
    const url = `${urls.DEDICATED_BLOCK_LIST}`;
    return axios.post(url, { courier: courierId, reason: reason })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },

  getTurnsDedicated: (id: number) => {
    return new Promise((resolve, reject) => {
      axios.get(`${urls.DEDICATED_PROPOSALS}${id}/turns/`)
        .then((response) => {
          const result: TurnResponse[] = response.data.map((item: any) => {
            return {
              ...item,
              value: convertToNumber(item.value)
            }
          });
          resolve(result);
        })
        .catch(error => reject(error));
    });
  },

}

export default DedicatedService;