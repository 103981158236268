import React, { useEffect, useState } from "react";
import IncentiveService from "../../services/incentiveService";
import { IncentiveDetailResponse } from "../../services/types/incentive";
import { format } from "date-fns";
import { CURRENT_DATE_TIME_FORMAT } from "../../i18n";
import {
    Button,
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@material-ui/core";
import numbro from "numbro";
import { Link, useParams } from "react-router-dom";
import { LoadingPage } from "../../components/Loading";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { EditOutlined } from "@material-ui/icons";
import ShowIf from "../../components/ShowIf";
import AuthService from "../../services/authService";

interface DetailIncentiveProps {
    incentiveId: string;
}

export const DetailIncentive: React.FC = () => {
    const { incentiveId } = useParams<DetailIncentiveProps>();
    const [incentive, setIncentive] = useState<IncentiveDetailResponse>();
    
    useEffect(() => {
        const loadIncentive = async (id: number) => {
            const response = await IncentiveService.getIncentiveById(id);
            setIncentive(response);
        };
        if (incentiveId) {
            loadIncentive(Number(incentiveId));
        }
    }, [incentiveId]);

    return (
        <div>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h1">
                                Detalhes do Incentivo
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomBreadcrumbs
                                pathList={[
                                    { label: "Incentivos", url: "/incentive" },
                                    {
                                        label: "Detalhes do Incentivo",
                                        url: `/incentive/detail/${incentiveId}`,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <ShowIf condition={AuthService.hasAuthorization("change_incentivedynamic")}>
                                <Button variant="contained" color="secondary"
                                    startIcon={<EditOutlined />}
                                    component={Link} to={`/incentive/edit/${incentiveId}`}>
                                    Editar
                                </Button>
                            </ShowIf>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {!incentive ? (
                <LoadingPage />
            ) : (
                <Card>
                    <CardContent>
                        <Typography variant="h2">Dados do Incentivo</Typography>
                        <Table className="table-bordered">
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Criado em</Typography>
                                        <Typography variant="subtitle1">
                                            {incentive?.created_at
                                                ? format(incentive?.created_at, CURRENT_DATE_TIME_FORMAT)
                                                : "---"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>Atualizado em</Typography>
                                        <Typography variant="subtitle1">
                                            {incentive?.updated_at
                                                ? format(incentive?.updated_at, CURRENT_DATE_TIME_FORMAT)
                                                : "---"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>Data de Início</Typography>
                                        <Typography variant="subtitle1">
                                            {incentive?.start_date
                                                ? format(incentive?.start_date, CURRENT_DATE_TIME_FORMAT)
                                                : "---"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>Data de Fim</Typography>
                                        <Typography variant="subtitle1">
                                            {incentive?.end_date
                                                ? format(incentive?.end_date, CURRENT_DATE_TIME_FORMAT)
                                                : "---"}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Região</Typography>
                                        <Typography variant="subtitle1">
                                            {`${incentive?.region.name} - ${incentive?.region.state}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            Valor pago pela Speedy
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {numbro(incentive?.speedy_value).formatCurrency()}
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={4}>
                                        <Typography>
                                            Valor pago pelo cliente
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {numbro(incentive?.customer_value).formatCurrency()}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Título</Typography>
                                        <Typography variant="subtitle1">
                                            {incentive?.title}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>Mensagem</Typography>
                                        <Typography variant="subtitle1">
                                            {incentive?.message}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            )}
        </div>
    );
};
