import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { CourierOrderHistoryFilter, ICourierOrderFilterParams, INITIAL_FILTER_PARAMS } from './CourierOrderHistoryFilter'
import numbro from 'numbro';
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { CourierDeliveryRequestOrder } from "../../../services/types/courier";
import { DATE_TIME_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";
import CourierService from "../../../services/courierService";

interface ICourierOrderHistoryProps {
  courierId: number;
}

export const CourierOrderHistory: React.FC<ICourierOrderHistoryProps> = ({ courierId }) => {
  const { t } = useTranslation();

  const [filterParams, setFilterParams] = useState<ICourierOrderFilterParams>(INITIAL_FILTER_PARAMS);

  const tableRef = React.useRef<MaterialTableProps<CourierDeliveryRequestOrder>>();

  const loadData = (query: Query<CourierDeliveryRequestOrder>) =>
    new Promise<QueryResult<CourierDeliveryRequestOrder>>((resolve, reject) => {
      CourierService.getOrderHistory(
        courierId, filterParams.request_number, filterParams.order_number,
        filterParams.status, filterParams.customer_name,
        filterParams.store_name, filterParams.requested_time_after,
        filterParams.requested_time_before, PageableParams.fromQuery(query)
      ).then(response => {
        resolve({
          data: response.data,
          page: query.page,
          totalCount: response.count,
        });
      }).catch((error) => reject(error));
    });

  const applyFilter = (params: ICourierOrderFilterParams) => {
    setFilterParams(params);
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({
        page: 0,
      } as Query<CourierDeliveryRequestOrder>);
    }
  };

  return (
    <div>
      <CourierOrderHistoryFilter onFilterChanged={applyFilter} />
      <MaterialTable
        tableRef={tableRef}
        columns={[
          { field: "id", hidden: true, width: 60 },
          {
            ...DATE_TIME_COLUMN_DEFAULTS, 
            field: "requested_time", 
            title: "Data", 
            defaultSort: "desc" 
          },
          { field: "request_number", title: "Solicitação" },
          { field: "order_number", title: "Pedido" },
          { field: "store_name", title: "Loja" },
          {
            field: "status", title: "Situação",
            render: (rowData, type) => rowData.status ? t("delivery_request_status." + rowData.status) : ""
          },
          { field: "closed_date", title: "Encerramento", type: "datetime", emptyValue: "---" },
          {
            field: "delivery_value", title: "Valor do Entregador", type: "currency",
            render: (rowData, type) => numbro(rowData.courier_value).formatCurrency()
          },
          {
            field: "payment", title: "Pagamento",
            render: (rowData, type) => rowData.payment ? t("financial_entry_status." + rowData.payment) : ""
          },
        ]}
        data={loadData}
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_L10N_PTBR}
        options={DEFAULT_TABLE_OPTIONS}
      />
    </div>
  );
};
