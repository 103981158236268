import React from "react";
import type { FC } from "react";

import {
  Divider,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import theme from "../../../styles/theme";
import { SlotResponse } from "../../../services/types/dedicated";
import { formatPhoneNumber } from "../../../utils/formatters";

interface PopoverRegionContactProps {
  openPopover: boolean;
  anchorEl: HTMLElement | null;
  slot: SlotResponse;
  handlePopoverClose: (value: string) => void;
}

const PopoverRegionContact: FC<PopoverRegionContactProps> = ({
  openPopover,
  anchorEl,
  slot,
  handlePopoverClose,
}) => {
  const classes = useStyles();

  return (
    <Popover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={openPopover}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography variant="body1" style={{ marginBottom: 10, marginTop: 10 }}>
        Região:{" "}
        <strong>
          {slot ? slot.region_name : "---"}
        </strong>
      </Typography>

      <Divider />
      <Typography variant="body1" style={{ marginBottom: 10, marginTop: 10 }}>
        Contato:{" "}
        <strong>{slot ? slot.contact_name : "---"}</strong>
      </Typography>

      <Divider />
      <Typography variant="body1" style={{ marginBottom: 10, marginTop: 10 }}>
        Telefone:{" "}
        <strong>{slot ? formatPhoneNumber(slot.contact_phone) : "---"}</strong>
      </Typography>
    </Popover>
  );
};

export default PopoverRegionContact;

const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
  },
  popover: {
    pointerEvents: "none",
  },
  cardTitle: {
    fontSize: "20px",
    marginBottom: "8px",
    fontWeight: "bold",
  },
});
