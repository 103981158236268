import React from "react";
import MaskedInput from "react-text-mask";

interface ITextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  mask: (string | RegExp)[];
}

export const TextMaskCustom: React.FC<ITextMaskCustomProps> = (props) => {
  const { inputRef, mask, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={"\u2000"}
      showMask={false}
    />
  );
};
