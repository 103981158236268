import { apiAxios as axios } from "../store/api/config";
import { getURL, urls } from "../store/api/urls";
import { IUser } from "../store/types/user";
import { IPageableParams, PagedResponse } from "./types/common";
import { UserGroup, UserResponse } from "./types/user";

export interface IUserService {
    getUsers: (
        name: string,
        email: string,
        userStatus: string,
        groups: string,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<UserResponse>>;

    getGroups: () => Promise<UserGroup[]>;

    getUser: (id: number) => Promise<any>;

    createUser: (user: IUser) => Promise<any>;

    updateUser: (user: IUser) => Promise<any>;

    sendActivate: (userId: number) => Promise<any>;
} 

const UserService: IUserService = {
    getUsers: async (
        name: string,
        email: string,
        userStatus: string,
        groups: string,
        pageParams: IPageableParams) => {
        const url = getURL(urls.USER, {
            ...pageParams,
            name: name,
            email: email,
            user_status: userStatus,
            groups: groups === "" ? undefined : groups,
        });
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then(response => {
                    const data: UserResponse[] = response.data.results.map(
                        (item: any) => {
                            return { ...item, id: Number(item.id) };
                        }
                    );
                    const result: PagedResponse<UserResponse> = {
                        data: data,
                        count: response.data.count,
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    getGroups: async () => {
        const url = getURL(urls.USER_GROUP, {});
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then(response => {
                    const result: UserGroup[] = response.data.results;
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    getUser: (userId: number) => {
        return new Promise((resolve, reject) => {
        axios
          .get(`${urls.USER}${userId}/`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
        });
      },
      createUser: (user: IUser) => {
        return new Promise((resolve, reject) => {
        axios
          .post(urls.USER, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });

        });
      },
      updateUser: (user: IUser) => {
        return new Promise((resolve, reject) => {
        axios
          .patch(`${urls.USER}${user.id}/`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
        });
      },

      sendActivate: (userId: number) => {
        return new Promise((resolve, reject) => {
        axios
          .post(`${urls.ACTIVATE}resend/`, {
            user: userId.toString(),
          })
          .then((response) => {
              resolve(response);          
          })
          .catch((error) => {
              reject(error);            
          });
        });
      },

};

export default UserService;
