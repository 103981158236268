import { Color } from "@material-ui/lab/Alert";
import { DeliveryRequestStatus } from "../../../../services/types/deliveryRequest";

export const mapDeliveryRequestStatusToSeverity = (
    value: DeliveryRequestStatus
): Color => {
    switch (value) {
        case DeliveryRequestStatus.FINISHED:
            return "success";
        case DeliveryRequestStatus.CANCELED:
            return "error";
        default:
            return "info";
    }
};
