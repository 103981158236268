import React, { ReactNode } from "react";
import { format } from "date-fns";
import { makeStyles, createStyles, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CURRENT_DATE_FORMAT } from "../i18n";
import { DeliveryRequestLog } from "../services/types/deliveryRequest";

interface IProps {
  children?: ReactNode;
  data: DeliveryRequestLog;
}

export const TimelineItem = ({ data }: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      className={
        data.is_courier_action ? classes.container : classes.containerRight
      }
    >
      <div className={classes.content}>
        <Typography variant="body2">
          {format(data.created_at, `${CURRENT_DATE_FORMAT} 'às' HH:mm:ss`)}
          {data.manual_action && (
            <span className={classes.manualAction}>Intervenção</span>
          )}
        </Typography>
        <Typography variant="h3">
          {t("delivery_request_event." + data.event)}
        </Typography>

        <span className={classes.circle} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "flex-start",
      position: "relative",
      marginBottom: "20px",
    },

    containerRight: {
      marginBottom: "20px",
      display: "flex",
      justifyContent: "flex-start",
      position: "relative",

      "& $content": {
        textAlign: "left",
        alignItems: "flex-start",
        left: "calc(50% + 56px)",

        "&::after": {
          right: "auto",
          left: "-7.5px",
          boxShadow: "-1px 1px 1px rgba(0, 0, 0, 0.2)",
        },
        "& $circle": {
          left: "-66px",
        },
      },
    },

    content: {
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
      borderRadius: "4px",
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "center",
      position: "relative",
      left: "calc(50% - 415px)",
      width: "360px",
      padding: "16px 32px",
      textAlign: "right",
      minHeight: "80px",

      "&::after": {
        content: "' '",
        backgroundColor: "#fff",
        boxShadow: "1px -1px 1px rgba(0, 0, 0, 0.2)",
        position: "absolute",
        right: "-7.5px",
        top: "calc(50% - 7.5px)",
        transform: "rotate(45deg)",
        width: "15px",
        height: "15px",
      },
    },
    circle: {
      background: "#F1F5F9",
      border: "5px solid #0080FF",
      borderRadius: "50%",
      position: "absolute",
      right: "-65px",
      width: "20px",
      height: "20px",
      zIndex: 20,
    },
    manualAction: {
      background: "#0080FF",
      color: "#ffffff",
      borderRadius: "4px",
      padding: "0 10px",
      marginLeft: theme.spacing(1),
    },
  })
);
