import React from "react";
import { useFormik } from "formik";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import CustomerService from "../../services/customerService";
import { CustomerResponse } from "../../services/types/customer";
import { PageableParams } from "../../components/TableTrinkets";
import { debounce } from "lodash";
import { GravityStatus, RegisterOccurrences } from "../../services/types/occurrences";
import OccurrencesService from "../../services/occurrencesService";
import { $enum } from "ts-enum-util";
import { useTranslation } from "react-i18next";
import { CURRENT_DATE_FORMAT, CURRENT_DATE_TIME_FORMAT, DEFAULT_LOCALE } from "../../i18n";
import DatePicker from "react-datepicker";
import ShowIf from "../../components/ShowIf";
import { Autocomplete } from "@material-ui/lab";
import { DeliveryOccurencesResponse, DeliveryRequest } from "../../services/types/deliveryRequest";
import DeliveryRequestService from "../../services/deliveryRequestService";
import CourierService from "../../services/courierService";
import { CourierDeliveryRequestOrder } from "../../services/types/courier";
import { IPageableParams } from "../../services/types/common";
import { format } from "date-fns";

interface AddOccurrenceDialogForm {
  id: number;
  courier: number;
  created_at: Date;
  gravity: GravityStatus;
  description: string;
  delivery_request: number | null;
  customer: number,
  all_customers: boolean,
  undefined_time: boolean,
  duration: number,
  reason: string,
  is_blockList: boolean
}

const INITIAL_FORM_VALUES: AddOccurrenceDialogForm = {
  id: 0,
  courier: 0,
  created_at: new Date(),
  gravity: GravityStatus.MODERATE,
  description: "",
  delivery_request: null,
  customer: 0,
  all_customers: false,
  undefined_time: false,
  duration: 0,
  reason: "",
  is_blockList: false
}

interface AddOccurrenceDialogProps {
  courierId: number;
  occurrenceId: number | null;
  deliveryList: DeliveryRequest[] | null;
  onAccept?: () => Promise<void>;
  onCancel: () => void;
}

export interface AddOccurrenceDialogHandle {
  open: () => void;
  close: () => void;
}

const validationSchema = Yup.object().shape({
  description: Yup.string()
    .required("Campo obrigatório"),
  gravity: Yup.string()
    .required("Campo obrigatório"),
  created_at: Yup.date()
    .required("Campo obrigatório")
});

const AddOccurrenceDialog = React.forwardRef<AddOccurrenceDialogHandle, AddOccurrenceDialogProps>((props, ref) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const [customerList, setCustomerList] = React.useState<CustomerResponse[]>([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState<CustomerResponse>();
  const [customerSearchValue, setCustomerSearchValue] = React.useState<string>("");

  const [deliveries, setDeliveries] = React.useState<DeliveryOccurencesResponse[]>([]);
  const [selectedDelivery, setSelectedDelivery] = React.useState<DeliveryOccurencesResponse>();
  const [occurrence, setOccurrence] = React.useState<RegisterOccurrences>();
  const [occurrenceId, setOccurrenceId] = React.useState<number>();
  const [checked, setChecked] = React.useState<number[]>([]);
  const [deliveriesCourrier, setDeliveriesCourrier] = React.useState<CourierDeliveryRequestOrder[]>([]);
  const [checkedSwitch, setCheckedSwitch] = React.useState<boolean>(false);
  const [deliveriesCalled, setDeliveriesCalled] = React.useState<DeliveryRequest[] | undefined>();

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => {
    return ({
      open() {
        if (props.deliveryList && props.deliveryList.length > 0) {
          setDeliveriesCalled(props.deliveryList);
          setCheckedSwitch(false);
        } else {
          setDeliveriesCalled(undefined);
        }
        if (props.occurrenceId) {
          setOccurrenceId(Number(props.occurrenceId));
          loadOccurrence(Number(props.occurrenceId));
          setCheckedSwitch(true);
        } else {
          show();
        }
      },

      close() {
        hide();
      }
    });
  });

  const hide = () => {
    setOpen(false);
    setOccurrence(undefined);
    setOccurrenceId(undefined);
    formik.resetForm();
    setSelectedCustomer(undefined);
    setCustomerSearchValue("");
    setChecked([]);
    if (props.onCancel) {
      props.onCancel();
    }
  }

  const show = () => {
    setOpen(true);
  }

  const updateCustomerList = React.useMemo(() => debounce((name: string) => {
    if (name?.trim().length > 2) {
      CustomerService.getCustomers(name, undefined, undefined, undefined, undefined, undefined, undefined, new PageableParams(1, 25, "trade_name"))
        .then((response) => {
          setCustomerList(response.data);
        });
    }
  }, 300, { trailing: true }), [setCustomerList]);

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: async (values) => {
      try {
        var mensagemSucesso = "";
        if (occurrenceId) {
          await OccurrencesService.UpdateOccurrence(
            occurrenceId,
            values.created_at,
            values.gravity,
            values.description,
            values.delivery_request ? values.delivery_request : null
          );
          mensagemSucesso = "Ocorrência atualizada com sucesso!";
        } else {
          await OccurrencesService.AddRegisterOccurrence(
            props.courierId,
            values.created_at,
            values.gravity,
            values.duration,
            values.description,
            values.delivery_request ? values.delivery_request : null,
            values.customer ? values.customer : null,
            values.description,
            values.is_blockList,
          );
          mensagemSucesso = "Ocorrência adicionada com sucesso!";
        }

        NotificationManager.success(
          mensagemSucesso,
          "Ocorrência"
        );

        hide();
        if (props.onAccept) {
          await props.onAccept();
        }

      } catch (error) {
        const { status, data } = error.response;
        if (status === 400) {
          for (var key in data) {
            var value = data[key];
            formik.setFieldError(key, value);
          }
        } else {
          NotificationManager.error(
            "Não foi possível inserir a Ocorrência!",
            "Ocorrência"
          );
        }
      }
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const loadDataDeliveries = (courierId: number) => {
    const pageParams: IPageableParams = {
      page: 1,
      page_size: 5,
      ordering: "-created_at",
    }
    CourierService.getOrderHistory(
      courierId, null, null,
      null, null,
      null, null,
      null, pageParams
    ).then(response => {
      if (response.data.length === 0) {
        setCheckedSwitch(true);
      }
      setDeliveriesCourrier(response.data);
    }).catch((error) => { setDeliveriesCourrier([]) });
  };

  React.useEffect(() => {
    if (props.courierId) {
      loadDataDeliveries(Number(props.courierId));
    }
  }, [props.courierId]);

  const loadOccurrence = async (id: number) => {
    const response = await OccurrencesService.getOccurrenceById(id);
    if (response.delivery_request) {
      const responseDeliveries = await DeliveryRequestService.getDeliveryRequestByRequestNumber(response.delivery_request.request_number);
      formik.setFieldValue("delivery_request", response.delivery_request.id);
      setSelectedDelivery(responseDeliveries.data.results.find((item: DeliveryOccurencesResponse) => { return item.id === response.delivery_request.id }));
    } else {
      formik.setFieldValue("delivery_request", null);
      setSelectedDelivery(undefined);
    }
    if (response.blocklist_record && !response.blocklist_record.all_customers) {
      const responseCustomer = await CustomerService.getCustomer(response.blocklist_record.customers[0].id);
      const customerFormat: CustomerResponse = {
        ...responseCustomer,
      }
      formik.setFieldValue("customer", customerFormat.id);
      setSelectedCustomer(customerFormat);
    }
    setOccurrence(response);
    let loadedOccurrence: AddOccurrenceDialogForm = {
      id: response.id,
      courier: response.courier.id,
      created_at: response.occurence_date,
      gravity: response.gravity,
      description: response.description,
      delivery_request: response.delivery_request ? response.delivery_request.id : null,
      customer: 0,
      all_customers: response.blocklist_record ? response.blocklist_record.all_customers : false,
      undefined_time: response.blocklist_record ? response.blocklist_record.undefined_time : false,
      duration: response.blocklist_record ? response.blocklist_record.duration : 0,
      reason: response.blocklist_record ? response.blocklist_record.reason : "",
      is_blockList: response.blocklist_record ? true : false
    };
    formik.setValues(loadedOccurrence);
  };

  React.useEffect(() => {
    if (occurrence) {
      setOpen(true);
    }
  }, [occurrence]);

  const getDeliveries = (requestNumber: string) => {
    DeliveryRequestService.getDeliveryRequestByRequestNumber(requestNumber || "").then(response => {
      setDeliveries(response.data.results);
    }).catch();
  };

  const handleToggle = (item: CourierDeliveryRequestOrder | DeliveryRequest) => {
    const currentIndex = checked.indexOf(item.id);
    const newChecked = [...checked];

    if (checked.length > 0) {
      newChecked.pop();
    }

    if (currentIndex === -1) {
      newChecked.push(item.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleChangeSwitch = (event: any) => {
    setCheckedSwitch(event.target.checked);
    setChecked([]);
    if (!occurrenceId) {
      setSelectedDelivery(undefined);
      formik.setFieldValue("delivery_request", null);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={hide}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="form-dialog-title">{occurrenceId ? "Atualizar Ocorrência" : "Adicionar Ocorrência"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DatePicker
                required={true}
                id="created_at"
                name="created_at"
                onChange={date => formik.setFieldValue("created_at", date)}
                selected={formik.values.created_at}
                maxDate={new Date()}
                locale={DEFAULT_LOCALE}
                dateFormat={CURRENT_DATE_TIME_FORMAT}
                timeCaption="Hora"
                showTimeSelect
                autoComplete="off"
                wrapperClassName="MuiFormControl-fullWidth"
                customInput={<TextField
                  label="Data e Hora"
                  variant="outlined"
                  size="small"
                  required={true}
                  InputLabelProps={{ required: true }}
                  error={!!formik.errors.created_at}
                  helperText={formik.errors.created_at}
                  fullWidth />}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required={true}
                id="description"
                label="Descrição"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.description}
                error={!!formik.errors.description}
                helperText={formik.errors.description}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="status-label">Gravidade</InputLabel>
                <Select
                  required={true}
                  id="gravity"
                  name="gravity"
                  labelId="status-label"
                  label="Gravidade"
                  value={formik.values.gravity}
                  onChange={formik.handleChange}
                >
                  {$enum(GravityStatus).map((item) => (
                    <MenuItem value={item} key={item}>
                      {t(`gravity_occurrences.${item}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!occurrenceId && !deliveriesCalled && (
              <Grid item xs={12}>
                <FormControlLabel control={<Switch checked={checkedSwitch} onChange={handleChangeSwitch} color="primary" />} label="Outra Entrega" />
              </Grid>
            )}

            {!checkedSwitch && (
              <Grid item xs={12} style={{ padding: 0, background: "whitesmoke", borderRadius: "10px" }}>
                <List style={{ width: '100%', padding: 0 }} dense={true}>
                  {deliveriesCalled && deliveriesCalled.length > 0 ? (
                    deliveriesCalled.map((item, index) => {
                      const labelId = `checkbox-list-label-${item.id}`;
                      return (
                        <ListItem
                          key={item.id}
                          disableGutters
                        >
                          <MenuItem button style={{ width: '100%' }} onClick={(value) => {
                            formik.setFieldValue("delivery_request", item.id);
                            handleToggle(item);
                          }}>
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={deliveriesCalled.length === 1 ? true : checked.indexOf(item.id) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${item.request_number} - ${format(item.created_at, `${CURRENT_DATE_FORMAT} 'às' HH:mm:ss`)}`} secondary={`#${item.order_number} - ${item.customer.store_name}`} />
                          </MenuItem>
                        </ListItem>
                      );
                    })

                  ) : (
                    deliveriesCourrier.length === 0 ? (
                      <Typography>Nenhuma entrega encontrada.</Typography>
                    ) : (
                      deliveriesCourrier.map((item, index) => {
                        const labelId = `checkbox-list-label-${item.id}`;
                        return (
                          <ListItem
                            key={item.id}
                            disableGutters
                          >
                            <MenuItem button style={{ width: '100%' }} onClick={(value) => {
                              formik.setFieldValue("delivery_request", item.id);
                              handleToggle(item);
                            }}>
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={checked.indexOf(item.id) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText id={labelId} primary={`${item.request_number} - ${format(item.created_at, `${CURRENT_DATE_FORMAT} 'às' HH:mm:ss`)}`} secondary={`#${item.order_number} - ${item.store_name}`} />
                            </MenuItem>
                          </ListItem>
                        );
                      })
                    )
                  )}
                </List>
              </Grid>
            )}

            {checkedSwitch && (
              <Grid item xs={12}>
                <Autocomplete
                  id="delivery_request"
                  size="small"
                  noOptionsText="Nenhuma opção."
                  value={selectedDelivery}
                  options={deliveries}
                  getOptionLabel={(option) => `${option.request_number} - #${option.order_number} - ${option.store ? option.store.name : "---"}`}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedDelivery(newValue);
                      formik.setFieldValue("delivery_request", newValue.id);
                    } else {
                      setSelectedDelivery(undefined);
                      formik.setFieldValue("delivery_request", null);
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      name="delivery_request"
                      helperText={formik.errors.delivery_request}
                      error={!!formik.errors.delivery_request}
                      placeholder="Informe o Número da Solicitação EX: 10000001"
                      onChange={(newValue) => {
                        var item = newValue.target.value.trim();
                        const minRequestNumber = 8;
                        if (newValue && item.length === minRequestNumber) {
                          getDeliveries(item);
                        }
                      }}
                      label="Entrega"
                      variant="outlined"
                      fullWidth />
                  }
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={occurrenceId ? true : false}
                    id="is_blockList"
                    name="is_blockList"
                    checked={formik.values.is_blockList}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Bloquear"
              />
            </Grid>
            <ShowIf condition={formik.values.is_blockList}>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={occurrenceId ? true : false}
                  options={customerList}
                  getOptionLabel={(customer: CustomerResponse) => customer.trade_name}
                  value={selectedCustomer}
                  size="small"
                  inputValue={customerSearchValue}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSelectedCustomer(newValue);
                      formik.setFieldValue("customer", newValue.id);
                    } else {
                      setSelectedCustomer(undefined);
                      formik.setFieldValue("customer", null);
                    }
                  }}
                  onInputChange={(e: object, value: string, reason: string) => {
                    setCustomerSearchValue(value);
                    if (reason === "input") {
                      updateCustomerList(value);
                    }
                  }}
                  loadingText="Aguarde ..."
                  noOptionsText="Sem opções"
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label="Cliente - Deixe em branco para 'Todos'"
                      helperText={formik.errors.customer || "Digite pelo menos 3 caracteres para realizar a busca"}
                      error={!!formik.errors.customer}
                      variant="outlined"
                      fullWidth
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={occurrenceId ? true : false}
                  id="duration"
                  label="Duração - 0 (zero) para tempo indeterminado"
                  variant="outlined"
                  type="number"
                  value={formik.values.duration}
                  onChange={formik.handleChange}
                  helperText={formik.errors.duration}
                  error={!!formik.errors.duration}
                  fullWidth
                  required={formik.values.is_blockList ? true : false}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">minutos</InputAdornment>,
                  }}
                />
              </Grid>
            </ShowIf>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide}>
            Cancelar
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={formik.isSubmitting}
            startIcon={formik.isSubmitting && (
              <CircularProgress size="1.5rem" color="inherit" />
            )}
          >
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

export default AddOccurrenceDialog;
