import { apiAxios as axios } from "../store/api/config";
import { urls } from "../store/api/urls";
import { convertToDate, convertToNumber } from "../utils/utils";
import { DeliverySettingsResponse } from "./types/deliveryRequest";
import { TaxPaymentResponse } from "./types/financialEntry";
import { ReferralSettingsResponse } from "./types/referral";

export interface ISettingsService {
    getTaxPayment: () => Promise<TaxPaymentResponse>;
    updateTaxPayment: (values: TaxPaymentResponse) => Promise<TaxPaymentResponse>;

    getReferralSettings: () => Promise<ReferralSettingsResponse>;
    updateReferralSettings: (values: ReferralSettingsResponse) => Promise<ReferralSettingsResponse>;

    getDeliverySettings: () => Promise<DeliverySettingsResponse>;
    updateDeliverySettings: (values: DeliverySettingsResponse) => Promise<DeliverySettingsResponse>;

}

const SettingsService: ISettingsService = {
    getTaxPayment: async () => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${urls.FINANCIAL_SETTINGS}get_settings/`)
                .then(response => {
                    const data = response.data;
                    const result: TaxPaymentResponse = {
                        ...data,
                        credit_card_percentage_tax: convertToNumber(data.credit_card_percentage_tax),
                        bank_slip_tax: convertToNumber(data.bank_slip_tax),
                        pix_tax: convertToNumber(data.pix_tax)
                    }
                    resolve(result)
                })
                .catch(error => reject(error));
        });
    },

    updateTaxPayment: async (values: TaxPaymentResponse) => {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${urls.FINANCIAL_SETTINGS}update_settings/`, {
                    bank_slip_tax: convertToNumber(values.bank_slip_tax),
                    credit_card_percentage_tax: convertToNumber(values.credit_card_percentage_tax),
                    pix_tax: convertToNumber(values.pix_tax)
                })
                .then(response => {
                    const data = response.data;
                    const result: TaxPaymentResponse = {
                        ...data,
                        credit_card_percentage_tax: convertToNumber(data.credit_card_percentage_tax),
                        bank_slip_tax: convertToNumber(data.bank_slip_tax),
                        pix_tax: convertToNumber(data.pix_tax),
                    }
                    resolve(result)
                })
                .catch(error => reject(error));
        });
    },

    getReferralSettings: async () => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${urls.REFERRAL_SETTINGS}get_settings/`)
                .then(response => {
                    const data = response.data;
                    const result: ReferralSettingsResponse = {
                        ...data,
                        quantity_of_deliveries: convertToNumber(data.quantity_of_deliveries),
                        reward_amount: convertToNumber(data.reward_amount),
                        updated_at: convertToDate(data.updated_at)
                    }
                    resolve(result)
                })
                .catch(error => reject(error));
        });
    },

    updateReferralSettings: async (values: ReferralSettingsResponse) => {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${urls.REFERRAL_SETTINGS}update_settings/`, {
                    quantity_of_deliveries: convertToNumber(values.quantity_of_deliveries),
                    reward_amount: convertToNumber(values.reward_amount)
                })
                .then(response => {
                    const data = response.data;
                    const result: ReferralSettingsResponse = {
                        ...data,
                        quantity_of_deliveries: convertToNumber(data.quantity_of_deliveries),
                        reward_amount: convertToNumber(data.reward_amount)
                    }
                    resolve(result)
                })
                .catch(error => reject(error));
        });
    },

    getDeliverySettings: async () => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${urls.DELIVERY_SETTINGS}get_settings/`)
                .then(response => {
                    const data = response.data;
                    console.log(data);
                    const result: DeliverySettingsResponse = {
                        ...data,
                        acceptation_seconds: convertToNumber(data.acceptation_seconds),
                        advanced_speeder_points: convertToNumber(data.advanced_speeder_points),
                        queue_retries: convertToNumber(data.queue_retries),
                        extra_acceptation_time: convertToNumber(data.extra_acceptation_time),
                        minutes_to_discard: convertToNumber(data.minutes_to_discard),
                        retry_notification_seconds: convertToNumber(data.retry_notification_seconds),
                        updated_at: convertToDate(data.updated_at)
                    }
                    resolve(result)
                })
                .catch(error => reject(error));
        });
    },

    updateDeliverySettings: async (values: DeliverySettingsResponse) => {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${urls.DELIVERY_SETTINGS}update_settings/`, values)
                .then(response => {
                    const data = response.data;
                    const result: DeliverySettingsResponse = {
                        ...data,
                    }
                    resolve(result)
                })
                .catch(error => reject(error));
        });
    },

};

export default SettingsService;
