import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";

import { DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { INITIAL_FILTER_PARAMS, IRankingCouriersParams, RankingCouriersFilter } from "./RankingCouriersFilter";
import RegionService from "../../services/regionService";
import CourierService from "../../services/courierService";
import { RankingCouriersResponse } from "../../services/types/rankingCouriers";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { decodeURLParams } from "../../utils/utils";

const TABLE_LOCALIZATION = {
  ...TABLE_L10N_PTBR,
  body: {
    ...TABLE_L10N_PTBR.body,
    emptyDataSourceMessage: "Nenhum registro encontrado"
  }
}

export const RankingCourier = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [regionList, setRegionList] = useState<any[]>([]);
  const [filterParams, setFilterParams] = useState<IRankingCouriersParams>({
    ...INITIAL_FILTER_PARAMS,
    ...decodeURLParams(history.location.search)
  });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  
  const tableRef = React.useRef<MaterialTableProps<RankingCouriersResponse>>();

  const loadData = (query: Query<RankingCouriersResponse>) => {
    if (filterParams.regions.length === 0) {
      TABLE_LOCALIZATION.body.emptyDataSourceMessage = "Selecione as Regiões";
      return new Promise<QueryResult<RankingCouriersResponse>>((resolve, reject) => {
        setCurrentPage(query.page);
        setPageSize(query.pageSize);
        resolve({
          data: [],
          page: query.page,
          totalCount: 0,
        })
      })
    } else {
      TABLE_LOCALIZATION.body.emptyDataSourceMessage = "Nenhum registro encontrado";
      return new Promise<QueryResult<RankingCouriersResponse>>((resolve, reject) => {
        CourierService.getCouriersByRegionOrderRanking(filterParams.regions, filterParams.speedy_ranking, PageableParams.fromQuery(query))
          .then(response => {
            setCurrentPage(query.page);
            setPageSize(query.pageSize);
            resolve({
              data: response.data,
              page: query.page,
              totalCount: response.count,
            })
          })
      });
    }


  }

  const applyFilter = (params: IRankingCouriersParams) => {
    setFilterParams(params);
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({ page: 0 } as Query<RankingCouriersResponse>);
    }
  }

  useEffect(() => {
    const getRegions = async () => {
      const response = await RegionService.loadAllRegions({
        ordering: "name",
      });
      setRegionList(response);
    };
    getRegions();
  }, []);

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Ranking de Entregadores</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[{ label: "Ranking de Entregadores", url: "/rankingCouriers" }]}
            />
          </Grid>
        </Grid>
      </Grid>

      <RankingCouriersFilter params={filterParams} regionList={regionList} onFilterChanged={applyFilter} />

      <MaterialTable
        tableRef={tableRef}
        title=""
        columns={[
          {
            field: "tableData.position", title: "#", align: "left", width: 20,
            render: (rowData, type) => {
              (rowData as any).tableData.position = ((rowData as any).tableData.id + 1) + (currentPage * pageSize);
              return (rowData as any).tableData.position;
            }
          },
          {
            field: "name", title: "Nome"
          },
          {
            field: "region.name", title: "Região"
          },
          {
            field: "vehicle_type", title: "Tipo do Veículo",
            render: (rowData, type) => rowData?.vehicle_type ? t(`vehicle_type.${rowData?.vehicle_type}`) : "---",
          },
          {
            field: "new_ranking_points", title: "Pontuação"
          },
          {
            field: "speedy_ranking", title: "Speedy Ranking",
            render: (rowData, type) => rowData?.speedy_ranking ? t(`courier_type.${rowData?.speedy_ranking}`) : "---",
          },

        ]}
        data={loadData}
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_LOCALIZATION}
        options={{
          ...DEFAULT_TABLE_OPTIONS,
          sorting: false,
          pageSize: pageSize
        }}
      />

    </div>
  );
};