import {
  Card,
  CardContent,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { IPageableParams } from "../../store/ducks/requests";
import theme from "../../styles/theme";
import { endOfToday, endOfWeek, startOfToday, startOfWeek } from "date-fns";
import RegionService from "../../services/regionService";
import DeliveryRequestService from "../../services/deliveryRequestService";
import { OnlineCourierUserDetails } from "../../services/types/courier";
import { DeliveryRequestStatus, PendingDeliveryRequest, PendingTrip, StatsDashboard, TripStatus } from "../../services/types/deliveryRequest";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import MapIcon from '@material-ui/icons/Map';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ShowIf from "../../components/ShowIf";
import CourierService from "../../services/courierService";
import { RegionResponse } from "../../services/types/region";
import { DashboardMapView } from "./views/dashboardMapView";
import { DashboardDeliveriesView } from "./views/dashboardDeliveriesView";
import { DashboardDeliveriesFilter, DashboardDeliveriesFilterParams, DELIVERIES_INITIAL_FILTER_PARAMS } from "./views/dashboardDeliveriesFilter";
import { DashboardMapFilter, DashboardMapFilterParams, MAP_INITIAL_FILTER_PARAMS } from "./views/dashboardMapFilter";
import { DashboardCourierScheduleFilter, DashboardCourierScheduleFilterParams, COURIER_SCHEDULE_INITIAL_FILTER_PARAMS } from "./views/dashboardCourierScheduleFilter";
import { StoreResponse } from "../../services/types/customer";
import CustomerService from "../../services/customerService";
import { DashboardStatisticsView } from "./views/dashboardStatisticsView";
import { DashboardCourierScheduleView } from "./views/dashboardCourierScheduleView";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import TripService from "../../services/tripService";
import { CourierScheduleContextProvider } from "./contexts/CourierScheduleContext";
import useCourierSchedule from "./hooks/useCourierSchedule";
import { decodeURLParams, encodeURLParams, URLParamType } from "../../utils/utils";

const REFRESH_INTERVAL = 15000;

const InternalMainDashboard: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const [view, setView] = useState<string>("deliveries");

  const [deliveriesSearchValue, setDeliveriesSearchValue] = useState<string>("");
  const [mapSearchValue, setMapSearchValue] = useState<string>("");
  const [courierScheduleSearchValue, setCourierScheduleSearchValue] = useState<string>("");

  const { loadAvailableSlotsRequests } = useCourierSchedule();

  const [mapRegion, setMapRegion] = useState<RegionResponse>();
  const [deliveriesFilterParams, setDeliveriesFilterParams] = useState<DashboardDeliveriesFilterParams>(DELIVERIES_INITIAL_FILTER_PARAMS);
  const [mapFilterParams, setMapFilterParams] = useState<DashboardMapFilterParams>(MAP_INITIAL_FILTER_PARAMS);
  const [courierScheduleFilterParams, setCourierScheduleFilterParams] = useState<DashboardCourierScheduleFilterParams>(COURIER_SCHEDULE_INITIAL_FILTER_PARAMS);
  const [courierScheduleDateRange, setCourierScheduleDateRange] = useState<[Date, Date]>([startOfWeek(new Date()), endOfWeek(new Date())]);
  const [statsDashboard, setStatsDashboard] = useState<StatsDashboard[]>([]);
  const [regionsIds, setRegionsIds] = useState<number[]>([]);
  const [regionList, setRegionList] = useState<RegionResponse[]>([]);
  const [courierList, setCourierList] = useState<OnlineCourierUserDetails[]>([]);
  const [mapStoreList, setMapStoreList] = useState<StoreResponse[]>([]);
  const [deliveryRequestList, setDeliveryRequestList] = useState<PendingDeliveryRequest[]>([]);
  const [tripList, setTripList] = useState<PendingTrip[]>([]);
  const [retrieveNewDeliveries, setRetrieveNewDeliveries] = React.useState<boolean>(true);

  useLayoutEffect(() => {
    RegionService
      .loadAllRegions({})
      .then((response) => {
        setRegionList(response);
        const regions: number[] = response.map((item) => item.id);
        setRegionsIds(regions);
      })
      .catch((error) => { });

      const result: any = decodeURLParams(history.location.search, [{"menu": URLParamType.STRING}]);
      if(result && result.menu){
        setView(result.menu);
      }
      
  }, [history.location.search]);

  useLayoutEffect(() => {
      const result: any = decodeURLParams(history.location.search, [{"menu": URLParamType.STRING}]);
      if(result && result.menu){
        setView(result.menu);
      }      
  }, [history.location.search]);

  useLayoutEffect(() => {
    if (regionList.length && mapFilterParams.regionId) {
      const regionFound = regionList.find((region) => region.id === mapFilterParams.regionId);
      setMapRegion(regionFound);
    }
  }, [regionList, mapFilterParams]);

  const refreshPendingDeliveryRequests = (
    regionsIds: number[],
    storesIds: number[],
    retrieveNew: boolean | null,
    pageParams: IPageableParams
  ) => {
    DeliveryRequestService.getPendingDeliveryRequests(
      regionsIds,
      storesIds,
      [DeliveryRequestStatus.NEW, DeliveryRequestStatus.NO_COURIER],
      retrieveNew,
      pageParams
    )
      .then((response) => {
        setDeliveryRequestList(response.data);
      })
      .catch((error) => { });
  };

  const refreshPendingTripRequests = (
    regionsIds: number[],
    storesIds: number[],
    statusTrip: TripStatus | null,
    pageParams: IPageableParams
  ) => {
    TripService.getTrips(
      regionsIds,
      storesIds,
      statusTrip,
      pageParams
    )
      .then((response) => {
        setTripList(response.data);
      })
      .catch((error) => { });
  };

  const refreshDeliveryRequestsStats = (regionsId: number[]) => {
    if (regionsId.length > 0) {
      DeliveryRequestService.getDeliveryRequestsStats(regionsId, startOfToday(), endOfToday())
        .then((response) => {
          setStatsDashboard(response);
        })
        .catch((error) => { });
    }
  };

  const refreshCouriers = useCallback((regionId: number) => {
    CourierService.getOnlineCouriersInRegion(regionId)
      .then((couriers) => {
        setCourierList(couriers);
      })
      .catch((error) => { });
  }, []);

  const refreshStores = useCallback((regionId: number) => {
    const DEFAULT_PAGINATION: IPageableParams = {
      page: 1,
      page_size: 200,
      ordering: null
    };
    CustomerService
      .getStores(undefined, undefined, [regionId], true, DEFAULT_PAGINATION)
      .then((response) => {
        setMapStoreList(response.data);
      })
      .catch((error) => { });
  }, []);

  const refreshAvailableSlotsRequests = useCallback(() => {
    if (courierScheduleFilterParams && courierScheduleFilterParams.regionsIds) {
      loadAvailableSlotsRequests(
        courierScheduleFilterParams.regionsIds,
        courierScheduleDateRange[0],
        courierScheduleDateRange[1]
      );
    }
  }, [courierScheduleFilterParams, courierScheduleDateRange, loadAvailableSlotsRequests]);

  const showDeliveryRequestDetails = async (deliveryRequest: PendingDeliveryRequest | number) => {
    let param =
      typeof deliveryRequest == "number" ? deliveryRequest : deliveryRequest.id;
    history.push(`/requests/detail/${param}`);
  };

  const refreshDeliveries = useCallback(
    (params: DashboardDeliveriesFilterParams, retrieveNew: boolean) => {
      const DEFAULT_PAGINATION: IPageableParams = {
        page: 1,
        page_size: 200,
        ordering: "estimated_delivery_time"
      };
      if (params.regionsIds?.length > 0) {
        refreshPendingDeliveryRequests(params.regionsIds, params.storesIds, retrieveNew, DEFAULT_PAGINATION);
      } else {
        setDeliveryRequestList([]);
      }
    }, []);

  const refreshTrips = useCallback(
    (params: DashboardDeliveriesFilterParams) => {
      if (params.regionsIds?.length > 0) {
        const TRIP_DEFAULT_PAGINATION: IPageableParams = {
          page: 1,
          page_size: 200,
          ordering: "created_at",
        };
        refreshPendingTripRequests(params.regionsIds, params.storesIds, null, TRIP_DEFAULT_PAGINATION);
      } else {
        setTripList([]);
      }
    }, []);

  const refreshStats = useCallback(() => {
    if (regionsIds.length > 0) {
      DeliveryRequestService.getDeliveryRequestsStats(regionsIds, startOfToday(), endOfToday())
        .then((response) => {
          setStatsDashboard(response);
        })
        .catch((error) => { });
    }
  }, [regionsIds]);


  useEffect(() => {
    refreshDeliveries(deliveriesFilterParams, retrieveNewDeliveries);
    refreshTrips(deliveriesFilterParams);

    const interval = setInterval(() => {
      refreshDeliveries(deliveriesFilterParams, retrieveNewDeliveries);
      refreshTrips(deliveriesFilterParams);
    }, REFRESH_INTERVAL);

    return function cleanup() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [deliveriesFilterParams, refreshDeliveries, retrieveNewDeliveries, refreshTrips]);

  const loadData = () => {
    refreshDeliveries(deliveriesFilterParams, retrieveNewDeliveries);  
    refreshTrips(deliveriesFilterParams);
  };

  useEffect(() => {
    if (mapFilterParams.regionId) {
      refreshCouriers(mapFilterParams.regionId);
      refreshStores(mapFilterParams.regionId);
    }

    const interval = setInterval(() => {
      if (mapFilterParams.regionId) {
        refreshCouriers(mapFilterParams.regionId);
      }
    }, REFRESH_INTERVAL);

    return function cleanup() {
      setCourierList([]);
      setMapStoreList([]);
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [mapFilterParams, refreshCouriers, refreshStores]);

  useEffect(() => {
    refreshAvailableSlotsRequests();

    const interval = setInterval(() => {
      refreshAvailableSlotsRequests();
    }, REFRESH_INTERVAL);

    return function cleanup() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [refreshAvailableSlotsRequests]);

  useEffect(() => {
    refreshStats();

    const interval = setInterval(() => {
        refreshStats();  
    }, REFRESH_INTERVAL);

    return function cleanup() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [refreshStats]);

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    history.replace(encodeURLParams(history.location.pathname, {menu: value}));
    if (value) {
      setView(value);
    }
  };

  const handleDeliveriesSearchValueChange = (value: string) => {
    setDeliveriesSearchValue(value);
  };

  const handleDeliveriesFilterChange = (params: DashboardDeliveriesFilterParams) => {
    setDeliveriesFilterParams(params);
    localStorage.setItem("dashboardDeliveries:filter", JSON.stringify(params));
  }

  const handleMapSearchValueChange = (value: string) => {
    console.info("handleMapSearchValueChange", value);
    setMapSearchValue(value);
  };

  const handleMapFilterChange = (params: DashboardMapFilterParams) => {
    setMapFilterParams(params);
    localStorage.setItem("dashboardMap:filter", JSON.stringify(params));
  }

  const handleCourierScheduleSearchValueChange = (value: string) => {
    console.info("handleCourierScheduleSearchValueChange", value);
    setCourierScheduleSearchValue(value);
  };

  const handleCourierScheduleFilterChange = (params: DashboardCourierScheduleFilterParams) => {
    setCourierScheduleFilterParams(params);
    if (params && params.regionsIds) {
      loadAvailableSlotsRequests(
        params.regionsIds,
        courierScheduleDateRange[0],
        courierScheduleDateRange[1]
      );
    }

    localStorage.setItem("dashboardCourierSchedule:filter", JSON.stringify(params));
  }

  useEffect(() => {
    const storedFilter = localStorage.getItem("dashboardDeliveries:filter");
    if (storedFilter) {
      const params: DashboardDeliveriesFilterParams = JSON.parse(storedFilter);
      if (params.regionsIds) {
        if (!params.storesIds) {
          params.storesIds = [];
        }
        setDeliveriesFilterParams(params);
      }
    }
  }, []);

  useEffect(() => {
    const storedFilter = localStorage.getItem("dashboardMap:filter");
    if (storedFilter) {
      setMapFilterParams(JSON.parse(storedFilter));
    }
  }, []);

  useEffect(() => {
    const storedFilter = localStorage.getItem("dashboardCourierSchedule:filter");
    if (storedFilter) {
      setCourierScheduleFilterParams(JSON.parse(storedFilter));
    }
  }, []);

  const handleRetrieveNewDeliveriesChange = (isRetrieveNew: boolean) => {
    setRetrieveNewDeliveries(isRetrieveNew);
  }

  const handleOnCourierScheduleDateRangeChange = (newDateRange: [Date, Date]) => {
    setCourierScheduleDateRange(newDateRange);
    if (courierScheduleFilterParams && courierScheduleFilterParams.regionsIds) {
      loadAvailableSlotsRequests(
        courierScheduleFilterParams.regionsIds,
        newDateRange[0],
        newDateRange[1]
      );
    }
  }

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        className={classes.page}
      >
        <Grid item xs={12}>
          <Card>
            <CardContent style={{ paddingBottom: theme.spacing(1) }}>
              <Grid container spacing={2}>
                <Grid item>
                  <ToggleButtonGroup
                    exclusive
                    value={view}
                    onChange={handleViewChange}
                    size="small"
                  >
                    <ToggleButton value="deliveries">
                      <ViewColumnIcon />
                    </ToggleButton>
                    <ToggleButton value="map">
                      <MapIcon />
                    </ToggleButton>
                    <ToggleButton value="schedule">
                      <EventNoteIcon />
                    </ToggleButton>
                    <ToggleButton value="statistics">
                      <EqualizerIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item md className={classes.styleBox}>
                  <ShowIf condition={view === "deliveries"}>
                    <DashboardDeliveriesFilter
                      regionList={regionList}
                      params={deliveriesFilterParams}
                      onSearchValueChanged={handleDeliveriesSearchValueChange}
                      onFilterChanged={handleDeliveriesFilterChange}
                    />
                  </ShowIf>
                  <ShowIf condition={view === "map"}>
                    <DashboardMapFilter
                      regionList={regionList}
                      params={mapFilterParams}
                      onSearchValueChanged={handleMapSearchValueChange}
                      onFilterChanged={handleMapFilterChange}
                    />
                  </ShowIf>
                  <ShowIf condition={view === "schedule"}>
                    <DashboardCourierScheduleFilter
                      regionList={regionList}
                      params={courierScheduleFilterParams}
                      onSearchValueChanged={handleCourierScheduleSearchValueChange}
                      onFilterChanged={handleCourierScheduleFilterChange}
                    />
                  </ShowIf>
                  <ShowIf condition={view === "statistics"}>
                    {/* <DashboardStatsFilter
                      regionList={regionList}
                      params={statsFilterParams}
                      onFilterChanged={handleStatsFilterChange}
                    /> */}
                  </ShowIf>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <ShowIf condition={view === "deliveries"}>
          <DashboardDeliveriesView
            deliveryRequestList={deliveryRequestList}
            tripList={tripList}
            searchValue={deliveriesSearchValue}
            deliveryRequestDetailsAction={showDeliveryRequestDetails}
            onRetrievesNewChange={handleRetrieveNewDeliveriesChange}
            loadData={loadData}
          />
        </ShowIf>
        <ShowIf condition={view === "map"}>
          <DashboardMapView
            courierList={courierList}
            storeList={mapStoreList}
            region={mapRegion}
            searchValue={mapSearchValue}
            deliveryRequestDetailsAction={showDeliveryRequestDetails}
          />
        </ShowIf>
        <ShowIf condition={view === "schedule"}>
          <DashboardCourierScheduleView
            searchValue={courierScheduleSearchValue}
            regionsIds={courierScheduleFilterParams.regionsIds}
            onDateRangeChange={handleOnCourierScheduleDateRangeChange}
          />
        </ShowIf>
        <ShowIf condition={view === "statistics"}>
          <DashboardStatisticsView
            statsDashboard={statsDashboard}
          />
        </ShowIf>
      </Grid>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  page: {
    flex: 1,
    display: "flex",
    height: "100vh",
  },
  stats: {
    display: "inline-block",
  },
  statsAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  legend: {
    display: "flex",
    alignItems: "center",
  },
  successDeliveries: {
    backgroundColor: green[500]
  },
  canceledDeliveries: {
    backgroundColor: "red"
  },
  positionButtonFilter: {
    position: "absolute",
    right: "16px",
    marginRight: "16px",
    top: "30px",
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  styleBox: {
    /*display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
      border: "1px solid #b3b3b3ad",
     padding: "5px 5px",
     borderRadius: "5px",
     background: "#f0f0f06b", */
  },
  fullSelect: {
    width: "100%",
  }

});

export const MainDashboard: React.FC = () => {
  return (
    <CourierScheduleContextProvider>
      <InternalMainDashboard />
    </CourierScheduleContextProvider>
  );
}