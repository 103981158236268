import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import Search from '@material-ui/icons/Search';
import { IconButton, InputAdornment, InputBaseProps } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Clear } from '@material-ui/icons';
import ShowIf from './ShowIf';

const useSearchInputStyles = makeStyles((theme) => ({
    root: {
        padding: `${theme.spacing(1) / 4}px 0`,
        borderRadius: 4,
        '&:hover, &$focused': {
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
        },
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "rgba(0, 0, 0, 0.23)",
    },
    focused: {},
    input: {
        color: "rgba(0, 0, 0, 0.87);",
        '&::placeholder': {
            color: "#526272",
            padding: "0",
            fontSize: "14px",
            fontFamily: "Nunito Sans, sans-serif",
            fontWeight: "400",
            lineHeight: "1",
        }
    },
    adornedStart: {
        color: "rgba(0, 0, 0, 0.54)",
        '& > *:first-child': {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
    },
}));

const SearchInput = (props: InputBaseProps) => {
    const inputBaseRef = React.createRef<InputBaseProps>();
    const inputRef = React.createRef<HTMLInputElement>();
    const styles = useSearchInputStyles();
    return (
        <InputBase
            classes={styles}
            placeholder={'Search...'}
            inputRef={inputRef}
            ref={inputBaseRef}
            startAdornment={
                <InputAdornment position="start">
                    <Search />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    <ShowIf condition={props.value !== ""} type="visibilityHidden">
                        <IconButton style={{ color: "inherit" }}
                            onClick={(e) => {
                                e.preventDefault();
                                const propertyDescriptor = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value");
                                if (propertyDescriptor) {
                                    const nativeInputValueSetter = propertyDescriptor.set;
                                    if (nativeInputValueSetter) {
                                        nativeInputValueSetter.call(inputRef.current, '');
                                        const event = new Event('input', { bubbles: true });
                                        inputRef.current?.dispatchEvent(event);
                                    }
                                }
                            }}>
                            <Clear />
                        </IconButton>
                    </ShowIf>
                </InputAdornment>
            }
            {...props}
        />
    );
};

export default SearchInput;