import React from "react";
import { Card, CardActionArea, CardContent, Grid, makeStyles, Typography, } from "@material-ui/core";
import theme from "../../../styles/theme";
import clsx from "clsx";
import { SlotResponse } from "../../../services/types/dedicated";
import ScheduleIcon from '@material-ui/icons/Schedule';
import StoreIcon from '@material-ui/icons/Store';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { format } from "date-fns";
import numbro from "numbro";
import PopoverRegionContact from "../customComponents/popoverRegionContact";

interface SlotCardProps {
  slot: SlotResponse;
  onClick?: (slot: SlotResponse, event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SlotCard: React.FC<SlotCardProps> = ({ slot, onClick }) => {
  const classes = useStyles();
  const [anchorElPopover, setAnchorElPopover] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorElPopover);


  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElPopover(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(slot, event);
    }
  }

  return (
    <React.Fragment>
      <Card variant="outlined" className={clsx(classes.card, { [classes.selectedCard]: false }, {
        [classes.cardGreen]: ((slot.is_done === true)),
        [classes.cardRed]: ((slot.is_done === false)),
        [classes.cardOrange]: ((slot.courier && slot.is_done === null)),
        [classes.cardGray]: ((!slot.courier && slot.is_done === null)),
      })}>
        <CardActionArea aria-controls="actions-menu" aria-haspopup="true" onClick={handleClick}>
          <CardContent className={classes.content}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" noWrap
                  title={`${format(slot.start_datetime, "HH:mm")} - ${format(slot.end_datetime, "HH:mm")}`}>
                  <ScheduleIcon
                    className={classes.styleStarRanking}
                    fontSize="small"
                  />
                  {`${format(slot.start_datetime, "HH:mm")} - ${format(slot.end_datetime, "HH:mm")}`}
                </Typography>
              </Grid>
              <Grid item xs={12} zeroMinWidth aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}>
                <Typography variant="body2" noWrap>
                  <StoreIcon
                    className={classes.styleStarRanking}
                    fontSize="small"
                  />
                  {slot.store_name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" noWrap title={numbro(slot.value).formatCurrency() +" - "+ numbro(slot.current_earnings ? slot.current_earnings : "0.0").formatCurrency()}>
                  <MonetizationOnIcon
                    className={classes.styleStarRanking}
                    fontSize="small"
                  />
                  {numbro(slot.value).formatCurrency() + " - "}                 
                  <span className={classes.styleValueEarned}>{numbro(slot.current_earnings ? slot.current_earnings : "0.0").formatCurrency()}</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {(slot.courier !== null) ? (
                  <Typography variant="body2" noWrap title={slot.courier.name}>
                    <AssignmentTurnedInIcon
                      className={classes.styleStarRanking}
                      fontSize="small"
                    />
                    {slot.courier.name}
                  </Typography>
                ) : (
                  <Typography variant="body2" noWrap title="Sem Entregador">
                    <AssignmentLateIcon
                      className={classes.styleStarRanking}
                      fontSize="small"
                    />
                    Sem Entregador
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>

      <PopoverRegionContact openPopover={open} anchorEl={anchorElPopover} slot={slot} handlePopoverClose={handlePopoverClose} />
    </React.Fragment>
  );
}

const useStyles = makeStyles({
  content: {
    padding: theme.spacing(1) / 2,
  },
  statusChip: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: "20px !important",
    fontSize: "0.8rem !important",
  },
  card: {
    position: "relative",
    zIndex: 1,
    marginBottom: "5px"
  },
  selectedCard: {
    borderColor: theme.palette.warning.main,
    borderWidth: "4px",
    margin: "-3px",
    zIndex: 2
  },
  paper: {
    padding: theme.spacing(2),
  },
  popover: {
    pointerEvents: 'none',
  },
  cardTitle: {
    fontSize: "24px",
    marginBottom: "8px",
    fontWeight: "bold",
  },
  styleStarRanking: {
    position: "relative",
    top: "3px",
    marginRight: "4px",
  },
  advancedSpeederStar: {
    color: theme.palette.primary.main
  },
  cardGreen: {
    borderTop: "5px solid #4caf50",
    backgroundColor: "#ddfff6",
  },
  cardRed: {
    borderTop: "5px solid #f44336",
  },
  cardGray: {
    borderTop: "5px solid #9e9e9e",
  },
  cardOrange: {
    borderTop: "5px solid #ffc107",
  },
  styleValueEarned: {
    color: "#32bb37",
    fontWeight: "bolder",
  }
});