import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { PhoneInput } from "../../components/PhoneInput";
import { useTranslation } from "react-i18next";
import { FormikHelpers, useFormik } from "formik";
import { Autocomplete } from "@material-ui/lab";
import { CourierAccountStatus, VehicleType } from "../../services/types/courier";
import { DEFAULT_LOCALE, CURRENT_DATE_TIME_FORMAT } from "../../i18n";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router";
import { encodeURLParams } from "../../utils/utils";

export interface ICourierFilterParams {
  name: string;
  email: string;
  phonenumber: string;
  courierAccountStatus: CourierAccountStatus | "";
  vehicleLicensePlate: string;
  vehicleType: VehicleType | "";
  region: string;
  invalidBankData: boolean;
  createdAtAfter: Date | null;
  createdAtBefore: Date | null;
  district: string;
}

export const INITIAL_FILTER_PARAMS: ICourierFilterParams = {
  name: "",
  email: "",
  phonenumber: "",
  courierAccountStatus: "",
  vehicleLicensePlate: "",
  vehicleType: "",
  region: "",
  invalidBankData: false,
  createdAtAfter: null,
  createdAtBefore: null,
  district: ""
};

interface IFilterCourierProps {
  params?: ICourierFilterParams;
  regionList: any[];
  onFilterChanged: (params: ICourierFilterParams) => void;
}

export const CourierFilter: React.FC<IFilterCourierProps> = ({
  params = INITIAL_FILTER_PARAMS,
  regionList,
  onFilterChanged,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const vehicle_types = ["BIKE", "MOTORCYCLE", "CAR", "VAN"];
  const [selectedRegion, setSelectedRegion] = React.useState<any>(null);

  React.useEffect(() => {
    history.replace(encodeURLParams(history.location.pathname, params));
  }, [history, params]);

  const formik = useFormik<ICourierFilterParams>({
    initialValues: params,
    onSubmit: (values: ICourierFilterParams, formikHelpers: FormikHelpers<ICourierFilterParams>) => {
      onFilterChanged(values);
    },
    onReset: (values: ICourierFilterParams, formikHelpers: FormikHelpers<ICourierFilterParams>) => {
      onFilterChanged(INITIAL_FILTER_PARAMS);
      formikHelpers.setValues(INITIAL_FILTER_PARAMS);
    },
  });

  return (
    <div className="page-filter">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <TextField
              id="name"
              label="Nome"
              onChange={formik.handleChange}
              value={formik.values.name}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              id="phonenumber"
              label="Celular"
              variant="outlined"
              value={formik.values.phonenumber}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, "");
                formik.handleChange(e);
              }}
              fullWidth
              InputProps={{
                inputComponent: PhoneInput,
              }}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              id="email"
              label="E-mail"
              onChange={formik.handleChange}
              value={formik.values.email}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              id="vehicleType"
              name="vehicleType"
              label="Tipo do Veículo"
              select
              onChange={formik.handleChange}
              value={formik.values.vehicleType}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="">Selecione</MenuItem>
              {vehicle_types.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {t("vehicle_type." + item)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={2}>
            <TextField
              id="vehicleLicensePlate"
              label="Placa do Veículo"
              onChange={formik.handleChange}
              value={formik.values.vehicleLicensePlate}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={2}>
            <DatePicker
              id="createdAtAfter"
              name="createdAtAfter"
              onChange={(val, event: React.SyntheticEvent) => {
                formik.setFieldValue("createdAtAfter", val);
                event.preventDefault();
              }}
              maxDate={new Date()}
              selected={formik.values.createdAtAfter}
              startDate={formik.values.createdAtAfter}
              endDate={formik.values.createdAtBefore}
              selectsStart
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_TIME_FORMAT}
              timeCaption="Hora"
              isClearable
              showTimeSelect
              autoComplete="off"
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data Início do Cadastro"
                variant="outlined"
                size="small"
                error={!!formik.errors.createdAtAfter}
                helperText={formik.errors.createdAtAfter}
                fullWidth />}
            />
          </Grid>
          <Grid item md={2}>
            <DatePicker
              id="createdAtBefore"
              name="createdAtBefore"
              onChange={val => {
                formik.setFieldValue("createdAtBefore", val);
              }}
              maxDate={new Date()}
              selected={formik.values.createdAtBefore}
              startDate={formik.values.createdAtAfter}
              endDate={formik.values.createdAtBefore}
              minDate={formik.values.createdAtAfter}
              selectsEnd
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_TIME_FORMAT}
              timeCaption="Hora"
              isClearable
              showTimeSelect
              autoComplete="off"
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data Fim do Cadastro"
                variant="outlined"
                size="small"
                error={!!formik.errors.createdAtBefore}
                helperText={formik.errors.createdAtBefore}
                fullWidth />}
            />
          </Grid>
          <Grid item md={2}>
            <Autocomplete
              id="region"
              size="small"
              value={selectedRegion}
              options={regionList}
              getOptionLabel={(option) => `${option.name} - ${option.state}`}
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedRegion(newValue);
                  formik.setFieldValue("region", newValue.id);
                } else {
                  setSelectedRegion(null);
                  formik.setFieldValue("region", null);
                }
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  name="region"
                  label="Região"
                  onChange={formik.handleChange}
                  value={formik.values.region}
                  variant="outlined"
                  fullWidth
                />
              }
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              id="district"
              label="Bairro do Entregador"
              onChange={formik.handleChange}
              value={formik.values.district}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              id="courierAccountStatus"
              name="courierAccountStatus"
              label="Situação"
              select
              onChange={formik.handleChange}
              value={formik.values.courierAccountStatus}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="">Selecione</MenuItem>
              <MenuItem value="ACTIVE">
                {t("courier_account_status.ACTIVE")}
              </MenuItem>
              <MenuItem value="REGISTRATION_ANALYSIS">
                {t("courier_account_status.REGISTRATION_ANALYSIS")}
              </MenuItem>
              <MenuItem value="REGISTRATION_DENIED">
                {t("courier_account_status.REGISTRATION_DENIED")}
              </MenuItem>
              <MenuItem value="DATA_UPDATED">
                {t("courier_account_status.DATA_UPDATED")}
              </MenuItem>
              <MenuItem value="SUSP_INACTIVE">
                {t("courier_account_status.SUSP_INACTIVE")}
              </MenuItem>
              <MenuItem value="SUSP_DECLINE">
                {t("courier_account_status.SUSP_DECLINE")}
              </MenuItem>
              <MenuItem value="SUSP_OTHERS">
                {t("courier_account_status.SUSP_OTHERS")}
              </MenuItem>
              <MenuItem value="BANNED">
                {t("courier_account_status.BANNED")}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.invalidBankData}
                  onChange={formik.handleChange}
                  name="invalidBankData"
                  color="primary"
                />
              }
              label="Dados Bancários Inválidos"
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              Pesquisar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" type="reset">
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
