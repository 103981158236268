import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import {
  Typography,
  createStyles,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableRow,
  withStyles,
  Card,
  CardContent
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import CourierService from "../../../services/courierService";

interface IParams {
  courierId: string;
}

export const CourierDetailLogs: React.FC = () => {

  const { t } = useTranslation();
  const { courierId } = useParams<IParams>();
  const [logs, setLogs] = useState<any[]>([]);

  useEffect(() => {
    if (courierId) {
      CourierService.getLogs(Number(courierId))
        .then((response) => {
          setLogs(response.data);
        })
        .catch((error) => { });
    }
    return () => {
      setLogs([]);
    };
  }, [courierId]);

  return (
    <Card style={{marginTop: "16px"}}>
      <CardContent>
        <Typography variant="h2">
          Logs
        </Typography>
        {logs.length === 0 && (
          <Typography variant="body2">
            Nenhum log encontrado.
          </Typography>
        )}
        <Table stickyHeader>
          <TableBody>
            {logs?.map((log: any, index: number) => (
              <StyledTableRow key={index}>
                <TableCell>
                  <Typography variant="subtitle1">{t("courier_event_log." + log.event)}</Typography>
                  <Typography>{format(parseISO(log.created_at), "dd/MM/yyyy")}</Typography>
                  {log.description &&
                    (<Typography>Observação: {log.description}</Typography>)
                  }
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: "white",
      },
    },
    cardStyle: {
      marginTop: "8px"
    }
  }),
)(TableRow);
