import { urls } from "../api/urls";
import { IUser } from "../types/user";
import Axios, { AxiosRequestConfig } from "axios";
import jwtDecode from 'jwt-decode';
import { apiAxios } from "../api/config";

import { AppDispatch } from "..";
//@ts-ignore
import { NotificationManager } from "react-notifications";

const Types = {
  AUTH_USER_REQUEST: "AUTH_USER_REQUEST",
  AUTH_USER_SUCCESS: "AUTH_USER_SUCCESS",
  AUTH_USER_FAILURE: "AUTH_USER_FAILURE",
  AUTH_USER_DISCONNECT: "AUTH_USER_DISCONNECT",
  EMAIL_PASSWORD_CHANGE_REQUEST: "EMAIL_PASSWORD_CHANGE_REQUEST",
  EMAIL_PASSWORD_CHANGE_SUCCESS: "EMAIL_PASSWORD_CHANGE_SUCCESS",
  EMAIL_PASSWORD_CHANGE_FAILURE: "EMAIL_PASSWORD_CHANGE_FAILURE",
  ACTIVATE_REQUEST: "ACTIVATE_REQUEST",
  ACTIVATE_SUCCESS: "ACTIVATE_SUCCESS",
  ACTIVATE_FAILURE: "ACTIVATE_FAILURE",
  PASSWORD_RESET_REQUEST: "PASSWORD_RESET_REQUEST",
  PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_FAILURE: "PASSWORD_RESET_FAILURE",
  PASSWORD_CHANGE_REQUEST: "PASSWORD_CHANGE_REQUEST",
  PASSWORD_CHANGE_SUCCESS: "PASSWORD_CHANGE_SUCCESS",
  PASSWORD_CHANGE_FAILURE: "PASSWORD_CHANGE_FAILURE",
};

export interface IState {
  user: IUser;
  isLoading: boolean;
  error?: any;
  status: string;
}

const INITIAL_STATE = {
  user: {} as IUser,
  isLoading: false,
  error: "",
  status: "",
};

export default (
  state = INITIAL_STATE,
  action: { type: string; payload?: any }
): IState => {
  switch (action.type) {
    case Types.AUTH_USER_REQUEST:
      return { ...state, isLoading: true, error: "" };
    case Types.AUTH_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
        user: action.payload.user,
      };
    case Types.AUTH_USER_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    case Types.AUTH_USER_DISCONNECT:
      return { ...state, user: {} as IUser };
    case Types.EMAIL_PASSWORD_CHANGE_REQUEST:
      return { ...state, isLoading: true, error: "", status: "" };
    case Types.EMAIL_PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
        status: "send",
      };
    case Types.EMAIL_PASSWORD_CHANGE_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    case Types.ACTIVATE_REQUEST:
      return { ...state, isLoading: true, error: "", status: "" };
    case Types.ACTIVATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
        status: "activated",
      };
    case Types.ACTIVATE_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    case Types.PASSWORD_RESET_REQUEST:
      return { ...state, isLoading: true, error: "", status: "" };
    case Types.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
        status: "changed",
      };
    case Types.PASSWORD_RESET_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    case Types.PASSWORD_CHANGE_REQUEST:
      return { ...state, isLoading: true, error: "", status: "" };
    case Types.PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
        status: "changed",
      };
    case Types.PASSWORD_CHANGE_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    default:
      return { ...state };
  }
};

export const Actions = {
  getToken: async function (
    dispatch: AppDispatch,
    credentials: { email: string; password: string }
  ) {
    let result = false;
    dispatch({ type: Types.AUTH_USER_REQUEST });

    await Axios.post(urls.TOKEN, credentials, { skipAuthRefresh: true } as AxiosRequestConfig)
      .then((response) => {
        const jwtDecoded: any = jwtDecode(response.data.access);
        localStorage.setItem("@speedy:access", response.data.access);
        localStorage.setItem("@speedy:refresh", response.data.refresh);
        localStorage.setItem("@speedy:exp", jwtDecoded.exp || 0);
        localStorage.setItem("@speedy:user", JSON.stringify(response.data.user));
        result = true;
        dispatch({
          type: Types.AUTH_USER_SUCCESS,
          payload: { user: response.data.user },
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.AUTH_USER_FAILURE,
          payload: { error },
        });
        NotificationManager.error("Login ou senha inválidos", "Autenticação");
      });
    return result;
  },

  disconnect: (dispatch: AppDispatch) => {
    dispatch({ type: Types.AUTH_USER_DISCONNECT });

    localStorage.removeItem("@speedy:access");
    localStorage.removeItem("@speedy:refresh");
    localStorage.removeItem("@speedy:exp");
    localStorage.removeItem("@speedy:user");
  },

  currentUser: (dispatch: AppDispatch, userId: number) => {
    Axios.get(urls.USER + userId)
      .then((response) => {
        dispatch({
          type: Types.AUTH_USER_SUCCESS,
          payload: { user: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.AUTH_USER_FAILURE,
          payload: { error },
        });
      });
  },

  requestPasswordReset: (dispatch: AppDispatch, email: string) => {
    dispatch({ type: Types.EMAIL_PASSWORD_CHANGE_REQUEST });

    Axios.post(urls.REQUEST_PASSWORD_RESET, { email })
      .then((response) => {
        dispatch({
          type: Types.EMAIL_PASSWORD_CHANGE_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.EMAIL_PASSWORD_CHANGE_FAILURE,
          payload: { error: "E-mail não encontrado" },
        });
      });
  },

  resetPassword: (
    dispatch: AppDispatch,
    credentials: {
      uid: string;
      token: string;
      new_password1: string;
      new_password2: string;
    }
  ) => {
    dispatch({ type: Types.PASSWORD_RESET_REQUEST });
    Axios.post(urls.PASSWORD_RESET, credentials)
      .then((response) => {
        dispatch({
          type: Types.PASSWORD_RESET_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.PASSWORD_RESET_FAILURE,
          payload: { error: "Não foi possível alterar sua senha!" },
        });
      });
  },

  activate: (
    dispatch: AppDispatch,
    credentials: {
      uid: string;
      token: string;
      password: string;
    }
  ) => {
    dispatch({ type: Types.ACTIVATE_REQUEST });
    Axios.post(`${urls.ACTIVATE}`, credentials)
      .then((response) => {
        dispatch({
          type: Types.ACTIVATE_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.ACTIVATE_FAILURE,
          payload: { error },
        });
      });
  },

  changePassword: (
    dispatch: AppDispatch,
    credentials: {
      old_password: string;
      new_password1: string;
      new_password2: string;
    }
  ) => {
    dispatch({ type: Types.PASSWORD_CHANGE_REQUEST });
    apiAxios
      .post(urls.PASSWORD_CHANGE, credentials)
      .then((response) => {
        dispatch({
          type: Types.PASSWORD_CHANGE_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.PASSWORD_CHANGE_FAILURE,
          payload: { error },
        });
      });
  },

  //   register: async function (signupData) {
  //     await axios({
  //       method: "post",
  //       url: urls.USERS,
  //       headers: { "Content-Type": "application/form-data" },
  //       data: signupData,
  //     })
  //       .then((response) => {
  //         return response;
  //       })
  //       .catch((error) => {
  //         let err_obj = error.response.data;
  //         throw err_obj[Object.keys(err_obj)[0]][0];
  //       });
  //   },

};
