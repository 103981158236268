import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import { FormikHelpers, useFormik } from "formik";
import DatePicker from "react-datepicker";
import { CURRENT_DATE_TIME_FORMAT, DEFAULT_LOCALE } from "../../i18n";
import { ProposalType } from "../../services/types/dedicated";
import RegionService from "../../services/regionService";
import CustomerService from "../../services/customerService";
import { PageableParams } from "../../components/TableTrinkets";
import { useHistory } from "react-router";
import { encodeURLParams } from "../../utils/utils";

export interface IDedicatedFilterParams {
  store: number | null,
  region: number | null,
  requested_time_after: Date | null,
  requested_time_before: Date | null,
  proposal_type: ProposalType | null,
}

export const INITIAL_FILTER_PARAMS: IDedicatedFilterParams = {
  store: null,
  region: 0,
  requested_time_after: null,
  requested_time_before: null,
  proposal_type: null,
}

interface IDedicatedFilterProps {
  params?: IDedicatedFilterParams;
  onFilterChanged: (params: IDedicatedFilterParams) => void;
}

export const DedicatedFilter: React.FC<IDedicatedFilterProps> = ({
  params = INITIAL_FILTER_PARAMS,
  onFilterChanged
}) => {
  const [regionList, setRegionList] = React.useState<any[]>([]);
  const [selectedRegion, setSelectedRegion] = React.useState<number>();
  const [storeList, setStoreList] = React.useState<any[]>([]);
  const history = useHistory();

  React.useEffect(() => {
    history.replace(encodeURLParams(history.location.pathname, params));
  }, [history, params]);


  const formik = useFormik<IDedicatedFilterParams>({
    initialValues: params,
    onSubmit: (values: IDedicatedFilterParams, formikHelpers: FormikHelpers<IDedicatedFilterParams>) => {
      onFilterChanged(values);
    },
    onReset: (values: IDedicatedFilterParams, formikHelpers: FormikHelpers<IDedicatedFilterParams>) => {
      onFilterChanged(INITIAL_FILTER_PARAMS);   
      clear();
      formikHelpers.setValues(INITIAL_FILTER_PARAMS);
    },
  });

  const clear = () => {
    setStoreList([]); 
    setSelectedRegion(undefined);
    formik.setFieldValue("region", undefined);
    formik.setFieldValue("store", undefined);
  }

  useEffect(() => {
    if (params.region) {
      setSelectedRegion(params.region);
    }
  }, [params]);

  React.useEffect(() => {
    const getRegions = async () => {
      const response = await RegionService.loadAllRegions({ "ordering": "name" });
      setRegionList(response);
    }
    getRegions();
  }, []);

  React.useEffect(() => {
    const getStores = async () => {
      const isActive = true;
      let regionsId = selectedRegion ? [selectedRegion] : undefined;
      const response = await CustomerService.getStores(undefined, undefined,
        regionsId, isActive, new PageableParams(1, 100, "name"));
      setStoreList(response.data);
    };
    if (selectedRegion) {
      getStores();
    }
  }, [selectedRegion]);

  return (
    <div className="page-filter">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <TextField
                id="region"
                name="region"
                label="Região"
                select
                onChange={(newValue) => {
                  if (newValue) {
                    setSelectedRegion(Number(newValue.target.value));
                    formik.setFieldValue("region", newValue.target.value);
                  } else {
                    clear();
                  }
                }}
                value={formik.values.region}
                variant="outlined"
                fullWidth
            >
                <MenuItem selected={true} value={0}>Todas</MenuItem>
                {regionList.map(region => (
                    <MenuItem key={region.id} value={region.id}>
                        {region.name} - {region.state}
                    </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item md={3}>
            <TextField
              id="store"
              name="store"
              label={!selectedRegion ? "Loja (Selecione uma Região*)" : "Loja"}
              select
              onChange={formik.handleChange}
              value={formik.values.store}
              variant="outlined"
              fullWidth
            >
              <MenuItem selected={true} value={0}>Todas</MenuItem>
              {storeList.map((store) => (
                <MenuItem key={store.id} value={store.id}>
                  {store.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xl={2} md={3}>
            <DatePicker
              id="requested_time_after"
              name="requested_time_after"
              onChange={val => {
                formik.setFieldValue("requested_time_after", val);
              }}
              maxDate={new Date()}
              selected={formik.values.requested_time_after}
              startDate={formik.values.requested_time_after}
              endDate={formik.values.requested_time_before}
              selectsStart
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_TIME_FORMAT}
              isClearable
              autoComplete="off"
              timeCaption="Hora"
              showTimeSelect
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data e Hora - Início"
                variant="outlined"
                size="small"
                error={!!formik.errors.requested_time_after}
                helperText={formik.errors.requested_time_after}
                fullWidth />}
            />
          </Grid>

          <Grid item xl={2} md={3}>
            <DatePicker
              id="requested_time_before"
              name="requested_time_before"
              onChange={val => {
                formik.setFieldValue("requested_time_before", val);
              }}
              maxDate={new Date()}
              selected={formik.values.requested_time_before}
              startDate={formik.values.requested_time_after}
              endDate={formik.values.requested_time_before}
              minDate={formik.values.requested_time_after}
              selectsEnd
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_TIME_FORMAT}
              isClearable
              autoComplete="off"
              timeCaption="Hora"
              showTimeSelect
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data e Hora - Fim"
                variant="outlined"
                size="small"
                error={!!formik.errors.requested_time_before}
                helperText={formik.errors.requested_time_before}
                fullWidth />}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              Pesquisar
            </Button>
          </Grid>

          <Grid item>
            <Button variant="contained" color="secondary" type="reset">
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
