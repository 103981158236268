import React from "react";
import {
    Grid,
    Button,
    TextField,
    MenuItem,
} from "@material-ui/core";
import { FormikHelpers, useFormik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import * as Yup from "yup";
import { useHistory } from "react-router";
import { encodeURLParams } from "../../utils/utils";
import { DEFAULT_LOCALE } from "../../i18n";
import DatePicker from "react-datepicker";
import { City } from "../../services/types/common";

export interface INotRechargingCustomersParams {
    fiscal_address_city: number | null;
    date_after: Date;
    date_before: Date;
};
var today = new Date();
var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
export const INITIAL_FILTER_PARAMS: INotRechargingCustomersParams = {
    fiscal_address_city: null,
    date_after: lastDayOfMonth,
    date_before: firstDayOfMonth
};

interface INotRechargingCustomersFilterProps {
    params?: INotRechargingCustomersParams;
    cityList: City[];
    onFilterChanged: (params: INotRechargingCustomersParams) => void;
};

const validationSchema = Yup.object().shape({});

export const NotRechargingCustomersFilter: React.FC<INotRechargingCustomersFilterProps> = ({ params = INITIAL_FILTER_PARAMS, cityList, onFilterChanged }) => {
    const history = useHistory();

    React.useEffect(() => {
        history.replace(encodeURLParams(history.location.pathname, params));
    }, [history, params]);

    const formik = useFormik<INotRechargingCustomersParams>({
        initialValues: params,
        onSubmit: (values: INotRechargingCustomersParams, formikHelpers: FormikHelpers<INotRechargingCustomersParams>) => {
            onFilterChanged(values);
        },
        onReset: (values: INotRechargingCustomersParams, formikHelpers: FormikHelpers<INotRechargingCustomersParams>) => {
            onFilterChanged(INITIAL_FILTER_PARAMS);
            formikHelpers.setValues(values);
        },
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });

    return (
        <div className="page-filter">
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Grid container spacing={2}>
                    <Grid item lg={2} md={2} sm={3}>
                        <DatePicker
                            id="date_after"
                            name="date_after"
                            onChange={val => {
                                var today = new Date(val as Date);
                                var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                                formik.setFieldValue("date_after", val);
                                formik.setFieldValue("date_before", lastDayOfMonth);
                            }}
                            maxDate={new Date()}
                            selected={formik.values.date_after}
                            startDate={formik.values.date_after}
                            endDate={formik.values.date_before}
                            selectsStart
                            locale={DEFAULT_LOCALE}
                            todayButton="Mês e Ano"
                            autoComplete="off"
                            wrapperClassName="MuiFormControl-fullWidth"
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            customInput={<TextField
                                label="Mês e Ano"
                                variant="outlined"
                                size="small"
                                error={!!formik.errors.date_after}
                                helperText={formik.errors.date_after}
                                fullWidth
                            />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="fiscal_address_city"
                            name="fiscal_address_city"
                            label="Cidade"
                            select
                            onChange={formik.handleChange}
                            value={formik.values.fiscal_address_city}
                            variant="outlined"
                            fullWidth
                            >
                            <MenuItem disabled value={0}>Selecione uma Cidade</MenuItem>
                            {cityList.map((city) => (
                                <MenuItem key={city.id} value={city.id}>
                                {city.name} - {city.state_initials}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" type="submit">
                            Pesquisar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" type="reset">
                            Limpar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};