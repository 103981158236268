import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CustomerIntegrationSource, StoreResponse } from "../../../services/types/customer";
import { useFormik } from "formik";

export interface ICustomerIntegrationsFilterParams {
  storeId: number | undefined;
  integration_source: CustomerIntegrationSource | undefined;
}

export const INITIAL_FILTER_PARAMS: ICustomerIntegrationsFilterParams = {
  storeId: undefined,
  integration_source: undefined,
};

interface ICustomerIntegrationsFilterProps {
  params?: ICustomerIntegrationsFilterParams;
  storeList: StoreResponse[];
  onFilterChanged: (params: ICustomerIntegrationsFilterParams) => void;
}

export const CustomerFinancialOperationsFilter: React.FC<ICustomerIntegrationsFilterProps> = ({
  params = INITIAL_FILTER_PARAMS,
  storeList,
  onFilterChanged
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: params,
    onSubmit: values => {
      onFilterChanged(values);
    },
    onReset: values => {
      onFilterChanged(values);
    },
  });

  return (
    <div className="page-filter">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          <Grid item md={2}>
            <TextField
              id="storeId"
              name="storeId"
              select
              label="Loja"
              value={formik.values.storeId}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="">Todos</MenuItem>
              {storeList.map(store => (
                <MenuItem key={store.id} value={store.id}>
                  {store.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item md={2}>
            <TextField
              id="integretion_source"
              name="integretion_source"
              select
              label="Aplicativo"
              value={formik.values.integration_source}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
            >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value={CustomerIntegrationSource.IFOOD}>{t("customer_integration_source." + CustomerIntegrationSource.IFOOD)}</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginTop: "10px" }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              Pesquisar
            </Button>
          </Grid>

          <Grid item>
            <Button variant="contained" color="secondary" type="reset">
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};