import React from "react";
import { $enum } from "ts-enum-util";
import { FormikHelpers, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
    Grid,
    TextField,
    MenuItem,
    Button,
} from "@material-ui/core";
import {
    DeliveryForType,
} from "../../services/types/announcement";
import { DEFAULT_LOCALE, CURRENT_DATE_TIME_FORMAT } from "../../i18n";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router";
import { encodeURLParams } from "../../utils/utils";

export interface IAnnouncemetFilterParams {
    title: string;
    send_date_after: Date | null;
    send_date_before: Date | null;
    expire_date_after: Date | null;
    expire_date_before: Date | null;
    screen: string;
    announcement_type: string;
    delivery_for: string;
}

export const INITIAL_FILTER_PARAMS: IAnnouncemetFilterParams = {
    title: "",
    send_date_after: null,
    send_date_before: null,
    expire_date_after: null,
    expire_date_before: null,
    screen: "",
    announcement_type: "",
    delivery_for: "",
};

interface IAnnouncemetFilterProps {
    params?: IAnnouncemetFilterParams;
    onFilterChanged: (params: IAnnouncemetFilterParams) => void;
}

export const AnnouncemetFilter: React.FC<IAnnouncemetFilterProps> = ({ params = INITIAL_FILTER_PARAMS, onFilterChanged }) => {
    const { t } = useTranslation();
    const history = useHistory();

    React.useEffect(() => {
        history.replace(encodeURLParams(history.location.pathname, params));
    }, [history, params]);

    const formik = useFormik({
        initialValues: params,

        onSubmit: (values: IAnnouncemetFilterParams, formikHelpers: FormikHelpers<IAnnouncemetFilterParams>) => {
            onFilterChanged(values);
        },
        onReset: (values: IAnnouncemetFilterParams, formikHelpers: FormikHelpers<IAnnouncemetFilterParams>) => {
            onFilterChanged(INITIAL_FILTER_PARAMS);
            formikHelpers.setValues(INITIAL_FILTER_PARAMS);
        }
    });

    return (
        <div className="page-filter">
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            id="title"
                            label="Título"
                            onChange={formik.handleChange}
                            fullWidth
                            value={formik.values.title}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="delivery_for"
                            name="delivery_for"
                            label="Envio"
                            select
                            onChange={formik.handleChange}
                            value={formik.values.delivery_for}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value="">Todas as opções</MenuItem>
                            {$enum(DeliveryForType).map(
                                (delivery_for: string) => {
                                    return (
                                        <MenuItem key={delivery_for} value={delivery_for}>
                                            {t("announcement_delivery_for_type." + delivery_for)}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </TextField>
                    </Grid>
                    <Grid item md={3}>
                        <DatePicker
                            id="send_date_after"
                            name="send_date_after"
                            onChange={(val, event: React.SyntheticEvent) => {
                                formik.setFieldValue("send_date_after", val);
                                event.preventDefault();
                            }}
                            maxDate={new Date()}
                            selected={formik.values.send_date_after}
                            startDate={formik.values.send_date_after}
                            endDate={formik.values.send_date_before}
                            selectsStart
                            locale={DEFAULT_LOCALE}
                            dateFormat={CURRENT_DATE_TIME_FORMAT}
                            timeCaption="Hora"
                            isClearable
                            showTimeSelect
                            autoComplete="off"
                            wrapperClassName="MuiFormControl-fullWidth"
                            customInput={<TextField
                                label="Data Início do Envio"
                                variant="outlined"
                                size="small"
                                error={!!formik.errors.send_date_after}
                                helperText={formik.errors.send_date_after}
                                fullWidth />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <DatePicker
                            id="send_date_before"
                            name="send_date_before"
                            onChange={val => {
                                formik.setFieldValue("send_date_before", val);
                            }}
                            maxDate={new Date()}
                            selected={formik.values.send_date_before}
                            startDate={formik.values.send_date_after}
                            endDate={formik.values.send_date_before}
                            minDate={formik.values.send_date_after}
                            selectsEnd
                            locale={DEFAULT_LOCALE}
                            dateFormat={CURRENT_DATE_TIME_FORMAT}
                            timeCaption="Hora"
                            isClearable
                            showTimeSelect
                            autoComplete="off"
                            wrapperClassName="MuiFormControl-fullWidth"
                            customInput={<TextField
                                label="Data Fim do Envio"
                                variant="outlined"
                                size="small"
                                error={!!formik.errors.send_date_before}
                                helperText={formik.errors.send_date_before}
                                fullWidth />}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Pesquisar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="reset"
                        >
                            Limpar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
