import React from "react";
import { useFormik } from "formik";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, 
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { DeliveryRequest } from "../../services/types/deliveryRequest";

import { addHours } from "date-fns";
import { AnnouncementTemplate, AnnouncementType, CreateUpdateAnnouncement, DeliveryForType } from "../../services/types/announcement";
import AnnouncementsService from "../../services/announcementsService";

interface SendDeliveryRequestAnnouncementForm {
    templateId: number | null;
    title: string;
    content: string;
  }
  
const INITIAL_STATE: SendDeliveryRequestAnnouncementForm = {
    templateId: null,
    title: "",
    content: "",
};

const validationSchema = Yup.object().shape({
title: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .required("Campo obrigatório"),
content: Yup.string()
    .max(250, "Máximo de 250 caracteres excedido")
    .required("Campo obrigatório"),
});

interface NewAnnouncementDialogProps {
  couriers: number[];
  tripId: number | null;
  deliverySelected: DeliveryRequest | null;
  onAccept?: () => Promise<void>;
  onCancel: () => void;
}

export interface NewAnnouncementDialogHandle {
  open: () => void;
  close: () => void;
}


const NewAnnouncementDialog = React.forwardRef<NewAnnouncementDialogHandle, NewAnnouncementDialogProps>((props, ref) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const [loadingAnnouncement, setLoadingAnnouncement] = React.useState<boolean>(false);
  const [announcementTemplateList, setAnnouncementTemplateList] = React.useState<AnnouncementTemplate[]>();

  const classes = useStyles();

  React.useImperativeHandle(ref, () => {
    return ({
      open() {       
        show();       
      },

      close() {
        hide();
      }
    });
  });

  const hide = () => {
    setOpen(false);
    formik.resetForm();
    if(props.onCancel){
      props.onCancel();
    }
  }

  const show = () => {
    formik.resetForm();
    setOpen(true);
  }

  const formik = useFormik({
    initialValues: INITIAL_STATE,
    onSubmit: async (values: SendDeliveryRequestAnnouncementForm) => {
        setLoadingAnnouncement(true);
        const newAnnouncement: CreateUpdateAnnouncement = {
          related_trip: props.tripId,
          title: values.title,
          content: values.content,
          couriers: props.couriers,
          delivery_for: DeliveryForType.SPECIFIC_COURIERS,
          announcement_type: AnnouncementType.IMMEDIATE,
          only_active_couriers: false,
          expire_date: addHours(new Date(), 1)
        };

        AnnouncementsService.sendAnnouncement(newAnnouncement)
          .then((response) => {
            setLoadingAnnouncement(false);
            handleClose();
            if(props.onAccept){
                props.onAccept();
            }
            NotificationManager.success(
              "Aviso enviado com sucesso",
              "Novo Aviso"
            );
          }).catch((error) => {
            setLoadingAnnouncement(false);
            NotificationManager.error(
              "Erro ao enviar o Aviso",
              "Novo Aviso"
            );
          });
      
    },
    validationSchema,
    enableReinitialize: false,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleSubmit = () => {
    formik.submitForm();
  }

  function interpolate(template: string, context: any) {
    return template.replace(/[$]{([^}]+)}/g, function (_, path) {
      const properties = path.split('.');
      return properties.reduce((prev: any, curr: any) => prev && prev[curr], context);
    })
  }

  React.useEffect(() => {
    const loadTemplateNotification = async () => {
      const listTemplates = await AnnouncementsService.getAnnouncementTemplates();
      setAnnouncementTemplateList(listTemplates);
    };

    loadTemplateNotification();
  }, []);


  return (
    <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{"Enviar Aviso"}</DialogTitle>
    <DialogContent>
      <Grid item style={{ marginBottom: "15px" }}>
        <TextField
          id="templateId"
          name="templateId"
          label="Tipo do Aviso"
          select
          fullWidth
          variant="outlined"
          value={formik.values.templateId || 0}
          onChange={(e) => {
            formik.handleChange(e);
            if (announcementTemplateList) {
              const result = announcementTemplateList.find(item => item.id === Number(e.target.value));
              if (result?.title) {
                formik.setFieldValue("title", interpolate(result.title, { delivery: props.deliverySelected}))
              }
              if (result?.content) {
                formik.setFieldValue("content", interpolate(result.content, { delivery: props.deliverySelected === null ? {order_number:"____", customer:{customer_name: "_______"}} :  props.deliverySelected}))
              }
            }
          }}
        >
          <MenuItem disabled={true} value={0}>
            Tipo do Aviso
          </MenuItem>
          {announcementTemplateList?.map((template) => (
            <MenuItem key={template.id} value={template.id}>
              {template.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item style={{ marginBottom: "15px" }}>
        <TextField
          label="Título"
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={!!formik.errors.title}
          helperText={formik.errors.title}
          variant="outlined"
          required={true}
          inputProps={{
            maxLength: 100
          }}
          fullWidth
        />
      </Grid>
      <Grid item style={{ marginBottom: "15px" }}>
        <TextField
          label="Mensagem"
          name="content"
          multiline
          rows={5}
          value={formik.values.content}
          error={!!formik.errors.content}
          helperText={"Máx. 250 caracteres" || formik.errors.content}
          onChange={formik.handleChange}
          variant="outlined"
          inputProps={{
            maxLength: 250
          }}
          required={true}
          fullWidth
        />
      </Grid>
      <Typography>
        Obs.: Não será possível reverter esta ação.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary">
        Cancelar
      </Button>
      <Button onClick={handleSubmit} disabled={loadingAnnouncement} color="primary" autoFocus>
        Enviar{" "}
        {loadingAnnouncement && (
          <CircularProgress
            size={24}
            className={classes.buttonProgress}
          />
        )}
      </Button>
    </DialogActions>
  </Dialog>
  );
});

export default NewAnnouncementDialog;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      position: "absolute",
    },
  })
);