/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Fade from "@material-ui/core/Fade";
import {
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableRow,
    TableCell,
    ListItemAvatar,
    Avatar,
    Chip,
    Card,
    CardContent,
} from "@material-ui/core";
import { LoadingComponent } from "../../../components/Loading";
import { CustomerScheduleStoreEnum, StoreResponse } from "../../../services/types/customer";
import CustomerService from "../../../services/customerService";
import { useTranslation } from "react-i18next";
import { formatPhoneNumber } from "../../../utils/formatters";
import { Alert } from "@material-ui/lab";
import AccessTimeIcon from '@material-ui/icons/AccessTimeOutlined';
import { CustomAvatar } from "../../../components/CustomAvatar";

interface ICustomerStoreDetailsDialog {
    modal: {
        open: boolean;
        setOpen(value: boolean): void;
    };
    store: StoreResponse;
    refresh(): void;
}


const CustomerStoreDetailsDialog: React.FC<ICustomerStoreDetailsDialog> = ({
    modal,
    store,
    refresh
}) => {
    const [storeDetails, setStoreDetails] = useState<StoreResponse>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [storeSchedule, setStoreSchedule] = useState<any[]>([]);
    const { t } = useTranslation();


    const handleClose = () => {
        refresh();
        setStoreDetails(undefined);
        modal.setOpen(false);
    };

    const loadStoreDetails = (storeId: number) => {
        setIsLoading(true);

        CustomerService.getStore(storeId)
            .then((response) => {
                setIsLoading(false);
                setStoreDetails(response);
            }).catch((error) => { setIsLoading(false); });
    }

    const loadStoreSchedule = (storeId: number) => {
        setIsLoading(true);

        var daysWeek = [{ name: t("store_schedule." + CustomerScheduleStoreEnum.SUNDAY), day: CustomerScheduleStoreEnum.SUNDAY, schedule: [] },
        { name: t("store_schedule." + CustomerScheduleStoreEnum.MONDAY), day: CustomerScheduleStoreEnum.MONDAY, schedule: [] },
        { name: t("store_schedule." + CustomerScheduleStoreEnum.TUESDAY), day: CustomerScheduleStoreEnum.TUESDAY, schedule: [] },
        { name: t("store_schedule." + CustomerScheduleStoreEnum.WEDNESDAY), day: CustomerScheduleStoreEnum.WEDNESDAY, schedule: [] },
        { name: t("store_schedule." + CustomerScheduleStoreEnum.THURSDAY), day: CustomerScheduleStoreEnum.THURSDAY, schedule: [] },
        { name: t("store_schedule." + CustomerScheduleStoreEnum.FRIDAY), day: CustomerScheduleStoreEnum.FRIDAY, schedule: [] },
        { name: t("store_schedule." + CustomerScheduleStoreEnum.SATURDAY), day: CustomerScheduleStoreEnum.SATURDAY, schedule: [] },
        { name: t("store_schedule." + CustomerScheduleStoreEnum.HOLIDAY), day: CustomerScheduleStoreEnum.HOLIDAY, schedule: [] }
        ]


        CustomerService.getStoreSchedule(storeId)
            .then((response) => {
                setIsLoading(false);
                const daysWeekGroup = daysWeek.reduce((arr: any, { name, day, schedule }) => {
                    const dWeek = response.filter((item) => { 
                        if (item.day_of_week === day) { 
                            return item 
                        }
                        return null;
                    });
                    if (!arr[day]) {
                        arr[day] = [];
                        dWeek.forEach((item) => {
                            arr[day].push(item);
                        });
                    } else {
                        dWeek.forEach((item) => {
                            arr[day].push(item);
                        });
                    }

                    return arr;
                }, []);

                setStoreSchedule(daysWeekGroup);
            }).catch((error) => { setIsLoading(false); });
    }

    useEffect(() => {
        loadStoreDetails(store.id)
        loadStoreSchedule(store.id);
    }, [store]);

    const checkExists = (numb: number) => {
        var exists = false;
        if (storeSchedule[numb].length > 0) {
            exists = true;
        }
        return exists;
    }

    return (
        <div>
            <Dialog
                aria-labelledby="form-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick={false}
                disableEscapeKeyDown={false}
                open={modal.open}
                fullWidth={true}
                maxWidth={"lg"}
                onClose={handleClose}
            >
                <Fade in={modal.open}>
                    {isLoading ? (
                        <LoadingComponent />
                    ) : (
                        <div>
                            <DialogTitle id="form-dialog-title">
                                Detalhes da Loja
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={2}>                                   
                                    <Grid item md={2}>
                                        <Card style={{height:"100%"}}>
                                            <CardContent>
                                                <Grid
                                                container
                                                justify="center"
                                                alignItems="center"
                                                direction="column"
                                                spacing={1}
                                                >
                                                {storeDetails?.photo && (
                                                    <React.Fragment>
                                                        <Grid item>
                                                            <CustomAvatar img={storeDetails?.photo} useCache={false} size="lg" />
                                                        </Grid>
                                                    </React.Fragment>
                                                )}
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item md={10}>
                                        <Table className="table-bordered">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>Nome Fantasia</Typography>
                                                        <Typography variant="subtitle1">
                                                            {storeDetails?.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>Contato</Typography>
                                                        <Typography variant="subtitle1">
                                                            {storeDetails?.contact_name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>Email</Typography>
                                                        <Typography variant="subtitle1">
                                                            {storeDetails?.email}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell colSpan={2}>
                                                        <Typography>Telefone</Typography>
                                                        <Typography variant="subtitle1">
                                                            {storeDetails?.phonenumber ? formatPhoneNumber(storeDetails?.phonenumber) : "---"}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>Situação</Typography>
                                                        <Typography variant="subtitle1">
                                                            <Alert
                                                                icon={false}
                                                                severity={storeDetails?.is_active ? "success" : "error"}
                                                            >
                                                                {storeDetails?.is_active ? "Ativo" : "Inativo"}
                                                            </Alert>
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Typography>Máx. de Entregas Juntas</Typography>
                                                        <Typography variant="subtitle1">
                                                            {storeDetails?.max_number_of_deliveries}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Typography>Máx. de Entregas Juntas Configurável</Typography>
                                                        <Typography variant="subtitle1">
                                                            {storeDetails?.max_number_of_deliveries_limit}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell colSpan={2}>
                                                        <Typography>Priorização da Fila</Typography>
                                                        <Typography variant="subtitle1">
                                                            {storeDetails?.use_priority_queue ? "Sim" : "Não"}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>Rua</Typography>
                                                        <Typography variant="subtitle1">
                                                            {storeDetails?.street}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Typography>Bairro</Typography>
                                                        <Typography variant="subtitle1">
                                                            {storeDetails?.district}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Typography>N°</Typography>
                                                        <Typography variant="subtitle1">
                                                            {storeDetails?.address_number}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Typography>Complemento</Typography>
                                                        <Typography variant="subtitle1">
                                                            {storeDetails?.address_complement ? storeDetails?.address_complement : "---"}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell colSpan={3}>
                                                        <Typography>CEP</Typography>
                                                        <Typography variant="subtitle1">
                                                            {storeDetails?.postal_code ? storeDetails?.postal_code : "---"}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Typography variant="h2" style={{ paddingBottom: "0px" }}>Horários</Typography>
                                        <List dense={true} style={{ paddingTop: "0px" }}>
                                            {storeSchedule && storeSchedule.map((item, index) => {
                                                return (
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar style={{ backgroundColor: checkExists(index) ? "#2196f3" : "#bdbdbd" }}>
                                                                <AccessTimeIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={`${t("store_schedule." + index)}`}
                                                            secondary={
                                                                <React.Fragment>
                                                                    {item && item.length > 0 ? (
                                                                        <div>
                                                                            Horários de Funcionamento:
                                                                            {item && item.map((obj: any, indx: number) => {
                                                                                return (
                                                                                    <>
                                                                                        <Chip style={{ marginLeft: "5px" }} label={`${obj.begin_time} - ${obj.end_time} `} size="small" variant="outlined" />
                                                                                        {indx === item.length - 1 ? "" : <strong style={{ color: "black", marginLeft: "5px" }}>/</strong>}
                                                                                    </>

                                                                                )
                                                                            })}
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            Nenhum horário informado.
                                                                        </div>
                                                                    )}

                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                        {storeSchedule && storeSchedule.length === 0 && (
                                            <Typography>Nenhum horário informado.</Typography>
                                        )}
                                    </Grid>
                                </Grid>

                            </DialogContent>
                        </div>

                    )}
                </Fade>
            </Dialog>
        </div>
    );
};

export default CustomerStoreDetailsDialog;
