import React, { useCallback, useEffect, useState } from "react";
import { Grid, makeStyles, Paper, Theme, Typography, createStyles } from "@material-ui/core";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { useHistory } from "react-router-dom";
import { decodeURLParams, URLParamType } from "../../utils/utils";
import { endOfDay, startOfDay } from "date-fns";
import { INITIAL_FILTER_PARAMS, IReportCouriersParams, ReportCouriersFilter } from "./ReportCouriersFilter";
import RegionService from "../../services/regionService";
import CourierService from "../../services/courierService";
import { ReportCouriersResponse } from "../../services/types/courier";
import { Alert } from "@material-ui/lab";

interface ICardGain {
    value: number;
    title: string;
}

const CardGain: React.FC<ICardGain> = ({ value, title }) => {
    const classes = useStyles();
    return (
        <Paper
            elevation={4}
            className={classes.cardGain}>
            <Typography variant="h1">{value}</Typography>
            <Typography variant="body1">{title}</Typography>
        </Paper >
    )
}

export const ReportCouriers = () => {
    const history = useHistory();
    const classes = useStyles();
    const [filterParams, setFilterParams] = useState<IReportCouriersParams>({
        ...INITIAL_FILTER_PARAMS,
        ...decodeURLParams(history.location.search, [
            { "date_after": URLParamType.DATE },
            { "date_before": URLParamType.DATE }
        ])
    });
    const [regionList, setRegionList] = useState<any[]>([]);
    const [reportCouriers, setReportCouriers] = useState<ReportCouriersResponse>();
    const [errorFilter, setErrorFilter] = useState<string>("");

    const loadData = useCallback(() => {
        const region = filterParams.region ? Number(filterParams.region) : null;
        const startDate = filterParams.date_after ? startOfDay(filterParams.date_after) : null;
        const endDate = filterParams.date_before ? endOfDay(filterParams.date_before) : null;
        console.log(region)
        if (startDate == null || endDate == null || region === 0 || region == null) {
            setErrorFilter("Selecione o Mês/Ano e Região para Filtrar as Informações.");
        } else {
            setErrorFilter("");
            CourierService.getReportCouriers(region, startDate, endDate)
                .then(response => {
                    setReportCouriers(response);
                });
        }
    }, [filterParams]);

    const applyFilter = (params: IReportCouriersParams) => {
        setFilterParams(params);
    }

    useEffect(() => {
        loadData();
    }, [filterParams, loadData]);


    useEffect(() => {
        const getRegions = async () => {
            const response = await RegionService.loadAllRegions({
                ordering: "name",
            });
            setRegionList(response);
        };
        getRegions();
    }, []);

    return (
        <div>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Relatório de Entregadores</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[{ label: "Relatório de Entregadores", url: "/reportCouriers" }]}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <ReportCouriersFilter params={filterParams} regionList={regionList} onFilterChanged={applyFilter} />
            {errorFilter !== "" && (
                <Grid item>
                    <Alert severity={errorFilter === "" ? "warning" : "info"}>{errorFilter}</Alert>
                </Grid>
            )}
            
            
            <Grid container
                direction="row"
                alignItems="flex-start"
                justify="space-between">
                <Grid item md={12}>
                    <div className={classes.cardGainGroup}>
                        <CardGain
                            value={reportCouriers ? reportCouriers.new_registers : 0}
                            title="Novos Cadastros" />
                        <CardGain
                            value={reportCouriers ? reportCouriers.approved_count : 0}
                            title="Aprovados" />
                        <CardGain
                            value={reportCouriers ? reportCouriers.pending_count : 0}
                            title="Em Análise" />
                        <CardGain
                            value={reportCouriers ? reportCouriers.rejected_count : 0}
                            title="Rejeitados" />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardGainGroup: {
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            columnGap: "50px",
            rowGap: "30px",
            marginBottom: "30px",
            marginTop: "20px"
        },
        cardGain: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "30px 0",
            whiteSpace: "nowrap",
        }
    })
);
