import { formatISO, formatRFC3339, parseISO } from "date-fns";
import { apiAxios as axios } from "../store/api/config";
import { getURL, urls } from "../store/api/urls";
import { ICreateCustomer, ICustomerUser } from "../store/types/customer";
import { convertToBoolean, convertToDate, convertToNumber } from "../utils/utils";
import { IPageableParams, PagedResponse } from "./types/common";
import { AddCustomerIntegration, BlockedCourier, CourierQueueItem, CreateUpdateStore, CustomerCourierBlock, CustomerDetails, CustomerFinancialOperation, CustomerFinancialOperationType, CustomerIntegration, CustomerIntegrationResponse, CustomerIntegrationSource, CustomerResponse, CustomerDeliveryStats, EditCustomerIntegration, StoreResponse, UpdateCustomer, CustomerStoreScheduleResponse, NegativeCustomersResponse, NotRechargingCustomersResponse, ReportCustomersActiveResponse, StoresCustomersActiveResponse } from "./types/customer";
import { FinancialEntryOrigin, RechargesPerDayResponse } from "./types/customerFinances";
import { CustomerFinancialEntryResponse, FinancialEntryStatus } from "./types/financialEntry";

export interface ICustomerService {
    getCustomers: (
        trade_name: string | undefined,
        email: string | undefined,
        phonenumber: string | undefined,
        registrationNumber: string | undefined,
        city: string | undefined,
        is_active: boolean | undefined,
        region: number | undefined,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<CustomerResponse>>;

    getCustomer: (customerId: number) => Promise<CustomerDetails>;

    updateCustomer: (customerId: number, customer: UpdateCustomer) => Promise<any>;

    exportCustomerOperations: (
        customerId: number,
        start_date: Date | null,
        end_date: Date | null,
        description: string | null,
        store_name: string | null,
        status: FinancialEntryStatus | null,
        type: CustomerFinancialOperationType | null,
    ) => Promise<any>;

    getStores: (
        name: string | undefined,
        customerId: number | undefined,
        regionIds: number[] | undefined,
        isActive: boolean | undefined,
        pageParams: IPageableParams) => Promise<PagedResponse<StoreResponse>>;

    getCustomerDeliveryStats: (customerId: number, storeId: number) => Promise<CustomerDeliveryStats>;

    getStoreCourierQueue: (idStore: number) => Promise<CourierQueueItem[]>;

    getStore: (storeId: number) => Promise<any>;

    getStoreSchedule: (storeId: number) => Promise<CustomerStoreScheduleResponse[]>;

    createStore: (store: CreateUpdateStore) => Promise<any>;

    updateStore: (storeId: number, store: CreateUpdateStore) => Promise<any>;

    deleteStore: (storeId: number) => Promise<any>;

    activateStore: (storeId: number) => Promise<any>;

    deactivateStore: (storeId: number) => Promise<any>;

    getCustomerIntegrations: (
        customerId: number,
        storeId: number | undefined,
        integretion_source: CustomerIntegrationSource | undefined,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<CustomerIntegrationResponse>>;

    getCustomerIntegrationById: (id: number) => Promise<CustomerIntegration>;

    createCustomerIntegration: (integration: AddCustomerIntegration) => Promise<any>;

    updateCustomerIntegration: (id: number, integration: EditCustomerIntegration) => Promise<any>;

    activateCustomerIntegration: (id: number) => Promise<any>;

    deactivateCustomerIntegration: (id: number) => Promise<any>;

    removeCourierFromStoreQueue: (
        idStore: number,
        idCourier: number
    ) => Promise<any>;

    addCredits: (
        customerId: number,
        description: string,
        value: number
    ) => Promise<any>;

    addCreditCustomer: (customerId: number, value: number, paymentMethod: FinancialEntryOrigin.BANK_SLIP | FinancialEntryOrigin.PIX) => Promise<any>;

    getCustomerBlockList: (customerId: number) => Promise<PagedResponse<CustomerCourierBlock>>;

    getCreditFinancialEntries: (
        customerId: number | null,
        createdAtAfter: Date | null,
        createdAtBefore: Date | null,
        paymentDateAfter: Date | null,
        paymentDateBefore: Date | null,
        origin: FinancialEntryOrigin | undefined,
        status: FinancialEntryStatus | undefined,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<CustomerFinancialEntryResponse>>;

    updateCreditLimit: (customerId: number, value: number) => Promise<any>;

    getCustomerFinancialOperations: (
        customerId: number,
        start_date: Date | null,
        end_date: Date | null,
        description: string | null,
        store_name: string | null,
        status: FinancialEntryStatus | null,
        type: CustomerFinancialOperationType | null,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<CustomerFinancialOperation>>;

    activateCustomer: (customerId: number) => Promise<any>;

    deactivateCustomer: (customerId: number) => Promise<any>;

    updateConcurrentDeliveries: (storeId: number, max_number_of_deliveries: number, max_number_of_deliveries_limit: number, use_priority_queue: boolean) => Promise<any>;

    setCustomerUserPassword: (courierId: number, password: string) => Promise<any>;

    reactivateUser: (id: number) => Promise<any>;

    deactivateUser: (id: number) => Promise<any>;

    getUsers: (params: { [any: string]: any }) => Promise<any>;

    createCustomerUser: (customerUser: ICustomerUser) => Promise<any>;

    updateCustomerUser: (customerUser: ICustomerUser) => Promise<any>;
    
    getCustomerUser: (id: number) => Promise<any>;

    getPlans: () => Promise<any>;

    createCustomer: (customer: ICreateCustomer) => Promise<any>;

    getBankSlipDocument: (financialEntryId: number) => Promise<string>;

    getNegativeCustomers: (date_after: Date, date_before: Date, regions: number[], pageParams: IPageableParams) => Promise<PagedResponse<NegativeCustomersResponse>>;

    getRechargesPerDay: ( regions: number[], date_after: Date, date_before: Date) => Promise<RechargesPerDayResponse[]>;

    getCustomersDebitNotes: (
        trade_name: string | undefined,
        corporate_name: string | undefined,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<CustomerResponse>>;

    getNotRechargingCustomers: (
        fiscal_address_city: number,
        date_after: Date,
        date_before: Date,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<NotRechargingCustomersResponse>>;

    getReportCustomersActive: (
        region: number | null,
        customer: number | null,
        date_after: Date,
        date_before: Date,
    ) => Promise<ReportCustomersActiveResponse[]>;

    exportReportCustomersActive: (
        region: number | null,
        customer: number | null,
        date_after: Date,
        date_before: Date,
    ) => Promise<any>;

}

const CustomerService: ICustomerService = {
    getCustomers: async (
        trade_name: string | undefined,
        email: string | undefined,
        phonenumber: string | undefined,
        registrationNumber: string | undefined,
        city: string | undefined,
        is_active : boolean | undefined,
        region: number | undefined,
        pageParams: IPageableParams
    ) => {
        const url = getURL(urls.CUSTOMER, {
            ...pageParams,
            trade_name: trade_name,
            email: email,
            phonenumber: phonenumber,
            cnpj: registrationNumber,
            city: city,
            is_active: is_active,
            region: region
        });
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then(response => {
                    const data: CustomerResponse[] = response.data.results.map((item: any) => {
                        return {
                            ...item,
                            is_active: convertToBoolean(item.is_active),
                        }
                    });
                    const result: PagedResponse<CustomerResponse> = {
                        data: data,
                        count: response.data.count,
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    getCustomer: (customerId: number) => {
        const url = `${urls.CUSTOMER}${customerId}/`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then(response => {
                    const stores: StoreResponse[] = response.data.stores.map((item: any) => {
                        return {
                            ...item,
                            created_at: convertToDate(item.created_at),
                            updated_at: convertToDate(item.updated_at),
                            latitude: convertToNumber(item.latitude),
                            longitude: convertToNumber(item.longitude),
                            is_active: convertToBoolean(item.is_active),
                            city: convertToNumber(item.city),
                            region: convertToNumber(item.region),
                            customer: convertToNumber(item.customer),
                            commercial_plan: convertToNumber(item.commercial_plan),
                            max_number_of_deliveries: convertToNumber(item.max_number_of_deliveries),
                            max_number_of_deliveries_limit: convertToNumber(item.max_number_of_deliveries_limit),
                            photo: item.photo
                        }
                    });
                    const result: CustomerDetails = {
                        ...response.data,
                        created_at: convertToDate(response.data.created_at),
                        updated_at: convertToDate(response.data.updated_at),
                        waste_limit: convertToNumber(response.data.waste_limit),
                        allow_incentive_dynamics: convertToBoolean(response.data.allow_incentive_dynamics),
                        skip_customer_actions: convertToBoolean(response.data.skip_customer_actions),
                        commercial_plan: convertToNumber(response.data.commercial_plan),
                        is_active: convertToBoolean(response.data.is_active),
                        stores: stores
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    updateCustomer: (customerId: number, customer: UpdateCustomer) => {
        return axios.put(`${urls.CUSTOMER}${customerId}/`, customer)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },

    getStores: (
        name: string | undefined,
        customerId: number | undefined,
        regionIds: number[] | undefined,
        isActive: boolean | undefined,
        pageParams: IPageableParams
    ) => {
        const url = getURL(urls.STORE, {
            ...pageParams,
            name: name,
            customer: customerId,
            region: regionIds,
            is_active: isActive
        });
        return new Promise((resolve, reject) => axios.get(url)
            .then((response) => {
                const data: StoreResponse[] = response.data.results.map((item: any) => {
                    return {
                        ...item,
                        created_at: convertToDate(item.created_at),
                        updated_at: convertToDate(item.updated_at),
                        latitude: convertToNumber(item.latitude),
                        longitude: convertToNumber(item.longitude),
                        is_active: convertToBoolean(item.is_active),
                        city: convertToNumber(item.city),
                        region: convertToNumber(item.region),
                        customer: convertToNumber(item.customer),
                        commercial_plan: convertToNumber(item.commercial_plan),
                        max_number_of_deliveries: convertToNumber(item.max_number_of_deliveries),
                        max_number_of_deliveries_limit: convertToNumber(item.max_number_of_deliveries_limit),
                        photo: item.photo,
                        use_priority_queue: convertToBoolean(item.use_priority_queue)
                    }
                });
                const result: PagedResponse<StoreResponse> = {
                    data: data,
                    count: response.data.count,
                };
                resolve(result);
            })
            .catch((error) => reject(error)));
    },

    getCustomerDeliveryStats: (customerId: number, storeId: number) => {
        return axios
            .post(`${urls.CUSTOMER}${customerId}/extra_details/`, {
                store: storeId === 0 ? null : storeId,
            })
            .then(response => {
                const result: CustomerDeliveryStats = {
                    ...response.data,
                    current_balance: convertToNumber(response.data.current_balance),
                    today_expenses: convertToNumber(response.data.today_expenses),
                    weekly_expenses: convertToNumber(response.data.weekly_expenses),
                    average_weekly_expenses: convertToNumber(response.data.average_weekly_expenses),
                    average_ticket: convertToNumber(response.data.average_ticket),
                    today_deliveries: convertToNumber(response.data.today_deliveries),
                    average_weekly_deliveries: convertToNumber(response.data.average_weekly_deliveries),
                    weekly_deliveries: convertToNumber(response.data.weekly_deliveries),
                    weekly_canceled_deliveries: convertToNumber(response.data.weekly_canceled_deliveries)
                }
                return Promise.resolve(result);
            })
            .catch(error => Promise.reject(error));
    },

    getStoreCourierQueue: async (idStore: number) => {
        return new Promise((resolve, reject) => {
            axios.get(`${urls.STORE}${idStore}/detail_queue/`)
                .then((response) => {
                    const result: CourierQueueItem[] = response.data.map((item: any) => {
                        return {
                            id: convertToNumber(item.id),
                            name: item.name,
                            picture: item.picture,
                            position: convertToNumber(item.position),
                            vehicle_load_compartment_type: item.vehicle_load_compartment_type,
                            vehicle_type: item.vehicle_type,
                            has_priority: convertToBoolean(item.has_priority),
                            courier_status: {
                                courier_id: convertToNumber(item.courier_status.courier_id),
                                courier_status: item.courier_status.courier_status,
                                created_at: convertToDate(item.courier_status.created_at),
                                delivery_request_id: convertToNumber(item.courier_status.delivery_request_id),
                                latitude: convertToNumber(item.courier_status.latitude),
                                longitude: convertToNumber(item.courier_status.longitude),
                                online: convertToBoolean(item.courier_status.online),
                                pk: item.courier_status.pk,
                                suspended: convertToBoolean(item.courier_status.suspended),
                                trip_id: convertToNumber(item.courier_status.trip_id),
                                updated_at: convertToDate(item.courier_status.updated_at),
                                _id: item.courier_status._id,
                            }
                        };
                    });
                    resolve(result);
                }).catch((error) => reject(error));
        });
    },

    getStore: (storeId: number) => {
        const url = `${urls.STORE}${storeId}/`;

        return axios.get(url)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },

    getStoreSchedule: (storeId: number) => {
        const url = `${urls.STORE_SCHEDULE}?store=${storeId}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    const result: CustomerStoreScheduleResponse[] = response.data.results.map((item: any) => {
                        return {
                            id: convertToNumber(item.id),
                            begin_time: item.begin_time,
                            day_of_week: convertToNumber(item.day_of_week),
                            end_time: item.end_time,
                            store: convertToNumber(item.store),
                        };
                    });
                    resolve(result);
                }).catch((error) => reject(error));
        });
    },

    createStore: (store: CreateUpdateStore) => {
        const formData = new FormData();
        const courierKeys = Object.keys(store);
        courierKeys.forEach((key) => {
          const rawValue = (store as any)[key];
          if (rawValue instanceof Blob) {
            formData.append(key, rawValue);
          } else if (rawValue instanceof Date) {
            formData.append(key, formatRFC3339(rawValue));
          } else if (rawValue instanceof String){
            formData.append(key, String((store as any)[key]));
          }else{
            formData.append(key, String((store as any)[key]));
          }
        });  

        return axios.post(urls.STORE, formData)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },

    updateStore: (storeId: number, store: CreateUpdateStore) => {
        let useFormData: boolean = true;
        const formData = new FormData();
        if(store.photo === undefined){
            store.photo = null;
        };
        const courierKeys = Object.keys(store);
        courierKeys.forEach((key) => {
          const rawValue = (store as any)[key];
          if (rawValue instanceof Blob) {
            formData.append(key, rawValue);
          } else if (rawValue instanceof Date) {
            formData.append(key, formatRFC3339(rawValue));
          } else if (rawValue instanceof String){
            formData.append(key, String((store as any)[key]));
          }else if (rawValue === null){
            formData.append(key, String((store as any)[key]));
          }else{
            formData.append(key, String((store as any)[key]));
          }
        });       
        if(store.photo == null){
            useFormData = false;
        };
        const url = `${urls.STORE}${storeId}/`;
        return new Promise((resolve, reject) => {
            axios.patch(url, useFormData ? formData : store)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });    
    },
    
    deleteStore: (storeId: number) => {
        const url = `${urls.STORE}${storeId}/`;
        return axios.delete(url)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },

    activateStore: (storeId: number) => {
        const url = `${urls.STORE}${storeId}/`;
        return new Promise((resolve, reject) => {
            axios
                .patch(url, { is_active: true })
                .then(response => resolve(response.data))
                .catch(error => {
                    reject(error);
                });
        });
    },

    deactivateStore: (storeId: number) => {
        const url = `${urls.STORE}${storeId}/`;
        return new Promise((resolve, reject) => {
            axios
                .patch(url, { is_active: false })
                .then(response => resolve(response.data))
                .catch(error => {
                    reject(error);
                });
        });
    },

    getCustomerIntegrations: async (
        customerId: number,
        storeId: number | undefined,
        integretion_source: CustomerIntegrationSource | undefined
    ) => {
        const url = getURL(urls.INTEGRATIONS, {
            customer: customerId,
            store: storeId,
            integretion_source: integretion_source
        });
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    const data: CustomerIntegrationResponse[] = response.data.results.map((item: any) => {
                        return {
                            ...item,
                            id: convertToNumber(item.id),
                            enabled: convertToBoolean(item.enabled),
                            authorized: convertToBoolean(item.authorized),
                            created_at: convertToDate(item.created_at)
                        };
                    });
                    const result: PagedResponse<CustomerIntegrationResponse> = {
                        data: data,
                        count: response.data.count,
                    };
                    resolve(result);
                }).catch((error) => reject(error));
        });
    },

    getCustomerIntegrationById: (id: number) => {
        const url = `${urls.INTEGRATIONS}${id}/`;
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    const result: CustomerIntegration = {
                        ...response.data,
                        id: convertToNumber(response.data.id),
                        enabled: convertToBoolean(response.data.enabled),
                        authorized: convertToBoolean(response.data.authorized),
                        created_at: convertToDate(response.data.created_at)
                    };
                    resolve(result);
                }).catch((error) => reject(error));
        });
    },

    createCustomerIntegration: (integration: AddCustomerIntegration) => {
        let result: Promise<any>;
        switch (integration.integration_source) {
            case CustomerIntegrationSource.IFOOD:
                let urlIfood = `${urls.STORE}${integration.store_id}/connect_ifood/`;
                result = new Promise((resolve, reject) => {
                    axios.post(urlIfood, {
                        name: integration.name,
                        initials: integration.initials,
                        key: integration.store_identifier
                    })
                        .then(response => resolve(response.data))
                        .catch(error => reject(error));
                });
                break;
            case CustomerIntegrationSource.DELIVERY_DIRETO:
                let urlDeliveryDireto = `${urls.STORE}${integration.store_id}/connect_delivery_direto/`;
                result = new Promise((resolve, reject) => {
                    axios.post(urlDeliveryDireto, {
                        name: integration.name,
                        initials: integration.initials,
                        key: integration.key,
                        x_deliverydireto_id: integration.x_deliverydireto_id,
                        delivery_direto_user: integration.delivery_direto_user,
                        delivery_direto_password: integration.delivery_direto_password,
                        password_confirmation: integration.password_confirmation
                    })
                        .then(response => resolve(response.data))
                        .catch(error => reject(error));
                });
                break;
            case CustomerIntegrationSource.MEU_XODO:
                let urlDeliveryMeuXodo = `${urls.STORE}${integration.store_id}/connect_meuxodo/`;
                result = new Promise((resolve, reject) => {
                    axios.post(urlDeliveryMeuXodo, {
                        name: integration.name,
                        initials: integration.initials,
                        key: integration.key,
                        external_email: integration.external_email,                       
                    })
                        .then(response => resolve(response.data))
                        .catch(error => reject(error));
                });
                break;
            case CustomerIntegrationSource.NEEMO:
                    let urlDeliveryNeemo = `${urls.STORE}${integration.store_id}/connect_neemo/`;
                    result = new Promise((resolve, reject) => {
                        axios.post(urlDeliveryNeemo, {
                            name: integration.name,
                            initials: integration.initials,
                            key: integration.key,
                        })
                            .then(response => resolve(response.data))
                            .catch(error => reject(error));
                    });
                    break;                        
            default:
                result = new Promise((resolve, reject) => {
                    reject({ errorMsg: "Integração não suportada" });
                });
                break;
        }
        return result;
    },

    updateCustomerIntegration: (id: number, integration: EditCustomerIntegration) => {
        const url = `${urls.INTEGRATIONS}${id}/`;
        return new Promise((resolve, reject) => {
            axios
                .patch(url, integration)
                .then(response => resolve(response.data))
                .catch(error => {
                    reject(error);
                });
        });
    },

    activateCustomerIntegration: (id: number) => {
        const url = `${urls.INTEGRATIONS}${id}/`;
        return new Promise((resolve, reject) => {
            axios
                .patch(url, { enabled: true })
                .then(response => resolve(response.data))
                .catch(error => {
                    reject(error);
                });
        });
    },

    deactivateCustomerIntegration: (id: number) => {
        const url = `${urls.INTEGRATIONS}${id}/`;
        return new Promise((resolve, reject) => {
            axios
                .patch(url, { enabled: false })
                .then(response => resolve(response.data))
                .catch(error => {
                    reject(error);
                });
        });
    },

    removeCourierFromStoreQueue: async (idStore: number, idCourier: number) => {
        return axios.put(`${urls.STORE}${idStore}/remove_from_queue/`, {
            courier: idCourier,
        });
    },

    exportCustomerOperations: async (
        customerId: number,
        start_date: Date | null,
        end_date: Date | null,
        description: string | null,
        store_name: string | null,
        status: FinancialEntryStatus | null,
        type: CustomerFinancialOperationType | null,
    ) => {
        const removeEmpty = (obj: any) => {
            Object.keys(obj).forEach(key => {
                if (obj[key] == null || obj[key] === "") {
                    delete obj[key];
                }
            });
        };

        const filter = {
            customer: customerId,
            start_date: start_date ? formatISO(start_date, { representation: "date" }) : null,
            end_date: end_date ? formatISO(end_date, { representation: "date" }) : null,
            description: description,
            store_name: store_name,
            status: status,
            type: type
        }
        removeEmpty(filter);

        return axios.post(
            `${urls.CUSTOMER_FINANCE}export_customer_operations/`,
            {
                ...filter,
            },
            {
                responseType: "blob",
            }
        );
    },

    addCredits: async (
        customerId: number,
        description: string,
        value: number
    ) => {
        return axios
            .post(`${urls.CUSTOMER_FINANCE}add_credits/`, {
                customer: customerId,
                description: description,
                value: value,
            })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    },

    addCreditCustomer: async (customerId: number, value: number, paymentMethod: FinancialEntryOrigin.BANK_SLIP | FinancialEntryOrigin.PIX) => {
        let url = urls.CUSTOMER_FINANCES_PAY_CREDITS;
        if (paymentMethod === FinancialEntryOrigin.PIX) {
          url = urls.CUSTOMER_FINANCES_PAY_CREDITS_PIX;
        }
    
        return new Promise((resolve, reject) => {
          axios.post(url, {
            customer: customerId,
            value: value
          })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
        });
      },

    getCustomerBlockList: (customerId: number) => {
        const url = `${urls.CUSTOMER}${customerId}/blocklist_records/`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then(response => {
                    const data: CustomerCourierBlock[] = response.data.map((item: any) => {
                        const courier: BlockedCourier = {
                            ...item.courier,
                            ranking_points: convertToNumber(item.courier.ranking_points)
                        };

                        return {
                            ...item,
                            all_customers: convertToBoolean(item.all_customers),
                            courier: courier,
                            expired: convertToBoolean(item.expired),
                            duration: convertToNumber(item.duration),
                            undefined_time: convertToBoolean(item.undefined_time),
                            created_at: convertToDate(item.created_at)
                        }
                    });

                    const result: PagedResponse<CustomerCourierBlock> = {
                        data: data,
                        count: data.length
                    };

                    resolve(result);
                })
                .catch(error => reject(error));
        })
    },

    getCreditFinancialEntries: (
        customerId: number | null,
        createdAtAfter: Date | null,
        createdAtBefore: Date | null,
        paymentDateAfter: Date | null,
        paymentDateBefore: Date | null,
        origin: FinancialEntryOrigin | undefined,
        status: FinancialEntryStatus | undefined,
        pageParams: IPageableParams
    ) => {
        const url = getURL(`${urls.CUSTOMER_FINANCE}entries/`, {
            ...pageParams,
            type: "CREDIT",
            customer: customerId,
            created_at_after: createdAtAfter
                ? formatRFC3339(createdAtAfter)
                : null,
            created_at_before: createdAtBefore
                ? formatRFC3339(createdAtBefore)
                : null,
            payment_date_after: paymentDateAfter
                ? formatISO(paymentDateAfter, { representation: "date" })
                : null,
            payment_date_before: paymentDateBefore
                ? formatISO(paymentDateBefore, { representation: "date" })
                : null,
            origin: origin,
            status: status
        });
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then(response => {
                    const data: CustomerFinancialEntryResponse[] = response.data.results.map(
                        (item: any) => {
                            return {
                                ...item,
                                id: Number(item.id),
                                created_at: parseISO(item.created_at),
                                payment_date: item.payment_date
                                    ? parseISO(item.payment_date)
                                    : null,
                                value: Number(item.value),
                                tax: Number(item.tax),
                                total: Number(item.total),
                            };
                        }
                    );
                    const result: PagedResponse<CustomerFinancialEntryResponse> = {
                        data: data,
                        count: response.data.count,
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    updateCreditLimit: (customerId: number, value: number) => {
        return new Promise((resolve, reject) => {
            axios
                .put(`${urls.CUSTOMER}${customerId}/update_customer_limit/`, {
                    waste_limit: value || 0,
                })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    },

    getCustomerFinancialOperations: (
        customerId: number,
        start_date: Date | null,
        end_date: Date | null,
        description: string | null,
        store_name: string | null,
        status: FinancialEntryStatus | null,
        type: CustomerFinancialOperationType | null,
        pageParams: IPageableParams
    ) => {
        const url = `${urls.CUSTOMER_FINANCE}customer_operations/?page=${pageParams.page}&page_size=${pageParams.page_size}`;
        return new Promise((resolve, reject) => {
            axios.post(url, {
                ordering: pageParams.ordering,
                customer: customerId,
                start_date: start_date ? formatISO(start_date, { representation: "date" }) : null,
                end_date: end_date ? formatISO(end_date, { representation: "date" }) : null,
                description: description ? description : undefined,
                store_name: store_name ? store_name : undefined,
                status: status ? status : undefined,
                type: type ? type : undefined
            }).then((response) => {
                const data: CustomerFinancialOperation[] = response.data.results.map(
                    (item: any) => {
                        return {
                            ...item,
                            id: Number(item.id),
                            created_at: convertToDate(item.created_at),
                            updated_at: convertToDate(item.updated_at),
                            courier_value: convertToNumber(item.courier_value),
                            speedy_value: convertToNumber(item.speedy_value),
                            value: convertToNumber(item.value)
                        };
                    }
                );
                const result: PagedResponse<CustomerFinancialOperation> = {
                    data: data,
                    count: response.data.count
                }
                resolve(result);
            }).catch((error) => reject(error));
        })
    },

    activateCustomer: (customerId: number) => {
        const url = `${urls.CUSTOMER}${customerId}/`;
        return axios.patch(url, { is_active: true });
    },

    deactivateCustomer: (customerId: number) => {
        const url = `${urls.CUSTOMER}${customerId}/`;
        return axios.patch(url, { is_active: false });
    },

    updateConcurrentDeliveries: (storeId: number, max_of_deliveries: number, max_of_deliveries_limit: number, use_priority_queue: boolean) => {
        const url = `${urls.STORE}${storeId}/`;
        return axios.patch(url, {
            max_number_of_deliveries: max_of_deliveries, 
            max_number_of_deliveries_limit: max_of_deliveries_limit, 
            use_priority_queue: use_priority_queue
        });
    },

    setCustomerUserPassword: (userId: number, password: string) => {
        return axios.patch(`${urls.CUSTOMER_USER}${userId}/`, {
          password: password,
          password_confirmation: password,
        });
      },

    reactivateUser: (id: number) => {
        const url = `${urls.CUSTOMER_USER}${id}/reactivate/`;
        return axios.post(url, {});
    },

    deactivateUser: (id: number) => {
        const url = `${urls.CUSTOMER_USER}${id}/deactivate/`;
        return axios.post(url, {});
    },

    getUsers: (params: { [any: string]: any }) => {
        const url = getURL(urls.CUSTOMER_USER, params);
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => {
                    resolve(response.data)})
                .catch(error => reject(error));
        });
      },

    createCustomerUser: (customerUser: ICustomerUser) => {
        return new Promise((resolve, reject) => {
            axios.post(urls.CUSTOMER_USER, {...customerUser, phonenumber: customerUser.phonenumber === "" ? null : customerUser.phonenumber})
                .then((response) => {
                    const user = response.data;
                    resolve(user);               
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    
    updateCustomerUser: (customerUser: ICustomerUser) => {
        return new Promise((resolve, reject) => {
            axios.patch(`${urls.CUSTOMER_USER}${customerUser.id}/`, {...customerUser, phonenumber: customerUser.phonenumber === "" ? null : customerUser.phonenumber})
            .then((response) => {
                const user = response.data;
                resolve(user);               
            })
            .catch((error) => {
                reject(error);
            });
        });
    },

    getCustomerUser: (id: number) =>{
        return new Promise((resolve, reject) => {
            axios.get(`${urls.CUSTOMER_USER}${id}/`)
            .then((response) => {        
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },

    getPlans: () =>{
        return new Promise((resolve, reject) => {
            axios.get(`${urls.COMMERCIAL_PLAN}`)
            .then((response) => {        
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },  

    createCustomer: (customer: ICreateCustomer) => {
        return new Promise((resolve, reject) => {
            axios.post(urls.CUSTOMER, customer)
            .then((response) => {        
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },

    getBankSlipDocument: (financialEntryId: number) => {
        return new Promise((resolve, reject) => {
          const url = `${urls.CUSTOMER_FINANCES_ENTRIES}${financialEntryId}/bank_slip/`;
          axios.get(url)
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
        });
    },

    getNegativeCustomers: (date_after: Date, date_before: Date, regions: number[], pageParams: IPageableParams) => {
        const url = getURL(`${urls.CUSTOMER}negative_customers/`, {
            ...pageParams,
            date_after: formatRFC3339(date_after),
            date_before: formatRFC3339(date_before),
            regions: regions,
        });
        return new Promise((resolve, reject) => {
          axios.get(url)
            .then((response) => {
                const data: NegativeCustomersResponse[] = response.data.results.map(
                    (item: any) => {
                        return {
                            ...item,
                            id: convertToNumber(item.id),
                            waste_limit: convertToNumber(item.waste_limit),
                            current_balance: convertToNumber(item.current_balance),
                        };
                    }
                );
                const result: PagedResponse<NegativeCustomersResponse> = {
                    data: data,
                    count: response.data.count,
                };
                resolve(result);
            })
            .catch((error) => reject(error));
        });
    },

    getRechargesPerDay: (
        regions: number[],
        date_after: Date,
        date_before: Date
    ) => {
        const url = getURL(`${urls.CUSTOMER_FINANCES}entries/recharges_per_day/`, {
            payment_date_after: formatISO(date_after, { representation: "date" }),
            payment_date_before: formatISO(date_before, { representation: "date" }),
            regions: regions
        });
        return new Promise((resolve, reject) => {
            axios
              .get(url).then((response) => {
                const data: RechargesPerDayResponse[] = response.data.map((item: any) => {                   
                    return {
                        ...item,
                        date: convertToDate(item.date),
                        total: convertToNumber(item.total)
                    };
                });
                resolve(data);
              }).catch((error) => reject(error));
          });
    },

    getCustomersDebitNotes: async (
        trade_name: string | undefined,
        corporate_name: string | undefined,
        pageParams: IPageableParams
    ) => {
        const url = getURL(urls.CUSTOMER, {
            ...pageParams,
            trade_name: trade_name,
            corporate_name: corporate_name
        });
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then(response => {
                    const data: CustomerResponse[] = response.data.results.map((item: any) => {
                        return {
                            ...item,
                            is_active: convertToBoolean(item.is_active),
                        }
                    });
                    const result: PagedResponse<CustomerResponse> = {
                        data: data,
                        count: response.data.count,
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    getNotRechargingCustomers: (
        fiscal_address_city: number,
        date_after: Date,
        date_before: Date,
        pageParams: IPageableParams
    ) => {
        const url = getURL(`${urls.CUSTOMER}not_recharging_customers/`, {
            ...pageParams,
            date_after: formatISO(date_after, { representation: "date" }),
            date_before: formatISO(date_before, { representation: "date" }),
            fiscal_address_city: fiscal_address_city
        });
        return new Promise((resolve, reject) => {
            axios
              .get(url).then((response) => {
                const data: NotRechargingCustomersResponse[] = response.data.results.map((item: any) => {                   
                    return {
                        ...item,
                        current_balance: convertToNumber(item.current_balance)
                    };
                });
                const result: PagedResponse<NotRechargingCustomersResponse> = {
                    data: data,
                    count: response.data.count,
                };
                resolve(result);
              }).catch((error) => reject(error));
          });
    },

    getReportCustomersActive: (
        region: number | null,
        customer: number | null,
        date_after: Date,
        date_before: Date,
      ) => {
        const url = getURL(`${urls.CUSTOMER}active_customers_report/`, {
            month: date_after.getMonth() + 1,
            year: date_before.getFullYear(),
            region: region,
            customer: customer
        });
        return new Promise((resolve, reject) => {
            axios
            .get(url).then((response) => {
                const data: ReportCustomersActiveResponse[] = response.data.map((item: ReportCustomersActiveResponse) => {   
                    const storesList: StoresCustomersActiveResponse[] = item.stores.map((store: any) => {
                        return {
                            ...store,
                            customer_id: convertToNumber(store.customer_id),
                            deliveries_count: convertToNumber(store.deliveries_count),
                            recharges_value: convertToNumber(store.recharges_value),
                            speedy_value: convertToNumber(store.speedy_value),
                            store_id: convertToNumber(store.store_id),
                        }
                    });

                    return {
                        ...item,
                        total_quantity_delivery: 0,
                        total_value_recharge: 0,
                        total_speedy_value: 0, 
                        stores: storesList
                    };
                });
                resolve(data);
            }).catch((error) => reject(error));
        });
    },
    exportReportCustomersActive: async (
        region: number | null,
        customer: number | null,
        date_after: Date,
        date_before: Date,
    ) => {
        const url = getURL(`${urls.CUSTOMER}active_customers_report/export/`, {
            month: date_after.getMonth() + 1,
            year: date_before.getFullYear(),
            region: region,
            customer: customer
        });

        return axios.get(url,
            {
                responseType: "blob",
            }
        );
    },

};

export default CustomerService;
