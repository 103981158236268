import { formatISO, parseISO } from "date-fns";
import { apiAxios as axios } from "../store/api/config";
import { getURL, urls } from "../store/api/urls";
import { convertToDate } from "../utils/utils";
import { IPageableParams, PagedResponse } from "./types/common";
import { RemittanceResponse, RemittanceStatus } from "./types/remittance";

export interface IRemittanceService {
    getRemittances: (
        number: string,
        sentDate: Date | null,
        liquidateDate: Date | null,
        status: RemittanceStatus | null,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<RemittanceResponse>>;

    getRemittance: (remittanceId: number) => Promise<any>;

    getTransaction: (transactionId: number) => Promise<any>;

    downloadRemittanceXLSX: (remittanceId: number) => Promise<any>;

    sendRemittance: (remittanceId: number) => Promise<any>;

    setRemittanceAsSent: (remittanceId: number, sentDate: Date) => Promise<any>;

    uploadRemittanceResponseFile: (
        remittanceId: number,
        fileContent: Blob
    ) => Promise<any>;

    duplicateRemittance: (remittanceId: number) => Promise<RemittanceResponse>;

    singleRemittance: (couriers: number[], anticipation_fee: number) => Promise<any>;

    deleteRemittance: (remittanceId: number) => Promise<any>;

}

const RemittanceService: IRemittanceService = {
    getRemittances: async (
        number: string,
        sentDate: Date | null,
        liquidateDate: Date | null,
        status: RemittanceStatus | null,
        pageParams: IPageableParams
    ) => {
        let sent_date = sentDate ? formatISO(sentDate, { representation: 'date' }) : null;
        let liquidate_date = liquidateDate ? formatISO(liquidateDate, { representation: 'date' }) : null;

        const url = getURL(urls.REMITTANCE, {
            ...pageParams,
            number: number,
            sent_date: sent_date,
            liquidate_date: liquidate_date,
            status: status,
        });
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then(response => {
                    const data: RemittanceResponse[] = response.data.results.map((item: any) => {
                        return {
                            ...item,
                            id: Number(item.id),
                            sent_date: convertToDate(item.sent_date),
                            liquidate_date: convertToDate(item.liquidate_date),
                            transactions_count: Number(item.transactions_count),
                            value: Number(item.value),
                            taxes: Number(item.taxes),
                            total: Number(item.total),
                            paid_value: Number(item.paid_value)
                        };
                    });
                    const result: PagedResponse<RemittanceResponse> = {
                        data: data,
                        count: response.data.count,
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    getRemittance: async (remittanceId: number) => {
        const url = `${urls.REMITTANCE}${remittanceId}/`;

        return axios.get(url);
    },

    getTransaction: async (transactionId: number) => {
        const url = `${urls.TRANSACTION}${transactionId}/`;

        return axios.get(url);
    },

    downloadRemittanceXLSX: async (remittanceId: number) => {
        const url = `${urls.REMITTANCE}${remittanceId}/get_xlsx/`;

        return axios.get(url, {
            responseType: "blob",
        });
    },

    sendRemittance: (remittanceId: number) => {
        const url = `${urls.REMITTANCE}${remittanceId}/send_remittance/`;

        return axios.post(url, {});
    },

    setRemittanceAsSent: async (remittanceId: number, sentDate: Date) => {
        const url = `${urls.REMITTANCE}${remittanceId}/set_remittance_as_sent/`;

        return axios.put(url, {
            sent_date: formatISO(sentDate, { representation: "date" }),
        });
    },

    uploadRemittanceResponseFile: async (
        remittanceId: number,
        fileContent: Blob
    ) => {
        const url = `${urls.REMITTANCE}${remittanceId}/finalize_remittance/`;

        const formData = new FormData();
        formData.append("transactions_file", fileContent);

        return axios.post(url, formData);
    },

    duplicateRemittance: (remittanceId: number) => {
        const url = `${urls.REMITTANCE}${remittanceId}/duplicate_remittance/`;

        return new Promise((resolve, reject) => {
            axios
                .post(url)
                .then(response => {
                    const result: RemittanceResponse = {
                        ...response.data,
                        id: Number(response.data.id),
                        sent_date: response.data.sent_date
                            ? parseISO(response.data.sent_date)
                            : null,
                        liquidate_date: response.data.liquidate_date
                            ? parseISO(response.data.liquidate_date)
                            : null,
                        transactions_count: Number(
                            response.data.transactions_count
                        ),
                        value: Number(response.data.value),
                        taxes: Number(response.data.taxes),
                        total: Number(response.data.total),
                        paid_value: Number(response.data.paid_value),
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    singleRemittance: (couriers: number[], anticipation_fee: number) => {
        const url = `${urls.REMITTANCE}single_remittance/`;
        return new Promise((resolve, reject) => {
            axios
                .post(url, {"couriers": couriers, "anticipation_fee": anticipation_fee})
                .then(response => {
                    resolve(response);
                })
                .catch(error => reject(error));
        });
    },

    deleteRemittance: (id: number) => {
        const url = `${urls.REMITTANCE}${id}/`;
        return axios.delete(url)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },
};

export default RemittanceService;
