import { Color } from "@material-ui/lab/Alert";
import { CourierAccountStatus } from "../../services/types/courier";

export const mapCourierAccountStatusToSeverity = (
    user_status: CourierAccountStatus
): Color => {
    switch (user_status) {
        case CourierAccountStatus.ACTIVE:
            return "success";
        case CourierAccountStatus.SUSP_INACTIVE:
        case CourierAccountStatus.SUSP_DECLINE:
            return "info";
        case CourierAccountStatus.BANNED:
            return "error";
        default:
            return "warning";
    }
};

