import React from "react";
import { FormikHelpers, useFormik } from "formik";
import { Grid, TextField, Button, debounce } from "@material-ui/core";
import { DEFAULT_LOCALE } from "../../i18n";
import DatePicker from "react-datepicker";
import { CustomerResponse } from "../../services/types/customer";
import { Autocomplete } from "@material-ui/lab";
import CustomerService from "../../services/customerService";
import { PageableParams } from "../../components/TableTrinkets";
import { useHistory } from "react-router";
import { encodeURLParams, URLParamType } from "../../utils/utils";
import ShowIf from "../../components/ShowIf";

export interface IDebitNoteFilterParams {
    customerId: number | null;
    date_after: Date | null,
    date_before: Date | null,
}

export const INITIAL_FILTER_PARAMS: IDebitNoteFilterParams = {
    customerId: null,
    date_after: null,
    date_before: null
};

interface IDebitNoteFilterProps {
    params?: IDebitNoteFilterParams;
    customerId: number | null;
    onFilterChanged: (params: IDebitNoteFilterParams) => void;
}

export const DebitNoteFilter: React.FC<IDebitNoteFilterProps> = ({
    params = INITIAL_FILTER_PARAMS,
    customerId,
    onFilterChanged,
}) => {
    const [customerList, setCustomerList] = React.useState<CustomerResponse[]>([]);
    const [selectedCustomerCorporate, setSelectedCustomerCorporate] = React.useState<CustomerResponse | undefined>();
    const [selectedCustomerTrade, setSelectedCustomerTrade] = React.useState<CustomerResponse | undefined>();
    const [searchValueCorporate, setSearchValueCorporate] = React.useState<string>("");
    const [searchValueTrade, setSearchValueTrade] = React.useState<string>("");
    const history = useHistory();

    React.useEffect(() => {
        history.replace(encodeURLParams(history.location.pathname, params, [
            { "date_after": URLParamType.DATE },
            { "date_before": URLParamType.DATE }
        ]));
    }, [history, params]);

    const formik = useFormik<IDebitNoteFilterParams>({
        initialValues: params,
        onSubmit: (values: IDebitNoteFilterParams, formikHelpers: FormikHelpers<IDebitNoteFilterParams>) => {
            onFilterChanged(values);
        },
        onReset: (values: IDebitNoteFilterParams, formikHelpers: FormikHelpers<IDebitNoteFilterParams>) => {
            onFilterChanged(INITIAL_FILTER_PARAMS);
            formikHelpers.setValues(INITIAL_FILTER_PARAMS);
            setSelectedCustomerCorporate(undefined);
            setSelectedCustomerTrade(undefined);
            setSearchValueCorporate("");
            setSearchValueTrade("");
            setCustomerList([]);
        },
    });

    const loadCustomersCorporate = React.useCallback((corporate_name: string | undefined) => {
        CustomerService.getCustomersDebitNotes(undefined, corporate_name, new PageableParams(1, 25, "corporate_name"))
            .then((response) => {
                setCustomerList(response.data);
            });
    }, []);

    const loadCustomersTrade = React.useCallback((trade_name: string | undefined) => {
        CustomerService.getCustomersDebitNotes(trade_name, undefined, new PageableParams(1, 25, "trade_name"))
            .then((response) => {
                setCustomerList(response.data);
            });
    }, []);

    const searchCustomerCorporate = React.useMemo(() => debounce((corporate_name: string) => {
        if (corporate_name?.trim().length > 2) {
            loadCustomersCorporate(corporate_name);
        }
    }, 300), [loadCustomersCorporate]);

    const searchCustomerTrade = React.useMemo(() => debounce((trade_name: string) => {
        if (trade_name?.trim().length > 2) {
            loadCustomersTrade(trade_name);
        }
    }, 300), [loadCustomersTrade]);

    return (
        <div className="page-filter">
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Grid container spacing={2}>
                    <ShowIf condition={customerId == null}>
                        <Grid item md={3}>
                            <Autocomplete
                                id="customerId"
                                size="small"
                                value={selectedCustomerCorporate}
                                inputValue={searchValueCorporate}
                                options={customerList}
                                getOptionLabel={(customer: CustomerResponse) => customer.corporate_name}
                                loadingText="Aguarde ..."
                                noOptionsText="Sem opções"
                                onChange={(e, newValue) => {
                                    if (newValue) {
                                        setSelectedCustomerCorporate(newValue);
                                        formik.setFieldValue("customerId", newValue.id);
                                    } else {
                                        setSelectedCustomerCorporate(undefined);
                                        formik.setFieldValue("customerId", "");
                                    }
                                }}
                                onInputChange={(e: object, value: string, reason: string) => {
                                    setSearchValueCorporate(value);
                                    if (reason === "input") {
                                        searchCustomerCorporate(value);
                                    }
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Cliente"
                                        variant="outlined"
                                        error={!!formik.errors.customerId}
                                        helperText={formik.errors.customerId || "Digite pelo menos 3 caracteres para realizar a busca"}
                                        fullWidth
                                    />
                                }
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                id="customerId"
                                size="small"
                                value={selectedCustomerTrade}
                                inputValue={searchValueTrade}
                                options={customerList}
                                getOptionLabel={(customer: CustomerResponse) => customer.trade_name}
                                loadingText="Aguarde ..."
                                noOptionsText="Sem opções"
                                onChange={(e, newValue) => {
                                    if (newValue) {
                                        setSelectedCustomerTrade(newValue);
                                        formik.setFieldValue("customerId", newValue.id);
                                    } else {
                                        setSelectedCustomerTrade(undefined);
                                        formik.setFieldValue("customerId", "");
                                    }
                                }}
                                onInputChange={(e: object, value: string, reason: string) => {
                                    setSearchValueTrade(value);
                                    if (reason === "input") {
                                        searchCustomerTrade(value);
                                    }
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Nome Fantasia"
                                        variant="outlined"
                                        error={!!formik.errors.customerId}
                                        helperText={formik.errors.customerId || "Digite pelo menos 3 caracteres para realizar a busca"}
                                        fullWidth
                                    />
                                }
                            />
                        </Grid>
                    </ShowIf>

                    <Grid item xl={2} md={3}>
                        <DatePicker
                            id="date_after"
                            name="date_after"
                            onChange={val => {
                                formik.setFieldValue("date_after", val);
                            }}
                            maxDate={new Date()}
                            selected={formik.values.date_after}
                            startDate={formik.values.date_after}
                            endDate={formik.values.date_before}
                            selectsStart
                            locale={DEFAULT_LOCALE}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            isClearable
                            autoComplete="off"
                            wrapperClassName="MuiFormControl-fullWidth"
                            customInput={<TextField
                                label="Mês e ano de Referência"
                                variant="outlined"
                                size="small"
                                error={!!formik.errors.date_after}
                                helperText={formik.errors.date_after}
                                fullWidth />}
                        />
                    </Grid>

                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Pesquisar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="reset"
                        >
                            Limpar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
