import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useHistory } from 'react-router-dom'
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { Link } from "react-router-dom";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";

import { INITIAL_FILTER_PARAMS, IRegionFilterParams, RegionFilter } from "./RegionFilter";
import RegionService from "../../services/regionService";
import CityService from "../../services/cityService";
import { DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { RegionResponse } from "../../services/types/region";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { City } from "../../services/types/common";
import { formatPhoneNumber } from "../../utils/formatters";
import AuthService from "../../services/authService";
import ShowIf from "../../components/ShowIf";
import { decodeURLParams } from "../../utils/utils";

export const Regions = () => {
  const history = useHistory();
  const [cityList, setCityList] = useState<City[]>([]);
  const [filterParams, setFilterParams] = useState<IRegionFilterParams>({
    ...INITIAL_FILTER_PARAMS,
    ...decodeURLParams(history.location.search)
  });

  const tableRef = React.useRef<MaterialTableProps<RegionResponse>>();

  const loadData = (query: Query<RegionResponse>) =>
    new Promise<QueryResult<RegionResponse>>((resolve, reject) => {
      RegionService.getRegions(filterParams.name, filterParams.state,
        filterParams.metropolitanArea, PageableParams.fromQuery(query))
        .then(response => {
          resolve({
            data: response.data,
            page: query.page,
            totalCount: response.count,
          })
        })
    });

  const applyFilter = (params: IRegionFilterParams) => {
    setFilterParams(params);
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({ page: 0 } as Query<RegionResponse>);
    }
  }

  useEffect(() => {
    const getCities = async () => {
      const response = await CityService.getCities();
      setCityList(response);
    };

    getCities();
  }, []);

  const showDetails = (item: RegionResponse) => {
    history.push(`region/detail/${item.id}`)
  };

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Regiões</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[{ label: "Regiões", url: "/region" }]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <ShowIf condition={AuthService.hasAuthorization("add_region")}>
            <Button color="primary" variant="contained"
              startIcon={<AddCircleOutlineRoundedIcon />}
              component={Link} to="/region/new">
              Nova Região
            </Button>
          </ShowIf>
        </Grid>
      </Grid>

      <RegionFilter params={filterParams} cityList={cityList} onFilterChanged={applyFilter} />

      <MaterialTable
        tableRef={tableRef}
        columns={[
          { field: "id", hidden: true, width: 60 },
          { field: "name", title: "Nome", defaultSort: "asc" },
          {
            field: "support_phone", title: "Telefone do Suporte", emptyValue: "---",
            render: (rowData, type) => formatPhoneNumber(rowData.support_phone)
          },
          {
            field: "metropolitan_area", title: "Área Metropolitana (Cidade)",
            render: (rowData, type) => `${rowData.metropolitan_area} - ${rowData.state}`
          },
        ]}
        data={loadData}
        actions={[
          {
            icon: () => <VisibilityOutlinedIcon />,
            tooltip: 'Ver Detalhes',
            onClick: (event, rowData) => showDetails(rowData as RegionResponse)
          }
        ]}
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_L10N_PTBR}
        options={DEFAULT_TABLE_OPTIONS}
      />
    </div>
  );
};