import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    Typography,
    Avatar,
    Tooltip,
} from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CustomerService from "../../../services/customerService";
import { CourierQueueItem, StoreResponse } from "../../../services/types/customer";
import { DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";
import { CustomBreadcrumbs } from "../../../components/CustomBreadcrumbs";
import MaterialTable from "@material-table/core";
import { ImgLoader } from "../../../components/ImgLoader";
import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import { decodeURLParams, distance } from "../../../utils/utils";
import { useHistory } from "react-router";
import { INITIAL_FILTER_PARAMS, IStoreCourierQueueFilterParams, StoreCourierQueueFilter } from "./StoreCourierQueueFilter";
import { RegionResponse } from "../../../services/types/region";
import RegionService from "../../../services/regionService";
import CheckCircle from "@material-ui/icons/CheckCircle";

const TABLE_LOCALIZATION = {
    ...TABLE_L10N_PTBR,
    body: {
        ...TABLE_L10N_PTBR.body,
        emptyDataSourceMessage: "Selecione uma Loja para ver sua fila"
    }
}

export const StoreCourierQueue: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [storeQueue, setStoreQueue] = useState<CourierQueueItem[]>([]);
    const [filterParams, setFilterParams] = useState<IStoreCourierQueueFilterParams>({
        ...INITIAL_FILTER_PARAMS,
        ...decodeURLParams(history.location.search)
    });
    const [regionList, setRegionList] = React.useState<RegionResponse[]>([]);
    const [storeList, setStoreList] = React.useState<StoreResponse[]>([]);
    const [selectedStore, setSelectedStore] = React.useState<StoreResponse>();

    const loadRegionStores = React.useCallback(async (regionId: number | ""): Promise<StoreResponse[]> => {
        if (regionId !== "") {
            try {
                const response = await CustomerService.getStores("", undefined, [regionId], true, new PageableParams(1, 500, "name"));
                setStoreList(response.data);
                return Promise.resolve(response.data);
            } catch (error) {
                console.error(error);
                return Promise.reject(error);
            }
        } else {
            setStoreList([]);
            return Promise.resolve([]);
        }
    }, []);

    const refreshStoreQueue = React.useCallback((storeId: number | undefined | "") => {
        if ((storeId !== undefined) && (storeId !== "")) {
            CustomerService.getStoreCourierQueue(storeId)
                .then(response => {
                    setStoreQueue(response);
                    if(response.length === 0){
                        TABLE_LOCALIZATION.body.emptyDataSourceMessage = "Nenhum Entregador na fila";
                    }
                })
                .catch(error => console.error(error));
        } else {
            TABLE_LOCALIZATION.body.emptyDataSourceMessage = "Selecione uma Região e uma Loja para ver sua fila";
            setStoreQueue([]);
        }
    }, []);

    const removeCourierFromQueue = async (courier: CourierQueueItem) => {
        if (filterParams.storeId !== "") {
            const storeId: number = filterParams.storeId;
            await CustomerService.removeCourierFromStoreQueue(storeId, courier.id)
                .then(response => {
                    refreshStoreQueue(storeId);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            refreshStoreQueue(selectedStore?.id);
        }, 20000);

        return function cleanup() {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [refreshStoreQueue, selectedStore]);

    React.useEffect(() => {
        const getRegions = async () => {
            const response = await RegionService.loadAllRegions({ ordering: "name" });
            setRegionList(response);
        };
        getRegions();
    }, []);

    const applyFilter = React.useCallback(async (params: IStoreCourierQueueFilterParams) => {
        setFilterParams(params);
    }, []);

    const refresh = React.useCallback(() => {
        refreshStoreQueue(selectedStore?.id);
    },[refreshStoreQueue, selectedStore]);

    React.useEffect(() => {
        loadRegionStores(filterParams.regionId);
        setSelectedStore(undefined);
        if (filterParams.regionId === "") {
            TABLE_LOCALIZATION.body.emptyDataSourceMessage = "Selecione uma Região e uma Loja para ver sua fila";
        }
    }, [filterParams.regionId, loadRegionStores]);

    React.useEffect(() => {
        if (filterParams.storeId !== "") {
            TABLE_LOCALIZATION.body.emptyDataSourceMessage = "Nenhum Entregador na fila";
            let selectedStore: StoreResponse | undefined = undefined;
            for (let i = 0; i < storeList.length; i++) {
                if (storeList[i].id === filterParams.storeId) {
                    selectedStore = storeList[i];
                    break;
                }
            }
            setSelectedStore(selectedStore);
        } else {
            TABLE_LOCALIZATION.body.emptyDataSourceMessage = "Selecione uma Loja para ver sua fila";
            setSelectedStore(undefined);
        }
    }, [filterParams.storeId, storeList])

    React.useEffect(() => {
        refresh()
    }, [selectedStore, refresh]);

    return (
        <React.Fragment>
            <Grid container justify="space-between" alignItems="center" className="page-title">
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">
                            Fila de Entregadores
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[
                                { label: "Filas", url: "/storeQueue" },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <StoreCourierQueueFilter
                regionList={regionList}
                storeList={storeList}
                params={filterParams}
                onRefresh={refresh}
                onFilterChanged={applyFilter} />

            <Grid container direction="row" spacing={2}>
                <Grid item sm={12}>
                    <MaterialTable
                        columns={[
                            { field: "id", hidden: true, width: 60 },
                            {
                                field: "position", title: "#", defaultSort: "asc", type: "numeric", width: "40px"
                            },
                            {
                                field: "picture", title: "Foto", width: "60px",
                                render: (rowData, type) => <Avatar alt={rowData.name}><ImgLoader src={rowData.picture} width={40} height={40} /></Avatar>
                            },
                            { field: "name", title: "Nome",
                                render: (rowData, type) => <React.Fragment>
                                    <span style={{display: "flex", alignItems: "center"}}>
                                        {rowData.has_priority === true && (<Tooltip title="Prioridade"><CheckCircle fontSize="small" style={{marginRight: "5px", color: "#4caf50"}}/></Tooltip>)} 
                                        {rowData.name}
                                    </span>
                                </React.Fragment>
                            },
                            {
                                field: "vehicle_type", title: "Tipo de Veículo",
                                render: (rowData, type) => t("vehicle_type." + rowData.vehicle_type)
                            },
                            {
                                field: "vehicle_load_compartment_type", title: "Compartimento de Carga",
                                render: (rowData, type) => {
                                    return rowData.vehicle_load_compartment_type ?
                                        t("vehicle_load_compartment_type." + rowData.vehicle_load_compartment_type)
                                        : "---"
                                }
                            },
                            {
                                field: "courier_status.courier_status", title: "Situação",
                                render: (rowData, type) => {
                                    let result = t("courier_status." + rowData.courier_status.courier_status);
                                    if (!rowData.courier_status.online) {
                                        result += " - Sem enviar dados";
                                    }
                                    return result;
                                }
                            },
                            {
                                field: "courier_status.latitude", title: "Distância",
                                render: (rowData, type) => {
                                    let result = selectedStore ?
                                        Math.trunc(distance(rowData.courier_status.latitude, rowData.courier_status.longitude,
                                            selectedStore.latitude, selectedStore.longitude, "m")) + " metros"
                                        : "---";
                                    return result;
                                }
                            },
                            {
                                field: "courier_status.updated_at", title: "Últ. Atualização",
                                render: (rowData, type) => {
                                    return formatDistanceToNow(
                                        new Date(rowData.courier_status.updated_at),
                                        { locale: ptBR }
                                    )
                                }
                            }
                        ]}
                        data={storeQueue}
                        actions={[
                            {
                                icon: () => <CancelOutlinedIcon />,
                                tooltip: 'Remover da Fila',
                                onClick: (event, rowData) => removeCourierFromQueue(rowData as CourierQueueItem)
                            }
                        ]}
                        components={{
                            ...DEFAULT_TABLE_COMPONENTS,
                            Pagination: (props: any) => <span />
                        }}
                        localization={TABLE_LOCALIZATION}
                        options={{
                            ...DEFAULT_TABLE_OPTIONS,
                            pageSize: 200,
                            sorting: false
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
