import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, List, ListItemText, ListItem, ListItemAvatar, Typography, Avatar, Divider, Card, CardContent, Box, LinearProgressProps, makeStyles, Theme, createStyles } from "@material-ui/core";
import CourierService from "../../../services/courierService";
import { ReferralCourierResponse, ReferralSettings } from "../../../services/types/referral";
import LinearProgress from '@material-ui/core/LinearProgress';
import clsx from "clsx";

interface ICourierIndicationsProps {
  courierId: number;
  referralSettings: ReferralSettings;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Box style={{ width: '100%' }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box style={{ minWidth: 35, marginLeft: "10px" }}>
        <Typography variant="body2">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export const CourierIndications: React.FC<ICourierIndicationsProps> = ({ courierId, referralSettings }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [referralCodes, setReferralCodes] = React.useState<ReferralCourierResponse[]>()

  const ProgressCode = (countDelivery: number) => {
    const result: number = (countDelivery * 100) / referralSettings.quantity_of_deliveries;
    return result;
  };

  React.useEffect(() => {
    if (courierId) {
      CourierService.getIndications(Number(courierId))
        .then((response) => {
          setReferralCodes(response)
        })
        .catch((error) => { });
    };
  }, [courierId]);

  /* function copyCode(item: string) {
      if (item) {
        let input = document.createElement("textarea");
        input.innerHTML = item;
        document.body.appendChild(input);
        input.select();
        let result = document.execCommand("copy");
        document.body.removeChild(input);         
        if(result){
          NotificationManager.success(
              "Código copiado com sucesso.",
              "Sucesso"
          );
        }else{
          NotificationManager.error(
              "Falha ao copiar Código.",
              "Falha"
          );
        }
        return result;
      }
  }; */

  const SubtractUse = (countDelivery: number) => {
    const result: number = referralSettings.quantity_of_deliveries - countDelivery;
    return result;
  };
  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item md={6}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Códigos de Indicação
              </Typography>
              <List style={{ width: '100%', background: 'background.paper' }}>
                {referralCodes && referralCodes.length === 0 && (
                  <Typography
                    style={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                  >
                    Nenhum código encontrado
                  </Typography>
                )}
                {referralCodes && referralCodes.map((item, index) => (
                  <>
                    <ListItem alignItems="flex-start" key={index}>
                      <ListItemAvatar>
                        {item.used_by ? (
                          <Avatar alt="Entregador" src={item.used_by.photo} />
                        ) : (
                          <Avatar alt="Nenhum entregador indicado" />
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        title={item.revoked ? "REVOGADO" : ""}
                        primary={
                          <React.Fragment>
                            <span className={clsx(classes.coupon, {
                              [classes.usedCoupon]: item.used_by,
                              [classes.revokedCoupon]: item.revoked,
                            })}>
                              {item.code}
                            </span>
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            {item.used_by ? (
                              <>
                                <Typography
                                  style={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                >
                                  {item.revoked && "REVOGADO - "}{item.used_by.name}
                                </Typography>
                                <span style={{ margin: "0px 5px" }}>-</span> {item.used_by.region_name}
                                <label className={classes.positionEnd}>Faltam <b>{SubtractUse(item.deliveries_count)}</b> entregas de <b>{referralSettings.quantity_of_deliveries}</b></label>
                                <LinearProgressWithLabel value={ProgressCode(item.deliveries_count)} />
                              </>
                            ) : (
                              <>
                                {item.revoked ? "REVOGADO" : "Nenhum entregador indicado"}
                              </>
                            )}

                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                ))}

              </List>
            </CardContent>
          </Card>

        </Grid>

      </Grid>

    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    coupon: {
      border: "1px dashed gray",
      textAlign: "center",
      padding: "0px 10px",
      fontSize: "26px",
      width: "150px",
      backgroundColor: "#f3f1f1",
      marginBottom: "5px",
    },
    usedCoupon: {
      borderColor: "#0080ff",
      backgroundColor: "#deefff",
    },
    revokedCoupon: {
      color: 'silver',
    },
    positionEnd: {
      display: "block",
      textAlign: "end",
    }

  })
);