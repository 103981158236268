import { AxiosRequestConfig } from "axios";
import { apiAxios as axios } from "../store/api/config";
import { urls } from "../store/api/urls";
import { LoggedUser } from "./types/user";

export interface IAuthService {
    getNewToken: (refreshToken: string | null) => Promise<any>;

    getCurrentUser: () => LoggedUser;

    hasAuthorization: (authorization: string | string[]) => boolean;
}

const AuthService: IAuthService = {

    getNewToken: (refreshToken: string | null) => {
        return axios
            .post(urls.TOKEN_REFRESH, 
                { refresh: refreshToken }, 
                { skipAuthRefresh: true } as AxiosRequestConfig);
    },

    getCurrentUser: () => {
        const user = localStorage.getItem("@speedy:user");
        if (user) {
            return JSON.parse(user);
        } else {
            return null;
        }
    },

    hasAuthorization: (authorization: string | string[]) => {
        const currentUser = AuthService.getCurrentUser();
        let result = false;

        if (currentUser) {
            if (currentUser.user_type === "ADMIN") {
                result = true;
            } else if (Array.isArray(authorization)) {
                for (let i = 0; i < authorization.length; i++) {
                    result = currentUser.permissions.includes(authorization[i]);
                    if (result) {
                        break;
                    }
                }
            } else {
                result = currentUser.permissions.includes(authorization);
            }
        }
        return result;
    }
}

export default AuthService;