import React, { ReactNode, useCallback } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import { Typography, Grid, TableContainer, Table, TableHead, Paper, TableRow, TableCell, TableBody, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import numbro from "numbro";
import { format } from "date-fns";
import { CURRENT_DATE_TIME_FORMAT } from "../../../../i18n";
import { DeliveryRequest, DeliveryRequestStatus, Incentive, OriginStatus } from "../../../../services/types/deliveryRequest";
import { formatPhoneNumber } from "../../../../utils/formatters";
import DeliveryRequestService from "../../../../services/deliveryRequestService";
import { CourierOperation } from "../../../../services/types/courier";
import ShowIf from "../../../../components/ShowIf";


const useStyles = makeStyles({
  status: {
    display: "inline-flex",
    padding: "0px 8px",
    marginBottom: "2px",
    borderRadius: "4px",
  },
  even_row: {
    padding: "8px 0px;",
  },
  row: {
    "background-color": "#F1F5F9",
    padding: "2px 0px;",
  },
  backgroundResult: {
    background: "#ebebeb",
  },
});

interface IProps {
  children?: ReactNode;
  data: DeliveryRequest;
}

interface IMoneyText {
  value: number;
  type: string;
  origin: string;
}

const MoneyText: React.FC<IMoneyText> = ({ value, type, origin }) => {
  let money = {negative : false, value: ""};
  if(origin === OriginStatus.DISCOUNT){
    money = { negative: true, value: `${numbro(value).formatCurrency()}` };
  }else{
    money = (type === "CREDIT")
    ? { negative: false, value: `+${numbro(value).formatCurrency()}` }
    : { negative: true, value: `-${numbro(value).formatCurrency()}` }
  }

  const MoneyTypo = styled(Typography)({
    color: money.negative ? '#E86262' : '#6CBE54',
  })

  return <MoneyTypo variant="subtitle1">{money.value}</MoneyTypo>
}

export default function CustomTableTrip({ children, data }: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();

   const [releasesDelivery, setReleasesDelivery] = React.useState<CourierOperation[]>([]);


  const loadReleases = useCallback(async (id: number) => {
    const response = await DeliveryRequestService.getReleasesDelivery(id, true);
    setReleasesDelivery(response);
  }, []);

  React.useEffect(() => {
    if(data && [DeliveryRequestStatus.FINISHED].includes(data.status)){
      loadReleases(data.id);
    }   
  }, [data, loadReleases]);

  const calcIncentive = (incentive: Incentive) => {
    let customerValue = 0;
    let speedyValue = 0;

    if (incentive) {
      customerValue = incentive.customer_value ? incentive.customer_value : customerValue;
      speedyValue = incentive.speedy_value ? incentive.speedy_value : speedyValue;
    }

    return customerValue + speedyValue;
  }

  const formatIncentive = (incentive: Incentive) => {
    const total = calcIncentive(incentive);
    let result = "";
    if (total > 0) {
      result = ` + ${numbro(total).formatCurrency()} (Incentivos)`;
    }
    return result;
  }

  const formatIncentiveView = (incentive: Incentive) => {
    const customer = incentive.customer_value;
    const speedy = incentive.speedy_value;
    let result = "";
    if (incentive) {
      result = `${numbro(customer).formatCurrency()} (Cliente) + ${numbro(speedy).formatCurrency()} (Speedy)`;
    }else{
      result = "---"
    }
    return result;
  }

  function formatAddress(address: any): string {
    let result: string = "";
    if (address) {
      let address_components = [];

      if (address.street) {
        address_components.push(address.street);
      }

      if (address.address_number) {
        address_components.push(address.address_number);
      }

      if (address.district) {
        address_components.push(address.district);
      }

      if (address.address_complement) {
        address_components.push(address.address_complement);
      }

      if (address.address_reference) {
        address_components.push(address.address_reference);
      }

      result = address_components.join(", ");
      if (address.postal_code) {
        if (result.length) {
          result += "\n";
        }
        result += `CEP: ${address.postal_code}`;
      }

      if (address.city) {
        let city = address.city;

        if (address.state) {
          city += " - " + address.state;
        }

        if (result.length) {
          result += "\n" + city;
        }
      }
    }
    return result;
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.row}>
        <Grid item xl={2} md={2} sm={4} xs={12}>
          <Typography variant="body1">Solicitação</Typography>
          <Typography variant="subtitle1">{data?.request_number} - {data?.manual_creation ? "Manual" : "Busca"}</Typography>
        </Grid>
        <Grid item xl={2} md={2} sm={4} xs={12}>
          <Typography variant="body1">Compartimento</Typography>
          <Typography variant="subtitle1">
            {data?.compartment_type ? t("vehicle_load_compartment_type." + data?.compartment_type) : "---"}
          </Typography>
        </Grid>
        <Grid item xl={2} md={2} sm={3} xs={12}>
          <Typography variant="body1">Data da solicitação</Typography>
          <Typography variant="subtitle1"> {data?.requested_time ? format(data?.requested_time, CURRENT_DATE_TIME_FORMAT) : "---"}</Typography>
        </Grid>
        <Grid item xl={2} md={2} sm={3} xs={12}>
          <Typography variant="body1">Prazo p/ check-in</Typography>
          <Typography variant="subtitle1"> {data?.time_to_arrive_store ? format(data?.time_to_arrive_store, CURRENT_DATE_TIME_FORMAT) : "---"}</Typography>
        </Grid>
        <Grid item xl={2} md={2} sm={3} xs={12}>
          <Typography variant="body1">Previsão de entrega</Typography>
          <Typography variant="subtitle1"> {data?.estimated_delivery_time ? format(data?.estimated_delivery_time, CURRENT_DATE_TIME_FORMAT) : "---"}</Typography>
        </Grid>
        <Grid item xl={2} md={2} sm={3} xs={12}>
          <Typography variant="body1">Prazo p/ Entregador</Typography>
          <Typography variant="subtitle1"> {data?.estimated_delivery_time_after_release ? format(data?.estimated_delivery_time_after_release, CURRENT_DATE_TIME_FORMAT) : "---"}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.even_row}>      
        <Grid item xl={2} md={2} sm={4} xs={12}>
          <Typography variant="body1">Valor Mínimo</Typography>
          <Typography variant="subtitle1">
            {data?.minimum_value !== 0 ? numbro(data?.minimum_value).formatCurrency() : "---"}
            {/* {formatIncentive(data?.incentive_json)} */}
          </Typography>
        </Grid>
        <Grid item xl={2} md={2} sm={4} xs={12}>
          <Typography variant="body1">Valor do Deslocamento</Typography>
          <Typography variant="subtitle1">
            {data?.additional_km_value !== 0 ? numbro(data?.additional_km_value).formatCurrency() : "---"}
          </Typography>
        </Grid>
        <Grid item xl={2} md={2} sm={4} xs={12}>
          <Typography variant="body1">Incentivos</Typography>
          <Typography variant="subtitle1">
            {data?.incentive_json ? formatIncentiveView(data?.incentive_json) : "---"}
          </Typography>
        </Grid>
        <Grid item xl={2} md={2} sm={4} xs={12}>
          <Typography variant="body1">Valor de Retorno</Typography>
          <Typography variant="subtitle1">
            {data?.return_value !== 0 ? numbro(data?.return_value).formatCurrency() : "---"}
          </Typography>
        </Grid>
        <Grid item xl={calcIncentive(data?.incentive_json) > 0 ? 2 : 1} md={calcIncentive(data?.incentive_json) > 0 ? 2 : 1} sm={4} xs={12} className={classes.backgroundResult}>
          <Typography variant="body1">Entregador</Typography>
          <Typography variant="subtitle1">
            {data?.courier_value !== null ? `${numbro(data?.courier_value).formatCurrency()}` : "---"}
            {formatIncentive(data?.incentive_json)}
          </Typography>
        </Grid>
        <Grid item xl={1} md={1} sm={4} xs={12} className={classes.backgroundResult}>
          <Typography variant="body1">Speedy</Typography>
          <Typography variant="subtitle1">
            {data?.speedy_value !== null ? `${numbro(data?.speedy_value).formatCurrency()}` : "---"}
          </Typography>
        </Grid>
        <Grid item xl={calcIncentive(data?.incentive_json) > 0 ? 1 : 2} md={calcIncentive(data?.incentive_json) > 0 ? 1 : 2} sm={4} xs={12} className={classes.backgroundResult}>
          <Typography variant="body1">Valor da Entrega</Typography>
          <Typography variant="subtitle1">
            {data?.value_total !== 0 ? numbro(data?.value_total).formatCurrency() : "---"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.row}>
        <Grid item xl={4} md={4} sm={4} xs={12}>
          <Typography variant="body1">Consumidor</Typography>
          <Typography variant="subtitle1">{data ? data?.consignee : "---"}</Typography>
        </Grid>
        <Grid item xl={2} md={2} sm={2} xs={12}>
          <Typography variant="body1">Tel. do Consumidor</Typography>
          <Typography variant="subtitle1">{data ? formatPhoneNumber(data?.consignee_phone_number) : "---"}</Typography>
        </Grid>
        <Grid item xl={4} md={4} sm={12} xs={12}>
          <Typography variant="body1">
            Instruções/Observações de Entrega
          </Typography>
          <Typography variant="subtitle1">{data?.destination_address.delivery_observations ? data?.destination_address.delivery_observations : "---"}</Typography>
        </Grid>
        <Grid item xl={2} md={2} sm={2} xs={12}>
        <Typography variant="body1">
          Tipo de Retorno
        </Typography>
        <Typography variant="subtitle1">
          {t("delivery_request_return_type." + data.return_type)}
        </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.even_row}>
        <Grid item xl={6} md={6} sm={12} xs={12}>
          <Typography variant="body1">Endereço de Origem</Typography>
          <Typography variant="subtitle1">
            {formatAddress(data?.origin_address)
              .split("\n")
              .map((value, index) => {
                return <div key={index}>{value}</div>;
              })}
          </Typography>
        </Grid>
        <Grid item xl={6} md={6} sm={12} xs={12}>
          <Typography variant="body1">Endereço de Destino</Typography>
          <Typography variant="subtitle1">
            {formatAddress(data?.destination_address)
              .split("\n")
              .map((value, index) => {
                return <div key={index}>{value}</div>;
              })}
          </Typography>
        </Grid>
      </Grid>
      <ShowIf condition={[DeliveryRequestStatus.FINISHED].includes(data.status)}>
        <Divider style={{marginTop:"15px", marginBottom:"15px"}} />
        <Grid style={{marginTop:"8px"}}  container spacing={1}>
          <Typography variant="h2" >Lançamentos</Typography>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead style={{ backgroundColor: "#f1f5f9" }}>
                <TableRow>
                  <TableCell>Criado em</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell align="right">Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {releasesDelivery.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {format(
                          new Date(row.created_at),
                          CURRENT_DATE_TIME_FORMAT
                      )}
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell align="right"><MoneyText value={row.value} type={row.type} origin={row.origin} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </ShowIf>
    </React.Fragment>
  );
}
