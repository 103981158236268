import React, { useState } from "react";
import {
  Typography,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Card,
  CardContent,
} from "@material-ui/core";
import { FileModalView } from "../../../components/FileModalView";
import { CourierDetails } from "../../../services/types/courier";

interface ICourierDetailDocsProps {
  courier?: CourierDetails;
}

interface Document {
  title: string;
  src: string;
}

export const CourierDetailDocs: React.FC<ICourierDetailDocsProps> = ({
  courier,
}) => {
  const [isOpen, setOpenModal] = useState(false);
  const [image, setImage] = useState<Document>({ title: "", src: "" });

  const classes = useStyles();

  const handleShowImage = (document: Document) => {
    setImage(document);
    setOpenModal(true);
  }

  const availableDocs = (courier?: CourierDetails): Document[] => {
    const result: Document[] = []

    if (courier) {
      if (courier.drivers_license_front_image) {
        result.push({ title: "CNH - Frente", src: courier.drivers_license_front_image });
      }
      if (courier.drivers_license_back_image) {
        result.push({ title: "CNH - Verso", src: courier.drivers_license_back_image });
      }
      if (courier.vehicle_registration_front_image) {
        result.push({ title: "CRLV - Frente", src: courier.vehicle_registration_front_image });
      }
      if (courier.vehicle_registration_back_image) {
        result.push({ title: "CRLV - Verso", src: courier.vehicle_registration_back_image });
      }
      if (courier.address_proof_image) {
        result.push({ title: "Comprovante de Residência", src: courier.address_proof_image });
      }
    }

    return result;
  }

  return (
    <Card style={{marginTop: "16px"}}>
      <CardContent>
        <Typography variant="h2">
          Documentos anexados
        </Typography>
        <Grid container spacing={2} direction="row">
          {availableDocs(courier).map(item => (
            <Grid item md>
              <Typography variant="subtitle1">{item.title}</Typography>
              <div onClick={() => handleShowImage(item)}>
                <Typography className={classes.fileLink}>Visualizar</Typography>
              </div>
            </Grid>
          ))}
        </Grid>
        <FileModalView
          isOpen={isOpen}
          closeModal={() => setOpenModal(false)}
          title={image.title}
          src={image.src}
        />
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: "10px",
    },
    fileLink: {
      color: "#03a9f4",
      cursor: "pointer",
    },
  })
);
