export interface ICreateCustomer {
    id?: number;
    email: string;
    name: string;
    phonenumber: string;
    corporate_name: string;
    trade_name: string;
    registration_number: string;
    contact_name: string;
    additional_km_price: number;
    single_store: boolean;
    fiscal_address_city?: number;
    stores: IStore[];
    fiscal_address_postal_code: string;
    fiscal_address_street: string;
    fiscal_address_number: string;
    fiscal_address_commplement: string;
    fiscal_address_district: string;
    allow_incentive_dynamics: boolean;
    skip_customer_actions: boolean;
    commercial_plan: number;
    management_user: number | null;
}

export interface IUpdateCustomer {
    id?: number;
    email: string;
    name: string;
    phonenumber: string;
    corporate_name: string;
    trade_name: string;
    registration_number: string;
    contact_name: string;
    additional_km_price: number;
    single_store: boolean;
    fiscal_address_city?: number;
    fiscal_address_postal_code: string;
    fiscal_address_street: string;
    fiscal_address_number: string;
    fiscal_address_commplement: string;
    fiscal_address_district: string;
    allow_incentive_dynamics: boolean;
    skip_customer_actions: boolean;
    commercial_plan: number
}

export interface ICustomer {
    id?: number;
    email: string;
    name: string;
    phonenumber: string;
    created_at?: string;
    updated_at: string;
    corporate_name: string;
    trade_name: string;
    registration_number: string;
    contact_name: string;
    additional_km_price: number;
    single_store: boolean;
    fiscal_address_city?: ICity;
    stores?: IStore[];
    waste_limit: number;
    fiscal_address_postal_code: string;
    fiscal_address_street: string;
    fiscal_address_number: string;
    fiscal_address_commplement: string;
    fiscal_address_district: string;
    allow_incentive_dynamics: boolean;
    skip_customer_actions: boolean;
    commercial_plan: number;
    is_active: boolean;
}

export interface ICity {
    id: number;
    name: string;
    state_initials: string;
    state_name: string;
}

export interface IUser {
    id: number;
    name: string;
    email?: string;
    user_status: string;
}

export interface ICustomerUser {
    id?: number;
    first_name: string;
    last_name: string;
    name?: string;
    email: string;
    registration_number: string;
    user_status?: string;
    user_type?: string;
    permissions?: string[];
    groups?: Array<number>;
    phonenumber: string;
    street: string;
    city: string;
    postal_code: string;
    district: string;
    address_number: string;
    address_complement: string;
    customer: number;
    store: any;
}

export interface IStore {
    id?: number;
    name: string;
    latitude: number;
    longitude: number;
    contact_name: string;
    email: string;
    phonenumber: string;
    postal_code: string;
    street: string;
    address_number: string;
    address_complement?: string;
    district: string;
    city: string;
    customer?: number;
    region?: number;
}

export const EMPTY_STORE: IStore = {
    name: "",
    latitude: 0,
    longitude: 0,
    contact_name: "",
    email: "",
    phonenumber: "",
    postal_code: "",
    street: "",
    address_number: "",
    address_complement: "",
    district: "",
    city: "",
};

export const EMPTY_CUSTOMER_USER = {
    first_name: "",
    last_name: "",
    email: "",
    registration_number: "",
    phonenumber: "",
    postal_code: "",
    street: "",
    address_number: "",
    address_complement: "",
    city: "",
    district: "",
    customer: 0,
    store: null,
};
