import React, { useEffect } from "react";
import { Button, Checkbox,  CircularProgress,  FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CurrencyInput } from "../../../components/CurrencyInput";
import { CURRENT_DATE_TIME_FORMAT, getNumberSpecs } from "../../../i18n";
import { useFormik } from "formik";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import * as Yup from "yup";
import { convertToNumber } from "../../../utils/utils";
import numbro from "numbro";
import { LoadingDialog } from "../../../components/LoadingDialog";
import SettingsService from "../../../services/settingsService";
import { ReferralSettingsResponse } from "../../../services/types/referral";
import { format } from "date-fns";

const INITIAL_FORM_VALUES: ReferralSettingsResponse = {
    quantity_of_deliveries: 0,
    reward_amount: 0,
    updated_at: new Date()
}

const validationSchema = Yup.object().shape({
    quantity_of_deliveries: Yup.number()
        .required("Campo obrigatório")
        .transform((currentValue, originalValue) => convertToNumber(originalValue))
        .min(1, "Campo obrigatório"),
    reward_amount: Yup.number()
        .required("Campo obrigatório")
        .transform((currentValue, originalValue) => numbro.unformat(originalValue))
        .moreThan(0, "Campo obrigatório")
});

export const ReferralSettings = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [state, setState] = React.useState({ confirmation: false });
    const [isShowLoadingDialog, showLoadingDialog] = React.useState(false);

    const handleChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const { confirmation } = state;

    const getReferralSettings = async () => {
        showLoadingDialog(true);
        const response = await SettingsService.getReferralSettings();
        formik.setValues(response);
        showLoadingDialog(false);
    };

    useEffect(() => {
        getReferralSettings();
    }, []);

    const formik = useFormik({
        initialValues: INITIAL_FORM_VALUES,
        onSubmit: async (values) => {
            try {
                await SettingsService.updateReferralSettings(values);
                getReferralSettings();
                NotificationManager.success(
                    "Configurações da Indicação alteradas com Sucesso!",
                    "Configurações da Indicação"
                );
                setState({ "confirmation": false });
            } catch (error) {
                const { status, data } = error.response;
                if (status === 400) {
                    for (var key in data) {
                        var value = data[key];
                        formik.setFieldError(key, value);
                    }
                } else {
                    NotificationManager.error(
                        "Não foi possível alterar Configurações de Indicação!",
                        "Configurações de Indicação"
                    );
                }
            }
        },
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
    });

    return (
        <div>
            <LoadingDialog open={isShowLoadingDialog} message={""} />
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item md={12} style={{marginBottom: "10px"}}>
                        <Typography variant="subtitle1">
                            Última atualização: {format(formik.values.updated_at, CURRENT_DATE_TIME_FORMAT)}
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Grid container md={12} spacing={2}>
                            <Grid item md={12}>
                                <TextField
                                    type="number"
                                    id="quantity_of_deliveries"
                                    label="Quantidade de Corridas"
                                    variant="outlined"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.quantity_of_deliveries}
                                    error={!!formik.errors.quantity_of_deliveries}
                                    helperText={formik.errors.quantity_of_deliveries}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    id="reward_amount"
                                    label="Valor da Recompensa"
                                    variant="outlined"
                                    required={true}
                                    onChange={formik.handleChange}
                                    value={formik.values.reward_amount}
                                    error={!!formik.errors.reward_amount}
                                    helperText={formik.errors.reward_amount}
                                    fullWidth
                                    InputProps={{
                                        inputComponent: CurrencyInput,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {getNumberSpecs().currency.symbol}
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FormControl component="fieldset">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox color={"primary"} checked={confirmation} onChange={handleChange} name="confirmation" />}
                                            color={"primary"}
                                            label="Confirmar alteração dos dados."
                                        />
                                    </FormGroup>
                                    <FormHelperText>Verifique todos os dados antes de salvar</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button color="primary" variant="contained" type="submit" disabled={formik.isSubmitting || confirmation === false}>
                            {formik.isSubmitting ? (
                            <CircularProgress size="1.5rem" color="inherit" />
                            ) : (
                            <span>Salvar</span>
                            )} 
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};