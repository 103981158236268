import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { CourierDeliveryRelatoryRanking, SpeedyRankingType, } from "../../../services/types/courier";
import CourierService from "../../../services/courierService";
import { createStyles, Divider, FormControl, Grid, makeStyles, MenuItem, Paper, Select, Theme, Typography } from "@material-ui/core";
import { CURRENT_DATE_FORMAT_NA } from "../../../i18n";
import { format, subDays } from "date-fns";

interface ICourierDetailRankingProps {
  courierId: number;
}

export const CourierDetailRanking: React.FC<ICourierDetailRankingProps> = ({ courierId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [ranking, setRanking] = useState<CourierDeliveryRelatoryRanking>({
    accept_checkin_delays: 0,
    accepted: 0,
    total_deliveries: 0,
    checkout_deliver_delays: 0,
    finished: 0,
    finished_0_3: 0,
    finished_3_8: 0,
    finished_8_gt: 0,
    ignored: 0,
    ranking_points: 0,
    refused: 0,
    speedy_ranking: SpeedyRankingType.INITIATE_SPEEDER,
    stores: 0
  });

  const [tableTypeList] = useState([
    { label: "Ranking", value: 30 },
    { label: "Últimos 15 dias", value: 15 },
    { label: "Últimos 7 dias", value: 7 },
    { label: "Hoje", value: 0 }
  ]);
  const [tableType, setTableType] = useState<number>(() => tableTypeList[0].value)

  const getRelatoryRanking = useCallback((days: number) => {
    let startDate = format(subDays(new Date(), days), CURRENT_DATE_FORMAT_NA);
    let endDate = format(new Date(), CURRENT_DATE_FORMAT_NA);

    CourierService.getDeliveriesRelatory(Number(courierId), startDate, endDate)
      .then((response) => {
        setRanking(response);
      })
      .catch((error) => { });
  }, [courierId]);

  useEffect(() => {
    if (courierId) {
      getRelatoryRanking(tableType);
    }
  }, [courierId, getRelatoryRanking, tableType]);

  const handleChange = (value: number) => {
    getRelatoryRanking(value);
    setTableType(value);
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justify="space-between">
        <Grid md={2} xs={4}>
          <div className={classes.cardGainGroupOne}>
            <Paper
              elevation={4}
              className={classes.cardGain}>
              <Typography variant="h1" className={classes.title}>{Number(ranking?.ranking_points)}</Typography>
              <Typography variant="body1" className={classes.body1}>Speedy Points</Typography>
              <Typography variant="h3">{t(`courier_type.${ranking?.speedy_ranking}`)}</Typography>
            </Paper >
          </div>
        </Grid>
        <Divider orientation="vertical" flexItem />

        <Grid md={9} xs={12}>
          <Grid direction="row">
            <Typography variant="body1" className={classes.qtdTotalEntregas}><b>Quantidade total de Entregas: </b> {ranking?.total_deliveries}</Typography>
          </Grid>
          <Grid direction="row" md={3} className={classes.marginSelect}>
            <FormControl variant="outlined" fullWidth>
              <Select
                labelId="table-range-label"
                id="table-range"
                value={tableType}
                onChange={
                  (e) => handleChange(e.target.value as number)
                }
              >
                {tableTypeList.map((item, index) => (
                  <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid direction="row" >
            <div className={classes.cardGainGroup}>
              <Grid>
                <Paper
                  elevation={4}
                  className={classes.cardGainItems}>
                  <Typography variant="body1" className={classes.bodyCard}><b>Aceitas: </b>{ranking?.accepted}</Typography>
                  <Typography variant="body1" className={classes.bodyCard}><b>Ignoradas: </b>{ranking?.ignored}</Typography>
                  <Typography variant="body1" className={classes.bodyCard}><b>Recusadas: </b>{ranking?.refused}</Typography>
                </Paper >
              </Grid>

              <Grid>
                <Paper
                  elevation={4}
                  className={classes.cardGainItems}>
                  <Typography variant="body1" className={classes.bodyCard}><b>Finalizadas: </b>{ranking?.finished}</Typography>
                  <Typography variant="body1" className={classes.bodyCardMini}><b>0 a 3km: </b>{ranking?.finished_0_3}</Typography>
                  <Typography variant="body1" className={classes.bodyCardMini}><b>3 a 8km: </b>{ranking?.finished_3_8}</Typography>
                  <Typography variant="body1" className={classes.bodyCardMini}><b>+ 8km: </b>{ranking?.finished_8_gt}</Typography>
                </Paper >
              </Grid>

              <Grid>
                <Paper
                  elevation={4}
                  className={classes.cardGainItems}>
                  <Typography variant="body1" className={classes.bodyCard}><b>Atraso para buscar: </b>{ranking?.accept_checkin_delays}</Typography>
                  <Typography variant="body1" className={classes.bodyCard}><b>Atraso para entregar: </b>{ranking?.checkout_deliver_delays}</Typography>
                  <Typography variant="body1" className={classes.bodyCard}><b>Lojas atendidas: </b>{ranking?.stores}</Typography>
                </Paper >
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardGainGroupOne: {
      display: "grid",
      columnGap: "10px",
      rowGap: "30px",
      marginBottom: "30px"
    },
    cardGainGroup: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      columnGap: "20px",
      rowGap: "30px",
      marginBottom: "30px"
    },
    cardGain: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      padding: "30px 0",
      whiteSpace: "nowrap",
    },
    title: {
      fontSize: "60px",
    },
    body1: {
      fontSize: "10px",
      marginBottom: "10px",
    },
    bodyCard: {
      fontSize: "18px",
    },
    bodyCardMini: {
      fontSize: "14px",
    },
    cardGainItems: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "left",
      height: "100%",
      padding: "10px 30px",
      whiteSpace: "nowrap",
    },
    qtdTotalEntregas: {
      fontSize: "18px",
      textAlign: "right",
    },
    marginSelect: {
      marginBottom: "10px"
    }
  })
);
