import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import {  DATE_TIME_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";
import OccurrencesService from "../../../services/occurrencesService";
import { RegisterOccurrences } from "../../../services/types/occurrences";
import { INITIAL_FILTER_PARAMS, IOccurrencesFilterParams, OccurrencesFilter } from "../../occurrences/OccurrencesFilter";
import { Alert } from "@material-ui/lab";
import { mapDeliveryRequestStatusToSeverity } from "../../requestsHistory/deliveryRequestUtils";
import AddOccurrenceDialog, { AddOccurrenceDialogHandle } from "../../occurrences/AddOccurrenceDialog";
import { Button, Grid } from "@material-ui/core";
import { VisibilityOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";


interface IProps {
    courierId: number;
}

export const CourierRegisterOccurences: React.FC<IProps> = ({ courierId }) => {
    const { t } = useTranslation();
    const occurrenceDialog = React.createRef<AddOccurrenceDialogHandle>();
    const history = useHistory();
    const [occurrenceId, setOccurrenceId] = useState<number | null>(null);
    const [filterParams, setFilterParams] = useState<IOccurrencesFilterParams>(INITIAL_FILTER_PARAMS);
  
    const tableRef = React.useRef<MaterialTableProps<RegisterOccurrences>>();
  
    const loadData = (query: Query<RegisterOccurrences>) =>
      new Promise<QueryResult<RegisterOccurrences>>((resolve, reject) => {
        OccurrencesService.getRegistersOccurrences(
          courierId, filterParams.gravity, filterParams.number,
          filterParams.request_number, filterParams.blocked, filterParams.requested_time_after,
          filterParams.requested_time_before, [], PageableParams.fromQuery(query)
        ).then(response => {
          resolve({
            data: response.data,
            page: query.page,
            totalCount: response.count,
          });
        }).catch((error) => reject(error));
      });
  
    const applyFilter = (params: IOccurrencesFilterParams) => {
      setFilterParams(params);
      if (tableRef.current?.onQueryChange) {
        tableRef.current.onQueryChange({
          page: 0,
        } as Query<RegisterOccurrences>);
      }
    };

    const refresh = () => {
      if (tableRef.current?.onQueryChange) {
        tableRef.current.onQueryChange({
          page: 0,
        } as Query<RegisterOccurrences>);
      }
    }

    const addOccurrencesDone = async (): Promise<void> => {
      try {
        refresh();
      } finally {
        return Promise.resolve();
      }
    }
  
    const showDetails = (item: RegisterOccurrences) => {
      history.push(`/occurrences/detail/${item.id}`);
    }

    const openEditOccurrence = (item: RegisterOccurrences) => {
      if(item){
        setOccurrenceId(item.id);
      }
    }

    React.useEffect(() => {
      if (occurrenceId) {
        occurrenceDialog.current?.open();
      }
    }, [occurrenceId, occurrenceDialog]);

    const cancelAddEditOccurrence = () => {
      setOccurrenceId(null);
    }

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={1} justify="flex-end">
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                style={{
                  width: "160px",
                  whiteSpace: "nowrap",
                }}
                onClick={() => { occurrenceDialog.current?.open(); }}
                
              >
                Adicionar Ocorrência
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <OccurrencesFilter onFilterChanged={applyFilter} />

        <AddOccurrenceDialog
        ref={occurrenceDialog}
        courierId={Number(courierId)}
        occurrenceId={occurrenceId}
        deliveryList={null}
        onAccept={addOccurrencesDone}
        onCancel={cancelAddEditOccurrence}
        />

        <MaterialTable
          tableRef={tableRef}
          columns={[
            { field: "id", hidden: true, width: 60 },
            {
              ...DATE_TIME_COLUMN_DEFAULTS, 
              field: "occurence_date", 
              title: "Data Ocorrência"
            },
            { field: "number", title: "Número" },
            { field: "courier.name", title: "Entregador" },
            { field: "description", title: "Descrição" }, 
            { field: "delivery_request.request_number", title: "Solicitação",
              render: (rowData, type) => rowData.delivery_request ? rowData.delivery_request.request_number : "---"
            },
            {
                field: "gravity", title: "Gravidade",
                render: (rowData, type) => rowData.gravity ? t("gravity_occurrences." + rowData.gravity) : "---"
            },
            { field: "delivery_request.status", title: "Status Solicitação",
              render: (rowData, type) => rowData.delivery_request ? 
              <Alert
                severity={mapDeliveryRequestStatusToSeverity(
                    rowData.delivery_request.status
                )}
                icon={false}
                >
                    {t("delivery_request_status." + rowData.delivery_request.status)}
                </Alert> : "---"
            },
          ]}
          actions={[
            {
              icon: () => <VisibilityOutlined />,
              tooltip: "Ver Detalhes",
              onClick: (event, rowData) =>
                  showDetails(rowData as RegisterOccurrences),
            },
            {
              icon: () => <EditOutlinedIcon />,
              tooltip: 'Editar',
              onClick: (event, rowData) => openEditOccurrence(rowData as RegisterOccurrences)
            },
          ]}
          data={loadData}
          components={DEFAULT_TABLE_COMPONENTS}
          localization={TABLE_L10N_PTBR}
          options={DEFAULT_TABLE_OPTIONS}
        />
      </div>
    );
};