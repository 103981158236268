import React, { useEffect, useState } from "react";
import { green, red } from '@material-ui/core/colors';
import { Box, Button, Typography } from "@material-ui/core";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import WarningIcon from '@material-ui/icons/Warning';
import CustomerService from "../../../services/customerService";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { useTranslation } from "react-i18next";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";
import { Block, Check, EditOutlined } from "@material-ui/icons";
import { CustomerFinancialOperationsFilter, ICustomerIntegrationsFilterParams, INITIAL_FILTER_PARAMS } from "./CustomerIntegrationsFilter";
import { CustomerIntegrationResponse, StoreResponse } from "../../../services/types/customer";
import AddEditCustomerIntegrationDialog, { AddEditCustomerIntegrationDialogHandle } from "./AddEditCustomerIntegrationDialog";
import ShowIf from "../../../components/ShowIf";
import AuthService from "../../../services/authService";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../../components/ConfirmationDialog";
//@ts-ignore
import { NotificationManager } from "react-notifications";

interface IProps {
  customerId: number;
}

export const CustomerIntegrations: React.FC<IProps> = ({ customerId }) => {
  const { t } = useTranslation();

  const [storeList, setStoreList] = useState<StoreResponse[]>([]);
  const [filterParams, setFilterParams] = useState<ICustomerIntegrationsFilterParams>(INITIAL_FILTER_PARAMS);

  const tableRef = React.useRef<MaterialTableProps<CustomerIntegrationResponse>>();
  const addEditCustomerIntegrationDialog = React.createRef<AddEditCustomerIntegrationDialogHandle>();
  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();

  const loadData = (query: Query<CustomerIntegrationResponse>) =>
    new Promise<QueryResult<CustomerIntegrationResponse>>((resolve, reject) => {
      CustomerService.getCustomerIntegrations(customerId, filterParams.storeId,
        filterParams.integration_source, PageableParams.fromQuery(query)).then(response => {
          resolve({
            data: response.data,
            page: query.page,
            totalCount: response.count,
          });
        }).catch((error) => reject(error));
    });

  const refresh = () => {
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({
        page: 0,
      } as Query<CustomerIntegrationResponse>);
    }
  }

  const applyFilter = (params: ICustomerIntegrationsFilterParams) => {
    setFilterParams(params);
    refresh();
  };

  useEffect(() => {
    const getStores = async () => {
      const response = await CustomerService.getStores(undefined, customerId,
        undefined, undefined, new PageableParams(1, 100, "name"));
      setStoreList(response.data);
    };
    getStores();
  }, [customerId]);

  const onIntegrationSaved = () => {
    refresh();
  }

  const newCustomerIntegration = () => {
    addEditCustomerIntegrationDialog.current?.add();
  };

  const editCustomerIntegration = (item: CustomerIntegrationResponse) => {
    addEditCustomerIntegrationDialog.current?.edit(item.id);
  };

  const handleActivateCustomerIntegration = (item: CustomerIntegrationResponse) => {
    confirmationDialog.current?.confirm(
      "Ativar Integração",
      <Typography>Você tem certeza que deseja <strong>Ativar</strong> esta Integração?</Typography>,
      "Ativar",
      "Cancelar")
      .then(() => {
        CustomerService.activateCustomerIntegration(item.id)
          .then((response) => {
            NotificationManager.success(
              "Integração Ativada com sucesso.",
              "Integração"
            );
            refresh();
            confirmationDialog.current?.hide();
          })
          .catch((error) => {
            NotificationManager.error(
              "Não foi possível Ativar a Integração.",
              "Integração"
            );
          });
      })
      .catch(() => { });
  };

  const handleDeactivateCustomerIntegration = (item: CustomerIntegrationResponse) => {
    confirmationDialog.current?.confirm(
      "Desativar Integração",
      <Typography>Você tem certeza que deseja <strong>Desativar</strong> esta Integração?</Typography>,
      "Desativar",
      "Cancelar"
    ).then(() => {
      CustomerService.deactivateCustomerIntegration(item.id)
        .then((response) => {
          NotificationManager.success(
            "Integração Desativada com sucesso.",
            "Integração"
          );
          refresh();
          confirmationDialog.current?.hide();
        })
        .catch((error) => {
          NotificationManager.error(
            "Não foi possível Desativar a Integração.",
            "Integração"
          );
        });
    }).catch(() => { });
  };

  return (
    <React.Fragment>
      <Box mt={1} style={{ display: "flex", justifyContent: "flex-end" }}>
        <ShowIf condition={AuthService.hasAuthorization("add_integrationconnection")}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => newCustomerIntegration()}
            startIcon={<AddCircleOutlineRoundedIcon />}
          >
            Nova Integração
          </Button>
        </ShowIf>
      </Box>

      <AddEditCustomerIntegrationDialog
        ref={addEditCustomerIntegrationDialog}
        storeList={storeList}
        onSaved={onIntegrationSaved}
      />

      <CustomerFinancialOperationsFilter
        params={filterParams}
        storeList={storeList}
        onFilterChanged={applyFilter}
      />

      <ConfirmationDialog ref={confirmationDialog} />

      <MaterialTable
        tableRef={tableRef}
        columns={[
          { field: "id", hidden: true },
          {
            field: "store", title: "Loja", defaultSort: "asc",
            render: (rowData, type) => rowData.store.name
          },
          { field: "name", title: "Nome", sorting: false },
          {
            field: "integration_source", title: "Aplicativo",
            render: (rowData, type) => t("customer_integration_source." + rowData.integration_source)
          },
          {
            field: "authorized", title: "Autorizado", sorting: false, width: 100,
            render: (rowData, type) => {
              if (rowData.authorized) {
                return <CheckCircleIcon style={{ color: green[500] }} />
              } else {
                return <WarningIcon style={{ color: red[500] }} />
              }
            }
          },
          {
            field: "enabled", title: "Ativo", sorting: false, width: 100,
            render: (rowData, type) => {
              if (rowData.enabled) {
                return <CheckCircleIcon style={{ color: green[500] }} />
              } else {
                return <WarningIcon style={{ color: red[500] }} />
              }
            }
          }
        ]}
        data={loadData}
        actions={AuthService.hasAuthorization("change_integrationconnection") ? [
          (rowData) => rowData.enabled ?
            {
              icon: () => <Block />,
              tooltip: 'Desativar',
              onClick: (event, rowData) => handleDeactivateCustomerIntegration(rowData as CustomerIntegrationResponse)
            } : {
              icon: () => <Check />,
              tooltip: 'Ativar',
              onClick: (event, rowData) => handleActivateCustomerIntegration(rowData as CustomerIntegrationResponse)
            }
          ,
          {
            icon: () => <EditOutlined />,
            tooltip: 'Editar',
            onClick: (event, rowData) => editCustomerIntegration(rowData as CustomerIntegrationResponse)
          },
        ] : []
        }
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_L10N_PTBR}
        options={{
          ...DEFAULT_TABLE_OPTIONS,
          paging: false,
          sorting: false,
          draggable: false,
          exportAllData: true,
          toolbar: false,
          search: false,
          columnsButton: true,
          fixedColumns: {}        
        }}
      />
    </React.Fragment>
  );
};
