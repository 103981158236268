import React, { useMemo, useState } from "react";
import { useFormik } from "formik";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import {
  Button,
  CircularProgress,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";
import * as Yup from "yup";
import { Autocomplete } from "@material-ui/lab";
import CourierService from "../../services/courierService";
import { CourierAccountStatus, CourierResponse } from "../../services/types/courier";
import { IPageableParams } from "../../store/ducks/requests";
import { ImgLoader } from "../../components/ImgLoader";
import { useTranslation } from "react-i18next";
import useCourierSchedule from "../mainDashboard/hooks/useCourierSchedule";

interface SelectCourierSlotForm {
  courier: number | null;
}

const INITIAL_FORM_VALUES: SelectCourierSlotForm = {
  courier: null,
};

interface AddRegisterDebitDialogProps {
  onAccept?: () => Promise<void>;
}

export interface SelectCourierSlotDialogHandle {
  open: (slotId: number, vehicleType: string, regionsIds: number[], region_name: string) => void;
  close: () => void;
}

const validationSchema = Yup.object().shape({
  courier: Yup.number().required("Campo obrigatório").moreThan(0, "Campo obrigatório").nullable(),
});

export const SelectCourierSlotDialog: React.ForwardRefRenderFunction<
  SelectCourierSlotDialogHandle,
  AddRegisterDebitDialogProps
> = (props, ref) => {
  const { t } = useTranslation();

  const { setSlotCourier } = useCourierSchedule();

  const [open, setOpen] = useState<boolean>(false);
  const [courierList, setCourierList] = useState<CourierResponse[]>([]);
  const [courierSearchValue, setCourierSearchValue] = useState<string>("");
  const [selectedCourier, setSelectedCourier] = useState<CourierResponse>();

  const [slotId, setSlotId] = useState<number>(0);
  const [vehicleType, setVehicleType] = useState<string>("");
  const [regionsIds, setRegionsIds] = useState<number[]>([]);
  const [regionName, setRegionName] = useState<string>();

  React.useImperativeHandle(ref, () => {
    return {
      open(slotId: number, vehicleType: string, regionsIds: number[], region_name: string) {
        setSlotId(slotId);
        setVehicleType(vehicleType);
        setRegionsIds(regionsIds);
        setRegionName(region_name);
        show();
      },

      close() {
        hide();
      },
    };
  });

  const hide = () => {
    clear();
    setOpen(false);
    formik.resetForm();
  };

  const show = () => {
    clear();
    setOpen(true);
  };

  const clear = () => {
    setSelectedCourier(undefined);
    setCourierSearchValue("");
    setCourierList([]);
  }

  const updateCourierList = useMemo(() => debounce((name: string) => {
    const DEFAULT_PAGINATION: IPageableParams = {
      page: 1,
      page_size: 15,
      ordering: "name"
    };
    if (name.trim().length > 3) {
      CourierService.getCouriers(name, "", "", null, null, CourierAccountStatus.ACTIVE, "", vehicleType, regionsIds, "", null, DEFAULT_PAGINATION).then((response) => {
        setCourierList(response.data);
      });
    } else {
      setCourierList([]);
    }
  }, 300), [vehicleType, regionsIds]);

  React.useEffect(() => {
    updateCourierList(courierSearchValue);
  }, [courierSearchValue, updateCourierList]);

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: async (values) => {
      try {
        if (values.courier) {
          await setSlotCourier(slotId, values.courier)
          if (props.onAccept) {
            await props.onAccept();
          }
          hide();
          NotificationManager.success("Entregador adicionado com sucesso.", "Sucesso");
        }
      } catch (error) {
        const { status, data } = error.response;
        if (status === 400) {
          for (var key in data) {
            var value = data[key];
            formik.setFieldError(key, value);
          }
        } else {
          NotificationManager.error("Não foi possível Adicionar o Entregador", "Error");
        }
      }
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Dialog
      open={open}
      onClose={hide}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="form-dialog-title">{`Selecionar Entregador ${regionName ? " - "+ regionName : ""}`} </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  id="courier"
                  size="small"
                  value={selectedCourier}
                  inputValue={courierSearchValue}
                  options={courierList}                  
                  getOptionLabel={(courier: CourierResponse) => courier.name}
                  loadingText="Aguarde ..."
                  noOptionsText="Sem opções"
                  renderOption={(courier) => (
                    <React.Fragment>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <ImgLoader src={courier.photo} width={40} height={40} />
                        </Grid>
                        <Grid item>
                          {`${courier.name} - ${courier.region.name} - (${t("vehicle_type." + courier.vehicle_type)})`}
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSelectedCourier(newValue);
                      formik.setFieldValue("courier", newValue.id);
                      formik.setFieldError("courier", undefined);
                    } else {
                      setSelectedCourier(undefined);
                      formik.setFieldValue("courier", null);
                      formik.setFieldError("courier", "Necessário selecionar um Entregador para continuar. Informe pelo menos 4 caracteres para realizar a busca.");
                    }
                  }}
                  onInputChange={(e: object, value: string, reason: string) => {
                    setCourierSearchValue(value);
                    if (reason === "input") {
                      updateCourierList(value);
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label="Entregador"
                      variant="outlined"
                      required={true}                      
                      error={!!formik.errors.courier}
                      helperText={formik.errors.courier || "Digite pelo menos 4 caracteres para realizar a busca"}
                      fullWidth
                    />
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide}>Cancelar</Button>
          <Button
            color="primary"
            type="submit"
            disabled={formik.isSubmitting}
            startIcon={
              formik.isSubmitting && (
                <CircularProgress size="1.5rem" color="inherit" />
              )
            }
          >
            Adicionar Entregador
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default React.forwardRef(SelectCourierSlotDialog);
