import React, { Fragment } from "react";
import type { FC } from "react";

import { Button, ButtonGroup, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { addDays, addWeeks, endOfWeek, format, isSameDay, isSameMonth, isSameWeek, isSameYear, isThisWeek, isToday, startOfDay, startOfWeek } from "date-fns";
import clsx from "clsx";
import { CURRENT_DATE_FORMAT, CURRENT_DATE_LOCALE, DEFAULT_LOCALE } from "../../../i18n";
import { ScheduleColumn } from "./scheduleColumn";
import { SlotResponse } from "../../../services/types/dedicated";
import ReactDatePicker from "react-datepicker";
import DateRangeIcon from '@material-ui/icons/DateRange';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TodayIcon from '@material-ui/icons/Today';
import Scrollbars from "react-custom-scrollbars-2";

interface CalendarProps {
  slots: SlotResponse[];
  initialDate?: Date;
  maxDate: Date;
  onDateRangeChange: (newDateRange: [Date, Date]) => void;
  renderItem: (slot: SlotResponse) => React.ReactNode;
}

export const Calendar: FC<CalendarProps> = ({
  slots = [],
  initialDate = new Date(),
  maxDate = endOfWeek(initialDate),
  onDateRangeChange,
  renderItem
}) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState<Date>(initialDate);
  const [slotMap, setSlotMap] = React.useState<Map<number, SlotResponse[]>>(new Map<number, SlotResponse[]>());
  const [weekDays, setWeekDays] = React.useState<Date[]>([
    startOfWeek(initialDate), 
    addDays(startOfWeek(initialDate), 1), 
    addDays(startOfWeek(initialDate), 2), 
    addDays(startOfWeek(initialDate), 3), 
    addDays(startOfWeek(initialDate), 4), 
    addDays(startOfWeek(initialDate), 5), 
    endOfWeek(initialDate)
  ]);

  const buildWeekDaysArray = React.useMemo(() => (date: Date) => {
    if ((weekDays.length === 0) || !isSameWeek(weekDays[0], date)) {
      const week: Date[] = [];
      const weekStart = startOfWeek(date);
      const weekEnd = endOfWeek(date);
      for (let day = weekStart; day <= weekEnd; day = addDays(day, 1)) {
        week.push(day);
      }
      setWeekDays(week);
      onDateRangeChange([weekStart, weekEnd]);
    }
  }, [weekDays, setWeekDays, onDateRangeChange]);

  const populateSlotMap = React.useMemo(() => (dates: Date[], slots: SlotResponse[], 
    slotMap: Map<number, SlotResponse[]>) => {
    dates.forEach(day => {
      const daySlots = slots.filter(slot => isSameDay(day, slot.start_datetime));
      slotMap.set(day.getDay(), daySlots);
    });
    return slotMap;
  }, []);

  const getSlotsForDate = (day: number) => {
    const slots = slotMap.get(day);
    return slots ? slots : [];
  }

  React.useLayoutEffect(() => {
    let slotMap = new Map<number, SlotResponse[]>();
    if (slots?.length > 0) {
      slotMap = populateSlotMap(weekDays, slots, slotMap);
    }
    setSlotMap(slotMap);
  }, [weekDays, slots, populateSlotMap]);

  React.useLayoutEffect(() => {
    if (selectedDate) {
      buildWeekDaysArray(startOfDay(selectedDate));
    }
  }, [selectedDate, buildWeekDaysArray]);

  const nextWeek = () => {
    const newDate = addWeeks(selectedDate, 1);
    setSelectedDate(newDate);
  }

  const canIncrementWeek = () => {
    const newDate = addWeeks(selectedDate, 1);
    return newDate < maxDate;
  }

  const previousWeek = () => {
    const newDate = addWeeks(selectedDate, -1);
    setSelectedDate(newDate);
  }

  function capitalize(string: string) {
    return string.toLocaleLowerCase().charAt(0).toUpperCase() + string.slice(1);
  }

  function getFormatedDateRange(date: Date): string {
    const startDate = startOfWeek(date);
    const endDate = endOfWeek(date);

    function formatDay(date: Date): string {
      return format(date, "d", { locale: CURRENT_DATE_LOCALE });
    }

    function formatMonth(date: Date): string {
      return capitalize(format(date, "MMM", { locale: CURRENT_DATE_LOCALE }));
    }

    function formatYear(date: Date): string {
      return format(date, "yyyy", { locale: CURRENT_DATE_LOCALE });
    }

    function formatDayAndMonth(date: Date): string {
      return formatDay(date) + " de " + formatMonth(date);
    }

    function formatDayMonthAndYear(date: Date): string {
      return formatDayAndMonth(date) + " de " + formatYear(date);
    }

    if (isSameMonth(startDate, endDate)) {
      return formatDay(startDate) + "  –  " + formatDayMonthAndYear(endDate);
    } else if (isSameYear(startDate, endDate)) {
      return formatDayAndMonth(startDate) + "  –  " + formatDayMonthAndYear(endDate);
    } else {
      return formatDayMonthAndYear(startDate) + "  –  " + formatDayMonthAndYear(endDate);
    }
  };

  return (
    <Fragment>
      <Grid container justify="space-between">
        <Grid item>
          <ButtonGroup size="small">
            <Button onClick={previousWeek}>
              <NavigateBeforeIcon />
            </Button>
            <Button onClick={nextWeek} disabled={!canIncrementWeek()}>
              <NavigateNextIcon />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <ReactDatePicker
            id="date"
            name="date"
            required={true}
            onChange={newDate => {
              if (newDate && !Array.isArray(newDate)) {
                setSelectedDate(newDate);
              }
            }}
            maxDate={maxDate}
            selected={selectedDate}
            locale={DEFAULT_LOCALE}
            dateFormat={CURRENT_DATE_FORMAT}
            calendarClassName="week_select"
            dayClassName={(date: Date) => {
              if (isSameWeek(date, selectedDate)) {
                return "react-datepicker__day--selected";
              }
              return "";
            }}
            customInput={
              <Grid container spacing={1} style={{ cursor: "pointer" }}>
                <Grid item>
                  <DateRangeIcon style={{ marginTop: "3px" }} />
                </Grid>
                <Grid item>
                  <Typography variant="h1">
                    {getFormatedDateRange(selectedDate)}
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        </Grid>
        <Grid item>
          <ButtonGroup size="small">
            <Button
              title="Ir para semana atual"
              disabled={isThisWeek(selectedDate)}
              onClick={() => setSelectedDate(new Date())}>
              <TodayIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      <Grid container spacing={0} wrap="nowrap" className={classes.page}>
        <Grid item xs className={classes.weekColumnsGrid}>
          <ScheduleColumn
            title={format(weekDays[0], "E d/M", { locale: CURRENT_DATE_LOCALE })}
            className={classes.list}
            titleClassName={clsx(classes.columnTitle, classes.firstColumnTitle, { 
              [classes.todayColumnTitle]: isToday(weekDays[0])
            })}>
            <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
            <div className={classes.listContent}>
              {getSlotsForDate(0).map((slot) => renderItem(slot))}
            </div>
            </Scrollbars>
          </ScheduleColumn>
        </Grid>

        <Grid item xs className={classes.weekColumnsGrid}>
          <ScheduleColumn
            title={format(weekDays[1], "E d/M", { locale: CURRENT_DATE_LOCALE })}
            className={classes.list}
            titleClassName={clsx(classes.columnTitle, { 
              [classes.todayColumnTitle]: isToday(weekDays[1])
            })}>
            <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
            <div className={classes.listContent}>
              {getSlotsForDate(1).map((slot) => renderItem(slot))}
            </div>
            </Scrollbars>         
          </ScheduleColumn>
        </Grid>

        <Grid item xs className={classes.weekColumnsGrid}>
          <ScheduleColumn
            title={format(weekDays[2], "E d/M", { locale: CURRENT_DATE_LOCALE })}
            className={classes.list}
            titleClassName={clsx(classes.columnTitle, { 
              [classes.todayColumnTitle]: isToday(weekDays[2])
            })}>
            <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
            <div className={classes.listContent}>
              {getSlotsForDate(2).map((slot) => renderItem(slot))}
            </div>
            </Scrollbars>
          </ScheduleColumn>
        </Grid>

        <Grid item xs className={classes.weekColumnsGrid}>
          <ScheduleColumn
            title={format(weekDays[3], "E d/M", { locale: CURRENT_DATE_LOCALE })}
            className={classes.list}
            titleClassName={clsx(classes.columnTitle, { 
              [classes.todayColumnTitle]: isToday(weekDays[3])
            })}>
            <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
            <div className={classes.listContent}>
              {getSlotsForDate(3).map((slot) => renderItem(slot))}
            </div>
            </Scrollbars>
          </ScheduleColumn>
        </Grid>

        <Grid item xs className={classes.weekColumnsGrid}>
          <ScheduleColumn
            title={format(weekDays[4], "E d/M", { locale: CURRENT_DATE_LOCALE })}
            className={classes.list}
            titleClassName={clsx(classes.columnTitle, { 
              [classes.todayColumnTitle]: isToday(weekDays[4])
            })}>
            <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
            <div className={classes.listContent}>
              {getSlotsForDate(4).map((slot) => renderItem(slot))}
            </div>
            </Scrollbars>
          </ScheduleColumn>
        </Grid>

        <Grid item xs className={classes.weekColumnsGrid}>
          <ScheduleColumn
            title={format(weekDays[5], "E d/M", { locale: CURRENT_DATE_LOCALE })}
            className={classes.list}
            titleClassName={clsx(classes.columnTitle, { 
              [classes.todayColumnTitle]: isToday(weekDays[5])
            })}>
            <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
            <div className={classes.listContent}>
              {getSlotsForDate(5).map((slot) => renderItem(slot))}
            </div>
            </Scrollbars>
          </ScheduleColumn>
        </Grid>

        <Grid item xs className={classes.weekColumnsGrid}>
          <ScheduleColumn
            title={format(weekDays[6], "E d/M", { locale: CURRENT_DATE_LOCALE })}
            className={classes.list}
            titleClassName={clsx(classes.columnTitle, classes.lastColumnTitle, { 
              [classes.todayColumnTitle]: isToday(weekDays[6])
            })}>
            <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
            <div className={classes.listContent}>
              {getSlotsForDate(6).map((slot) => renderItem(slot))}
            </div>
            </Scrollbars>
          </ScheduleColumn>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    display: "flex",
    flexFlow: "row",
    flex: 1,
    maxHeight: "calc(100% - 33px)",
    height: "calc(100% - 33px)",
  },
  weekColumnsGrid: {
  },
  list: {
    display: "flex",
    flexFlow: "column",
    flex: 1,
    maxHeight: "100%",
    height: "100%",
  },
  listContent: {
    flex: 1,
    marginTop: 0,
    padding: theme.spacing(1),
    overflow: "auto",
  },
  columnTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textTransform: "capitalize",
    position: "relative",
    borderWidth: "0 1px 0 0",
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.25)",
  },
  todayColumnTitle: {
    backgroundColor: "#d99528",
  },
  firstColumnTitle: {
    borderRadius: "4px 0 0 0",
  },
  lastColumnTitle: {
    borderRadius: "0 4px 0 0",
    borderWidth: "0",
  }
}));
