import { apiAxios as axios } from "../store/api/config";
import { parseISO } from "date-fns";
import { IPageableParams, PagedResponse } from "./types/common";
import { CommercialPlanDetails, CommercialPlanResponse, CreateUpdateAdditionalRulesPlan, CreateUpdateCommercialPlan } from "./types/commercialPlan";
import { getURL, urls } from "../store/api/urls";
import { convertToNumber } from "../utils/utils";

export interface ICommercialPlanService {
  getComercialPlans: (
    name: string | null,
    regionIds: (number | null)[],
    pageParams: IPageableParams
  ) => Promise<PagedResponse<CommercialPlanResponse>>;
  createCommercialPlan: (commercialPlan: CreateUpdateCommercialPlan) => Promise<any>;
  updateCommercialPlan: (commercialPlan: CreateUpdateCommercialPlan) => Promise<any>;
  getCommercialPlanById: (id: number) => Promise<CommercialPlanDetails>;
}

const CommercialPlanService: ICommercialPlanService = {
  getComercialPlans: async (
    name: string | null,
    regionIds: (number | null)[],
    pageParams: IPageableParams
  ) => {
    const url = getURL(urls.COMMERCIAL_PLAN, {
      ...pageParams,
      name: name,
      region: regionIds,
    });

    return new Promise((resolve, reject) => {
      axios.get(url, {})
        .then((response) => {
          const data: CommercialPlanResponse[] = response.data.results.map((item: any) => {
            return {
              ...item,
              id: Number(item.id),
              created_at: parseISO(item.created_at),
              updated_at: item.updated_at ? parseISO(item.updated_at) : null,
              minimum_value_distance: Number(item.minimum_value_distance),
              region: item.region ? {
                ...item.region,
                id: Number(item.region.id)
              } : null
            };
          });
          const result: PagedResponse<CommercialPlanResponse> = {
            data: data,
            count: response.data.count,
          };
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  createCommercialPlan: async (commercialPlan: CreateUpdateCommercialPlan) => {
    return axios
      .post(urls.COMMERCIAL_PLAN, commercialPlan)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  updateCommercialPlan: async (commercialPlan: CreateUpdateCommercialPlan) => {
    const url = `${urls.COMMERCIAL_PLAN}${commercialPlan.id}/`;
    return axios
      .put(url, commercialPlan)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  getCommercialPlanById: (id: number) => {
    const url = `${urls.COMMERCIAL_PLAN}${id}/`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {

          const resultAdditionalBike: CreateUpdateAdditionalRulesPlan[] = response.data.bike_additional_km_rules.map((item: any) => { 
            return { 
              ...item, 
              start_m: convertToNumber(item.start_m),
              end_m: convertToNumber(item.end_m),
              courier_value: convertToNumber(item.courier_value),
              speedy_value: convertToNumber(item.speedy_value),
            }
          });

          const resultAdditionalMotorcycle: CreateUpdateAdditionalRulesPlan[] = response.data.motorcycle_additional_km_rules.map((item: any) => { 
            return { 
              ...item, 
              start_m: convertToNumber(item.start_m),
              end_m: convertToNumber(item.end_m),
              courier_value: convertToNumber(item.courier_value),
              speedy_value: convertToNumber(item.speedy_value),
            }
          });

          const resultAdditionalCar: CreateUpdateAdditionalRulesPlan[] = response.data.car_additional_km_rules.map((item: any) => { 
            return { 
              ...item, 
              start_m: convertToNumber(item.start_m),
              end_m: convertToNumber(item.end_m),
              courier_value: convertToNumber(item.courier_value),
              speedy_value: convertToNumber(item.speedy_value),
            }
          });

          const resultAdditionalVan: CreateUpdateAdditionalRulesPlan[] = response.data.van_additional_km_rules.map((item: any) => { 
            return { 
              ...item, 
              start_m: convertToNumber(item.start_m),
              end_m: convertToNumber(item.end_m),
              courier_value: convertToNumber(item.courier_value),
              speedy_value: convertToNumber(item.speedy_value),
            }
          });

          const result = {
            ...response.data,
            id: Number(response.data.id),
            minimum_value_distance: Number(response.data.minimum_value_distance),
            cancel_fee_speedy: Number(response.data.cancel_fee_speedy),
            cancel_fee_courier: Number(response.data.cancel_fee_courier),
            created_at: parseISO(response.data.created_at),
            updated_at: response.data.updated_at ? parseISO(response.data.updated_at) : null,
            region: response.data.region ? {
              ...response.data.region,
              id: Number(response.data.region.id)
            } : null,

            bike_minimum_speedy_value: Number(response.data.bike_minimum_speedy_value),
            bike_minimum_courier_value: Number(response.data.bike_minimum_courier_value),
            bike_additional_km_speedy_value: Number(response.data.bike_additional_km_speedy_value),
            bike_additional_km_courier_value: Number(response.data.bike_additional_km_courier_value),
            bike_minimum_speedy_return_value: Number(response.data.bike_minimum_speedy_return_value),
            bike_minimum_courier_return_value: Number(response.data.bike_minimum_courier_return_value),
            bike_additional_km_speedy_return_value: Number(response.data.bike_additional_km_speedy_return_value),
            bike_additional_km_courier_return_value: Number(response.data.bike_additional_km_courier_return_value),
            bike_different_value_for_shared_trip: response.data.bike_different_value_for_shared_trip,
            bike_minimum_shared_trip_speedy_value: Number(response.data.bike_minimum_shared_trip_speedy_value),
            bike_minimum_shared_trip_courier_value: Number(response.data.bike_minimum_shared_trip_courier_value),
            bike_additional_km_shared_trip_speedy_value: Number(response.data.bike_additional_km_shared_trip_speedy_value),
            bike_additional_km_shared_trip_courier_value: Number(response.data.bike_additional_km_shared_trip_courier_value),
            bike_minimum_shared_trip_speedy_return_value: Number(response.data.bike_minimum_shared_trip_speedy_return_value),
            bike_minimum_shared_trip_courier_return_value: Number(response.data.bike_minimum_shared_trip_courier_return_value),
            bike_additional_km_shared_trip_speedy_return_value: Number(response.data.bike_additional_km_shared_trip_speedy_return_value),
            bike_additional_km_shared_trip_courier_return_value: Number(response.data.bike_additional_km_shared_trip_courier_return_value),
            bike_additional_km_rules: resultAdditionalBike,

            motorcycle_minimum_speedy_value: Number(response.data.motorcycle_minimum_speedy_value),
            motorcycle_minimum_courier_value: Number(response.data.motorcycle_minimum_courier_value),
            motorcycle_additional_km_speedy_value: Number(response.data.motorcycle_additional_km_speedy_value),
            motorcycle_additional_km_courier_value: Number(response.data.motorcycle_additional_km_courier_value),
            motorcycle_minimum_speedy_return_value: Number(response.data.motorcycle_minimum_speedy_return_value),
            motorcycle_minimum_courier_return_value: Number(response.data.motorcycle_minimum_courier_return_value),
            motorcycle_additional_km_speedy_return_value: Number(response.data.motorcycle_additional_km_speedy_return_value),
            motorcycle_additional_km_courier_return_value: Number(response.data.motorcycle_additional_km_courier_return_value),
            motorcycle_different_value_for_shared_trip: response.data.motorcycle_different_value_for_shared_trip,
            motorcycle_minimum_shared_trip_speedy_value: Number(response.data.motorcycle_minimum_shared_trip_speedy_value),
            motorcycle_minimum_shared_trip_courier_value: Number(response.data.motorcycle_minimum_shared_trip_courier_value),
            motorcycle_additional_km_shared_trip_speedy_value: Number(response.data.motorcycle_additional_km_shared_trip_speedy_value),
            motorcycle_additional_km_shared_trip_courier_value: Number(response.data.motorcycle_additional_km_shared_trip_courier_value),
            motorcycle_minimum_shared_trip_speedy_return_value: Number(response.data.motorcycle_minimum_shared_trip_speedy_return_value),
            motorcycle_minimum_shared_trip_courier_return_value: Number(response.data.motorcycle_minimum_shared_trip_courier_return_value),
            motorcycle_additional_km_shared_trip_speedy_return_value: Number(response.data.motorcycle_additional_km_shared_trip_speedy_return_value),
            motorcycle_additional_km_shared_trip_courier_return_value: Number(response.data.motorcycle_additional_km_shared_trip_courier_return_value),
            motorcycle_additional_km_rules: resultAdditionalMotorcycle,

            car_minimum_speedy_value: Number(response.data.car_minimum_speedy_value),
            car_minimum_courier_value: Number(response.data.car_minimum_courier_value),
            car_additional_km_speedy_value: Number(response.data.car_additional_km_speedy_value),
            car_additional_km_courier_value: Number(response.data.car_additional_km_courier_value),
            car_minimum_speedy_return_value: Number(response.data.car_minimum_speedy_return_value),
            car_minimum_courier_return_value: Number(response.data.car_minimum_courier_return_value),
            car_additional_km_speedy_return_value: Number(response.data.car_additional_km_speedy_return_value),
            car_additional_km_courier_return_value: Number(response.data.car_additional_km_courier_return_value),
            car_different_value_for_shared_trip: response.data.car_different_value_for_shared_trip,
            car_minimum_shared_trip_speedy_value: Number(response.data.car_minimum_shared_trip_speedy_value),
            car_minimum_shared_trip_courier_value: Number(response.data.car_minimum_shared_trip_courier_value),
            car_additional_km_shared_trip_speedy_value: Number(response.data.car_additional_km_shared_trip_speedy_value),
            car_additional_km_shared_trip_courier_value: Number(response.data.car_additional_km_shared_trip_courier_value),
            car_minimum_shared_trip_speedy_return_value: Number(response.data.car_minimum_shared_trip_speedy_return_value),
            car_minimum_shared_trip_courier_return_value: Number(response.data.car_minimum_shared_trip_courier_return_value),
            car_additional_km_shared_trip_speedy_return_value: Number(response.data.car_additional_km_shared_trip_speedy_return_value),
            car_additional_km_shared_trip_courier_return_value: Number(response.data.car_additional_km_shared_trip_courier_return_value),
            car_additional_km_rules: resultAdditionalCar,

            van_minimum_speedy_value: Number(response.data.van_minimum_speedy_value),
            van_minimum_courier_value: Number(response.data.van_minimum_courier_value),
            van_additional_km_speedy_value: Number(response.data.van_additional_km_speedy_value),
            van_additional_km_courier_value: Number(response.data.van_additional_km_courier_value),
            van_minimum_speedy_return_value: Number(response.data.van_minimum_speedy_return_value),
            van_minimum_courier_return_value: Number(response.data.van_minimum_courier_return_value),
            van_additional_km_speedy_return_value: Number(response.data.van_additional_km_speedy_return_value),
            van_additional_km_courier_return_value: Number(response.data.van_additional_km_courier_return_value),
            van_different_value_for_shared_trip: response.data.van_different_value_for_shared_trip,
            van_minimum_shared_trip_speedy_value: Number(response.data.van_minimum_shared_trip_speedy_value),
            van_minimum_shared_trip_courier_value: Number(response.data.van_minimum_shared_trip_courier_value),
            van_additional_km_shared_trip_speedy_value: Number(response.data.van_additional_km_shared_trip_speedy_value),
            van_additional_km_shared_trip_courier_value: Number(response.data.van_additional_km_shared_trip_courier_value),
            van_minimum_shared_trip_speedy_return_value: Number(response.data.van_minimum_shared_trip_speedy_return_value),
            van_minimum_shared_trip_courier_return_value: Number(response.data.van_minimum_shared_trip_courier_return_value),
            van_additional_km_shared_trip_speedy_return_value: Number(response.data.van_additional_km_shared_trip_speedy_return_value),
            van_additional_km_shared_trip_courier_return_value: Number(response.data.van_additional_km_shared_trip_courier_return_value),
            van_additional_km_rules: resultAdditionalVan,

          } as CommercialPlanDetails;

          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

};

export default CommercialPlanService;
