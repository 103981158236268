import React, { useCallback, useEffect, useState } from "react";
import { Grid, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { CURRENCY_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import MaterialTable from "@material-table/core";
import { CommissionReportsResponse } from "../../services/types/commissionReports";
import { decodeURLParams } from "../../utils/utils";
import CommissionReportsService from "../../services/commissionReports";
import { CommissionReportsFilter, ICommissionReportsFilterParams, INITIAL_FILTER_PARAMS } from "./CommissionReportsFilter";
import { useTranslation } from "react-i18next";
import numbro from "numbro";
import UserService from "../../services/userService";
import { UserResponse, UserStatus } from "../../services/types/user";
import AuthService from "../../services/authService";

export interface ICommissionObject {
  id: number;
  month_year: string;
  value_total: number;
};

export const CommissionReports = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [filterParams, setFilterParams] = useState<ICommissionReportsFilterParams>({
    ...INITIAL_FILTER_PARAMS,
    ...decodeURLParams(history.location.search)
  });
  const [rows, setRows] = useState<ICommissionObject[]>([]);
  const [commissionsResult, setCommissionsResult] = useState<CommissionReportsResponse[]>([]);
  const [managementUsers, setManagementUsers] = useState<UserResponse[]>([]);

  const loadData = useCallback(() => {
    if (filterParams.management_user) {
      CommissionReportsService.getCommissionReports(
        filterParams.management_user)
        .then(response => {
          var resultCommissions: ICommissionObject[] = [];
          response.data.forEach((item, index) => {
            if(resultCommissions.length === 0){
              resultCommissions.push({id: index, month_year: item.month_year, value_total: 0});
            }else{
              var existe = resultCommissions.find(obj => obj.month_year === item.month_year);
              if(!existe){
                resultCommissions.push({id: index, month_year: item.month_year, value_total: 0});
              }
            }
          });

          resultCommissions.forEach((item, index) => {
            var total_value: number = 0;
            var lista: CommissionReportsResponse[] = response.data.filter(obj => obj.month_year === item.month_year);
            if(lista.length > 0){
             lista.forEach(e => {
              total_value += e.value;
             })
            }
            resultCommissions[index].value_total = total_value;
          });

          setRows(resultCommissions);
          setCommissionsResult(response.data);
        })
    }
  }, [filterParams]);

  const applyFilter = (params: ICommissionReportsFilterParams) => {
    setFilterParams(params);
  };

  useEffect(() => {
    loadData();
  }, [filterParams, loadData]);

  const getManagementUsers = () => {
    UserService.getUsers("", "", UserStatus.ACTIVE, "", new PageableParams(1, 99999)).then((users) => {
        if (users.data) {
            setManagementUsers(users.data);
        }
    }).catch(() => { });
  };

  useEffect(() => {   
    if(!AuthService.hasAuthorization("change_customer_management_user_to_any")){  
      const currentUser = AuthService.getCurrentUser();  
      if(currentUser){
        setManagementUsers([{id: currentUser.id, name: currentUser.name, email: currentUser.email, groups: currentUser.groups, user_status: UserStatus.ACTIVE}])
      }
    }else{
      getManagementUsers();
    }   
  }, []);

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Comissões</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[{ label: "Comissões", url: "/commissionReports" }]}
            />
          </Grid>
        </Grid>
      </Grid>

      <CommissionReportsFilter params={filterParams} managementUsers={managementUsers} onFilterChanged={applyFilter} />

      <MaterialTable
        columns={[
          { field: "id", hidden: true, width: 60 },
          { title: 'Mês Referência', field: 'month_year',
            render: (rowData, type) => {
              var fieldSplit = rowData.month_year.split("-");             
              return t("month_names." + fieldSplit[0]) + " - " + fieldSplit[1];
            }
          },
          {
            ...CURRENCY_COLUMN_DEFAULTS,
            title: "Valor Total", field: "value_total"
          },
        ]}
        data={rows}
        title="Comissões"
        detailPanel={(row) => {
          const filterItens: CommissionReportsResponse[] = commissionsResult.filter(e => e.month_year === row.rowData.month_year);
          return (
            <Table style={{width: "90%", marginLeft: "10%"}} size="small" aria-label="purchases">
            <TableBody>
              {filterItens.map((rowItem: CommissionReportsResponse) => (
                <TableRow key={rowItem.id}>
                  <TableCell component="th" scope="row">
                    {rowItem.customer.trade_name}
                  </TableCell>
                  <TableCell align="right">
                    {numbro(Number(rowItem.value)).formatCurrency()}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow key={"last-item-"+row.rowData.id}>
                  <TableCell component="th" scope="row">
                  
                  </TableCell>
                  <TableCell style={{fontWeight: "bold"}} align="right">
                    {numbro(Number(row.rowData.value_total)).formatCurrency()}
                  </TableCell>
                </TableRow>
            </TableBody>
          </Table>
          )
        }}
        onRowClick={(event, rowData, togglePanel) => {if(togglePanel) togglePanel()}}
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_L10N_PTBR}
        options={{
          ...DEFAULT_TABLE_OPTIONS,
          paging: false,
          draggable: false
        }}
      />
    </div>
  );
};