import { Button, Chip, Menu, MenuItem } from "@material-ui/core";
import React from "react";

export interface Item {
  value: string | number;
  label: string;
}

interface DialogFilterColumnProps {
  placeholder?: string;
  triggerClassName?: string;
  triggerType?: "button" | "chip";
  size?: "small" | "medium";
  horizontalMenuAnchor?: "right" | "left";
  itens: Item[];
  selectedValue: string | number;
  icon?: React.ReactElement;
  onSelect: (value: string | number) => void;
}

export const DashboardSelectMenu: React.FC<DialogFilterColumnProps> = ({
  placeholder = "",
  triggerClassName,
  triggerType = "button",
  size = "medium",
  horizontalMenuAnchor = "left",
  itens,
  selectedValue,
  icon,
  onSelect,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (value: string | number) => {
    onSelect(value);
    handleCloseMenu();
  }

  return (
    <React.Fragment>
      {triggerType === "button" && (
        <Button
          className={triggerClassName}
          size={size}
          aria-label="filters"
          onClick={handleOpenMenu}
          endIcon={icon}
        >
          {itens.find((item) => item.value === selectedValue)?.label || placeholder}
        </Button>
      )}
      {triggerType === "chip" && (
        <Chip
          icon={icon}
          className={triggerClassName}
          variant="outlined"
          label={itens.find((item) => item.value === selectedValue)?.label || placeholder}
          onClick={handleOpenMenu}
        />
      )}
      <Menu
        anchorOrigin={{ vertical: "top", horizontal: horizontalMenuAnchor }}
        transformOrigin={{ vertical: "top", horizontal: horizontalMenuAnchor }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {itens.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            onClick={() => { handleItemClick(item.value) }}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default DashboardSelectMenu;
