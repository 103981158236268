import React from "react";
import { TextMaskCustom } from "./TextMaskCustom";
import { mobilePhoneMask, phoneMask } from "../utils/masks";

export const PhoneInput = (props: any) => {
  function _mask(rawValue: string) {
    let result = phoneMask;
    let digits = rawValue.match(/\d/g)?.join("");
    if(digits){
      if(digits.length > 10){
        result = mobilePhoneMask;
      }
    }  

    return result;
  }

  return <TextMaskCustom guide={false} {...props} mask={_mask} />;
};
