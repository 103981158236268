import React from "react";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import theme from "../../../styles/theme";
import { CourierUserStatus, OnlineCourierUserDetails } from "../../../services/types/courier";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { Map } from "leaflet";
import LeafletMarker from "../../../components/maps/LeafletMarker";
import CourierPopup from "../../../components/maps/CourierPopup";
import { StatsContainer } from "../customComponents/statsContainer";
import { CourierCard } from "../customComponents/courierCard";
import { DirectionsBikeOutlined, DirectionsCarOutlined, MotorcycleOutlined, Storefront } from "@material-ui/icons";
import { PendingDeliveryRequest } from "../../../services/types/deliveryRequest";
import { RegionResponse } from "../../../services/types/region";
import DashboardColumn from "../customComponents/dashboardColumn";
import CustomControl from "../../../components/maps/CustomControl";
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import { StoreResponse } from "../../../services/types/customer";
import StorePopup from "../../../components/maps/StorePopup";

interface DashboardMapViewProps {
    courierList: OnlineCourierUserDetails[];
    storeList: StoreResponse[];
    region?: RegionResponse;
    searchValue?: string;
    deliveryRequestDetailsAction: (deliveryRequest: PendingDeliveryRequest | number) => void;
}

export const DashboardMapView: React.FC<DashboardMapViewProps> = ({ courierList, storeList, region, searchValue, deliveryRequestDetailsAction }) => {
    const DEFAULT_MAP_POSITION: [number, number][] = ([[-33.8689056, -73.9830625], [5.2842873, -28.6341164]]); //Brasil

    const classes = useStyles();

    const [courierFilteredList, setCourierFilteredList] = React.useState<OnlineCourierUserDetails[]>([]);
    const [map, setMap] = React.useState<Map>();

    const [availableCouriersCount, setAvailableCouriersCount] = React.useState<number>(0);
    const [busyCouriersCount, setBusyCouriersCount] = React.useState<number>(0);

    const [selectedCourierId, setSelectedCourierId] = React.useState<number>();
    const [lastRegionId, setLastRegionId] = React.useState<number>();

    React.useEffect(() => {
        let totalAvailable = 0;
        let totalBusy = 0;

        if (courierList && courierList[0]) {
            courierList.forEach((courier: OnlineCourierUserDetails) => {
                if (courier.courier_status === CourierUserStatus.AVAILABLE) {
                    totalAvailable++;
                } else {
                    totalBusy++;
                }
            });
        }

        setAvailableCouriersCount(totalAvailable);
        setBusyCouriersCount(totalBusy);
    }, [courierList]);

    React.useEffect(() => {
        const filteredList = courierList?.filter((courier) => {
            if (searchValue?.trim()) {
                const value = searchValue.toLocaleLowerCase();
                if (courier.name.toLocaleLowerCase().includes(value)) {
                    return true;
                }
                if (courier.phonenumber.toLocaleLowerCase().includes(value)) {
                    return true;
                }
                return false;
            } else {
                return true;
            }
        }).sort((a, b) => {
            const aName = a.name.toLowerCase().trim();
            const bName = b.name.toLowerCase().trim();
            if (aName > bName) {
                return 1;
            }
            if (aName < bName) {
                return -1;
            }
            return 0;
        });

        if (filteredList) {
            setCourierFilteredList(filteredList);
        } else {
            setCourierFilteredList([]);
        }
    }, [courierList, searchValue]);

    React.useLayoutEffect(() => {
        if (map) {
            let positions: [number, number][] | null = null;

            if (region) {
                if (region.id !== lastRegionId) {
                    setLastRegionId(region.id);
                    if (region.coordinates && region.coordinates.length) {
                        positions = [];
                        const coords = region.coordinates;
                        for (let i = 0; i < coords.length; i++) {
                            const tuple: [number, number] = [coords[i].latitude, coords[i].longitude];
                            positions.push(tuple);
                        }
                    }
                }
            } else {
                positions = DEFAULT_MAP_POSITION;
            }

            if (positions) {
                try {
                    map.flyToBounds(positions);
                } catch (error) {
                    map.fitBounds(positions);
                }
            }
        }
    }, [map, region, DEFAULT_MAP_POSITION, lastRegionId]);

    const centerInRegion = () => {
        if (map && region) {
            if (region.coordinates && region.coordinates.length) {
                const positions: [number, number][] = [];
                const coords = region.coordinates;
                for (let i = 0; i < coords.length; i++) {
                    const tuple: [number, number] = [coords[i].latitude, coords[i].longitude];
                    positions.push(tuple);
                }
                try {
                    map.flyToBounds(positions);
                } catch (error) {
                    map.fitBounds(positions);
                }
            }
        }
    }

    const toggleCourierSelection = (courier: OnlineCourierUserDetails) => {
        if (selectedCourierId === courier.id) {
            setSelectedCourierId(undefined);
        } else {
            setSelectedCourierId(courier.id);
            map?.flyTo([courier.latitude, courier.longitude]);
        }
    }

    return (
        <React.Fragment>
            <Grid item lg={8} className={classes.dashboardGridColumn}>
                <div className={classes.mapWrapper}>
                    <MapContainer whenCreated={setMap} zoomControl={false}>
                        <ZoomControl position="bottomright" />
                        <CustomControl position="topright">
                            <IconButton onClick={centerInRegion} title="Centralizar na Região">
                                <CenterFocusStrongIcon />
                            </IconButton>
                        </CustomControl>
                        <TileLayer
                            attribution={'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {courierList && courierList.map((courier, index) => {
                            let markerClassName =
                                courier.courier_status === CourierUserStatus.AVAILABLE
                                    ? classes.availableCouriers
                                    : classes.busyCouriers;

                            let zIndex: number = 0;
                            if (courier.courier_status === CourierUserStatus.AVAILABLE) {
                                zIndex = -9999999;
                            }
                            if (courier.id === selectedCourierId) {
                                markerClassName = classes.selectedCourier;
                                zIndex = 9999999;
                            }

                            let icon: React.ReactNode;
                            switch (courier.vehicle_type) {
                                case "CAR":
                                    icon = <DirectionsCarOutlined />
                                    break;
                                case "BIKE":
                                    icon = <DirectionsBikeOutlined />
                                    break;
                                default:
                                    icon = <MotorcycleOutlined />
                                    break;
                            }

                            return (
                                <LeafletMarker
                                    key={index}
                                    position={[Number(courier.latitude), Number(courier.longitude)]}
                                    className={markerClassName}
                                    internalIcon={icon}
                                    riseOnHover={true}
                                    zIndexOffset={zIndex}
                                    eventHandlers={{
                                        mouseover: (e) => {
                                            e.target.openPopup();
                                        }
                                    }}
                                >
                                    <CourierPopup viewDetailsAction={deliveryRequestDetailsAction} info={courier} />
                                </LeafletMarker>
                            )
                        })}

                        {storeList && storeList.map((store, index) => {
                            return (
                                <LeafletMarker
                                    key={index}
                                    position={[Number(store.latitude), Number(store.longitude)]}
                                    className={classes.store}
                                    internalIcon={<Storefront />}
                                    riseOnHover={true}
                                    zIndexOffset={-99999999}
                                    eventHandlers={{
                                        mouseover: (e) => {
                                            e.target.openPopup();
                                        }
                                    }}
                                >
                                    <StorePopup info={store} />
                                </LeafletMarker>
                            )
                        })}

                        <StatsContainer
                            availableCouriers={availableCouriersCount}
                            busyCouriers={busyCouriersCount}>
                        </StatsContainer>
                    </MapContainer>
                </div>
            </Grid>
            <Grid item lg={4} className={classes.dashboardGridColumn}>
                <DashboardColumn
                    title="Entregadores"
                    className={classes.list}
                    titleClassName={classes.courierTitle}
                >
                    <div className={classes.listContent}>
                        {courierFilteredList.map(
                            (courier, index) => (
                                <CourierCard
                                    key={index}
                                    courier={courier}
                                    selected={courier.id === selectedCourierId}
                                    action={toggleCourierSelection}
                                />
                            )
                        )}
                    </div>
                </DashboardColumn>
            </Grid>
        </React.Fragment>
    );
}

const useStyles = makeStyles({
    dashboardGridColumn: {
        height: "calc(100vh - 108px)",
    },
    mapWrapper: {
        position: "relative",
        width: "100%",
        borderRadius: "4px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    availableCouriers: {
        backgroundColor: theme.palette.primary.main,
    },
    busyCouriers: {
        backgroundColor: theme.palette.secondary.main,
    },
    selectedCourier: {
        backgroundColor: theme.palette.warning.main,
    },
    store: {
        backgroundColor: theme.palette.success.main,
    },
    courierTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    list: {
        display: "flex",
        flexFlow: "column",
        flex: 1,
        maxHeight: "100%",
        height: "100%",
    },
    listContent: {
        flex: 1,
        marginTop: 0,
        padding: theme.spacing(1),
        overflow: "auto",
    },
});