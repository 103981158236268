import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ReactComponent as LogoNameIcon } from "../assets/img/logo-branca.svg";
import { Collapse, Grid, Menu, MenuItem, Tooltip, withStyles } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { Theme, makeStyles, createStyles } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import DashboardIcon from '@material-ui/icons/Dashboard';
import MapIcon from "@material-ui/icons/Map";
import NotificationsIcon from "@material-ui/icons/NotificationsActive";
import AssistantIcon from "@material-ui/icons/Assistant";
import Satellite from "@material-ui/icons/Satellite";
import HistoryIcon from "@material-ui/icons/History";
import MotorcycleRoundedIcon from "@material-ui/icons/MotorcycleRounded";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import NextWeekIcon from "@material-ui/icons/NextWeek";
import NotesIcon from "@material-ui/icons/Notes";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { ExpandLess, ExpandMore, ListAlt } from "@material-ui/icons";
import AuthService from "../services/authService";
import { LoggedUser } from "../services/types/user";
import { Actions as AuthActions } from "../store/ducks/auth";
import { useAppContext } from "../utils/appContext";
import AnnouncementIcon from '@material-ui/icons/Announcement';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ChangeOwnPassword from "./ChangeOwnPassword";
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { MonetizationOnRounded } from "@material-ui/icons";
import BuildIcon from '@material-ui/icons/Build';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '../store/types/state';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PieChartIcon from '@material-ui/icons/PieChart';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { ReactComponent as LogoIcon } from "../assets/img/favicon-branco.svg";

const drawerWidth = 240;

const sidebarItens = [
    {
        type: "TITLE",
        text: "Painel",
        icon: <MapIcon style={{ fontSize: "1.5rem" }} />,
        link: "/operational/dashboard",
        submenu: false,
        requireAuth: ["view_deliveryrequest"],
        items: []
    },
    {
        type: "TITLE",
        text: "Operacional",
        icon: <ListAlt style={{ fontSize: "1.5rem" }} />,
        requireAuth: ["view_deliveryrequest", "view_annoucement", "add_annoucement", "change_announcement",
            "view_courier", "add_courier", "change_courier", "view_incentivedynamic", "add_incentivedynamic",
            "change_incentivedynamic"],
        link: "/menuOperational",
        submenu: true,
        items: [
            {
                text: "Filas",
                icon: <FormatListNumberedIcon style={{ fontSize: "1.5rem" }} />,
                link: "/storeQueue",
                requireAuth: ["view_deliveryrequest"],
                submenu: false,
                items: []
            },
            {
                text: "Solicitações",
                icon: <HistoryIcon style={{ fontSize: "1.5rem" }} />,
                link: "/requests",
                requireAuth: ["view_deliveryrequest"],
                submenu: false,
                items: []
            },
            {
                text: "Avisos",
                icon: <NotificationsIcon style={{ fontSize: "1.5rem" }} />,
                link: "/announcement",
                requireAuth: ["view_announcement", "add_announcement", "change_announcement"],
                submenu: false,
                items: []
            },
            {
                text: "Entregadores",
                icon: <MotorcycleRoundedIcon style={{ fontSize: "1.5rem" }} />,
                link: "/courier",
                requireAuth: ["view_courier", "add_courier", "change_courier"],
                submenu: false,
                items: []
            },
            {
                text: "Incentivos",
                icon: <AssistantIcon style={{ fontSize: "1.5rem" }} />,
                link: "/incentive",
                requireAuth: ["view_incentivedynamic", "add_incentivedynamic", "change_incentivedynamic"],
                submenu: false,
                items: []
            },
            {
                text: "Ocorrências",
                icon: <AnnouncementIcon style={{ fontSize: "1.5rem" }} />,
                link: "/occurrences",
                requireAuth: ["view_courier"],
                submenu: false,
                items: []
            },
            {
                text: "Agendamentos",
                icon: <EventNoteIcon style={{ fontSize: "1.5rem" }} />,
                link: "/dedicated",
                requireAuth: ["view_dedicatedproposal"],
                submenu: false,
                items: []
            },
        ]
    },

    {
        type: "TITLE",
        text: "Comercial",
        icon: <AssignmentIndIcon style={{ fontSize: "1.5rem" }} />,
        requireAuth: ["view_customer", "add_customer", "change_customer", "view_commercialplan",
            "add_commercialplan", "change_commercialplan", "view_customer_finances_entries"],
        link: "/menuCommercial",
        submenu: true,
        items: [
            {
                text: "Clientes",
                icon: <PeopleAltIcon style={{ fontSize: "1.5rem" }} />,
                link: "/customer",
                requireAuth: ["view_customer", "add_customer", "change_customer"],
                submenu: false,
                items: []
            },
            {
                text: "Créditos",
                icon: <AttachMoneyIcon style={{ fontSize: "1.5rem" }} />,
                link: "/credit",
                requireAuth: ["view_customer_finances_entries"],
                submenu: false,
                items: []
            },
            {
                text: "Planos",
                icon: <ListAlt style={{ fontSize: "1.5rem" }} />,
                link: "/commercialPlan",
                requireAuth: ["view_commercialplan", "add_commercialplan", "change_commercialplan"],
                submenu: false,
                items: []
            },
            {
                text: "Comissões",
                icon: <MonetizationOnRounded style={{ fontSize: "1.5rem" }} />,
                link: "/commissionReports",
                requireAuth: ["view_commission"],
                submenu: false,
                items: []
            },
        ]
    },

    {
        type: "TITLE",
        text: "Financeiro",
        link: "/menuRemittance",
        icon: <MonetizationOnRounded style={{ fontSize: "1.5rem" }} />,
        requireAuth: ["view_remittance", "admin"],
        submenu: true,
        items: [
            {
                text: "Remessas",
                icon: <NextWeekIcon style={{ fontSize: "1.5rem" }} />,
                link: "/remittance",
                requireAuth: ["view_remittance"],
                submenu: false,
                items: []
            },
            {
                text: "Notas de Débito",
                icon: <NotesIcon style={{ fontSize: "1.5rem" }} />,
                link: "/debitNotes",
                requireAuth: ["view_debit_note"],
                submenu: false,
                items: []
            },
        ]
    },

    {
        type: "TITLE",
        text: "Relatórios",
        icon: <PieChartIcon style={{ fontSize: "1.5rem" }} />,
        requireAuth: ["view_courier"],
        link: "/menuReport",
        submenu: true,
        items: [
            {
                text: "Top Entregadores",
                icon: <FormatListNumberedIcon style={{ fontSize: "1.5rem" }} />,
                link: "/topCouriers",
                requireAuth: ["view_courier"],
                submenu: false,
                items: []
            },
            {
                text: "Ranking",
                icon: <EmojiEventsIcon style={{ fontSize: "1.5rem" }} />,
                link: "/rankingCouriers",
                requireAuth: ["view_courier"],
                submenu: false,
                items: []
            },
            {
                text: "Não Recarregaram",
                icon: <MoneyOffIcon style={{ fontSize: "1.5rem" }} />,
                link: "/notRechargingCustomers",
                requireAuth: ["view_customer"],
                submenu: false,
                items: []
            },
            {
                text: "Relatório Entregadores",
                icon: <AssessmentIcon style={{ fontSize: "1.5rem" }} />,
                link: "/reportCouriers",
                requireAuth: ["view_courier"],
                submenu: false,
                items: []
            },
            {
                text: "Relatório Clientes",
                icon: <EqualizerIcon style={{ fontSize: "1.5rem" }} />,
                link: "/reportCustomersActive",
                requireAuth: ["view_customerreports"],
                submenu: false,
                items: []
            },
        ]
    },
    {
        type: "TITLE",
        text: "Administração",
        icon: <SupervisorAccountIcon style={{ fontSize: "1.5rem" }} />,
        requireAuth: ["view_user", "add_user", "change_user", "view_region", "add_region", "change_region"],
        link: "/menuConfiguration",
        submenu: true,
        items: [
            {
                text: "Painel",
                icon: <DashboardIcon style={{ fontSize: "1.5rem" }} />,
                link: "/panel",
                requireAuth: ["view_panel"],
                submenu: false,
                items: []
            },
            {
                text: "Configurações",
                icon: <BuildIcon style={{ fontSize: "1.5rem" }} />,
                link: "/configurations",
                requireAuth: ["view_configuration"],
                submenu: false,
                items: []
            },
            {
                text: "Usuários",
                icon: <PeopleIcon style={{ fontSize: "1.5rem" }} />,
                link: "/user",
                requireAuth: ["view_user", "add_user", "change_user"],
                submenu: false,
                items: []
            },
            {
                text: "Regiões",
                icon: <Satellite style={{ fontSize: "1.5rem" }} />,
                link: "/region",
                requireAuth: ["view_region", "add_region", "change_region"],
                submenu: false,
                items: []
            },
            {
                text: "Códigos de Indicação",
                icon: <ConfirmationNumberIcon style={{ fontSize: "1.5rem" }} />,
                link: "/newReferral",
                requireAuth: ["view_referral", "add_referral, add_courierreferralcode"],
                submenu: false,
                items: []
            },
        ]
    },

];

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "#1d5d90 !important",
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
    arrow: {
        color: "#1d5d90 !important",
    },
}))(Tooltip);

interface ISideBarTopBarProps {
    changeMenu: boolean;
}

export const SidebarTopBar: React.FC<ISideBarTopBarProps> = ({changeMenu}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const isMenuOpen = useSelector((state: IGlobalState) => state.app.isMenuOpen);
    const { userHasAuthenticated } = useAppContext();
    const [user, setUser] = React.useState<LoggedUser>();
    const [accountMenuAnchor, setAccountMenuAnchor] = React.useState<null | HTMLElement>(null);
    const [showChangeOwnPasswordDialog, setShowChangeOwnPasswordDialog] = React.useState(false);
    const [isOpenCollapse, setIsOpenCollapse] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const [open, setOpen] = React.useState(true);

    React.useEffect(() =>{
        if(changeMenu){
            handleDrawerOpen();
        }else{
            handleDrawerClose();
        }
    },[changeMenu]);

    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    React.useEffect(() => {
        if (isMenuOpen && !user) {
            setUser(AuthService.getCurrentUser());
        }
    }, [isMenuOpen, user]);

    const handleLogoff = React.useCallback(() => {
        AuthActions.disconnect(dispatch)
        userHasAuthenticated(false);
        history.push("/");
    }, [dispatch, history, userHasAuthenticated]);

    const handleCloseAccountMenu = () => {
        setAccountMenuAnchor(null);
    };

    const openChangeOwnPasswordDialog = () => {
        handleCloseAccountMenu();
        setShowChangeOwnPasswordDialog(true);
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
      };

    const Match = (url: string) => {
        const match = useRouteMatch(url);
        return !!match;
    };

    const handleOpen = (clickedIndex: any) => {
        if (isOpenCollapse === clickedIndex) {
            setIsOpenCollapse(null);
        } else {
            setIsOpenCollapse(clickedIndex);
        }
    };

    return (
        <div className={classes.root}>
            <ChangeOwnPassword modal={{ open: showChangeOwnPasswordDialog, setOpen: setShowChangeOwnPasswordDialog }} />

            <CssBaseline />
            <AppBar
                position="fixed"
                style={{ boxShadow: "none" }}
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                {/* Caso precise utilizar a Barra do menu */}
                {/* <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        <LogoNameIcon width={140} fill="#fff" />
                    </Typography>


                    <Button
                        variant="text"
                        color="secondary"
                        //onClick={handleMenu}
                        style={{ color: "white" }}
                        startIcon={<AccountCircle />}
                    >
                        administrador
                    </Button>
                </Toolbar> */}
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    {open ? (
                        <>
                            <Typography variant="h6" className={classes.title}>
                                <Link to="/operational/dashboard">
                                    {open && (
                                        <LogoNameIcon width={140} fill="#fff" />
                                    )}
                                </Link>
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography variant="h6" className={classes.title}>
                                <LogoIcon width={25} fill="#fff" />
                            </Typography>
                        </>
                    )}
                    
                </div>
                <List component="div" disablePadding>
                        {user && (                                           
                            <div>
                                <LightTooltip title={open === true ? "" : user.name} placement="right" arrow>                                                              
                                    <MenuItem
                                        button
                                        className="submenu-item"
                                        component={MenuItem}
                                        onClick={handleMenu}
                                        key={user.name}
                                    >
                                        <ListItemIcon className={classes.titleMenu}>
                                            <AccountCircle/>
                                        </ListItemIcon>
                                        <ListItemText primary={<span className={classes.titleMenu}>{user.name}</span>} />
                                    </MenuItem>
                                </LightTooltip>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                    }}
                                    open={openMenu}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={openChangeOwnPasswordDialog}>Alterar Senha</MenuItem>
                                    <MenuItem onClick={handleLogoff}>Sair</MenuItem>
                                </Menu>
                            </div>  
                        )}                
                    </List>
                <Grid item className={classes.buttons}>
                    <Divider />
                    <List component="nav">
                        {sidebarItens.map((sidebarItem, index) => {
                            if ((sidebarItem.requireAuth.length > 0) && (!AuthService.hasAuthorization(sidebarItem.requireAuth))) {
                                return <React.Fragment key={index}></React.Fragment>;
                            } else {
                                return (
                                    <React.Fragment key={index}>
                                        <LightTooltip title={open === true ? "" : sidebarItem.text} placement="right" arrow>                                                              
                                            <MenuItem
                                                button
                                                component={
                                                    sidebarItem.submenu ? MenuItem : Link
                                                }
                                                to={sidebarItem.link}
                                                onClick={
                                                    sidebarItem.submenu ? () => { handleOpen(index) } : () => { setIsOpenCollapse(null); }
                                                }
                                                selected={Match(sidebarItem.link)}
                                            >
                                                <ListItemIcon className={classes.titleMenu}>
                                                    {sidebarItem.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={<span className={Match(sidebarItem.link) ? classes.titleMenuSelected : classes.titleMenu}>{sidebarItem.text}</span>} />
                                                {sidebarItem.submenu && (
                                                    isOpenCollapse === index ? (
                                                        <ExpandLess className={classes.titleMenu} />
                                                    ) : (
                                                        <ExpandMore className={classes.titleMenu} />
                                                    )
                                                )}
                                            </MenuItem>
                                        </LightTooltip>
                                        {sidebarItem.items && (
                                            <Collapse className={classes.backgroundSubItem} in={isOpenCollapse === index} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {sidebarItem.items.map((subItem, idx) => {
                                                        return (
                                                            <div>
                                                                <LightTooltip title={open === true ? "" : subItem.text} placement="right" arrow>                                                              
                                                                    <MenuItem
                                                                        button
                                                                        className={open === true ? classes.styleMenuItem : classes.styleMenuItemMinimized}
                                                                        component={Link}
                                                                        to={subItem.link}
                                                                        selected={Match(
                                                                            subItem.link
                                                                        )}
                                                                        key={subItem.text}
                                                                    >
                                                                        <ListItemIcon className={classes.titleMenu}>
                                                                            {subItem.icon}
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={<span className={Match(subItem.link) ? classes.titleMenuSelected : classes.titleMenu}>{subItem.text}</span>} />
                                                                    </MenuItem>
                                                                </LightTooltip>
                                                            </div>
                                                        )
                                                    })}
                                                </List>
                                            </Collapse>
                                        )}
                                    </React.Fragment>
                                );
                            }
                        })}
                    </List>                   
                </Grid>
                {open && (
                    <>
                    <footer className={classes.footer}>                       
                        <Typography
                            className={clsx(classes.textVersion, {
                                [classes.hide]: !isMenuOpen,
                            })}
                        >
                            Versão {process.env.REACT_APP_VERSION}
                        </Typography>
                    </footer>
                    </>
                )}
                <Divider />
            </Drawer>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 14,
        color: "white",
    },
    backgroundSubItem: {
        background: "#0674e1",
        boxShadow: "inset rgb(29 93 144) 0px 1px 4px 0px"
    },
    backgroundSubItemMinimized: {
        background: "#0674e1",
        boxShadow: "inset rgb(29 93 144) 0px 1px 4px 0px",
        paddingLeft: "20px"
    },
    titleMenu: {
        color: "rgba(255,255,255,.86)",
        fontWeight: 300,
    },
    titleMenuSelected: {
        color: "white",
        fontWeight: 500,
    },
    iconRight: {
        minWidth: 0,
        color: "rgba(255,255,255,.86)",
        fontWeight: 300,
    },
    hide: {
        display: 'none',
    },
    footer: {
        padding: theme.spacing(2),
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',

    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        background: "#0080ff",
        borderRight: "none"

    },
    title: {
        flexGrow: 1,
        textAlign: "center",
        alignItems: "center",
        alignContent: "center",
        marginTop: "8px",
    },
    buttons: {
        flex: 1,
        alignItems: "left",
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: "54px !important",
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
        background: "#0080ff"
    },
    textVersion: {
        color: "rgba(255,255,255,.65)",
        marginLeft: theme.spacing(2),
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toggleSidebar: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2) * -1,
        height: theme.spacing(3),
        minHeight: theme.spacing(3),
        width: theme.spacing(3),
        minWidth: theme.spacing(3),
        backgroundColor: "#fff",
        zIndex: 1000,
    },
    toggleSidebarOpen: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2) * -1,
        height: theme.spacing(3),
        minHeight: theme.spacing(3),
        width: theme.spacing(3),
        minWidth: theme.spacing(3),
        backgroundColor: "#fff",
        zIndex: 1000,
    },
    styleMenuItemMinimized: {
        //paddingLeft: "35%"
    },
    styleMenuItem: {
        paddingLeft: "15%"
    }
  })
);
