import React from "react";
import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import TripService from "../../../services/tripService";
import * as Yup from "yup";
import { Autocomplete } from "@material-ui/lab";
import { DeliveryRequest, PendingDeliveryRequest, PendingTrip, Trip, TripDeliveryRequest } from "../../../services/types/deliveryRequest";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import numbro from "numbro";

interface JoinDeliveriesForm {
  selectedDeliveryRequestId: number | "";
}

const INITIAL_VALUES: JoinDeliveriesForm = {
  selectedDeliveryRequestId: ""
};

export const joinDeliveriesValidation = Yup.object().shape({
  selectedDeliveryRequestId: Yup.string()
    .required("Campo obrigatório"),
});

interface AvailableTripDeliveryRequest extends TripDeliveryRequest {
  trip: PendingTrip | Trip;
}

interface IJoinDeliveriesDialogProps {
  onSuccess?: () => void;
  onClose?: () => void;
}

export interface JoinDeliveriesDialogHandle {
  joinDeliveries: (deliveryRequest: PendingDeliveryRequest | DeliveryRequest, storeId: number, storeName: string, availableDeliveries: PendingDeliveryRequest[], availableTrips: PendingTrip[]) => void;
  addToTrip: (trip: PendingTrip | Trip, availableOptions: PendingDeliveryRequest[], availableTrips: PendingTrip[]) => void;
  hide: () => void;
}

const JoinDeliveriesDialog = React.forwardRef<JoinDeliveriesDialogHandle, IJoinDeliveriesDialogProps>((props, ref) => {
  const classes = useStyles();

  const [joining, setJoining] = React.useState<boolean>(false);
  const [trip, setTrip] = React.useState<PendingTrip | Trip>();
  const [deliveryRequest, setDeliveryRequest] = React.useState<PendingDeliveryRequest | DeliveryRequest>();
  const [title, setTitle] = React.useState<string>("");

  const [open, setOpen] = React.useState<boolean>(false);

  const [deliveryRequestList, setDeliveryRequestList] = React.useState<(PendingDeliveryRequest | AvailableTripDeliveryRequest)[]>([]);
  const [deliverySearchValue, setDeliverySearchValue] = React.useState<string>("");
  const [selectedDelivery, setSelectedDelivery] = React.useState<PendingDeliveryRequest | AvailableTripDeliveryRequest>();
  const [storeId, setStoreId] = React.useState<number>();
  React.useImperativeHandle(ref, () => ({
    joinDeliveries(deliveryRequest: PendingDeliveryRequest | DeliveryRequest, storeId: number, storeName: string, availableDeliveries: PendingDeliveryRequest[], availableTrips: PendingTrip[]) {
      const deliveries: (PendingDeliveryRequest | AvailableTripDeliveryRequest)[] = availableDeliveries.filter((item) => item.id !== deliveryRequest.id);
      availableTrips?.forEach((trip) => {
        deliveries.push(...trip.deliveries.map((delivery) => {
          return ({ ...delivery, trip: trip } as AvailableTripDeliveryRequest);
        }));
      });


      setDeliveryRequestList(deliveries);
      setDeliveryRequest(deliveryRequest);
      setTitle(storeName || "");
      setJoining(true);
      setStoreId(storeId);
      show();
    },

    addToTrip(trip: PendingTrip | Trip, availableOptions: PendingDeliveryRequest[], availableTrips: PendingTrip[]) {
      const deliveries: (PendingDeliveryRequest | AvailableTripDeliveryRequest)[] = availableOptions.filter((item) => trip.deliveries.map((delivery) => delivery.id !== item.id));
      availableTrips?.forEach((tripAvailable) => {
        if(tripAvailable.id !== trip.id){
          deliveries.push(...tripAvailable.deliveries.map((delivery) => {
            return ({ ...delivery, trip: tripAvailable } as AvailableTripDeliveryRequest);
          }));
        }       
      });
      
      setDeliveryRequestList(deliveries);
      setTrip(trip);
      setTitle(trip.store.name);
      setJoining(false);
      show();
    },

    hide() {
      hide();
    }
  }));

  const hide = () => {
    setOpen(false);
  }

  const show = () => {
    setOpen(true);
  }

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: async (values) => {
      try {
        if (values.selectedDeliveryRequestId) {
          if (joining && deliveryRequest && selectedDelivery && ("trip" in selectedDelivery)) {
            await TripService.addDeliveryToTrip(selectedDelivery.trip, deliveryRequest.id);
          } else if (trip) {
            await TripService.addDeliveryToTrip(trip, values.selectedDeliveryRequestId);
          } else if (deliveryRequest && storeId) {
            await TripService.createNewTrip(storeId, [deliveryRequest.id, values.selectedDeliveryRequestId]);
          }
        }

        if (props.onSuccess) {
          props.onSuccess();
        }
        NotificationManager.success(
            "Entregas juntadas com sucesso!",
            "Sucesso"
        );

        handleClose();
      } catch (error) {
        const { status, data } = error.response;
        const errorMsgs: string[] = [];
        let errorMsg = "Ocorreu um erro ao juntar as entregas";
        if (status === 400) {
          for (var key in data) {
            var value = data[key];
            if (key in values) {
              formik.setFieldError(key, value);
            } else {
              errorMsgs.push(value);
            }
          }

          if (errorMsgs.length > 0) {
            errorMsg = errorMsgs.join(". \n");
          }
        }
        NotificationManager.error(
            errorMsg,
            "Error"
        );
        handleClose();
      }
    },
    validationSchema: joinDeliveriesValidation,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleClose = () => {
    formik.resetForm();
    setDeliveryRequestList([]);
    setTrip(undefined);
    setDeliveryRequest(undefined);
    setTitle("");
    setDeliverySearchValue("");
    setSelectedDelivery(undefined);
    setOpen(false);
    if(props.onClose){
      props.onClose();
    }
    
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
      fullWidth={false}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <DialogTitle id="form-dialog-title">Juntar Entregas - {title}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12} md={12}>
              <Autocomplete
                id="selectedDeliveryRequestId"
                size="small"
                value={selectedDelivery}
                inputValue={deliverySearchValue}
                options={deliveryRequestList}
                getOptionLabel={(delivery) => `#${delivery.order_number} - ${delivery.destination_address.district} (${numbro(delivery.distance).format()} km)`}
                loadingText="Aguarde ..."
                noOptionsText="Sem opções"
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSelectedDelivery(newValue);
                    formik.setFieldValue("selectedDeliveryRequestId", newValue.id);
                  } else {
                    setSelectedDelivery(undefined);
                    formik.setFieldValue("selectedDeliveryRequestId", null);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setDeliverySearchValue(newInputValue);
                }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Pedido"
                    helperText={formik.errors.selectedDeliveryRequestId}
                    error={!!formik.errors.selectedDeliveryRequestId}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ required: true }}
                  />
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button color="primary" type="submit" disabled={formik.isSubmitting}>
            Juntar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      minWidth: "500px"
    },
  })
);

export default JoinDeliveriesDialog;
