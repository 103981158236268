import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import {
    Grid,
    Typography,
    makeStyles,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
    TextField,
    MenuItem,
    CircularProgress,
    CardContent,
    Card,
    Theme,
    createStyles,
    FormLabel,
    Divider,
    FormHelperText,
} from "@material-ui/core";
import SelectCourier from "./SelectCourier";
import * as Yup from "yup";
import { $enum } from "ts-enum-util";
import RegionService from "../../services/regionService";
import {
    AnnouncementType,
    ScreenType,
    DeliveryForType,
} from "../../services/types/announcement";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import AnnouncementsService from "../../services/announcementsService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { DEFAULT_LOCALE, CURRENT_DATE_TIME_FORMAT } from "../../i18n";
import { UploadFile } from "../../components/UploadFile";
import { FileModalView } from "../../components/FileModalView";
import { AnnouncementForm, convertAnnouncementDetailToAnnouncementForm, convertAnnouncementFormToCreateUpdateAnnouncement, DeliveryForOptions } from "./announcementUtils";

const validationSchema = Yup.object().shape({
    announcement_type: Yup.string().required("Campo obrigatório"),
    region: Yup.string()
        .when("delivery_for", {
            is: (value) => [DeliveryForOptions.REGION, DeliveryForOptions.REGION_ACTIVE_COURIERS].includes(value),
            then: Yup.string().nullable().required("Campo obrigatório"),
            otherwise: Yup.string().nullable().optional()
        }),
    send_date: Yup.string()
        .when("announcement_type", {
            is: (value) => value === AnnouncementType.SCHEDULED,
            then: Yup.string().nullable().required("Campo obrigatório"),
            otherwise: Yup.string().nullable().optional()
        }),
    expire_date: Yup.string().required("Campo obrigatório"),
    screen: Yup.string().required("Campo obrigatório"),
    title: Yup.string()
        .max(100, "Máximo de 100 caracteres excedido")
        .required("Campo obrigatório"),
    content: Yup.string()
        .max(250, "Máximo de 250 caracteres excedido")
        .required("Campo obrigatório"),
    couriers: Yup.array()
        .when("delivery_for", {
            is: (value) => value === DeliveryForType.SPECIFIC_COURIERS,
            then: Yup.array().min(1, "É necessário informar pelo menos 1 entregador"),
            otherwise: Yup.array().nullable().optional()
        }),
});

const INITIAL_STATE: AnnouncementForm = {
    related_trip: null,
    delivery_for: DeliveryForOptions.ALL_COURIERS,
    title: "",
    content: "",
    region: "",
    screen: "",
    announcement_type: AnnouncementType.IMMEDIATE,
    send_date: undefined,
    expire_date: undefined,
    picture: "",
    couriers: [],
    only_active_couriers: false
};

export enum ANNOUNCEMENT_ACTION {
    NEW = "NEW",
    EDIT = "EDIT",
    COPY = "COPY"
};

interface NewEditAnnouncementProps {
    action: ANNOUNCEMENT_ACTION;
    announcementId?: string;
}

export const NewEditAnnouncement: React.FC<NewEditAnnouncementProps> = ({ action, announcementId }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [picture, setPicture] = useState<string>();
    const [isShowImagePreview, setShowImagePreview] = useState(false);
    const [regionList, setRegionList] = useState<any[]>([]);
    const [announcement, setAnnouncement] = useState<AnnouncementForm>(INITIAL_STATE);
    const [initialCouriers, setInitialCouriers] = useState<any[]>([]);

    useEffect(() => {
        RegionService.loadAllRegions({ ordering: "name" })
            .then(response => {
                setRegionList(response);
            })
            .catch(error => console.log(error));
    }, []);

    useEffect(() => {
        const loadAnnouncement = async (id: number) => {
            const response = await AnnouncementsService.getAnnouncementById(id);

            setInitialCouriers(response.couriers);
            setPicture(response.picture);

            setAnnouncement(convertAnnouncementDetailToAnnouncementForm(response));
        };

        if (![ANNOUNCEMENT_ACTION.NEW, ANNOUNCEMENT_ACTION.EDIT, ANNOUNCEMENT_ACTION.COPY].includes(ANNOUNCEMENT_ACTION[action])) {
            history.push({ pathname: "/announcement" });
        }

        if ([ANNOUNCEMENT_ACTION.EDIT, ANNOUNCEMENT_ACTION.COPY].includes(action)) {
            if (announcementId) {
                loadAnnouncement(Number(announcementId));
            } else {
                history.push({ pathname: "/announcement" });
            }
        }
    }, [action, announcementId, history]);

    const formik = useFormik({
        initialValues: announcement,
        onSubmit: async (announcemetValues: AnnouncementForm) => {
            setLoading(true);
            const announcement = convertAnnouncementFormToCreateUpdateAnnouncement(announcemetValues);


            if (action === ANNOUNCEMENT_ACTION.EDIT) {
                AnnouncementsService.replaceAnnouncement(Number(announcementId), announcement)
                    .then((response) => {
                        NotificationManager.success(
                            "Aviso salvo com sucesso",
                            "Alterar Aviso"
                        );
                        history.push({ pathname: "/announcement" });
                    })
                    .catch((error) => {
                        NotificationManager.error(
                            "Erro ao salvar o Aviso",
                            "Alterar Aviso"
                        );
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                AnnouncementsService.sendAnnouncement(announcement)
                    .then((response) => {
                        NotificationManager.success(
                            "Aviso salvo com sucesso",
                            "Novo Aviso"
                        );
                        history.push({ pathname: "/announcement" });
                    })
                    .catch((error) => {
                        NotificationManager.error(
                            "Erro ao salvar o Aviso",
                            "Novo Aviso"
                        );
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        validationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const handleImageChange = (imageFile: string | File | null, imageURL: string | undefined) => {
        if (imageFile === null) {
            formik.setFieldValue("picture", imageURL);
            setPicture(imageURL);
            formik.setFieldError("picture", undefined);
        } else if (imageFile instanceof File) {
            const reader = new FileReader();

            reader.onload = function (e) {
                const image = new Image();

                image.onload = function () {
                    const height = image.height;
                    const width = image.width;
                    if (width === 500 && height === 250) {
                        setPicture(imageURL);
                        formik.setFieldValue("picture", imageURL);
                        formik.setFieldError("picture", undefined);
                    } else {
                        formik.setFieldError("picture", "A imagem deve ter 500px de largura e 250px de altura");
                    }
                };

                image.src = e.target!.result as string;
            };

            reader.readAsDataURL(imageFile);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleCouriersChange = (listCouriersId: number[]) => {
        formik.setFieldValue("couriers", listCouriersId);
    };

    return (
        <div>
            <FileModalView
                isOpen={isShowImagePreview}
                closeModal={() => setShowImagePreview(false)}
                title="Imagem"
                src={picture}
                aspectRatio={2}
            />
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Novo Aviso</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[
                                { label: "Avisos", url: "/announcement" },
                                {
                                    label: "Novo Aviso",
                                    url: "/announcement/new",
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <form onSubmit={formik.handleSubmit} noValidate>
                <Grid container spacing={2} direction="column" wrap="nowrap">
                    <Grid item>
                        <Card>
                            <CardContent>
                                <Typography variant="h2">Destinatários</Typography>
                                <Grid container spacing={2}>
                                    <Grid item md={12}>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                row
                                                aria-label="delivery_for"
                                                name="delivery_for"
                                                value={formik.values.delivery_for}
                                                onChange={formik.handleChange}
                                            >
                                                {$enum(DeliveryForOptions).map(
                                                    (option: string) => {
                                                        return (
                                                            <FormControlLabel
                                                                key={option}
                                                                control={<Radio />}
                                                                value={option}
                                                                label={t("announcement_delivery_for_options." + option)}
                                                            />
                                                        );
                                                    }
                                                )}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {[DeliveryForOptions.REGION, DeliveryForOptions.REGION_ACTIVE_COURIERS].includes(formik.values.delivery_for) && (
                                        <Grid item xs={12}>
                                            <Grid container direction="column" spacing={1} className={classes.subGroup}>
                                                <Grid item>
                                                    <Typography variant="subtitle1">Informe a Região</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        select
                                                        label="Região"
                                                        name="region"
                                                        value={formik.values.region}
                                                        onChange={formik.handleChange}
                                                        error={!!formik.errors.region}
                                                        helperText={formik.errors.region}
                                                        variant="outlined"
                                                        required={true}
                                                        fullWidth
                                                    >
                                                        {regionList.map(region => (
                                                            <MenuItem
                                                                key={region.id}
                                                                value={region.id}
                                                            >
                                                                {region.name} - {region.state}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {formik.values.delivery_for === "SPECIFIC_COURIERS" && (
                                        <Grid item xs={12}>
                                            <Grid container direction="column" spacing={1} className={classes.subGroup}>
                                                <Grid item>
                                                    <Typography variant="subtitle1">Selecione os Entregadores</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <SelectCourier
                                                        formik={formik}
                                                        initialCouriers={initialCouriers}
                                                        onCouriersChange={handleCouriersChange} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card>
                            <CardContent>
                                <Typography variant="h2">Dados do Aviso</Typography>
                                <Grid container justify="space-between">
                                    <Grid item xl={3} lg={5} md={12}>
                                        <Grid container spacing={2} direction="column">
                                            <Grid item>
                                                <Typography variant="subtitle1">Configurações</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container alignItems="flex-end" wrap="nowrap">
                                                    <Grid item>
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">Envio:</FormLabel>
                                                            <RadioGroup
                                                                row
                                                                name="announcement_type"
                                                                value={formik.values.announcement_type}
                                                                onChange={formik.handleChange}
                                                            >
                                                                <FormControlLabel
                                                                    value={AnnouncementType.IMMEDIATE}
                                                                    control={<Radio />}
                                                                    label={t("announcement_type." + AnnouncementType.IMMEDIATE)}
                                                                />
                                                                <FormControlLabel
                                                                    value={AnnouncementType.SCHEDULED}
                                                                    control={<Radio />}
                                                                    label={t("announcement_type." + AnnouncementType.SCHEDULED)}
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    {formik.values.announcement_type ===
                                                        "SCHEDULED" && (
                                                            <Grid item xs zeroMinWidth>
                                                                <DatePicker
                                                                    id="send_date"
                                                                    name="send_date"
                                                                    onChange={date => formik.setFieldValue("send_date", date)}
                                                                    selected={formik.values.send_date}
                                                                    minDate={new Date()}
                                                                    locale={DEFAULT_LOCALE}
                                                                    dateFormat={CURRENT_DATE_TIME_FORMAT}
                                                                    timeCaption="Hora"
                                                                    isClearable
                                                                    showTimeSelect
                                                                    autoComplete="off"
                                                                    wrapperClassName="MuiFormControl-fullWidth"
                                                                    customInput={<TextField
                                                                        label="Data e Hora de Envio"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        required={true}
                                                                        InputLabelProps={{ required: true }}
                                                                        error={!!formik.errors.send_date}
                                                                        helperText={formik.errors.send_date}
                                                                        fullWidth />}
                                                                />
                                                            </Grid>
                                                        )}
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <DatePicker
                                                    id="expire_date"
                                                    name="expire_date"
                                                    onChange={date => formik.setFieldValue("expire_date", date)}
                                                    selected={formik.values.expire_date}
                                                    minDate={formik.values.send_date || new Date()}
                                                    locale={DEFAULT_LOCALE}
                                                    dateFormat={CURRENT_DATE_TIME_FORMAT}
                                                    timeCaption="Hora"
                                                    isClearable
                                                    showTimeSelect
                                                    autoComplete="off"
                                                    wrapperClassName="MuiFormControl-fullWidth"
                                                    customInput={<TextField
                                                        label="Data e Hora de Expiração"
                                                        variant="outlined"
                                                        size="small"
                                                        required={true}
                                                        InputLabelProps={{ required: true }}
                                                        error={!!formik.errors.expire_date}
                                                        helperText={formik.errors.expire_date}
                                                        fullWidth />}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    select
                                                    label="Local de Exibição"
                                                    name="screen"
                                                    value={formik.values.screen}
                                                    onChange={formik.handleChange}
                                                    error={!!formik.errors.screen}
                                                    helperText={formik.errors.screen}
                                                    variant="outlined"
                                                    required={true}
                                                    fullWidth
                                                >
                                                    {$enum(ScreenType).map((screen: string) => {
                                                        return (
                                                            <MenuItem
                                                                key={screen}
                                                                value={screen}
                                                            >
                                                                {t(
                                                                    "announcement_screen_type." +
                                                                    screen
                                                                )}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Divider orientation="vertical" flexItem />

                                    <Grid item xl={8} lg={6} md={12}>
                                        <Grid container spacing={2} direction="column">
                                            <Grid item>
                                                <Typography variant="subtitle1">Notificação</Typography>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    label="Título"
                                                    name="title"
                                                    value={formik.values.title}
                                                    onChange={formik.handleChange}
                                                    error={!!formik.errors.title}
                                                    helperText={formik.errors.title}
                                                    variant="outlined"
                                                    required={true}
                                                    inputProps={{
                                                        maxLength: 100
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    label="Mensagem"
                                                    name="content"
                                                    multiline
                                                    rows={5}
                                                    value={formik.values.content}
                                                    error={!!formik.errors.content}
                                                    helperText={"Máx. 250 caracteres" || formik.errors.content}
                                                    onChange={formik.handleChange}
                                                    variant="outlined"
                                                    inputProps={{
                                                        maxLength: 250
                                                    }}
                                                    required={true}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControl
                                                    error={!!formik.errors.picture}
                                                    component="fieldset"
                                                >
                                                    <UploadFile
                                                        className={classes.uploadFileClass}
                                                        error={!!formik.errors.picture}
                                                        name="picture"
                                                        initialFile={announcement?.picture}
                                                        onChange={(file, fileURL) => handleImageChange(file, fileURL)}
                                                        onTitleClick={() => setShowImagePreview(true)}
                                                        title="Imagem" />
                                                    <FormHelperText>
                                                        {formik.errors.picture || "A imagem deve ter 500px de largura e 250px de altura"}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item zeroMinWidth>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    type="button"
                                    onClick={handleCancel}
                                >
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={loading}
                                    type="submit"
                                >
                                    Enviar{" "}
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorMsg: {
            color: "#f44336",
        },
        subGroup: {
            paddingLeft: theme.spacing(2)
        },
        buttonProgress: {
            position: "absolute",
        },
        uploadFileClass: {
            margin: '0',
        },
    })
);
