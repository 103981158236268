import { City } from "./common";
import { CourierUserStatus, SpeedyRankingType, VehicleLoadCompartmentType, VehicleType } from "./courier";
import { FinancialEntryStatus } from "./financialEntry";

export interface CustomerResponse {
    id: number,
    corporate_name: string,
    trade_name: string,
    registration_number: string,
    contact_name: string,
    phonenumber: string,
    email: string,
    fiscal_address_city: City,
    is_active: boolean
}

export interface CustomerDetails {
    id: number;
    email: string;
    name: string;
    phonenumber: string;
    created_at: Date;
    updated_at: Date;
    corporate_name: string;
    trade_name: string;
    registration_number: string;
    contact_name: string;
    additional_km_price: number;
    single_store: boolean;
    fiscal_address_city: City;
    stores: StoreResponse[];
    waste_limit: number;
    fiscal_address_postal_code: string;
    fiscal_address_street: string;
    fiscal_address_number: string;
    fiscal_address_commplement: string;
    fiscal_address_district: string;
    allow_incentive_dynamics: boolean;
    skip_customer_actions: boolean;
    commercial_plan: number;
    is_active: boolean;
    management_user: number | null;
}

export interface UpdateCustomer {
    id: number;
    email: string;
    name: string;
    phonenumber: string;
    corporate_name: string;
    trade_name: string;
    registration_number: string;
    contact_name: string;
    additional_km_price: number;
    single_store: boolean;
    fiscal_address_city?: number;
    fiscal_address_postal_code: string;
    fiscal_address_street: string;
    fiscal_address_number: string;
    fiscal_address_commplement: string;
    fiscal_address_district: string;
    allow_incentive_dynamics: boolean;
    skip_customer_actions: boolean;
    commercial_plan: number;
    management_user: number | null;
}

interface CourierQueueItemStatus {
    courier_id: number;
    courier_status: CourierUserStatus;
    created_at: Date;
    delivery_request_id: number;
    latitude: number;
    longitude: number;
    online: boolean;
    pk: string;
    suspended: boolean;
    trip_id: number;
    updated_at: Date;
    _id: string;
}

export interface CourierQueueItem {
    id: number;
    name: string;
    picture: string;
    position: number;
    vehicle_load_compartment_type: VehicleLoadCompartmentType;
    vehicle_type: VehicleType;
    has_priority: boolean;
    courier_status: CourierQueueItemStatus;
}

export interface CreateUpdateStore {
    name: string;
    contact_name: string;
    phonenumber: string;
    email: string;
    postal_code: string;
    street: string;
    address_number: string;
    address_complement: string;
    district: string;
    city: number;
    latitude: number;
    longitude: number;
    customer: number;
    photo: Blob | null;
    use_priority_queue: boolean;
}

export interface StoreResponse {
    id: number;
    created_at: Date;
    updated_at: Date;
    name: string;
    phonenumber: string;
    email: string;
    latitude: number;
    longitude: number;
    contact_name: string;
    postal_code: string;
    street: string;
    address_number: string;
    address_complement: string;
    district: string;
    is_active: boolean;
    city: number;
    region: number;
    customer: number;
    commercial_plan: number;
    max_number_of_deliveries: number;
    max_number_of_deliveries_limit: number;
    photo: string;
    use_priority_queue: boolean;
}

export enum CustomerFinancialOperationType {
    CREDIT = "CREDIT",
    DEBIT = "DEBIT"
}

export interface CustomerFinancialOperation {
    id: number;
    created_at: Date;
    updated_at: Date;
    description: string;
    store_name: string;
    courier_value: number;
    speedy_value: number;
    value: number;
    type: CustomerFinancialOperationType;
    status: FinancialEntryStatus;
}

export enum CustomerIntegrationSource {
    IFOOD = "IFOOD",
    DELIVERY_DIRETO = "DELIVERY_DIRETO",
    MEU_XODO = "MEU_XODO",
    NEEMO = "NEEMO"
}

export interface CustomerIntegrationStore {
    id: number;
    name: string;
}

export interface CustomerIntegrationResponse {
    id: number;
    store: CustomerIntegrationStore;
    name: string;
    integration_source: CustomerIntegrationSource;
    initials: string;
    enabled: boolean;
    authorized: boolean;
    created_at: Date;
}

export interface AddCustomerIntegration {
    store_id: number;
    name: string;
    integration_source: CustomerIntegrationSource;
    initials: string;
    store_identifier: string;
    key: string | undefined;
    x_deliverydireto_id: string | undefined;
    delivery_direto_user: string;
    delivery_direto_password: string | undefined;
    password_confirmation: string | undefined;
    external_email: string | undefined;
}

export interface EditCustomerIntegration {
    name: string;
    initials: string;
    credentials: CredentialsIntegration;
}

export interface CustomerIntegration {
    id: number;
    store: CustomerIntegrationStore;
    name: string;
    integration_source: CustomerIntegrationSource;
    initials: string;
    enabled: boolean;
    authorized: boolean;
    created_at: Date;
    credentials: CredentialsIntegration;
    delivery_direto_password: string;
    password_confirmation: string;
    external_email: string;
}

export interface CredentialsIntegration {
    key: string | undefined;
    x_deliverydireto_id: string | undefined;
    delivery_direto_user: string;
    delivery_direto_password: string | undefined;
    password_confirmation: string | undefined;
    external_email: string | undefined;
}

export interface BlockedCourier {
    id: number;
    name: string;
    phonenumber: string;
    photo: string;
    vehicle_type: VehicleType;
    vehicle_load_compartment_type: VehicleLoadCompartmentType | null;
    ranking_points: number;
    speedy_ranking: SpeedyRankingType;
}

export interface CustomerCourierBlock {
    id: number;
    all_customers: boolean;
    courier: BlockedCourier;
    expired: boolean;
    duration: number | null;
    undefined_time: boolean;
    reason: string;
    created_at: Date;
}

export interface CustomerDeliveryStats {
    current_balance: number;
    today_expenses: number;
    weekly_expenses: number;
    average_weekly_expenses: number;
    average_ticket: number;
    today_deliveries: number;
    average_weekly_deliveries: number;
    weekly_deliveries: number;
    weekly_canceled_deliveries: number;
}

export interface CustomerUsersResponse {
    id: number,
    email: string,
    name: string,
    phonenumber: string,
    store: CustomerIntegrationStore,
    user_status: string
}

export interface CustomerStoreScheduleResponse {
    begin_time: string;
    day_of_week: number;
    end_time: string;
    id: number;
    store: number;
}


export enum CustomerScheduleStoreEnum {
    SUNDAY = 0,
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
    HOLIDAY = 7    
}

export interface NegativeCustomersResponse {
    id: number;
    corporate_name: string;
    trade_name: string;
    waste_limit: string;
    current_balance: string;
}

export interface NotRechargingCustomersResponse {
    id: number;
    corporate_name: string;
    trade_name: string;
    current_balance: number;
    contact_name: string;
    phonenumber: string;
    email: string;
}

export interface ReportCustomersActiveResponse {
    region_id: number;
    region_name: string;
    total_quantity_delivery: number;
    total_value_recharge: number;
    total_speedy_value: number;
    stores: StoresCustomersActiveResponse[];
}

export interface StoresCustomersActiveResponse {
    customer_id: number;
    customer_name: string;
    deliveries_count: number;
    recharges_value: number;
    speedy_value: number;
    store_id: number;
    store_name: string;
}