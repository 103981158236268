import React, { useEffect, useCallback, useState } from "react";
import { Grid, Typography, Button, TextField, CardContent, Card } from "@material-ui/core";
import { useFormik, getIn } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import cep from "cep-promise";
import { TextMaskCustom } from "../../components/TextMaskCustom";
import {
  postalCodeMask,
  registrationNumberMask,
  postalCodeMaskRegex,
  noSpecialCharactersRegex,
  noSpecialCharactersAndDigitsRegex,
  mobilePhoneMaskRegex,
} from "../../utils/masks";
import { IUser, EMPTY_USER } from "../../store/types/user";
import { MultiSelect } from "../../components/MultiSelect";
import { useHistory } from "react-router";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { PhoneInput } from "../../components/PhoneInput";
import UserService from "../../services/userService";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { UserGroup } from "../../services/types/user";

interface INewUserProps { }

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, "Mínimo de 3 caracteres")
    .max(30, "Máximo de 30 caracteres excedido")
    .required("Campo obrigatório")
    .matches(
      noSpecialCharactersAndDigitsRegex,
      "Números e caracteres especiais não são permitidos"
    ),
  last_name: Yup.string()
    .min(3, "Mínimo de 3 caracteres")
    .max(150, "Máximo de 150 caracteres excedido")
    .matches(
      noSpecialCharactersAndDigitsRegex,
      "Números e caracteres especiais não são permitidos"
    )
    .required("Campo obrigatório"),
  email: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .email("E-mail inválido")
    .required("Campo obrigatório"),
  registration_number: Yup.string()
    .min(14, "CPF inválido")
    .max(14, "CPF inválido")
    .required("Campo obrigatório"),
  phonenumber: Yup.string()
    .min(15, "Telefone inválido")
    .max(15, "Telefone inválido")
    .matches(mobilePhoneMaskRegex, "Telefone inválido")
    .required("Campo obrigatório"),
  postal_code: Yup.string()
    .min(9, "CEP inválido")
    .max(9, "CEP inválido")
    .matches(postalCodeMaskRegex, "CEP inválido")
    .required("Campo obrigatório"),
  street: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .required("Campo obrigatório"),
  city: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .matches(
      noSpecialCharactersRegex,
      "Caracteres especiais não são permitidos"
    )
    .required("Campo obrigatório"),
  district: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .matches(
      noSpecialCharactersRegex,
      "Caracteres especiais não são permitidos"
    )
    .required("Campo obrigatório"),
  address_number: Yup.string()
    .matches(
      noSpecialCharactersRegex,
      "Caracteres especiais não são permitidos"
    )
    .max(10, "Máximo de 10 caracteres excedido"),
  address_complement: Yup.string().max(50, "Máximo de 50 caracteres excedido"),
  state: Yup.string()
    .max(2, "Máximo de 2 caracteres excedido")
    .required("Campo obrigatório"),
  groups: Yup.number().required("Campo obrigatório"),
});

export const NewUser: React.FC<INewUserProps> = () => {

  const [postalCodeValid, setPostalCodeValid] = useState(false);

  const [groups, setGroups] = React.useState<UserGroup[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const goBack = () => {
    history.push({ pathname: "/user" });
  };

  const createUser = async (user: IUser) => {
    await UserService.createUser(user).then((res) => {
      NotificationManager.success(
        "Usuário editado com sucesso",
        "Editar Usuário"
      );
      setIsLoading(false);
      goBack();
    }).catch((error) =>{
      setIsLoading(false);
      NotificationManager.success(
        "Falha ao adicionar Usuário",
        "Adicionar Usuário"
      );
    })
  };
  
  const getGroups = async () => {
    await UserService.getGroups().then((response) =>{
      setGroups(response);
    }).catch((error) => {})
  };

  const history = useHistory();

  const debouncedCallApi = useCallback(
    _.debounce((userValues: IUser) => createUser(userValues), 3000),
    []
  );

  const formik = useFormik({
    initialValues: EMPTY_USER,
    onSubmit: async (userValues: IUser) => {
      if (validatePostalCode()) {
        setIsLoading(true);
        debouncedCallApi(userValues);
      }
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const isRequired = (field_name: string) => {
    return (
      getIn(validationSchema.fields, field_name)._exclusive.required || false
    );
  };

  useEffect(() => {
    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCepChange = (cepRequested: string) => {
    cep(cepRequested)
      .then((cepResponse) => {
        setPostalCodeValid(true);
        formik.setFieldError("postal_code", "");
        formik.setFieldValue("street", cepResponse.street);
        formik.setFieldValue("district", cepResponse.neighborhood);
        formik.setFieldValue("city", cepResponse.city);
        formik.setFieldValue("state", cepResponse.state);
      })
      .catch((error) => {
        setPostalCodeValid(false);
        formik.setFieldError("postal_code", "Preencha o campo corretamente");
        formik.setFieldValue("street", "");
        formik.setFieldValue("district", "");
        formik.setFieldValue("city", "");
        formik.setFieldValue("state", "");
      });
  };

  const validatePostalCode = () => {
    if (!postalCodeValid) {
      formik.setFieldError("postal_code", "Preencha o campo corretamente");
    }
    return postalCodeValid;
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Novo Usuário</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[
                { label: "Usuários", url: "/user" },
                { label: "Novo Usuário", url: "/user/new" },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>

      <form onSubmit={formik.handleSubmit} noValidate>
        <Card>
          <CardContent>
            <Typography variant="h2">Dados pessoais</Typography>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <TextField
                  id="first_name"
                  label="Primeiro nome"
                  variant="outlined"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  helperText={formik.errors.first_name}
                  error={!!formik.errors.first_name}
                  required={isRequired("first_name")}
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  id="last_name"
                  label="Último nome"
                  variant="outlined"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  helperText={formik.errors.last_name}
                  error={!!formik.errors.last_name}
                  required={isRequired("last_name")}
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  id="email"
                  label="E-mail"
                  variant="outlined"
                  value={formik.values.email}
                  onChange={(event) => {
                    formik.setFieldValue(
                      event.target.id,
                      event.target.value.trim()
                    );
                  }}
                  helperText={formik.errors.email}
                  error={!!formik.errors.email}
                  required={isRequired("email")}
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  id="registration_number"
                  label="CPF"
                  variant="outlined"
                  value={formik.values.registration_number}
                  onChange={formik.handleChange}
                  fullWidth
                  error={!!formik.errors.registration_number}
                  helperText={formik.errors.registration_number}
                  required={isRequired("registration_number")}
                  InputProps={{
                    inputComponent: RegistrationNumberInput,
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  id="phonenumber"
                  label="Celular"
                  variant="outlined"
                  value={formik.values.phonenumber}
                  onChange={formik.handleChange}
                  fullWidth
                  error={!!formik.errors.phonenumber}
                  helperText={formik.errors.phonenumber}
                  required={isRequired("phonenumber")}
                  InputProps={{
                    inputComponent: PhoneInput,
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <MultiSelect
                  label={"Grupos"}
                  placeholder=""
                  selectedList={formik.values.groups}
                  optionList={groups}
                  onChange={formik.handleChange}
                  name="groups"
                  error={!!formik.errors.groups}
                  helperText={formik.errors.groups}
                  required={isRequired("groups")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  Os campos com (*) são de preenchimento obrigatório.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h2">Endereço</Typography>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <TextField
                  id="postal_code"
                  label="CEP"
                  variant="outlined"
                  value={formik.values.postal_code}
                  onChange={formik.handleChange}
                  onBlur={(e) => handleCepChange(e.target.value)}
                  fullWidth
                  error={!!formik.errors.postal_code}
                  helperText={formik.errors.postal_code}
                  required={isRequired("postal_code")}
                  InputProps={{
                    inputComponent: PostCodeInput,
                  }}
                />
              </Grid>
              <Grid item md={7}>
                <TextField
                  id="street"
                  label="Rua"
                  variant="outlined"
                  value={formik.values.street}
                  onChange={formik.handleChange}
                  fullWidth
                  error={!!formik.errors.street}
                  helperText={formik.errors.street}
                  required={isRequired("street")}
                />
              </Grid>
              <Grid item md={2}>
                <TextField
                  id="address_number"
                  label="Número"
                  variant="outlined"
                  value={formik.values.address_number}
                  onChange={formik.handleChange}
                  helperText={formik.errors.address_number}
                  error={!!formik.errors.address_number}
                  required={isRequired("address_number")}
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  id="address_complement"
                  label="Complemento"
                  variant="outlined"
                  value={formik.values.address_complement}
                  onChange={formik.handleChange}
                  helperText={formik.errors.address_complement}
                  error={!!formik.errors.address_complement}
                  required={isRequired("address_complement")}
                  fullWidth
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  id="district"
                  label="Bairro"
                  variant="outlined"
                  value={formik.values.district}
                  onChange={formik.handleChange}
                  helperText={formik.errors.district}
                  error={!!formik.errors.district}
                  required={isRequired("district")}
                  fullWidth
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  id="city"
                  label="Cidade"
                  variant="outlined"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  helperText={formik.errors.city}
                  error={!!formik.errors.city}
                  required={isRequired("city")}
                  fullWidth
                />
              </Grid>
              <Grid item md={2}>
                <TextField
                  id="state"
                  label="Estado"
                  variant="outlined"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  helperText={formik.errors.state}
                  error={!!formik.errors.state}
                  required={isRequired("state")}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  Os campos com (*) são de preenchimento obrigatório.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Grid container spacing={2} style={{marginTop:"10px"}}>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              type="button"
              onClick={handleCancel}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const RegistrationNumberInput = (props: any) => (
  <TextMaskCustom {...props} mask={registrationNumberMask} />
);

const PostCodeInput = (props: any) => (
  <TextMaskCustom {...props} mask={postalCodeMask} />
);
