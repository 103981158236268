import React from "react";
import {
    Grid,
    TextField,
    MenuItem,
    Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FormikHelpers, useFormik } from "formik";
import { DEFAULT_LOCALE, CURRENT_DATE_TIME_FORMAT } from "../../i18n";
import { DeliveryRequestStatus } from "../../services/types/deliveryRequest";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router";
import { encodeURLParams } from "../../utils/utils";

export interface IDeliveryRequestFilterParams {
    request_number: string;
    order_number: string;
    requested_time_after: Date | null;
    requested_time_before: Date | null;
    courier_name: string;
    store_id: number | null;
    status: string;
    manual_creation: string | boolean | null;
    region: string;
}

export const INITIAL_FILTER_PARAMS: IDeliveryRequestFilterParams = {
    request_number: "",
    order_number: "",
    requested_time_after: null,
    requested_time_before: null,
    courier_name: "",
    store_id : null,
    status: "FINISHED",
    manual_creation: " ",
    region: "",
};

interface IDeliveryRequestFilterProps {
    params?: IDeliveryRequestFilterParams;
    storeList: any[];
    regionList: any[];
    selectRegion: (regionId: number | null) => void;
    onFilterChanged: (params: IDeliveryRequestFilterParams) => void;
}

export const DeliveryRequestFilter: React.FC<IDeliveryRequestFilterProps> = ({
    params = INITIAL_FILTER_PARAMS,
    storeList,
    regionList,
    selectRegion,
    onFilterChanged,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    React.useEffect(() => {
        history.replace(encodeURLParams(history.location.pathname, params));
    }, [history, params]);

    const formik = useFormik<IDeliveryRequestFilterParams>({
        initialValues: params,
        onSubmit: (values: IDeliveryRequestFilterParams, formikHelpers: FormikHelpers<IDeliveryRequestFilterParams>) => {
            onFilterChanged(values);
        },
        onReset: (values: IDeliveryRequestFilterParams, formikHelpers: FormikHelpers<IDeliveryRequestFilterParams>) => {
            selectRegion(null);
            onFilterChanged(INITIAL_FILTER_PARAMS);
            formikHelpers.setValues(INITIAL_FILTER_PARAMS);
        },
    });

    return (
        <div className="page-filter">
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            id="request_number"
                            label="Solicitação"
                            onChange={formik.handleChange}
                            value={formik.values.request_number}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={2}>
                        <TextField
                            id="order_number"
                            label="Pedido"
                            onChange={formik.handleChange}
                            value={formik.values.order_number}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={2}>
                        <DatePicker
                            id="requested_time_after"
                            name="requested_time_after"
                            onChange={val => {
                                formik.setFieldValue("requested_time_after", val);
                            }}
                            maxDate={new Date()}
                            selected={formik.values.requested_time_after}
                            startDate={formik.values.requested_time_after}
                            endDate={formik.values.requested_time_before}
                            selectsStart
                            locale={DEFAULT_LOCALE}
                            dateFormat={CURRENT_DATE_TIME_FORMAT}
                            timeCaption="Hora"
                            isClearable
                            showTimeSelect
                            autoComplete="off"
                            wrapperClassName="MuiFormControl-fullWidth"
                            customInput={<TextField
                                label="Data e Hora Inicial"
                                variant="outlined"
                                size="small"
                                error={!!formik.errors.requested_time_after}
                                helperText={formik.errors.requested_time_after}
                                fullWidth />}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <DatePicker
                            id="requested_time_before"
                            name="requested_time_before"
                            onChange={val => {
                                formik.setFieldValue("requested_time_before", val);
                            }}
                            maxDate={new Date()}
                            selected={formik.values.requested_time_before}
                            startDate={formik.values.requested_time_after}
                            endDate={formik.values.requested_time_before}
                            minDate={formik.values.requested_time_after}
                            selectsEnd
                            locale={DEFAULT_LOCALE}
                            dateFormat={CURRENT_DATE_TIME_FORMAT}
                            timeCaption="Hora"
                            isClearable
                            showTimeSelect
                            autoComplete="off"
                            wrapperClassName="MuiFormControl-fullWidth"
                            customInput={<TextField
                                label="Data e Hora Final"
                                variant="outlined"
                                size="small"
                                error={!!formik.errors.requested_time_before}
                                helperText={formik.errors.requested_time_before}
                                fullWidth />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="manual_creation"
                            name="manual_creation"
                            label="Tipo da Solicitação"
                            select
                            onChange={formik.handleChange}
                            value={formik.values.manual_creation}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value=" ">Todos</MenuItem>
                            <MenuItem value="true">Manual</MenuItem>
                            <MenuItem value="false">Busca</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item md={3}>
                        <TextField
                            id="region"
                            name="region"
                            label="Região"
                            select
                            onChange={(e)=>{
                                formik.handleChange(e);
                                if(Number(e.target.value) > 0){
                                    selectRegion(Number(e.target.value));
                                }else{
                                    selectRegion(null);
                                }
                            }}
                            value={formik.values.region}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value=" ">Todas</MenuItem>
                            {regionList.map(region => (
                                <MenuItem key={region.id} value={region.id}>
                                    {region.name} - {region.state}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="store_id"
                            name="store_id"
                            label="Loja"
                            select
                            onChange={formik.handleChange}
                            value={formik.values.store_id}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value="">Todas</MenuItem>
                            {storeList.map(store => (
                                <MenuItem key={store.id} value={store.id}>
                                    {store.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="courier_name"
                            label="Entregador"
                            onChange={formik.handleChange}
                            value={formik.values.courier_name}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    

                    <Grid item md={3}>
                        <TextField
                            id="status"
                            name="status"
                            label="Situação"
                            select
                            onChange={formik.handleChange}
                            value={formik.values.status}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value={DeliveryRequestStatus.FINISHED}>
                                {t("delivery_request_status." + DeliveryRequestStatus.FINISHED)}
                            </MenuItem>
                            <MenuItem value={DeliveryRequestStatus.CANCELED}>
                                {t("delivery_request_status." + DeliveryRequestStatus.CANCELED)}
                            </MenuItem>
                            <MenuItem value={DeliveryRequestStatus.DISCARDED}>
                                {t("delivery_request_status." + DeliveryRequestStatus.DISCARDED)}
                            </MenuItem>
                        </TextField>
                    </Grid>

                    

                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Pesquisar
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="reset"
                        >
                            Limpar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
