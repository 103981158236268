import React from "react";

//@ts-ignore
import CurrencyInputDef from 'react-currency-input';

export const PercentInput = (props: any) => {
  return <CurrencyInputDef
    maxLength={6}
    onChangeEvent={props.onChange}
    decimalSeparator={"."}
    thousandSeparator={","}
    precision={2}  
    {...props}
    onChange={undefined}
  />
};
