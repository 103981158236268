import React from "react";
import {
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  makeStyles,
  OutlinedInput,
  Chip,
  Drawer,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import { useFormik } from "formik";
import { RegionResponse } from "../../../services/types/region";
import SearchInput from "../../../components/SearchInput";
import { ChipListItem, DashboardChipList } from "../customComponents/dashboardChipList";
import FilterListIcon from '@material-ui/icons/FilterList';
import CancelIcon from '@material-ui/icons/Cancel';
import { StoreResponse } from "../../../services/types/customer";
import CustomerService from "../../../services/customerService";
import { PageableParams } from "../../../components/TableTrinkets";

export interface DashboardDeliveriesFilterParams {
  regionsIds: number[];
  storesIds: number[];
}

export const DELIVERIES_INITIAL_FILTER_PARAMS: DashboardDeliveriesFilterParams = {
  regionsIds: [],
  storesIds: [],
};

interface DashboardDeliveriesFilterProps {
  params?: DashboardDeliveriesFilterParams;
  regionList: RegionResponse[];
  onSearchValueChanged: (value: string) => void;
  onFilterChanged: (params: DashboardDeliveriesFilterParams) => void;
}

export const DashboardDeliveriesFilter: React.FC<DashboardDeliveriesFilterProps> = ({
  params = DELIVERIES_INITIAL_FILTER_PARAMS,
  regionList = [],
  onSearchValueChanged,
  onFilterChanged,
}) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = React.useState<string>("");

  const [openFilterForm, showFilterForm] = React.useState<boolean>(false);
  const [openRegionSelect, setOpenRegionSelect] = React.useState<boolean>(false);
  const [openStoreSelect, setOpenStoreSelect] = React.useState<boolean>(false);
  const [chipList, setChipList] = React.useState<ChipListItem[]>([]);
  const [storeList, setStoreList] = React.useState<StoreResponse[]>([]);

  React.useEffect(() => {
    formik.setValues(params);
    if(params.regionsIds.length > 0){
      refreshStores(params.regionsIds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);


const refreshStores = React.useCallback((regionsId: number[]) => {
  CustomerService
    .getStores(undefined, undefined, 
      regionsId, undefined, new PageableParams(1, 10000, "name"))
    .then((response) => {
      setStoreList(response.data);
    })
    .catch((error) => { });
}, []);

  const formik = useFormik({
    initialValues: params,
    onSubmit: (values) => {
      onFilterChanged({ ...values });
      showFilterForm(false);
    },
    onReset: (values) => {
      showFilterForm(false);
    },
  });

  const handleOpenDrawer = () => {
    showFilterForm(true);
  };

  const handleApplyFilter = () => {
    formik.submitForm();
  };

  const handleCloseDrawer = () => {
    formik.resetForm();
  };

  const handleChipClick = (data: any) => {
    showFilterForm(true);
  }
  // Region
  const handleRegionChipDelete = React.useCallback((deletedRegion: RegionResponse) => {
    const selectedRegionsIds = [...params.regionsIds];
    if (deletedRegion && selectedRegionsIds) {
      const index = selectedRegionsIds.indexOf(deletedRegion.id);
      if (index > -1) {
        selectedRegionsIds.splice(index, 1);
        removeStoreByRegionDeleted(deletedRegion.id);
      }
      formik.setFieldValue("regionsIds", selectedRegionsIds);
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.regionsIds]);

  const handleSelectRegionDelete = (deletedRegionId: number) => {
    const selectedRegionsIds = [...formik.values.regionsIds];
    if (deletedRegionId && selectedRegionsIds) {
      const index = selectedRegionsIds.indexOf(deletedRegionId);
      if (index > -1) {
        selectedRegionsIds.splice(index, 1);
        removeStoreByRegionDeleted(deletedRegionId);
      }
      formik.setFieldValue("regionsIds", selectedRegionsIds);
      if(selectedRegionsIds.length > 0){
        refreshStores(selectedRegionsIds)
      }else{
        refreshStores([]);
      }
    }
  };

  const removeStoreByRegionDeleted = (deletedRegionId: number) => {
    const selectedStoresIds = [...params.storesIds];
    if (deletedRegionId && selectedStoresIds) {
      storeList.forEach(function(store){
        if(store.region === deletedRegionId){
          const index = selectedStoresIds.indexOf(store.id);
          if (index > -1) {
            selectedStoresIds.splice(index, 1);
          }
        }
      });
      formik.setFieldValue("storesIds", selectedStoresIds);
    }
  }

  // Store 
  const handleStoreChipDelete = React.useCallback((deletedStore: StoreResponse) => {
    const selectedStoresIds = [...params.storesIds];
    if (deletedStore && selectedStoresIds) {
      const index = selectedStoresIds.indexOf(deletedStore.id);
      if (index > -1) {
        selectedStoresIds.splice(index, 1);
      }
      formik.setFieldValue("storesIds", selectedStoresIds);
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.storesIds]);

  const handleSelectStoreDelete = (deletedStoreId: number) => {
    const selectedStoresIds = [...formik.values.storesIds];
    if (deletedStoreId && selectedStoresIds) {
      const index = selectedStoresIds.indexOf(deletedStoreId);
      if (index > -1) {
        selectedStoresIds.splice(index, 1);
      }
      formik.setFieldValue("storesIds", selectedStoresIds);
    }
  };

  React.useEffect(() => {
    const chips: ChipListItem[] = [];
    if (regionList && (regionList.length > 0) && (params.regionsIds.length > 0)) {
      for (let i = 0; i < params.regionsIds.length; i++) {
        let regionId = params.regionsIds[i];
        let selectedRegion = regionList.find((region => region.id === regionId));
        if (selectedRegion) {
          chips.push({
            label: selectedRegion.name,
            data: selectedRegion,
            className: classes.chip,
            onClick: handleChipClick,
            onDelete: handleRegionChipDelete
          });
        }
      }
    } else {
      chips.push({
        label: "Selecione pelo menos uma Região",
        className: classes.chip,
        onClick: handleChipClick
      });
    }

    if (storeList && (storeList.length > 0) && (params.storesIds.length > 0)) {
      for (let i = 0; i < params.storesIds.length; i++) {
        let storeId = params.storesIds[i];
        let selectedStore = storeList.find((store => store.id === storeId));
        if (selectedStore) {
          chips.push({
            label: selectedStore.name,
            data: selectedStore,
            className: classes.chip,
            onClick: handleChipClick,
            onDelete: handleStoreChipDelete
          });
        }
      }
    }

    setChipList(chips);
  }, [params, regionList, storeList, handleRegionChipDelete, handleStoreChipDelete, classes.chip]);

  const handleOpenRegionSelect = (event: React.ChangeEvent<{}>) => {
    setOpenRegionSelect(true);
  }

  const handleCloseRegionSelect = (event: React.ChangeEvent<{}>) => {
    setOpenRegionSelect(false);
  }

  const handleOpenStoreSelect = (event: React.ChangeEvent<{}>) => {
    setOpenStoreSelect(true);
  }

  const handleCloseStoreSelect = (event: React.ChangeEvent<{}>) => {
    setOpenStoreSelect(false);
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} justify="space-between">
        <Grid item md={3}>
          <SearchInput
            id="searchValue"
            placeholder="Busca Rápida"
            fullWidth
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.currentTarget.value);
              onSearchValueChanged(e.currentTarget.value);
            }}
          />
        </Grid>
        <Grid item md>
          <DashboardChipList chipList={chipList} />
        </Grid>
        <Grid item>
          <IconButton aria-label="filters" onClick={handleOpenDrawer}>
            <FilterListIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Drawer
        anchor={'right'}
        open={openFilterForm}
        onClose={handleCloseDrawer}
        classes={{ paper: classes.drawerPaper, }}
        ModalProps={{ keepMounted: true }}>
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <Grid container item spacing={2}>
            <Grid item md={12}>
              <Typography gutterBottom variant="h5" component="h5">
                Filtros
              </Typography>
            </Grid>
            <Grid item md={12}>
              <FormControl variant="outlined" fullWidth required={true}>
                <InputLabel id="regions-label">Regiões</InputLabel>
                <Select
                  labelId="regions-label"
                  id="regionsIds"
                  multiple
                  required={true}
                  open={openRegionSelect}
                  onOpen={handleOpenRegionSelect}
                  onClose={handleCloseRegionSelect}
                  name="regionsIds"
                  variant="outlined"
                  input={<OutlinedInput id="select-multiple-regions" label="Regiões" />}
                  value={formik.values.regionsIds}
                  onChange={e => {
                    let idsRegions = e.target.value as number[];
                    if(idsRegions.length > 0){
                      refreshStores(idsRegions);
                    }else{
                      refreshStores([]);
                    }
                    formik.handleChange(e);
                    setOpenRegionSelect(false);
                  }}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {(selected as number[]).map((value, index) => (
                        <Chip
                          key={value}
                          label={regionList !== undefined ? regionList.find((option: { id: number }) => option.id === value)?.name : value}
                          className={classes.chip}
                          deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                          onDelete={() => { handleSelectRegionDelete(value) }}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    }
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {regionList.map((region) => (
                    <MenuItem key={region.id} value={region.id}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={12}>
              <FormControl variant="outlined" fullWidth required={false}>
                <InputLabel id="stores-label">Lojas</InputLabel>
                <Select
                  labelId="stores-label"
                  id="storesIds"
                  multiple
                  required={false}
                  open={openStoreSelect}
                  onOpen={handleOpenStoreSelect}
                  onClose={handleCloseStoreSelect}
                  name="storesIds"
                  variant="outlined"
                  input={<OutlinedInput id="select-multiple-store" label="Lojas" />}
                  value={formik.values.storesIds}
                  onChange={e => {
                    formik.handleChange(e);
                    setOpenStoreSelect(false);
                  }}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {(selected as number[]).map((value, index) => (
                        <Chip
                          key={value}
                          label={storeList !== undefined ? storeList.find((option: { id: number }) => option.id === value)?.name : value}
                          className={classes.chip}
                          deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                          onDelete={() => { handleSelectStoreDelete(value) }}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    }
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {storeList.map((store) => (
                    <MenuItem key={store.id} value={store.id}>
                      {store.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>        

            <Grid item>
              <Button onClick={handleCloseDrawer} color="secondary">
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleApplyFilter} color="primary">
                OK
              </Button>
            </Grid>
          </Grid>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: "4px",
    marginRight: "4px",
  },
  drawerPaper: {
    width: "290px",
    padding: "16px",
    height: "50%",
    borderBottomLeftRadius: "10px",
  }
});