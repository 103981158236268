/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SendOutlined from "@material-ui/icons/SendOutlined";
import { LoadingPage } from "../../components/Loading";

import _ from "lodash";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import AuthService from "../../services/authService";
import ShowIf from "../../components/ShowIf";
import { useHistory } from "react-router";
import UserService from "../../services/userService";
import { IUser } from "../../store/types/user";
import { UserGroup } from "../../services/types/user";
//@ts-ignore
import { NotificationManager } from "react-notifications";

interface IDetailUserProps {
  userId: string;
  match: any;
}

export const DetailUser: React.FC<IDetailUserProps> = ({ match }) => {
  const [user, setUser] = React.useState<IUser>();
  const [groups, setGroups] = React.useState<UserGroup[]>([]);
  
  const getUser = async (userId: number) => {
    await UserService.getUser(userId).then((response) =>{
      setUser(response);
      
    }).catch((error) => {})
  };

  const getGroups = async () => {
    await UserService.getGroups().then((response) =>{
      setGroups(response);
    }).catch((error) => {})
  };

  const sendActivate = async (userId: number) => {
    await UserService.sendActivate(userId).then((resp) => {
      NotificationManager.success(
        "E-mail enviado com sucesso",
        "Reenvio de ativação"
      );
    }).catch((error) => {
      NotificationManager.error(
        "Não foi possivel reenviar o email.",
        "Reenvio de ativação"
      );
    })
  };
    
  const { userId } = useParams<IDetailUserProps>();
  const history = useHistory();

  const handleConvertGroup = useCallback(
    (groupsIds) => {
      return groupsIds.map((item: Number) => {
        return groups?.find((group) => group.id === item)?.name || "";
      });
    },
    [groups]
  );
  const handleSendActivation = useCallback(
    _.debounce(() => sendActivate(Number(userId)), 1000),
    [userId]
  );

  useEffect(() => {
    getUser(Number(userId));
    getGroups();
  }, [userId]);

  const goToEdit = () => {
    history.push({ pathname: `/user/edit/${userId}` })    
  };

  return (
    <div >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Detalhes do Usuário</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[
                { label: "Usuários", url: "/user" },
                {
                  label: "Detalhes do Usuário",
                  url: `/user/detail/${match.params.userId}`,
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid item justify="flex-end">
          <ShowIf condition={AuthService.hasAuthorization("change_user")}>
            <Button
              color="secondary"
              variant="contained"
              style={{ marginRight: 10 }}
              startIcon={<EditOutlinedIcon />}
              onClick={() => {goToEdit();}}>
              Editar
            </Button>
          </ShowIf>

          <ShowIf condition={AuthService.hasAuthorization("resend_management_user_activation_email")}>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSendActivation}
              startIcon={<SendOutlined />}
            >
              Reenviar Ativação
            </Button>
          </ShowIf>
        </Grid>
      </Grid>

      {_.isEmpty(user) && user && !user.id ? (
        <LoadingPage />
      ) : (
        <>
          <Card>
            <CardContent>
              <Typography variant="h2">Dados pessoais</Typography>
              <Table className="table-bordered" >
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography>Nome Completo</Typography>
                      <Typography variant="subtitle1">{`${user ? user.first_name : "---"} ${user ? user.last_name : ""}`}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>E-mail</Typography>
                      <Typography variant="subtitle1">{user ? user.email : "---"}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>CPF</Typography>
                      <Typography variant="subtitle1">{user ? user.registration_number : "---"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Celular</Typography>
                      <Typography variant="subtitle1">{user ? user.phonenumber : "---"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Grupos</Typography>
                      <Typography variant="subtitle1">
                        {!!groups.length && handleConvertGroup(user ? user.groups : []).toString()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography variant="h2">Endereço</Typography>
              <Table className="table-bordered" >
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>CEP</Typography>
                      <Typography variant="subtitle1">{user ? user.postal_code : "---"}</Typography>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Typography>Rua</Typography>
                      <Typography variant="subtitle1">{user ? user.street : "---"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Número</Typography>
                      <Typography variant="subtitle1">{user ? user.address_number ? user.address_number : "---" : "---"}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Complemento</Typography>
                      <Typography variant="subtitle1">{user ? user.address_complement ? user.address_complement : "---" : "---"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Bairro</Typography>
                      <Typography variant="subtitle1">{user ? user.district : "---"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Cidade</Typography>
                      <Typography variant="subtitle1">{user ? user.city : "---"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Estado</Typography>
                      <Typography variant="subtitle1">{user ? user.state : "---"}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
};
