import React from "react";
import {
  Button,
  Chip,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { RegionResponse } from "../../../services/types/region";
import SearchInput from "../../../components/SearchInput";
import { ChipListItem, DashboardChipList } from "../customComponents/dashboardChipList";
import FilterListIcon from '@material-ui/icons/FilterList';
import CancelIcon from '@material-ui/icons/Cancel';

export interface DashboardCourierScheduleFilterParams {
  regionsIds: number[];
}

export const COURIER_SCHEDULE_INITIAL_FILTER_PARAMS: DashboardCourierScheduleFilterParams = {
  regionsIds: [],
};

interface DashboardCourierScheduleFilterProps {
  params?: DashboardCourierScheduleFilterParams;
  regionList: RegionResponse[];
  onSearchValueChanged: (value: string) => void;
  onFilterChanged: (params: DashboardCourierScheduleFilterParams) => void;
}

export const DashboardCourierScheduleFilter: React.FC<DashboardCourierScheduleFilterProps> = ({
  params = COURIER_SCHEDULE_INITIAL_FILTER_PARAMS,
  regionList = [],
  onSearchValueChanged,
  onFilterChanged,
}) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [openFilterForm, showFilterForm] = React.useState<boolean>(false);
  const [openRegionSelect, setOpenRegionSelect] = React.useState<boolean>(false);
  const [chipList, setChipList] = React.useState<ChipListItem[]>([]);


  const formik = useFormik({
    initialValues: params,
    onSubmit: (values) => {
      onFilterChanged({ ...values });
      showFilterForm(false);
    },
    onReset: (values) => {
      showFilterForm(false);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleOpenDrawer = () => {
    showFilterForm(true);
  };

  const handleApplyFilter = () => {
    formik.submitForm();
  };

  const handleCloseDrawer = () => {
    formik.resetForm();
  };

  const handleChipClick = (data: any) => {
    showFilterForm(true);
  }
  // Region
  const handleRegionChipDelete = React.useCallback((deletedRegion: RegionResponse) => {
    const selectedRegionsIds = [...params.regionsIds];
    if (deletedRegion && selectedRegionsIds) {
      const index = selectedRegionsIds.indexOf(deletedRegion.id);
      if (index > -1) {
        selectedRegionsIds.splice(index, 1);
      }
      formik.setFieldValue("regionsIds", selectedRegionsIds);
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.regionsIds]);

  const handleSelectRegionDelete = (deletedRegionId: number) => {
    const selectedRegionsIds = [...formik.values.regionsIds];
    if (deletedRegionId && selectedRegionsIds) {
      const index = selectedRegionsIds.indexOf(deletedRegionId);
      if (index > -1) {
        selectedRegionsIds.splice(index, 1);
      }
      formik.setFieldValue("regionsIds", selectedRegionsIds);
    }
  };


  React.useEffect(() => {
    const chips: ChipListItem[] = [];
    if (regionList && (regionList.length > 0) && (params.regionsIds.length > 0)) {
      for (let i = 0; i < params.regionsIds.length; i++) {
        let regionId = params.regionsIds[i];
        let selectedRegion = regionList.find((region => region.id === regionId));
        if (selectedRegion) {
          chips.push({
            label: selectedRegion.name,
            data: selectedRegion,
            className: classes.chip,
            onClick: handleChipClick,
            onDelete: handleRegionChipDelete
          });
        }
      }
    } else {
      chips.push({
        label: "Todas as Regiões",
        className: classes.chip,
        onClick: handleChipClick
      });
    }

    setChipList(chips);
  }, [params, regionList, handleRegionChipDelete, classes.chip]);

  const handleOpenRegionSelect = (event: React.ChangeEvent<{}>) => {
    setOpenRegionSelect(true);
  };

  const handleCloseRegionSelect = (event: React.ChangeEvent<{}>) => {
    setOpenRegionSelect(false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} justify="space-between">
        <Grid item md={3}>
          <SearchInput
            id="searchValue"
            placeholder="Busca Rápida"
            value={searchValue}
            fullWidth
            onChange={(e) => {
              setSearchValue(e.currentTarget.value);
              onSearchValueChanged(e.currentTarget.value);
            }}
          />
        </Grid>
        <Grid item md>
          <DashboardChipList chipList={chipList} />
        </Grid>

        <Grid item>
          <IconButton aria-label="filters" onClick={handleOpenDrawer}>
            <FilterListIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Drawer
        anchor={'right'}
        open={openFilterForm}
        onClose={handleCloseDrawer}
        classes={{ paper: classes.drawerPaper, }}
        ModalProps={{ keepMounted: true }}>
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <Grid container item spacing={2}>
            <Grid item md={12}>
              <Typography gutterBottom variant="h5" component="h5">
                Filtros
              </Typography>
            </Grid>
            <Grid item md={12}>
              <FormControl variant="outlined" fullWidth >
                <InputLabel id="regions-label">Regiões</InputLabel>
                <Select
                  labelId="regions-label"
                  id="regionsIds"
                  multiple
                  open={openRegionSelect}
                  onOpen={handleOpenRegionSelect}
                  onClose={handleCloseRegionSelect}
                  name="regionsIds"
                  variant="outlined"
                  input={<OutlinedInput id="select-multiple-regions" label="Regiões" />}
                  value={formik.values.regionsIds}
                  onChange={e => {
                    formik.handleChange(e);
                    setOpenRegionSelect(false);
                  }}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {(selected as number[]).map((value, index) => (
                        <Chip
                          key={value}
                          label={regionList !== undefined ? regionList.find((option: { id: number }) => option.id === value)?.name : value}
                          className={classes.chip}
                          deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                          onDelete={() => { handleSelectRegionDelete(value) }}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    }
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {regionList.map((region) => (
                    <MenuItem key={region.id} value={region.id}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <Button onClick={handleCloseDrawer} color="secondary">
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleApplyFilter} color="primary">
                OK
              </Button>
            </Grid>
          </Grid>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: "4px",
    marginRight: "4px",
  },
  drawerPaper: {
    width: "280px",
    padding: "16px",
    height: "40%",
    borderBottomLeftRadius: "10px",
  }
});
