import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import { FormikHelpers, useFormik } from "formik";
import { useHistory } from "react-router";
import { encodeURLParams } from "../../utils/utils";

export interface IRegionFilterParams {
  name: string;
  state: string;
  metropolitanArea: string;
}

export const INITIAL_FILTER_PARAMS: IRegionFilterParams = {
  name: "",
  state: "",
  metropolitanArea: "",
};

interface IRegionFilterProps {
  params?: IRegionFilterParams;
  cityList: any[];
  onFilterChanged: (params: IRegionFilterParams) => void;
}

export const RegionFilter: React.FC<IRegionFilterProps> = ({ params = INITIAL_FILTER_PARAMS, cityList, onFilterChanged }) => {
  const history = useHistory();

  React.useEffect(() => {
    history.replace(encodeURLParams(history.location.pathname, params));
  }, [history, params]);

  const formik = useFormik<IRegionFilterParams>({
    initialValues: params,
    onSubmit: (values: IRegionFilterParams, formikHelpers: FormikHelpers<IRegionFilterParams>) => {
      onFilterChanged(values);
    },
    onReset: (values: IRegionFilterParams, formikHelpers: FormikHelpers<IRegionFilterParams>) => {
      onFilterChanged(INITIAL_FILTER_PARAMS);
      formikHelpers.setValues(INITIAL_FILTER_PARAMS);
    }
  });

  return (
    <div className="page-filter">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <TextField
              id="name"
              label="Nome"
              value={formik.values.name}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              id="state"
              label="Estado"
              value={formik.values.state}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\d/g, "").toUpperCase();
                formik.handleChange(e);
              }}
              variant="outlined"
              fullWidth
              inputProps={{
                maxLength: 2,
              }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              id="metropolitanArea"
              name="metropolitanArea"
              label="Área Metropolitana"
              select
              onChange={formik.handleChange}
              value={formik.values.metropolitanArea}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="">Todas</MenuItem>
              {cityList.map((city) => (
                <MenuItem key={city.id} value={city.name}>
                  {city.name} - {city.state_initials}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              Pesquisar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" type="reset">
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
