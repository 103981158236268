import queryString from "query-string";

export const API_URI = process.env.REACT_APP_BACKEND_URL;

/*
recebe a URL base e o objeto de parametros a ser consultado
retorna uma URL com os Query params para consulta
*/
export const getURL = (baseUrl: string, params: { [any: string]: any }) => {
    return queryString.stringifyUrl({
        url: baseUrl,
        query: params,
    });
};

export const urls = {
    USER: `${API_URI}management_users/`,
    TOKEN: `${API_URI}manager-token-auth/`,
    TOKEN_REFRESH: `${API_URI}api-refresh-token/`,
    USER_GROUP: `${API_URI}groups/`,
    STATUS: `${API_URI}user_status/`,
    REQUEST_PASSWORD_RESET: `${API_URI}password/reset/`,
    PASSWORD_RESET: `${API_URI}password/reset/confirm/`,
    PASSWORD_CHANGE: `${API_URI}password/change/`,
    COURIER: `${API_URI}courier_users/`,
    COURIER_FINANCE: `${API_URI}courier_finances/`,
    CUSTOMER: `${API_URI}customers/`,
    CUSTOMER_FINANCE: `${API_URI}customer_finances/`,
    CUSTOMER_FINANCES: `${API_URI}customer_finances/`,
    CUSTOMER_FINANCES_ENTRIES: `${API_URI}customer_finances/entries/`,
    CUSTOMER_FINANCES_PAY_CREDITS: `${API_URI}customer_finances/pay_credits/`,
    CUSTOMER_FINANCES_PAY_CREDITS_PIX: `${API_URI}customer_finances/pay_credits/pix/`,
    STORE: `${API_URI}customers_stores/`,
    STORE_SCHEDULE: `${API_URI}business_schedules/`,
    CUSTOMER_USER: `${API_URI}customer_users/`,
    COMMERCIAL_PLAN: `${API_URI}commercial_plans/`,
    COMMISSION_REPORTS: `${API_URI}commission_reports/`,
    CITY: `${API_URI}cities/`,
    BRAZILIAN_STATE: `${API_URI}states/`,
    REGION: `${API_URI}regions/`,
    REMITTANCE: `${API_URI}remittances/`,
    TRANSACTION: `${API_URI}transactions/`,
    REGION_KM_RULE: `${API_URI}region_km_rules/`,
    DELIVERY_REQUEST: `${API_URI}delivery_requests/`,
    TRIP: `${API_URI}trips/`,
    ACTIVATE: `${API_URI}activate/`,
    DELIVERY_SETTINGS: `${API_URI}delivery_settings/`,
    ADDITIONA_KM: `${API_URI}additional_km/`,
    SET_COURIER_REASON: `${API_URI}set_courier_reasons/`,
    ANNOUNCEMENTS: `${API_URI}announcements/`,
    INCENTIVE_DYNAMICS: `${API_URI}incentive_dynamics/`,
    BANKS: `${API_URI}banks/`,
    INTEGRATIONS: `${API_URI}integration_connections/`,
    BLOCKLIST: `${API_URI}blocklist_records/`,
    NOTIFICATION_TEMPLATE: `${API_URI}notification_templates/`,
    TRACKING: `${API_URI}tracking/`,
    OCCURENCES: `${API_URI}courier_occurences/`,
    DEBIT_REPORTS: `${API_URI}debit_reports/`,
    DEDICATED_PROPOSALS: `${API_URI}dedicated_proposals/`,
    DEDICATED_SLOTS: `${API_URI}dedicated_slots/`,
    DEDICATED_BLOCK_LIST: `${API_URI}dedicated_blocklists/`,
    SPEEDY_FINANCES: `${API_URI}speedy_finances/`,
    REFERRAL_SETTINGS: `${API_URI}referral_settings/`,
    REFERRAL_CODES: `${API_URI}referral_codes/`,
    FINANCIAL_SETTINGS: `${API_URI}financial_settings/`,


};
