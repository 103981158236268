import { apiAxios as axios } from "../store/api/config";
import { getURL, urls } from "../store/api/urls";
import { convertToNumber } from "../utils/utils";
import { IPageableParams, PagedResponse } from "./types/common";
import { CreateUpdateRegion, RegionCordinates, RegionResponse } from "./types/region";

export interface IRegionService {
    getRegions: (
        name: string,
        state: string,
        metropolitanArea: string,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<RegionResponse>>;
    loadAllRegions: (params: { [any: string]: any }) => Promise<RegionResponse[]>;
    getRegion: (regionId: number) => Promise<RegionResponse>;
    getRegionArea: (regionId: number) => Promise<Object>;

    createRegion: (region: CreateUpdateRegion) => Promise<any>;
    updateRegion: (regionId: number, region: CreateUpdateRegion) => Promise<any>;
}

const RegionService: IRegionService = {
    getRegions: async (
        name: string,
        state: string,
        metropolitanArea: string,
        pageParams: IPageableParams
    ) => {
        const url = getURL(urls.REGION, {
            ...pageParams,
            name: name,
            state: state,
            metropolitan_area: metropolitanArea,
        });
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    const data: RegionResponse[] = response.data.results.map((item: any) => {
                        const coordinates: RegionCordinates[] = item.coordinates?.map((coord: any) => {
                            return {
                                ...coord,
                                latitude: convertToNumber(coord.latitude),
                                longitude: convertToNumber(coord.longitude)
                            }
                        });
                        return {
                            ...item,
                            id: convertToNumber(item.id),
                            max_ignore_count: convertToNumber(item.max_ignore_count),
                            ignore_timeout_minutes: convertToNumber(item.ignore_timeout_minutes),
                            coordinates: coordinates
                        };
                    });
                    const result: PagedResponse<RegionResponse> = {
                        data: data,
                        count: response.data.count,
                    };
                    resolve(result);
                })
                .catch((error) => reject(error));
        });
    },

    loadAllRegions: async (params: { [any: string]: any }) => {
        const url = getURL(`${urls.REGION}all/`, params);
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    const result: RegionResponse[] = response.data.map((item: any) => {
                        const coordinates: RegionCordinates[] = item.coordinates?.map((coord: any) => {
                            return {
                                ...coord,
                                latitude: convertToNumber(coord.latitude),
                                longitude: convertToNumber(coord.longitude)
                            }
                        });
                        return {
                            ...item,
                            id: convertToNumber(item.id),
                            max_ignore_count: convertToNumber(item.max_ignore_count),
                            ignore_timeout_minutes: convertToNumber(item.ignore_timeout_minutes),
                            coordinates: coordinates
                        };
                    });
                    resolve(result);
                })
                .catch((error) => reject(error));
        });
    },

    getRegion: (regionId: number) => {
        const url = `${urls.REGION}${regionId}/`;
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    const coordinates: RegionCordinates[] = response.data.coordinates?.map((coord: any) => {
                        return {
                            ...coord,
                            latitude: convertToNumber(coord.latitude),
                            longitude: convertToNumber(coord.longitude)
                        }
                    });
                    const result: RegionResponse = {
                        ...response.data,
                        id: convertToNumber(response.data.id),
                        max_ignore_count: convertToNumber(response.data.max_ignore_count),
                        ignore_timeout_minutes: convertToNumber(response.data.ignore_timeout_minutes),
                        coordinates: coordinates
                    };
                    resolve(result);
                })
                .catch((error) => reject(error));
        });
    },

    getRegionArea: (regionId: number) => {
        const url = `${urls.REGION}${regionId}/get_full_area/`;
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    resolve(response.data.polygons);
                })
                .catch((error) => reject(error));
        });
    },

    createRegion: (region: CreateUpdateRegion) => {
        let support_phone: string | null = null;
        if (region.support_phone) {
            support_phone = region.support_phone.replace(/\D/g, "");
        }
        return new Promise((resolve, reject) => {
            axios
                .post(urls.REGION, {
                    ...region,
                    support_phone: support_phone
                })
                .then(response => resolve(response.data))
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        });
    },

    updateRegion: (regionId: number, region: CreateUpdateRegion) => {
        let support_phone: string | null = null;
        if (region.support_phone) {
            support_phone = region.support_phone.replace(/\D/g, "");
        }
        const url = `${urls.REGION}${regionId}/`;
        return new Promise((resolve, reject) => {
            axios
                .patch(url, {
                    ...region,
                    support_phone: support_phone
                })
                .then(response => resolve(response.data))
                .catch(error => {
                    reject(error);
                });
        });
    },

}

export default RegionService;