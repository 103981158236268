import React from "react";
import {
    Grid,
    TextField,
    MenuItem,
    Button,
} from "@material-ui/core";

import { IGroup } from "../../store/types/user";
import { $enum } from "ts-enum-util";
import { FormikHelpers, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { UserStatus } from "../../services/types/user";
import { useHistory } from "react-router";
import { encodeURLParams } from "../../utils/utils";
export interface IUserFilterParams {
    name: string;
    email: string;
    user_status: string;
    groups: any;
}

export const INITIAL_FILTER_PARAMS: IUserFilterParams = {
    name: "",
    email: "",
    user_status: "",
    groups: [],
};

interface IUserFilterProps {
    params?: IUserFilterParams;
    groupList: IGroup[];
    onFilterChanged: (params: IUserFilterParams) => void;
}

export const UserFilter: React.FC<IUserFilterProps> = ({
    params = INITIAL_FILTER_PARAMS,
    groupList,
    onFilterChanged,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    React.useEffect(() => {
        history.replace(encodeURLParams(history.location.pathname, params));
    }, [history, params]);

    const formik = useFormik<IUserFilterParams>({
        initialValues: params,
        onSubmit: (values: IUserFilterParams, formikHelpers: FormikHelpers<IUserFilterParams>) => {
            onFilterChanged(values);
        },
        onReset: (values: IUserFilterParams, formikHelpers: FormikHelpers<IUserFilterParams>) => {
            onFilterChanged(INITIAL_FILTER_PARAMS);
            formikHelpers.setValues(INITIAL_FILTER_PARAMS);
        },
    });

    return (
        <div className="page-filter">
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            id="name"
                            label="Usuário"
                            onChange={formik.handleChange}
                            fullWidth
                            value={formik.values.name}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="email"
                            label="E-mail"
                            onChange={formik.handleChange}
                            fullWidth
                            value={formik.values.email}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="groups"
                            name="groups"
                            label="Perfil"
                            select
                            onChange={formik.handleChange}
                            value={formik.values.groups}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value={[]}>Todos</MenuItem>
                            {groupList.map(group => (
                                <MenuItem key={group.id} value={group.id}>
                                    {group.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="user_status"
                            name="user_status"
                            label="Situação"
                            select
                            onChange={formik.handleChange}
                            value={formik.values.user_status}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value="">Todas</MenuItem>
                            {$enum(UserStatus).map((status, index) => {
                                return (
                                    <MenuItem key={index} value={status}>
                                        {t("user_status." + status)}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" type="submit">
                            Pesquisar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" type="reset">
                            Limpar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
