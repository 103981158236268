import { apiAxios } from "../api/config";
import { urls } from "../api/urls";
import { ISettigs, IAdditionalKm } from "../types/settings";
import { AppDispatch } from "..";
//@ts-ignore
import { NotificationManager } from "react-notifications";

export const Types = {
  GET_SETTINGS_REQUEST: "GET_SETTINGS_REQUEST",
  GET_SETTINGS_SUCCESS: "GET_SETTINGS_SUCCESS",
  GET_SETTINGS_FAILURE: "GET_SETTINGS_FAILURE",

  GET_ADDITIONAL_KM_REQUEST: "GET_ADDITIONAL_KM_REQUEST",
  GET_ADDITIONAL_KM_SUCCESS: "GET_ADDITIONAL_KM_SUCCESS",
  GET_ADDITIONAL_KM_FAILURE: "GET_ADDITIONAL_KM_FAILURE",

  UPDATE_SETTINGS_REQUEST: "UPDATE_SETTINGS_REQUEST",
  UPDATE_SETTINGS_SUCCESS: "UPDATE_SETTINGS_SUCCESS",
  UPDATE_SETTINGS_FAILURE: "UPDATE_SETTINGS_FAILURE",
};

export interface IState {
  settings: ISettigs;
  additional_km: IAdditionalKm[];
  isLoading: boolean;
  error: string;
}

const INITIAL_STATE = {
  settings: {} as ISettigs,
  additional_km: [],
  isLoading: false,
  error: "",
};

export default (
  state = INITIAL_STATE,
  action: { type: string; payload?: any }
): IState => {
  switch (action.type) {
    case Types.GET_SETTINGS_REQUEST:
      return { ...state, isLoading: true, error: "" };
    case Types.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload.settings,
        isLoading: false,
      };
    case Types.GET_SETTINGS_FAILURE:
      return { ...state, error: action.payload.error, isLoading: false };

    case Types.GET_ADDITIONAL_KM_REQUEST:
      return { ...state, isLoading: true, error: "" };
    case Types.GET_ADDITIONAL_KM_SUCCESS:
      return {
        ...state,
        additional_km: action.payload.additional_km,
        isLoading: false,
      };
    case Types.GET_ADDITIONAL_KM_FAILURE:
      return { ...state, error: action.payload.error, isLoading: false };

    case Types.UPDATE_SETTINGS_REQUEST:
      return { ...state, isLoading: true, error: "" };
    case Types.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload.settings,
        isLoading: false,
      };
    case Types.UPDATE_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };

    default:
      return { ...state };
  }
};

export const Actions = {
  getSettings: (dispatch: AppDispatch) => {
    dispatch({
      type: Types.GET_SETTINGS_REQUEST,
    });
    apiAxios
      .get(`${urls.DELIVERY_SETTINGS}get_settings/`)
      .then((response) => {
        dispatch({
          type: Types.GET_SETTINGS_SUCCESS,
          payload: {
            settings: {
              ...response.data,
              settings: response.data.settings,
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.GET_SETTINGS_FAILURE,
          payload: { error },
        });
      });
  },
  updateSettings: (dispatch: AppDispatch, settings: ISettigs) => {
    dispatch({
      type: Types.UPDATE_SETTINGS_REQUEST,
    });
    apiAxios
      .post(`${urls.DELIVERY_SETTINGS}update_settings/`, settings)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_SETTINGS_SUCCESS,
          payload: { settings: response.data },
        });
        NotificationManager.success(
          " Configurações atualizadas com sucesso",
          "Nova Configurações"
        );
      })
      .catch((error) => {
        let errorMsg = "Ocorreu um erro ao editar as configurações";
        const errorKeys = Object.keys(error.response.data);
        if (errorKeys.length) {
          errorMsg = error.response.data[errorKeys[0]];
        }
        dispatch({
          type: Types.UPDATE_SETTINGS_FAILURE,
          payload: { errorMsg },
        });
        NotificationManager.error(errorMsg, "Erro");
      });
  },
  getAdditionalKm: (dispatch: AppDispatch) => {
    dispatch({
      type: Types.GET_ADDITIONAL_KM_REQUEST,
    });
    apiAxios
      .get(`${urls.ADDITIONA_KM}`)
      .then((response) => {
        dispatch({
          type: Types.GET_ADDITIONAL_KM_SUCCESS,
          payload: { additional_km: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.GET_ADDITIONAL_KM_FAILURE,
          payload: { error },
        });
      });
  },
};
