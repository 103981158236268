import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import {
    INITIAL_FILTER_PARAMS,
    IUserFilterParams,
    UserFilter,
} from "./UserFilter";
import { Link, useHistory } from "react-router-dom";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { useTranslation } from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import UserService from "../../services/userService";
import {
    DEFAULT_TABLE_COMPONENTS,
    DEFAULT_TABLE_OPTIONS,
    PageableParams,
    TABLE_L10N_PTBR,
} from "../../components/TableTrinkets";
import { mapUserStatusToSeverity } from "./userUtils";
import MaterialTable, {
    MaterialTableProps,
    Query,
    QueryResult,
} from "@material-table/core";
import { UserResponse } from "../../services/types/user";
import { VisibilityOutlined } from "@material-ui/icons";
import ShowIf from "../../components/ShowIf";
import AuthService from "../../services/authService";
import { decodeURLParams } from "../../utils/utils";

interface IUserProps { }

export const User: React.FC<IUserProps> = () => {
    const { t } = useTranslation();

    const [groupList, setGroupList] = useState<any[]>([]);
    const history = useHistory();
    const [filterParams, setFilterParams] = useState<IUserFilterParams>({
        ...INITIAL_FILTER_PARAMS,
        ...decodeURLParams(history.location.search)
    });
    const tableRef = React.useRef<MaterialTableProps<UserResponse>>();

    const loadData = (query: Query<UserResponse>) =>
        new Promise<QueryResult<UserResponse>>((resolve, reject) => {
            UserService.getUsers(
                filterParams.name,
                filterParams.email,
                filterParams.user_status,
                filterParams.groups,
                PageableParams.fromQuery(query)
            ).then(response => {
                resolve({
                    data: response.data,
                    page: query.page,
                    totalCount: response.count,
                });
            });
        });

    useEffect(() => {
        const getGroups = async () => {
            const response = await UserService.getGroups();
            setGroupList(response);
        };

        getGroups();
    }, []);

    const applyFilter = (params: IUserFilterParams) => {
        setFilterParams(params);
        if (tableRef.current?.onQueryChange) {
            tableRef.current.onQueryChange({ page: 0 } as Query<UserResponse>);
        }
    };

    const showDetails = async (item: UserResponse) => {
        history.push(`/user/detail/${item.id}`);
    };

    return (
        <div>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Usuários</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[{ label: "Usuários", url: "/user" }]}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <ShowIf condition={AuthService.hasAuthorization("add_user")}>
                        <Button color="primary" variant="contained"
                            startIcon={<AddCircleOutlineRoundedIcon />}
                            component={Link} to={"/user/new"}>
                            Novo usuário
                        </Button>
                    </ShowIf>
                </Grid>
            </Grid>

            <UserFilter params={filterParams} groupList={groupList} onFilterChanged={applyFilter} />

            <MaterialTable
                tableRef={tableRef}
                columns={[
                    { field: "id", hidden: true, width: 60 },
                    { field: "name", title: "Nome", defaultSort: "asc" },
                    { field: "email", title: "E-mail" },
                    {
                        field: "groups",
                        title: "Perfil",
                        render: (rowData, type) => {
                            const result: String[] = rowData.groups.map(
                                item => item.name
                            );
                            return result.join(", ");
                        },
                    },
                    {
                        field: "user_status",
                        title: "Situação",
                        render: (rowData, type) => (
                            <Alert
                                severity={mapUserStatusToSeverity(
                                    rowData.user_status
                                )}
                                icon={false}
                            >
                                {t("user_status." + rowData.user_status)}
                            </Alert>
                        ),
                    },
                ]}
                data={loadData}
                actions={[
                    {
                        icon: () => <VisibilityOutlined />,
                        tooltip: "Ver Detalhes",
                        onClick: (event, rowData) =>
                            showDetails(rowData as UserResponse),
                    },
                ]}
                components={DEFAULT_TABLE_COMPONENTS}
                localization={TABLE_L10N_PTBR}
                options={DEFAULT_TABLE_OPTIONS}
            />
        </div>
    );
};
