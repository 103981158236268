import React, { useCallback, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { DeliveryRequestLog, Trip } from "../../../services/types/deliveryRequest";
import TripService from "../../../services/tripService";
import { DashboardTimelineTrip } from "../../../components/DashboardTimeLineTrip";

interface ITimelineTripProps {
  trip: Trip;
  refreshData: () => void;
}

export const TimelineTrip: React.FC<ITimelineTripProps> = ({ trip, refreshData }) => {
  const classes = useStyles();
  const [logList, setLogList] = React.useState<DeliveryRequestLog[]>([]);
  const loadLogs = useCallback(async (id: number) => {
    const response = await TripService.getTripRequestLogs(id);
    setLogList(response);
  }, []);

  useEffect(() => {
    loadLogs(trip.id);
  }, [trip, loadLogs]);

  return (
    <div className={classes.root}>
      <DashboardTimelineTrip
        logs={logList}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  })
);
