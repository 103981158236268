import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Box,
  Divider,
  IconButton,
  FormHelperText,
  createStyles,
  Theme,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { BarcodeIcon, TimerSandIcon, PixIcon } from "../../../components/icons";

import numbro from "numbro";
import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import { LoadingOverlay } from "../../../components/LoadingOverlay";
import CustomerService from "../../../services/customerService";
import { CustomerFinancialStats, FinancialEntryOrigin } from "../../../services/types/customerFinances";
import { CurrencyInput } from "../../../components/CurrencyInput";
import { CustomerDetails } from "../../../services/types/customer";
import { CNPJInput } from "../../../components/CNPJInput";
import { CPF_CNPJInput } from "../../../components/CPF_CNPJInput";
import { PhoneInput } from "../../../components/PhoneInput";
import { PostCodeInput } from "../../../components/PostCodeInput";
//@ts-ignore
import { NotificationManager } from "react-notifications";

interface IInsertCredits {
  open: boolean;
  idCustomer: number; 
  setOpen: (value: boolean) => void;
  updateFinances: () => void;
  setPixDetails: (value: any) => void;
  setOpenDialog: (value: boolean) => void;
}

interface FinancesInsertCredit {
  value: number;
  payment_method?: FinancialEntryOrigin.BANK_SLIP | FinancialEntryOrigin.PIX;
}

const INITIAL_VALUES: FinancesInsertCredit = {
  value: 0,
};

export const insertCreditValidation = Yup.object().shape({
  value: Yup.number()
    .required("Campo obrigatório")
    .transform((currentValue, originalValue) => numbro.unformat(originalValue))
    .min(100, "O valor mínimo da recarga é R$ 100,00")
    .max(99999, "O valor máximo da recarga é R$ 99.999,00"),
  payment_method: Yup.string().required("Selecione a forma de pagamento")
});

const InsertCredit: React.FC<IInsertCredits> = ({
  open,
  idCustomer,
  setOpen,
  updateFinances,
  setPixDetails,
  setOpenDialog,
}) => {
  const [customer, setCustomer] = useState<CustomerDetails>();
  const [customerStats, setCustomerStats] = useState<CustomerFinancialStats>();
  const [estimatedCreditDuration, setEstimatedCreditDuration] = useState<number>(0);
  const classes = useStyles();

  const updateEstimatedDuration = useCallback(
    (credit: number, customerStats: CustomerFinancialStats | undefined) => {
      if (customerStats) {
        let duration: number =
          (credit + customerStats.current_balance) /
          (customerStats.average_weekly_expenses / 7);
        if (isNaN(duration)) {
          duration = 0;
        }
        setEstimatedCreditDuration(Math.max(Math.trunc(duration), 0));
      } else {
        setEstimatedCreditDuration(0);
      }
    },
    []
  );

  const loadData = useCallback(
    (customerId: number) => {
      CustomerService.getCustomer(customerId)
        .then(customer => {
          setCustomer(customer);
        })
        .catch(error => { });

      CustomerService.getCustomerDeliveryStats(customerId, 0)
        .then(stats => {
          setCustomerStats(stats);
          updateEstimatedDuration(0, stats);
        })
        .catch(error => { });
    },
    [updateEstimatedDuration]
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: async values => {
      try {
        if (idCustomer && values.payment_method) {
          const response = await CustomerService.addCreditCustomer(
            idCustomer,
            numbro.unformat(String(values.value)),
            values.payment_method
          );
          handleClose();
          updateFinances();

          if (values.payment_method === FinancialEntryOrigin.PIX) {
            NotificationManager.success("PIX gerado com sucesso!");
            setPixDetails({
              bank_slip_barcode: response.bank_slip_barcode,
              value: Number(response.value),
              tax: Number(response.tax),
              total: Number(response.total),
            });
            setOpenDialog(true);
          } else {
            NotificationManager.success("Boleto gerado com sucesso!");

            const bankSlip =
              await CustomerService.getBankSlipDocument(response.id);

            const newWindow: any = window.open("about:blank");
            newWindow.document.open();
            newWindow.document.write(bankSlip);
            newWindow.document.close();
          }
        }
      } catch (error) {
        let errorMsg = "Ocorreu um erro ao emitir o Boleto.";
        if (values.payment_method === FinancialEntryOrigin.PIX) {
          errorMsg = "Ocorreu um erro ao gerar o PIX.";
        }

        const { status, data } = error.response;
        const errorMsgs: string[] = [];
        if (status === 400) {
          for (var key in data) {
            var value = data[key];
            if (key in values) {
              formik.setFieldError(key, value);
            } else {
              errorMsgs.push(value);
            }
          }

          if (errorMsgs.length > 0) {
            errorMsg = errorMsgs.join(". \n");
          }
        }
        NotificationManager.error(errorMsg);

      }
    },
    validationSchema: insertCreditValidation,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handlePayment = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    if (value !== null) {
      formik.setFieldValue("payment_method", value);
      formik.setFieldError("payment_method", undefined);
    }
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  function isCNPJ(value: string | null | undefined): boolean {
    if (value) {
      return value.length > 11;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (idCustomer && open) {
      loadData(idCustomer);
    }
  }, [idCustomer, open, loadData]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <LoadingOverlay open={formik.isSubmitting} />
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <DialogTitle id="form-dialog-title">
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography variant="h1">Pagamento</Typography>
              <IconButton aria-label="close" onClick={handleClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ width: "600px" }}>
            <Grid item xs={9}>
              <Typography variant="h6" className="dark">
                Média de consumo semanal: 
                <strong className="ml-2">
                  {numbro(customerStats?.average_weekly_expenses).formatCurrency()}
                </strong>
              </Typography>
              <Typography variant="h6" className="dark">
                Saldo atual: 
                <strong
                  className={
                    customerStats?.current_balance
                      ? customerStats?.current_balance > 0
                        ? classes.primary
                        : classes.negative
                      : ""
                  }
                >
                  {numbro(customerStats?.current_balance).formatCurrency()}
                </strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box
                p={3}
                bgcolor="info.main"
                style={{ backgroundColor: "#ecf0f5", borderRadius: "8px" }}
              >
                <Grid container direction="row">
                  <Grid xs={6}>
                    <Typography component="div" variant="body1">
                      <label htmlFor="value" className="dark bold">
                        Valor da recarga
                      </label>
                    </Typography>
                    <TextField
                      id="value"
                      style={{ marginTop: "5px", textAlign: "right" }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={e => {
                        if (e.target) {
                          let credit = 0;
                          if (e.target.value !== null && e.target.value !== undefined && e.target.value !== "") {
                            credit = numbro.unformat(String(e.target.value));
                          }
                          updateEstimatedDuration(credit, customerStats);
                        }
                        formik.handleChange(e);
                      }}
                      value={formik.values.value}
                      error={!!formik.errors.value}
                      helperText={
                        formik.errors.value ||
                        "O valor mínimo da recarga é R$ 100,00"
                      }
                      inputProps={{
                        maxLength: 9,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className="secondary"
                          >
                            <strong>R$</strong>
                          </InputAdornment>
                        ),
                        inputComponent: CurrencyInput,
                      }}
                    />
                  </Grid>
                  <Grid
                    xs={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography
                      component="div"
                      variant="body1"
                      className="secondary"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <TimerSandIcon />
                      Duração estimada:
                      <strong className="dark ml-1">
                        {estimatedCreditDuration} dia(s).
                      </strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <fieldset style={{ border: "0px solid #ddd", borderTop: "1px solid #ddd", width: "100%" }}>
                <legend style={{ marginLeft: "-12px" }}>
                  <Typography variant="body1">Pagar com</Typography>
                </legend>
                <ToggleButtonGroup
                  value={formik.values.payment_method}
                  onChange={handlePayment}
                  size="small"
                  exclusive
                  aria-label="payment"
                >
                  <ToggleButton className={classes.styleBtn} value={FinancialEntryOrigin.BANK_SLIP} aria-label="boleto bancário">
                    <BarcodeIcon className="mr-2" />Boleto
                  </ToggleButton>
                  <ToggleButton className={classes.styleBtn} value={FinancialEntryOrigin.PIX} aria-label="pix">
                    <PixIcon className="mr-2" />Pix
                  </ToggleButton>
                </ToggleButtonGroup>
                <FormHelperText className="error">{formik.errors.payment_method}</FormHelperText>
              </fieldset>
            </Grid>

            {formik.values.payment_method === FinancialEntryOrigin.BANK_SLIP && (
              <React.Fragment>
                <Grid item xs={12}>
                  <Typography component="div" variant="body1">
                    <label htmlFor="corporate_name" className="dark bold">
                      Nome completo
                    </label>
                  </Typography>
                  <TextField
                    id="corporate_name"
                    className="mt-1"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={customer?.corporate_name}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography component="div" variant="body1">
                    <label htmlFor="registration_number" className="dark bold">
                      Documento CPF/CNPJ
                    </label>
                  </Typography>
                  <TextField
                    id="registration_number"
                    className="mt-1"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={customer?.registration_number}
                    InputProps={{
                      inputComponent: isCNPJ(customer?.registration_number)
                        ? CNPJInput
                        : CPF_CNPJInput,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography component="div" variant="body1">
                    <label htmlFor="phonenumber" className="dark bold">
                      Telefone
                    </label>
                  </Typography>
                  <TextField
                    id="phonenumber"
                    className="mt-1"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={customer?.phonenumber}
                    InputProps={{
                      inputComponent: PhoneInput,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <Typography component="div" variant="body1">
                    <label
                      htmlFor="fiscal_address_postal_code"
                      className="dark bold"
                    >
                      CEP
                    </label>
                  </Typography>
                  <TextField
                    id="fiscal_address_postal_code"
                    className="mt-1"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={customer?.fiscal_address_postal_code || ""}
                    InputProps={{
                      inputComponent: PostCodeInput,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography component="div" variant="body1">
                    <label
                      htmlFor="fiscal_address_street"
                      className="dark bold"
                    >
                      Endereço
                    </label>
                  </Typography>
                  <TextField
                    id="fiscal_address_street"
                    className="mt-1"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={customer?.fiscal_address_street || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography component="div" variant="body1">
                    <label
                      htmlFor="fiscal_address_number"
                      className="dark bold"
                    >
                      Nº
                    </label>
                  </Typography>
                  <TextField
                    id="fiscal_address_number"
                    className="mt-1"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={customer?.fiscal_address_number || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography component="div" variant="body1">
                    <label
                      htmlFor="fiscal_address_district"
                      className="dark bold"
                    >
                      Bairro
                    </label>
                  </Typography>
                  <TextField
                    id="fiscal_address_district"
                    className="mt-1"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={customer?.fiscal_address_district || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography component="div" variant="body1">
                    <label
                      htmlFor="fiscal_address_city_name"
                      className="dark bold"
                    >
                      Cidade
                    </label>
                  </Typography>
                  <TextField
                    id="fiscal_address_city_name"
                    className="mt-1"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={
                      customer?.fiscal_address_city
                        ? customer?.fiscal_address_city?.name || ""
                        : ""
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography component="div" variant="body1">
                    <label
                      htmlFor="fiscal_address_city_state_initials"
                      className="dark bold"
                    >
                      UF
                    </label>
                  </Typography>
                  <TextField
                    id="fiscal_address_city_state_initials"
                    className="mt-1"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={
                      customer?.fiscal_address_city
                        ? customer?.fiscal_address_city?.state_initials || ""
                        : ""
                    }
                    disabled
                  />
                </Grid>
              </React.Fragment>
            )}
            {formik.values.payment_method === FinancialEntryOrigin.PIX && (
              <Grid container justify="center">
                <Grid item>
                  <Alert severity="info" style={{ width: "330px" }}>
                    Clique em <strong>"Confirmar"</strong> para gerar o PIX.
                  </Alert>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default InsertCredit;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      primary: {
        color: "#0080FF",
      },
      negative: {
        color: "#f44336",
      },
      styleBtn: {
        padding: "7px 15px",
        fontSize: "0.9125rem",
        width: "180px",
        border: "2px solid #dfe6f2 !important",
        marginRight: "10px",
        borderRadius: "6px !important",
        color: "#454545",
        WebkitBoxPack: "start",
        WebkitJustifyContent: "start",
        msFlex: "start",
        justifyContent: "start"
        
      },
    })
);

