import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Card,
  CardContent,
} from "@material-ui/core";
import { useFormik } from "formik";
import cep from "cep-promise";
import * as Yup from "yup";
import { TextMaskCustom } from "../../components/TextMaskCustom";
import {
  postalCodeMask,
  registrationNumberMask,
  postalCodeMaskRegex,
  noSpecialCharactersAndDigitsRegex,
  noSpecialCharactersRegex,
  mobilePhoneMask,
  mobilePhoneMaskRegex,
} from "../../utils/masks";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { useTranslation } from 'react-i18next';
import { $enum } from "ts-enum-util";
import { UploadFile } from "../../components/UploadFile";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FileModalView } from "../../components/FileModalView";
import RegionService from "../../services/regionService";
import { BankAccountType, CourierDetails, DriversLicenseCategory, CreateUpdateCourier, VehicleLoadCompartmentType, VehicleType, PaymentMethod } from "../../services/types/courier";
import CourierService from "../../services/courierService";
import { useCallback } from "react";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { formatPhoneNumber } from "../../utils/formatters";
import { formatISO } from "date-fns";
import { CPF_CNPJInput } from "../../components/CPF_CNPJInput";

interface IEditCourierProps {
  match: any;
}

interface Bank {
  Code: string;
  Name: string;
  Document: string;
  CreatedAt: string;
  UpdatedAt: null;
  DeletedAt: null;
  IsDeleted: boolean;
}

interface ImageField {
  src: Blob | string | null,
  error: string,
  status: string
};

const INITIAL_IMAGES = {
  photo: { src: null, error: "", status: "" } as ImageField,
  drivers_license_back_image: { src: null, error: "", status: "" } as ImageField,
  drivers_license_front_image: { src: null, error: "", status: "" } as ImageField,
  vehicle_registration_back_image: { src: null, error: "", status: "" } as ImageField,
  vehicle_registration_front_image: { src: null, error: "", status: "" } as ImageField,
  address_proof_image: { src: null, error: "", status: "" } as ImageField,
};

interface UpdateCourierForm {
  region?: number;
  name?: string;
  email?: string;
  phonenumber?: string;
  registration_number?: string;
  state?: string;
  city?: string;
  postal_code?: string;
  district?: string;
  street?: string;
  address_number?: string;
  address_complement?: string;
  mother_name?: string;
  general_register?: string;
  birthdate?: string | null;
  drivers_license_category?: DriversLicenseCategory | null;
  drivers_license_number?: string;
  drivers_license_expiration?: string | null;
  vehicle_type?: VehicleType | null;
  vehicle_load_compartment_type?: string;
  vehicle_license_plate?: string;
  bank_code?: string;
  bank_name?: string;
  bank_account_type?: BankAccountType | null;
  bank_agency?: string;
  bank_account_code?: string;
  bank_account_code_dv?: string;
  payment_method?: string;
  legal_person_registration_number?: string;
}

const INITIAL_STATE: UpdateCourierForm = {};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Mínimo de 3 caracteres")
    .max(150, "Limite 150")
    .required("Campo obrigatório")
    .matches(
      noSpecialCharactersAndDigitsRegex,
      "Números e caracteres especiais não são permitidos"
    ),
  registration_number: Yup.string().nullable().required("Campo obrigatório"),
  general_register: Yup.string()
    .when("vehicle_type", {
      is: (value) => [VehicleType.BIKE].includes(value),
      then: Yup.string().nullable().required("Campo obrigatório"),
      otherwise: Yup.string().nullable().optional(),
    }),
  payment_method: Yup.string().required("Campo obrigatório"),  
  legal_person_registration_number: Yup.string()
    .when("payment_method", {
      is: (value) => [PaymentMethod.LEGAL_PERSON].includes(value),
      then: Yup.string().nullable().required("Campo obrigatório"),
      otherwise: Yup.string().nullable().optional(),
    }),  
  email: Yup.string()
    .email("E-mail inválido")
    .max(100, "Máximo de 100 caracteres excedido")
    .required("Campo obrigatório"),
  phonenumber: Yup.string()
    .max(15, "Telefone inválido")
    .min(15, "Telefone inválido")
    .matches(mobilePhoneMaskRegex, "Telefone inválido")
    .required("Campo obrigatório"),
  birthdate: Yup.string().required("Campo obrigatório"),
  region: Yup.string().nullable().required("Campo obrigatório"),
  postal_code: Yup.string()
    .min(9, "CEP inválido")
    .max(9, "CEP inválido")
    .matches(postalCodeMaskRegex, "CEP inválido")
    .required("Campo obrigatório"),
  drivers_license_number: Yup.string()
    .max(11, "CNH inválido")
    .min(11, "CNH inválido")
    .when("vehicle_type", {
      is: (value) => [VehicleType.BIKE].indexOf(value) !== -1,
      then: Yup.string().optional().nullable(),
      otherwise: Yup.string().nullable().required("Campo obrigatório"),
    }),
  drivers_license_expiration: Yup.string()
    .when("vehicle_type", {
      is: (value) => [VehicleType.BIKE].indexOf(value) !== -1,
      then: Yup.string().optional().nullable(),
      otherwise: Yup.string().nullable().required("Campo obrigatório"),
    }),
  drivers_license_category: Yup.string().when("vehicle_type", {
    is: (value) => [VehicleType.BIKE].indexOf(value) !== -1,
    then: Yup.string().optional().nullable(),
    otherwise: Yup.string().nullable().required("Campo obrigatório"),
  }),
  vehicle_type: Yup.string().required("Campo obrigatório"),
  vehicle_load_compartment_type: Yup.string().when("vehicle_type", {
    is: (value) => [VehicleType.BIKE, VehicleType.MOTORCYCLE].indexOf(value) !== -1,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string().optional().nullable(),
  }),
  vehicle_license_plate: Yup.string()
    .min(7, "Placa inválida")
    .max(9, "Placa inválida")
    .matches(
      noSpecialCharactersRegex,
      "Caracteres especiais não são permitidos"
    )
    .when("vehicle_type", {
      is: (value) => [VehicleType.BIKE].indexOf(value) !== -1,
      then: Yup.string().optional().nullable(),
      otherwise: Yup.string().nullable().required("Campo obrigatório"),
    }),
  street: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .required("Campo obrigatório"),
  city: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .min(2, "Mínimo de 2 caracteres")
    .matches(
      noSpecialCharactersAndDigitsRegex,
      "Caracteres especiais não são permitidos"
    )
    .required("Campo obrigatório"),
  district: Yup.string()
    .max(200, "Máximo de 200 caracteres excedido")
    .required("Campo obrigatório"),
  address_number: Yup.string()
    .max(10, "Máximo de 10 caracteres excedido")
    .required("Campo obrigatório"),
  address_complement: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .nullable(),
  state: Yup.string()
    .max(2, "Máximo de 2 caracteres excedido")
    .required("Campo obrigatório"),
  bank_code: Yup.string()
    .matches(/^\d{2,3}$/, "Informe um código válido.")
    .max(10, "Máximo de 10 caracteres excedido")
    .required("Campo obrigatório"),
  bank_account_type: Yup.string()
    .nullable()
    .required("Campo obrigatório"),
  bank_agency: Yup.string()
    .matches(/^\d+$/g, "Informe apenas números.")
    .max(5, "Máximo de 5 caracteres excedido")
    .min(1, "Mínimo de 1 caratere")
    .required("Campo obrigatório"),
  bank_account_code: Yup.string()
    .matches(/^\d+$/g, "Informe apenas números.")
    .max(10, "Máximo de 10 caracteres excedido")
    .min(1, "Mínimo de 1 caratere")
    .required("Campo obrigatório"),
  bank_account_code_dv: Yup.string()
    .matches(/^\d+$/g, "Informe apenas números.")
    .max(3, "Máximo de 3 caracteres excedido")
    .min(1, "Mínimo de 1 caratere")
    .required("Campo obrigatório"),
});

interface IImage {
  title: string;
  src: string;
}

export const EditCourier: React.FC<IEditCourierProps> = ({ match }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [courier, setCourier] = useState<CourierDetails>();
  const [banks, setBanks] = useState<any[]>([]);
  const [regionList, setRegionList] = useState<any[]>([]);

  const [images, setImages] = useState(INITIAL_IMAGES);
  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const [vehicleConditions, setVehicleConditions] = useState({
    needsCompartment: false,
    needsLicensePlate: false,
  });
  const [courierFormData, setCourierFormData] = useState<UpdateCourierForm>(INITIAL_STATE);

  const [isShowImagePreview, setShowImagePreview] = useState(false);
  const [imageToPreview, setImageToPreview] = useState<IImage>({ title: "", src: "" });

  const loadCourier = useCallback(async (courierId: number) => {
    try {
      const courier = await CourierService.getCourier(courierId);
      setCourier(courier);
      setCourierFormData({
        ...courier,
        region: courier.region?.id,
        phonenumber: formatPhoneNumber(courier.phonenumber),
        birthdate: courier.birthdate ? formatISO(courier.birthdate, { representation: "date" }) : "",
        drivers_license_expiration: courier.drivers_license_expiration ?
          formatISO(courier.drivers_license_expiration, { representation: "date" }) : "",
      });
    } catch (error) {
      NotificationManager.error(
        "Erro ao tentar carregar os dados do Entregador.",
        "Entregador"
      );
      history.goBack();
    }
  }, [history]);

  const handleCepChange = (cepRequested: string) => {
    cep(cepRequested)
      .then((cepResponse) => {
        formik.setFieldError("postal_code", "");
        formik.setFieldValue("street", cepResponse.street);
        formik.setFieldValue("district", cepResponse.neighborhood);
        formik.setFieldValue("city", cepResponse.city);
        formik.setFieldValue("state", cepResponse.state);
      })
      .catch((error) => {
        formik.setFieldError("postal_code", "Preencha o campo corretamente");
        formik.setFieldValue("street", "");
        formik.setFieldValue("district", "");
        formik.setFieldValue("city", "");
        formik.setFieldValue("state", "");
      });
  };

  const showImagePreview = (title: string, name: string, src: string | ArrayBuffer) => {
    if (src) {
      setImageToPreview({
        title: title,
        src: String(src)
      });
      setShowImagePreview(true);
    }
  }

  useEffect(() => {
    if (banks.length === 0) {
      CourierService.getBanks()
        .then((response) => {
          setBanks(response.data);
        })
        .catch((error) => { });
    }
  }, [banks]);

  useEffect(() => {
    if (courier) {
      // altera o estado dos campos de acordo com o veículo carregado
      switch (courier.vehicle_type) {
        case VehicleType.BIKE:
          setVehicleConditions((prevState) => {
            return {
              needsLicensePlate: false,
              needsCompartment: true,
            };
          });
          break;
        case VehicleType.MOTORCYCLE:
          setVehicleConditions((prevState) => {
            return {
              needsCompartment: true,
              needsLicensePlate: true,
            };
          });
          break;
        default:
          setVehicleConditions((prevState) => {
            return {
              needsCompartment: false,
              needsLicensePlate: true,
            };
          });
          break;
      }
      setImages({
        photo: { src: courier.photo, error: "", status: "loaded" },
        drivers_license_back_image: { src: courier.drivers_license_back_image, error: "", status: "loaded" },
        drivers_license_front_image: { src: courier.drivers_license_front_image, error: "", status: "loaded" },
        vehicle_registration_back_image: { src: courier.vehicle_registration_back_image, error: "", status: "loaded" },
        vehicle_registration_front_image: { src: courier.vehicle_registration_front_image, error: "", status: "loaded" },
        address_proof_image: { src: courier.address_proof_image, error: "", status: "loaded" },
      });
    }
  }, [courier]);

  useEffect(() => {
    if (courier?.bank_code && banks) {
      const bank = banks.find(item => item.code === courier.bank_code);
      setSelectedBank(bank !== undefined ? bank as Bank : null);
    }
  }, [courier, banks]);

  const formik = useFormik({
    initialValues: courierFormData,
    onSubmit: async (courierValues) => {
      let newCourier = {} as CreateUpdateCourier;

      if (validateImages()) {
        if (images.photo.src instanceof Blob) {
          newCourier.photo = images.photo.src;
        }
        if (images.drivers_license_front_image.src instanceof Blob) {
          newCourier.drivers_license_front_image =
            images.drivers_license_front_image.src;
        }
        if (images.drivers_license_back_image.src instanceof Blob) {
          newCourier.drivers_license_back_image =
            images.drivers_license_back_image.src;
        }
        if (images.vehicle_registration_front_image.src instanceof Blob) {
          newCourier.vehicle_registration_front_image =
            images.vehicle_registration_front_image.src;
        }
        if (images.vehicle_registration_back_image.src instanceof Blob) {
          newCourier.vehicle_registration_back_image =
            images.vehicle_registration_back_image.src;
        }
        if (images.address_proof_image.src instanceof Blob) {
          newCourier.address_proof_image = images.address_proof_image.src;
        }
      }

      if (courierFormData && !_.isEqual(courier, courierValues)) {
        //verifica quais campos foram alterados
        const courierKeys = Object.keys(courierFormData) as Array<keyof UpdateCourierForm>;
        courierKeys.forEach((key: keyof UpdateCourierForm) => {
          if (courierValues[key] !== courierFormData[key]) {
            (newCourier as any)[key] = courierValues[key];
          }
        });
      }

      if (!_.isEmpty(newCourier) && courier?.id) {
        try {
          await CourierService.updateCourier(courier.id, newCourier);
          NotificationManager.success("Entregador salvo com sucesso", "Editar Entregador");
          history.goBack();
        } catch (error) {
          const { status, data } = error.response;
          const errorMsgs: string[] = [];
          let errorMsg = "Ocorreu um erro ao salvar o Entregador";
          if (status === 400) {
            for (var key in data) {
              var value = data[key];
              if (key in courierValues) {
                formik.setFieldError(key, value);
              } else if (key in images) {
                (images as any)[key].error = value;
              } else {
                errorMsgs.push(value);
              }
            }

            if (errorMsgs.length > 0) {
              errorMsg = errorMsgs.join(". \n");
            }
          }
          NotificationManager.error(errorMsg, "Editar Entregador");
        }
      } else {
        history.goBack();
      }
    },
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  function validateImageData(image: string | Blob | null): boolean {
    let result = true;

    if (image === null) {
      result = false;
    } else if ((image instanceof Blob) && (image.size === 0)) {
      result = false;
    } else if (image === "") {
      result = false;
    }

    return result;
  }

  const validateImages = () => {
    let valid = true;
    if (!validateImageData(images.photo.src)) {
      setImages({
        ...images,
        photo: {
          src: images.photo.src,
          error: "Campo Obrigatório",
          status: "error",
        },
      });
      valid = false;
    }
    if (formik.values.vehicle_type !== VehicleType.BIKE) {
      if (!validateImageData(images.drivers_license_front_image.src)) {
        setImages({
          ...images,
          drivers_license_front_image: {
            src: images.drivers_license_front_image.src,
            error: "Campo Obrigatório",
            status: "error",
          },
        });
        valid = false;
      }
      if (!validateImageData(images.drivers_license_back_image.src)) {
        setImages({
          ...images,
          drivers_license_back_image: {
            src: images.drivers_license_back_image.src,
            error: "Campo Obrigatório",
            status: "error",
          },
        });
        valid = false;
      }
      if (!validateImageData(images.vehicle_registration_front_image.src)) {
        setImages({
          ...images,
          vehicle_registration_front_image: {
            src: images.vehicle_registration_front_image.src,
            error: "Campo Obrigatório",
            status: "error",
          },
        });
        valid = false;
      }
      if (!validateImageData(images.vehicle_registration_back_image.src)) {
        setImages({
          ...images,
          vehicle_registration_back_image: {
            src: images.vehicle_registration_back_image.src,
            error: "Campo Obrigatório",
            status: "error",
          },
        });
        valid = false;
      }
    }
    return valid;
  };

  const handleChangeImages = (
    imageField: keyof typeof INITIAL_IMAGES,
    imageValue: string | File | null
  ) => {
    const status = images[imageField].status === "" ? "loaded" : "changed";
    if (imageValue === null) {
      imageValue = new File([], "");
    }
    setImages(oldValues => {
      return {
        ...oldValues,
        [imageField]: { src: imageValue, error: "", status: status },
      }
    });
  };

  useEffect(() => {
    RegionService
      .loadAllRegions({ "ordering": "name" })
      .then((response) => {
        setRegionList(response);
      })
      .catch((error) => { });
  }, [setRegionList]);

  useEffect(() => {
    if (regionList.length && courier?.region) {
      const region = regionList.find((item: any) => item.id === courier.region?.id);
      setSelectedRegion(region !== undefined ? region : null);
    }
  }, [regionList, courier]);

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (match.params) loadCourier(match.params.courierId);
  }, [loadCourier, match.params]);

  return (
    <div>
      <Grid
        container
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Editar Entregador</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[
                { label: "Entregadores", url: "/courier" },
                {
                  label: "Detalhes do Entregador",
                  url: `/courier/detail/${match.params.courierId}`,
                },
                {
                  label: "Editar Entregador",
                  url: `/courier/edit/${match.params.courierId}`,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      {_.isEmpty(formik.values) ||
        (courier && !courier.name) ||
        (
          <form onSubmit={formik.handleSubmit}>
            <Card style={{marginBottom: "10px"}}>
              <CardContent>
                <Typography variant="h2">Dados pessoais</Typography>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <TextField
                      id="name"
                      label="Nome"
                      variant="outlined"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      helperText={formik.errors.name}
                      error={!!formik.errors.name}
                      InputLabelProps={{ required: true }}
                      autoFocus
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      id="mother_name"
                      label="Nome da Mãe"
                      variant="outlined"
                      value={formik.values.mother_name}
                      onChange={formik.handleChange}
                      error={!!formik.errors.mother_name}
                      helperText={formik.errors.mother_name}
                      InputLabelProps={{ required: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      id="email"
                      label="E-mail"
                      variant="outlined"
                      value={formik.values.email}
                      onChange={(event) => {
                        formik.setFieldValue(
                          event.target.id,
                          event.target.value.trim()
                        );
                      }}
                      helperText={formik.errors.email}
                      error={!!formik.errors.email}
                      InputLabelProps={{ required: true }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid style={{paddingTop:"8px"}} container spacing={2}>
                  <Grid item md>
                    <TextField
                      id="registration_number"
                      label="CPF"
                      variant="outlined"
                      value={formik.values.registration_number}
                      onChange={formik.handleChange}
                      fullWidth
                      error={!!formik.errors.registration_number}
                      helperText={formik.errors.registration_number}
                      InputLabelProps={{ required: true }}
                      InputProps={{
                        inputComponent: RegistrationNumberInput,
                      }}
                    />
                  </Grid>
                  <Grid item md>
                    <TextField
                      id="general_register"
                      label="RG"
                      variant="outlined"
                      value={formik.values.general_register}
                      onChange={formik.handleChange}
                      fullWidth
                      error={!!formik.errors.general_register}
                      helperText={formik.errors.general_register}
                      InputLabelProps={{ required: !vehicleConditions.needsLicensePlate }}
                    />
                  </Grid>
                  <Grid item md>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        error={!!formik.errors.payment_method}
                      >
                      <InputLabel id="payment_method-label">
                        Pessoa *    
                      </InputLabel>
                      <Select
                        labelId="payment_method-label"
                        label="Pessoa"
                        id="payment_method"
                        value={formik.values.payment_method}
                        name="payment_method"
                        onChange={formik.handleChange}
                        required={true}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                      >
                        <MenuItem value={PaymentMethod.PHYSICAL_PERSON}>{t("payment_method." + PaymentMethod.PHYSICAL_PERSON)}</MenuItem>
                        <MenuItem value={PaymentMethod.LEGAL_PERSON}>{t("payment_method." + PaymentMethod.LEGAL_PERSON)}</MenuItem>

                      </Select>
                      <FormHelperText id="payment_method-helper">
                        {formik.errors.payment_method}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md>
                    <TextField
                        id="legal_person_registration_number"
                        label={"CNPJ"}
                        variant="outlined"
                        value={formik.values.legal_person_registration_number}
                        onChange={formik.handleChange}
                        fullWidth
                        required={formik.values.payment_method === PaymentMethod.LEGAL_PERSON ? true : false}
                        error={!!formik.errors.legal_person_registration_number}
                        helperText={formik.errors.legal_person_registration_number}
                        InputProps={{
                            inputComponent: CPF_CNPJInput,
                        }}
                    />
                  </Grid> 
                </Grid>
                <Grid style={{paddingTop:"8px"}} container spacing={2}>
                  <Grid item md>
                    <TextField
                      id="birthdate"
                      label="Data de Nascimento"
                      variant="outlined"
                      type="date"
                      value={formik.values.birthdate}
                      onChange={formik.handleChange}
                      helperText={formik.errors.birthdate}
                      error={!!formik.errors.birthdate}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item md>
                    <TextField
                      id="phonenumber"
                      label="Celular"
                      variant="outlined"
                      value={formik.values.phonenumber}
                      onChange={formik.handleChange}
                      fullWidth
                      error={!!formik.errors.phonenumber}
                      helperText={formik.errors.phonenumber}
                      InputLabelProps={{ required: true }}
                      InputProps={{
                        inputComponent: PhoneInput,
                      }}
                    />
                  </Grid>
                  <Grid item md>
                    <Autocomplete
                      id="region"
                      size="small"
                      value={selectedRegion}
                      options={regionList}
                      getOptionLabel={(option) => `${option.name} - ${option.state}`}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedRegion(newValue);
                          formik.setFieldValue("region", newValue.id);
                        } else {
                          setSelectedRegion(null);
                          formik.setFieldValue("region", null);
                        }
                      }}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          name="region"
                          label="Região"
                          variant="outlined"
                          value={formik.values.region}
                          onChange={formik.handleChange}
                          fullWidth
                          error={!!formik.errors.region}
                          helperText={formik.errors.region}
                          InputLabelProps={{ required: true }}
                        />
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      Os campos com (*) são de preenchimento obrigatório.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card style={{marginBottom: "10px"}}>
              <CardContent>
                <Typography variant="h2">Dados bancários</Typography>
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <Autocomplete
                      id="bank_code"
                      size="small"
                      value={selectedBank}
                      options={banks}
                      getOptionLabel={(option) => `${option.code} - ${option.name}`}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedBank(newValue as Bank);
                          formik.setFieldValue("bank_code", newValue.code);
                        } else {
                          setSelectedBank(null);
                          formik.setFieldValue("bank_code", null);
                        }
                      }}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          name="bank_code"
                          value={formik.values.bank_code}

                          helperText={formik.errors.bank_code}
                          error={!!formik.errors.bank_code}
                          onChange={formik.handleChange}
                          label="Banco"
                          variant="outlined"
                          InputLabelProps={{ required: true }}
                          fullWidth />
                      }
                    />
                  </Grid>

                  <Grid item md={4}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={!!formik.errors.bank_account_type}
                    >
                      <InputLabel id="bank_account_type-label" required={true}>
                        Tipo de Conta
                      </InputLabel>
                      <Select
                        labelId="bank_account_type-label"
                        label="Tipo de Conta *"
                        id="bank_account_type"
                        value={formik.values.bank_account_type}
                        name="bank_account_type"
                        onChange={formik.handleChange}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                      >
                        {$enum(BankAccountType).map((bank: string, index) => {
                          return <MenuItem key={index} value={bank}>{t("bank_account_type." + bank)}</MenuItem>
                        })}
                      </Select>
                      <FormHelperText id="bank_account_type-helper">
                        {formik.errors.bank_account_type}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <TextField
                      id="bank_agency"
                      label="Agência"
                      variant="outlined"
                      value={formik.values.bank_agency}
                      onChange={formik.handleChange}
                      helperText={formik.errors.bank_agency}
                      error={!!formik.errors.bank_agency}
                      InputLabelProps={{ required: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      id="bank_account_code"
                      label="Número da Conta"
                      variant="outlined"
                      value={formik.values.bank_account_code}
                      onChange={formik.handleChange}
                      error={!!formik.errors.bank_account_code}
                      helperText={formik.errors.bank_account_code}
                      InputLabelProps={{ required: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      id="bank_account_code_dv"
                      label="Dígito Verificador da Conta"
                      variant="outlined"
                      value={formik.values.bank_account_code_dv}
                      onChange={formik.handleChange}
                      error={!!formik.errors.bank_account_code_dv}
                      helperText={formik.errors.bank_account_code_dv}
                      InputLabelProps={{ required: true }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      Os campos com (*) são de preenchimento obrigatório.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card style={{marginBottom: "10px"}}>
              <CardContent>
                <Typography variant="h2">Dados do Veículo</Typography>
                <Grid container spacing={2}>
                  <Grid item md={4} xl={2}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={!!formik.errors.vehicle_type}
                    >
                      <InputLabel id="vehicle_type-label" required={true}>
                        Tipo de veículo
                      </InputLabel>
                      <Select
                        labelId="vehicle_type-label"
                        label="Tipo de veículo *"
                        id="vehicle_type"
                        name="vehicle_type"
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                        value={formik.values.vehicle_type}
                        onChange={(e) => {
                          formik.handleChange(e);
                          switch (e.target.value) {
                            case VehicleType.BIKE:
                              formik.setFieldValue("vehicle_license_plate", "");
                              setVehicleConditions((prevState) => {
                                return {
                                  needsCompartment: true,
                                  needsLicensePlate: false,
                                };
                              });
                              break;
                            case VehicleType.MOTORCYCLE:
                              setVehicleConditions((prevState) => {
                                return {
                                  needsCompartment: true,
                                  needsLicensePlate: true,
                                };
                              });
                              break;
                            default:
                              formik.setFieldValue(
                                "vehicle_load_compartment_type",
                                ""
                              );
                              setVehicleConditions((prevState) => {
                                return {
                                  needsCompartment: false,
                                  needsLicensePlate: true,
                                };
                              });
                              break;
                          }
                        }}
                        error={!!formik.errors.vehicle_type}
                      >
                        {$enum(VehicleType).map((type, index) => {
                          return <MenuItem key={index} value={type}>{t("vehicle_type." + type)}</MenuItem>
                        })}
                      </Select>
                      <FormHelperText id="vehicle_type-helper">
                        {formik.errors.vehicle_type}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xl={2}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={!!formik.errors.vehicle_load_compartment_type}
                    >
                      <InputLabel id="drivers_license_category-label" required={vehicleConditions.needsCompartment}>
                        Tipo de compartimento de carga
                      </InputLabel>
                      <Select
                        labelId="vehicle_load_compartment_type-label"
                        label="Tipo de compartimento de carga"
                        id="vehicle_load_compartment_type"
                        name="vehicle_load_compartment_type"
                        disabled={!vehicleConditions.needsCompartment}
                        value={formik.values.vehicle_load_compartment_type}
                        onChange={formik.handleChange}
                        error={!!formik.errors.vehicle_type}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                      >
                        {$enum(VehicleLoadCompartmentType).map((type, index) => {
                          return <MenuItem key={index} value={type}>{t("vehicle_load_compartment_type." + type)}</MenuItem>
                        })}
                      </Select>
                      <FormHelperText id="vehicle_load_compartment_type-helper">
                        {formik.errors.vehicle_load_compartment_type}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xl={2}>
                    <TextField
                      id="vehicle_license_plate"
                      label="Placa"
                      variant="outlined"
                      disabled={!vehicleConditions.needsLicensePlate}
                      value={formik.values.vehicle_license_plate}
                      onChange={formik.handleChange}
                      helperText={formik.errors.vehicle_license_plate}
                      error={!!formik.errors.vehicle_license_plate}
                      InputLabelProps={{ required: vehicleConditions.needsLicensePlate }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item md={4} xl={2}>
                    <TextField
                      id="drivers_license_number"
                      label="CNH"
                      variant="outlined"
                      value={formik.values.drivers_license_number}
                      onChange={formik.handleChange}
                      helperText={formik.errors.drivers_license_number}
                      error={!!formik.errors.drivers_license_number}
                      InputLabelProps={{ required: vehicleConditions.needsLicensePlate }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} xl={2}>
                    <TextField
                      id="drivers_license_expiration"
                      label="Validade da CNH"
                      variant="outlined"
                      type="date"
                      value={formik.values.drivers_license_expiration}
                      onChange={formik.handleChange}
                      helperText={formik.errors.drivers_license_expiration}
                      error={!!formik.errors.drivers_license_expiration}
                      InputLabelProps={{ shrink: true, required: vehicleConditions.needsLicensePlate }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} xl={2}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={!!formik.errors.drivers_license_category}
                    >
                      <InputLabel id="drivers_license_category-label" required={vehicleConditions.needsLicensePlate}>
                        Categoria da CNH
                      </InputLabel>
                      <Select
                        labelId="drivers_license_category-label"
                        id="drivers_license_category"
                        value={formik.values.drivers_license_category}
                        name="drivers_license_category"
                        onChange={formik.handleChange}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                      >
                        {$enum(DriversLicenseCategory).map((category, index) => {
                          return <MenuItem key={index} value={category}>{category}</MenuItem>
                        })}
                      </Select>
                      <FormHelperText id="drivers_license_category-helper">
                        {formik.errors.drivers_license_category}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      Os campos com (*) são de preenchimento obrigatório.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card style={{marginBottom: "10px"}}>
              <CardContent>
                <Typography variant="h2">Endereço</Typography>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <TextField
                      id="postal_code"
                      label="CEP"
                      variant="outlined"
                      value={formik.values.postal_code}
                      onChange={formik.handleChange}
                      onBlur={(e) => handleCepChange(e.target.value)}
                      fullWidth
                      error={!!formik.errors.postal_code}
                      helperText={formik.errors.postal_code}
                      InputLabelProps={{ required: true }}
                      InputProps={{
                        inputComponent: PostCodeInput,
                      }}
                    />
                  </Grid>
                  <Grid item md={7}>
                    <TextField
                      id="street"
                      label="Rua"
                      variant="outlined"
                      value={formik.values.street}
                      onChange={formik.handleChange}
                      error={!!formik.errors.street}
                      helperText={formik.errors.street}
                      InputLabelProps={{ required: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      id="address_number"
                      label="Número"
                      variant="outlined"
                      value={formik.values.address_number}
                      onChange={formik.handleChange}
                      helperText={formik.errors.address_number}
                      error={!!formik.errors.address_number}
                      InputLabelProps={{ required: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      id="address_complement"
                      label="Complemento"
                      variant="outlined"
                      value={formik.values.address_complement}
                      error={!!formik.errors.address_complement}
                      helperText={formik.errors.address_complement}
                      onChange={formik.handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      id="district"
                      label="Bairro"
                      variant="outlined"
                      value={formik.values.district}
                      onChange={formik.handleChange}
                      helperText={formik.errors.district}
                      error={!!formik.errors.district}
                      InputLabelProps={{ required: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      id="city"
                      label="Cidade"
                      variant="outlined"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      helperText={formik.errors.city}
                      error={!!formik.errors.city}
                      InputLabelProps={{ required: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      id="state"
                      label="Estado"
                      variant="outlined"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      helperText={formik.errors.state}
                      error={!!formik.errors.state}
                      InputLabelProps={{ required: true }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      Os campos com (*) são de preenchimento obrigatório.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card style={{marginBottom: "10px"}}>
              <CardContent>
                <Typography variant="h2">Documentos anexados</Typography>
                <Grid container spacing={2}>
                  <Grid item md={2}>
                    <UploadFile
                      error={!!images.photo.error}
                      name="photo"
                      initialFile={courier?.photo}
                      onChange={(image) => handleChangeImages("photo", image)}
                      onTitleClick={showImagePreview}
                      title="Foto" />
                  </Grid>
                  <Grid item md={2}>
                    <UploadFile
                      error={!!images.drivers_license_front_image.error}
                      name="drivers_license_front_image"
                      initialFile={courier?.drivers_license_front_image}
                      onChange={(image) => handleChangeImages("drivers_license_front_image", image)}
                      onTitleClick={showImagePreview}
                      title="CNH/RG Frente" />
                  </Grid>
                  <Grid item md={2}>
                    <UploadFile
                      error={!!images.drivers_license_back_image.error}
                      name="drivers_license_back_image"
                      initialFile={courier?.drivers_license_back_image}
                      onChange={(image) => handleChangeImages("drivers_license_back_image", image)}
                      onTitleClick={showImagePreview}
                      title="CNH/RG Verso" />
                  </Grid>
                  <Grid item md={2}>
                    <UploadFile
                      error={!!images.vehicle_registration_front_image.error}
                      name="vehicle_registration_front_image"
                      initialFile={courier?.vehicle_registration_front_image}
                      onChange={(image) => handleChangeImages("vehicle_registration_front_image", image)}
                      onTitleClick={showImagePreview}
                      title="CRLV Frente" />
                  </Grid>
                  <Grid item md={2}>
                    <UploadFile
                      error={!!images.vehicle_registration_back_image.error}
                      name="vehicle_registration_back_image"
                      initialFile={courier?.vehicle_registration_back_image}
                      onChange={(image) => handleChangeImages("vehicle_registration_back_image", image)}
                      onTitleClick={showImagePreview}
                      title="CRLV Verso" />
                  </Grid>
                  <Grid item md={2}>
                    <UploadFile
                      error={!!images.address_proof_image.error}
                      name="address_proof_image"
                      initialFile={courier?.address_proof_image}
                      onChange={(image) => handleChangeImages("address_proof_image", image)}
                      onTitleClick={showImagePreview}
                      title="Comprovante de Residência" />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      Os campos com (*) são de preenchimento obrigatório.
                    </Typography>
                  </Grid>
                </Grid>
                <FileModalView
                  isOpen={isShowImagePreview}
                  closeModal={() => setShowImagePreview(false)}
                  title={imageToPreview.title}
                  src={imageToPreview.src}
                />
              </CardContent>
            </Card>

            <Grid container spacing={2}>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  type="button"
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button color="primary" variant="contained" type="submit" disabled={formik.isSubmitting}>
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </form>
        )}

    </div>
  );
};

const RegistrationNumberInput = (props: any) => (
  <TextMaskCustom {...props} mask={registrationNumberMask} />
);

const PostCodeInput = (props: any) => (
  <TextMaskCustom {...props} mask={postalCodeMask} />
);

const PhoneInput = (props: any) => (
  <TextMaskCustom {...props} guide={false} mask={mobilePhoneMask} />
);
