import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@material-ui/core";
import {  useHistory } from "react-router-dom";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import {
    DATE_TIME_COLUMN_DEFAULTS,
    DEFAULT_TABLE_COMPONENTS,
    DEFAULT_TABLE_OPTIONS,
    PageableParams,
    TABLE_L10N_PTBR,
} from "../../../components/TableTrinkets";
import MaterialTable, {
    MaterialTableProps,
    Query,
    QueryResult,
} from "@material-table/core";
import { VisibilityOutlined } from "@material-ui/icons";

import AnnouncementsService from "../../../services/announcementsService";
import { AnnouncementResponse } from "../../../services/types/announcement";
import ShowIf from "../../../components/ShowIf";
import AuthService from "../../../services/authService";
import { convertDeliveryForTypeToOption } from "../../announcement/announcementUtils";
import NewAnnouncementDialog, { NewAnnouncementDialogHandle } from "../../announcement/NewAnnouncementDialog";
import { DeliveryRequest } from "../../../services/types/deliveryRequest";

interface ICourierAnnouncementsProps { 
    courierId: number;
    tripId: number | null;
    deliverySelected: DeliveryRequest | null;
}

export const CourierAnnouncement: React.FC<ICourierAnnouncementsProps> = ({ courierId, tripId, deliverySelected }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const tableRef = React.useRef<MaterialTableProps<AnnouncementResponse>>();
    const newAnnouncementDialogHandle = React.createRef<NewAnnouncementDialogHandle>();

    const loadData = (query: Query<AnnouncementResponse>) =>
        new Promise<QueryResult<AnnouncementResponse>>((resolve, reject) => {
            AnnouncementsService.getAnnouncementsCourier(
                courierId,
                PageableParams.fromQuery(query)
            ).then(response => {
                resolve({
                    data: response.data,
                    page: query.page,
                    totalCount: response.count,
                });
            });
    });

    const showDetails = async (item: AnnouncementResponse) => {
        history.push(`/announcement/detail/${item.id}`);
    };

    const openDialog = () => {
        newAnnouncementDialogHandle.current?.open();
    };

    const refresh = () => {
        if (tableRef.current?.onQueryChange) {
          tableRef.current.onQueryChange({
            page: 0,
          } as Query<AnnouncementResponse>);
        }
      }

    const finishAdd = async (): Promise<void> => {
        try {
          refresh(); 
        } finally {
          return Promise.resolve();
        }
    };

    return (
        <div>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <ShowIf condition={AuthService.hasAuthorization("add_announcement")}>
                        <Button color="primary" variant="contained"
                            onClick={openDialog}
                            startIcon={<AddCircleOutlineRoundedIcon />}>
                            Nova Notificação
                        </Button>
                    </ShowIf>
                </Grid>
            </Grid>

            <NewAnnouncementDialog ref={newAnnouncementDialogHandle}      
                couriers={[courierId]}
                tripId={tripId}
                deliverySelected={deliverySelected}
                onAccept={finishAdd}
                onCancel={finishAdd} />

            <MaterialTable
                tableRef={tableRef}
                columns={[
                    { field: "id", hidden: true, width: 60 },
                    {
                        ...DATE_TIME_COLUMN_DEFAULTS,
                        field: "send_date",
                        title: "Data de Envio",
                    },
                    { field: "title", title: "Título" },
                    {
                        field: "delivery_for",
                        title: "Envio",
                        render: (rowData, type) =>
                            t(
                                "announcement_delivery_for_options."
                                + convertDeliveryForTypeToOption(rowData.delivery_for, rowData.only_active_couriers)
                            )
                    },
                    {
                        field: "screen",
                        title: "Local",
                        render: (rowData, type) =>
                            t("announcement_screen_type." + rowData.screen)
                    },
                ]}
                data={loadData}
                actions={[
                    {
                        icon: () => <VisibilityOutlined />,
                        tooltip: "Ver Detalhes",
                        onClick: (event, rowData) =>
                            showDetails(rowData as AnnouncementResponse)
                    },
                ]}
                components={DEFAULT_TABLE_COMPONENTS}
                localization={TABLE_L10N_PTBR}
                options={{
                    ...DEFAULT_TABLE_OPTIONS,
                    draggable: false               
                }}
            />
        </div>
    );
};
