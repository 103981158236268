import React, { useState } from "react";
import { useFormik } from "formik";
import {
  Grid,
  Button,
  CardContent,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
  Link,
  Card,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ReactComponent as LogoNameIcon } from "../../assets/img/logo-azul.svg";
import { Actions as authActions } from "../../store/ducks/auth";
import { useDispatch } from "react-redux";
import { useAppContext } from "../../utils/appContext";

interface ILoginProps {}

export const Login: React.FC<ILoginProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userHasAuthenticated } = useAppContext();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    onSubmit: async (credencials) => {
      const authResult = await authActions.getToken(dispatch, credencials);
      if (authResult) userHasAuthenticated(true);
    },
  });
  return (
    <div className="container-unauthenticated">
      <Card className="card-unauthenticated">
      <form onSubmit={formik.handleSubmit}>
        <CardContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <LogoNameIcon width={150} fill="#fff" />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                id="email"
                label="Usuário"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>                
              <TextField
                fullWidth
                id="password"
                label="Senha"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                value={formik.values.password}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography color="primary">
                <Link href="/request-password"> Esqueci minha senha</Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <CircularProgress size="1.5rem" color="inherit" />
                ) : (
                  <span>Login</span>
                )}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
      </Card>
      <div className={classes.versionWrapper}>
        <Typography className={classes.versionText}>Versão {process.env.REACT_APP_VERSION}</Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    versionWrapper: {
      position: "absolute",
      bottom: 0,
      marginBottom: "1rem",
    },
    versionText: {
      color: theme.palette.primary.contrastText,
    }
  })
);