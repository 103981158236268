import React, { useEffect } from "react";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import bancos_brasil from "bancos-brasileiros";
import numbro from "numbro";
import RemittanceService from "../../services/remittanceService";
import { CURRENT_DATE_FORMAT } from "../../i18n";

interface IDetailTransactionProps {
  open: boolean;
  onCloseAction: () => void;
  transactionId: any;
}

export const DetailTransactionDialog: React.FC<IDetailTransactionProps> = ({ open, onCloseAction, transactionId }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [_transaction, setTransaction] = React.useState<any>({});

  useEffect(() => {
    if (transactionId) {
      RemittanceService.getTransaction(Number(transactionId))
        .then((response) => {
          setTransaction(response.data);
        })
        .catch((error) => { });
    }
  }, [transactionId]);

  const handleClose = () => {
    setTransaction({});
    onCloseAction();
  };

  const formatDate = (date: string, mask: string): string => {
    if (date) {
      return format(parseISO(date), mask);
    } else {
      return "---";
    }
  }

  const formatCurrency = (value: any): string => {
    if (value) {
      return numbro(Number(value)).formatCurrency();
    } else {
      return "";
    }
  }

  const getFormatedBank = (code: string): string => {
    let result = "";
    let bank = null;
    if (code) {
      bank = bancos_brasil.find(item => item.Code === code);
    }
    if (bank) {
      result = `${bank.Code} - ${bank.Name}`;
    }
    return result;
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle className={classes.dialogTitle}>
        Transação {_transaction.invoice && (`${_transaction.invoice} - ${t("transaction_status." + _transaction.status)}`)}
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography>Data</Typography>
            <Typography variant="subtitle1">{formatDate(_transaction.created_at, CURRENT_DATE_FORMAT)}</Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography>Entregador</Typography>
            <Typography variant="subtitle1">{_transaction.courier_name}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>CPF</Typography>
            <Typography variant="subtitle1">{_transaction.courier_registration_number}</Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography>Banco</Typography>
            <Typography variant="subtitle1">{getFormatedBank(_transaction.courier_bank_code)}</Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <Typography>Agência</Typography>
            <Typography variant="subtitle1">{_transaction.courier_agency}</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography>Conta</Typography>
            <Typography variant="subtitle1">{`${_transaction.courier_bank_account}-${_transaction.courier_bank_account_dv}`}</Typography>
          </Grid>

          <Grid item xs={12} md={2}>
            <Typography>Valor</Typography>
            <Typography variant="subtitle1">{formatCurrency(_transaction.amount)}</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography>Taxa</Typography>
            <Typography variant="subtitle1">{formatCurrency(_transaction.tax)}</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography>Total</Typography>
            <Typography variant="subtitle1">{formatCurrency(Number(_transaction.amount) + Number(_transaction.tax))}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      {_transaction && (
        <DialogContent dividers className={classes.entriesContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} colSpan={3}>Lançamentos</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_transaction?.entries && _transaction.entries.map((entry: any, index: number) => (
                <StyledTableRow key={index}>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="subtitle1">Entrega: #{entry.request_number}</Typography>
                    <Typography>{entry.store_name}</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography>{format(parseISO(entry.created_at), CURRENT_DATE_FORMAT)}</Typography>
                  </TableCell>
                  <TableCell className={classes.numericTableCell}>
                    <Typography variant="subtitle1" className={classes.entryValue}>{numbro(Number(entry.value)).formatCurrency()}</Typography>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      )}
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    entryValue: {
      color: "green",
    },
    entriesContainer: {
      padding: "0 24px",
    },
    dialogTitle: {
      padding: "16px 24px 0",
    },
    tableCell: {
      backgroundColor: "white",
      borderBottom: "0",
    },
    numericTableCell: {
      backgroundColor: "white",
      borderBottom: "0",
      textAlign: "right",
    },
  })
);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: "white",
      },
    },
  }),
)(TableRow);

export default DetailTransactionDialog;
