import React, { useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { Typography, Grid, Table, TableBody, TableCell, TableRow, Button, Card, CardContent } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import GeoJsonMap from "../../components/maps/GeoJsonMap";
import { RegionResponse } from "../../services/types/region";
import RegionService from "../../services/regionService";
import { formatPhoneNumber } from "../../utils/formatters";
import ShowIf from "../../components/ShowIf";
import AuthService from "../../services/authService";

interface IParams {
  regionId: string;
}

export const DetailsRegion: React.FC = () => {
  const { regionId } = useParams<IParams>();
  const [region, setRegion] = React.useState<RegionResponse>();
  const [regionCoordinates, setRegionCoordinates] = React.useState<Object>();

  useEffect(() => {
    if (regionId) {
      const regionPromise = RegionService.getRegion(Number(regionId));
      const geoJSONPromise = RegionService.getRegionArea(Number(regionId));
      Promise.all([regionPromise, geoJSONPromise]).then(values => {
        setRegion(values[0]);
        setRegionCoordinates(values[1]);
      })
    }
  }, [regionId])

  return (
    <React.Fragment>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Detalhes da Região</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[
                { label: "Regiões", url: "/region" },
                { label: "Detalhes da Região", url: `/region/detail/${regionId}` },
              ]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <ShowIf condition={AuthService.hasAuthorization("change_region")}>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<EditOutlinedIcon />}
              component={Link} to={`/region/edit/${regionId}`}
            >
              Editar
            </Button>
          </ShowIf>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item container xs={12} lg={6} spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h2">Dados da Região</Typography>
                <Table className="table-bordered" >
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography>Nome</Typography>
                        <Typography variant="subtitle1">{region?.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Área Metropolitana (Cidade)</Typography>
                        <Typography variant="subtitle1">{region?.metropolitan_area} - {region?.state}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Telefone do Suporte</Typography>
                        <Typography variant="subtitle1">{region?.support_phone ? formatPhoneNumber(region?.support_phone) : "---"}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h2">Configurações para retirar das Filas</Typography>
                <Table className="table-bordered" >
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography>Qtd. de Solicitações Ignoradas</Typography>
                        <Typography variant="subtitle1">{region?.max_ignore_count}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Tempo para contar as Solicitações Ignoradas (em minutos)</Typography>
                        <Typography variant="subtitle1">{region?.ignore_timeout_minutes}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className="map-region">
            {!!regionCoordinates && <GeoJsonMap
              setGeoJson={() => { }}
              view
              defaultCoords={regionCoordinates} />}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};