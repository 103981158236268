import { parseISO } from "date-fns";
import { apiAxios as axios } from "../store/api/config";
import { getURL, urls } from "../store/api/urls";
import { convertToBoolean, convertToDate, convertToNumber } from "../utils/utils";
import { AnnouncementResponse } from "./types/announcement";
import { IPageableParams, PagedResponse } from "./types/common";
import { CourierPosition, DeliveryRequestAction, DeliveryRequestLog, PendingTrip, Trip, TripDeliveryRequest, TripStatus, TripTrackingPoint } from "./types/deliveryRequest";


export interface ITripService {

  getTripById: (id: number) => Promise<Trip>;

  getTrips: (region: number[] | null,
    store: number[] | null,
    status: TripStatus | null,
    ordering: IPageableParams) => Promise<PagedResponse<PendingTrip>>;

  interveneInTrip: (
    tripId: number,
    action: DeliveryRequestAction,
  ) => Promise<any>;

  cancelTripById: (tripId: number) => Promise<any>;

  resetTripById: (tripId: number) => Promise<any>;

  resendTrip: (id: number, courierId: number | null) => Promise<any>;

  getTripTracking: (tripId: number, courierId: number) => Promise<TripTrackingPoint[]>;

  getAvailableTripResquestActions: (
    status: TripStatus,
    has_return: boolean,
  ) => DeliveryRequestAction[];

  searchCourierForTrip: (tripId: number, courierId?: number | null) => Promise<any>;

  releaseTripById: (tripId: number) => Promise<any>;

  finishTripById: (tripId: number) => Promise<any>;

  getTripRequestLogs: (tripId: number) => Promise<any>;

  getTripRequestSelectedCouriers: (tripId: number) => Promise<any>;

  createNewTrip: (storeId: number, deliveryIds: number[]) => Promise<any>;

  addDeliveryToTrip: (trip: PendingTrip | Trip, deliveryId: number) => Promise<any>;

  removeDeliveryFromTrip: (tripId: number, deliveryId: number) => Promise<any>;

  discardTrip: (id: number) => Promise<any>;

  getAnnouncementByTrip: (id: number) => Promise<any>;

}


const TripService: ITripService = {

  getTripById: (id: number) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${urls.TRIP}${id}/`)
        .then((response) => {
          let called_ifood = false;
          const deliveries: TripDeliveryRequest[] = response.data.deliveries.map((item: any) => {
            const deliveryRequest = {
              ...item,
              created_at: convertToDate(item.created_at),
              estimated_delivery_time: convertToDate(item.estimated_delivery_time),
              estimated_delivery_time_after_release: convertToDate(item.estimated_delivery_time_after_release),
              called_ifood: convertToBoolean(item.called_ifood)
            };

            if (deliveryRequest.called_ifood) {
              called_ifood = true;
            }

            return deliveryRequest;
          });
          let courier = null;
          if (response.data.courier) {
            courier = {
              ...response.data.courier,
              id: convertToNumber(response.data.courier.id),
              new_ranking_points: convertToNumber(response.data.courier.new_ranking_points),
              requests_count: convertToNumber(response.data.courier.requests_count)
            };
          }
          const storeTrip = {
            ...response.data.store,
            id: convertToNumber(response.data.store.id),
            nome: response.data.store.nome,
            latitude: convertToNumber(response.data.store.latitude),
            longitude: convertToNumber(response.data.store.longitude)
          };
          let courierPosition: CourierPosition | null = null;
          if (response.data.courier_position) {
              courierPosition = {
                  ...response.data.courier_position,
                  courier_id: convertToNumber(response.data.courier_position.courier_id),
                  latitude: convertToNumber(response.data.courier_position.latitude),
                  longitude: convertToNumber(response.data.courier_position.longitude),
                  delivery_request_id: convertToNumber(response.data.courier_position.delivery_request_id),
                  trip_id: convertToNumber(response.data.courier_position.trip_id),
                  updated_at: convertToDate(response.data.courier_position.updated_at),
              }
          }
          const result = {
            ...response.data,
            id: convertToNumber(response.data.id),
            deliveries: deliveries,
            courier: courier,
            has_return: convertToBoolean(response.data.has_return),
            created_at: convertToDate(response.data.created_at),
            updated_at: convertToDate(response.data.updated_at),
            start_date: convertToDate(response.data.start_date),
            end_date: convertToDate(response.data.end_date),
            store: storeTrip,
            called_ifood: called_ifood,
            courier_incentive_value: response.data.courier_incentive_value ? convertToNumber(response.data.courier_incentive_value) : null,
            customer_incentive_value: response.data.customer_incentive_value ? convertToNumber(response.data.customer_incentive_value) : null,
            total_value: convertToNumber(response.data.total_value),
            courier_value: convertToNumber(response.data.courier_value),
            canceled: convertToBoolean(response.data.canceled),
            courier_position: courierPosition,
          }
          resolve(result)
        })
        .catch((error) => reject(error));
    });
  },

  getTrips: (regionsId: number[] | null,
    storesId: number[] | null,
    statusId: TripStatus | null,
    pageParams: IPageableParams) => {
    const url = `${urls.TRIP}pending_trips/?page=${pageParams.page}&page_size=${pageParams.page_size}`;
    return axios.post(url, { regions: regionsId, stores: storesId, status: statusId, ordering: pageParams.ordering, })
      .then((response) => {
        const data: PendingTrip[] = response.data.results.map((item: any) => {
          let called_ifood = false;
          const deliveries: TripDeliveryRequest[] = item.deliveries.map((item: any) => {
            const destinationAddress = {
              ...item.destination_address,
              latitude: convertToNumber(item.destination_address.latitude),
              longitude: convertToNumber(item.destination_address.longitude)
            };
            const tripItem: TripDeliveryRequest = {
              ...item,
              created_at: convertToDate(item.created_at),
              requested_time: convertToDate(item.requested_time),
              is_scheduled: convertToBoolean(item.is_scheduled),
              estimated_delivery_time: convertToDate(item.estimated_delivery_time),
              estimated_delivery_time_after_release: convertToDate(item.estimated_delivery_time_after_release),
              destination_address: destinationAddress,
              distance: convertToNumber(item.distance),
              called_ifood: convertToBoolean(item.called_ifood)
            };

            if (tripItem.called_ifood) {
              called_ifood = true;
            }

            return tripItem;
          });

          const storeTrip = {
            ...item.store,
            id: convertToNumber(item.store.id),
            nome: item.store.nome,
            latitude: convertToNumber(item.store.latitude),
            longitude: convertToNumber(item.store.longitude)
          };

          return {
            ...item,
            id: convertToNumber(item.id),
            deliveries: deliveries,
            courier_id: convertToNumber(item.courier_id),
            created_at: convertToDate(item.created_at),
            updated_at: convertToDate(item.updated_at),
            start_date: convertToDate(item.start_date),
            end_date: convertToDate(item.end_date),
            store: storeTrip,
            called_ifood: called_ifood,
          };
        });
        const result: PagedResponse<PendingTrip> = {
          data: data,
          count: response.data.count,
        };
        return Promise.resolve(result);
      })
      .catch((error) => Promise.reject(error));
  },

  interveneInTrip: (tripId: number, action: DeliveryRequestAction) => {
    const url = `${urls.TRIP}${tripId}/intervein/`;

    let status: string;
    switch (action) {
      case DeliveryRequestAction.CHECK_IN:
        status = "AWAITING_RELEASE"
        break;
      case DeliveryRequestAction.RELEASE_ORDER:
        status = "AWAITING_COLLECTION"
        break;
      case DeliveryRequestAction.CHECK_OUT:
        status = "ON_WAY"
        break;
      case DeliveryRequestAction.REGISTER_CHECK_IN_BACK:
        status = "BACK_TO_STORE"
        break;
      case DeliveryRequestAction.FINISHED:
        status = "FINISHED"
        break;
      default:
        status = "";
        break;
    }

    return axios.post(url, { action: status });
  },

  cancelTripById: (tripId: number) => {
    const url = `${urls.TRIP}${tripId}/cancel/`;
    return axios.post(url)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  resetTripById: (tripId: number) => {
    const url = `${urls.TRIP}${tripId}/reset/`;
    return axios.post(url)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  resendTrip: (tripId: number, courierId: number | null) => {
    return axios
      .post(`${urls.TRIP}${tripId}/resend/`, { courier: courierId })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  getTripTracking: (tripId: number, courierId: number) => {
    return new Promise((resolve, reject) => {
      const url = getURL(urls.TRACKING, { "trip_id": tripId, "courier_id": courierId, "page_size": 250 });
      axios.get(url)
        .then((response) => {
          const result: TripTrackingPoint[] = response.data.results.map((item: any) => {
            return {
              created_at: convertToDate(item.created_at),
              latitude: convertToNumber(item.latitude),
              longitude: convertToNumber(item.longitude)
            };
          });
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  getAvailableTripResquestActions: (
    status: TripStatus,
    has_return: boolean
  ): DeliveryRequestAction[] => {
    let baseChoices: DeliveryRequestAction[] = [];
    switch (status) {
      case TripStatus.ACCEPTED:
        baseChoices.push(DeliveryRequestAction.CHECK_IN);
        baseChoices.push(DeliveryRequestAction.RELEASE_ORDER);
        baseChoices.push(DeliveryRequestAction.CHECK_OUT);
        if (has_return) {
          baseChoices.push(DeliveryRequestAction.REGISTER_CHECK_IN_BACK);
        }
        baseChoices.push(DeliveryRequestAction.FINISHED);
        break;
      case TripStatus.AWAITING_RELEASE:
        baseChoices.push(DeliveryRequestAction.RELEASE_ORDER);
        baseChoices.push(DeliveryRequestAction.CHECK_OUT);
        if (has_return) {
          baseChoices.push(DeliveryRequestAction.REGISTER_CHECK_IN_BACK);
        }
        baseChoices.push(DeliveryRequestAction.FINISHED);
        break;
      // falls through
      case TripStatus.AWAITING_COLLECTION:
        baseChoices.push(DeliveryRequestAction.CHECK_OUT);
        if (has_return) {
          baseChoices.push(DeliveryRequestAction.REGISTER_CHECK_IN_BACK);
        }
        baseChoices.push(DeliveryRequestAction.FINISHED);
        break;
      // falls through
      case TripStatus.ON_WAY:
      case TripStatus.RETURNING:
        if (has_return) {
          baseChoices.push(DeliveryRequestAction.REGISTER_CHECK_IN_BACK);
        }
        baseChoices.push(DeliveryRequestAction.FINISHED);
        break;
      // falls through
      case TripStatus.BACK_TO_STORE:
        baseChoices.push(DeliveryRequestAction.FINISHED);
        break;
    }
    return baseChoices;
  },

  searchCourierForTrip: (tripId: number, courierId: number | null = null) => {
    if (courierId == null) {
      return axios
        .post(`${urls.TRIP}${tripId}/search_courier/`)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    } else {
      return axios
        .post(`${urls.TRIP}${tripId}/search_courier/`, { courier: courierId })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    }
  },

  releaseTripById: (id: number) => {
    return axios
      .post(`${urls.TRIP}${id}/release_orders/`, {})
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  finishTripById: (id: number) => {
    return axios
      .post(`${urls.TRIP}${id}/finish/`, {})
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  getTripRequestLogs: (tripId: number) => {
    const url = `${urls.TRIP}${tripId}/logs/`;
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then((response) => {
          const result: DeliveryRequestLog[] = response.data.map((item: any) => {
            return {
              ...item,
              courier: convertToNumber(item.courier),
              manual_action: convertToBoolean(item.manual_action),
              created_at: convertToDate(item.created_at),
              is_courier_action: convertToBoolean(item.is_courier_action),
              made_by: convertToNumber(item.made_by)
            }
          });
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  getTripRequestSelectedCouriers: (tripId: number) => {
    const url = `${urls.TRIP}${tripId}/selected_couriers/`;

    return axios.get(url);
  },

  createNewTrip: async (storeId: number, deliveryIds: number[]) => {
    return axios
      .post(`${urls.TRIP}mount_trip/`, {
        store: storeId,
        deliveries: deliveryIds
      })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  addDeliveryToTrip: (trip: PendingTrip | Trip, deliveryId: number) => {
    let request = null;
    let validStatus = true;
    switch (trip.status) {
      case TripStatus.ACCEPTED:
      case TripStatus.AWAITING_RELEASE:
      case TripStatus.AWAITING_COLLECTION:
        request = axios
          .post(`${urls.TRIP}${trip.id}/request_delivery_addition/`, {
            delivery_request: deliveryId
          });
        break;
      case TripStatus.NEW:
      case TripStatus.NO_COURIER:
        request = axios
          .post(`${urls.TRIP}${trip.id}/join_delivery_request/`, {
            delivery_request: deliveryId
          });
        break;
      default:
        validStatus = false;
        break;
    }

    let result;
    if (validStatus && request) {
      result = request
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    } else {
      result = Promise.reject({
        response: {
          status: 400,
          data: { status: "Não pode adicionar entregas na situação atual." }
        }
      });
    }

    return result;
  },

  removeDeliveryFromTrip: (tripId: number, deliveryId: number) => {
    return axios
      .post(`${urls.TRIP}${tripId}/remove_delivery_request/`, {
        delivery_request_id: deliveryId
      })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  discardTrip: (id: number) => {
    return axios
      .post(`${urls.TRIP}${id}/discard/`, {})
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  getAnnouncementByTrip: async (id: number) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${urls.TRIP}${id}/announcements/`)
            .then(response => {
              const data: AnnouncementResponse[] = response.data.map(
                (item: any) => {
                    return {
                        ...item,
                        id: Number(item.id),
                        region: item.region ? Number(item.region) : null,
                        send_date: item.send_date ? parseISO(item.send_date) : null,
                        expire_date: item.expire_date ? parseISO(item.expire_date) : null
                    };
                }
            );
            const result: PagedResponse<AnnouncementResponse> = {
                data: data,
                count: data.length,
            };
            resolve(result);
            })
            .catch(error => reject(error));
    });
},
}

export default TripService;