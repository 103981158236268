/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Fade from "@material-ui/core/Fade";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { LoadingComponent } from "../../../components/Loading";
import { useSelector } from "react-redux";
import { IGlobalState } from "../../../store/types/state";
import { StoreResponse } from "../../../services/types/customer";
import CustomerService from "../../../services/customerService";
//@ts-ignore
import { NotificationManager } from "react-notifications";

interface IMaxDeliveryTripDialog {
  modal: {
    open: boolean;
    setOpen(value: boolean): void;
  };
  store: StoreResponse | undefined;
  refresh(): void;
}

interface EMPTY_OBJ_STORE_MAX {
  max_number_of_deliveries: number;
  max_number_of_deliveries_limit: number;
  use_priority_queue: boolean
};

export const EMPTY_STORE_MAX: EMPTY_OBJ_STORE_MAX = {
    max_number_of_deliveries: 1,
    max_number_of_deliveries_limit: 1,
    use_priority_queue: true
};

const validationSchema = Yup.object().shape({
    max_number_of_deliveries_limit: Yup.number().min(1, "Mínimo de 1").max(10, "Não pode ser mais que 10").required("Campo obrigatório"),
    max_number_of_deliveries: Yup.number().max(Yup.ref('max_number_of_deliveries_limit'), "Não pode ser maior que o Máx. de Entregas Juntas Configurável").min(1, "Mínimo de 1").required("Campo obrigatório"),
});

const MaxDeliveryTripDialog: React.FC<IMaxDeliveryTripDialog> = ({
  modal,
  store,
  refresh
}) => {

  const { isLoading } = useSelector(
    (state: IGlobalState) => state.customer
  );

  const handleClose = () => {
    modal.setOpen(false);
  };

  useEffect(() => {
    if(store){
      formik.resetForm();
      formik.setValues({
          max_number_of_deliveries: store.max_number_of_deliveries,
          max_number_of_deliveries_limit: store.max_number_of_deliveries_limit,
          use_priority_queue: store.use_priority_queue
      });
    
    }    
  }, [store]);

  const formik = useFormik({
    initialValues: EMPTY_STORE_MAX,
    onSubmit: (item) => {
        if(store){
            if(Number(item.max_number_of_deliveries_limit) >= Number(item.max_number_of_deliveries)){
                handleUpdateConcurrentDeliveries(store.id, item.max_number_of_deliveries, item.max_number_of_deliveries_limit, item.use_priority_queue);   
            }else{
                NotificationManager.error("Máx. de Entregas Juntas não pode ser maior que o Máx. de Entregas Juntas Configurável.", "Configuração");
            }
        }
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleUpdateConcurrentDeliveries = (idStore: number, max_number_of_deliveries: number, max_number_of_deliveries_limit: number, use_priority_queue: boolean) => {
    CustomerService.updateConcurrentDeliveries(idStore, max_number_of_deliveries, max_number_of_deliveries_limit, use_priority_queue)
    .then((response) => {
        NotificationManager.success("Configurações atualizadas com sucesso.", "Loja");
        handleClose();
        refresh();
    })
    .catch((error) => {
        NotificationManager.error("Não foi possível atualizar configurações da Loja.", "Loja");
    });
  }

  return (
    <div>
      <Dialog
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        open={modal.open}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
      >
        <Fade in={modal.open}>
          {isLoading ? (
            <LoadingComponent />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <DialogTitle id="form-dialog-title">
                Configuração Entregas Juntas
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <TextField
                      id="max_number_of_deliveries_limit"
                      label="Máx. de Entregas Juntas Configurável *"
                      variant="outlined"
                      value={formik.values.max_number_of_deliveries_limit}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldValue('max_number_of_deliveries_limit', e.target.value);
                      }}
                      helperText={formik.errors.max_number_of_deliveries_limit}
                      error={!!formik.errors.max_number_of_deliveries_limit}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      id="max_number_of_deliveries"
                      label="Máx. de Entregas Juntas *"
                      variant="outlined"
                      value={formik.values.max_number_of_deliveries}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldValue('max_number_of_deliveries', e.target.value);
                      }}
                      helperText={formik.errors.max_number_of_deliveries}
                      error={!!formik.errors.max_number_of_deliveries}
                      fullWidth
                    />
                  </Grid>

                  <Grid item md={12} lg={12}>
                      <FormControlLabel
                          control={
                              <Checkbox
                              checked={formik.values.use_priority_queue}
                              onChange={formik.handleChange}                            
                              name="use_priority_queue"
                              color="primary"
                              />
                          }
                          label="Priorização na Fila"
                      />
                    </Grid>
                  
                </Grid>
                <Typography style={{ fontSize: "10px", marginTop: "20px" }}>
                  Os campos com (*) são de preenchimento obrigatório.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button type="button" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button color="primary" type="submit">
                  Salvar
                </Button>
              </DialogActions>
            </form>
          )}
        </Fade>
      </Dialog>
    </div>
  );
};

export default MaxDeliveryTripDialog;
