import React from "react";
import { Grid, Theme, makeStyles, createStyles } from "@material-ui/core";

export const LoadingPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <img src="/loader.gif" width="250" alt="loading" />
        </Grid>
      </Grid>
    </div>
  );
};

export const LoadingComponent = ({ width = "100px" }) => {
  const setWith = width || "100px";
  return (
    <div className="text-center">
      <img src="/loader.gif" width={setWith} alt="loading" />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
    },
    container: {
      marginTop: "100px",
    },
  })
);
