//telefone
export const phoneMask = [
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const phoneMaskRegex = /[(]\d\d[)]\s\d\d\d\d\d?[-]\d\d\d\d*/g;

//celular
export const mobilePhoneMask = [
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const mobilePhoneMaskRegex = /[(]\d\d[)]\s\d\d\d\d\d[-]\d\d\d\d/g;

//CEP
export const postalCodeMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
];
export const postalCodeMaskRegex = /\d\d\d\d\d[-]\d\d\d/g;

//CPF
export const registrationNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];

//CNPJ
export const cnpjNumberMask = [
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];

export const UUID_MASK = [
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 "-",
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 "-",
 /[1-5]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 "-",
 /[89ab]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 "-",
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
 /[0-9a-f]/,
];

export const noSpecialCharactersRegex = /^[a-zA-Z0-9\u00C0-\u00FF\s]*$/g;
export const noSpecialCharactersAndDigitsRegex = /^[a-zA-Z\u00C0-\u00FF\s]*$/g;
export const UUID_REGEX = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-\b[0-9a-f]{12}\b/g

export const currencyMaskRegex = [
  /\d/,
  /\d/,
  [","],
  /\d/,
  /\d/,
]
