import { endOfDay, formatISO, formatRFC3339, startOfDay } from "date-fns";
import { apiAxios as axios } from "../store/api/config";
import { getURL, urls } from "../store/api/urls";
import { convertToBoolean, convertToDate, convertToNumber } from "../utils/utils";
import { IPageableParams, PagedResponse } from "./types/common";
import { CourierOperation } from "./types/courier";
import {
    Address,
    CourierPosition,
    DeliveryRequest,
    DeliveryRequestAction,
    DeliveryRequestEvent,
    DeliveryRequestLog,
    DeliveryRequestResponse,
    DeliveryRequestReturnType,
    DeliveryRequestStatus,
    Incentive,
    DeliveriesPerRegionPerDay,
    PendingDeliveryRequest,
    StatsDashboard,
    TripCourier,
    ValuesPerRegionsResponse,
    RegionValues,
    RegionDeliveries,
    DeliveriesPerDay,
    FinishedDeliveryRequest,
} from "./types/deliveryRequest";

export interface IDeliveryRequestService {
    getDeliveryRequests: (
        requestNumber: string,
        orderNumber: string,
        requestedTimeAfter: Date | null,
        requestedTimeBefore: Date | null,
        courierName: string,
        store_id: number | null,
        status: string,
        manualCreation: boolean | null,
        region: number | null,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<DeliveryRequestResponse>>;

    getDeliveryRequest: (id: number) => Promise<DeliveryRequest>;

    getDeliveryRequestLogs: (deliveryRequestId: number) => Promise<DeliveryRequestLog[]>;

    getDeliveryRequestSelectedCouriers: (
        deliveryRequestId: number
    ) => Promise<any>;

    interveneInDeliveryRequest: (
        deliveryRequestId: number,
        action: DeliveryRequestAction,
        reason: string
    ) => Promise<any>;

    getPendingDeliveryRequests: (
        regionIds: number[],
        storeIds: number[] | null,
        status: DeliveryRequestStatus[] | null,
        retrieveNew: boolean | null,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<PendingDeliveryRequest>>;

    getDeliveryRequestsStats: (
        regionsId: number[],
        startDate: Date,
        endDate: Date
    ) => Promise<any>;

    getAvailableDeliveryResquestActions: (
        returnType: string,
        status: DeliveryRequestStatus,
        logs: any[]
    ) => DeliveryRequestAction[];

    refundDeliveryRequestById: (deliveryRequestId: number) => Promise<any>;

    cancelDeliveryRequestById: (deliveryRequestId: number) => Promise<any>;
   
    resetDeliveryRequestById: (deliveryRequestId: number) => Promise<any>;
   
    resendDeliveryRequest: (id: number, courierId: number | null) => Promise<any>;

    getAvailableDeliveryActions: (status: DeliveryRequestStatus) => DeliveryRequestAction[];  

    requestCourierToDeliveryRequestById: (id: number, courierId?: number | null) => Promise<any>;

    discardDeliveryRequestById: (id: number) => Promise<any>;

    getDeliveryRequestByRequestNumber: (requestNumber: string) => Promise<any>;

    lookForIFoodCourier: (deliveryRequestId: number) => Promise<any>;

    getReleasesDelivery: (deliveryRequestId: number, courierEntries: boolean) => Promise<any>;

    fixDistanceDelivery: (deliveryRequestId: number, newDistance: number, description: string) => Promise<any>;

    getQuantityDeliveries: (regions: number[], date_after: Date, date_before: Date) => Promise<RegionDeliveries[]>;

    getValuesPassedOn: (regions: number[], date_after: Date, date_before: Date) => Promise<ValuesPerRegionsResponse>;

    getEarnings: (regions: number[], date_after: Date, date_before: Date) => Promise<ValuesPerRegionsResponse>;

    getNumberDeliveries: (regions: number[], date_after: Date, date_before: Date) => Promise<DeliveriesPerRegionPerDay[]>;

    getValueTotalDeliveries: (regions: number[], date_after: Date, date_before: Date) => Promise<any[]>;

    createFinishedDeliveryRequest: (deliveryRequest: FinishedDeliveryRequest) => Promise<any>;
}

const DeliveryRequestService: IDeliveryRequestService = {
    getDeliveryRequests: async (
        requestNumber: string,
        orderNumber: string,
        requestedTimeAfter: Date | null,
        requestedTimeBefore: Date | null,
        courierName: string,
        store_id: number | null,
        status: string,
        manualCreation: boolean | null,
        region: number | null,
        pageParams: IPageableParams
    ) => {
        const url = `${urls.DELIVERY_REQUEST}get_closed_deliveries/?page=${Math.max(pageParams.page, 1)}&page_size=${pageParams.page_size}`;

        return new Promise((resolve, reject) => {
            axios
                .post(url, {
                    request_number: requestNumber,
                    order_number: orderNumber,
                    requested_time_after: requestedTimeAfter ? formatRFC3339(requestedTimeAfter) : null,
                    requested_time_before: requestedTimeBefore ? formatRFC3339(requestedTimeBefore) : null,
                    courier_name: courierName,
                    store_id: store_id,
                    status: status,
                    manual_creation: manualCreation,
                    region: region,
                    ordering: pageParams.ordering,
                })
                .then(response => {
                    const data: DeliveryRequestResponse[] = response.data.results.map(
                        (item: any) => {
                            return {
                                ...item,
                                id: Number(item.id),
                                created_at: convertToDate(item.created_at),
                                requested_time: convertToDate(item.requested_time),
                                manual_creation: String(item.manual_creation).toLowerCase() === "true" ? true : false,
                            };
                        }
                    );

                    const result: PagedResponse<DeliveryRequestResponse> = {
                        data: data,
                        count: response.data.count,
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    getDeliveryRequest: (id: number) => {
        const url = `${urls.DELIVERY_REQUEST}${id}/full_detail/`;
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    let total_value = 0;
                    const delivery_value = convertToNumber(response.data.delivery_value) || 0;
                    const return_value = convertToNumber(response.data.return_value) || 0;
                    const additional_km_value = convertToNumber(response.data.additional_km_value) || 0; 
                    const minimum_value = convertToNumber(response.data.minimum_value) || 0;
                    const originAddress: Address = {
                        ...response.data.origin_address,
                        latitude: convertToNumber(response.data.origin_address.latitude),
                        longitude: convertToNumber(response.data.origin_address.longitude)
                    };
                    const destinationAddress: Address = {
                        ...response.data.destination_address,
                        latitude: convertToNumber(response.data.destination_address.latitude),
                        longitude: convertToNumber(response.data.destination_address.longitude)
                    };
                    let courierPosition: CourierPosition | null = null;
                    if (response.data.courier_position) {
                        courierPosition = {
                            ...response.data.courier_position,
                            courier_id: convertToNumber(response.data.courier_position.courier_id),
                            latitude: convertToNumber(response.data.courier_position.latitude),
                            longitude: convertToNumber(response.data.courier_position.longitude),
                            delivery_request_id: convertToNumber(response.data.courier_position.delivery_request_id),
                            trip_id: convertToNumber(response.data.courier_position.trip_id),
                            updated_at: convertToDate(response.data.courier_position.updated_at),
                        }
                    }
                    let currentCourier: TripCourier | null = null;
                    if (response.data.current_courier) {
                        currentCourier = {
                            ...response.data.current_courier,
                            ranking_points: convertToNumber(response.data.current_courier.ranking_points)
                        };
                    }
                    let incentive: Incentive | null = null;
                    let totalIncentive = 0;
                    if (response.data.incentive_json) {
                        let customerValue = convertToNumber(response.data.incentive_json.customer_value) || 0;
                        let speedyValue = convertToNumber(response.data.incentive_json.speedy_value) || 0;
                        totalIncentive = customerValue + speedyValue;
                        incentive = {
                            ...response.data.incentive_json,
                            customer_value: convertToNumber(response.data.incentive_json.customer_value),
                            speedy_value: convertToNumber(response.data.incentive_json.speedy_value),
                            total: convertToNumber(response.data.incentive_json.total)
                        };
                    };
                    total_value = minimum_value + totalIncentive + additional_km_value + return_value;
                    const result: DeliveryRequest = {
                        ...response.data,
                        created_at: convertToDate(response.data.created_at),
                        requested_time: convertToDate(response.data.requested_time),
                        distance: convertToNumber(response.data.distance),
                        delivery_value: delivery_value,
                        minimum_value: minimum_value,
                        additional_km_value: additional_km_value,
                        return_value: return_value,
                        speedy_value: convertToNumber(response.data.speedy_value),
                        courier_value: convertToNumber(response.data.courier_value),
                        courier_cancel_value: convertToNumber(response.data.courier_cancel_value),
                        origin_address: originAddress,
                        destination_address: destinationAddress,
                        courier_position: courierPosition,
                        current_courier: currentCourier,
                        acceptation_distance: convertToNumber(response.data.acceptation_distance),
                        time_to_arrive_store: convertToDate(response.data.time_to_arrive_store),
                        estimated_delivery_time: convertToDate(response.data.estimated_delivery_time),
                        estimated_delivery_time_after_release: convertToDate(response.data.estimated_delivery_time_after_release),
                        manual_creation: convertToBoolean(response.data.manual_creation),
                        refunded: convertToBoolean(response.data.refunded),
                        incentive_json: incentive,
                        is_scheduled: convertToBoolean(response.data.is_scheduled),
                        canceled: convertToBoolean(response.data.canceled),
                        trip_id: convertToNumber(response.data.trip_id),
                        trip_number: response.data.trip_number,
                        called_ifood: convertToBoolean(response.data.called_ifood),
                        value_total: convertToNumber(total_value),
                    };
                    resolve(result);
                })
                .catch((error) => reject(error));
        });
    },

    getDeliveryRequestLogs: (deliveryRequestId: number) => {
        const url = `${urls.DELIVERY_REQUEST}${deliveryRequestId}/logs/`;
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    const result: DeliveryRequestLog[] = response.data.map((item: any) => {
                        return {
                            ...item,
                            courier: convertToNumber(item.courier),
                            manual_action: convertToBoolean(item.manual_action),
                            created_at: convertToDate(item.created_at),
                            is_courier_action: convertToBoolean(item.is_courier_action),
                            made_by: convertToNumber(item.made_by)
                        }
                    });
                    resolve(result);
                })
                .catch((error) => reject(error));
        });
    },

    getDeliveryRequestSelectedCouriers: (deliveryRequestId: number) => {
        const url = `${urls.DELIVERY_REQUEST}${deliveryRequestId}/selected_couriers/`;

        return axios.get(url);
    },

    interveneInDeliveryRequest: (deliveryRequestId: number, action: DeliveryRequestAction, reason: string) => {
        const url = `${urls.DELIVERY_REQUEST}${deliveryRequestId}/intervein/`;

        return axios.post(url, {
            action: action,
            reason: reason,
        });
    },

    getPendingDeliveryRequests: (
        regionIds: number[],
        storeIds: number[] | null,
        status: DeliveryRequestStatus[] | null,
        retrieveNew: boolean | null,
        pageParams: IPageableParams
    ) => {
        const url = `${urls.DELIVERY_REQUEST}get_pending_deliveries/?page=${pageParams.page}&page_size=${pageParams.page_size}`;
        return new Promise((resolve, reject) => {
            axios.post(url, {
                ordering: pageParams.ordering,
                regions: regionIds,
                stores: storeIds ? storeIds : [],
                status: status,
                retrieve_new: retrieveNew,
                with_trip: false
            }).then((response) => {
                const data: PendingDeliveryRequest[] = response.data.results.map((item: any, index: number) => {
                    const destinationAddress = {
                        ...item.destination_address,
                        latitude: convertToNumber(item.destination_address.latitude),
                        longitude: convertToNumber(item.destination_address.longitude)
                    };
                    const store = {
                        ...item.store,
                        id: convertToNumber(item.store.id),
                        nome: item.store.nome,
                        latitude: convertToNumber(item.store.latitude),
                        longitude: convertToNumber(item.store.longitude)
                      };
                    return {
                        ...item,
                        id: convertToNumber(item.id),
                        created_at: convertToDate(item.created_at),
                        requested_time: convertToDate(item.requested_time),
                        customer_id: convertToNumber(item.customer_id),
                        store: store,
                        courier_id: convertToNumber(item.courier_id),
                        estimated_delivery_time: convertToDate(item.estimated_delivery_time),
                        estimated_delivery_time_after_release: convertToDate(item.estimated_delivery_time_after_release),
                        distance: convertToNumber(item.distance),
                        destination_address: destinationAddress,
                        called_ifood: convertToBoolean(item.called_ifood),
                        return_type: item.return_type
                    };
                });
                const result: PagedResponse<PendingDeliveryRequest> = {
                    data: data,
                    count: response.data.count,
                };
                resolve(result);
            }).catch((error) => reject(error));
        });
    },

    getDeliveryRequestsStats: async (
        regionsId: number[],
        startDate: Date,
        endDate: Date
    ) => {
        const url = `${urls.DELIVERY_REQUEST}stats/`;
        return new Promise((resolve, reject) => {
            axios
              .post(url, {
                region: regionsId,
                start_date: formatISO(startDate),
                end_date: formatISO(endDate),
            }).then((response) => {

                const data: StatsDashboard[] = response.data.map((item: any, index: number) => {                   
                    return {
                        ...item,
                        region: convertToNumber(item.region),
                        canceled:  convertToNumber(item.canceled),
                        finished: convertToNumber(item.finished),
                        total: item.finished + item.canceled,
                    };
                });

                resolve(data);
              }).catch((error) => reject(error));
          });
    },

    getAvailableDeliveryResquestActions: (
        returnType: string,
        status: DeliveryRequestStatus,
        logs: any[]
    ): DeliveryRequestAction[] => {
        let baseChoices: DeliveryRequestAction[] = [];

        if (logs) {
            let canceled = false;
            for (let index = 0; index < logs.length; index++) {
                const log = logs[index];
                console.error(`logs[${index}].event`, log.event);
                if (
                    log.event === DeliveryRequestEvent.CUSTOMER_CANCELED_ORDER
                ) {
                    baseChoices.push(DeliveryRequestAction.CANCELED);
                    canceled = true;
                    break;
                }
            }
            if (canceled) {
                return baseChoices;
            }
        }

        switch (status) {
            case DeliveryRequestStatus.ACCEPTED:
                baseChoices.push(DeliveryRequestAction.CHECK_IN);
            // falls through
            case DeliveryRequestStatus.AWAITING_RELEASE:
                baseChoices.push(DeliveryRequestAction.RELEASE_ORDER);
            // falls through
            case DeliveryRequestStatus.AWAITING_COLLECTION:
                baseChoices.push(DeliveryRequestAction.CHECK_OUT);
            // falls through
            case DeliveryRequestStatus.ON_WAY:
                baseChoices.push(DeliveryRequestAction.REGISTER_ARRIVAL);
            // falls through
            case DeliveryRequestStatus.AT_DESTINY:
                baseChoices.push(DeliveryRequestAction.REGISTER_DELIVER);
            // falls through
            case DeliveryRequestStatus.RETURNING:
                if (returnType !== DeliveryRequestReturnType.NONE) {
                    baseChoices.push(
                        DeliveryRequestAction.REGISTER_CHECK_IN_BACK
                    );
                }
            // falls through
            case DeliveryRequestStatus.DELIVERED:
            case DeliveryRequestStatus.BACK_TO_STORE:
                baseChoices.push(DeliveryRequestAction.FINISHED);
        }
        return baseChoices;
    },

    getAvailableDeliveryActions: (
        status: DeliveryRequestStatus
    ): DeliveryRequestAction[] => {
        let baseChoices: DeliveryRequestAction[] = [];
        switch (status) {
            case DeliveryRequestStatus.ON_WAY:
                baseChoices.push(DeliveryRequestAction.REGISTER_ARRIVAL);
                baseChoices.push(DeliveryRequestAction.REGISTER_DELIVER);
                break;
            // falls through
            case DeliveryRequestStatus.AT_DESTINY:
                baseChoices.push(DeliveryRequestAction.REGISTER_DELIVER);
                break;
        }
        return baseChoices;
    },

    refundDeliveryRequestById: (deliveryRequestId: number) => {
        const url = `${urls.DELIVERY_REQUEST}${deliveryRequestId}/refund/`;

        return axios.post(url);
    },

    cancelDeliveryRequestById: (deliveryRequestId: number) => {
        const url = `${urls.DELIVERY_REQUEST}${deliveryRequestId}/cancel/`;
        return axios.post(url)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },

    resetDeliveryRequestById: (deliveryRequestId: number) => {
        const url = `${urls.DELIVERY_REQUEST}${deliveryRequestId}/reset/`;
        return axios.post(url)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },

    resendDeliveryRequest: (tripId: number, courierId: number | null) => {
        return axios
            .post(`${urls.DELIVERY_REQUEST}${tripId}/resend/`, { current_courier: courierId })
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },

    requestCourierToDeliveryRequestById: (id: number, courierId?: number | null) => {
        return new Promise((resolve, reject) => {
          axios
            .post(`${urls.DELIVERY_REQUEST}${id}/look_for_courier/`, { current_courier: courierId })
            .then((response) => {
              resolve({
                ...response.data,
                id: convertToNumber(response.data.id),
                trip: convertToNumber(response.data.trip)
              });
            }).catch((error) => reject(error));
        });
    },

    discardDeliveryRequestById: (id: number) => {
    return axios
        .post(`${urls.DELIVERY_REQUEST}${id}/discard/`)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    },

    getDeliveryRequestByRequestNumber: (requestNumber: string) => {
        const url = `${urls.DELIVERY_REQUEST}?request_number=${requestNumber}`;
        
        return axios.get(url);
    },

    lookForIFoodCourier: (deliveryRequestId: number) => {
        return axios
            .post(`${urls.DELIVERY_REQUEST}${deliveryRequestId}/look_for_ifood_courier/`)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },

    getReleasesDelivery: async (deliveryRequestId: number, courierEntries: boolean) => {
        return new Promise((resolve, reject) => {
            axios
              .get(`${urls.DELIVERY_REQUEST}${deliveryRequestId}/financial_entries/?courier_entries=${courierEntries}`)
              .then((response) => {
                const result: CourierOperation[] = response.data.map((item: any, index: number) => {
                    return {
                        ...item,
                        id: convertToNumber(item.id),
                        created_at: convertToDate(item.created_at),
                        value: convertToNumber(item.value),
                        tax: convertToNumber(item.tax),
                        total: convertToNumber(item.total),
                    };
                });
                resolve(result);
              }).catch((error) => reject(error));
          });
    },

    fixDistanceDelivery: async (
        deliveryRequestId: number,
        newDistance: number,
        description: string
    ) => {
        return new Promise((resolve, reject) => {
            axios
              .post(`${urls.DELIVERY_REQUEST}${deliveryRequestId}/fix_distance/`, {
                new_distance: newDistance,
                description: description
            }).then((response) => {
                resolve(response);
              }).catch((error) => reject(error));
          });
    },

    getQuantityDeliveries: (
        regions: number[],
        date_after: Date,
        date_before: Date
    ) => {
        const url = getURL(`${urls.DELIVERY_REQUEST}graph/`, {
            requested_time_after: formatRFC3339(startOfDay(date_after)),
            requested_time_before: formatRFC3339(endOfDay(date_before)),
            regions: regions
        });
        return new Promise((resolve, reject) => {
            axios
              .get(url).then((response) => {
                const result: RegionDeliveries[] = response.data.map((item: any) => {
                    return {
                        id: item.region_id,
                        name: item.region_name,
                        quantity: item.quantity
                    }
                });
                resolve(result);
              }).catch((error) => reject(error));
          });
    },

    getValuesPassedOn: (
        regions: number[],
        date_after: Date,
        date_before: Date
    ) => {
        const url = getURL(`${urls.SPEEDY_FINANCES}passed_on/`, {
            created_at_date_after: formatISO(date_after, { representation: "date" }),
            created_at_date_before : formatISO(date_before, { representation: "date" }),
            regions: regions
        });
        return new Promise((resolve, reject) => {
            axios
              .get(url).then((response) => {
                const per_regions: RegionValues[] = response.data.per_regions.map((item: any, index: number) => {
                    return {
                        ...item,
                        id: convertToNumber(item.id),                      
                        value: convertToNumber(item.value),                      
                    };
                });
                const result: ValuesPerRegionsResponse = {
                    ...response.data,
                    per_regions: per_regions,
                    total: convertToNumber(response.data.total)     
                };      
                resolve(result);
              }).catch((error) => reject(error));
          });
    },

    getEarnings: (
        regions: number[],
        date_after: Date,
        date_before: Date
    ) => {
        const url = getURL(`${urls.SPEEDY_FINANCES}earnings/`, {
            created_at_date_after: formatISO(date_after, { representation: "date" }),
            created_at_date_before: formatISO(date_before, { representation: "date" }),
            regions: regions
        });
        return new Promise((resolve, reject) => {
            axios
              .get(url).then((response) => {

                const per_regions: RegionValues[] = response.data.per_regions.map((item: any, index: number) => {
                    return {
                        ...item,
                        id: convertToNumber(item.id),                      
                        value: convertToNumber(item.value),                      
                    };
                });
                const result: ValuesPerRegionsResponse = {
                    ...response.data,
                    per_regions: per_regions,
                    total: convertToNumber(response.data.total)     
                };                
                resolve(result);
              }).catch((error) => reject(error));
          });
    },

    getNumberDeliveries: (
        regions: number[],
        date_after: Date,
        date_before: Date
    ) => {
        const url = getURL(`${urls.DELIVERY_REQUEST}per_days_graph/`, {
            requested_time_after: formatRFC3339(startOfDay(date_after)),
            requested_time_before: formatRFC3339(endOfDay(date_before)),
            regions: regions
        });
        return new Promise((resolve, reject) => {
            axios
              .get(url).then((response) => {
                const data: DeliveriesPerRegionPerDay[] = response.data.map((item: any, index: number) => {                   
                    const dates: DeliveriesPerDay[] = item.dates?.map((item: any) => {
                        return {
                            ...item,
                            date: convertToDate(item.date),
                            quantity: convertToNumber(item.quantity)
                        }
                    });

                    return {
                        ...item,
                        region_id: convertToNumber(item.region_id),
                        dates: dates
                    };
                });              
                resolve(data);
              }).catch((error) => reject(error));
          });
    },

    getValueTotalDeliveries: (
        regions: number[],
        date_after: Date,
        date_before: Date
    ) => {
        const url = getURL(`${urls.DELIVERY_REQUEST}delivery_values/`, {
            requested_time_date_after: formatISO(date_after, { representation: "date" }),
            requested_time_date_before: formatISO(date_before, { representation: "date" }),
            regions: regions
        });
        return new Promise((resolve, reject) => {
            axios
              .get(url).then((response) => {     
                const values: RegionValues[] = response.data.map((item: any, index: number) => {
                    return {
                        ...item,
                        region_id: convertToNumber(item.region_id),                      
                        value: convertToNumber(item.value),
                        name: item.region_name                      
                    };
                });    
                resolve(values);
              }).catch((error) => reject(error));
          });
    },

    createFinishedDeliveryRequest: (deliveryRequest: FinishedDeliveryRequest) => {
        return axios
          .post(`${urls.DELIVERY_REQUEST}manual_creation/`, {
            ...deliveryRequest,
            consignee_phone_number: "0",
            order_date: formatISO(deliveryRequest.order_date),
            custom_courier: true,
            estimated_delivery_time: null,
            order_value: null
          })
          .then((response) => Promise.resolve(response.data))
          .catch((error) => Promise.reject(error));
      },

};

export default DeliveryRequestService;
