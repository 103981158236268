import React from "react";
import { SlotResponse } from "../../../services/types/dedicated";
import { addWeeks, endOfWeek } from "date-fns";
import { Calendar } from "../calendar";
import { Divider, Grid, makeStyles, Menu, MenuItem, Theme, Typography } from "@material-ui/core";
import { SlotCard } from "../calendar/slotCard";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../../components/ConfirmationDialog";
import SelectCourierSlotDialog, { SelectCourierSlotDialogHandle } from "../../dedicated/SelectCourierSlotDialog";
import DedicatedDetailsDialog, { DedicatedDetailsDialogHandle } from "../calendar/dedicatedDetailsDialog";
import useCourierSchedule from "../hooks/useCourierSchedule";

interface DashboardCourierScheduleViewProps {
  searchValue?: string;
  regionsIds: number[];
  onDateRangeChange: (newDateRange: [Date, Date]) => void;
}

export const DashboardCourierScheduleView: React.FC<DashboardCourierScheduleViewProps> = ({ searchValue, regionsIds, onDateRangeChange }) => {
  const classes = useStyles();

  const { slotList, closeSlot, removeCourierFromSlot } = useCourierSchedule();

  const [slotFilteredList, setSlotFilteredList] = React.useState<SlotResponse[]>([]);

  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
  const addCourierDialog = React.createRef<SelectCourierSlotDialogHandle>();
  const dedicatedDetailsDialog = React.createRef<DedicatedDetailsDialogHandle>();

  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);

  const [selectedSlot, setSelectedSlot] = React.useState<SlotResponse>();
  const maxDate = endOfWeek(addWeeks(new Date(), 1));

  const handleMarkAsResolved = (slot?: SlotResponse, isDone?: boolean) => {
    handleClose();
    if (slot) {
      var title = "Confirmar Realização do Agendamento";
      var message = "Realização do Agendamento";
      var btnMessage = "Realizado";
      if (isDone === false) {
        title = "Confirmar a Não Realização do Agendamento";
        message = "Não Realização do Agendamento";
        btnMessage = "Não Realizado";
      }
      const dialogMarkResolved = confirmationDialog.current;
      dialogMarkResolved?.confirm(
        title,
        <Typography>
          Você tem certeza que deseja <strong>Confirmar</strong> a {message}? Essa ação não poderá ser desfeita.
        </Typography>,
        btnMessage,
        "Cancelar"
      )
        .then(() => {
          closeSlot(slot.id, isDone ? true : false)
            .then(() => {
              NotificationManager.success("Confirmado com sucesso.", "Sucesso");
              dialogMarkResolved?.hide();
              finish();
            })
            .catch((error) => {
              NotificationManager.error(
                "Não foi possível Confirmar a Realização do Agendamento",
                "Error"
              );
              dialogMarkResolved?.hide();
            });
        })
        .catch(() => { });
    }
  };

  const removeCourier = (slotObj: SlotResponse) => {
    if (slotObj) {
      const dialogRemove = confirmationDialog.current;
      dialogRemove?.confirm(
        "Remover o Entregador",
        <Typography>
          Você tem certeza que deseja <strong>Remover</strong> o Entregador da Vaga?
        </Typography>,
        "Confirmar",
        "Cancelar"
      )
        .then(() => {
          removeCourierFromSlot(slotObj.id)
            .then(() => {
              NotificationManager.success("Removido com sucesso.", "Sucesso");
              dialogRemove.hide();
              finish();
            })
            .catch((error) => {
              NotificationManager.error(
                "Não foi possível Remover o Entregador",
                "Error"
              );
              dialogRemove.hide();
            });
        })
        .catch(() => { });
    }
  };

  const addRemoveCourier = (slot?: SlotResponse) => {
    handleClose();
    if (slot) {
      if (slot.courier) {
        removeCourier(slot);
      } else {
        addCourierDialog.current?.open(slot.id, "", [slot.region_id], slot.region_name);
      }
    }
  };

  const disableBtnDone = (slotObj?: SlotResponse) => {
    if (slotObj) {
      if (!slotObj.courier) {
        return true;
      } else if (slotObj.courier && slotObj.is_done == null) {
        return false;
      } else if (slotObj.is_done === null) {
        return false;
      } else if (slotObj.courier && slotObj.is_done !== null) {
        return true;
      }
    }
  };

  const disableBtnConfirm = (slotObj?: SlotResponse) => {
    if (slotObj) {
      if (!slotObj.courier || slotObj.is_done !== null) {
        return true;
      } else if (slotObj.courier && slotObj.is_done === null) {
        //Só pode confirmar a realização após o horario de témino da vaga    
        /* if (slotObj.end_datetime.getTime() > new Date().getTime()) {
          return true;
        } else { */
          return false;
        //}
      }
    }
  };

  const finish = (): Promise<void> => {
    try {
      confirmationDialog.current?.hide();
      //refreshData();
    } finally {
      return Promise.resolve();
    }
  };

  const showDetails = (slot?: SlotResponse) => {
    handleClose();
    if (slot) {
      dedicatedDetailsDialog.current?.open(slot.proposal_id);
    }
  };

  const handleClick = (slot: SlotResponse, event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedSlot(slot);
    setAnchorElMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElMenu(null);
  };

  const renderItem = (slot: SlotResponse) => {
    return <SlotCard key={slot.id} slot={slot} onClick={handleClick} />
  }

  React.useLayoutEffect(() => {
    let filteredList: SlotResponse[] = slotList || [];
    if (filteredList.length > 0) {
      if (searchValue?.trim()) {
        const emptyCourier = "Sem Entregador";
        filteredList = filteredList.filter((slot) => {
          const value = searchValue.toLocaleLowerCase();
          if (slot.store_name.toLocaleLowerCase().includes(value)) {
            return true;
          }
          if (slot.courier?.name.toLocaleLowerCase().includes(value)) {
            return true;
          }
          if (!slot.courier) {
            if (emptyCourier.toLocaleLowerCase().includes(value)) {
              return true;
            }
          }
          return false;
        });
      }

      filteredList = filteredList.sort((a, b) => {
        if (a.start_datetime > b.start_datetime) {
          return 1;
        } else if (a.start_datetime < b.start_datetime) {
          return -1;
        } else {
          return 0;
        }
      });
    }

    setSlotFilteredList(filteredList);
  }, [slotList, searchValue, setSlotFilteredList]);

  return (
    <React.Fragment>
      <ConfirmationDialog ref={confirmationDialog} />
      <SelectCourierSlotDialog ref={addCourierDialog} onAccept={finish} />
      <DedicatedDetailsDialog ref={dedicatedDetailsDialog} />

      <Menu
        id="actions-menu"
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleClose}
      >
        {selectedSlot && !selectedSlot.courier && (
          <MenuItem onClick={() => addRemoveCourier(selectedSlot)} disabled={selectedSlot?.is_done !== null}>Associar Entregador</MenuItem>
        )}
        <MenuItem onClick={(e) => handleMarkAsResolved(selectedSlot, true)} disabled={disableBtnConfirm(selectedSlot)}>Confirmar Realização</MenuItem>
        <MenuItem onClick={(e) => handleMarkAsResolved(selectedSlot, false)} disabled={disableBtnDone(selectedSlot)}>Desistência</MenuItem>
        {selectedSlot && selectedSlot.courier && (
          <MenuItem onClick={() => addRemoveCourier(selectedSlot)} disabled={selectedSlot?.is_done !== null}>Remover Entregador</MenuItem>
        )}
        <Divider />
        <MenuItem onClick={() => showDetails(selectedSlot)}>Ver Proposta</MenuItem>
      </Menu>

      <Grid item md={12} className={classes.dashboardGridContent}>
        <Calendar
          slots={slotFilteredList}
          maxDate={maxDate}
          onDateRangeChange={onDateRangeChange}
          renderItem={renderItem}>
        </Calendar>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  dashboardGridContent: {
    height: "calc(100vh - 108px)",
  },
}));
