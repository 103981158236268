import React from "react";
import { useFormik } from "formik";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import * as Yup from "yup";
import { CurrencyInput } from "../../../../components/CurrencyInput";
import { convertToNumber } from "../../../../utils/utils";
import DeliveryRequestService from "../../../../services/deliveryRequestService";
import PinDropIcon from '@material-ui/icons/PinDrop';


interface CorrectDeliveryValueForm {
  description: string;
  new_distance: number | null;
}

const INITIAL_FORM_VALUES: CorrectDeliveryValueForm = {
  description: "",
  new_distance: 0,
}

interface CorrectDeliveryValueDialogProps {
  deliveryId: number;
  onAccept?: () => Promise<void>;
}

export interface CorrectDeliveryValueDialogHandle {
  open: () => void;
  close: () => void;
}

const validationSchema = Yup.object().shape({
  description: Yup.string()
    .min(3, "Mínimo de 5 caracteres")
    .max(100, "Máximo de 100 caracteres excedido")
    .required("Campo obrigatório"),
    new_distance: Yup.number()
    .required("Campo obrigatório")
    .transform((currentValue, originalValue) => convertToNumber(originalValue))
    .moreThan(0, "Campo obrigatório")
    
});

export const CorrectDeliveryValueDialog: React.ForwardRefRenderFunction<CorrectDeliveryValueDialogHandle, CorrectDeliveryValueDialogProps> = (props, ref) => {
  const [open, setOpen] = React.useState<boolean>(false);

  React.useImperativeHandle(ref, () => {
    return ({
      open() {
        show();
      },

      close() {
        hide();
      }
    });
  });

  const hide = () => {
    setOpen(false);
    formik.resetForm();
  }

  const show = () => {
    setOpen(true);
  }

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: async (values) => {
      try {
        const newDistance = values.new_distance ? convertToNumber(values.new_distance) : 0;
        await DeliveryRequestService.fixDistanceDelivery(props.deliveryId, newDistance ? newDistance : 0, values.description);

        if (props.onAccept) {
          await props.onAccept();
        }
        hide();
        NotificationManager.success(
          "Correão da taxa de Entrega feita com sucesso!",
          "Correão da taxa de Entrega"
        );
      } catch (error) {
        const { status, data } = error.response;
        if (status === 400) {
          for (var key in data) {
            var value = data[key];
            formik.setFieldError(key, value);
          }
        } else {
          NotificationManager.error(
            "Não foi possível corrigir taxa de entrega!",
            "Correão da taxa de Entrega"
          );
        }
      }
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });


  return (
    <Dialog
      open={open}
      onClose={hide}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="form-dialog-title">Corrigir Taxa da Entrega</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="description"
                label="Descrição"
                variant="outlined"
                required={true}
                onChange={formik.handleChange}
                value={formik.values.description}
                error={!!formik.errors.description}
                helperText={formik.errors.description}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="new_distance"
                label="Distância Correta em quilômetros KM"
                variant="outlined"
                required={true}
                onChange={formik.handleChange}
                value={formik.values.new_distance}
                error={!!formik.errors.new_distance}
                helperText={formik.errors.new_distance}
                fullWidth
                InputProps={{
                  inputComponent: CurrencyInput,
                  startAdornment: <InputAdornment position="start"><PinDropIcon style={{color:"#9e9e9e"}} /></InputAdornment>,
                }}
                inputProps={{
                  maxLength: 10
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide}>
            Cancelar
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={formik.isSubmitting}
            startIcon={formik.isSubmitting && (
              <CircularProgress size="1.5rem" color="inherit" />
            )}
          >
            Corrigir
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default React.forwardRef(CorrectDeliveryValueDialog);
