import React from "react";
import {
  Grid,
  Button,
  MenuItem,
  FormControl,
  makeStyles,
  OutlinedInput,
  InputLabel,
  Select,
  Chip,
  TextField,
} from "@material-ui/core";
import { FormikHelpers, useFormik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import * as Yup from "yup";
import CancelIcon from '@material-ui/icons/Cancel';
import { SpeedyRankingType } from "../../services/types/courier";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { encodeURLParams } from "../../utils/utils";

export interface IRankingCouriersParams {
  regions: number[];
  speedy_ranking: string
};

export const INITIAL_FILTER_PARAMS: IRankingCouriersParams = {
  regions: [],
  speedy_ranking: " "
};

interface IRankingCouriersFilterProps {
  params?: IRankingCouriersParams;
  regionList: any[];
  onFilterChanged: (params: IRankingCouriersParams) => void;
};

const validationSchema = Yup.object().shape({});

export const RankingCouriersFilter: React.FC<IRankingCouriersFilterProps> = ({ params = INITIAL_FILTER_PARAMS, regionList, onFilterChanged }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openRegionSelect, setOpenRegionSelect] = React.useState<boolean>(false);
  const history = useHistory();

  React.useEffect(() => {
    history.replace(encodeURLParams(history.location.pathname, params));
  }, [history, params]);

  const handleOpenRegionSelect = (event: React.ChangeEvent<{}>) => {
    setOpenRegionSelect(true);
  }

  const handleCloseRegionSelect = (event: React.ChangeEvent<{}>) => {
    setOpenRegionSelect(false);
  }

  const formik = useFormik<IRankingCouriersParams>({
    initialValues: params,
    onSubmit: (values: IRankingCouriersParams, formikHelpers: FormikHelpers<IRankingCouriersParams>) => {
      onFilterChanged(values);
    },
    onReset: (values: IRankingCouriersParams, formikHelpers: FormikHelpers<IRankingCouriersParams>) => {
      onFilterChanged(values);
      formikHelpers.setValues(values);
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleSelectRegionDelete = (deletedRegionId: number) => {
    const selectedRegionsIds = [...formik.values.regions];
    if (deletedRegionId && selectedRegionsIds) {
      const index = selectedRegionsIds.indexOf(deletedRegionId);
      if (index > -1) {
        selectedRegionsIds.splice(index, 1);
      }
      formik.setFieldValue("regions", selectedRegionsIds);
      if (selectedRegionsIds.length > 0) {
        formik.handleSubmit();
      } else {
        formik.resetForm();
      }
    }
  };

  return (
    <div className="page-filter">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <FormControl variant="outlined" fullWidth required={true}>
              <InputLabel id="regions-label">Regiões</InputLabel>
              <Select
                labelId="regions-label"
                id="regions"
                multiple
                required={true}
                open={openRegionSelect}
                onOpen={handleOpenRegionSelect}
                onClose={handleCloseRegionSelect}
                name="regions"
                variant="outlined"
                input={<OutlinedInput id="select-multiple-regions" label="Regiões" />}
                value={formik.values.regions}
                onChange={e => {
                  formik.handleChange(e);
                  setOpenRegionSelect(false);
                }}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {(selected as number[]).map((value, index) => (
                      <Chip
                        key={value}
                        label={regionList !== undefined ? regionList.find((option: { id: number }) => option.id === value)?.name : value}
                        className={classes.chip}
                        deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                        onDelete={() => { handleSelectRegionDelete(value) }}
                      />
                    ))}
                  </div>
                )}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  }
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled selected value={[]}>Selecione</MenuItem>
                {regionList.map((region) => (
                  <MenuItem key={region.id} value={region.id}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2}>
            <TextField
              id="speedy_ranking"
              name="speedy_ranking"
              label="Speedy Ranking"
              select
              onChange={formik.handleChange}
              value={formik.values.speedy_ranking}
              variant="outlined"
              fullWidth
            >
              <MenuItem selected value=" ">Todos</MenuItem>
              <MenuItem value={SpeedyRankingType.INITIATE_SPEEDER}>{t("courier_type." + SpeedyRankingType.INITIATE_SPEEDER)}</MenuItem>
              <MenuItem value={SpeedyRankingType.ADVANCED_SPEEDER}>{t("courier_type." + SpeedyRankingType.ADVANCED_SPEEDER)}</MenuItem>
            </TextField>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              Pesquisar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" type="reset">
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: "4px",
    marginRight: "4px",
  },
  drawerPaper: {
    width: "290px",
    padding: "16px",
    height: "50%",
    borderBottomLeftRadius: "10px",
  }
});