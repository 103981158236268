import { apiAxios as axios } from "../store/api/config";
import { getURL, urls } from "../store/api/urls";
import { IPageableParams, PagedResponse } from "./types/common";
import { AnnouncementResponse, AnnouncementResponseDetail, CreateUpdateAnnouncement, AnnouncementTemplate } from "./types/announcement";
import { parseISO, formatRFC3339 } from "date-fns";
import { IAnnouncemetFilterParams } from "../pages/announcement/FilterAnnouncement";

export interface IAnnouncementsService {
    getAnnouncements: (
        announcementParams: IAnnouncemetFilterParams,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<AnnouncementResponse>>;

    getAnnouncementById: (id: number) => Promise<AnnouncementResponseDetail>;

    sendAnnouncement: (announcement: CreateUpdateAnnouncement) => Promise<any>;

    replaceAnnouncement: (id: number, announcement: CreateUpdateAnnouncement) => Promise<any>;

    getAnnouncementTemplates: () => Promise<AnnouncementTemplate[]>;

    getAnnouncementsCourier: (
        courierId: number,
        pageParams: IPageableParams
    ) => Promise<PagedResponse<AnnouncementResponse>>;

}

const AnnouncementsService: IAnnouncementsService = {
    getAnnouncements: async (
        announcementParams: IAnnouncemetFilterParams,
        pageParams: IPageableParams
    ) => {
        const url = getURL(urls.ANNOUNCEMENTS, {
            title: announcementParams.title,
            send_date_after: announcementParams.send_date_after ? formatRFC3339(announcementParams.send_date_after) : null,
            send_date_before: announcementParams.send_date_before ? formatRFC3339(announcementParams.send_date_before) : null,
            expire_date_after: announcementParams.expire_date_after,
            expire_date_before: announcementParams.expire_date_before,
            screen: announcementParams.screen,
            announcement_type: announcementParams.announcement_type,
            delivery_for: announcementParams.delivery_for,
            ...pageParams,
        });
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then(response => {
                    const data: AnnouncementResponse[] = response.data.results.map(
                        (item: any) => {
                            return {
                                ...item,
                                id: Number(item.id),
                                region: item.region ? Number(item.region) : null,
                                send_date: item.send_date ? parseISO(item.send_date) : null,
                                expire_date: item.expire_date ? parseISO(item.expire_date) : null
                            };
                        }
                    );
                    const result: PagedResponse<AnnouncementResponse> = {
                        data: data,
                        count: response.data.count,
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

    getAnnouncementById: async (id: number) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${urls.ANNOUNCEMENTS}${id}/`)
                .then(response => {
                    const announcement: AnnouncementResponseDetail = {
                        ...response.data,
                        id: Number(response.data.id),
                        send_date: response.data.send_date ? parseISO(response.data.send_date) : null,
                        expire_date: response.data.expire_date ? parseISO(response.data.expire_date) : null
                    };
                    resolve(announcement);
                })
                .catch(error => reject(error));
        });
    },

    sendAnnouncement: async (announcement: CreateUpdateAnnouncement) => {
        return new Promise((resolve, reject) => {
            axios
                .post(urls.ANNOUNCEMENTS, announcement)
                .then(response => resolve(response.data))
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        });
    },

    replaceAnnouncement: async (id: number, announcement: CreateUpdateAnnouncement) => {
        return new Promise((resolve, reject) => {
            const deleteURL = `${urls.ANNOUNCEMENTS}${id}/`;
            axios.delete(deleteURL)
                .then(deleteResponse => {
                    axios
                        .post(urls.ANNOUNCEMENTS, announcement)
                        .then(response => resolve(response.data))
                        .catch(error => {
                            console.log(error);
                            reject(error);
                        });
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        });
    },

    getAnnouncementTemplates: async () =>{
        return new Promise((resolve, reject) => {
            axios
                .get(urls.NOTIFICATION_TEMPLATE)
                .then(response => resolve(response.data.results))
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        });
    },

    getAnnouncementsCourier: async (
        courierId: number,
        pageParams: IPageableParams
    ) => {
        const url = getURL(urls.ANNOUNCEMENTS, {
            couriers: courierId,
            ...pageParams,
        });
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then(response => {
                    const data: AnnouncementResponse[] = response.data.results.map(
                        (item: any) => {
                            return {
                                ...item,
                                id: Number(item.id),
                                region: item.region ? Number(item.region) : null,
                                send_date: item.send_date ? parseISO(item.send_date) : null,
                                expire_date: item.expire_date ? parseISO(item.expire_date) : null
                            };
                        }
                    );
                    const result: PagedResponse<AnnouncementResponse> = {
                        data: data,
                        count: response.data.count,
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },
};

export default AnnouncementsService;
