import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { $enum } from "ts-enum-util";
import { FormikHelpers, useFormik } from "formik";
import DatePicker from "react-datepicker";
import { CURRENT_DATE_TIME_FORMAT, DEFAULT_LOCALE } from "../../i18n";
import { GravityStatus } from "../../services/types/occurrences";

export interface IOccurrencesFilterParams {
  number: string,
  request_number: string,
  gravity: GravityStatus | null,
  requested_time_after: Date | null,
  requested_time_before: Date | null,
  blocked: boolean | null,
}

export const INITIAL_FILTER_PARAMS: IOccurrencesFilterParams = {
  number: "",
  request_number: "",
  gravity: null,
  requested_time_after: null,
  requested_time_before: null,
  blocked: null,
}

interface IOccurrencesFilterProps {
  params?: IOccurrencesFilterParams;
  onFilterChanged: (params: IOccurrencesFilterParams) => void;
}

export const OccurrencesFilter: React.FC<IOccurrencesFilterProps> = ({
  params = INITIAL_FILTER_PARAMS,
  onFilterChanged
}) => {
  const { t } = useTranslation();
  const formik = useFormik<IOccurrencesFilterParams>({
    initialValues: params,
    onSubmit: (values: IOccurrencesFilterParams, formikHelpers: FormikHelpers<IOccurrencesFilterParams>) => {
      onFilterChanged(values);
    },
    onReset: (values: IOccurrencesFilterParams, formikHelpers: FormikHelpers<IOccurrencesFilterParams>) => {
      onFilterChanged(INITIAL_FILTER_PARAMS);
      formikHelpers.setValues(INITIAL_FILTER_PARAMS);
    },
  });

  return (
    <div className="page-filter">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          <Grid item xl={2} md={3}>
            <DatePicker
              id="requested_time_after"
              name="requested_time_after"
              onChange={val => {
                formik.setFieldValue("requested_time_after", val);
              }}
              maxDate={new Date()}
              selected={formik.values.requested_time_after}
              startDate={formik.values.requested_time_after}
              endDate={formik.values.requested_time_before}
              selectsStart
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_TIME_FORMAT}
              isClearable
              autoComplete="off"
              timeCaption="Hora"
              showTimeSelect
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data e Hora - Início"
                variant="outlined"
                size="small"
                error={!!formik.errors.requested_time_after}
                helperText={formik.errors.requested_time_after}
                fullWidth />}
            />
          </Grid>

          <Grid item xl={2} md={3}>
            <DatePicker
              id="requested_time_before"
              name="requested_time_before"
              onChange={val => {
                formik.setFieldValue("requested_time_before", val);
              }}
              maxDate={new Date()}
              selected={formik.values.requested_time_before}
              startDate={formik.values.requested_time_after}
              endDate={formik.values.requested_time_before}
              minDate={formik.values.requested_time_after}
              selectsEnd
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_TIME_FORMAT}
              isClearable
              autoComplete="off"
              timeCaption="Hora"
              showTimeSelect
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data e Hora - Fim"
                variant="outlined"
                size="small"
                error={!!formik.errors.requested_time_before}
                helperText={formik.errors.requested_time_before}
                fullWidth />}
            />
          </Grid>

          <Grid item xl={2} md={2}>
            <TextField
              id="number"
              name="number"
              label="Número"
              onChange={formik.handleChange}
              value={formik.values.number}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xl={2} md={2}>
            <TextField
              id="request_number"
              name="request_number"
              label="Número da Solicitação"
              placeholder="Número da Solicitação EX: 10000001"
              onChange={formik.handleChange}
              value={formik.values.request_number}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xl={2} md={3}>
            <TextField
              id="blocked"
              name="blocked"
              label="Gerou Bloqueio"
              select
              onChange={formik.handleChange}
              value={formik.values.blocked}
              variant="outlined"
              fullWidth
            >
              <MenuItem selected value="">Todos</MenuItem>
              <MenuItem value={"true"} key={"true"}>Sim</MenuItem>
              <MenuItem value={"false"} key={"false"}>Não</MenuItem>
            </TextField>
          </Grid>

          <Grid item xl={2} md={3}>
            <TextField
              id="gravity"
              name="gravity"
              label="Gravidade"
              select
              onChange={formik.handleChange}
              value={formik.values.gravity}
              variant="outlined"
              fullWidth
            >
              <MenuItem selected value="">Todos</MenuItem>
              {$enum(GravityStatus).map((item) => (
                <MenuItem value={item} key={item}>
                  {t(`gravity_occurrences.${item}`)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              Pesquisar
            </Button>
          </Grid>

          <Grid item>
            <Button variant="contained" color="secondary" type="reset">
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
