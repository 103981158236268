import React from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import { FormHelperText, OutlinedInput } from "@material-ui/core";

interface IMultiSelectProps {
  label: string;
  placeholder: any;
  selectedList: any[number];
  optionList: any[number];
  onChange(arg0: unknown): void;
  name: string;
  error: any;
  helperText: any;
  required?: boolean;
}

export const MultiSelect: React.FC<IMultiSelectProps> = ({
  label,
  placeholder,
  selectedList,
  optionList,
  onChange,
  name,
  error,
  helperText,
  required,
}) => {
  return (
    <FormControl
      variant="outlined" 
      required={required}
      fullWidth
      error={error}
    >
      <InputLabel id={`${label}-label`}>
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-label`}
        multiple
        value={selectedList}
        onChange={(event) => onChange(event)}
        name={name}
        variant="outlined"
        input={<OutlinedInput label={label} />}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          }
        }}
        renderValue={(selected) => {
          if (selected)
            return (
              <div>
                {(selected as number[]).length === 0 ? (
                  <span>{placeholder}</span>
                ) : (
                  (selected as number[]).map((value, index) => {
                    if (index < 2)
                      return (
                        <Chip
                          className="chip"
                          key={value}
                          label={
                            optionList.length
                              ? optionList.find(
                                  (option: { id: number }) =>
                                    option.id === value
                                ).name
                              : ""
                          }
                        />
                      );
                    if (index === 2) return <Chip className="chip" key={value} label={"..."} />;
                    return null;
                  })
                )}
              </div>
            );
        }}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {optionList.map(({ id, name }: { id: number; name: string }) => (
          <MenuItem
            key={id}
            value={id}
            // style={getStyles(name, personName, theme)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
      {error ? <FormHelperText>Campo obrigatório</FormHelperText> : ''}
    </FormControl>
  );
};