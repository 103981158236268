import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";
import { StoreResponse } from "../../../services/types/customer";
import { formatPhoneNumber } from "../../../utils/formatters";
import { Alert } from "@material-ui/lab";
import CustomerService from "../../../services/customerService";
import { City } from "../../../services/types/common";
import CityService from "../../../services/cityService";
import AddEditStoreDialog, { AddEditStoreDialogHandle } from "../AddEditStoreDialog";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import SettingsIcon from '@material-ui/icons/Settings';
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../../components/ConfirmationDialog";
import { Block, Check } from "@material-ui/icons";
import AuthService from "../../../services/authService";
import MaxDeliveryTripDialog from "./MaxDeliveryTripDialog";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import CustomerStoreDetailsDialog from "./CustomerStoreDetailsDialog";

interface ICustomerDetailStores {
  customerId: number;
}

export const CustomerDetailStores: React.FC<ICustomerDetailStores> = ({ customerId }) => {

  const [cityList, setCityList] = useState<City[]>([]);

  const tableRef = React.useRef<MaterialTableProps<StoreResponse>>();
  const addEditStoreDialog = React.createRef<AddEditStoreDialogHandle>();
  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
  const [storeSelected, setStoreSelected] = React.useState<StoreResponse>({} as StoreResponse);
  const [open, setOpen] = React.useState<boolean>(false);
  const [openDetails, setOpenDetails] = React.useState<boolean>(false);

  useEffect(() => {
    const getCities = async () => {
      const response = await CityService.getCities();
      setCityList(response);
    };

    getCities();
  }, []);

  const loadData = (query: Query<StoreResponse>) =>
    new Promise<QueryResult<StoreResponse>>((resolve, reject) => {
      CustomerService.getStores(undefined, customerId, undefined, undefined,
        PageableParams.fromQuery(query)).then(response => {
          resolve({
            data: response.data,
            page: query.page,
            totalCount: response.count,
          });
        }).catch((error) => reject(error));
    });

  const refresh = () => {
    console.warn("tableRef.current", tableRef.current);
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({
        page: 0,
      } as Query<StoreResponse>);
    }
  };

  const handleNewStore = () => {
    addEditStoreDialog.current?.add(customerId)
      .then((store) => {
        CustomerService.createStore(store)
          .then((response) => {
            refresh();
            addEditStoreDialog.current?.hide();
            NotificationManager.success("Loja salva com sucesso.", "Nova Loja");
          })
          .catch((error) => {
            const { status, data } = error.response;
            const errorMsg = "Ocorreu um erro ao salvar a Loja";
            let remainingErrorMsgs;
            if (status === 400) {
              remainingErrorMsgs = addEditStoreDialog.current?.showFieldErrors(data);
            }
            NotificationManager.error(remainingErrorMsgs || errorMsg, "Nova Loja");
          });
      })
      .catch(error => { });
  }

  const handleEditStore = (store: StoreResponse) => {
    addEditStoreDialog.current?.edit(store)
      .then((updatedStore) => {
        CustomerService.updateStore(store.id, updatedStore)
          .then((response) => {
            refresh();
            addEditStoreDialog.current?.hide();
            NotificationManager.success("Loja salva com sucesso.", "Editar Loja");
          })
          .catch((error) => {
            const { status, data } = error.response;
            const errorMsg = "Ocorreu um erro ao salvar a Loja";
            let remainingErrorMsgs;
            if (status === 400) {
              remainingErrorMsgs = addEditStoreDialog.current?.showFieldErrors(data);
            }
            NotificationManager.error(remainingErrorMsgs || errorMsg, "Editar Loja");
          });
      })
      .catch(error => { });
  }

  const handleDeleteStore = (store: StoreResponse) => {
    confirmationDialog.current?.confirm(
      "Excluir loja",
      <Typography>Você tem certeza que deseja <strong>Excluir</strong> esta Loja?</Typography>,
      "Excluir",
      "Cancelar")
      .then((updatedStore) => {
        CustomerService.deleteStore(store.id)
          .then((response) => {
            refresh();
            confirmationDialog.current?.hide();
            NotificationManager.success("Loja excluída com sucesso.", "Excluir Loja");
          })
          .catch((error) => {
            const { status, data } = error.response;
            let errorMsg = "Ocorreu um erro ao excluir a Loja";
            const remainingErrorMsgs = [];
            if (status === 400) {
              for (var key in data) {
                var value = data[key];
                remainingErrorMsgs.push(value);
              }
            }

            if (remainingErrorMsgs.length > 0) {
              errorMsg = remainingErrorMsgs.join(". \n");
            }
      
            NotificationManager.error(errorMsg, "Excluir Loja");
          });
      })
      .catch(error => { });
  }

  const handleActivateStore = (store: StoreResponse) => {
    confirmationDialog.current?.confirm(
      "Ativar Loja",
      <Typography>Você tem certeza que deseja <strong>Ativar</strong> esta Loja?</Typography>,
      "Ativar",
      "Cancelar")
      .then(() => {
        CustomerService.activateStore(store.id)
          .then((response) => {
            NotificationManager.success("Loja Ativada com sucesso.", "Loja");
            refresh();
            confirmationDialog.current?.hide();
          })
          .catch((error) => {
            NotificationManager.error("Não foi possível Ativar a Loja.", "Loja");
          });
      })
      .catch(() => { });
  };

  const handleDeactivateStore = (store: StoreResponse) => {
    confirmationDialog.current?.confirm(
      "Desativar Loja",
      <Typography>Você tem certeza que deseja <strong>Desativar</strong> esta Loja?</Typography>,
      "Desativar",
      "Cancelar"
    ).then(() => {
      CustomerService.deactivateStore(store.id)
        .then((response) => {
          NotificationManager.success("Loja Desativada com sucesso.", "Loja");
          refresh();
          confirmationDialog.current?.hide();
        })
        .catch((error) => {
          NotificationManager.error("Não foi possível Desativar a Loja.", "Loja");
        });
    }).catch(() => { });
  };

  const handleConfigureStore = (store: StoreResponse) => {
    if(store){
      setStoreSelected(store);
      setOpen(true);
    }    
  };

  const handleOpenStore = (store: StoreResponse) => {
    if(store){
      setStoreSelected(store);
      setOpenDetails(true);
    }    
  };

  return (
    <React.Fragment>
      <AddEditStoreDialog ref={addEditStoreDialog} cityList={cityList} />

      <ConfirmationDialog ref={confirmationDialog} />

      <MaxDeliveryTripDialog modal={{ open: open, setOpen: setOpen }} store={storeSelected} refresh={refresh} />

      <CustomerStoreDetailsDialog modal={{ open: openDetails, setOpen: setOpenDetails }} store={storeSelected} refresh={refresh} /> 

      <Grid container xs={12} spacing={2} justify="flex-end">
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleNewStore}
          >
            Nova Loja
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            tableRef={tableRef}
            columns={[
              { field: "id", hidden: true, width: 120 },
              { field: "name", title: "Nome Fantasia", defaultSort: "asc" },
              { field: "contact_name", title: "Pessoa para Contato" },
              {
                field: "phonenumber", title: "Telefone",
                render: (rowData, type) => formatPhoneNumber(rowData.phonenumber)
              },
              {
                field: "city", title: "Cidade",
                render: (rowData, type) => cityList.find(item => item.id === rowData.city)?.name
              },
              { field: "street", title: "Rua" },
              { field: "district", title: "Bairro" },
              {
                field: "is_active",
                title: "Situação",
                render: (rowData, type) => (
                  <Alert
                    icon={false}
                    severity={rowData.is_active ? "success" : "error"}
                  >
                    {rowData.is_active ? "Ativo" : "Inativo"}
                  </Alert>
                ),
              },
            ]}
            actions={AuthService.hasAuthorization("change_store") ? [
              (rowData) => rowData.is_active ?
                {
                  icon: () => <Block />,
                  tooltip: 'Desativar',
                  onClick: (event, rowData) => handleDeactivateStore(rowData as StoreResponse)
                } : {
                  icon: () => <Check />,
                  tooltip: 'Ativar',
                  onClick: (event, rowData) => handleActivateStore(rowData as StoreResponse)
                }
              ,
              {
                icon: () => <EditOutlinedIcon />,
                tooltip: 'Editar',
                onClick: (event, rowData) => handleEditStore(rowData as StoreResponse)
              },
              {
                icon: () => <DeleteOutlinedIcon />,
                tooltip: 'Excluir',
                onClick: (event, rowData) => handleDeleteStore(rowData as StoreResponse)
              },
              {
                icon: () => <SettingsIcon />,
                tooltip: 'Configurar Quantidades de Entregas',
                onClick: (event, rowData) => handleConfigureStore(rowData as StoreResponse)
              },
              {
                icon: () => <VisibilityOutlined />,
                tooltip: 'Ver Detalhes',
                onClick: (event, rowData) => handleOpenStore(rowData as StoreResponse)
              }
            ] : []}
            data={loadData}
            components={DEFAULT_TABLE_COMPONENTS}
            localization={TABLE_L10N_PTBR}
            options={DEFAULT_TABLE_OPTIONS}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
