/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import GoogleMapReact from "google-map-react";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import {
  Paper,
  makeStyles,
  Theme,
  createStyles,
  Typography,
} from "@material-ui/core";
import { NoteAddOutlined } from "@material-ui/icons";
import { locationByIP } from "../../utils/getLocation";
interface ICoord {
  lat: number;
  lng: number;
}

interface IProps {
  setGeoJson(value?: {}): void;
  defaultCoords?: any;
  view?: boolean;
}

const GeoJsonMap: React.FC<IProps> = ({ setGeoJson, defaultCoords, view }) => {
  const [zoom] = useState(13);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [files, setFiles] = useState<any | null>(null);
  const [update, setUpdate] = useState(0);
  const [fileName, setFileName] = useState("");
  const classes = useStyles();
  const key = process.env.REACT_APP_MAPS_KEY || "";

  const handleApiLoaded = (map: any, maps: any) => {
    let bounds = new maps.LatLngBounds();
    let coords = [];
    let coordsModify = [];
    if(files) { 
      coords = files?.features ? files?.features[0]?.geometry?.coordinates[0] : files?.geometries[0]?.coordinates[0][0];
      coordsModify = coords.map((item: any) => {
        return { lat: item[1], lng: item[0] };
      }); 

    } else if(defaultCoords) {
      
      coords = defaultCoords.features[0]?.geometry?.coordinates
      
      coordsModify = coords[0][0].map((item: any) => {
        return { lat: item[1], lng: item[0] };
      }); 
    }
      if (coordsModify?.length) {
    
        const regionPolygon = new maps.Polygon({
          paths: coordsModify,
          strokeColor: "#000",
          strokeOpacity: 0.3,
          strokeWeight: 2,
          fillColor: "#000",
          fillOpacity: 0.5,
        });

        for (let i = 0; i < coordsModify?.length; i++) {
          bounds.extend(coordsModify[i]);
        }

        map.fitBounds(bounds);

        setCenter({
          lat: bounds.getCenter().lat(),
          lng: bounds.getCenter().lng(),
        });
        regionPolygon.setMap(map)

        setUpdate(0);
        setGeoJson(files);
      } else if(files) {
        NotificationManager.error("Arquivo Inválido");
        setFiles(null);
        setFileName("");
        setUpdate(0);
      }
    
  };

  const readURL = (event: any) => {
    const input = event.target;
    const fileReader = new FileReader();
    if (input.files && input.files[0]) {
      setFileName(input.files[0].name);
      fileReader.readAsText(input.files[0], "UTF-8");
      fileReader.onload = (e) => {
        //@ts-ignore
        setFiles(JSON.parse(e?.target?.result));
        setUpdate(1);
      };
    }
  };

  const getPosition = useCallback(async () => {
    locationByIP().then((coords) => {
      setCenter({ lat: coords.lat, lng: coords.lng });
      }).catch((error) => {
        //ignore
      });
  }, []);

  useEffect(() => {
    if (!defaultCoords) {
      getPosition();
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "4px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {!view && (
        <Paper className={classes.fileInput}>
        <label htmlFor="file">
          <Typography variant="subtitle1">
            {fileName.length ? fileName : "Selecione o arquivo"}
          </Typography>
          <NoteAddOutlined />
        </label>


        <input
          id="file"
          type="file"
          accept=".json,.geojson"
          onChange={(e) => readURL(e)}
        />
      </Paper>
      )}
      

      <GoogleMapReact
        key={update}
        bootstrapURLKeys={{ key: key }}
        defaultCenter={center}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        yesIWantToUseGoogleMapApiInternals
        defaultZoom={zoom}
        center={center}
        options={{
          fullscreenControl: false,
          zoomControl: true,
          scrollwheel: false,
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileInput: {
      position: "absolute",
      zIndex: 22,

      padding: 12,
      left: "20px",
      bottom: "20px",

      borderRadius: "4px",

      "& label": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "& h6": {
          borderBottom: "solid 1px #000",
          paddingRight: "30px",
        },
      },

      "& input": {
        display: "none",
      },
    },
  })
);

export default GeoJsonMap;
