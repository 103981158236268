import { apiAxios as axios } from "../store/api/config";
import { formatRFC3339, parseISO } from "date-fns";
import { urls } from "../store/api/urls";
import { PagedResponse } from "./types/common";
import { TopCouriersResponse } from "./types/topCouriers";

export interface IReportService {

    getTopCouriersEntries: (
        numberOfRegisters: number | null,
        startDate: Date | null,
        endDate: Date | null,
        region: number | null,
        vehicleType: number | null,
        minimumStores: number | null,
    ) => Promise<PagedResponse<TopCouriersResponse>>;

}

const ReportService: IReportService = {

    getTopCouriersEntries: (
        numberOfRegisters: number | null,
        startDate: Date | null,
        endDate: Date | null,
        region: number | null,
        vehicleType: number | null,
        minimumStores: number | null,
    ) => {
        const url = `${urls.DELIVERY_REQUEST}top_couriers/`;
        let dateEndDefault = new Date();
        dateEndDefault.setDate(dateEndDefault.getDate() - 100);
        const object = {
            number_of_registers: numberOfRegisters ? Number(numberOfRegisters) : 10,
            start_date: startDate ? formatRFC3339(startDate) : new Date(),
            end_date: endDate ? formatRFC3339(endDate) : new Date(dateEndDefault),
            region: region ? Number(region) : null,
            vehicle_type: vehicleType ? vehicleType : null,
            minimum_stores: minimumStores ? Number(minimumStores) : null
        }
        return new Promise((resolve, reject) => {
            axios
                .post(url, object)
                .then(response => {
                    const data: TopCouriersResponse[] = response.data.map(
                        (item: any) => {
                            return {
                                ...item,
                                name: item.name,
                                created_at: parseISO(item.created_at),
                                payment_date: item.payment_date
                                    ? parseISO(item.payment_date)
                                    : null,
                                region_name: item.region_name,
                                vehicle_type: item.vehicle_type,
                                number_of_registers: Number(item.number_of_registers),
                            };
                        }
                    );
                    const result: PagedResponse<TopCouriersResponse> = {
                        data: data,
                        count: response.data.length,
                    };
                    resolve(result);
                })
                .catch(error => reject(error));
        });
    },

}

export default ReportService;