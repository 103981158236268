import React from "react";
import { Popup } from "react-leaflet";
import { Typography, makeStyles } from "@material-ui/core";
import theme from "../../styles/theme";
import { formatPhoneNumber } from "../../utils/formatters";
import { StoreResponse } from "../../services/types/customer";

interface IProps {
  info: StoreResponse;
}

const StorePopup = ({ info }: IProps) => {
  const classes = useStyles();

  return (
    <Popup className={classes.infoBoxContainer}>
      <div className={classes.infoBoxHeader}>
        <div className={classes.infoBoxHeaderInfo}>
          <Typography variant="h2">
            {info.name}
          </Typography>
        </div>
      </div>
      <div className={classes.infoBoxContent}>
      <div className={classes.infoBoxPhone}>
          <Typography variant="body2" className={classes.marginTypo}>
            Telefone
          </Typography>
          <Typography variant="h3">
            {formatPhoneNumber(info.phonenumber)}
          </Typography>
        </div>
        <div className={classes.infoBoxPhone}>
          <Typography variant="body2" className={classes.marginTypo}>
            Contato
          </Typography>
          <Typography variant="h3">
            {info.contact_name}
          </Typography>
        </div>
      </div>
    </Popup>
  )
}


const useStyles = makeStyles({
  marginTypo: {
    margin: "8px 0px !important",
  },
  info: {
    position: "absolute",
    visibility: "hidden",
    marginLeft: "40px",
    marginTop: "-150px",
    transitionDelay: ".2s",
    zIndex: 99,
    "&:hover": {
      visibility: "visible",
    },
    zoom: 0.8,
  },
  status: {
    position: "absolute",
    bottom: -90,
    left: -5,
    zIndex: -1,
  },
  infoBoxContainer: {
    width: "300px",
    height: "auto",
  },

  infoBoxHeader: {
    display: "flex",

    "& img": {
      width: "60px",
      height: "60px",
      borderRadius: "50%",
    },
  },
  infoBoxHeaderInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  infoBoxContent: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "0px",
  },
  infoBoxPhone: {},
  infoBoxContact: {
    marginTop: theme.spacing(1),
    display: "flex",
  },
});

export default StorePopup;
