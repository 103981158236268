import React from "react";
import {
    Grid,
    Button,
    TextField,
    MenuItem,
} from "@material-ui/core";
import { FormikHelpers, useFormik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import * as Yup from "yup";
import { useHistory } from "react-router";
import { encodeURLParams } from "../../utils/utils";
import { DEFAULT_LOCALE } from "../../i18n";
import DatePicker from "react-datepicker";

export interface IReportCustomersActiveParams {
    region: number;
    customer: number;
    date_after: Date;
    date_before: Date;
};
var today = new Date();
var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
export const INITIAL_FILTER_PARAMS: IReportCustomersActiveParams = {
    region: 0,
    customer: 0,
    date_after: lastDayOfMonth,
    date_before: firstDayOfMonth
};

interface IReportCustomersActiveFilterProps {
    params?: IReportCustomersActiveParams;
    regionList: any[];
    customerList: any[];
    onRegionChanged: (region: number) => void;
    onFilterChanged: (params: IReportCustomersActiveParams) => void;
};

const validationSchema = Yup.object().shape({});

export const ReportCustomersActiveFilter: React.FC<IReportCustomersActiveFilterProps> = ({ params = INITIAL_FILTER_PARAMS, regionList, customerList, onRegionChanged, onFilterChanged }) => {
    const history = useHistory();

    React.useEffect(() => {
        history.replace(encodeURLParams(history.location.pathname, params));
    }, [history, params]);

    const formik = useFormik<IReportCustomersActiveParams>({
        initialValues: params,
        onSubmit: (values: IReportCustomersActiveParams, formikHelpers: FormikHelpers<IReportCustomersActiveParams>) => {
            onFilterChanged(values);
        },
        onReset: (values: IReportCustomersActiveParams, formikHelpers: FormikHelpers<IReportCustomersActiveParams>) => {
            onFilterChanged(INITIAL_FILTER_PARAMS);
            formikHelpers.setValues(values);
        },
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });

    return (
        <div className="page-filter">
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Grid container spacing={2}>
                    <Grid item lg={2} md={3} sm={4} xs={12}>
                        <DatePicker
                            id="date_after"
                            name="date_after"
                            onChange={val => {
                                var today = new Date(val as Date);
                                var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                                formik.setFieldValue("date_after", val);
                                formik.setFieldValue("date_before", lastDayOfMonth);
                            }}
                            maxDate={new Date()}
                            selected={formik.values.date_after}
                            startDate={formik.values.date_after}
                            endDate={formik.values.date_before}
                            selectsStart
                            locale={DEFAULT_LOCALE}
                            todayButton="Mês e Ano"
                            autoComplete="off"
                            wrapperClassName="MuiFormControl-fullWidth"
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            required
                            customInput={<TextField
                                label="Mês e Ano"
                                variant="outlined"
                                size="small"
                                required
                                error={!!formik.errors.date_after}
                                helperText={formik.errors.date_after}
                                fullWidth
                            />}
                        />
                    </Grid>
                    <Grid item lg={2} md={3} sm={4} xs={12}>
                        <TextField
                            id="region"
                            name="region"
                            label="Região"
                            select
                            onChange={(e) => {
                                formik.handleChange(e);
                                onRegionChanged(Number(e.target.value));
                                formik.setFieldValue("customer", 0);
                                
                            }}
                            value={formik.values.region}
                            variant="outlined"
                            fullWidth
                            >
                            <MenuItem selected value={0}>Todas</MenuItem>
                            {regionList.map((region) => (
                                <MenuItem key={region.id} value={region.id}>
                                {region.name} - {region.state}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item lg={2} md={3} sm={4} xs={12}>
                        <TextField
                        id="customer"
                        name="customer"
                        label="Cliente"
                        select
                        value={formik.values.customer}
                        onChange={formik.handleChange}
                        disabled={formik.values.region === 0}
                        variant="outlined"
                        fullWidth
                        >
                        <MenuItem selected={true} value={0}>Todos</MenuItem>
                        {customerList.map((customer) => (
                            <MenuItem key={customer.id} value={customer.id}>
                            {customer.trade_name}
                            </MenuItem>
                        ))}

                        </TextField>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" type="submit">
                            Pesquisar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};