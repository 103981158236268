export enum RemittanceStatus {
    NOT_SENT = "NOT_SENT",
    AWAITING_SETTLEMENT = "AWAITING_SETTLEMENT",
    SETTLED = "SETTLED",
    PARTIALLY_SETTLED = "PARTIALLY_SETTLED",
}

export enum TransactionStatus {
    CREATED = "CREATED",
    CONFIRMED = "CONFIRMED",
    CANCELED = "CANCELED",
}

export interface RemittanceResponse {
    id: number;
    number: string;
    sent_date: Date;
    liquidate_date: Date;
    transactions_count: number;
    value: number;
    taxes: number;
    total: number;
    paid_value: number;
    status: RemittanceStatus;
}
