import React from "react";
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    FormControlLabel,
    Checkbox,
    CardContent,
    Card,
    Grid,
} from "@material-ui/core";
import { formatCPFCNPJ, formatPhoneNumber } from "../../../utils/formatters";
import { CustomerDetails } from "../../../services/types/customer";

interface ICustomerDetailCardProps {
    customer: CustomerDetails;
}

export const CustomerDetailData: React.FC<ICustomerDetailCardProps> = ({ customer }) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h2">Informações gerais</Typography>
                        <Table className="table-bordered">
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Razão Social / Nome</Typography>
                                        <Typography variant="subtitle1">
                                            {customer.corporate_name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>Nome Fantasia</Typography>
                                        <Typography variant="subtitle1">
                                            {customer.trade_name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>CNPJ / CNPJ</Typography>
                                        <Typography variant="subtitle1">
                                            {customer.registration_number ? formatCPFCNPJ(customer.registration_number) : "---"}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Contato</Typography>
                                        <Typography variant="subtitle1">
                                            {customer.contact_name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>Telefone</Typography>
                                        <Typography variant="subtitle1">
                                            {customer.phonenumber ? formatPhoneNumber(customer.phonenumber) : "---"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        <Typography>E-mail</Typography>
                                        <Typography variant="subtitle1">
                                            {customer.email}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Grid container spacing={2}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="allow_incentive_dynamics"
                                            checked={customer.allow_incentive_dynamics}
                                            disabled
                                        />
                                    }
                                    label="Autorizar Dinâmica"
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="skip_customer_actions"
                                            checked={customer.skip_customer_actions}
                                            disabled
                                        />
                                    }
                                    label="Liberar e Finalizar Pedidos Automaticamente"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h2">Endereço fiscal</Typography>
                        <Table className="table-bordered">
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography>CEP</Typography>
                                        <Typography variant="subtitle1">
                                            {customer.fiscal_address_postal_code || "-"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>Rua</Typography>
                                        <Typography variant="subtitle1">
                                            {customer.fiscal_address_street || "-"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>Número</Typography>
                                        <Typography variant="subtitle1">
                                            {customer.fiscal_address_number || "-"}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Complemento</Typography>
                                        <Typography variant="subtitle1">
                                            {customer.fiscal_address_commplement || "-"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>Bairro</Typography>
                                        <Typography variant="subtitle1">
                                            {customer.fiscal_address_district || "-"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        <Typography>Cidade</Typography>
                                        <Typography variant="subtitle1">
                                            {customer.fiscal_address_city?.name} -{" "}
                                            {
                                                customer.fiscal_address_city
                                                    ?.state_initials
                                            }
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
        </React.Fragment>
    );
};
