import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { Typography, Grid, Card, CardMedia, Chip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import numbro from "numbro";
import { mapDeliveryRequestStatusToSeverity } from "../../../requestsHistory/deliveryRequestUtils";
import { format } from "date-fns";
import { CURRENT_DATE_TIME_FORMAT } from "../../../../i18n";
import { DeliveryRequest, Incentive } from "../../../../services/types/deliveryRequest";
import { formatPhoneNumber } from "../../../../utils/formatters";
import SecurityIcon from "@material-ui/icons/Security";
import clsx from "clsx";

const useStyles = makeStyles({
  status: {
    display: "inline-flex",
    padding: "0px 16px",
    marginBottom: "4px",
    borderRadius: "4px",
  },
  even_row: {
    "background-color": "#F1F5F9",
    padding: "16px",
  },
  row: {
    padding: "16px",
  },
  chipSuccess: {
    color: "#4caf50",
    border: "1px solid #4caf50",
  },
  chipWarning: {
    color: "#ff9800",
    border: "1px solid #ff9800",
  },
  chipDanger: {
    color: "#f44336",
    border: "1px solid #f44336",
  }
});

interface IProps {
  children?: ReactNode;
  data: DeliveryRequest;
}

export default function CustomTable({ children, data }: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const calcIncentive = (incentive: Incentive) => {
    let customerValue = 0;
    let speedyValue = 0;

    if (incentive) {
      customerValue = incentive.customer_value ? incentive.customer_value : customerValue;
      speedyValue = incentive.speedy_value ? incentive.speedy_value : speedyValue;
    }

    return customerValue + speedyValue;
  }

  const formatIncentive = (incentive: Incentive) => {
    const total = calcIncentive(incentive);
    let result = "";
    if (total > 0) {
      result = ` + ${numbro(total).formatCurrency()} (Incentivo)`;
    }
    return result;
  }

  function formatAddress(address: any): string {
    let result: string = "";
    if (address) {
      let address_components = [];

      if (address.street) {
        address_components.push(address.street);
      }

      if (address.address_number) {
        address_components.push(address.address_number);
      }

      if (address.district) {
        address_components.push(address.district);
      }

      if (address.address_complement) {
        address_components.push(address.address_complement);
      }

      if (address.address_reference) {
        address_components.push(address.address_reference);
      }

      result = address_components.join(", ");
      if (address.postal_code) {
        if (result.length) {
          result += "\n";
        }
        result += `CEP: ${address.postal_code}`;
      }

      if (address.city) {
        let city = address.city;

        if (address.state) {
          city += " - " + address.state;
        }

        if (result.length) {
          result += "\n" + city;
        }
      }
    }
    return result;
  };

  const checkInsurance = (delivery: DeliveryRequest) => {
    if(!delivery.canceled_on_insurance_service && !delivery.registered_on_insurance_service){
      return "Sem Seguro";
    }else if(!delivery.canceled_on_insurance_service && delivery.registered_on_insurance_service){
      return "Com Seguro";
    }else if(delivery.canceled_on_insurance_service && delivery.registered_on_insurance_service){
      return "Seguro Cancelado";
    };
  };

  return (
    <Card>
      <CardMedia>
        <Grid container spacing={2} className={classes.row}>
          <Grid item xl={2} md={2} sm={4} xs={12}>
            <Typography variant="body1">Solicitação</Typography>
            <Typography variant="subtitle1">{data?.request_number}</Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={4} xs={12}>
            <Typography variant="body1">Pedido</Typography>
            <Typography variant="subtitle1">{data?.order_number}</Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={4} xs={12}>
            <Typography variant="body1">Tipo de solicitação</Typography>
            <Typography variant="subtitle1">
              {data?.manual_creation ? "Manual" : "Busca"}
            </Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={4} xs={12}>
            <Typography variant="body1">Situação</Typography>
            <Alert
              severity={mapDeliveryRequestStatusToSeverity(data?.status)}
              className={classes.status}
              icon={false}
            >
              {t("delivery_request_status." + data?.status)}{data?.refunded ? " e Estornada" : ""}
            </Alert>
          </Grid>
          <Grid item xl={2} md={2} sm={4} xs={12}>
            <Typography variant="body1">Tipo de Compartimento</Typography>
            <Typography variant="subtitle1">
              {data?.compartment_type ? t("vehicle_load_compartment_type." + data?.compartment_type) : "---"}
            </Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={4} xs={12}>
            <Typography variant="body1">Possui retorno</Typography>
            <Typography variant="subtitle1">
              {data?.return_type === "none" ? "Não" : "Sim"}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.even_row}>
          <Grid item xl={2} md={2} sm={4} xs={12}>
            <Typography variant="body1">Valor da Entrega</Typography>
            <Typography variant="subtitle1">
              {data?.delivery_value !== null ? numbro(data?.delivery_value + calcIncentive(data?.incentive_json)).formatCurrency() : "---"}
            </Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={4} xs={12}>
            <Typography variant="body1">Valor Mínimo</Typography>
            <Typography variant="subtitle1">
              {data?.minimum_value !== null ? numbro(data?.minimum_value).formatCurrency() : "---"}
              {formatIncentive(data?.incentive_json)}
            </Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={4} xs={12}>
            <Typography variant="body1">Valor do deslocamento</Typography>
            <Typography variant="subtitle1">
              {data?.additional_km_value !== null ? numbro(data?.additional_km_value).formatCurrency() : "---"}
            </Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={4} xs={12}>
            <Typography variant="body1">Valor de retorno</Typography>
            <Typography variant="subtitle1">
              {data?.return_value !== null ? numbro(data?.return_value).formatCurrency() : "---"}
            </Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={4} xs={12}>
            <Typography variant="body1">Valor do entregador</Typography>
            <Typography variant="subtitle1">
              {data?.courier_value !== null ? numbro(data?.courier_value).formatCurrency() : "---"}
              {formatIncentive(data?.incentive_json)}
            </Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={4} xs={12}>
            <Typography variant="body1">Valor da Speedy</Typography>
            <Typography variant="subtitle1">
              {data?.speedy_value !== null ? numbro(data?.speedy_value).formatCurrency() : "---"}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.row}>
        
          <Grid item xl={2} md={2} sm={3} xs={12}>
            <Typography variant="body1">Seguro</Typography>
            <Typography variant="subtitle1">
              <Chip icon={<SecurityIcon />} variant="outlined" className={clsx({
                  [classes.chipSuccess]: !data.canceled_on_insurance_service && data.registered_on_insurance_service,
                  [classes.chipWarning]: !data.canceled_on_insurance_service && !data.registered_on_insurance_service,
                  [classes.chipDanger]: data.canceled_on_insurance_service && data.registered_on_insurance_service,
              })} label={checkInsurance(data)} color="secondary" />
            </Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={3} xs={12}>
            <Typography variant="body1">Distância</Typography>
            <Typography variant="subtitle1">
              {numbro(data?.distance).format() + " Km"}
            </Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={3} xs={12}>
            <Typography variant="body1">Data da Solicitação</Typography>
            <Typography variant="subtitle1"> {data?.requested_time ? format(data?.requested_time, CURRENT_DATE_TIME_FORMAT) : "---"}</Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={3} xs={12}>
            <Typography variant="body1">Previsão de Entrega</Typography>
            <Typography variant="subtitle1"> {data?.estimated_delivery_time ? format(data?.estimated_delivery_time, CURRENT_DATE_TIME_FORMAT) : "---"}</Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={3} xs={12}>
            <Typography variant="body1">Prazo p/ Entregador</Typography>
            <Typography variant="subtitle1"> {data?.estimated_delivery_time_after_release ? format(data?.estimated_delivery_time_after_release, CURRENT_DATE_TIME_FORMAT) : "---"}</Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={3} xs={12}>
            <Typography variant="body1">Obs. Se encontrar com o Entregador</Typography>
            <Typography variant="subtitle1"> {data?.destination_address.delivery_observations ? data?.destination_address.delivery_observations : "---"}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.even_row}>
          <Grid item xl={6} md={6} sm={12} xs={12}>
            <Typography variant="body1">Endereço de Origem</Typography>
            <Typography variant="subtitle1">
              {formatAddress(data?.origin_address)
                .split("\n")
                .map((value, index) => {
                  return <div key={index}>{value}</div>;
                })}
            </Typography>
          </Grid>
          <Grid item xl={6} md={6} sm={12} xs={12}>
            <Typography variant="body1">Endereço de Destino</Typography>
            <Typography variant="subtitle1">
              {formatAddress(data?.destination_address)
                .split("\n")
                .map((value, index) => {
                  return <div key={index}>{value}</div>;
                })}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.row}>
          <Grid item xl={4} md={4} sm={8} xs={12}>
            <Typography variant="body1">Nome do Consumidor</Typography>
            <Typography variant="subtitle1">{data?.consignee}</Typography>
          </Grid>
          <Grid item xl={2} md={2} sm={4} xs={12}>
            <Typography variant="body1">Telefone do Consumidor</Typography>
            <Typography variant="subtitle1">{data ? formatPhoneNumber(data?.consignee_phone_number) : "---"}</Typography>
          </Grid>
          <Grid item xl={6} md={6} sm={12} xs={12}>
            <Typography variant="body1">
              Instruções/Observações de Entrega
            </Typography>
            <Typography variant="subtitle1">{data?.destination_address.delivery_observations ? data?.destination_address.delivery_observations : "---"}</Typography>
          </Grid>
        </Grid>
      </CardMedia>
    </Card>
  );
}
