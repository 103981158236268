import React from "react";
import { makeStyles, Theme, createStyles, Avatar } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircleOutlined";
//import { apiAxios } from "../store/api/config";
import { ImgLoader } from "./ImgLoader";

interface IAvatarProps {
  img?: string | null;
  text?: string | null;
  textBackgroundColor?: string | null;
  size: "lg" | "md" | "sm" | "xs";
  useCache?: boolean;
  className?: string | null;
}

export const CustomAvatar: React.FC<IAvatarProps> = ({ img = null, text = null, size = "md", useCache = true, className = null }) => {
  const classes = useStyles();

  let styleClassName = "";
  switch (size) {
    case "lg":
      styleClassName = classes.large;
      break;
    case "md":
      styleClassName = classes.medium;
      break;
    case "sm":
      styleClassName = classes.small;
      break;
    default:
      styleClassName = classes.extraSmall;
      break;
  }

  if (className) {
    styleClassName += ` ${className}`;
  }

  if (img) {
    return (
      <Avatar alt="Foto" className={styleClassName}>
        <ImgLoader src={String(img)} useCache={useCache} className={styleClassName}>
          <AccountCircleIcon className={styleClassName} />
        </ImgLoader>
      </Avatar>
    );
  } else if (text) {
    return (
      <Avatar alt={text} className={styleClassName}>
        {text}
      </Avatar>
    );
  } else {
    return (
      <AccountCircleIcon className={styleClassName} />
    );
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    extraSmall: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    small: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    medium: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    large: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
  })
);

