import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DeliveryRequestStatus } from "../../../services/types/deliveryRequest";
import { $enum } from "ts-enum-util";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import { CURRENT_DATE_TIME_FORMAT, DEFAULT_LOCALE } from "../../../i18n";

export interface ICourierOrderFilterParams {
  request_number: string,
  order_number: string,
  status: DeliveryRequestStatus | null,
  customer_name: string,
  store_name: string,
  requested_time_after: Date | null,
  requested_time_before: Date | null,
}

export const INITIAL_FILTER_PARAMS: ICourierOrderFilterParams = {
  request_number: "",
  order_number: "",
  status: null,
  customer_name: "",
  store_name: "",
  requested_time_after: null,
  requested_time_before: null,
}

interface ICourierOrderFilterProps {
  params?: ICourierOrderFilterParams;
  onFilterChanged: (params: ICourierOrderFilterParams) => void;
}

export const CourierOrderHistoryFilter: React.FC<ICourierOrderFilterProps> = ({
  params = INITIAL_FILTER_PARAMS,
  onFilterChanged
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: params,
    onSubmit: values => {
      onFilterChanged(values);
    },
    onReset: values => {
      onFilterChanged(values);
    },
  });

  return (
    <div className="page-filter">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          <Grid item xl={2} md={3}>
            <DatePicker
              id="requested_time_after"
              name="requested_time_after"
              onChange={val => {
                formik.setFieldValue("requested_time_after", val);
              }}
              maxDate={new Date()}
              selected={formik.values.requested_time_after}
              startDate={formik.values.requested_time_after}
              endDate={formik.values.requested_time_before}
              selectsStart
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_TIME_FORMAT}
              isClearable
              timeCaption="Hora"
              showTimeSelect
              autoComplete="off"
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data - Início"
                variant="outlined"
                size="small"
                error={!!formik.errors.requested_time_after}
                helperText={formik.errors.requested_time_after}
                fullWidth />}
            />
          </Grid>

          <Grid item xl={2} md={3}>
            <DatePicker
              id="requested_time_before"
              name="requested_time_before"
              onChange={val => {
                formik.setFieldValue("requested_time_before", val);
              }}
              maxDate={new Date()}
              selected={formik.values.requested_time_before}
              startDate={formik.values.requested_time_after}
              endDate={formik.values.requested_time_before}
              minDate={formik.values.requested_time_after}
              selectsEnd
              locale={DEFAULT_LOCALE}
              dateFormat={CURRENT_DATE_TIME_FORMAT}
              isClearable
              autoComplete="off"
              wrapperClassName="MuiFormControl-fullWidth"
              customInput={<TextField
                label="Data - Fim"
                variant="outlined"
                size="small"
                error={!!formik.errors.requested_time_before}
                helperText={formik.errors.requested_time_before}
                fullWidth />}
            />
          </Grid>

          <Grid item xl={2} md={3}>
            <TextField
              id="request_number"
              name="request_number"
              label="Solicitação"
              onChange={formik.handleChange}
              value={formik.values.request_number}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xl={2} md={3}>
            <TextField
              id="order_number"
              name="order_number"
              label="Pedido"
              variant="outlined"
              value={formik.values.order_number}
              fullWidth
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xl={4} md={6}>
            <TextField
              id="store_name"
              name="store_name"
              label="Loja"
              onChange={formik.handleChange}
              value={formik.values.store_name}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xl={2} md={3}>
            <TextField
              id="status"
              name="status"
              label="Situação"
              select
              onChange={formik.handleChange}
              value={formik.values.status}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="">Selecione</MenuItem>
              {$enum(DeliveryRequestStatus).map((item) => (
                <MenuItem value={item} key={item}>
                  {t(`delivery_request_status.${item}`)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              Pesquisar
            </Button>
          </Grid>

          <Grid item>
            <Button variant="contained" color="secondary" type="reset">
              Limpar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
