import React from "react";
import {
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  makeStyles,
  OutlinedInput,
  Chip,
  Drawer,
  Typography,
  IconButton,
  Button,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import FilterListIcon from '@material-ui/icons/FilterList';
import CancelIcon from '@material-ui/icons/Cancel';
import { CURRENT_DATE_FORMAT, DEFAULT_LOCALE } from "../../i18n";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { ChipListItem, DashboardChipList } from "../mainDashboard/customComponents/dashboardChipList";
import { RegionResponse } from "../../services/types/region";
import { format, startOfDay, endOfDay } from "date-fns";

export interface SelectRegionsDateFilterParams {
  regionsIds: number[];
  date_after: Date;
  date_before: Date;
}
export const PANEL_INITIAL_FILTER_PARAMS: SelectRegionsDateFilterParams = {
  regionsIds: [],
  date_after: startOfDay(new Date()),
  date_before: endOfDay(new Date()),
};

interface SelectRegionsDateFilterProps {
  params?: SelectRegionsDateFilterParams;
  regionList: RegionResponse[];
  onFilterChanged: (params: SelectRegionsDateFilterParams) => void;
};

const validationSchema = Yup.object().shape({
    date_after: Yup.date().required("Campo obrigatório"),
    date_before: Yup.date().required("Campo obrigatório")  
});

export const SelectRegionsDateFilter: React.FC<SelectRegionsDateFilterProps> = ({
  params = PANEL_INITIAL_FILTER_PARAMS,
  regionList = [],
  onFilterChanged,
}) => {
  const classes = useStyles();

  const [openFilterForm, showFilterForm] = React.useState<boolean>(false);
  const [openRegionSelect, setOpenRegionSelect] = React.useState<boolean>(false);
  const [chipList, setChipList] = React.useState<ChipListItem[]>([]);

  const formik = useFormik({
    initialValues: params,
    onSubmit: (values) => {
      showFilterForm(false);
      onFilterChanged(values);
    },
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleOpenDrawer = () => {
    showFilterForm(true);
  };

  const handleApplyFilter = () => {
    formik.submitForm();
  };

  const handleCloseDrawer = () => {
    showFilterForm(false);
    formik.resetForm();
  };

  const handleChipClick = (data: any) => {
    showFilterForm(true);
  }
  // Region
  const handleRegionChipDelete = React.useCallback((deletedRegion: RegionResponse) => {
    const selectedRegionsIds = [...params.regionsIds];
    if (deletedRegion && selectedRegionsIds) {
      const index = selectedRegionsIds.indexOf(deletedRegion.id);
      if (index > -1) {
        selectedRegionsIds.splice(index, 1);
      }
      formik.setFieldValue("regionsIds", selectedRegionsIds);
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.regionsIds]);

  const handleSelectRegionDelete = (deletedRegionId: number) => {
    const selectedRegionsIds = [...formik.values.regionsIds];
    if (deletedRegionId && selectedRegionsIds) {
      const index = selectedRegionsIds.indexOf(deletedRegionId);
      if (index > -1) {
        selectedRegionsIds.splice(index, 1);
      }
      formik.setFieldValue("regionsIds", selectedRegionsIds);
    }
  };

  React.useEffect(() => {
    const chips: ChipListItem[] = [];
    if (regionList && (regionList.length > 0) && (params.regionsIds.length > 0)) {
      for (let i = 0; i < params.regionsIds.length; i++) {
        let regionId = params.regionsIds[i];
        let selectedRegion = regionList.find((region => region.id === regionId));
        if (selectedRegion) {
          chips.push({
            label: selectedRegion.name,
            data: selectedRegion,
            className: classes.chip,
            onClick: handleChipClick,
            onDelete: handleRegionChipDelete
          });
        }
      }
    } else {
      chips.push({
        label: "Todas as Regiões",
        className: classes.chip,
        onClick: handleChipClick
      });
    }

    setChipList(chips);
  }, [params, regionList, handleRegionChipDelete, classes.chip]);

  const handleOpenRegionSelect = (event: React.ChangeEvent<{}>) => {
    setOpenRegionSelect(true);
  };

  const handleCloseRegionSelect = (event: React.ChangeEvent<{}>) => {
    setOpenRegionSelect(false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} justify="space-between">
        <Typography variant="subtitle1" style={{display: "inline-block", marginTop: "14px", marginRight: "10px"}}>
          <label>Data: </label>
            {format(params.date_after, CURRENT_DATE_FORMAT)} até{" "}
            {format(params.date_before, CURRENT_DATE_FORMAT)}
        </Typography>  
        <Grid item md>           
            <DashboardChipList chipList={chipList} />
        </Grid>
        
        <Grid item>
          <IconButton aria-label="filters" onClick={handleOpenDrawer}>
            <FilterListIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Drawer
        anchor={'right'}
        open={openFilterForm}
        onClose={handleCloseDrawer}
        classes={{ paper: classes.drawerPaper, }}
        ModalProps={{ keepMounted: true }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container item spacing={2}>
            <Grid item md={12}>
              <Typography gutterBottom variant="h5" component="h5">
                Filtros
              </Typography>
            </Grid>
            <Grid item md={12}>
              <FormControl variant="outlined" fullWidth >
                <InputLabel id="regions-label">Regiões</InputLabel>
                <Select
                  labelId="regions-label"
                  id="regionsIds"
                  multiple
                  open={openRegionSelect}
                  onOpen={handleOpenRegionSelect}
                  onClose={handleCloseRegionSelect}
                  name="regionsIds"
                  variant="outlined"
                  input={<OutlinedInput id="select-multiple-regions" label="Regiões" />}
                  value={formik.values.regionsIds}
                  onChange={e => {
                    formik.handleChange(e);
                    setOpenRegionSelect(false);
                  }}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {(selected as number[]).map((value, index) => (
                        <Chip
                          key={value}
                          label={regionList !== undefined ? regionList.find((option: { id: number }) => option.id === value)?.name : value}
                          className={classes.chip}
                          deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                          onDelete={() => { handleSelectRegionDelete(value) }}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    }
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {regionList.map((region) => (
                    <MenuItem key={region.id} value={region.id}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={12}>
                <DatePicker
                id="date_after"
                name="date_after"
                onChange={val => {
                    formik.setFieldValue("date_after", val);
                }}
                maxDate={new Date()}
                selected={formik.values.date_after}
                startDate={formik.values.date_after}
                endDate={formik.values.date_before}
                selectsStart
                locale={DEFAULT_LOCALE}
                dateFormat={CURRENT_DATE_FORMAT}
                autoComplete="off"
                required
                wrapperClassName="MuiFormControl-fullWidth"
                customInput={<TextField
                    label="Data Início"
                    variant="outlined"
                    size="small"
                    required={true}
                    error={!!formik.errors.date_after}
                    helperText={formik.errors.date_after}
                    fullWidth />}
                />
            </Grid>

            <Grid item md={12}>
                <DatePicker
                id="date_before"
                name="date_before"
                onChange={val => {
                    formik.setFieldValue("date_before", val);
                }}
                maxDate={new Date()}
                selected={formik.values.date_before}
                startDate={formik.values.date_after}
                endDate={formik.values.date_before}
                minDate={formik.values.date_after}
                selectsEnd
                required
                locale={DEFAULT_LOCALE}
                dateFormat={CURRENT_DATE_FORMAT}
                autoComplete="off"
                wrapperClassName="MuiFormControl-fullWidth"
                customInput={<TextField
                    label="Data Fim"
                    variant="outlined"
                    size="small"
                    required={true}
                    error={!!formik.errors.date_before}
                    helperText={formik.errors.date_before}
                    fullWidth />}
                />
            </Grid>

            <Grid item>
              <Button onClick={handleCloseDrawer} color="secondary">
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleApplyFilter} color="primary">
                OK
              </Button>
            </Grid>
          </Grid>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: "4px",
    marginRight: "4px",
  },
  drawerPaper: {
    width: "350px",
    padding: "16px",
    height: "50%",
    borderBottomLeftRadius: "10px",
  }
});