/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    Grid,
    Typography,
    TextField,
    Button,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Card,
    CardContent,
} from "@material-ui/core";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { useFormik } from "formik";
import { CPF_CNPJInput } from "../../components/CPF_CNPJInput";
import { PhoneInput } from "../../components/PhoneInput";
import * as Yup from "yup";
import {
    noSpecialCharactersRegex,
    phoneMaskRegex,
    postalCodeMaskRegex,
} from "../../utils/masks";
import { PostCodeInput } from "../../components/PostCodeInput";
import cep from "cep-promise";
import { CommercialPlanResponse } from "../../services/types/commercialPlan";
import CustomerService from "../../services/customerService";
import { CustomerDetails, UpdateCustomer } from "../../services/types/customer";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import CityService from "../../services/cityService";
import { City } from "../../services/types/common";
import UserService from "../../services/userService";
import { UserResponse, UserStatus } from "../../services/types/user";
import { PageableParams } from "../../components/TableTrinkets";
import AuthService from "../../services/authService";

interface IEditCustomerProps {
    match: any;
}
interface IParams {
    customerId: string;
}
const validationSchema = Yup.object().shape({
    corporate_name: Yup.string()
        .max(100, "Limite 100")
        .required("Campo obrigatório"),
    trade_name: Yup.string()
        .max(100, "Limite 100")
        .required("Campo obrigatório"),
    email: Yup.string()
        .max(100, "Limite 100")
        .email("E-mail inválido")
        .required("Campo obrigatório"),
    registration_number: Yup.string()
        .min(14, "CNPJ / CPF inválido")
        .max(18, "CNPJ / CPF inválido")
        .required("Campo obrigatório"),
    phonenumber: Yup.string()
        .max(15, "Telefone inválido")
        .min(14, "Telefone inválido")
        .matches(phoneMaskRegex, "Telefone inválido")
        .required("Campo obrigatório"),
    contact_name: Yup.string()
        .max(100, "Limite 100")
        .required("Campo obrigatório"),
    fiscal_address_city: Yup.number()
        .positive("Campo obrigatório")
        .required("Campo obrigatório"),
    fiscal_address_postal_code: Yup.string()
        .min(9, "CEP inválido")
        .max(9, "CEP inválido")
        .matches(postalCodeMaskRegex, "CEP inválido")
        .required("Campo obrigatório"),
    fiscal_address_street: Yup.string()
        .max(100, "Máximo de 100 caracteres excedido")
        .required("Campo obrigatório"),
    fiscal_address_district: Yup.string()
        .max(100, "Máximo de 100 caracteres excedido")
        .matches(noSpecialCharactersRegex, "Caracteres especiais não são permitidos")
        .required("Campo obrigatório"),
    fiscal_address_number: Yup.string()
        .matches(noSpecialCharactersRegex, "Caracteres especiais não são permitidos")
        .max(10, "Máximo de 10 caracteres excedido"),
    fiscal_address_commplement: Yup.string()
        .max(50, "Máximo de 50 caracteres excedido"),
    commercial_plan: Yup.number()
        .positive("Campo obrigatório")
        .required("Campo obrigatório"),
});

const EMPTY_CUSTOMER: UpdateCustomer = {
    id: 0,
    email: "",
    name: "",
    phonenumber: "",
    corporate_name: "",
    trade_name: "",
    registration_number: "",
    contact_name: "",
    additional_km_price: 0,
    single_store: false,
    fiscal_address_city: 0,
    fiscal_address_postal_code: "",
    fiscal_address_street: "",
    fiscal_address_number: "",
    fiscal_address_commplement: "",
    fiscal_address_district: "",
    allow_incentive_dynamics: false,
    skip_customer_actions: false,
    commercial_plan: 0,
    management_user: null
};

export const EditCustomer: React.FC<IEditCustomerProps> = ({ match }) => {
    const history = useHistory();
    const { customerId } = useParams<IParams>();
    const [plans, setPlans] = useState<CommercialPlanResponse[]>();
    const [customer, setCustomer] = React.useState<CustomerDetails>({} as CustomerDetails);
    const [cityList, setCityList] = useState<City[]>([]);
    const [managementUsers, setManagementUsers] = useState<UserResponse[]>();
    const getCustomer = async (customerId: number) => {
        const customerDetails = await CustomerService.getCustomer(customerId);
        setCustomer(customerDetails);
    };
        
    const updateCustomer = (customer: UpdateCustomer) => {
        CustomerService.updateCustomer(Number(customerId), customer).then(() =>{
            NotificationManager.success(
                "Cliente atualizado com sucesso",
                "Novo Cliente"
            );
            getCustomer(Number(customerId));
        }).catch((error) =>{
            NotificationManager.error(
                "Erro ao atualizar cliente",
                "Error"
            );
        });
    };
        
    const getCities = async () => {
        const response = await CityService.getCities();
        setCityList(response);
    };

    const getPlans = () => CustomerService.getPlans().then((plans) => {
        if (plans.results) {
            setPlans(plans.results);
        }
    }).catch(() => { });

    const getManagementUsers = () => {      
        if(!AuthService.hasAuthorization("change_customer_management_user_to_any")){  
            const currentUser = AuthService.getCurrentUser();  
            if(currentUser){
                setManagementUsers([{id: currentUser.id, name: currentUser.name, email: currentUser.email, groups: currentUser.groups, user_status: UserStatus.ACTIVE}]);
            }
        }else{
            UserService.getUsers("", "", UserStatus.ACTIVE, "", new PageableParams(1, 99999)).then((users) => {
                if (users.data) {
                    setManagementUsers(users.data);
                }
            }).catch(() => { });
        }   
    };

    const formik = useFormik({
        initialValues: EMPTY_CUSTOMER,
        onSubmit: (newCustomer) => {
            if(Number(newCustomer.management_user) === 0){
                newCustomer.management_user = null;
            }
            updateCustomer(newCustomer);
            handleCancel();
        },
        validationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const handleCancel = () => {
        history.goBack();
    };

    useEffect(() => {
        getCities();
        getPlans();
        getManagementUsers();
        getCustomer(Number(customerId));
    }, [customerId]);

    const handleCepChange = (cepRequested: string) => {
        cep(cepRequested)
            .then(cepResponse => {
                formik.setFieldError("fiscal_address_postal_code", "");
                formik.setFieldValue(
                    "fiscal_address_street",
                    cepResponse.street
                );
                formik.setFieldValue(
                    "fiscal_address_district",
                    cepResponse.neighborhood
                );
                formik.setFieldValue("fiscal_address_commplement", "");
            })
            .catch(error => {
                formik.setFieldError(
                    "fiscal_address_postal_code",
                    "Preencha o campo corretamente"
                );
                formik.setFieldValue("fiscal_address_street", "");
                formik.setFieldValue("fiscal_address_district", "");
                formik.setFieldValue("fiscal_address_commplement", "");
            });
    };

    useEffect(() => {
        if (customer?.id) {
            let loadedCustomer: UpdateCustomer = {
                id: customer.id,
                email: customer.email,
                name: customer.name,
                phonenumber: customer.phonenumber,
                corporate_name: customer.corporate_name,
                trade_name: customer.trade_name,
                registration_number: customer.registration_number,
                contact_name: customer.contact_name,
                additional_km_price: customer.additional_km_price,
                single_store: customer.single_store,
                fiscal_address_city: customer.fiscal_address_city?.id,
                fiscal_address_postal_code: customer.fiscal_address_postal_code,
                fiscal_address_street: customer.fiscal_address_street,
                fiscal_address_number: customer.fiscal_address_number,
                fiscal_address_commplement: customer.fiscal_address_commplement,
                fiscal_address_district: customer.fiscal_address_district,
                allow_incentive_dynamics: customer.allow_incentive_dynamics,
                skip_customer_actions: customer.skip_customer_actions,
                commercial_plan: customer.commercial_plan || 0,
                management_user: customer.management_user || 0
            };
            let _registration_number = customer.registration_number;
            if (_registration_number) {
                if (_registration_number.length > 11) {
                    _registration_number = customer.registration_number.replace(
                        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                        "$1.$2.$3/$4-$5"
                    );
                } else {
                    _registration_number = customer.registration_number.replace(
                        /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                        "$1.$2.$3-$4"
                    );
                }
            }
            formik.setValues({
                ...loadedCustomer,
                phonenumber: customer.phonenumber.replace(
                    /(\d{2})(\d{4})(\d{4,5})/g,
                    "($1) $2-$3"
                ),
                registration_number: _registration_number,
            });
        }
    }, [customer]);
    return (
        <div>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Editar Cliente</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[
                                { label: "Clientes", url: "/customer" },
                                {
                                    label: "Detalhes do Cliente",
                                    url: `/customer/detail/${match.params.customerId}`,
                                },
                                {
                                    label: "Editar Cliente",
                                    url: `/customer/edit/${customerId}`,
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Card>
                            <CardContent>
                                <Typography variant="h2">Informações Gerais</Typography>
                                <Grid container spacing={2}>
                                    <Grid item md={4}>
                                        <TextField
                                            id="corporate_name"
                                            label="Razão Social / Nome"
                                            variant="outlined"
                                            value={formik.values.corporate_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={
                                                formik.touched.corporate_name
                                                    ? formik.errors.corporate_name
                                                    : ""
                                            }
                                            error={
                                                !!formik.touched.corporate_name &&
                                                !!formik.errors.corporate_name
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <TextField
                                            id="trade_name"
                                            label="Nome fantasia"
                                            variant="outlined"
                                            value={formik.values.trade_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={
                                                formik.touched.trade_name
                                                    ? formik.errors.trade_name
                                                    : ""
                                            }
                                            error={
                                                !!formik.touched.trade_name &&
                                                !!formik.errors.trade_name
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <TextField
                                            id="registration_number"
                                            label="CNPJ / CPF"
                                            variant="outlined"
                                            value={formik.values.registration_number}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            error={!!formik.errors.registration_number}
                                            helperText={formik.errors.registration_number}
                                            InputProps={{
                                                inputComponent: CPF_CNPJInput,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <TextField
                                            id="contact_name"
                                            label="Pessoa para Contato"
                                            variant="outlined"
                                            value={formik.values.contact_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={
                                                formik.touched.contact_name
                                                    ? formik.errors.contact_name
                                                    : ""
                                            }
                                            error={
                                                !!formik.touched.contact_name &&
                                                !!formik.errors.contact_name
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <TextField
                                            id="phonenumber"
                                            label="Telefone"
                                            variant="outlined"
                                            value={formik.values.phonenumber}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            error={!!formik.errors.phonenumber}
                                            helperText={formik.errors.phonenumber}
                                            InputProps={{
                                                inputComponent: PhoneInput,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <TextField
                                            id="email"
                                            label="E-mail"
                                            variant="outlined"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={event => {
                                                formik.setFieldValue(
                                                    event.target.id,
                                                    event.target.value.trim()
                                                );
                                            }}
                                            onBlur={formik.handleBlur}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={
                                                formik.touched.email
                                                    ? formik.errors.email
                                                    : ""
                                            }
                                            error={
                                                !!formik.touched.email &&
                                                !!formik.errors.email
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={2} style={{ paddingTop: "0px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="allow_incentive_dynamics"
                                                    checked={
                                                        formik.values
                                                            .allow_incentive_dynamics
                                                    }
                                                    onChange={event =>
                                                        formik.setFieldValue(
                                                            event.target.name,
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            }
                                            label="Autorizar Dinâmica"
                                        />
                                    </Grid>
                                    <Grid item md={2} style={{ paddingTop: "0px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        formik.values.skip_customer_actions
                                                    }
                                                    onChange={event =>
                                                        formik.setFieldValue(
                                                            event.target.name,
                                                            event.target.checked
                                                        )
                                                    }
                                                    name="skip_customer_actions"
                                                />
                                            }
                                            label="Liberar e Finalizar Pedidos Automaticamente"
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card>
                            <CardContent>
                                <Typography variant="h2">Endereço Fiscal</Typography>
                                <Grid container spacing={2}>
                                    <Grid item md={3}>
                                        <TextField
                                            id="fiscal_address_postal_code"
                                            label="CEP"
                                            variant="outlined"
                                            value={formik.values.fiscal_address_postal_code}
                                            onChange={formik.handleChange}
                                            onBlur={e => handleCepChange(e.target.value)}
                                            fullWidth
                                            error={
                                                !!formik.errors.fiscal_address_postal_code
                                            }
                                            helperText={
                                                formik.errors.fiscal_address_postal_code
                                            }
                                            InputProps={{
                                                inputComponent: PostCodeInput,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={7}>
                                        <TextField
                                            id="fiscal_address_street"
                                            label="Rua"
                                            variant="outlined"
                                            value={formik.values.fiscal_address_street}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            error={!!formik.errors.fiscal_address_street}
                                            helperText={formik.errors.fiscal_address_street}
                                        />
                                    </Grid>
                                    <Grid item md={2}>
                                        <TextField
                                            id="fiscal_address_number"
                                            label="Número"
                                            variant="outlined"
                                            value={formik.values.fiscal_address_number}
                                            onChange={formik.handleChange}
                                            helperText={formik.errors.fiscal_address_number}
                                            error={!!formik.errors.fiscal_address_number}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <TextField
                                            id="fiscal_address_commplement"
                                            label="Complemento"
                                            variant="outlined"
                                            value={formik.values.fiscal_address_commplement}
                                            onChange={formik.handleChange}
                                            helperText={
                                                formik.errors.fiscal_address_commplement
                                            }
                                            error={
                                                !!formik.errors.fiscal_address_commplement
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <TextField
                                            id="fiscal_address_district"
                                            label="Bairro"
                                            variant="outlined"
                                            value={formik.values.fiscal_address_district}
                                            onChange={formik.handleChange}
                                            helperText={
                                                formik.errors.fiscal_address_district
                                            }
                                            error={!!formik.errors.fiscal_address_district}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <TextField
                                            id="fiscal_address_city"
                                            label="Cidade"
                                            variant="outlined"
                                            name="fiscal_address_city"
                                            value={formik.values.fiscal_address_city}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={
                                                formik.touched.fiscal_address_city
                                                    ? formik.errors.fiscal_address_city
                                                    : ""
                                            }
                                            error={
                                                !!formik.touched.fiscal_address_city &&
                                                !!formik.errors.fiscal_address_city
                                            }
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value="0">Selecione</MenuItem>
                                            {cityList.map(city => (
                                                <MenuItem key={city.id} value={city.id}>
                                                    {city.name} - {city.state_initials}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card>
                            <CardContent>
                                <Typography variant="h2">Comercial</Typography>
                                <Grid container spacing={2}>
                                    <Grid item md={12}>
                                        <TextField
                                            id="commercial_plan"
                                            label="Plano"
                                            variant="outlined"
                                            name="commercial_plan"
                                            value={formik.values.commercial_plan}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={formik.touched.commercial_plan ? formik.errors.commercial_plan : ""}
                                            error={!!formik.touched.commercial_plan && !!formik.errors.commercial_plan}
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value="0">Selecione</MenuItem>
                                            {plans ? (
                                                plans.map((plan) => (
                                                    <MenuItem key={plan.id} value={plan.id}>
                                                        {plan.name}
                                                    </MenuItem>
                                                ))
                                            ) : (<div></div>)
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item md={12}>
                                        <TextField
                                            id="management_user"
                                            label="Agente Comercial"
                                            variant="outlined"
                                            name="management_user"
                                            value={formik.values.management_user}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={formik.touched.management_user ? formik.errors.management_user : ""}
                                            error={!!formik.touched.management_user && !!formik.errors.management_user}
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value="0">Selecione</MenuItem>
                                            {managementUsers ? (
                                                managementUsers.map((user) => (
                                                    <MenuItem key={user.id} value={user.id}>
                                                        {user.name}
                                                    </MenuItem>
                                                ))
                                            ) : (<div></div>)
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item>
                        <Button
                            color="secondary"
                            variant="contained"
                            type="button"
                            onClick={handleCancel}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
