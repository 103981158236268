import { apiAxios as axios } from "../store/api/config";
import { formatISO, parseISO } from "date-fns";
import { PagedResponse } from "./types/common";
import { getURL, urls } from "../store/api/urls";
import { convertToDate } from "../utils/utils";
import { CommissionReportsResponse } from "./types/commissionReports";

export interface ICommissionReportsService {
  getCommissionReports: (
    management_user: number | null
  ) => Promise<PagedResponse<CommissionReportsResponse>>;
}
function subtractMonths(numOfMonths: number, date = new Date()) {
  date.setMonth(date.getMonth() - numOfMonths);
  return date;
};

const CommissionReportsService: ICommissionReportsService = {
  
    getCommissionReports: async (
      management_user: number | null
    ) => {
    const url = getURL(urls.COMMISSION_REPORTS, {
      page: 1,
      page_size: 99999999,
      ordering: "-reference_date",
      customer: null,
      management_user: management_user,
      emission_date_after: null,
      emission_date_before: null,
      reference_date_after: formatISO(subtractMonths(6), { representation: 'date' }),
      reference_date_before: formatISO(new Date(), { representation: 'date' })
    });

    return new Promise((resolve, reject) => {
      axios.get(url, {})
        .then((response) => {
          const data: CommissionReportsResponse[] = response.data.results.map((item: any) => {
            return {
              ...item,
              id: Number(item.id),
              created_at: parseISO(item.created_at),
              updated_at: item.updated_at ? parseISO(item.updated_at) : null,
              value: Number(item.value),
              emission_date: convertToDate(item.emission_date),
              management_user: Number(item.management_user),
              month_year: `${item.reference_date.month}-${item.reference_date.year}`
            };
          });
          const result: PagedResponse<CommissionReportsResponse> = {
            data: data,
            count: response.data.count,
          };
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  
  

};

export default CommissionReportsService;
