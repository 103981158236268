/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Fade from "@material-ui/core/Fade";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";

import { DebitNoteDetails } from "./debitNotesDetails";

interface IDebitNotesDetailsDialog {
    modal: {
        open: boolean;
        setOpen(value: boolean): void;
    };
    debitNoteId: number;
    refresh(): void;
}


const DebitNotesDetailsDialog: React.FC<IDebitNotesDetailsDialog> = ({
    modal,
    debitNoteId,
    refresh
}) => {
    const handleClose = () => {
        modal.setOpen(false);
        refresh()
    };

    return (
        <Dialog
            aria-labelledby="form-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll={'paper'}
            open={modal.open}
            fullWidth={true}
            maxWidth={"lg"}
            onClose={handleClose}
        >
            <Fade in={modal.open}>
                <div>
                    <DialogTitle id="form-dialog-title">
                        Detalhes da Nota de Débito
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <DebitNoteDetails debitNoteId={debitNoteId} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                            Fechar
                        </Button>
                    </DialogActions>
                </div>
            </Fade>
            
        </Dialog>
    );
};

export default DebitNotesDetailsDialog;
