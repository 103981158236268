import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { DATE_TIME_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import OccurrencesService from "../../services/occurrencesService";
import { RegisterOccurrences } from "../../services/types/occurrences";
import { Grid, Typography } from "@material-ui/core";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { Alert } from "@material-ui/lab";
import { mapDeliveryRequestStatusToSeverity } from "../requestsHistory/deliveryRequestUtils";
import { INITIAL_FILTER_PARAMS, IOccurrencesFilterParams, OccurrencesFilter } from "./OccurrencesFilter";
import { VisibilityOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddOccurrenceDialog, { AddOccurrenceDialogHandle } from "./AddOccurrenceDialog";
import { decodeURLParams, URLParamType } from "../../utils/utils";

export const Occurrences: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [filterParams, setFilterParams] = useState<IOccurrencesFilterParams>({
    ...INITIAL_FILTER_PARAMS,
    ...decodeURLParams(history.location.search, [
      { "requested_time_after": URLParamType.DATE_TIME },
      { "requested_time_before": URLParamType.DATE_TIME }
    ])
  });
  const [occurrenceId, setOccurrenceId] = useState<number | null>(null);
  const [courierId, setCourierId] = useState<number | null>();

  const tableRef = React.useRef<MaterialTableProps<RegisterOccurrences>>();
  const occurrenceDialog = React.createRef<AddOccurrenceDialogHandle>();

  const loadData = (query: Query<RegisterOccurrences>) =>
    new Promise<QueryResult<RegisterOccurrences>>((resolve, reject) => {
      OccurrencesService.getRegistersOccurrences(
        null, filterParams.gravity, filterParams.number,
        filterParams.request_number, filterParams.blocked, filterParams.requested_time_after,
        filterParams.requested_time_before, [], PageableParams.fromQuery(query)
      ).then(response => {
        resolve({
          data: response.data,
          page: query.page,
          totalCount: response.count,
        });
      }).catch((error) => reject(error));
    });

  const applyFilter = (params: IOccurrencesFilterParams) => {
    setFilterParams(params);
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({
        page: 0,
      } as Query<RegisterOccurrences>);
    }
  };

  const refresh = () => {
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({
        page: 0,
      } as Query<RegisterOccurrences>);
    }
  }

  const showDetails = (item: RegisterOccurrences) => {
    history.push(`/occurrences/detail/${item.id}`);
  }

  const openEditOccurrence = (item: RegisterOccurrences) => {
    if (item) {
      setOccurrenceId(item.id);
      setCourierId(item.courier.id);
    }
  }

  React.useEffect(() => {
    if (occurrenceId) {
      occurrenceDialog.current?.open();
    }
  }, [occurrenceId, occurrenceDialog]);

  const addOccurrencesDone = async (): Promise<void> => {
    try {
      setOccurrenceId(null);
      setCourierId(null);
      refresh();
    } finally {
      return Promise.resolve();
    }
  }

  const cancelAddEditOccurrence = () => {
    setOccurrenceId(null);
    setCourierId(null);
  }

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Registros de Ocorrências</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[
                { label: "Registros de Ocorrências", url: "/occurrences" },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>

      <OccurrencesFilter params={filterParams} onFilterChanged={applyFilter} />

      <AddOccurrenceDialog
        ref={occurrenceDialog}
        courierId={Number(courierId)}
        occurrenceId={occurrenceId}
        deliveryList={null}
        onAccept={addOccurrencesDone}
        onCancel={cancelAddEditOccurrence}
      />

      <MaterialTable
        tableRef={tableRef}
        columns={[
          { field: "id", hidden: true, width: 60 },
          {
            ...DATE_TIME_COLUMN_DEFAULTS,
            field: "occurence_date",
            title: "Data Ocorrência"
          },
          { field: "number", title: "Número" },
          { field: "courier.name", title: "Entregador" },
          { field: "courier.region.name", title: "Região" },
          { field: "description", title: "Descrição" },
          {
            field: "delivery_request.request_number", title: "Solicitação",
            render: (rowData, type) => rowData.delivery_request ? rowData.delivery_request.request_number : "---"
          },
          {
            field: "gravity", title: "Gravidade",
            render: (rowData, type) => rowData.gravity ? t("gravity_occurrences." + rowData.gravity) : "---"
          },
          {
            field: "delivery_request.status", title: "Status Solicitação",
            render: (rowData, type) => rowData.delivery_request ?
              <Alert
                severity={mapDeliveryRequestStatusToSeverity(
                  rowData.delivery_request.status
                )}
                icon={false}
              >
                {t("delivery_request_status." + rowData.delivery_request.status)}
              </Alert> : "---"
          },

          /*  { field: "closed_date", title: "Encerramento", type: "datetime", emptyValue: "---" },
           {
             field: "delivery_value", title: "Valor do Entregador", type: "currency",
             render: (rowData, type) => numbro(rowData.courier_value).formatCurrency()
           },
           {
             field: "payment", title: "Pagamento",
             render: (rowData, type) => rowData.payment ? t("financial_entry_status." + rowData.payment) : ""
           }, */
        ]}
        actions={[
          {
            icon: () => <VisibilityOutlined />,
            tooltip: "Ver Detalhes",
            onClick: (event, rowData) =>
              showDetails(rowData as RegisterOccurrences),
          },
          {
            icon: () => <EditOutlinedIcon />,
            tooltip: 'Editar',
            onClick: (event, rowData) => openEditOccurrence(rowData as RegisterOccurrences)
          },
        ]}
        data={loadData}
        components={DEFAULT_TABLE_COMPONENTS}
        localization={TABLE_L10N_PTBR}
        options={DEFAULT_TABLE_OPTIONS}
      />
    </div>
  );
};