import React, { useMemo, useState, useEffect } from "react";
import {
    Button,
    Card,
    CardContent,
    debounce,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import { TextMaskCustom } from "../../components/TextMaskCustom";
import { Autocomplete } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { $enum } from "ts-enum-util";
import { useFormik, getIn } from "formik";
import { useHistory } from "react-router";
import { formatPhoneNumber } from "../../utils/formatters";
import { ImgLoader } from "../../components/ImgLoader";
import ShowIf from "../../components/ShowIf";
import { DeliveryRequestReturnType, FinishedDeliveryRequest } from "../../services/types/deliveryRequest";
import cep from "cep-promise";
import { CityList, DistrictList } from "../../data/bairros";
import { postalCodeMaskRegex, postalCodeMask, noSpecialCharactersRegex } from "../../utils/masks";
import * as Yup from "yup";
import CourierService from "../../services/courierService";
import { CourierAccountStatus } from "../../services/types/courier";
import { IPageableParams } from "../../store/ducks/requests";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import CustomerService from "../../services/customerService";
import RegionService from "../../services/regionService";
import { PageableParams } from "../../components/TableTrinkets";
import locationByAddress from "../../utils/getLocation";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import DeliveryRequestService from "../../services/deliveryRequestService";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { CURRENT_DATE_FORMAT, DEFAULT_LOCALE } from "../../i18n";

export const INITIAL_VALUES: FinishedDeliveryRequest = {
    order_number: "",
    order_date: new Date(),
    consignee: "",
    consignee_phone_number: "",
    return_type: DeliveryRequestReturnType.NONE,
    postal_code: "",
    street: "",
    address_number: "",
    district: "",
    city: "",
    state: "",
    latitude: "",
    longitude: "",
    store: "",
    current_courier: "",
    addressType: "complete"
};

export const validationSchema = Yup.object().shape({
    order_number: Yup.string()
        .max(8, "Máximo de 8 caracteres excedido")
        .matches(noSpecialCharactersRegex, "Número do pedido inválido")
        .required("Campo obrigatório"),
    order_date: Yup.date()
        .required("Campo obrigatório"),
    consignee: Yup.string()
        .max(100, "Máximo de 100 caracteres excedido")
        .matches(noSpecialCharactersRegex, "O nome não pode ter caracteres especiais")
        .optional(),
    return_type: Yup.string().required("Campo obrigatório"),
    postal_code: Yup.string()
        .when("addressType", {
            is: (value) => value === "complete",
            then: Yup.string().nullable().optional()
                .matches(postalCodeMaskRegex, "CEP inválido"),
            otherwise: Yup.string().nullable().optional(),
        }),
    street: Yup.string()
        .when("addressType", {
            is: (value) => value === "complete",
            then: Yup.string().nullable()
                .max(100, "Máximo de 100 caracteres excedido")
                .required("Campo obrigatório"),
            otherwise: Yup.string().nullable().optional(),
        }),
    address_number: Yup.string()
        .max(10, "Máximo de 10 caracteres excedido"),
    district: Yup.string()
        .max(100, "Máximo de 100 caracteres excedido")
        .matches(
            noSpecialCharactersRegex,
            "Caracteres especiais não são permitidos"
        )
        .required("Campo obrigatório"),
    city: Yup.string()
        .max(100, "Máximo de 100 caracteres excedido")
        .matches(
            noSpecialCharactersRegex,
            "Caracteres especiais não são permitidos"
        )
        .required("Campo obrigatório"),
    state: Yup.string()
        .min(2, "Mínimo de 2 caracteres")
        .max(2, "Máximo de 2 caracteres excedido")
        .required("Campo obrigatório"),
    current_courier: Yup.number()
        .required("Campo obrigatório"),
});

export const NewDeliveryRequest: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [regionId, setRegionId] = React.useState<number>();
    const [courierSearchValue, setCourierSearchValue] = React.useState<string>("");
    const [courierList, setCourierList] = React.useState<any[]>([]);
    const [selectedCourier, setSelectedCourier] = React.useState<any>();
    const [districtList, setDistrictList] = React.useState<string[]>([]);
    const [selectedCity, setSelectedCity] = React.useState<string>("");
    const [storeList, setStoreList] = useState<any[]>([]);
    const [regionList, setRegionList] = useState<any[]>([]);

    const updateCourierList = useMemo(() => debounce((name: string) => {
        const DEFAULT_PAGINATION: IPageableParams = {
            page: 1,
            page_size: 15,
            ordering: "name"
        };
        if (name.trim().length > 3 && regionId !== undefined) {
            CourierService.getCouriers(name, "", "", null, null, CourierAccountStatus.ACTIVE, "", "", [regionId], "", null, DEFAULT_PAGINATION).then((response) => {
                setCourierList(response.data);
            });
        } else {
            setCourierList([]);
        }
    }, 300), [regionId]);

    React.useEffect(() => {
        updateCourierList(courierSearchValue);
    }, [courierSearchValue, updateCourierList]);

    const getFormattedAddress = (address: any): string => {
        const addressArray: string[] = [];

        if (address.street && address.street.trim().length) {
            addressArray.push(address.street.trim())
        }
        if (address.number && address.number.trim().length) {
            addressArray.push(address.number.trim());
        }
        if (address.district && address.district.trim().length) {
            addressArray.push(address.district.trim());
        }
        if (address.city && address.city.trim().length) {
            addressArray.push(address.city.trim());
        }

        let result = addressArray.join(", ");
        if (address.state && address.state.trim().length) {
            result += ` - ${address.state}`;
        }
        return result + ", Brasil";
    };

    const formik = useFormik<FinishedDeliveryRequest>({
        initialValues: INITIAL_VALUES,
        onSubmit: async (values) => {
            try {
                let addressNumber = "S/N";
                let postalCode = values.postal_code;
                let street = values.street;

                let fullAddress = {};
                if (values.addressType === "reduced") {
                    postalCode = "00000000";
                    street = "---";

                    fullAddress = {
                        district: values.district,
                        city: values.city,
                        state: values.state,
                    };
                } else {
                    if ((values.address_number !== null)
                        && (values.address_number !== undefined)
                        && values.address_number.trim().length !== 0) {
                        addressNumber = values.address_number.trim();
                    }

                    if ((values.postal_code !== null)
                        && (values.postal_code !== undefined)
                        && (values.postal_code.trim().length === 0)) {
                        values.postal_code = "00000000";
                        postalCode = values.postal_code;
                    }

                    fullAddress = {
                        cep: postalCode,
                        street: values.street,
                        number: addressNumber,
                        complement: values.address_complement,
                        district: values.district,
                        city: values.city,
                        state: values.state,
                    };
                }

                const formattedAddress = getFormattedAddress(fullAddress);
                const coords = await locationByAddress(formattedAddress);

                await DeliveryRequestService.createFinishedDeliveryRequest({
                    ...values,
                    consignee: values.consignee && values.consignee.trim() !== "" ? values.consignee : "---",
                    postal_code: postalCode,
                    street: street,
                    latitude: coords.lat,
                    longitude: coords.lng,
                    address_number: addressNumber,
                    store: values.store
                });

                NotificationManager.success("Entrega registrada com sucesso!", "Nova Entrega");
                history.goBack();
            } catch (error) {
                const { status, data } = error.response;
                const errorMsgs: string[] = [];
                let errorMsg = "Ocorreu um erro ao registrar a entrega";
                let showGlobalError = true;
                if (status === 400) {
                    for (let key in data) {
                        let value = data[key];
                        if (key in INITIAL_VALUES) {
                            showGlobalError = false;
                            formik.setFieldError(key, value);
                        } else {
                            errorMsgs.push(value);
                        }
                    }

                    if (errorMsgs.length > 0) {
                        showGlobalError = true;
                        errorMsg = errorMsgs.join(". \n");
                    }
                }
                if (showGlobalError) {
                    NotificationManager.error(errorMsg, "Entrega Realizada");
                }
            }
        },
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const handleCepChange = (cepRequested: string) => {
        cep(cepRequested)
            .then((cepResponse) => {
                //setPostalCodeValid(true);
                formik.setFieldError("postal_code", "");
                formik.setFieldValue("street", cepResponse.street);
                formik.setFieldValue("district", cepResponse.neighborhood);
                formik.setFieldValue("city", cepResponse.city);
                formik.setFieldValue("state", cepResponse.state);
            })
            .catch((error) => {
                //setPostalCodeValid(false);
                formik.setFieldError("postal_code", "Preencha o campo corretamente");
                formik.setFieldValue("street", "");
                formik.setFieldValue("district", "");
                formik.setFieldValue("city", "");
                formik.setFieldValue("state", "");
            });
    };

    const onSelectRegion = (regionId: number | null) => {
        if (regionId) {
            setRegionId(regionId);
            getStores([regionId]);
        } else {
            setRegionId(undefined);
            setStoreList([]);
        }
    };

    const getStores = async (regionsIds: number[]) => {
        const response = await CustomerService.getStores(undefined, undefined,
            regionsIds, undefined, new PageableParams(1, 100, "name"));
        setStoreList(response.data);
    };

    useEffect(() => {
        const getRegions = async () => {
            const response = await RegionService.loadAllRegions({
                ordering: "name",
            });
            setRegionList(response);
        };
        getRegions();
    }, []);

    const isRequired = (field_name: string) => {
        return (
            getIn(validationSchema.fields, field_name)._exclusive.required || false
        );
    };

    const handleCancel = () => {
        history.goBack();
    };

    return (
        <>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Entrega Realizada</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[
                                { label: "Histórico de Solicitações", url: "/requests" },
                                {
                                    label: "Entrega Realizada",
                                    url: "/requests/new"
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <div className="page-filter">
                <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h2" component="h2">
                                        {t("Entrega Realizada")}
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item md={4}>
                                            <TextField
                                                id="region"
                                                name="region"
                                                label="Região"
                                                select
                                                onChange={(e) => {
                                                    if (Number(e.target.value) > 0) {
                                                        onSelectRegion(Number(e.target.value));
                                                    } else {
                                                        onSelectRegion(null);
                                                    }
                                                }}
                                                value={regionId || ''}
                                                variant="outlined"
                                                fullWidth
                                                margin="dense"
                                                required={true}
                                            >
                                                <MenuItem value="">Todas</MenuItem>
                                                {regionList.map((region) => (
                                                    <MenuItem
                                                        key={region.id}
                                                        value={region.id}
                                                    >
                                                        {region.name}

                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={8}>
                                            <TextField
                                                id="store"
                                                name="store"
                                                label="Loja"
                                                select
                                                onChange={formik.handleChange}
                                                value={formik.values.store}
                                                variant="outlined"
                                                fullWidth
                                                margin="dense"
                                                required={true}
                                                error={!!formik.errors.store}
                                                helperText={formik.errors.store}
                                            >
                                                <MenuItem value="">Todas</MenuItem>
                                                {storeList.map(store => (
                                                    <MenuItem key={store.id} value={store.id}>
                                                        {store.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={12}>
                                            <Autocomplete
                                                id="courier_name"
                                                size="small"
                                                value={selectedCourier}
                                                inputValue={courierSearchValue}
                                                options={courierList}
                                                getOptionLabel={(courier) => `${courier.name} - ${formatPhoneNumber(courier.phonenumber)}`}
                                                loadingText="Aguarde ..."
                                                noOptionsText="Sem opções"
                                                onChange={(e, newValue) => {
                                                    if (newValue) {
                                                        setSelectedCourier(newValue);
                                                        formik.setFieldValue("current_courier", newValue.id);
                                                    } else {
                                                        setSelectedCourier(null);
                                                        formik.setFieldValue("current_courier", null);
                                                    }
                                                }}
                                                onInputChange={(event, newInputValue) => {
                                                    setCourierSearchValue(newInputValue);
                                                }}
                                                renderOption={(courier) => (
                                                    <React.Fragment>
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <ImgLoader src={courier.photo} width={40} height={40} />
                                                            </Grid>
                                                            <Grid item>
                                                                {`${courier.name} - ${formatPhoneNumber(courier.phonenumber)}`}
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        label="Entregador"
                                                        helperText={formik.errors.current_courier || "Digite pelo menos 4 letras para realizar a consulta"}
                                                        error={!!formik.errors.current_courier}
                                                        InputLabelProps={{ required: true }}
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="dense"
                                                        required={true}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} direction="column">
                                        <Grid item>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={2}>
                                                    <TextField
                                                        id="order_number"
                                                        label="Pedido"
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.order_number}
                                                        error={!!formik.errors.order_number}
                                                        helperText={formik.errors.order_number}
                                                        inputProps={{ maxLength: 8 }}
                                                        InputLabelProps={{ required: true }}
                                                        fullWidth
                                                        margin="dense"
                                                        required={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <ReactDatePicker
                                                        id="order_date"
                                                        name="order_date"
                                                        onChange={val => {
                                                            formik.setFieldValue("order_date", val);
                                                        }}
                                                        maxDate={new Date()}
                                                        selected={formik.values.order_date}
                                                        locale={DEFAULT_LOCALE}
                                                        dateFormat={CURRENT_DATE_FORMAT}
                                                        isClearable
                                                        autoComplete="off"
                                                        required={true}
                                                        wrapperClassName="MuiFormControl-fullWidth"
                                                        customInput={<TextField
                                                            label="Data"
                                                            variant="outlined"
                                                            size="small"
                                                            autoComplete="false"
                                                            error={!!formik.errors.order_date}
                                                            helperText={formik.errors.order_date}
                                                            InputLabelProps={{ required: true }}
                                                            fullWidth
                                                            margin="dense"
                                                            required={true} />}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        id="consignee"
                                                        label="Consumidor"
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={formik.handleChange}
                                                        fullWidth
                                                        margin="dense"
                                                        value={formik.values.consignee}
                                                        error={!!formik.errors.consignee}
                                                        helperText={formik.errors.consignee}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        error={!!formik.errors.return_type}
                                                    >
                                                        <InputLabel id="return_type-label">Tipo de retorno</InputLabel>
                                                        <Select
                                                            label="Tipo de retorno"
                                                            labelId="return_type-label"
                                                            id="return_type"
                                                            name="return_type"
                                                            value={formik.values.return_type}
                                                            onChange={formik.handleChange}
                                                            required={true}
                                                        >
                                                            {$enum(DeliveryRequestReturnType).map((type) => {
                                                                return (
                                                                    <MenuItem key={type} value={type}>
                                                                        {t("delivery_request_return_type." + type)}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                        {formik.errors.return_type && (
                                                            <FormHelperText>{formik.errors.return_type}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption">
                                                            Os campos com (*) são de preenchimento obrigatório.
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h2">Endereço</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item md={4}>
                                            <FormControl component="fieldset">
                                                <RadioGroup row aria-label="address" name="address" defaultValue="top">
                                                    <FormControlLabel
                                                        label="Completo"
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                value="complete"
                                                                checked={formik.values.addressType === "complete"}
                                                                onChange={(e) => formik.setFieldValue("addressType", e.target.value)}
                                                            />
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        label="Apenas Cidade e Bairro"
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                value="reduced"
                                                                checked={formik.values.addressType === "reduced"}
                                                                onChange={(e) => formik.setFieldValue("addressType", e.target.value)}
                                                            />
                                                        }
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <ShowIf condition={formik.values.addressType === "complete"}>
                                            <Grid container spacing={2}>
                                                <Grid item md={3}>
                                                    <TextField
                                                        id="postal_code"
                                                        label="CEP"
                                                        variant="outlined"
                                                        value={formik.values.postal_code}
                                                        onChange={formik.handleChange}
                                                        onBlur={(e) => handleCepChange(e.target.value)}
                                                        fullWidth
                                                        error={!!formik.errors.postal_code}
                                                        helperText={formik.errors.postal_code}
                                                        required={isRequired("postal_code")}
                                                        InputProps={{
                                                            inputComponent: PostCodeInput,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField
                                                        id="street"
                                                        label="Rua"
                                                        variant="outlined"
                                                        value={formik.values.street}
                                                        onChange={formik.handleChange}
                                                        fullWidth
                                                        error={!!formik.errors.street}
                                                        helperText={formik.errors.street}
                                                        required={isRequired("street")}
                                                    />
                                                </Grid>
                                                <Grid item md={2}>
                                                    <TextField
                                                        id="address_number"
                                                        label="Número"
                                                        variant="outlined"
                                                        value={formik.values.address_number}
                                                        onChange={formik.handleChange}
                                                        helperText={formik.errors.address_number}
                                                        error={!!formik.errors.address_number}
                                                        required={isRequired("address_number")}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item md={3}>
                                                    <TextField
                                                        id="district"
                                                        label="Bairro"
                                                        variant="outlined"
                                                        value={formik.values.district}
                                                        onChange={formik.handleChange}
                                                        helperText={formik.errors.district}
                                                        error={!!formik.errors.district}
                                                        required={isRequired("district")}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField
                                                        id="city"
                                                        label="Cidade"
                                                        variant="outlined"
                                                        value={formik.values.city}
                                                        onChange={formik.handleChange}
                                                        helperText={formik.errors.city}
                                                        error={!!formik.errors.city}
                                                        required={isRequired("city")}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item md={2}>
                                                    <TextField
                                                        id="state"
                                                        label="Estado"
                                                        variant="outlined"
                                                        value={formik.values.state}
                                                        onChange={formik.handleChange}
                                                        helperText={formik.errors.state}
                                                        error={!!formik.errors.state}
                                                        required={isRequired("state")}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ShowIf>

                                        <ShowIf condition={formik.values.addressType === "reduced"}>
                                            <Grid container spacing={2}>
                                                <Grid item md={4}>
                                                    <TextField
                                                        id="city"
                                                        label="Cidade"
                                                        variant="outlined"
                                                        select
                                                        fullWidth
                                                        onChange={(e) => {
                                                            if (e.target.value) {
                                                                setSelectedCity(e.target.value);
                                                                const districts = DistrictList.get(e.target.value);
                                                                if (districts) {
                                                                    setDistrictList(districts);
                                                                }
                                                                const selected = e.target.value.split("-");
                                                                const cityName = selected[0].trim();
                                                                const stateName = selected[1].trim();
                                                                formik.setFieldValue("city", cityName);
                                                                formik.setFieldValue("state", stateName);
                                                                formik.setFieldValue("district", "");
                                                            } else {
                                                                setSelectedCity("");
                                                                formik.setFieldValue("city", "");
                                                                formik.setFieldValue("state", "");
                                                            }
                                                        }}
                                                        value={selectedCity}
                                                        error={!!formik.errors.city}
                                                        helperText={formik.errors.city}
                                                        InputLabelProps={{ required: true }}
                                                    >
                                                        {CityList.map((city, index) => (
                                                            <MenuItem key={index} value={city}>
                                                                {city}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>

                                                </Grid>
                                                <Grid item md={4}>
                                                    <Autocomplete
                                                        id="district"
                                                        size="small"
                                                        value={formik.values.district}
                                                        options={districtList}
                                                        loadingText="Aguarde ..."
                                                        noOptionsText="Escolha uma Cidade"
                                                        onChange={(e, newValue) => {
                                                            if (newValue) {
                                                                formik.setFieldValue("district", newValue);
                                                            } else {
                                                                formik.setFieldValue("district", "");
                                                            }
                                                        }}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                label="Bairro"
                                                                helperText={formik.errors.district}
                                                                error={!!formik.errors.district}
                                                                variant="outlined"
                                                                fullWidth
                                                                InputLabelProps={{ required: true }}
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ShowIf>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="caption">
                                                    Os campos com (*) são de preenchimento obrigatório.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item container spacing={2} xs={12}>
                            <Grid item>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    type="button"
                                    onClick={handleCancel}
                                >
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={formik.isSubmitting}
                                >
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div >
        </>
    );
};

const PostCodeInput = (props: any) => (
    <TextMaskCustom {...props} mask={postalCodeMask} />
);
