import { City } from "../services/types/common";
import { capitalize } from "./capitalize";

export const formatPhoneNumber = (phoneNumber: string): string => {
    let result = phoneNumber;
    if(phoneNumber){
      if (phoneNumber.length <= 14) {
        let regex;
        switch (phoneNumber.length) {
            case 14:
            case 11:
                regex = /([+]\d{2})?(\d{2})(\d{5})(\d{4})/;
                break;
            case 13:
            case 10:
                regex = /([+]\d{2})?(\d{2})(\d{4})(\d{4})/;
                break;
            default:
                regex = /.*/;
                break;
        }
        const parts = phoneNumber.match(regex);
        if (parts && parts.length > 1) {
            result = `${parts[1] ? parts[1] + " " : ""}(${parts[2]}) ${parts[3]}-${parts[4]}`;
        }
    }  
    }
    
    return result;
}

export const formatCPFCNPJ = (cpfCnpj: string): string => {
    let result = cpfCnpj;
    if(cpfCnpj){
        if (cpfCnpj.length === 11) {
            const regex = /(\d{3})(\d{3})(\d{3})(\d{2})/
            const parts = cpfCnpj.match(regex);
            if (parts) {
                result = `${parts[1]}.${parts[2]}.${parts[3]}-${parts[4]}`;
            }
        } else if (cpfCnpj.length === 14) {
            const regex = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/
            const parts = cpfCnpj.match(regex);
            if (parts) {
                result = `${parts[1]}.${parts[2]}.${parts[3]}/${parts[4]}-${parts[5]}`;
            }
        }  
    }


    return result;
}

export const formatCityName = (city: City) => {
    let result = "";
    if (city && city.name && city.state_initials) {
        result = `${capitalize(city.name)} - ${city.state_initials.toUpperCase()}`;
    } else if (city && city.name) {
        result = capitalize(city.name);
    }

    return result;
};
