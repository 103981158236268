import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { CURRENT_DATE_TIME_FORMAT } from "../../i18n";
import {
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { LoadingPage } from "../../components/Loading";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import ShowIf from "../../components/ShowIf";
import OccurrencesService from "../../services/occurrencesService";
import { RegisterOccurrences } from "../../services/types/occurrences";
import { formatPhoneNumber } from "../../utils/formatters";
import { useTranslation } from "react-i18next";

interface DetailsOccurrenceProps {
    occurrenceId: string;
}

export const DetailsOccurrence: React.FC = () => {
    const { occurrenceId } = useParams<DetailsOccurrenceProps>();
    const [occurrence, setOccurrence] = useState<RegisterOccurrences>();
    const { t } = useTranslation();

    useEffect(() => {
        const loadOccurrence = async (id: number) => {
            const response = await OccurrencesService.getOccurrenceById(id);
            setOccurrence(response);
        };
        if (occurrenceId) {
            loadOccurrence(Number(occurrenceId));
        }
    }, [occurrenceId]);

    return (
        <div>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h1">
                                Detalhes da Occorrência
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomBreadcrumbs
                                pathList={[
                                    { label: "Occorrência", url: "/occurrences" },
                                    {
                                        label: "Detalhes da Occorrência",
                                        url: `/occurrences/detail/${occurrenceId}`,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {!occurrence ? (
                <LoadingPage />
            ) : (
                <Grid container spacing={2} xs={12}>
                    <Grid item xs={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h2">Dados da Ocorrência</Typography>
                                <Table className="table-bordered">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>Criado em</Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.created_at ? format(occurrence?.created_at, CURRENT_DATE_TIME_FORMAT) : "---"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>Data da Ocorrência</Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.occurence_date ? format(occurrence?.occurence_date, CURRENT_DATE_TIME_FORMAT) : "---"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>Gravidade</Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence.gravity ? t("gravity_occurrences." + occurrence.gravity) : "---"}                                                 
                                                </Typography>
                                            </TableCell>                                                                                     
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>Número</Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.number}
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={3}>
                                                <Typography>Descrição</Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.description}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h2">Dados do Entregador</Typography>
                                <Table className="table-bordered">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>Entregador</Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.courier.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    Telefone
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.courier.phonenumber ? formatPhoneNumber(occurrence?.courier.phonenumber) : "---"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    Veículo
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {t("vehicle_type." + occurrence?.courier.vehicle_type)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>Compartimento</Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.courier.vehicle_load_compartment_type ? t(`vehicle_load_compartment_type.${occurrence?.courier.vehicle_load_compartment_type}`) : "---"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>Ranking</Typography>
                                                <Typography variant="subtitle1">
                                                    {t(`courier_type.${occurrence?.courier.speedy_ranking}`)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={3}>
                                                <Typography>Pontuação</Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.courier.new_ranking_points}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>

                    <ShowIf condition={occurrence.delivery_request !== null}>
                        <Grid item xs={8}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h2">Dados da Solicitação</Typography>
                                    <Table className="table-bordered">
                                        <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>
                                                    Número da Solicitação
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.delivery_request ? occurrence?.delivery_request.request_number || "---" : "---"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    Order número
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.delivery_request ? occurrence?.delivery_request.order_number || "---" : "---"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    Situação
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.delivery_request ? t("delivery_request_status." + occurrence.delivery_request.status) || "---" : "---"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    Criado Em
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.delivery_request ? format(occurrence?.delivery_request.created_at, CURRENT_DATE_TIME_FORMAT) || "---" : "---"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    Estimativa da Entrega
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.delivery_request ? format(occurrence?.delivery_request.estimated_delivery_time, CURRENT_DATE_TIME_FORMAT) || "---" : "---"}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell colSpan={5}>
                                                <Typography>
                                                    Endereço
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                {occurrence?.delivery_request
                                                    ? `${occurrence?.delivery_request.destination_address.street || "---"}, ${occurrence?.delivery_request.destination_address.address_number || "---"}, ${occurrence?.delivery_request.destination_address.district || "---"}, ${occurrence?.delivery_request.destination_address.city || "---"}, ${occurrence?.delivery_request.destination_address.state || "---"} - ${occurrence?.delivery_request.destination_address.postal_code || "---"}`
                                                    : "---"}
                                                </Typography>
                                            </TableCell>
                                            
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <Typography>
                                                    Complemento
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.delivery_request ? occurrence?.delivery_request.destination_address.address_complement || "---" : "---"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={3}>
                                                <Typography>
                                                    Observações da Entrega
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    {occurrence?.delivery_request ? occurrence?.delivery_request.destination_address.delivery_observations || "---" : "---"}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                    </ShowIf>
                    
                    <ShowIf condition={occurrence.blocklist_record !== null && occurrence.blocklist_record !== undefined}>
                        <Grid item xs={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h2">Bloqueado</Typography>
                                    <Table className="table-bordered">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography>
                                                        Criado em
                                                    </Typography>
                                                    <Typography variant="subtitle1">
                                                        {occurrence?.blocklist_record ? format(occurrence?.blocklist_record.created_at, CURRENT_DATE_TIME_FORMAT) || "---" : "---"}
                                                    </Typography>
                                                </TableCell>                                                
                                                <TableCell>
                                                    <Typography>
                                                        Duração
                                                    </Typography>
                                                    <Typography variant="subtitle1">
                                                        {occurrence?.blocklist_record ? occurrence?.blocklist_record.duration !== null ? occurrence?.blocklist_record.duration + " minutos" : "Indefinida" : "---"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            
                                            <TableRow>
                                                <TableCell colSpan={3}>
                                                    <Typography>
                                                        Motivo
                                                    </Typography>
                                                    <Typography variant="subtitle1">
                                                        {occurrence?.blocklist_record ? occurrence?.blocklist_record.reason || "---" : "---"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                    </ShowIf>
                    
                </Grid>
                
            )}
        </div>
    );
};
