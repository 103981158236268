import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  styled,
  Button,
  IconButton,
} from "@material-ui/core";
import { DeleteOutlined, PhotoCamera } from '@material-ui/icons';
import clsx from "clsx";

interface IUploadImage {
  onChange: (file: File | null, fileURL?: string) => void;
  onTitleClick?: (title: string, name: string, src: string) => void;
  title?: string;
  name: string;
  error?: boolean;
  initialFile?: string;
  showDeleteButton?: boolean;
  className?: string;
}

const Input = styled('input')({
    display: 'none',
  });

export const UploadImage: React.FC<IUploadImage> = ({onChange, onTitleClick, title="", name, error=false, initialFile, showDeleteButton=true, className}) => {
  const classes = useStyles(error);
  const hiddenFileInput = React.useRef(null);
  const [fileUrl, setFileUrl] = useState<string>("");

  const handleChange = (event: any) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    if(file){
      reader.onloadend = () => {
        const url = reader.result ? String(reader.result) : "";
        setFileUrl(url);
        onChange(file, url);
      }
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = () => {
    setFileUrl('');
    onChange(null, undefined);
    if(hiddenFileInput){
      hiddenFileInput.current = null;
    }
  }

  useEffect(() => {
    if(initialFile) {
      setFileUrl(initialFile)
    }
  }, [initialFile])

  return (
    <div className={clsx(classes.root, className)}>
    <label htmlFor={name}>
        <Input id={name} name={name} accept="image/*" onClick={(event)=> {(event.target as HTMLInputElement).value = ""}} ref={hiddenFileInput} onChange={(e)=>{handleChange(e)}} type="file" />
        <Button startIcon={<PhotoCamera />} variant="contained" color={"primary"} component="span">
            {title}
        </Button>
    </label>
    {showDeleteButton &&
        <label htmlFor="icon-button-file">
            <IconButton disabled={fileUrl === ""} onClick={handleDelete} color="secondary" aria-label="upload picture" component="span">
                <DeleteOutlined />
            </IconButton>
        </label>
    }  
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      minWidth: '150px',
      height: '36px',
      margin: '2rem 0'
    },
    inputFile: {
      display: 'none',
    },
    buttonDelete: {
      color: '#526272',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.error.main
      }
    },
    buttonAttachment: {
      background: '#FFFFFF',
      boxShadow: theme.shadows[3],
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '10px',
      opacity: 1,
      padding: '4px 10px',
      marginLeft: '8px',
      cursor: 'pointer',
      outline: 'none',
      '&:hover': {
        background: theme.palette.primary.main,
        '& > *': {
          color: '#fff',
        }
      },
    },
    buttonText: {
      color: `${theme.palette.primary.main}`,
      fontSize: '12px',
    },
    title: {
      flex: 1,
      fontSize: '12px',
      fontWeight: 'lighter',
      whiteSpace: 'pre-wrap',
      cursor: 'initial',
      color: 'initial',
    },
    titleFileSelected: {
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    titleError: {
      color: '#f44336',
    }
  })
);
