import React from "react";
import { Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { decodeURLParams, encodeURLParams, URLParamType } from "../../utils/utils";
import { TaxPayment } from "./SubPages/TaxPayment";
import { ReferralSettings } from "./SubPages/ReferralSettings";
import { DeliverySettings } from "./SubPages/DeliverySettings";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  tabValue: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {tabValue === index && <Box>{children}</Box>}
    </div>
  );
}
export const Configurations = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [tabValue, setTabValue] = React.useState<string>("delivery_settings");

  React.useLayoutEffect(() => {
    const result: any = decodeURLParams(history.location.search, [{"menu": URLParamType.STRING}]);
    if(result && result.menu){
      setTabValue(result.menu);
    }    
}, [history.location.search]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    history.replace(encodeURLParams(history.location.pathname, {menu: newValue}));
    setTabValue(newValue);
  };

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
          <Grid item xs={12}>
            <Typography variant="h1">Configurações</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[{ label: "Configurações", url: "/configuration" }]}
            />
          </Grid>
      </Grid>

      <Tabs
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        className="tabs"
      >
        <Tab label="Configurações da Entrega" value={"delivery_settings"} />
        <Tab label="Taxas de Pagamento" value={"tax_payment"} />
        <Tab label="Configurações de Indicação" value={"referral_settings"} />

      </Tabs>
      <React.Fragment>
          <TabPanel tabValue={tabValue} index={"tax_payment"}>
            <TaxPayment />
          </TabPanel>
          <TabPanel tabValue={tabValue} index={"referral_settings"}>
            <ReferralSettings />
          </TabPanel>
           <TabPanel tabValue={tabValue} index={"delivery_settings"}>
            <DeliverySettings />
          </TabPanel>
        </React.Fragment>
    </div>
  );
};