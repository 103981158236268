import React, { ReactNode } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { LoadingComponent } from "../Loading";
const key = process.env.REACT_APP_MAPS_KEY;

const link = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`;

interface IProps {
  children?: ReactNode;
  setPosition(position: { latitude: number; longitude: number }): void;
  latitude: number;
  longitude: number;
}

export const SimpleMap = compose<IProps, any>(
  withProps({
    googleMapURL: link,
    loadingElement: <LoadingComponent />,
    containerElement: (
      <div
        style={{
          height: "100%",
          borderRadius: "11px",
          overflow: "hidden",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        }}
      />
    ),
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(
  (props: IProps): JSX.Element => {
    const onMarkerDragEnd = (event: any) => {
      const newLat = event.latLng.lat(),
        newLng = event.latLng.lng();
      props.setPosition({ latitude: newLat, longitude: newLng });
    };

    const { latitude, longitude } = props;

    return (
      <GoogleMap
        defaultZoom={15}
        center={{ lat: latitude, lng: longitude }}
        options={{
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControl: false,
        }}
      >
        <Marker
          position={{ lat: latitude, lng: longitude }}
          draggable
          onDragEnd={onMarkerDragEnd}
        />
      </GoogleMap>
    );
  }
);
