import React, { useState } from "react";
import {
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  CardMedia,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Chart } from "react-google-charts";
import DeliveryRequestService from "../../services/deliveryRequestService";
import numbro from "numbro";
import { format, isSameDay, startOfDay, endOfDay } from "date-fns";
import { CURRENT_DATE_FORMAT } from "../../i18n";
import RegionService from "../../services/regionService";
import { DeliveriesPerDay, DeliveriesPerRegionPerDay, RegionDeliveries, RegionValues } from "../../services/types/deliveryRequest";
import MaterialTable, { MaterialTableProps, Query, QueryResult } from "@material-table/core";
import { DATE_TIME_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, PageableParams, TABLE_L10N_PTBR } from "../../components/TableTrinkets";
import DetailsOccurrenceDialog, { DetailsOccurrenceDialogHandle } from "./DetailsOccurrenceDialog";
import theme from "../../styles/theme";
import { PANEL_INITIAL_FILTER_PARAMS, SelectRegionsDateFilter, SelectRegionsDateFilterParams } from "./SelectRegionsDateFilter";
import CustomerService from "../../services/customerService";
import { NegativeCustomersResponse } from "../../services/types/customer";
import { GoogleDataTableCell, GoogleDataTableColumn, GoogleDataTableRow } from "react-google-charts/dist/types";


export const Panel = () => {
  const [regionList, setRegionList] = React.useState<any[]>([]);

  const [passedOnRows, setPassedOnRows] = React.useState<GoogleDataTableRow[]>([]);
  const [totalPassedOn, setTotalPassedOn] = React.useState<number>(0);

  const [earningsRows, setEarningsRows] = React.useState<GoogleDataTableRow[]>([]);
  const [totalEarnings, setTotalEarnings] = React.useState<number>(0);

  const [totalValuesRows, setTotalValuesRows] = React.useState<GoogleDataTableRow[]>([]);
  const [grandTotalValue, setGrandTotalValue] = React.useState<number>(0);
  const [isLoadingValuesCard, setIsLoadingValuesCard] = React.useState<boolean>(false);

  const [deliveriesPerRegionRows, setDeliveriesPerRegionRows] = React.useState<GoogleDataTableRow[]>([]);
  const [deliveriesPerRegionTotal, setDeliveriesPerRegionTotal] = React.useState<number>(0);
  const [isLoadingDeliveriesPerRegion, setIsLoadingDeliveriesPerRegion] = React.useState<boolean>(false);
  
  const [deliveriesPerDayColumns, setDeliveriesPerDayColumns] = React.useState<GoogleDataTableColumn[]>([]);
  const [deliveriesPerDayRows, setDeliveriesPerDayRows] = React.useState<GoogleDataTableRow[]>([]);
  const [isLoadingDeliveriesPerDay, setIsLoadingDeliveriesPerDay] = React.useState<boolean>(false);

  const [rechargesPerDayRows, setRechargesPerDayRows] = React.useState<GoogleDataTableRow[]>([]);
  const [rechargesPerDayTotal, setRechargesPerDayTotal] = React.useState<number>(0);
  const [isLoadingRecharges, setIsLoadingRecharges] = React.useState<boolean>(false);

  const tableRef = React.useRef<MaterialTableProps<NegativeCustomersResponse>>();
  const detailsOccurrenceDialog = React.createRef<DetailsOccurrenceDialogHandle>();
  const [panelFilterParams, setPanelFilterParams] = useState<SelectRegionsDateFilterParams>(PANEL_INITIAL_FILTER_PARAMS);

  const optionsPassedOn = {
    title: `Repasse: ${numbro(totalPassedOn).formatCurrency()}`,
    sliceVisibilityThreshold: 0,
    pieHole: 0.4,
  };
  const optionsEarnings = {
    title: `Speedy: ${numbro(totalEarnings).formatCurrency()}`,
    sliceVisibilityThreshold: 0,
    pieHole: 0.4,
  };
  const optionsTotalValues = {
    title: `Total: ${numbro(grandTotalValue).formatCurrency()}`,
    sliceVisibilityThreshold: 0,
    pieHole: 0.4,
  };

  const optionsDeliveriesPerRegion = {
    title: `Total de Entregas: ${deliveriesPerRegionTotal}`,
    sliceVisibilityThreshold: 0,
    pieHole: 0.4,
  };

  const optionsDeliveriesPerDay = {
    isStacked: true,
  };

  const optionsRechargesPerDay = {
    title: `Total: ${numbro(rechargesPerDayTotal).formatCurrency()}`,
    legend: { position: "none" },
    vAxis: { minValue: 0, viewWindow: { min: 0 } }
  }

  const loadValuesChartsData = () => {

    function compareRegions(a: RegionValues, b: RegionValues) {
      return a.name.localeCompare(b.name);
    }

    setIsLoadingValuesCard(true);

    Promise.all([DeliveryRequestService.getValuesPassedOn(
      panelFilterParams.regionsIds,
      startOfDay(panelFilterParams.date_after),
      endOfDay(panelFilterParams.date_before)
    ), DeliveryRequestService.getEarnings(
      panelFilterParams.regionsIds,
      startOfDay(panelFilterParams.date_after),
      endOfDay(panelFilterParams.date_before)
    )]).then((response) => {
      const passedOnResponse = response[0];
      const earningsResponse = response[1];

      const passedOnRows: GoogleDataTableRow[] = [];
      const earningsRows: GoogleDataTableRow[] = [];
      const totalValuesRows: GoogleDataTableRow[] = [];

      const totalValuesMap = new Map<string, number>();

      setTotalPassedOn(passedOnResponse.total);
      passedOnResponse.per_regions.sort(compareRegions).forEach((item) => {
        passedOnRows.push([{ v: item.name }, { v: item.value, f: numbro(item.value).formatCurrency() }]);
        totalValuesMap.set(item.name, item.value);
      });
      setPassedOnRows(passedOnRows);

      setTotalEarnings(earningsResponse.total);
      earningsResponse.per_regions.sort(compareRegions).forEach((item) => {
        earningsRows.push([{ v: item.name }, { v: item.value, f: numbro(item.value).formatCurrency() }]);

        let total: number | undefined = totalValuesMap.get(item.name);
        if (total !== undefined) {
          total += item.value;
          totalValuesMap.set(item.name, total);
        } else {
          totalValuesMap.set(item.name, item.value);
        }
      });
      setEarningsRows(earningsRows);

      setGrandTotalValue(passedOnResponse.total + earningsResponse.total);
      totalValuesMap.forEach((value, key) => {
        totalValuesRows.push([{ v: key }, { v: value, f: numbro(value).formatCurrency() }]);
      });
      setTotalValuesRows(totalValuesRows);
    }).finally(() => {
      setIsLoadingValuesCard(false);
    });
  }

  const loadDeliveriesPerRegion = () => {
    function compareRegions(a: RegionDeliveries, b: RegionDeliveries) {
      return a.name.localeCompare(b.name);
    }
    
    setIsLoadingDeliveriesPerRegion(true);
    DeliveryRequestService.getQuantityDeliveries(
      panelFilterParams.regionsIds,
      startOfDay(panelFilterParams.date_after),
      endOfDay(panelFilterParams.date_before)
      )
      .then((response) => {
        const rows: GoogleDataTableRow[] = [];

        let totalDeliveries = 0;
        response.sort(compareRegions).forEach((item) => {
          totalDeliveries += item.quantity;
          rows.push([{ v: item.name }, { v: item.quantity }]);
        });
        setDeliveriesPerRegionRows(rows);
        setDeliveriesPerRegionTotal(totalDeliveries);
      })
      .finally(() => {
        setIsLoadingDeliveriesPerRegion(false);
      });
  };

  const loadDeliveriesPerDay = () => {

    function compareRegions(a: DeliveriesPerRegionPerDay, b: DeliveriesPerRegionPerDay) {
      return a.region_name.localeCompare(b.region_name);
    }

    setIsLoadingDeliveriesPerDay(true);
    DeliveryRequestService.getNumberDeliveries(
      panelFilterParams.regionsIds,
      startOfDay(panelFilterParams.date_after),
      endOfDay(panelFilterParams.date_before)
    )
      .then((response) => {
        const days: Date[] = [];
        const date_after = startOfDay(panelFilterParams.date_after);
        const date_before = endOfDay(panelFilterParams.date_before);
        for (let day = date_after; date_after <= date_before; day.setDate(day.getDate() + 1)) {
          days.push(new Date(day));
        }
        const sortedResponse = response.sort(compareRegions);

        const regionNames: string[] = [];
        const quantityPerDayPerRegionMap = new Map<Date, Map<String, number>>();
        sortedResponse.forEach((responseItem) => {
          regionNames.push(responseItem.region_name);
          for (let i = 0; i < days.length; i++) {
            const day = days[i];
            let quantityPerRegionMap = quantityPerDayPerRegionMap.get(day);
            if (quantityPerRegionMap === undefined) {
              quantityPerRegionMap = new Map<string, number>();
            }
            
            quantityPerRegionMap.set(responseItem.region_name, 0);
            quantityPerDayPerRegionMap.set(day, quantityPerRegionMap);
            for (let j = 0; j < responseItem.dates?.length; j++) {
              const deliveriesPerDay: DeliveriesPerDay = responseItem.dates[j];
              if (isSameDay(day, deliveriesPerDay.date)) {
                quantityPerRegionMap.set(responseItem.region_name, deliveriesPerDay.quantity);
              }
            }
          }
        });

        const rows: GoogleDataTableRow[] = days.map(day => {
          const cells: GoogleDataTableCell[] = [];
          cells.push({ v: day });
          regionNames.forEach(regionName => {
            const cacheItem = quantityPerDayPerRegionMap.get(day);
            if (cacheItem !== undefined) {
              const quantity = cacheItem.get(regionName);
              if (quantity !== undefined) {
                cells.push({ v: quantity });
              }
            }
          });
          return cells;
        });

        // Columns
        const columns: GoogleDataTableColumn[] = [];
        columns.push({ label: "Data", type: "date", pattern: CURRENT_DATE_FORMAT });
        regionNames.forEach(regionName => columns.push({ label: regionName, type: "number" }));
        setDeliveriesPerDayColumns(columns);
        setDeliveriesPerDayRows(rows);
        
        setIsLoadingDeliveriesPerDay(false);
      })
      .catch((error) => {
        setIsLoadingDeliveriesPerDay(false);
      });
  };

  const loadRechargesPerDay = () => {
    setIsLoadingRecharges(true);
    CustomerService.getRechargesPerDay(
      panelFilterParams.regionsIds,
      startOfDay(panelFilterParams.date_after),
      endOfDay(panelFilterParams.date_before)
    )
      .then((response) => {
        const days: Date[] = [];
        const date_after = startOfDay(panelFilterParams.date_after);
        const date_before = startOfDay(panelFilterParams.date_before);
        for (let day = date_after; date_after <= date_before; day.setDate(day.getDate() + 1)) {
          days.push(new Date(day));
        }

        const valuePerDayMap = new Map<number, number>();
        response.forEach((item) => {
          valuePerDayMap.set(startOfDay(item.date).getTime(), item.total);
        });

        let total = 0;
        const rows: GoogleDataTableRow[] = days.map(day => {
          const cells: GoogleDataTableCell[] = [];
          cells.push({ v: day, f: format(new Date(day), CURRENT_DATE_FORMAT) });
          let value =  valuePerDayMap.get(day.getTime());
          if (value === undefined) {
            value = 0;
          }
          total += value;         
          cells.push({ v: value, f: numbro(value).formatCurrency() });
          return cells;
        });
        setRechargesPerDayRows(rows);
        setRechargesPerDayTotal(total);
        setIsLoadingRecharges(false);
      })
      .catch((error) => {
        setIsLoadingRecharges(false);
      });
  };

  const loadNegativeCustomers = (query: Query<NegativeCustomersResponse>) =>
    new Promise<QueryResult<NegativeCustomersResponse>>((resolve, reject) => {
      CustomerService.getNegativeCustomers(
        startOfDay(panelFilterParams.date_after),
        endOfDay(panelFilterParams.date_before),
        panelFilterParams.regionsIds,
        PageableParams.fromQuery(query)
      )
        .then((response) => {
          resolve({
            data: response.data,
            page: query.page,
            totalCount: response.count,
          });
        })
        .catch((error) => reject(error));
    });

  const refreshNegativeCustomers = () => {
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange({
        page: 0,
      } as Query<NegativeCustomersResponse>);
    }
  }

  React.useEffect(() => {
    const getRegions = async () => {
      const response = await RegionService.loadAllRegions({ ordering: "name" });
      setRegionList(response);
    };
    getRegions();
  }, []);

  const refreshAll = () => {
    loadValuesChartsData();
    loadDeliveriesPerRegion();
    loadDeliveriesPerDay();
    loadRechargesPerDay();
  };

  const handlePanelFilterChange = (params: SelectRegionsDateFilterParams) => {    
    setPanelFilterParams(params);
  };

  const refreshValues = () => {
    loadValuesChartsData();
  };

  React.useEffect(() => {
    refreshAll();    
  }, [panelFilterParams]);

  return (
    <div>
      <DetailsOccurrenceDialog ref={detailsOccurrenceDialog} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent style={{ paddingBottom: theme.spacing(1) }}>
              <Grid container spacing={2}>
                <Grid item md>
                  <SelectRegionsDateFilter
                    regionList={regionList}
                    params={panelFilterParams}
                    onFilterChanged={handlePanelFilterChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={<Typography variant="h2">Valores</Typography>}
              action={
                <React.Fragment>
                  <Tooltip title="Atualizar">
                    <IconButton
                      onClick={refreshValues}
                      disabled={isLoadingValuesCard}
                      aria-label="Atualizar"
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              }
            />
            <CardMedia style={{ minHeight: "300px" }}>
              {isLoadingValuesCard ? (
                <Grid container justify="center" alignItems="center"
                  style={{ minHeight: "300px" }}>
                  <CircularProgress size={44} />
                </Grid>
              ) : (
                <Grid container justify="center">
                  <Grid item xs={4}>
                    <Chart
                      chartType="PieChart"
                      columns={[
                        { label: "Região", type: "string" },
                        { label: "Valor", type: "number" },
                      ]}
                      rows={passedOnRows}
                      options={optionsPassedOn}
                      width={"100%"}
                      height={"300px"}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Chart
                      chartType="PieChart"
                      columns={[
                        { label: "Região", type: "string" },
                        { label: "Valor", type: "number" },
                      ]}
                      rows={earningsRows}
                      options={optionsEarnings}
                      width={"100%"}
                      height={"300px"}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Chart
                      chartType="PieChart"
                      columns={[
                        { label: "Região", type: "string" },
                        { label: "Valor", type: "number" },
                      ]}
                      rows={totalValuesRows}
                      options={optionsTotalValues}
                      width={"100%"}
                      height={"300px"}
                    />
                  </Grid>
                </Grid>
              )}
            </CardMedia>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardHeader
              title={<Typography variant="h2">Lançamentos de Crédito Por Dia</Typography>}
              action={
                <React.Fragment>
                  <Tooltip title="Atualizar">
                    <IconButton onClick={loadRechargesPerDay}
                      disabled={isLoadingRecharges}
                      aria-label="Atualizar">
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              }
            />
            <CardMedia style={{ minHeight: "300px" }}>
              {isLoadingRecharges ? (
                <Grid container justify="center" alignItems="center"
                  style={{ minHeight: "300px" }}
                >
                  <CircularProgress size={44} />
                </Grid>
              ) : (
                <Chart
                  chartType="ColumnChart"
                  columns={[
                    { label: "Data", type: "date", pattern: CURRENT_DATE_FORMAT },
                    { label: "Total", type: "number" },
                  ]}
                  rows={rechargesPerDayRows}
                  width="100%"
                  height="300px"
                  options={optionsRechargesPerDay}
                />
              )}
            </CardMedia>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardHeader
              title={<Typography variant="h2">Estabelecimentos com Crédito Negativo</Typography>}
              action={
                <React.Fragment>
                  <Tooltip title="Atualizar">
                    <IconButton
                      onClick={refreshNegativeCustomers}
                      aria-label="Atualizar">
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              }
            />
            <CardMedia style={{ minHeight: "300px" }}>
              <MaterialTable
                tableRef={tableRef}
                columns={[
                  { field: "id", hidden: true, width: 60 },
                  {
                    ...DATE_TIME_COLUMN_DEFAULTS,
                    field: "trade_name",
                    title: "Nome"
                  },
                  {
                    field: "waste_limit", title: "Limite de Crédito", type: "currency",
                    render: (rowData, type) => numbro(rowData.waste_limit).formatCurrency()
                  },
                  {
                    field: "current_balance", title: "Saldo", type: "currency",
                    render: (rowData, type) => numbro(rowData.current_balance).formatCurrency()
                  },
                ]}
                data={loadNegativeCustomers}
                components={{
                  ...DEFAULT_TABLE_COMPONENTS,
                  Container: (props: any) => <div {...props}></div>
                }}
                localization={TABLE_L10N_PTBR}
                options={{
                  ...DEFAULT_TABLE_OPTIONS,
                  minBodyHeight: "257px",
                  maxBodyHeight: "257px",
                  pageSize: 7,
                  pageSizeOptions: []
                }}
              />
            </CardMedia>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={<Typography variant="h2">Entregas por Dia</Typography>}
              action={
                <React.Fragment>
                  <Tooltip title="Atualizar">
                    <IconButton onClick={loadDeliveriesPerDay}
                      disabled={isLoadingDeliveriesPerDay}
                      aria-label="Atualizar">
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              }
            />
            <CardMedia style={{ minHeight: "300px" }}>
              {isLoadingDeliveriesPerDay ? (
                <Grid container justify="center" alignItems="center"
                  style={{ minHeight: "300px" }}>
                  <CircularProgress size={44} />
                </Grid>
              ) : (
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="300px"
                  columns={deliveriesPerDayColumns}
                  rows={deliveriesPerDayRows}
                  options={optionsDeliveriesPerDay}
                />
              )}
            </CardMedia>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardHeader
              title={<Typography variant="h2">Total de Entregas</Typography>}
              action={
                <React.Fragment>
                  <Tooltip title="Atualizar">
                    <IconButton
                      onClick={loadDeliveriesPerRegion}
                      disabled={isLoadingDeliveriesPerRegion}
                      aria-label="Atualizar"
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              }
            />
            <CardMedia style={{ minHeight: "300px" }}>
              {isLoadingDeliveriesPerRegion ? (
                <Grid container justify="center" alignItems="center"
                  style={{ minHeight: "300px" }}>
                  <CircularProgress size={44} />
                </Grid>
              ) : (
                <Chart
                  chartType="PieChart"
                  columns={[
                    { label: "Região", type: "string" },
                    { label: "Quantidade", type: "number" },
                  ]}
                  rows={deliveriesPerRegionRows}
                  options={optionsDeliveriesPerRegion}
                  width={"100%"}
                  height={"300px"}
                />
              )}
            </CardMedia>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
