import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
} from "@material-ui/core";
import { DeleteOutlined } from '@material-ui/icons';
import clsx from "clsx";

interface IUploadFile {
  onChange: (file: File | null, fileURL?: string) => void;
  onTitleClick?: (title: string, name: string, src: string) => void;
  title?: string;
  name: string;
  error?: boolean;
  initialFile?: string;
  showDeleteButton?: boolean;
  className?: string;
}

export const UploadFile: React.FC<IUploadFile> = ({onChange, onTitleClick, title="", name, error=false, initialFile, showDeleteButton=true, className}) => {
  const classes = useStyles(error);
  const hiddenFileInput = React.useRef(null);
  const [fileUrl, setFileUrl] = useState<string>("");

  const handleChange = (event: any) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];

    reader.onloadend = () => {
      const url = reader.result ? String(reader.result) : "";
      setFileUrl(url);
      onChange(file, url);
    }

    reader.readAsDataURL(file);
  };

  const handleDelete = () => {
    onChange(null, undefined);
    setFileUrl('');
  }

  const handleTitleClick = () => {
    if (onTitleClick) {
      onTitleClick(title, name, fileUrl);
    }
  }

  useEffect(() => {
    if(initialFile) {
      setFileUrl(initialFile)
    }
  }, [initialFile])

  return (
    <div className={clsx(classes.root, className)}>
      <input
        id={name}
        type="file"
        name={name}
        className={classes.inputFile}
        ref={hiddenFileInput}
        onChange={handleChange}
        accept="image/*"
      />

      <Typography 
        className={clsx(classes.title, {[classes.titleFileSelected]: !!fileUrl, [classes.titleError]: error})} 
        onClick={handleTitleClick}>
          {title}
      </Typography>
      {showDeleteButton && <DeleteOutlined className={classes.buttonDelete} onClick={handleDelete}/>}
      <label 
        htmlFor={name}
        className={classes.buttonAttachment} 
      >
        <Typography 
          variant="subtitle1" 
          className={classes.buttonText}>
            Anexar
        </Typography>
      </label>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: '50px',
      maxWidth: '200px',
      height: '36px',
      margin: '2rem 0'
    },
    inputFile: {
      display: 'none',
    },
    buttonDelete: {
      color: '#526272',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.error.main
      }
    },
    buttonAttachment: {
      background: '#FFFFFF',
      boxShadow: theme.shadows[3],
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '10px',
      opacity: 1,
      padding: '4px 10px',
      marginLeft: '8px',
      cursor: 'pointer',
      outline: 'none',
      '&:hover': {
        background: theme.palette.primary.main,
        '& > *': {
          color: '#fff',
        }
      },
    },
    buttonText: {
      color: `${theme.palette.primary.main}`,
      fontSize: '12px',
    },
    title: {
      flex: 1,
      fontSize: '12px',
      fontWeight: 'lighter',
      whiteSpace: 'pre-wrap',
      cursor: 'initial',
      color: 'initial',
    },
    titleFileSelected: {
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    titleError: {
      color: '#f44336',
    }
  })
);
