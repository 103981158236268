export enum FinancialEntryStatus {
  PROCESSING = "PROCESSING",
  CONFIRMED = "CONFIRMED",
  PAID = "PAID",
  CANCELED = "CANCELED"
}

export enum FinancialEntryOrigin {
  BANK_SLIP = "BANK_SLIP",
  TRANSFER = "TRANSFER",
  CREDIT_CARD = "CREDIT_CARD",
  DELIVERY = "DELIVERY",
  PIX = "PIX"
}

export interface CustomerFinancialEntryResponse {
  id: number,
  created_at: Date,
  payment_date: Date,
  customer_name: string,
  value: number,
  tax: number,
  total: number,
  origin: FinancialEntryOrigin,
  status: FinancialEntryStatus
}

export interface TaxPaymentResponse {
  credit_card_percentage_tax: number,
  bank_slip_tax: number,
  pix_tax: number
}