import { apiAxios as axios } from "../store/api/config";
import { urls } from "../store/api/urls";
import { convertToDate, convertToNumber } from "../utils/utils";
import { ReferralCodesCreate, ReferralSettings } from "./types/referral";

export interface IReferralService {
    getSettings: () => Promise<ReferralSettings>;

    createReferralCodes: (values: ReferralCodesCreate) => Promise<any>;
}

const ReferralService: IReferralService = {
    getSettings: async () => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${urls.REFERRAL_SETTINGS}get_settings/`)
                .then(response => {
                    const data = response.data;
                    const result: ReferralSettings = {
                        ...data,
                        created_at: convertToDate(data.created_at),
                        updated_at: convertToDate(data.updated_at),
                        quantity_of_deliveries: convertToNumber(data.quantity_of_deliveries),
                        reward_amount: convertToNumber(data.reward_amount),
                    }
                    resolve(result)
                })
                .catch(error => reject(error));
        });
    },

    createReferralCodes: async (values: ReferralCodesCreate) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${urls.REFERRAL_CODES}`, values)
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error));
        });
    },

};

export default ReferralService;
