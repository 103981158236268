import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  makeStyles,
  TableContainer,
  Typography,
} from "@material-ui/core";
import theme from "../../../styles/theme";
import { green } from "@material-ui/core/colors";
import { StatsDashboard } from "../../../services/types/deliveryRequest";
import MaterialTable, { MaterialTableProps } from "@material-table/core";
import { DEFAULT_TABLE_OPTIONS, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";

interface DashboardDeliveriesViewProps {
  statsDashboard: StatsDashboard[]
}

const TABLE_LOCALIZATION = {
  ...TABLE_L10N_PTBR,
  body: {
    ...TABLE_L10N_PTBR.body,
    emptyDataSourceMessage: "Nenhum registro encontrado"
  }
}

export const DashboardStatisticsView: React.FC<DashboardDeliveriesViewProps> =
  ({
    statsDashboard,
  }) => {
    const classes = useStyles();
    const tableRef = React.useRef<MaterialTableProps<StatsDashboard>>();
    const [totalFinished, setTotalFinished] = React.useState<number>(0);
    const [totalCanceled, setTotalCanceled] = React.useState<number>(0);

    React.useEffect(() => {
      var _finished: number = 0;
      var _canceled: number = 0;
      if(statsDashboard){
        statsDashboard.forEach((item: StatsDashboard) => {
          _finished += Number(item.finished);
          _canceled += Number(item.canceled);
        });
      }
      
    setTotalFinished(_finished);
    setTotalCanceled(_canceled);

    },[statsDashboard]);

    return (       
      <Grid container md={12} className={classes.page}>
         <Grid md={12} item className={classes.paddingCard}>
            <Card>
              <CardContent>
              <MaterialTable
                tableRef={tableRef}
                title=""
                columns={[
                  {
                    field: "region_name", title: "Região"
                  },
                  {
                    field: "finished", title: "Entregas Finalizadas Hoje", defaultSort: "desc",
                    render: (rowData, type) => <div className={classes.stats}>
                    <Avatar
                      className={`${classes.statsAvatar} ${classes.successDeliveries}`}
                      alt={String(rowData.finished)}
                    >
                      {rowData.finished}
                    </Avatar>
                  </div>
                  },
                  {
                    field: "canceled", title: "Entregas Canceladas Hoje",
                    render: (rowData, type) => 
                    <Avatar
                      className={`${classes.statsAvatar} ${classes.canceledDeliveries}`}
                      alt={String(rowData.canceled)}
                    >
                      {rowData.canceled}
                    </Avatar>
                  },
                ]}
                data={statsDashboard}
                components={{
                  Container: props => (
                    <TableContainer {...props} customStyles={{paddingBotton: theme.spacing(2), paddingTop: theme.spacing(0)}} />
                  )
                }}
                localization={TABLE_LOCALIZATION}
                options={{
                  ...DEFAULT_TABLE_OPTIONS,
                  paging: false,
                  sorting: true,
                  draggable: false,
                  //exportButton: {csv: false},
                  exportAllData: false,
                  toolbar: false,
                  search: false,
                  columnsButton: false,
                  fixedColumns: {}        
                }}
              />
              </CardContent>
              <Grid container spacing={2} style={{textAlign:"end"}}>
              <Grid item container xl={12} md={12} spacing={2} justify="flex-end" style={{alignItems:"center", paddingBottom: "10px", paddingRight: "10px"}}>
                  <Grid item xl={2} md={2} sm={3} xs={12}>
                      <Typography><b>Totais:</b></Typography>
                  </Grid>
                  <Grid item xl={2} md={2} sm={3} xs={12} style={{marginBottom: "5px"}}>
                      <Typography><strong>Totais Finalizadas</strong></Typography><Typography variant="subtitle1"> 
                        <Avatar
                          className={`${classes.statsAvatarTotais} ${classes.successDeliveries}`}
                          alt={String(totalFinished)}
                        >
                          {totalFinished}
                        </Avatar>  
                    </Typography>
                  </Grid>
                  <Grid item xl={2} md={2} sm={3} xs={12} style={{marginBottom: "5px"}}>
                      <Typography><strong>Totais Canceladas</strong></Typography><Typography variant="subtitle1">
                        <Avatar
                          className={`${classes.statsAvatarTotais} ${classes.canceledDeliveries}`}
                          alt={String(totalCanceled)}
                        >
                          {totalCanceled}
                        </Avatar>  
                      </Typography>
                  </Grid>
              </Grid>
          </Grid>
            </Card>
            
          </Grid>
         
      </Grid>
    );
  };

const useStyles = makeStyles({
  page: {
    flex: 1,
    display: "flex",
    height: "100vh",
  },
  stats: {
    display: "inline-block",
  },
  statsAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  statsAvatarTotais: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    display: "inline-flex",
  },
  legend: {
    display: "flex",
    alignItems: "center",
  },
  successDeliveries: {
    backgroundColor: green[500],
  },
  canceledDeliveries: {
    backgroundColor: "red",
  },
  totalDeliveries: {
    backgroundColor: "#526272",
  },
  paddingCard: {
      padding: "8px",
  }
});
