import React from "react";
import { useFormik } from "formik";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@material-ui/core";
import * as Yup from "yup";
import CustomerService from "../../../services/customerService";
import { CurrencyInput } from "../../../components/CurrencyInput";
import { getNumberSpecs } from "../../../i18n";
import CourierService from "../../../services/courierService";
import { convertToNumber } from "../../../utils/utils";
import { CustomerResponse, StoreResponse } from "../../../services/types/customer";
import { PageableParams } from "../../../components/TableTrinkets";
import { debounce } from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { OriginStatus } from "../../../services/types/deliveryRequest";

interface AddCreditsToCourierForm {
  description: string;
  courier_value: number;
  customer_debit: boolean;
  customer_id: number | "";
  customer_value: number;
  referenced_store: number;
  origin_type: string;
}

const INITIAL_FORM_VALUES: AddCreditsToCourierForm = {
  description: "",
  courier_value: 0,
  customer_debit: true,
  customer_id: "",
  customer_value: 0,
  referenced_store: 0,
  origin_type: "",
}

interface AddCreditsToCourierDialogProps {
  courierId: number;
  onAccept?: () => Promise<void>;
}

export interface AddCreditsToCourierDialogHandle {
  open: () => void;
  close: () => void;
}

const validationSchema = Yup.object().shape({
  description: Yup.string()
    .min(3, "Mínimo de 5 caracteres")
    .max(100, "Máximo de 100 caracteres excedido")
    .required("Campo obrigatório"),
  courier_value: Yup.number()
    .required("Campo obrigatório")
    .transform((currentValue, originalValue) => convertToNumber(originalValue))
    .moreThan(0, "Campo obrigatório"),
  customer_id: Yup.string()
    .when("customer_debit", {
      is: true,
      then: Yup.string().min(1, "Campo obrigatório").required("Campo obrigatório"),
      otherwise: Yup.string().optional(),
    }),
  referenced_store: Yup.number()
  .when("customer_debit", {
    is: true,
    then: Yup.number().min(1, "Campo obrigatório").required("Campo obrigatório"),
    otherwise: Yup.number().optional(),
  }),
  customer_value: Yup.number()
    .transform((currentValue, originalValue) => convertToNumber(originalValue))
    .when("customer_debit", {
      is: true,
      then: Yup.number()
        .nullable()
        .required("Campo obrigatório")
        .moreThan(0, "Campo obrigatório")
        .max(Yup.ref('courier_value'), "Não pode ser maior que o valor creditado"),
      otherwise: Yup.number().optional(),
    }),
  origin_type: Yup.string()
  .required("Campo obrigatório"),
    
});

export const AddCreditsToCourierDialog: React.ForwardRefRenderFunction<AddCreditsToCourierDialogHandle, AddCreditsToCourierDialogProps> = (props, ref) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const [customerList, setCustomerList] = React.useState<CustomerResponse[]>([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState<CustomerResponse>();
  const [customerSearchValue, setCustomerSearchValue] = React.useState<string>("");
  const [storeList, setStoreList] = React.useState<StoreResponse[]>([]);

  React.useImperativeHandle(ref, () => {
    return ({
      open() {
        show();
      },

      close() {
        hide();
      }
    });
  });

  const hide = () => {
    setStoreList([]);
    setOpen(false);
    formik.resetForm();
    setSelectedCustomer(undefined);
    setCustomerSearchValue("");
  }

  const show = () => {
    setStoreList([]);
    setOpen(true);
  }

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: async (values) => {
      try {
        const customerValue = values.customer_debit ? values.customer_value : 0;
        const customerId = values.customer_debit ? values.customer_id : null;
        const storeId = values.referenced_store ? values.referenced_store : 0;
        const origin = values.origin_type;
        const deliveryId = null;
        await CourierService.addCredits(
          props.courierId,
          values.description,
          convertToNumber(values.courier_value) || 0,
          customerId === "" ? null : customerId,
          convertToNumber(customerValue) || 0,
          storeId,
          origin,
          deliveryId
        );
        if (props.onAccept) {
          await props.onAccept();
        }
        hide();
        NotificationManager.success(
          "Crédito inserido com sucesso!",
          "Lançamento de crédito"
        );
      } catch (error) {
        const { status, data } = error.response;
        if (status === 400) {
          for (var key in data) {
            var value = data[key];
            formik.setFieldError(key, value);
          }
        } else {
          NotificationManager.error(
            "Não foi possível inserir o crédito!",
            "Lançamento de crédito"
          );
        }
      }
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const loadCustomers = React.useCallback((name: string) => {
    CustomerService.getCustomers(name, undefined, undefined, undefined, undefined, undefined, undefined, new PageableParams(1, 25, "trade_name"))
      .then((response) => {
        setCustomerList(response.data);
      });
  }, []);

  const searchCustomer = React.useMemo(() => debounce((name: string) => {
    if (name?.trim().length > 2) {
      loadCustomers(name);
    }
  }, 300, { trailing: true }), [loadCustomers]);

  const getStoresCustomer = async (idCustomer: number) => {
    const response = await CustomerService.getStores(undefined, idCustomer,
      undefined, undefined, new PageableParams(1, 100, "name"));
    setStoreList(response.data);
    if(response.data.length === 1){
      formik.setFieldValue("referenced_store", response.data[0].id);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={hide}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="form-dialog-title">Adicionar Crédito</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="description"
                label="Descrição"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.description}
                error={!!formik.errors.description}
                helperText={formik.errors.description}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="courier_value"
                label="Valor Creditado"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.courier_value}
                error={!!formik.errors.courier_value}
                helperText={formik.errors.courier_value}
                fullWidth
                InputProps={{
                  inputComponent: CurrencyInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      {getNumberSpecs().currency.symbol}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 10
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="customer_debit"
                    name="customer_debit"
                    checked={formik.values.customer_debit}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Debitar do Cliente"
              />
            </Grid>
            <Grid item xs={12} style={!formik.values.customer_debit ? { display: "none" } : {}}>
              <Autocomplete
                id="customer_id"
                size="small"
                value={selectedCustomer}
                inputValue={customerSearchValue}
                options={customerList}
                getOptionLabel={(customer: CustomerResponse) => customer.trade_name}
                loadingText="Aguarde ..."
                noOptionsText="Sem opções"
                disabled={!formik.values.customer_debit}
                onChange={(e, newValue) => {
                  if (newValue) {
                    getStoresCustomer(newValue.id);
                    setSelectedCustomer(newValue);
                    formik.setFieldValue("customer_id", newValue.id);
                  } else {
                    setStoreList([]);
                    setSelectedCustomer(undefined);
                    formik.setFieldValue("customer_id", "");
                  }
                }}
                onInputChange={(e: object, value: string, reason: string) => {
                  setCustomerSearchValue(value);
                  if (reason === "input") {
                    searchCustomer(value);
                  }
                }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Cliente"
                    variant="outlined"
                    error={!!formik.errors.customer_id}
                    helperText={formik.errors.customer_id || "Digite pelo menos 3 caracteres para realizar a busca"}
                    fullWidth
                  />
                }
              />
            </Grid>
            <Grid item xs={12} style={!formik.values.customer_debit ? { display: "none" } : {}}>
              <TextField
                id="referenced_store"
                name="referenced_store"
                select
                label="Loja"
                value={formik.values.referenced_store}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
                required={true}
                error={!!formik.errors.referenced_store}
                helperText={formik.errors.referenced_store}
                placeholder={selectedCustomer === undefined ? "Selecione um cliente para selecionar uma Loja" : "Selecione uma Loja"}
              >
                <MenuItem disabled selected value="0">{selectedCustomer === undefined ? "Selecione um Cliente para selecionar uma Loja" : "Selecione uma Loja"}</MenuItem>
                {storeList.map(store => (
                  <MenuItem selected={storeList.length === 1 ? true : false} key={store.id} value={store.id}>
                    {store.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} style={!formik.values.customer_debit ? { display: "none" } : {}}>
              <TextField
                id="customer_value"
                label="Valor"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.customer_value}
                error={!!formik.errors.customer_value}
                helperText={formik.errors.customer_value}
                fullWidth
                disabled={!formik.values.customer_debit}
                InputProps={{
                  inputComponent: CurrencyInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      {getNumberSpecs().currency.symbol}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 10
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="origin_type"
                name="origin_type"
                select
                label="Tipo"
                value={formik.values.origin_type}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
                required={true}
                error={!!formik.errors.origin_type}
                helperText={formik.errors.origin_type}
                placeholder={"Selecione o Tipo"}
              >
                  <MenuItem key={OriginStatus.DEDICATED_FEE} value={OriginStatus.DEDICATED_FEE}>
                    Taxa de Dedicado
                  </MenuItem>
                  <MenuItem key={OriginStatus.OTHER} value={OriginStatus.OTHER}>
                    Valor Avulso
                  </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide}>
            Cancelar
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={formik.isSubmitting}
            startIcon={formik.isSubmitting && (
              <CircularProgress size="1.5rem" color="inherit" />
            )}
          >
            Adicionar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default React.forwardRef(AddCreditsToCourierDialog);
