import React, { useEffect } from "react";
import { useFormik } from "formik";
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Card,
  Box,
  CardContent,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as LogoNameIcon } from "../../assets/img/logo-azul.svg";
import { Actions as authActions } from "../../store/ducks/auth";
import { useSelector, useDispatch } from "react-redux";
import { IGlobalState } from "../../store/types/state";
import * as Yup from "yup";
import { useHistory } from "react-router";

interface IRequestPasswordProps { }
const validationSchema = Yup.object().shape({
  email: Yup.string().email("E-mail inválido").required("Campo obrigatório"),
});
export const RequestPassword: React.FC<IRequestPasswordProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { error, status } = useSelector(
    (state: IGlobalState) => state.auth
  );

  useEffect(() => {
    if (status === "send")
      setTimeout(() => history.push({ pathname: "/" }), 5000);
  }, [history, status]);

  const formik = useFormik({
    initialValues: { email: "" },
    onSubmit: async (formValues) => {
      authActions.requestPasswordReset(dispatch, formValues.email);
    },
    validationSchema,
  });
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
      spacing={5}
    >
      <Grid item>
        <Card className={classes.card}>
          <Box p={1} pt={5}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <LogoNameIcon width={150} fill="#fff" />
              </Grid>
              <Grid item xs={12} md={12} className={classes.alert}>
                {error && (
                  <Alert severity="error" variant="filled">
                    {error}
                  </Alert>
                )}
                {status === "send" && (
                  <Alert severity="success" variant="filled">
                    Siga as instruções do e-mail que enviamos para você
                  </Alert>
                )}
              </Grid>
            </Grid>
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Typography variant="h3">
                  Digite o e-mail associado a sua conta
                </Typography>
                <Typography variant="body2">
                  Nós enviaremos um e-mail com um link para você redefinir a sua
                  senha
                </Typography>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField
                      id="email"
                      label="E-mail"
                      variant="outlined"

                      value={formik.values.email}
                      // className={classes.textField}
                      onChange={formik.handleChange}
                      error={!!formik.errors.email}
                      helperText={formik.errors.email}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? (
                        <CircularProgress size="1.5rem" color="inherit" />
                      ) : (
                          <span>Enviar</span>
                        )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.primary.main,
      height: "100%",
      margin: 0,
    },
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    image: {
      width: "160px",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(10),
    },
    card: {
      minWidth: "550px",
      borderRadius: "4px",
      boxShadow: "0 3px 6px rgba(0,0,0,.16)",
    },
    button: {
      width: 1,
      marginTop: "10px",
    },
    alert: {
      width: "94%",
    },
  })
);
