import React, { useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FormikHelpers, useFormik } from "formik";
import { DEFAULT_LOCALE, CURRENT_DATE_TIME_FORMAT } from "../../i18n";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router";
import { encodeURLParams } from "../../utils/utils";

export interface IIncentiveFilterParams {
    region: string;
    start_date_after: Date | null;
    start_date_before: Date | null;
    end_date_after: Date | null;
    end_date_before: Date | null;
}

export const INITIAL_FILTER_PARAMS: IIncentiveFilterParams = {
    region: "",
    start_date_after: null,
    start_date_before: null,
    end_date_after: null,
    end_date_before: null,
};

interface IIncentiveFilterProps {
    params?: IIncentiveFilterParams;
    regionList: any[];
    onFilterChanged: (params: IIncentiveFilterParams) => void;
}

export const IncentiveFilter: React.FC<IIncentiveFilterProps> = ({
    params = INITIAL_FILTER_PARAMS,
    regionList,
    onFilterChanged,
}) => {
    const [selectedRegion, setSelectedRegion] = useState<any>(null);
    const history = useHistory();

    React.useEffect(() => {
        history.replace(encodeURLParams(history.location.pathname, params));
    }, [history, params]);

    const formik = useFormik<IIncentiveFilterParams>({
        initialValues: params,
        onSubmit: (values: IIncentiveFilterParams, formikHelpers: FormikHelpers<IIncentiveFilterParams>) => {
            onFilterChanged(values);
        },
        onReset: (values: IIncentiveFilterParams, formikHelpers: FormikHelpers<IIncentiveFilterParams>) => {
            setSelectedRegion(null);
            onFilterChanged(INITIAL_FILTER_PARAMS);
            formikHelpers.setValues(INITIAL_FILTER_PARAMS);
        },
    });

    return (
        <div className="page-filter">
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Grid container spacing={2}>
                    <Grid item sm={5} md={4} lg={3} xl={2}>
                        <Autocomplete
                            id="region"
                            size="small"
                            value={selectedRegion}
                            options={regionList}
                            getOptionLabel={option =>
                                `${option.name} - ${option.state}`
                            }
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setSelectedRegion(newValue);
                                    formik.setFieldValue("region", newValue.id);
                                } else {
                                    setSelectedRegion(null);
                                    formik.setFieldValue("region", null);
                                }
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    name="region"
                                    label="Região"
                                    onChange={formik.handleChange}
                                    value={formik.values.region}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item sm={5} md={4} lg={3} xl={2}>
                        <DatePicker
                            id="start_date_after"
                            name="start_date_after"
                            onChange={(val, event: React.SyntheticEvent) => {
                                formik.setFieldValue("start_date_after", val);
                                event.preventDefault();
                            }}
                            maxDate={new Date()}
                            selected={formik.values.start_date_after}
                            startDate={formik.values.start_date_after}
                            endDate={formik.values.start_date_before}
                            selectsStart
                            locale={DEFAULT_LOCALE}
                            dateFormat={CURRENT_DATE_TIME_FORMAT}
                            timeCaption="Hora"
                            isClearable
                            showTimeSelect
                            autoComplete="off"
                            wrapperClassName="MuiFormControl-fullWidth"
                            customInput={<TextField
                                label="Data Inicial da Vigência"
                                variant="outlined"
                                size="small"
                                error={!!formik.errors.start_date_after}
                                helperText={formik.errors.start_date_after}
                                fullWidth />}
                        />
                    </Grid>
                    <Grid item sm={5} md={4} lg={3} xl={2}>
                        <DatePicker
                            id="start_date_before"
                            name="start_date_before"
                            onChange={(val, event: React.SyntheticEvent) => {
                                formik.setFieldValue("start_date_before", val);
                                event.preventDefault();
                            }}
                            maxDate={new Date()}
                            selected={formik.values.start_date_before}
                            startDate={formik.values.start_date_after}
                            endDate={formik.values.start_date_before}
                            minDate={formik.values.start_date_after}
                            selectsEnd
                            locale={DEFAULT_LOCALE}
                            dateFormat={CURRENT_DATE_TIME_FORMAT}
                            timeCaption="Hora"
                            isClearable
                            showTimeSelect
                            autoComplete="off"
                            wrapperClassName="MuiFormControl-fullWidth"
                            customInput={<TextField
                                label="Data Final da Vigência"
                                variant="outlined"
                                size="small"
                                error={!!formik.errors.start_date_before}
                                helperText={formik.errors.start_date_before}
                                fullWidth />}
                        />
                    </Grid>
                    <Grid item sm={5} md={4} lg={3}>
                        <DatePicker
                            id="end_date_after"
                            name="end_date_after"
                            onChange={(val, event: React.SyntheticEvent) => {
                                formik.setFieldValue("end_date_after", val);
                                event.preventDefault();
                            }}
                            maxDate={new Date()}
                            selected={formik.values.end_date_after}
                            startDate={formik.values.end_date_after}
                            endDate={formik.values.end_date_before}
                            selectsStart
                            locale={DEFAULT_LOCALE}
                            dateFormat={CURRENT_DATE_TIME_FORMAT}
                            timeCaption="Hora"
                            isClearable
                            showTimeSelect
                            autoComplete="off"
                            wrapperClassName="MuiFormControl-fullWidth"
                            customInput={<TextField
                                label="Data Inicial do Término da Vigência"
                                variant="outlined"
                                size="small"
                                error={!!formik.errors.end_date_after}
                                helperText={formik.errors.end_date_after}
                                fullWidth />}
                        />
                    </Grid>
                    <Grid item sm={5} md={4} lg={3}>
                        <DatePicker
                            id="end_date_before"
                            name="end_date_before"
                            onChange={(val, event: React.SyntheticEvent) => {
                                formik.setFieldValue("end_date_before", val);
                                event.preventDefault();
                            }}
                            maxDate={new Date()}
                            selected={formik.values.end_date_before}
                            startDate={formik.values.end_date_after}
                            endDate={formik.values.end_date_before}
                            minDate={formik.values.end_date_after}
                            selectsEnd
                            locale={DEFAULT_LOCALE}
                            dateFormat={CURRENT_DATE_TIME_FORMAT}
                            timeCaption="Hora"
                            isClearable
                            showTimeSelect
                            autoComplete="off"
                            wrapperClassName="MuiFormControl-fullWidth"
                            customInput={<TextField
                                label="Data Final do Término da Vigência"
                                variant="outlined"
                                size="small"
                                error={!!formik.errors.end_date_before}
                                helperText={formik.errors.end_date_before}
                                fullWidth />}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Pesquisar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="reset"
                        >
                            Limpar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
