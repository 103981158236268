import React from "react";
import { Card, CardContent, Grid, makeStyles, Typography, } from "@material-ui/core";
import theme from "../../../styles/theme";
import clsx from "clsx";
import { TurnResponse } from "../../../services/types/dedicated";
import ScheduleIcon from '@material-ui/icons/Schedule';
import StoreIcon from '@material-ui/icons/Store';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import numbro from "numbro";

interface TurnCardProps {
  turn: TurnResponse;
}

export const TurnCard: React.FC<TurnCardProps> = ({ turn }) => {
  const classes = useStyles();

  const splitHour = (hour: string) => {
    let splt = hour.split(":");
    return `${splt[0]}:${splt[1]}`;
  }

  return (
    <React.Fragment>

      <Card variant="outlined" className={clsx(classes.card, { [classes.selectedCard]: false })}>
          <CardContent className={classes.content}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" noWrap
                  title={`${turn.start_time} - ${turn.end_time}`}>
                  <ScheduleIcon
                    className={classes.styleStarRanking}
                    fontSize="small"
                  />
                  {`${splitHour(turn.start_time)} - ${splitHour(turn.end_time)}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" noWrap title={`${turn.number_of_slots}`}>
                  <StoreIcon
                    className={classes.styleStarRanking}
                    fontSize="small"
                  />
                  Vaga(s): {turn.number_of_slots}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" noWrap title={`${turn.value}`}>
                <MonetizationOnIcon
                    className={classes.styleStarRanking}
                    fontSize="small"
                />
                    Valor: {numbro(turn.value).formatCurrency()}
                </Typography>                
              </Grid>
            </Grid>
          </CardContent>
      </Card>
    </React.Fragment>
  );
}

const useStyles = makeStyles({
  content: {
    padding: theme.spacing(1) / 2,
    paddingBottom: "8px !important",
  },
  statusChip: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: "20px !important",
    fontSize: "0.8rem !important",
  },
  card: {
    position: "relative",
    zIndex: 1,
    marginBottom: "5px"
  },
  selectedCard: {
    borderColor: theme.palette.warning.main,
    borderWidth: "4px",
    margin: "-3px",
    zIndex: 2
  },
  paper: {
    padding: theme.spacing(2),
  },
  popover: {
    pointerEvents: 'none',
  },
  cardTitle: {
    fontSize: "24px",
    marginBottom: "8px",
    fontWeight: "bold",
  },
  styleStarRanking: {
    position: "relative",
    top: "3px",
    marginRight: "4px",
  },
  advancedSpeederStar: {
    color: theme.palette.primary.main
  }
});