import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { CURRENT_DATE_FORMAT, CURRENT_DATE_FORMAT_MONTH_YEAR } from "../../../i18n";
import {
    Button,
    Card,
    CardContent,
    createStyles,
    debounce,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Theme,
    Typography,
} from "@material-ui/core";
import { LoadingPage } from "../../../components/Loading";
import { CustomBreadcrumbs } from "../../../components/CustomBreadcrumbs";
import DebitNoteService from "../../../services/debitNotesService";
import { DetailsDebitNoteResponse } from "../../../services/types/debitNotes";
import MaterialTable from "@material-table/core";
import { CURRENCY_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";
import { ptBR } from "date-fns/locale";
import { formatCPFCNPJ } from "../../../utils/formatters";
import numbro from "numbro";
import { NoteAddOutlined } from "@material-ui/icons";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import DownloadIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";

import fileDownload from "js-file-download";
import { LoadingDialog } from "../../../components/LoadingDialog";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../../components/ConfirmationDialog";

interface IDebitNoteDetails {
    debitNoteId: number;
}

export const DebitNoteDetails: React.FC<IDebitNoteDetails> = ({ debitNoteId }) => {
    const [debitNote, setDebitNote] = useState<DetailsDebitNoteResponse>();
    const [loadingDialogMessage, setLoadingDialogMessage] = React.useState("");
    const [isShowLoadingDialog, showLoadingDialog] = React.useState(false);

    const [totaisRepasse, setTotaisRepasse] = useState<number>(0);
    const [totaisIntermediacao, setTotaisIntermediacao] = useState<number>(0);
    const [totais, setTotais] = useState<number>(0);
    const [fileName, setFileName] = useState<string>("");
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const confirmationDialog = React.createRef<ConfirmationDialogHandle>();

    const loadDebitNotes = async (id: number) => {
        const response = await DebitNoteService.getDebitNote(id);
        setDebitNote(response);          
        if(response.entries){
            var _repasse = 0;
            var _intermeciacao = 0;
            var _totais = 0;
            response.entries.forEach((resp: any) => {
                if(resp.data){
                    resp.data.forEach((data: any) => {
                        _repasse += Number(data.total_passed_on);
                        _intermeciacao += Number(data.intermediated_total);
                        _totais += Number(data.total);
                    });
                }                 
            });
            setTotaisRepasse(_repasse);
            setTotaisIntermediacao(_intermeciacao);
            setTotais(_totais);
        }        
    };

    useEffect(() => {
        if (debitNoteId) {
            loadDebitNotes(Number(debitNoteId));
        }
    }, [debitNoteId]);

    function capitalizeFirstLetter(txt: string) {
        return txt.charAt(0).toUpperCase() + txt.slice(1);
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleClose = () => {       
        setOpenDialog(false);
        setFile(null);
        setFileName("");
    };  

    const handleChange = (event: any) => {
        event.preventDefault();
        const reader = new FileReader();
        const file = event.target.files[0];
        setFileName(file.name);
        setFile(file);  
        reader.readAsDataURL(file);
    };

    const handleUploadFile = () =>{
        if(file && debitNoteId){
            DebitNoteService.uploadInvoiceFile(Number(debitNoteId), file).then((res) => {
                handleClose();
                NotificationManager.success(
                    "Nota Fiscal anexada com sucesso.",
                    "Nota Fiscal"
                );
                loadDebitNotes(Number(debitNoteId));
            }).catch((erro) => {
                NotificationManager.error(
                    "Erro ao anexar Nota Fiscal.",
                    "Nota Fiscal"
                );
            });
        }
    }
    
    const handleDownloadNotaFiscal = () => {
        if(debitNote && debitNote.invoice){
            setLoadingDialogMessage("Baixando a Nota Fiscal, aguarde ...");
            showLoadingDialog(true);
            DebitNoteService.downloadInvoice(debitNote.invoice).then((response) => {
                fileDownload(response.data, `Nota Fiscal ${debitNote.number}.pdf`);
            }) 
            .catch(err => {
                NotificationManager.error(
                  "Erro ao fazer download da Nota Fiscal.",
                  "Nota Fiscal"
                );
              })
            .finally(() => {
                showLoadingDialog(false);
            });
        }
    }

    const handleRemoveNotaFiscal = () => {      
        confirmationDialog.current?.confirm(
            "Remover Nota Fiscal",
            <Typography>Você tem certeza que deseja <strong>Remover</strong> esta Nota Fiscal?</Typography>,
            "Remover",
            "Cancelar"
        ).then(() => {
                removeNotaFiscal();
        }).catch(() => { });
    }
    

    const removeNotaFiscal = React.useMemo(() => debounce(async () => {
        return new Promise((resolve, reject) => {
            if(debitNote && debitNote.invoice){
                DebitNoteService.removeInvoice(debitNote.id).then((response) => {
                    handleClose();
                    NotificationManager.success(
                        "Nota Fiscal removida com sucesso.",
                        "Nota Fiscal"
                    );
                    loadDebitNotes(Number(debitNoteId));
                    confirmationDialog.current?.hide();
                }) 
                .catch(err => {
                    NotificationManager.error(
                        "Erro ao realizar remoção da Nota Fiscal.",
                        "Nota Fiscal"
                    );
                });
            }        
        });
      }, 1000), [debitNote, confirmationDialog, debitNoteId]);

    const downloadDebitNotes = () => {
        if(debitNoteId){
            setLoadingDialogMessage("Baixando a Nota de Débito, aguarde ...");
            showLoadingDialog(true);
            DebitNoteService.downloadDebitNote(Number(debitNoteId))
              .then(res => {
                fileDownload(res.data, "Nota de Débito.pdf");
              })
              .catch(err => {
                NotificationManager.error(
                  "Erro ao fazer download da Nota de Débito.",
                  "Nota de Débito"
                );
              })
              .finally(() => {
                showLoadingDialog(false);
              });
        }
       
      };

    return (
        <div>
            <LoadingDialog open={isShowLoadingDialog} message={loadingDialogMessage} />
            <ConfirmationDialog ref={confirmationDialog} />

            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h1">
                                Detalhes da Nota de Débito
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomBreadcrumbs
                                pathList={[
                                    { label: "Notas de Débito", url: "/debitNotes" },
                                    {
                                        label: "Detalhes do Incentivo",
                                        url: `/debitNotes/detail/${debitNoteId}`,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        {debitNote && !debitNote?.invoice && (
                            <Grid item>
                                <Button 
                                    variant="contained" 
                                    color="secondary"
                                    onClick={() => {
                                        handleOpenDialog();
                                    }}
                                    startIcon={<NoteAddOutlined />}>
                                    Anexar Nota Fiscal
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            <Button 
                                variant="contained" 
                                color="secondary"
                                onClick={() => {
                                    downloadDebitNotes();
                                }}
                                startIcon={<DownloadIcon />}>
                                Baixar PDF
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

            {!debitNote ? (
                <LoadingPage />
            ) : (
                <div>
                <Dialog open={openDialog} fullWidth maxWidth="sm" onClose={handleClose}>
                    <DialogTitle>Nota Fiscal</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Anexe a Nota Fiscal abaixo.
                        </DialogContentText>

                        <Paper className={classes.fileInput}>
                            <label htmlFor="noteFiscal" className={classes.buttonAttachment}>
                                <Typography color="primary" variant="subtitle1" className={classes.buttonText}>
                                    {fileName.length ? fileName : "Selecione a Nota Fiscal"}
                                </Typography>
                            </label>
                            <input
                            id="noteFiscal"
                            type="file"
                            accept=".pdf"
                            onChange={(e) => handleChange(e)}
                            />
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancelar</Button>
                        <Button color="primary" disabled={file == null} onClick={handleUploadFile}>Salvar</Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            <span style={{marginRight:"30px"}}><b>Número:</b> #{debitNote.number}</span> <span style={{marginRight:"5x"}}><b>Emissão:</b> {debitNote.emission_date ? format(debitNote.emission_date, CURRENT_DATE_FORMAT) : "---"}</span> - <span style={{marginLeft:"5x", marginRight:"10px"}}><b>Referência:</b> {debitNote.reference_date ? capitalizeFirstLetter(format(debitNote.reference_date, CURRENT_DATE_FORMAT_MONTH_YEAR, {locale: ptBR})) : "---"} </span>
                            {debitNote && debitNote?.invoice && (
                                <span className={classes.styleBtnNote}> <b>Nota Fiscal: </b>
                                    <Button                             
                                        color="primary"
                                        onClick={() => {
                                            handleDownloadNotaFiscal();
                                        }}
                                        startIcon={<DownloadIcon />}>
                                        Baixar
                                    </Button>
                                    /
                                    <Button                             
                                        color="secondary"
                                        onClick={() => {
                                            handleRemoveNotaFiscal();
                                        }}
                                        startIcon={<DeleteIcon />}>
                                        Remover
                                    </Button>
                                </span>
                            )}
                                                                                       
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item container xl={12} md={12} spacing={2}>
                                <Grid item xl={3} md={3} sm={4} xs={12}>
                                    <Typography>Data da Criação</Typography>
                                    <Typography variant="subtitle1">{debitNote.created_at ? format(debitNote.created_at, CURRENT_DATE_FORMAT) : "---"}</Typography>
                                </Grid>
                                <Grid item xl={3} md={3} sm={4} xs={12}>
                                    <Typography>Data de Atualização</Typography>
                                    <Typography variant="subtitle1">{debitNote.updated_at ? format(debitNote.updated_at, CURRENT_DATE_FORMAT) : "---"}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item container xl={12} md={12} spacing={2}>
                                    <Table className="table-bordered">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography>Emissor</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.issuer.name || "-"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell colSpan={6}>
                                                    <Typography>CNPJ</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.issuer ? debitNote.issuer.registration_number ? formatCPFCNPJ(debitNote.issuer.registration_number) : "---" : "---"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography>Rua</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.issuer.address.street || "-"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell colSpan={1}>
                                                    <Typography>N°</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.issuer.address.number || "-"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell colSpan={1}>
                                                    <Typography>Bairro</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.issuer.address.district || "-"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell colSpan={1}> 
                                                    <Typography>CEP</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.issuer.address.postal_code || "-"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell colSpan={1}>
                                                    <Typography>Estado</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.issuer.address.state || "-"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell colSpan={1}>
                                                    <Typography>Cidade</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.issuer.address.city || "-"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item container xl={12} md={12} spacing={2}>
                                    <Table className="table-bordered">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography>Recebedor</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.customer.contact_name +" - "+ debitNote.customer.corporate_name || "-"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell colSpan={6}>
                                                    <Typography>CNPJ</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.customer ? debitNote.customer.registration_number ? formatCPFCNPJ(debitNote.customer.registration_number) : "---" : "-"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography>Rua</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.customer.fiscal_address_street || "-"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell colSpan={1}>
                                                    <Typography>N°</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.customer.fiscal_address_number || "-"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell colSpan={1}>
                                                    <Typography>Bairro</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.customer.fiscal_address_district || "-"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell colSpan={1}>
                                                    <Typography>CEP</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.customer.fiscal_address_postal_code || "-"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell colSpan={1}>
                                                    <Typography>Estado</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.customer.fiscal_address_city.state_name || "-"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell colSpan={1}>
                                                    <Typography>Cidade</Typography>
                                                    <Typography variant="subtitle1">
                                                        {debitNote.customer.fiscal_address_city.name || "-"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {debitNote.entries.map((item, index) => {
                    return (
                    <Grid key={index} item xs={12}>
                        <Card>
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                <span><b>Loja:</b> {item.store_name}</span>
                            </Typography>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <MaterialTable
                                            columns={[
                                            { field: "id", hidden: true, width: 60 },
                                            { field: "source_name", title: "Origem" },
                                            { field: "quantity", title: "Quantitativo" },
                                            { ...CURRENCY_COLUMN_DEFAULTS, field: "total_passed_on", title: "Valor Total de Repasse (a)" },
                                            {
                                                ...CURRENCY_COLUMN_DEFAULTS,
                                                field: "intermediated_total", title: "Valor Total de Intermediação (b)"
                                            },
                                            {
                                                ...CURRENCY_COLUMN_DEFAULTS,
                                                field: "total", title: "Total (a+b)"
                                            },
                                            ]}
                                            data={item.data}
                                            components={{
                                            ...DEFAULT_TABLE_COMPONENTS,
                                            Container: (props: any) => <div {...props}></div>
                                            }}
                                            localization={TABLE_L10N_PTBR}
                                            options={{
                                            ...DEFAULT_TABLE_OPTIONS,
                                            paging: false
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{textAlign:"end"}}>
                                    <Grid item container xl={12} md={12} spacing={2} justify="flex-end" style={{alignItems:"center", marginTop:"10px", paddingRight: "0px"}}>
                                        <Grid item xl={2} md={2} sm={3} xs={12}>
                                            <Typography><b>Totais</b></Typography>
                                        </Grid>
                                        <Grid item xl={2} md={2} sm={3} xs={12}>
                                            <Typography>Totais de Repasse</Typography><Typography variant="subtitle1">{numbro(totaisRepasse).formatCurrency()}</Typography>
                                        </Grid>
                                        <Grid item xl={2} md={2} sm={3} xs={12}>
                                            <Typography>Totais de Intermediação</Typography><Typography variant="subtitle1">{numbro(totaisIntermediacao).formatCurrency()}</Typography>
                                        </Grid>
                                        <Grid item xl={2} md={2} sm={3} xs={12}>
                                            <Typography>Total</Typography><Typography variant="subtitle1">{numbro(totais).formatCurrency()}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{textAlign:"end"}}>
                                    <Grid item container xl={12} md={12} spacing={2} justify="flex-end" style={{alignItems:"center", marginTop:"10px", paddingRight: "0px"}}>
                                    <Typography gutterBottom variant="h6" component="h6">
                                        <strong>VALOR DA NOTA DE DÉBITO: {numbro(totaisRepasse).formatCurrency()}</strong>
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        
                    </Grid>  
                    )                  
                })}

                
            </Grid>
            </div>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileInput: {
      position: "absolute",
      zIndex: 22,
      left: "20px",
      bottom: "20px",
      borderRadius: "4px",
      "& label": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

       /*  "& h6": {
          borderBottom: "solid 1px #000",
          paddingRight: "30px",
        }, */
      },

      "& input": {
        display: "none",
      },
    },
    inputFile: {
        display: 'none',
    },

    buttonAttachment: {
        background: '#FFFFFF',
        boxShadow: theme.shadows[3],
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '5px',
        opacity: 1,
        padding: '4px 10px',
        //marginLeft: '8px',
        cursor: 'pointer',
        outline: 'none',
        '&:hover': {
          background: theme.palette.primary.main,
          '& > *': {
            color: '#fff',
          }
        },
      },
      buttonText: {
        color: `${theme.palette.primary.main}`,
        fontSize: '12px',
      },

      styleBtnNote: {
          position: "absolute",
          //marginRight: "25px",
          right: "35px"
      }

  })
);