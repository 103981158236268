import React, { useCallback, useEffect } from "react";
import { format } from "date-fns";
import { makeStyles, createStyles, Theme, Typography, Card, CardContent, styled } from "@material-ui/core";

import DeliveryRequestService from "../../../services/deliveryRequestService";
import { CURRENT_DATE_TIME_FORMAT } from "../../../i18n";
import { DeliveryRequest, OriginStatus } from "../../../services/types/deliveryRequest";
import { CourierOperation } from "../../../services/types/courier";
import numbro from "numbro";
import MaterialTable, { MaterialTableProps } from "@material-table/core";
import { DATE_TIME_COLUMN_DEFAULTS, DEFAULT_TABLE_COMPONENTS, DEFAULT_TABLE_OPTIONS, TABLE_L10N_PTBR } from "../../../components/TableTrinkets";
interface IReleasesProps {
  deliveryRequest: DeliveryRequest;
}

interface IMoneyText {
    value: number;
    type: string;
    origin: string;
}

const MoneyText: React.FC<IMoneyText> = ({ value, type, origin }) => {
    let money = {negative : false, value: ""};
    if(origin === OriginStatus.DISCOUNT){
      money = { negative: true, value: `${numbro(value).formatCurrency()}` };
    }else{
      money = (type === "CREDIT")
      ? { negative: false, value: `+${numbro(value).formatCurrency()}` }
      : { negative: true, value: `-${numbro(value).formatCurrency()}` }
    }
     
  
    const MoneyTypo = styled(Typography)({
      color: money.negative ? '#E86262' : '#6CBE54',
    })
  
    return <MoneyTypo variant="subtitle1">{money.value}</MoneyTypo>
  }

export const Releases: React.FC<IReleasesProps> = ({ deliveryRequest }) => {
  const classes = useStyles();
  const [releasesDelivery, setReleasesDelivery] = React.useState<CourierOperation[]>([]);
  const tableRef = React.useRef<MaterialTableProps<CourierOperation[]>>();

  const loadReleases = useCallback(async (id: number) => {
    const response = await DeliveryRequestService.getReleasesDelivery(id, true);
    setReleasesDelivery(response);
  }, []);

  useEffect(() => {
    loadReleases(deliveryRequest.id);
  }, [deliveryRequest, loadReleases]);

  return (
    <div className={classes.root}>
      <Typography variant="h3" style={{ marginBottom: "30px" }}>
        Lançamentos
      </Typography>

        <MaterialTable
          tableRef={tableRef}
          columns={[
            { field: "id", hidden: true, width: 60 },
            { ...DATE_TIME_COLUMN_DEFAULTS, field: "created_at", title: "Criado em" },
            { field: "description", title: "Descrição" },
            {
              field: "value", title: "Valor", align:"right",
              render: (rowData, type) => rowData.value ? <MoneyText value={rowData.value} type={rowData.type} origin={rowData.origin} />  : "---"
            },

          ]}
          data={releasesDelivery}
          components={DEFAULT_TABLE_COMPONENTS}
          localization={TABLE_L10N_PTBR}
          options={DEFAULT_TABLE_OPTIONS}
        />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  })
);
