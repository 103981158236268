import React from "react";
import { FormControlLabel, FormGroup, Grid, makeStyles, Switch, Theme, Typography, withStyles } from "@material-ui/core";
import { DeliveryRequestAction, DeliveryRequestStatus, PendingDeliveryRequest, PendingTrip, TripDeliveryRequest, TripStatus } from "../../../services/types/deliveryRequest";
import DashboardColumn from "../customComponents/dashboardColumn";
import FilterListIcon from '@material-ui/icons/FilterList';
import DashboardSelectMenu from "../customComponents/dashboardSelectMenu";
import { useTranslation } from "react-i18next";
import { TripCard } from "../customComponents/tripCard";
import { useHistory } from "react-router";
import { CardStatusChip, DeliveryRequestCardNew, StatusSeverity } from "../customComponents/deliveryRequestCardNew";
import { debounce } from "lodash";
import TripService from "../../../services/tripService";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../../components/ConfirmationDialog";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import SelectCourierDialog from "../customComponents/selectCourierDialog";
import { OnlineCourierUserDetails } from "../../../services/types/courier";
import DeliveryRequestService from "../../../services/deliveryRequestService";
import JoinDeliveriesDialog, { JoinDeliveriesDialogHandle } from "../customComponents/joinDeliveriesOnTripDialog";

interface DashboardDeliveriesViewProps {
    deliveryRequestList: PendingDeliveryRequest[];
    tripList: PendingTrip[];
    searchValue?: string;
    deliveryRequestDetailsAction: (deliveryRequest: PendingDeliveryRequest | number) => void;
    onRetrievesNewChange: (isRetrieveNew: boolean) => void;
    loadData: () => void;
}

const waitingColumnStatusList = [
    TripStatus.ACCEPTED,
    TripStatus.AWAITING_RELEASE,
    TripStatus.AWAITING_COLLECTION,
]

const outColumnStatusList = [
    TripStatus.ON_WAY,
    TripStatus.RETURNING,
    TripStatus.PARTIALLY_CANCELED,
    TripStatus.BACK_TO_STORE,
    TripStatus.FINISHED,
    TripStatus.CANCELED,
]

const GreenSwitch = withStyles({
    switchBase: {
        '&$checked': {
            color: "#91ff35",
        },
    },
    checked: {},
    track: {},
})(Switch);

export const DashboardDeliveriesView: React.FC<DashboardDeliveriesViewProps> = ({
    deliveryRequestList,
    tripList,
    searchValue,
    deliveryRequestDetailsAction,
    onRetrievesNewChange,
    loadData }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [noCourierList, setNoCourierList] = React.useState<PendingTrip[]>([]);
    const [waitingList, setWaitingList] = React.useState<PendingTrip[]>([]);
    const [outList, setOutList] = React.useState<PendingTrip[]>([]);

    const [retrieveNew, setRetrieveNew] = React.useState(false);
    const [waitingColumnFilter, setWaitingColumnFilter] = React.useState<string>("ALL");
    const [outColumnFilter, setOutColumnFilter] = React.useState<string>("ALL");

    const [globalSearchValue, setGlobalSearchValue] = React.useState<string>("");
    const [filteredTripList, setFilteredTripList] = React.useState<PendingTrip[]>([]);
    const [filteredDeliveryRequest, setFilteredDeliveryRequest] = React.useState<PendingDeliveryRequest[]>([]);

    const [openSelectCourierDialog, setOpenSelectCourierDialog] = React.useState<boolean>(false);
    const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
    const joinDeliveriesDialog = React.createRef<JoinDeliveriesDialogHandle>();

    const [selectedTrip, setSelectedTrip] = React.useState<PendingTrip>();
    const [selectedDelivery, setSelectedDelivery] = React.useState<PendingDeliveryRequest>();

    const history = useHistory();

    React.useEffect(() => {
        if (searchValue) {
            setGlobalSearchValue(searchValue);
        } else {
            setGlobalSearchValue("");
        }
    }, [searchValue]);

    React.useEffect(() => {
        if (deliveryRequestList) {
            const filtered = deliveryRequestList.filter((delivery) => {
                let shouldShow = false;
                switch (delivery.status) {
                    case DeliveryRequestStatus.NO_COURIER:
                        shouldShow = true;
                        break;
                    case DeliveryRequestStatus.NEW:
                        if (retrieveNew) {
                            shouldShow = true;
                        } else {
                            shouldShow = false;
                        }
                        break;
                    default:
                        shouldShow = false;
                        break;
                }

                if (shouldShow && globalSearchValue && globalSearchValue.trim().length) {
                    shouldShow = false;
                    const value = globalSearchValue.trim().toLocaleLowerCase();
                    if (delivery.order_number.toLocaleLowerCase().includes(value)) {
                        shouldShow = true;
                    }
                    if (delivery.region.name.toLocaleLowerCase().includes(value)) {
                        shouldShow = true;
                    }
                    if (delivery.destination_address.district?.toLocaleLowerCase().includes(value)) {
                        shouldShow = true;
                    }
                    if (delivery.store.name.toLocaleLowerCase().includes(value)) {
                        shouldShow = true;
                    }
                    if (delivery.integration_instance?.initials.toLocaleLowerCase().includes(value)) {
                        shouldShow = true;
                    }
                    if ("sem entregador".includes(value)) {
                        shouldShow = true;
                    }
                }
                return shouldShow;
            });
            setFilteredDeliveryRequest(filtered);
        }
    }, [deliveryRequestList, globalSearchValue, retrieveNew]);

    React.useEffect(() => {
        if (tripList) {
            const filtered = tripList.filter((trip) => {
                if (globalSearchValue && globalSearchValue.trim().length) {
                    const value = globalSearchValue.trim().toLocaleLowerCase();
                    if (trip.store.name.toLocaleLowerCase().includes(value)) {
                        console.info("region")
                        return true;
                    }
                    if (trip.courier_name?.toLocaleLowerCase().includes(value)) {
                        console.info("courier_name")
                        return true;
                    }
                    if ((!trip.courier_name) && ("sem entregador".includes(value))) {
                        console.info("courier_name")
                        return true;
                    }
                    if (trip.region.name.toLocaleLowerCase().includes(value)) {
                        console.info("region")
                        return true;
                    }
                    const deliveries = trip.deliveries.filter(delivery => {
                        if (delivery.order_number.toLocaleLowerCase().includes(value)) {
                            return true;
                        }
                        if (delivery.destination_address?.district.toLocaleLowerCase().includes(value)) {
                            return true;
                        }
                        if (delivery.integration_instance?.name.toLocaleLowerCase().includes(value)) {
                            return true;
                        }
                        return false;
                    });
                    if (deliveries && deliveries.length > 0) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            });
            setFilteredTripList(filtered);
        }
    }, [tripList, globalSearchValue]);

    React.useEffect(() => {
        const _noCourierList: PendingTrip[] = [];
        const _waitingList: PendingTrip[] = [];
        const _outList: PendingTrip[] = [];
        filteredTripList.forEach(trip => {
            switch (trip.status) {
                case TripStatus.NEW:
                case TripStatus.NO_COURIER:
                case TripStatus.SEARCHING_COURIER:
                    _noCourierList.push(trip);
                    break;
                case waitingColumnFilter === "ALL" ? TripStatus.ACCEPTED : waitingColumnFilter:
                case waitingColumnFilter === "ALL" ? TripStatus.AWAITING_RELEASE : waitingColumnFilter:
                case waitingColumnFilter === "ALL" ? TripStatus.AWAITING_COLLECTION : waitingColumnFilter:
                    _waitingList.push(trip);
                    break;
                case outColumnFilter === "ALL" ? TripStatus.ON_WAY : outColumnFilter:
                case outColumnFilter === "ALL" ? TripStatus.BACK_TO_STORE : outColumnFilter:
                case outColumnFilter === "ALL" ? TripStatus.FINISHED : outColumnFilter:
                case outColumnFilter === "ALL" ? TripStatus.CANCELED : outColumnFilter:
                case outColumnFilter === "ALL" ? TripStatus.PARTIALLY_CANCELED : outColumnFilter:
                case outColumnFilter === "ALL" ? TripStatus.RETURNING : outColumnFilter:
                    _outList.push(trip);
                    break;
            }
        });

        setNoCourierList(_noCourierList);
        setWaitingList(_waitingList);
        setOutList(_outList);
    }, [waitingColumnFilter, outColumnFilter, filteredTripList]);


    const handleJoinTrips = (trip: PendingTrip) => {
        const availableDeliveries: PendingDeliveryRequest[] = deliveryRequestList.filter((delivery) => {
            return (delivery.store.id === trip.store.id);
        });
        const availableTrips: PendingTrip[] = [];
        noCourierList.forEach((noCouriertrip) => {
            if ([TripStatus.NEW, TripStatus.NO_COURIER].includes(noCouriertrip.status)
                && (noCouriertrip.store.id === trip.store.id)) {
                availableTrips.push(noCouriertrip);
            }
        });

        joinDeliveriesDialog.current?.addToTrip(trip, availableDeliveries, availableTrips);
    };

    const handleSearchCourierForTrip = async (trip: PendingTrip) => {
        let title = "Solicitar Entregador";
        let message = "Você tem certeza que deseja solicitar entregador?";
        let acceptButtonLabel = "Solicitar";
        const dialog = confirmationDialog.current;
        dialog?.confirm(title, message, acceptButtonLabel, "Cancelar")
            .then(async () => {
                try {
                    await requestCourier(trip);
                } finally {
                    dialog?.hide();
                }
            }).catch(() => { });
    };

    const requestCourier = React.useMemo(() => debounce(async (trip: PendingTrip) => {
        return new Promise<boolean>((resolve, reject) => {
            TripService.searchCourierForTrip(trip.id)
                .then((response) => {
                    loadData();
                    resolve(true);
                    NotificationManager.success(
                        "Entregador solicitado com sucesso!",
                        "Sucesso"
                    );
                })
                .catch((error) => {
                    reject(false);
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            errors.push(value);
                        }
                        NotificationManager.error(
                            errors.join(".\n"),
                            "Error"
                        );
                    } else {
                        NotificationManager.error(
                            "Não foi possível solicitar entregador!",
                            "Oops"
                        );
                    }
                });
        });
    }, 500), [loadData]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRetrieveNew(event.target.checked);
        onRetrievesNewChange(event.target.checked)
    };

    const handleSelectWaitingColumnFilter = (value: string | number) => {
        setWaitingColumnFilter(String(value));
    };

    const handleSelectOutColumnFilter = (value: string | number) => {
        setOutColumnFilter(String(value));
    };

    const getCardStatusChip = (deliveryRequest: PendingDeliveryRequest): CardStatusChip => {
        let label = t("delivery_request_status." + deliveryRequest.status);
        let severity = StatusSeverity.SUCCESS;

        if (deliveryRequest.status === DeliveryRequestStatus.NEW) {
            severity = StatusSeverity.INFO;
        }

        if (deliveryRequest.status === DeliveryRequestStatus.NO_COURIER) {
            if (deliveryRequest.courier_id) {
                label = t("delivery_request_status.SEARCHING_COURIER");
            } else {
                severity = StatusSeverity.WARN;
            }
        }
        return { label: label, severity: severity };
    }

    const showTripDetails = async (trip: PendingTrip) => {
        history.push(`/requests/trip/${trip.id}`);
    };

    const showDeliveryRequestDetails = async (deliveryRequest: any) => {
        let param =
            typeof deliveryRequest == "number"
                ? deliveryRequest
                : deliveryRequest.id;
        history.push(`/requests/detail/${param}`);
    };

    const handleSplitDeliveryRequest = (trip: PendingTrip, delivery: TripDeliveryRequest) => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            "Separar Pedido de Entrega",
            "Você tem certeza que deseja Separar esse Pedido de Entrega?",
            "Separar",
            "Cancelar"
        ).then(async () => {
            try {
                await handleConfirmSplitTrip(trip.id, delivery.id);
            } finally {
                dialog.hide();
            }
        }).catch(() => {
        });
    };

    const handleSetCourierToTrip = (trip: PendingTrip) => {
        setSelectedDelivery(undefined);
        setSelectedTrip(trip);
        setOpenSelectCourierDialog(true);
    };

    const handleSetCourierToDelivery = (delivery: PendingDeliveryRequest) => {
        setSelectedDelivery(delivery);
        setSelectedTrip(undefined);
        setOpenSelectCourierDialog(true);
    };

    const handleConfirmSplitTrip = React.useMemo(() => debounce(async (tripIdSplit: number, deliveryIdSplit: number) => {
        if (tripIdSplit && deliveryIdSplit) {
            try {
                await TripService.removeDeliveryFromTrip(tripIdSplit, deliveryIdSplit);
                await loadData();
                NotificationManager.success(
                    "Entrega separada com sucesso!",
                    "Sucesso"
                );
            } catch (error) {
                NotificationManager.error(
                    "Não foi possível separar a entrega!",
                    "Error"
                );
            }
        }
    }, 500), [loadData]);

    const handleCourierSelected = (courier: OnlineCourierUserDetails) => {
        return new Promise<void>(async (resolve, reject) => {
            if (selectedTrip || selectedDelivery) {
                try {
                    if (selectedTrip) {
                        console.log("await TripService.searchCourierForTrip(selectedTrip.id, courier.id);")
                        await TripService.searchCourierForTrip(selectedTrip.id, courier.id);
                    } else if (selectedDelivery) {
                        await DeliveryRequestService.requestCourierToDeliveryRequestById(selectedDelivery.id, courier.id);
                    }
                    NotificationManager.success(
                        "Entregador solicitado com sucesso!",
                        "Sucesso"
                    );
                    setSelectedDelivery(undefined);
                    setSelectedTrip(undefined);
                    await loadData();
                    setOpenSelectCourierDialog(false);
                    resolve();
                } catch (error) {
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            errors.push(value);
                        }
                        NotificationManager.error(
                            errors.join(".\n"),
                            "Error"
                        );
                    } else {
                        NotificationManager.error(
                            "Não foi possível solicitar entregador!",
                            "Oops"
                        );
                    }
                    reject();
                }
            } else {
                reject();
            }
        });
    };

    const resetTrip = React.useMemo(() => debounce(async (trip: PendingTrip) => {
        let successMessage = "Entrega(s) reaberta(s) com sucesso!";
        let failedMessage = "Não foi possível reabrir a(s) entrega(s)!";
        if ([TripStatus.SEARCHING_COURIER].includes(trip.status)) {
            successMessage = "O Entregador parou de ser chamado com sucesso!";
            failedMessage = "Não foi possível parar de chamar o Entregador!";
        }
        return new Promise<boolean>((resolve, reject) => {
            TripService.resetTripById(trip.id)
                .then((response) => {
                    loadData();
                    resolve(true);
                    NotificationManager.success(
                        successMessage,
                        "Sucesso"
                    );
                })
                .catch((error) => {
                    reject(false);
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            errors.push(value);
                        }
                        NotificationManager.error(
                            errors.join(".\n"),
                            "Error"
                        );
                    } else {
                        NotificationManager.error(
                            failedMessage,
                            "Oops"
                        );
                    }
                });
        });
    }, 500), [loadData]);

    const handleResetTrip = async (trip: PendingTrip) => {
        let title = "Reabrir Entrega(s)";
        let message = "Você tem certeza que deseja reabrir esta(s) entrega(s)?";
        let acceptButtonLabel = "Reabrir";
        if ([TripStatus.SEARCHING_COURIER].includes(trip.status)) {
            title = "Parar de Chamar Entregador";
            message = "Você tem certeza que deseja Parar de chamar Entregador para essa(s) entrega(s)?";
            acceptButtonLabel = "Parar";
        }
        const dialog = confirmationDialog.current;
        dialog?.confirm(title, message, acceptButtonLabel, "Cancelar")
            .then(async () => {
                try {
                    await resetTrip(trip);
                } finally {
                    dialog?.hide();
                }
            }).catch(() => { });
    }

    const handleReleaseTrip = (trip: PendingTrip) => {
        let title = "Liberar Coleta";
        let message = "Você tem certeza que deseja Liberar a Coleta?";
        let acceptButtonLabel = "Liberar Coleta";
        const dialog = confirmationDialog.current;
        dialog?.confirm(title, message, acceptButtonLabel, "Cancelar")
            .then(async () => {
                try {
                    await releaseTrip(trip.id, DeliveryRequestAction.RELEASE_ORDER);
                } finally {
                    dialog?.hide();
                }
            }).catch(() => { });
    };

    const releaseTrip = React.useMemo(() => debounce(async (tripId: number, action: DeliveryRequestAction) => {
        return new Promise<boolean>((resolve, reject) => {
            TripService.interveneInTrip(Number(tripId), action)
                .then((response) => {
                    loadData();
                    resolve(true);
                    NotificationManager.success(
                        "Coleta liberada com sucesso!",
                        "Sucesso"
                    );
                })
                .catch((error) => {
                    reject(false);
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            errors.push(value);
                        }
                        NotificationManager.error(
                            errors.join(".\n"),
                            "Error"
                        );
                    } else {
                        NotificationManager.error(
                            "Não foi possível liberar a coleta!",
                            "Oops"
                        );
                    }
                });
        });
    }, 500), [loadData]);

    const handleJoinDelivery = (deliveryRequest: PendingDeliveryRequest) => {
        const availableDeliveries: PendingDeliveryRequest[] = deliveryRequestList.filter((delivery) => {
            return (delivery.store.id === deliveryRequest.store.id);
        });
        const availableTrips: PendingTrip[] = [];
        noCourierList.forEach((trip) => {
            if ([TripStatus.NEW, TripStatus.NO_COURIER].includes(trip.status)
                && (trip.store.id === deliveryRequest.store.id)) {
                availableTrips.push(trip);
            }
        });

        joinDeliveriesDialog.current?.joinDeliveries(deliveryRequest, deliveryRequest.store.id, deliveryRequest.store.name, availableDeliveries, availableTrips);
    }

    const handleSearchCourierForDelivery = async (deliveryRequest: PendingDeliveryRequest) => {
        let title = "Solicitar Entregador";
        let message = "Você tem certeza que deseja solicitar entregador?";
        let acceptButtonLabel = "Solicitar";
        const dialog = confirmationDialog.current;
        dialog?.confirm(title, message, acceptButtonLabel, "Cancelar")
            .then(async () => {
                try {
                    await requestCourierForDelivery(deliveryRequest);
                } finally {
                    dialog?.hide();
                }
            }).catch(() => { });
    };

    const requestCourierForDelivery = React.useMemo(() => debounce(async (deliveryRequest: PendingDeliveryRequest) => {
        return new Promise<boolean>((resolve, reject) => {
            DeliveryRequestService.requestCourierToDeliveryRequestById(deliveryRequest.id)
                .then((response) => {
                    loadData();
                    resolve(true);
                    NotificationManager.success(
                        "Entregador solicitado com sucesso!",
                        "Sucesso"
                    );
                })
                .catch((error) => {
                    reject(false);
                    const { status, data } = error.response;
                    if (status === 400) {
                        let errors: string[] = [];
                        for (var key in data) {
                            var value = data[key];
                            errors.push(value);
                        }
                        NotificationManager.error(
                            errors.join(".\n"),
                            "Error"
                        );
                    } else {
                        NotificationManager.error(
                            "Não foi possível solicitar entregador!",
                            "Oops"
                        );
                    }
                });
        });
    }, 500), [loadData]);

    return (
        <React.Fragment>
            <ConfirmationDialog ref={confirmationDialog} />

            <JoinDeliveriesDialog ref={joinDeliveriesDialog} onSuccess={loadData} />

            {selectedDelivery && (
                <SelectCourierDialog
                    open={openSelectCourierDialog}
                    title="Direcionar para Entregador"
                    region={selectedDelivery.region}
                    referenceStore={selectedDelivery.store}
                    onCloseRequested={() => setOpenSelectCourierDialog(false)}
                    selectedCourier={handleCourierSelected}
                />
            )}

            {selectedTrip && (
                <SelectCourierDialog
                    open={openSelectCourierDialog}
                    title="Direcionar para Entregador"
                    region={selectedTrip.region}
                    referenceStore={selectedTrip.store}
                    onCloseRequested={() => setOpenSelectCourierDialog(false)}
                    selectedCourier={handleCourierSelected}
                />
            )}

            <Grid item md={4} className={classes.dashboardGridColumn}>
                <DashboardColumn
                    title={`Em aberto - ${noCourierList.length + filteredDeliveryRequest.length}`}
                    className={classes.list}
                    titleClassName={classes.noCourierTitle}
                    headerActions={
                        <FormGroup row className={classes.positionSwitch}>
                            <FormControlLabel
                                control={<GreenSwitch size="small" checked={retrieveNew} onChange={handleChange} name="retriever" />}
                                label={<Typography style={{ color: "white", fontSize: "12px" }}>Não solicitados</Typography>}
                                labelPlacement="start"
                            />
                        </FormGroup>
                    }
                >
                    <div className={classes.listContent}>
                        {
                            filteredDeliveryRequest.map((deliveryRequest, rowIndex) => (
                                <DeliveryRequestCardNew
                                    key={rowIndex}
                                    deliveryRequest={deliveryRequest}
                                    highlight={globalSearchValue.trim()}
                                    statusChip={getCardStatusChip(deliveryRequest)}
                                    onClick={showDeliveryRequestDetails}
                                    menuActions={[
                                        {
                                            title: "Juntar Entregas",
                                            onClick: handleJoinDelivery,
                                            available: !deliveryRequest.called_ifood
                                        },
                                        {
                                            title: "Solicitar Entregador",
                                            onClick: handleSearchCourierForDelivery,
                                            available: !deliveryRequest.called_ifood
                                        },
                                        {
                                            title: "Direcionar para Entregador",
                                            onClick: handleSetCourierToDelivery,
                                            available: !deliveryRequest.called_ifood
                                        },
                                    ]}
                                >
                                </DeliveryRequestCardNew>
                            ))
                        }


                        {noCourierList.map((trip) => (
                            <TripCard key={trip.id} trip={trip}
                                highlight={globalSearchValue.trim()}
                                showDeliveryStatus={false}
                                onClick={showTripDetails}
                                splitTripAction={[TripStatus.NEW, TripStatus.NO_COURIER].includes(trip.status) ? handleSplitDeliveryRequest : undefined}
                                menuActions={[
                                    {
                                        title: "Juntar Entregas",
                                        onClick: handleJoinTrips,
                                        available: (!trip.called_ifood && [TripStatus.NEW, TripStatus.NO_COURIER].includes(trip.status))
                                    },
                                    {
                                        title: "Solicitar Entregador",
                                        onClick: handleSearchCourierForTrip,
                                        available: (/*!trip.called_ifood &&*/ [TripStatus.NEW, TripStatus.NO_COURIER].includes(trip.status))
                                    },
                                    {
                                        title: "Direcionar para Entregador",
                                        onClick: handleSetCourierToTrip,
                                        available: (/*!trip.called_ifood &&*/ [TripStatus.NEW, TripStatus.NO_COURIER].includes(trip.status))
                                    },
                                    {
                                        title: "Parar de chamar Entregador",
                                        onClick: handleResetTrip,
                                        available: (/*!trip.called_ifood &&*/ [TripStatus.SEARCHING_COURIER].includes(trip.status))
                                    },
                                ]}
                            >
                            </TripCard>
                        ))}
                    </div>
                </DashboardColumn>
            </Grid>
            <Grid item md={4} className={classes.dashboardGridColumn}>
                <DashboardColumn
                    title={`Aguardando saída - ${waitingList.length}`}
                    className={classes.list}
                    titleClassName={classes.waitingTitle}
                    headerActions={
                        <DashboardSelectMenu
                            size="small"
                            triggerClassName={classes.positionFilter}
                            icon={<FilterListIcon />}
                            horizontalMenuAnchor="right"
                            itens={[{ value: "ALL", label: "Todos" }].concat(
                                waitingColumnStatusList.map(status => {
                                    return { value: status, label: t("trip_status." + status) }
                                }))}
                            selectedValue={waitingColumnFilter}
                            onSelect={handleSelectWaitingColumnFilter}
                        />
                    }
                >
                    <div className={classes.listContent}>
                        {waitingList.map((trip) => (
                            <TripCard
                                key={trip.id}
                                trip={trip}
                                highlight={globalSearchValue.trim()}
                                showDeliveryStatus={false}
                                onClick={showTripDetails}
                                menuActions={[
                                    {
                                        title: "Adicionar Entrega",
                                        onClick: handleJoinTrips,
                                        available: (!trip.called_ifood && [TripStatus.ACCEPTED,
                                        TripStatus.AWAITING_RELEASE,
                                        TripStatus.AWAITING_COLLECTION].includes(trip.status))
                                    },
                                    {
                                        title: "Reiniciar Viagem",
                                        onClick: handleResetTrip,
                                        available: (/*!trip.called_ifood &&*/ [TripStatus.ACCEPTED,
                                        TripStatus.AWAITING_RELEASE,
                                        TripStatus.AWAITING_COLLECTION,
                                        TripStatus.ON_WAY].includes(trip.status))
                                    },
                                    {
                                        title: "Liberar Coleta",
                                        onClick: handleReleaseTrip,
                                        available: (/*!trip.called_ifood &&*/ [TripStatus.AWAITING_RELEASE].includes(trip.status))
                                    },
                                ]}
                            >
                            </TripCard>
                        ))}
                    </div>
                </DashboardColumn>
            </Grid>
            <Grid item md={4} className={classes.dashboardGridColumn}>
                <DashboardColumn
                    title={`Em rota - ${outList.length}`}
                    className={classes.list}
                    titleClassName={classes.outTitle}
                    headerActions={
                        <DashboardSelectMenu
                            size="small"
                            triggerClassName={classes.positionFilter}
                            icon={<FilterListIcon />}
                            horizontalMenuAnchor="right"
                            itens={[{ value: "ALL", label: "Todos" }].concat(
                                outColumnStatusList.map(status => {
                                    return { value: status, label: t("trip_status." + status) }
                                }))}
                            selectedValue={outColumnFilter}
                            onSelect={handleSelectOutColumnFilter}
                        />
                    }
                >
                    <div className={classes.listContent}>
                        {outList.map((trip) => (
                            <TripCard
                                key={trip.id}
                                trip={trip}
                                highlight={globalSearchValue.trim()}
                                showTripStatus={false}
                                onClick={showTripDetails} />
                        ))}
                    </div>
                </DashboardColumn>
            </Grid>
        </React.Fragment >
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dashboardGridColumn: {
        height: "calc(100vh - 108px)",
    },
    list: {
        display: "flex",
        flexFlow: "column",
        flex: 1,
        maxHeight: "100%",
        height: "100%",
    },
    listContent: {
        flex: 1,
        marginTop: 0,
        padding: theme.spacing(1),
        overflow: "auto",
    },
    noCourierTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        position: "relative",
    },
    waitingTitle: {
        backgroundColor: "#d99528",
        color: theme.palette.primary.contrastText,
        position: "relative",
    },
    outTitle: {
        backgroundColor: "#44af07",
        color: theme.palette.primary.contrastText,
        position: "relative",
    },
    positionFilter: {
        position: "absolute",
        right: "8px",
        color: "white",
    },
    positionSwitch: {
        position: "absolute",
        right: "16px",
        color: "white",
    },
    cardButtonArea: {
        marginTop: (theme.spacing(1) * -1),
    },
}));
