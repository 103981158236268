import { SpeedyRankingType, VehicleLoadCompartmentType, VehicleType } from "./courier";
import { Address, DeliveryRequestStatus } from "./deliveryRequest";


export enum GravityStatus {
    LOW = "LOW",
    MODERATE  = "MODERATE",
    HIGH = "HIGH"
}

export interface RegisterOccurrences {
    id: number;
    created_at: Date;
    description: string;
    gravity: GravityStatus;
    number: string;
    delivery_request: DeliveryRequest;
    courier: CourierOccurrences;
    blocklist_record: BlocklistRecord;
    occurence_date: Date;
}

export interface DeliveryRequest {
    id: number;
    called_ifood: boolean;
    estimated_delivery_time: Date;
    destination_address: Address;
    created_at: Date;
    order_number: string;
    request_number: string;
    status: DeliveryRequestStatus;
}


export interface BlocklistRecord {
    id: number;
    all_customers: boolean;
    blocklists: number[];
    created_at: Date;
    duration: number;
    expired: boolean;
    reason: string;
    undefined_time: boolean;
    updated_at: Date;
    customers: CustomerOccurrence[],
}

export interface CourierOccurrences {
    id: number;
    name: string;
    new_ranking_points: number;
    phonenumber: string;
    photo: string;
    speedy_ranking: SpeedyRankingType;
    vehicle_load_compartment_type: VehicleLoadCompartmentType;
    vehicle_type: VehicleType;
    region: RegionOccurrence;
}

export interface CustomerOccurrence {
    id: number;
    trade_name: string;
}

export interface RegionOccurrence {
    id: number;
    name: string;
}